import Vue from 'vue';
import moment from 'moment';

Vue.filter('capitalize', function (value) {
	if (!value) return '';
	value = value.toString();
	return value.charAt(0).toUpperCase() + value.slice(1)
});

Vue.filter('lowercase', function (value) {
	if (!value) return '';
	value = value.toString();
	return value.toLowerCase();
});

Vue.filter('readableDateFormat', function (value) {
	if (!value) return '';
	return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
});

Vue.filter('readableShortDateFormat', function (value) {
    if (!value) return '';
    return moment(String(value)).format('MM.DD, HH:mm');
});

Vue.filter('getDate', function (value) {
	if (!value) return '';
	return value.split(' ')[0];
});

Vue.filter('getTime', function (value) {
	if (!value) return '';
	return value.split(' ')[1];
});

Vue.filter('gamesOptions', function (games) {
	const gamesOptions = games.map(item => {
		return {
			name: item.name,
			value: item.id
		}
	});
	gamesOptions.unshift({name: 'All', value: null});

	return games.length > 0 ? gamesOptions : [];
});

Vue.filter('platformsOptions', function (platforms) {
	const platformsOptions = platforms.map(item => {
		return {
			name: item.getName(),
			value: item.getId()
		}
	});
	platformsOptions.unshift({name: 'All', value: null});

	return platforms.length > 0 ? platformsOptions : [];
});
