<template>
	<div>
		<page-links v-bind:links="[
			{
				url: '/deep-links/add',
				text: $t('Add Deep Links'),
				classes: 'active'
			}
		]"/>
		<form class="form form--xl">
			<div class="form-group">
				<FormInput
					:name="'source'"
					:label="$t('Source')"
					:placeholder="$t('Source')"
					:value="form.source"
					:formErrors="errors.source"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group">
				<FormSelect
					:name="'game_id'"
					:label="$t('Game')"
					:value="form.game_id"
					:options="gamesOptions"
					:formErrors="errors.game_id"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group">
				<FormSelect
					:name="'platform_id'"
					:label="$t('Platform')"
					:value="form.platform_id"
					:options="platformsOptions"
					:formErrors="errors.platform_id"
					:onChange="formFieldChanged"
				/>
			</div>
			<FormNotification :errors="errors.message"/>
			<button
				class="button button-primary form-button-submit"
				@click.prevent="addLink()"
			>
				{{$t('Save')}}
			</button>
		</form>
	</div>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'

	import router from '../../routes'
	import SvgIcon from '../../components/SvgIcon'
	import PageLinks from '../../components/Page/Links'
	import FormInput from '../../components/Form/Input'
	import FormSelect from '../../components/Form/Select'
	import FormNotification from '../../components/Form/Notification'

	export default {
		props: ["id"],
		components: {
			SvgIcon,
			PageLinks,
			FormInput,
			FormSelect,
			FormNotification,
		},
		computed: {
			...mapGetters([
				'games',
				'platforms'
			])
		},
		data() {
			return {
				form: {
					source: '',
					game_id: '',
					platform_id: '',
				},
				gamesOptions: [],
				platformsOptions: [],
				errors: {},
				defaultError: {
					message: [
						this.$t('Something went wrong. Please try again.')
					]
				}
			};
		},
		created() {
			this.getFormData();
		},
		watch: {
			$route() {
				this.getFormData();
			},
		},
		methods: {
			...mapActions({
				getGames: 'getGames',
				getPlatforms: 'GET_PLATFORMS',
				addDeepLink: 'ADD_DEEP_LINK',
			}),
			formFieldChanged(field, value) {
				this.form[field] = value;
			},
			async getFormData() {
				await this.getGames();
				await this.getPlatforms();

				this.prepareFieldsAfterLoad();
			},
			prepareFieldsAfterLoad() {
				this.initGamesOptions();
				this.initPlatformsOptions();
			},
			initGamesOptions() {
				this.gamesOptions = this.games.map(item => {
					return {value: item.id, name: item.name}
				});
			},
			initPlatformsOptions() {
				this.platformsOptions = this.platforms.map(item => {
					return {value: item.getId(), name: item.getName()}
				});
			},
			addLink() {
				const data = this.form;

				this.addDeepLink(data)
					.then(() => router.push('/deep-links'))
					.catch(err => this.errorsHandler(err.response.data.errors));
			},
			errorsHandler(error) {
				this.errors = error ? error : this.defaultError;
			},
			clearError(key) {
				this.errors[key] = null;
			},
		}
	}
</script>