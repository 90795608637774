export default (matches) => {
	let brackets = [];

	matches.sort((a, b) => a.id - b.id);

	matches.forEach((item) => {
		const roundIsSet = 'undefined' !== typeof brackets[item.round - 1];
		const newItem = Object.assign(item);

		if (!roundIsSet) {
			brackets[newItem.round - 1] = {games: []};
		}

		if (newItem.first_participant) {
			newItem.first_participant.result = '';
		}

		if (newItem.second_participant) {
			newItem.second_participant.result = '';
		}

		brackets[newItem.round - 1].games.push(newItem);
	});

	return brackets;
};
