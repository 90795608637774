<template>
	<div
		class="brackets__player"
		:class="getPlayerClass()"
	>
		<div class="brackets__player-avatar" v-if="playerData">
			<img :src="getAvatar() ? getAvatar() : '/images/logo.jpg'" alt="avatar">
		</div>
		<div class="brackets__player-name" v-if="playerData">
			{{ getNickName() }}
		</div>
		<div class="brackets__player-result" v-if="playerData && showResult() && !showFinishedResult()">
			<span>{{playerData.result}}</span>
		</div>
		<div class="brackets__player-result finished" v-if="showFinishedResult()">
			<span>{{ getFinishedResult() }}</span>
		</div>

        <div class="brackets__player-cancel" @click="removeParticipantInMatch" v-if="showDeleteParticipantButton && playerData" title="Remove participant">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3178 0.682519C15.376 0.740576 15.4222 0.809545 15.4537 0.885477C15.4852 0.961408 15.5014 1.04281 15.5014 1.12502C15.5014 1.20723 15.4852 1.28863 15.4537 1.36456C15.4222 1.44049 15.376 1.50946 15.3178 1.56752L1.5678 15.3175C1.45044 15.4349 1.29127 15.5008 1.1253 15.5008C0.959331 15.5008 0.800159 15.4349 0.682801 15.3175C0.565443 15.2002 0.499512 15.041 0.499512 14.875C0.499512 14.709 0.565443 14.5499 0.682801 14.4325L14.4328 0.682519C14.4909 0.624314 14.5598 0.578136 14.6358 0.546628C14.7117 0.51512 14.7931 0.498901 14.8753 0.498901C14.9575 0.498901 15.0389 0.51512 15.1148 0.546628C15.1908 0.578136 15.2597 0.624314 15.3178 0.682519Z" fill="black"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.682641 0.682519C0.624437 0.740576 0.578258 0.809545 0.54675 0.885477C0.515242 0.961408 0.499023 1.04281 0.499023 1.12502C0.499023 1.20723 0.515242 1.28863 0.54675 1.36456C0.578258 1.44049 0.624437 1.50946 0.682641 1.56752L14.4326 15.3175C14.55 15.4349 14.7092 15.5008 14.8751 15.5008C15.0411 15.5008 15.2003 15.4349 15.3176 15.3175C15.435 15.2002 15.5009 15.041 15.5009 14.875C15.5009 14.709 15.435 14.5499 15.3176 14.4325L1.56764 0.682519C1.50958 0.624314 1.44061 0.578136 1.36468 0.546628C1.28875 0.51512 1.20735 0.498901 1.12514 0.498901C1.04293 0.498901 0.96153 0.51512 0.885599 0.546628C0.809667 0.578136 0.740698 0.624314 0.682641 0.682519Z" fill="black"/>
            </svg>

        </div>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'

	export default {
		name: "BracketItemPlayer",
		props: {
			data: Object,
			player: String
		},
		data() {
			return {
				defaultAvatar: process.env.AVATAR
			}
		},
		computed: {
            showDeleteParticipantButton () {
                if (!this.tournament || !this.tournament.getCustomSeeding()) return false
                if (![1,3].includes(this.tournament.getType())) return false
                if (![1,2].includes(this.data.round)) return false
                if (!['REGISTRATION_CLOSED'].includes(this.tournament.getStatus())) return false
                return true
            },
			...mapGetters({
                tournament: 'tournament',
				highlightedPlayerId: 'highlightedPlayerId'
			}),
			playerData: function () {
				return this.data[this.player]
			}
		},
		methods: {
            ...mapMutations(['REMOVE_EXCLUDE_PARTICIPANT', 'SET_DEFAULT_MATCH']),
			...mapActions({
                setParticipantToMatch: 'setParticipantToMatch',
				highlightBracketsPlayer: 'highlightBracketsPlayer',
				unHighlightBracketsPlayer: 'unHighlightBracketsPlayer',
			}),
            removeParticipantInMatch () {
                this.setParticipantToMatch({
                        tournamentId: this.tournament.getId(), matchId: this.data.id, data: {
                        participant_id: null,
                        participant_place: this.player
                    }
                }).then(async response => {
                    this.data[this.player] = response.data[this.player]
                    await this.SET_DEFAULT_MATCH(response.data)
                    await this.REMOVE_EXCLUDE_PARTICIPANT(this.playerData.id)
                })
            },
			getPlayerClass() {
                let classes = ['brackets__player-' + this.player];
                if (this.tournament && this.tournament.getCustomSeeding() && ['REGISTRATION_CLOSED'].includes(this.tournament.getStatus())) {
                    classes.push('big')
                }
                if (!this.playerData) {
                    classes.push('is--empty')
                    return classes
                }

				const match = this.data;
				const playerNode = this.playerData;
				const isHighLighted = playerNode.id === this.highlightedPlayerId;
				const hasWinner = match.winner_id && playerNode.id !== null;
				const isWinner = match.winner_id === playerNode.id;

				if (!hasWinner) return classes.join(' ');

				if (isHighLighted) classes.push('highlight');

				if (isWinner) {
					classes.push('winner');
				} else {
					classes.push('defeated');
				}

				return classes.join(' ');
			},
			showResult() {
				const match = this.data;
				const hasWinner = null !== match.winner_id;
				const hasResult = !!this.playerData.result;

				return (hasWinner || hasResult)
			},
			showFinishedResult() {
				const match = this.data;

				return match.match_result && 'FINISHED' === match.match_status;
			},
			highlightPlayer() {
				if (!this.playerData) return;
				this.highlightBracketsPlayer(this.playerData.id);
			},
			unHighlightPlayer() {
				if (!this.playerData) return;
				this.unHighlightBracketsPlayer(null);
			},
			getNickName() {
				// TODO refactor
				return this.playerData.type === 2 ? this.playerData.team.name : this.playerData.profile.user.nickname;
			},
			getAvatar() {
		  // TODO refactor
		  return this.playerData.type === 2 ? this.playerData.team.logo : this.playerData.profile.user.avatar;
			},
			getFinishedResult() {
				const result = this.data.match_result.split(':');
				return this.player === 'first_participant' ? result[0] : result[1];
			}
		}
	};
</script>
<style scoped lang="scss">
.big {
    width: 270px;
}
.brackets__player-cancel {
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    background: #fff;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 35px;
    transition: 0.2s;
    border-left: 1px solid #E5E5E5;
    &:hover {
        opacity: 0.5;
    }
}
</style>
