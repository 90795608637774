<template>
	<div class="brackets__statuses-map">
		<span class="started">{{$t('Started')}}</span>
		<span class="pending">{{$t('Pending')}}</span>
		<span class="conflict">{{$t('Conflict')}}</span>
		<span class="opened">{{$t('Opened')}}</span>
		<span class="finished">{{$t('Finished')}}</span>
	</div>
</template>

<script>
	export default {
		name: "BracketsStatusesMap",
	};
</script>
