<template>
    <div class="round__robin-match" :class="[match.status]">
        <div class="controls">
            <div class="controls-item"
                 :class="{active: isMatchInAdminCalls(match.id)}"
                 @click="openChat()"
                 v-if="showChatControl"
            >
                <SvgIcon
                    width="16"
                    height="16"
                    :viewBox="'0 0 24 24'"
                    :iconType="'chat'"
                />
                <span>{{$t('Chat')}}</span>
            </div>
            <div class="controls-item" v-else></div>
            <div class="controls-status">
                {{ match.status }}
            </div>
            <div class="controls-item" @click="isShowSelectWinner = true" v-if="showWinnerControl">
                <SvgIcon
                    width="16"
                    height="16"
                    :viewBox="'0 0 24 24'"
                    :iconType="'winner'"
                />
                <span>{{$t('Winner')}}</span>
            </div>
            <div class="controls-item" @click="selectRevertMatchId = true" v-if="showRevertControl">
                <SvgIcon
                    width="16"
                    height="16"
                    :iconType="'shuffle'"
                />
                <span>{{$t('Revert')}}</span>
            </div>
        </div>
        <div class="players">
            <div class="player" v-if="match.first_participant" :class="{'freeWin': !match.second_participant && match.status === 'FINISHED', 'winner': match.winner_id === match.first_participant.id}">
                <div class="avatar">
                    <img :src="getAvatar(match.first_participant.participant)" alt="">
                </div>
                <span v-if="match.first_participant">{{ getNickname(match.first_participant.participant) }}
                <span class="bold" v-if="!match.second_participant && match.status === 'FINISHED'"> - FREEWIN</span>
                </span>

                <div class="result" v-if="match.first_participant && (match.status === 'FINISHED' || match.first_participant.result)">
                    <div class="result__points" v-if="match.status === 'FINISHED'">
                        {{ match.points[0] }}
                    </div>
                    <template v-if="match.first_participant.result">
                        {{ match.first_participant.result }}
                    </template>
                    <template v-else-if="match.status === 'FINISHED'">0:0</template>
                </div>
            </div>

            <div class="player empty" v-else>
                <div class="avatar">
                    <img src="/images/logo.jpg" alt="">
                </div>
                <span>EMPTY</span>
            </div>

            <div class="player" v-if="match.second_participant" :class="{'empty': !match.second_participant, 'winner': match.second_participant && match.winner_id === match.second_participant.id}">
                <template v-if="match.second_participant">
                    <div class="avatar">
                        <img :src="getAvatar(match.second_participant.participant)" alt="">
                    </div>
                    <span>{{ getNickname(match.second_participant.participant) }}</span>
                    <div class="result" v-if="match.second_participant && (match.status === 'FINISHED' || match.second_participant.result)">
                        <div class="result__points" v-if="match.status === 'FINISHED'">
                            {{ match.points[1] }}
                        </div>
                        <template v-if="match.second_participant.result">
                            {{ match.second_participant.result }}
                        </template>
                        <template v-else-if="match.status === 'FINISHED'">0:0</template>
                    </div>
                </template>
                <template v-else>
                    <div class="avatar">
                        <img :src="'/images/logo.jpg'" alt="">
                    </div>
                    <span>EMPTY</span>
                </template>
            </div>

            <div class="player empty" v-else>
                <div class="avatar">
                    <img src="/images/logo.jpg" alt="">
                </div>
                <span>EMPTY</span>
            </div>

        </div>
        <SelectWinnerDialog @update="$root.$emit('updateRoom')" @close="isShowSelectWinner = false" v-if="isShowSelectWinner" :match="match"></SelectWinnerDialog>
        <RevertMatchDialog v-if="selectRevertMatchId" :callback="revert" @close="selectRevertMatchId = false"></RevertMatchDialog>

    </div>
</template>
<script>
import SvgIcon from "../../../SvgIcon";
import SelectWinnerDialog from './Dialogs/SelectWinnerDialog'
import RevertMatchDialog from './Dialogs/RevertMatchDialog'
import {mapActions, mapGetters} from "vuex";
import RoundRobinService from '../../../../api/round-robin'

export default {
    props: {
        match: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            processUpdate: false,
            selectRevertMatchId: false,
            isShowSelectWinner: false
        }
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament',
            isMatchInAdminCalls: 'isMatchInAdminCalls',
            centrifugeInstance: 'centrifugeInstance'
        }),
        showChatControl () {
            return this.match.first_participant && this.match.second_participant && !['FINISHED', 'WAITING'].includes(this.match.status)
        },
        showRevertControl () {
            if (this.match.status !== 'FINISHED') {
                return false;
            }

            if (this.tournament.getType() === 7) {
                if (this.match.firstParticipantNextMatch && this.match.firstParticipantNextMatch.status === 'FINISHED') {
                    return false;
                }
                if (this.match.secondParticipantNextMatch && this.match.secondParticipantNextMatch.status === 'FINISHED') {
                    return false;
                }

                if (!this.match.first_participant) {
                    return false;
                }

                if (!this.match.second_participant) {
                    return false;
                }

                return true;
            }

            if (this.match.firstParticipantNextMatch && this.match.firstParticipantNextMatch.status === 'FINISHED') {
                if (this.match.firstParticipantNextMatch.second_participant_id) {
                    return false;
                }
            }

            if (this.match.secondParticipantNextMatch && this.match.secondParticipantNextMatch.status === 'FINISHED') {

                if (this.match.secondParticipantNextMatch.second_participant_id) {
                    return false;
                }
            }

            if (!this.match.first_participant) {
                return false;
            }

            if (!this.match.second_participant) {
                return false;
            }

          return true;
        },
        showWinnerControl () {
            return this.match.status !== 'FINISHED' && (!!this.match.first_participant && !!this.match.second_participant)
        },
    },
    methods: {
        ...mapActions({
            showChat: 'OPEN_CHAT',
        }),
        getParticipantResult (participant) {
            console.log(participant)
        },
        async openChat() {
            const data = {
                match: this.match.id,
                tournament: this.tournament.getId(),
                payload: {
                    channel: this.match.chat_room
                }
            };

            this.showChat(data);
        },
        revert () {
            this.selectRevertMatchId = false
            RoundRobinService.rrRevertMatch(this.tournament.getId(), this.match.room_id, this.match.id).then(data => {
                if (data.response && data.response && data.response.status !== 200) {
                    this.$notify({
                        type: 'error',
                        title: 'Revert error',
                        group: 'notify',
                        text: data.response.data.message ? data.response.data.message : 'Unknown error'
                    })
                } else {
                    this.$root.$emit('updateRoom')
                }
            })
        },
        getChat () {
            RoundRobinService.getRoomMatch(this.tournament.getId(), this.match.room_id, this.match.id).then(response => {
                this.match = response.data.data;
            })
        },
        getAvatar (participant) {
            if (participant.type === 2) {
                return participant.team.logo ? participant.team.logo : '/images/logo.jpg'
            }

            return participant.profile.user.avatar ? participant.profile.user.avatar : '/images/logo.jpg';
        },
        getNickname (participant) {
            if (participant.type === 2) {
                return participant.team.name;
            }

            if (participant.profile.user.nickname) {
                return participant.profile.user.nickname;
            }
            if (participant.profile.user.name) {
                return participant.profile.user.name;
            }
            if (participant.profile.user.email) {
                return participant.profile.user.email;
            }

            return 'Unknown';
        },
        subscribeStatus () {
            this.centrifugeInstance.subscribe(`rr.tournament.${this.tournament.getId()}.match.${this.match.id}`, (match) => {
                this.processUpdate = true
                this.$emit('update')
            });
        }
    },
    mounted () {
      this.subscribeStatus()
    },
    components: {
        RevertMatchDialog,
        SvgIcon,
        SelectWinnerDialog
    }
}
</script>
<style scoped lang="scss">
.round__robin-match {
    margin-bottom: 20px;
    .controls {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        align-items: center;
        position: relative;
        .controls-status {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            z-index: 1;
            height: 100%;
        }
        .controls-item {
            display: flex;
            cursor: pointer;
            position: relative;
            min-height: 24px;
            z-index: 2;
            &.active {
                span {
                    color: #FF8A65;
                }
                ::v-deep {
                    svg {
                        path {
                            fill: #FF8A65;
                        }
                    }
                }
            }
            &:hover {
                opacity: 0.7;
            }
            transition: 0.2s;
            &::v-deep {
                svg {
                    path {
                        fill: #00CBCB
                    }
                }
            }
            span {
                margin-left: 4px;
                display: block;
                font-weight: 500;
                font-family: "Roboto";
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.16px;
                text-transform: uppercase;
                color: #06C2BC;

            }
        }
    }
    .players {
        border: 1px solid #E5E5E5;
        .player {
            position: relative;
            padding: 5px 10px;
            display: flex;
            align-items: center;
            & > span {
                max-width: 80%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .result {
                height: 100%;
                position: absolute;
                right: 0;
                display: flex;
                padding: 0 15px;
                align-items: center;
                justify-content: flex-start;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                min-width: 120px;
                text-transform: capitalize;

                color: #000000;
                border-left: 1px solid #E5E5E5;
                .result__points {
                    padding: 0 13px;
                    padding-left: 0;
                    font-weight: 500;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    line-height: 14px;
                    margin-right: 15px;
                    border-right: 1px solid #E5E5E5;
                    text-transform: capitalize;
                    color: #000000;
                }
            }
            &.winner {
                background: rgba(0, 231, 217, 0.5);
            }
            &.freeWin {
                background: #ccffcc;
            }
            &.empty {
                background: #fff6f6;
                * {
                    opacity: 0.5;
                }
            }
            .avatar {
                width: 28px;
                height: 28px;
                min-width: 28px;
                border-radius: 100%;
                overflow: hidden;
                img {
                    max-width: 100%;
                    border-radius: 100%;
                }

                margin-right: 10px;
            }
            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                text-transform: capitalize;
                color: #000000;
                &.bold {
                    font-weight: bold;
                    font-style: italic;
                }
            }
        }
        .player + .player {
            border-top: 1px solid #E5E5E5;
        }
    }
    &.CONFLICT {
        .controls-status {
            color: #D71411;
        }
        .players {
            border-color: #D71411;
            .player {
                border-color: #D71411;
                .result {
                    color: #D71411;
                }
            }
        }
    }
}
</style>
