<template>
    <div class="modal">
        <div class="modal-wrapper">
            <div class="modal-overlay"></div>
            <div class="modal-container">
                <div class="close" @click="$emit('close')">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.64113 6.98014L13.6128 3.00851C14.1291 2.53191 14.1291 1.73759 13.6128 1.26099L12.739 0.387234C12.2624 -0.129078 11.4681 -0.129078 10.9915 0.387234L7.01986 4.35887L3.00851 0.387234C2.53191 -0.129078 1.73759 -0.129078 1.26099 0.387234L0.387234 1.26099C-0.129078 1.73759 -0.129078 2.53191 0.387234 3.00851L4.35887 6.98014L0.387234 10.9915C-0.129078 11.4681 -0.129078 12.2624 0.387234 12.739L1.26099 13.6128C1.73759 14.1291 2.53191 14.1291 3.00851 13.6128L7.01986 9.64113L10.9915 13.6128C11.4681 14.1291 12.2624 14.1291 12.739 13.6128L13.6128 12.739C14.1291 12.2624 14.1291 11.4681 13.6128 10.9915L9.64113 6.98014Z" fill="#1C1C1C"/>
                    </svg>
                </div>
                <hooper
                    :itemsToShow="1" :centerMode="false" :rtl="true" pagination="fraction"
                >
                    <slide
                        v-for="result in results"
                        :key="result.id"
                    >
                        <div class="result__item">
                            <div class="result__item-reporter" v-if="result.user">
                                <img :src="result.user.avatar ? result.user.avatar : '/images/logo.svg'" alt="" class="avatar">
                                <div>
                                    <strong>{{ result.user.nickname ? result.user.nickname : result.user.name }}</strong>
                                    <span>GAME ID: {{ result.profile ? (result.profile.info.id ? result.profile.info.id : result.profile.info.nickname) : 'Unknown' }}</span>
                                </div>
                            </div>
                            <img :src="result.value_state" alt="" class="result-image">
                        </div>
                    </slide>
                    <hooper-navigation slot="hooper-addons"></hooper-navigation>
                    <hooper-pagination slot="hooper-addons"></hooper-pagination>

                </hooper>
            </div>
        </div>
    </div>
</template>
<script>
import { Hooper, Slide, Pagination as HooperPagination, Navigation as HooperNavigation } from 'hooper';
export default {
    props: {
        results: {
            type: Array,
            required: true
        }
    },
    components: {
        HooperNavigation,
        HooperPagination,
        Hooper,
        Slide
    },
}
</script>
<style scoped lang="scss">
.modal-container {
    width: 972px;
    max-width: 972px;
    max-width: 972px;
    .close {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
}
.modal-container {
    overflow: hidden;
}
.hooper {
    height: 500px !important;
}
::v-deep {

    .hooper {
        height: 500px !important;
    }
    .hooper-indicator {
        background: #E0E0E0;
        width: 12px;
        height: 12px;
        cursor: pointer;
        transition: 0.2s;
        border-radius: 100%;
        &.is-active {
            cursor: default;
            background: #00E7DF;
        }

        margin-left: 10px;
    }
    .hooper-navigation {
        top: -10px;
        position: relative;
        display: flex;
        justify-content: center;
        .hooper-next, .hooper-prev {
            position: static !important;
            margin: 0 80px;
        }
    }
}
.result__item {
    padding-bottom: 10px;
    height: 100%;
    .result__item-reporter {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .avatar {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            margin-right: 12px;
        }
        div {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            strong {
                display: block;
                width: 100%;
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                text-transform: capitalize;
                color: #1C1C1C;
                margin-bottom: 3px;
            }
            span {
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0.4px;
                text-transform: uppercase;
                color: #878787;
            }
        }
    }
    .result-image {
        max-width: 100%;
        max-height: 100%;
    }
}
.pagination {
    display: flex;
    margin-top: 25px;
    align-items: center;
    justify-content: center;
    width: 100%;
    .pagination__item {
        background: #E0E0E0;
        width: 12px;
        height: 12px;
        cursor: pointer;
        transition: 0.2s;
        border-radius: 100%;
        &.active {
            cursor: default;
            background: #00E7DF;
        }
    }
    .pagination__item + .pagination__item {
        margin-left: 10px;
    }
}
</style>
