import news from '../../api/news'
import PageMeta from "../../dto/page-meta";
import News from "../../dto/news";

import router from '../../routes'

const state = () => ({
	news: null,
	newsMeta: null
});

const getters = {
	news: state => state.news,
	newsMeta: state => state.newsMeta
};

const actions = {
	getAllNews: async ({commit}, data = {}) => {
		const response = await news.get(data);

		commit('UPDATE_NEWS', response.data.data);
		commit('UPDATE_NEWS_META', response.data);
	},
	getNews: async ({commit}, id) => {
		const response = await news.getItem(id);
		return response.data;
	},
	addNews: async ({commit}, data) => {
		try {
            const response = await news.add(data).then();
            if (response.status === 201) {
                router.push('/news');
            } else {
                throw response
            }
        } catch(e) {
            throw e
        }


	},
	updateNews: async ({commit}, data) => {
		try {
            const response = await news.update(data.id, data.data);

            if (response.status === 200) {
                router.push('/news');
            } else {
                throw response
            }
        } catch(e) {
            throw e
        }
	},
	deleteNews: async ({commit, dispatch}, id) => {
		try {
            await news.delete(id).then(data => {
                if (data.response && data.response.status !== 200) {
                    throw data
                }
            });
        } catch(e) {
            throw e
        }

		dispatch('getAllNews');
	},
};

const mutations = {
	UPDATE_NEWS: (state, data) => {
		const news = [];

		data.forEach(item => {
			news.push(new News(item));
		});

		state.news = news;
	},
	UPDATE_NEWS_META: (state, data) => {
		state.newsMeta = new PageMeta(data);
	}
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
