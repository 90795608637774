<template>
	<div v-if="tournament">
		<ChallengeContainer :id="id" v-if="tournament.getType() === 8 || tournament.getType() === 9"></ChallengeContainer>
		<BracketsContainer :id="id" v-if="tournament.getType() === 1 || tournament.getType() === 3" />
		<LeaderboardContainer :id="id" v-if="tournament.getType() === 2" />
		<RoundRobinContainer :id="id"
			v-if="tournament && (tournament.getType() === 4 || tournament.getType() === 7) && tournament.getRR()">
		</RoundRobinContainer>
		<MultilobbyArena :id="id" v-if="tournament.getType() === 5" />
		<TftBracketsContainer :id="id" v-if="tournament.getType() === 6" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import BracketsContainer from '../../components/Brackets/BracketsContainer'
import LeaderboardContainer from "../../components/Leaderboard/LeaderboardContainer";
import MultilobbyArena from '../../components/Multilobby/MultilobbyContainer';
import TftBracketsContainer from '../../components/Tft/TftBracketsContainer'
import RoundRobinContainer from '../../components/RoundRobin/RoundRobinContainer';
import ChallengeContainer from '../../components/Challenge/ChallengeContainer'
export default {
	props: {
		id: String
	},
	components: {
		ChallengeContainer,
		LeaderboardContainer,
		BracketsContainer,
		MultilobbyArena,
		TftBracketsContainer,
		RoundRobinContainer
	},
	computed: {
		...mapGetters({
			tournament: 'tournament',
		}),
	},
	mounted() {
		this.init();
	},
	methods: {
		...mapActions({
			getTournament: 'GET_TOURNAMENT'
		}),
		async init() {
			await this.getTournament(this.id);
		},
	}
}
</script>
