import http from '../utils/http'
import joinUrls from '../utils/join-urls'

export default {
    getTournamentType (id) {
        return http.get(joinUrls('/tournaments', id, 'get-type'))
    },
    getChatMessages (roomId)
    {
        return http.get(joinUrls('/tournaments', 'chat', roomId))
    },
    sendChatMessages (roomId, data)
    {
        return http.post(joinUrls('/tournaments', 'chat', roomId), data)
    },
    saveChallengeWinners (tournamentId, data)
    {
        return http.put(joinUrls('/tournaments', tournamentId, 'challenge', 'save-winners' ), data)
    },
	get(id) {
		if (!id) {
			return Promise.reject(new Error('Tournament id is required to get tournament data'))
		}

		return http.get(joinUrls('/tournaments', id))
	},
	fillEmptySpots (id) {
		if (!id) {
			return Promise.reject(new Error('Tournament id is required to get tournament data'))
		}

		return http.put(joinUrls('/tournaments', id, 'fill-empty-spots'))
	},

    finishChallenge(id) {
        return http.put(joinUrls('/tournaments', id, 'challenge', 'finish'));
    },

	add(data) {
		return http.postMultipart('/tournaments', data)
	},
	update(id, data) {
		if (!id) {
			return Promise.reject(new Error('Tournament id is required to update tournament data'))
		}

		return http.putMultipart(joinUrls('/tournaments', id), data)
	},
	changeStatus(id, data) {
		if (!id) {
			return Promise.reject(new Error('Tournament id is required to update tournament data'))
		}

		return http.put(joinUrls('/tournaments', id, 'change-status'), data)
	},
	deleteTournament (id) {
		if (!id) {
			return Promise.reject(new Error('Tournament id is required to delete tournament data'))
		}

		return http.delete(joinUrls('/tournaments', id))
	},
	start(id, data) {
		return http.put(joinUrls('/tournaments', id, 'start'), data)
	},
	getResults(id) {
		return http.get(joinUrls('/tournaments', id, 'results'))
	},
	getGames() {
		return http.get('/games')
	},
	getPlatforms() {
		return http.get('/platforms')
	}
}
