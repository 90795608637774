import http from '../utils/http'
import joinUrls from '../utils/join-urls'

export default {
	get(data) {
		return http.get('/deep-link', data)
	},
	getLink(id) {
		if (!id) {
			return Promise.reject(new Error('Deep link id is required to get link data'))
		}

		return http.get(joinUrls('/deep-link', id))
	},
	addLink(data) {
		return http.post('/deep-link', data)
	},
	deleteLink(id) {
		if (!id) {
			return Promise.reject(new Error('Deep link id is required to delete link'))
		}

		return http.delete(joinUrls('/deep-link', id))
	}
}