<template>
	<div
		class="brackets__controls"
		v-if="showControls"
	>
        <template v-if="hasPermission('Tournament edit', tournament.getGameId())">
            <button
                class="brackets__control brackets__control--choose-winner"
                v-if="showWinnerControl"
                @click.prevent="openWinnerDialog()"
            >
                <SvgIcon
                    width="16"
                    height="16"
                    :viewBox="'0 0 24 24'"
                    :iconType="'winner'"
                />
                <span>{{$t('Winner')}}</span>
            </button>
        </template>

        <template v-if="hasPermission('Messages send', tournament.getGameId())">
            <button
                class="brackets__control brackets__control--open-chat"
                :class="{active: isMatchInAdminCalls(currentMatch.id)}"
                v-if="showChatControl"
                @click="openChat()"
            >
                <SvgIcon
                    width="16"
                    height="16"
                    :viewBox="'0 0 24 24'"
                    :iconType="'chat'"
                />
                <span>{{$t('Chat')}}</span>
            </button>
        </template>
        <template v-if="hasPermission('Tournament edit', tournament.getGameId())">
            <button
                class="brackets__control brackets__control--revert"
                v-if="data.match_status === 'FINISHED' && !data.next_match_finished"
                @click.prevent="revert()"

            >
                <SvgIcon
                    width="16"
                    height="16"
                    :iconType="'shuffle'"
                />
                <span>{{$t('Revert')}}</span>
            </button>
        </template>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'
	import SvgIcon from '../SvgIcon'

	import {STATUSES_BEFORE_START} from '../../constants/common'
	import {REVERT_MATCH_DIALOG} from '../../constants/dialogs'

	export default {
		name: "BracketItemControls",
		components: {
			SvgIcon
		},
		props: {
			data: Object
		},
		data() {
			return {
				winnerId: this.data.winner_id
			}
		},
		computed: {
			currentMatch: function () {
				return this.data;
			},
			showControls: function () {
				return !STATUSES_BEFORE_START.includes(this.status);
			},
			showWinnerControl: function () {
				return !this.data.winner_id && (!!this.data.first_participant || !!this.data.second_participant)
			},
			showChatControl: function () {
				return this.data.first_participant && this.data.second_participant && 'FINISHED' !== this.data.match_status
			},
			...mapGetters({
                centrifugeInstance: 'centrifugeInstance',
				tournament: 'tournament',
				getNextMatch: 'getNextMatch',
				status: 'tournamentStatus',
				isActiveChat: 'isActiveChat',
                isMatchInAdminCalls: 'isMatchInAdminCalls'
			}),
		},
		methods: {
			...mapActions({
				showWinnerDialog: 'showBracketsWinnerDialog',
				showChat: 'OPEN_CHAT',
				showDialog: 'showDialog',
			}),
			openWinnerDialog() {
				const data = {
					matchId: this.currentMatch.id,
					tournamentId: this.tournament.getId()
				};

				this.showWinnerDialog(data);
			},
			async openChat() {
				const data = {
					match: this.currentMatch.id,
					tournament: this.tournament.getId(),
                    payload: {
                        channel: this.currentMatch.chat_room
                    }
				};

				this.showChat(data);
			},
			revert() {
				this.showDialog({
					type: REVERT_MATCH_DIALOG,
					match: this.currentMatch.id
				});
			}
		}
	};
</script>
