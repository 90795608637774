<template>
    <div class="miltilobby">
        <div class="page__content-header">
            <PageLinks :links="[
					{
						url: '/tournament/' +  tournament.getId(),
						text: tournament.getTitle()
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/brackets',
						text: $t('Brackets')
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/participants',
						text: $t('Participants')
					}
				]"/>
            <div class="page-content__actions">
                <BracketsRoundsScheduleButton/>
                <TftStartButton/>
            </div>
        </div>
        <div class="page__content-body" :class="{progress: showProgressScreen}">
            <LeaderboardGeneratingLoader v-if="showProgressScreen"/>
            <Tft :key="key" v-if="!showProgressScreen && isStarted" :id="id"></Tft>
            <p class="form-field-description" v-if="!isStarted && !showProgressScreen">
                Information will be generated after the start of the tournament
            </p>
            <BracketsRoundsScheduleDialog @update="key++"></BracketsRoundsScheduleDialog>
        </div>
    </div>
</template>
<script>
import TftStartButton from './TftStartButton'
import PageLinks from '../../components/Page/Links'
import LeaderboardStartButton from '../Leaderboard/LeaderboardStartButton'
import LeaderboardGeneratingLoader from '../Leaderboard/LeaderboardGeneratingLoader'
import Tft from '../Tft/Tft'
import BracketsRoundsScheduleDialog from '../Brackets/BracketsRoundsScheduleDialog'
import BracketsRoundsScheduleButton from '../Brackets/BracketsRoundsScheduleButton'
import {mapGetters} from "vuex";
export default {
    data () {
        return {
            key: 0,
        }
    },
    props: {
        id: String
    },
    components: {
        TftStartButton,
        PageLinks,
        LeaderboardStartButton,
        BracketsRoundsScheduleDialog,
        BracketsRoundsScheduleButton,
        Tft,
        LeaderboardGeneratingLoader
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament',
            tournamentStatus: 'tournamentStatus'
        }),
        isStarted () {
            return ['STARTED', 'IN_PROGRESS', 'FINISHED', 'CLOSED'].includes(this.tournamentStatus)
        },
        showProgressScreen() {
            return 'PENDING' === this.tournamentStatus;
        }
    },
}
</script>
