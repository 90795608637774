<template>
	<div class="status-progress" v-if="currentStatus">
		<div
			class="status-progress__item" v-for="status in statuses">
			<div class="status-progress__item-dot" :class="{active: status.active}"></div>
			<div class="status-progress__item-label">{{status.title}}</div>
		</div>
	</div>
</template>

<script>
	import {STATUSES} from '../constants/common'

	export default {
		name: "StatusProgress",
		props: {
			currentStatus: {
				type: String,
				default: "OPENED"
			},
		},
		data() {
			return {
				isActive: true,
				statuses: []
			}
		},
		mounted() {
			STATUSES.forEach((status) => {
				this.statuses.push({
					title: this.getStatusText(status),
					active: this.isActive,
				});

				if (status === this.currentStatus) this.isActive = false;
			});
		},
		methods: {
			changeStatus() {
				this.active = false
			},
			getStatusText(text) {
				return text.toLowerCase().replace('_', ' ')
			}
		}
	};
</script>