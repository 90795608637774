<template>
    <div>
        <div class="top__panel">
            <PageLinks :links="[
                {
                    url: '/tournament/advanced/',
                    text: 'Advanced'
                }
            ]" />
        </div>

        <div>
            <form action="#" method="post" class="form-container">
                <div class="spacer"></div>
                <div class="form-container-input">
                    <label for="tournamentId">
                        Tournament ID
                    </label>
                    <input @keyup="($event) => { populateData($event.target.value) }" name="tournamentId"
                        class="multiselect__tags" placeholder="Tournament id">
                </div>
                <div class="form-container-input">
                    <label for="tournamentStatus">
                        Tournament Status
                    </label>
                    <FormMultiSelect v-model="selectedTournamentStatus" :placeholder="$t('')" :options="tournamentStatuses"
                        :loading="false" :label="'name'" :track-by="'value'" :multiple="false" :taggable="false"
                        :allowEmpty="false" />
                </div>
                <div class="form-container-input second-row">
                    <label for="matchId">
                        Match ID
                    </label>
                    <FormMultiSelect v-model="selectedMatch" :placeholder="$t('')" :options="tournamentMatches"
                        :loading="false" :label="'name'" :track-by="'value'" :multiple="false" :taggable="false"
                        :disabled="isLoading" @close="matchChanged" />
                </div>

                <div class="form-container-input second-row">
                    <label for="matchStatus">
                        Match Status
                    </label>
                    <FormMultiSelect v-model="selectedMatchStatus" :placeholder="$t('')" :options="matchStatuses"
                        :loading="false" :label="'name'" :track-by="'value'" :multiple="false" :taggable="false"
                        :allowEmpty="false" :disabled="isLoading || !selectedMatch" />
                </div>

                <div class="form-container-input third-row">
                    <label for="participant1">
                        Participant 1
                    </label>
                    <FormMultiSelect v-model="selectedParticipant1" :placeholder="$t('')" :options="matchParticipants"
                        :loading="false" :label="'name'" :track-by="'value'" :multiple="false" :taggable="false"
                        :disabled="isLoading || !selectedMatch" />
                </div>

                <div class="form-container-input third-row">
                    <label for="participant2">
                        Participant 2
                    </label>
                    <FormMultiSelect v-model="selectedParticipant2" :placeholder="$t('')" :options="matchParticipants"
                        :loading="false" :label="'name'" :track-by="'value'" :multiple="false" :taggable="false"
                        :disabled="isLoading || !selectedMatch" />
                </div>

                <div class="form-container-input third-row">
                    <label for="winner">
                        Winner
                    </label>
                    <FormMultiSelect v-model="selectedWinner" :placeholder="$t('')" :options="matchParticipants"
                        :loading="false" :label="'name'" :track-by="'value'" :multiple="false" :taggable="false"
                        :disabled="isLoading || !selectedMatch" />
                </div>

            </form>
        </div>
        <div class="saving-container">
            <div class="save-error response-message" v-if="saveError">
                {{ saveError }}
            </div>
            <div class="save-success response-message" v-if="saveSuccess">
                Save success!
            </div>

            <div>
                <button class="button button-primary form-button-submit" :disabled="isSaving || !tournament"
                    @click.prevent="changeTournamentStatus()">
                    {{ isSaving ? 'Saving...' : 'Change Tournament Status' }}
                </button>
                <button class="button button-primary form-button-submit"
                    :disabled="isSaving || !selectedMatch || !tournament" @click.prevent="save()">
                    {{ isSaving ? 'Saving...' : 'Change Match' }}
                </button>
            </div>
        </div>

        <div class="separator"></div>


        <div class="ban-section-wrapper">
            <div class="top__panel">
                <div class="title">User Access Management</div>
            </div>

            <div class="ban-section">
                <div class="form-container-input" style="width: 30%; align-items: baseline;">
                    <input type="text" v-model="blockedUser" class="multiselect__tags" placeholder="User Email">
                </div>
                <button type="submit" class="button button-primary form-button-submit" @click="blockUser"
                    style="width: 40%; margin: 0;">
                    Block Access
                </button>
            </div>

            <div class="error-wrapper">
                <div v-if="blockedUserError" class="save-error block-message">
                    {{ blockedUserError }}
                </div>


                <div v-if="blockedUserSuccess" class="save-success block-message">
                    User blocked successfully!
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import PageLinks from '../../components/Page/Links'
import SvgIcon from '../../components/SvgIcon'
import FormNumber from '../../components/Form/Number'
import FormMultiSelect from 'vue-multiselect'
import match from '../../api/match'
import participants from '../../api/participants'
import users from '../../api/users'
import TournamentService from '../../api/tournament'

export default {
    props: [],
    components: {
        SvgIcon,
        PageLinks,
        FormMultiSelect,
        FormNumber,
    },
    data() {
        return {
            isLoading: false,
            isSaving: false,
            matchStatuses: [],
            tournamentMatches: [],
            tournamentParticipants: [],
            matchParticipants: [],
            selectedTournamentId: null,
            tournamentStatuses: [],
            tournamentId: null,
            selectedTournamentStatus: null,
            selectedMatch: null,
            selectedParticipant1: null,
            selectedParticipant2: null,
            selectedWinner: null,
            selectedMatchStatus: null,
            saveError: null,
            saveSuccess: null,
            blockedUser: null,
            blockedUserError: null,
            blockedUserSuccess: false,
        }
    },
    computed: {
        ...mapGetters([
            'tournament'
        ])
    },
    created() {
        this.init();
    },
    methods: {
        ...mapActions({
            getTournament: 'GET_TOURNAMENT', //_WITHOUT_STATUS
        }),
        async init() {
            this.matchStatuses = [
                { name: 'OPENED', value: 'OPENED' },
                { name: 'WAITING', value: 'WAITING' },
                { name: 'STARTED', value: 'STARTED' },
                { name: 'PENDING', value: 'PENDING' },
                { name: 'FINISHED', value: 'FINISHED' },
                { name: 'CONFLICT', value: 'CONFLICT' },
            ];
            this.tournamentStatuses = [
                { name: 'OPENED', value: 'OPENED' },
                { name: 'REGISTRATION_OPENED', value: 'REGISTRATION_OPENED' },
                { name: 'CHECKIN', value: 'CHECKIN' },
                { name: 'REGISTRATION_CLOSED', value: 'REGISTRATION_CLOSED' },
                { name: 'PENDING', value: 'PENDING' },
                { name: 'STARTED', value: 'STARTED' },
                { name: 'IN_PROGRESS', value: 'IN_PROGRESS' },
                { name: 'FINISHED', value: 'FINISHED' },
                { name: 'CLOSED', value: 'CLOSED' },
            ];
            this.tournamentMatches = []
            this.tournamentParticipants = []
            this.matchParticipants = []
            this.selectedMatch = null
            this.selectedParticipant1 = null
            this.selectedParticipant2 = null
            this.selectedWinner = null
            this.selectedMatchStatus = null
        },
        isNumber: (val) => {
            return /^\d+$/.test(val)
        },
        populateData(val) {
            this.selectedMatch = null;
            this.selectedParticipant1 = null;
            this.selectedParticipant2 = null;
            this.tournamentMatches = [];
            this.tournamentParticipants = [];
            this.matchParticipants = [];
            if (this.isNumber(val)) {
                this.isLoading = true
                this.getTournament(val).then((tournament) => {
                    this.tournamentId = tournament.data?.id
                    this.selectedTournamentStatus = this.tournamentStatuses.find((o) => o.value === tournament.data?.status)
                    this.getTournamentParticipants().then(() => {
                        this.getTournamentMatches().then(() => {
                            this.isLoading = false
                        })
                    })
                })
            } else {
                this.selectedMatch = null;
                this.selectedParticipant1 = null;
                this.selectedParticipant2 = null;
                this.tournamentMatches = [];
                this.tournamentParticipants = [];
                this.matchParticipants = [];
            }

        },
        getTournamentMatches() {
            return new Promise((resolve, reject) => {
                if (this.tournament && this.tournament.exists()) {
                    match.getAll(this.tournament.getId(), true).then((resp) => {
                        this.tournamentMatches = resp.data.data.map((o) => {
                            return {
                                name: o.id,
                                value: o
                            }
                        })
                        resolve()
                    }).catch((err) => {
                        resolve()
                    })
                } else {
                    resolve()
                }
            });
        },
        getTournamentParticipants() {
            return new Promise((resolve, reject) => {
                if (this.tournament && this.tournament.exists()) {
                    participants.get(this.tournament.getId()).then((resp) => {
                        this.tournamentParticipants = resp.data.data.map((o) => {
                            return {
                                name: `${o.id} - ${o.team ? o.team?.name : o.profile?.user?.nickname}`,
                                value: o
                            }
                        })
                        resolve()
                    }).catch((err) => {
                        resolve()
                    })
                } else {
                    resolve()
                }
            })
        },
        matchChanged() {

            // closed filter
            this.matchParticipants = this.tournamentParticipants
                .filter(o => o.value.id === this.selectedMatch?.value.first_participant?.id).concat(
                    this.tournamentParticipants
                        .filter(o => o.value.id === this.selectedMatch?.value.second_participant?.id)
                )

            // open filter
            // this.matchParticipants = this.tournamentParticipants

            this.selectedParticipant1 = this.tournamentParticipants.find((o) => o.value.id === this.selectedMatch?.value.first_participant?.id)
            this.selectedParticipant2 = this.tournamentParticipants.find((o) => o.value.id === this.selectedMatch?.value.second_participant?.id)
            this.selectedWinner = this.tournamentParticipants.find((o) => o.value.id === this.selectedMatch?.value.winner_id)
            this.selectedMatchStatus = this.matchStatuses.find((o) => o.value === this.selectedMatch?.value.match_status)
        },
        save() {
            this.isSaving = true
            this.saveError = null;
            this.saveSuccess = null;

            let data = {
                firstParticipant: this.selectedParticipant1 ? this.selectedParticipant1.value.id : null,
                secondParticipant: this.selectedParticipant2 ? this.selectedParticipant2.value.id : null,
                winner: this.selectedWinner ? this.selectedWinner.value.id : null,
                status: this.selectedMatchStatus ? this.selectedMatchStatus.value : null
            }

            match.advancedUpdate(
                this.tournament.getId(),
                this.selectedMatch.value.id,
                data
            ).then((resp) => {
                if (resp && resp.status === 200) {
                    this.isSaving = false
                    this.saveSuccess = true
                    setTimeout(() => {
                        this.saveSuccess = false
                    }, 3000)
                } else {
                    this.isSaving = false
                    this.saveError = resp.data?.error ? resp.data.error : resp
                    setTimeout(() => {
                        this.saveError = false
                    }, 5000)
                }
            })
        },
        blockUser() {
            this.blockedUserError = null;
            this.blockedUserSuccess = null;

            users.block(this.blockedUser).then(response => {
                if (response.name === 'Error') {
                    this.blockedUserError = response.message;
                } else {
                    this.blockedUserSuccess = true;
                }
                this.blockedUser = null
            })
        },
        changeTournamentStatus() {
            TournamentService.changeStatus(this.tournamentId, this.selectedTournamentStatus ?? 'OPENED')
                .then((res) => {
                    if (res && res.status === 200) {
                        this.isSaving = false
                        this.saveSuccess = true
                        setTimeout(() => {
                            this.saveSuccess = false
                        }, 3000)
                    } else {
                        console.log(res)
                        this.isSaving = false
                        this.saveError = res.data?.error ? res.data.error : res
                        setTimeout(() => {
                            this.saveError = false
                        }, 5000)
                    }
                })
                .catch((err) => console.log(err))
        }
    },
}
</script>
<style scoped lang="scss">
.top__panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.open-tournament-chat {
    background: transparent;
    cursor: pointer;
    border: 1px solid #00E7DF;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 40px;
    padding: 9px 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    color: #00CBCB;
    transition: 0.2s;

    &:hover {
        background: #ecfffe;
    }
}

.form-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.spacer {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
}

.second-row {
    grid-row: 2;
}

.third-row {
    grid-row: 3;
    grid-column: span 2;
}

.form-container-input {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-container-input input,
select {
    width: 98%;
}

.saving-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.response-message {
    border-radius: 4%;
    border: .5px solid #00E7DF;
    width: 25%;
    padding: 1rem 1rem;
    text-align: center;
}

.save-error {
    background-color: rgba(248, 85, 85, 0.5);
}

.save-success {
    background-color: rgba(79, 223, 79, 0.5);
}

.separator {
    background-color: #000000;
    width: 100%;
    height: 1px;
    margin: 2rem 0;
}

.title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.ban-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.error-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-message {
    margin-top: 1rem;
    padding: 1rem;
    text-align: center;
    color: white;
    width: 60%;
}
</style>
