<template>
	<header
		class="page__header"
		v-if="showPanel"
	>
		<div class="page__header--left">
			<RouterLink :class="'page__header-brand'" to="/">
				<div class="page__header-logo">
					<img :src="'/images/logo.jpg'" alt="">
				</div>
				<h1 class="page__header-title">{{$t('QLASH Tournament Admin Panel')}}</h1>
			</RouterLink>
		</div>
		<div class="page__header--right">
			<a href="#" class="page__header-profile">
                <span>{{ getUser.nickname }}</span>
				<dropdown
					:data-position="'bottom'"
					:data-horizontal-aligntment="'right'"
				>
					<svg-icon
						slot="dropdown-toggle"
						width="22"
						height="22"
						iconColor="#FFFFFF" dropdown-toggle
						:iconType="'profile'"
					/>
					<div slot="dropdown-content">
						<RouterLink :class="'dropdown__link'" to="/logout">
							<span>{{$t('Logout')}}</span>
						</RouterLink>
					</div>
				</dropdown>
			</a>
		</div>
	</header>
</template>

<script>
	import SvgIcon from '../SvgIcon'
	import Dropdown from '../Dropdown'

	import {mapGetters} from 'vuex'

	export default {
		name: "page-header",
		components: {
			SvgIcon,
			Dropdown
		},
		computed: {
			...mapGetters([
                'getUser',
				'navigationIsClosed',
				'showPanel'
			]),
		}
	};
</script>

<style scoped lang="scss">
.page__header-profile {
    align-items: center;
    display: flex;
    span {
        margin-right: 10px;
    }
}
@media screen and (max-width: 575px) {
    .page__header-profile span {
        font-size: 12px;
    }
}
</style>
