<template>
	<div class="form-checkbox">
		<label>
			<input
				class="form-check-input"
				type="checkbox"
				v-model="state"
				@change="valueChange"
                :disabled="disabled"
			>
			{{label}}
		</label>
		<FormNotification :errors="errors"/>
	</div>
</template>

<script>
	import FormNotification from '../../components/Form/Notification'

	export default {
		name: "FormCheckbox",
		components: {
			FormNotification
		},
		data() {
			return {
				state: false,
				errors: this.formErrors,
			}
		},
		props: {
            disabled: {
                default: false,
                type: Boolean
            },
			name: {
				type: String,
			},
			value: {
				type: Boolean,
			},
			label: {
				type: String,
			},
			formErrors: {
				type: [Array, Object],
			},
			onChange: {type: Function},
		},
		watch: {
			value: {
				handler(val) {
					this.state = val;
				}
			},
			formErrors: {
				handler(errors) {
					this.errors = errors;
				},
				deep: true
			}
		},
		methods: {
			valueChange(event) {
				this.onChange(this.name, event.target.checked);
				this.errors = [];
			}
		}
	};
</script>
