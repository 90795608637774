<template>
	<div class="brackets__empty" v-if="show()">
		<p>{{$t('No bracket yet.')}} <br> {{$t('Close the registration of players to enable generating bracket.')}}</p>
		<button class="button button-primary" @click="generate">{{$t('Generate Brackets')}}</button>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'

	export default {
		name: "BracketsEmpty",
		computed: {
			...mapGetters({
				tournament: 'tournament',
				brackets: 'brackets',
			})
		},
		methods: {
			...mapActions({
				generateBrackets: 'generateBrackets'
			}),
			async generate() {
				await this.generateBrackets(this.tournament.getId());
			},
			show() {
				return 'OPENED' === this.tournament.getStatus() || 'CHECKIN' === this.tournament.getStatus() || 'REGISTRATION_OPENED' === this.tournament.getStatus();
			}
		}
	};
</script>
