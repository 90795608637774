import countries from '../../api/countries'
import PageMeta from '../../dto/page-meta'
import router from "../../routes";

const state = () => ({
	country: null,
	countries: [],
	countriesMeta: null,
});

const getters = {
	country: state => state.country,
	countries: state => state.countries,
	countriesMeta: state => state.countriesMeta
};

const actions = {
	getCountries: async ({commit}, data = {}) => {
		const response = await countries.get(data);

		commit('UPDATE_COUNTRIES', response.data.data);
		commit('UPDATE_COUNTRIES_META', response.data);
	},
	getCountry: async ({commit}, id) => {
		const response = await countries.getCountry(id);

		commit('UPDATE_COUNTRY', response.data.data);
	},
	updateCountry: async ({commit}, {id, data}) => {
		const response = await countries.update(id, data);

		if (response.status === 201 || response.status === 200) {
			router.push('/countries');
		}
	},
};

const mutations = {
	UPDATE_COUNTRY: (state, data) => {
		state.country = data;
	},
	UPDATE_COUNTRIES: (state, data) => {
		state.countries = data;
	},
	UPDATE_COUNTRIES_META: (state, data) => {
		state.countriesMeta = new PageMeta(data.meta);
	},
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
