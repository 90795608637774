<template>
    <div v-if="tournament">
        <div class="table__wrapper">
            <table v-if="participants.length">
                <thead class="text-left">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{$t('ID')}}</th>
                    <th scope="col">{{$t('Name')}}</th>
                    <th scope="col" v-if="participants[0].isType('single')">{{$t('Nick name')}}</th>
                    <th scope="col" v-if="participants[0].isType('single')">{{$t('Email')}}</th>
                    <th scope="col" v-if="participants[0].isType('single')">{{$t('Game ID')}}</th>
                    <th scope="col" v-if="participants[0].isType('team')">{{$t('Max Players')}}</th>
                    <th scope="col" v-if="participants[0].isType('team')">{{$t('Team Mates Count')}}</th>
                    <th scope="col">{{$t('Status')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr :style="{'cursor': (participant.isType('team') ? 'pointer': 'default')}" @click="participant.isType('team') && participant.getTeamId() ? $router.push({ name: 'team', params: { id: participant.getTeamId() } }) : ''" v-for="(participant, index) in participants">
                    <td scope="row">{{index + 1}}</td>
                    <td scope="row" class="row_id">{{participant.getId()}}</td>
                    <td class="row row--with-avatar">
                        <img :src="participant.getUserAvatar()" alt="">
                        <span>{{participant.getName()}}</span>
                    </td>
                    <td class="row" v-if="participant.isType('single')">{{participant.getUserNickName()}}</td>
                    <td class="row row_email" v-if="participant.isType('single')">{{participant.getUserEmail()}}</td>
                    <td class="row" v-if="participant.isType('single')">{{participant.getPlatformId()}}</td>
                    <td class="row" v-if="participant.isType('team')">{{participant.getTeamMatesCount()}}</td>
                    <td class="row" v-if="participant.isType('team')">{{participant.getMaxPlayers()}}</td>
                    <td class="row row_status">{{participant.getStatus()}}</td>
                    <td class="row" v-if="userCanDeleteParticipants(participant)">
                        <a href="#" @click.prevent="deleteParticipant(participant)">
                            <SvgIcon
                                width="16"
                                height="16"
                                :iconType="'delete'"
                            />
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <ChatsListContainer :id="id"/>
        <DeleteParticipantDialog/>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {DELETE_PARTICIPANT_DIALOG} from '../../../constants/dialogs'

import {ExportToCsv} from 'export-to-csv'
import PageLinks from '../../../components/Page/Links'
import SvgIcon from '../../../components/SvgIcon'
import Dropdown from '../../../components/Dropdown'
import ChatsListContainer from '../../../components/Chat/ChatsListContainer'
import DeleteParticipantDialog from '../../../components/Dialogs/DeleteParticipant'
import { SUPER_ADMIN, TOURNAMENT_ADMIN, TOURNAMENT_MANAGER } from "../../../constants/roles";

export default {
    props: ["id"],
    components: {
        SvgIcon,
        Dropdown,
        PageLinks,
        ChatsListContainer,
        DeleteParticipantDialog
    },
    data() {
        return {
            csvOptions: {
                filename: 'participants',
                decimalSeparator: '.',
                fieldSeparator: ',',
                quoteStrings: '"',
                showLabels: true,
                showTitle: true,
                title: 'Participants',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
            }
        }
    },
    computed: {
        ...mapGetters([
            'tournament',
            'tournamentStatus',
            'participants',
            'participantsEmails',
            'getUser'
        ])
    },
    created() {
        this.initData();
    },
    methods: {
        ...mapActions({
            showDialog: 'showDialog',
            getParticipants: 'GET_ROUND_ROBIN_PARTICIPANTS',
            getParticipantsEmails: 'GET_PARTICIPANTS_EMAILS',
            centrifugeConnect: 'centrifugeConnect',
        }),
        async initData() {
            await this.getParticipants({id: this.id});
            await this.centrifugeConnect();
        },
        async exportCsv() {
            if (Object.keys(this.participants).length === 0) return;

            await this.getParticipantsEmails(this.id);

            const csvExporter = new ExportToCsv(this.csvOptions);

            csvExporter.generateCsv(this.participantsEmails);
        },
        getActiveParticipants() {
            const active = this.participants.filter(item => item.getStatus() === 'active' || item.getStatus() !== 'registered');
            return active.length;
        },
        deleteParticipant(participant) {
            this.showDialog({
                type: DELETE_PARTICIPANT_DIALOG,
                participant: participant
            });
        },
        userCanDeleteParticipants(participant) {
            const user = this.getUser;
            const status = this.tournamentStatus;
            const type = this.tournament.getType();
            const userRegistered = 'registered' === participant.getStatus();
            const allowedStatuses = [
                'OPENED',
                'REGISTRATION_OPENED',
                'CHECKIN',
                'REGISTRATION_CLOSED',
            ];
            if (type === 2 && status !== 'FINISHED') return this.hasPermission("Tournament edit", this.id)
            if (type === 1 && !allowedStatuses.includes(status)) return false;

            if (type === 2 && status === 'FINISHED') return false;
            return this.hasPermission("Tournament edit", this.id)
        }
    },
}
</script>
<style scoped lang="scss">
table .row {
    word-break: break-all;
}

@media screen and (max-width: 600px) {
    .table__wrapper {
        max-width: 100%;
        overflow-x: auto;
    }
    table {
        .row {
            min-width: 100px;
            &.row_email {
                min-width: 200px;
            }
            &.row_status {
                min-width: 100px;
            }
        }
        .row_id {
            min-width: 60px;
        }
    }
    table {

    }
}
</style>
