export default (rounds, currentRound) => {
	if (!rounds) {
		return null;
	}

	const currentGames = rounds[currentRound - 1].games

	return currentGames.map(game => {
		return {
			...game,
			title: "round " + currentRound,
			round: currentRound - 1,
			games: [],
			hasParent: false
		};
	});
};
