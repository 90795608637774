export default function Terms( data= null) {
    const info = data;

    this.getId = () => info.id;

    this.getTitle = () => info.title;

    this.getVersion = () => info.version;

    this.getCountry = () => info.country;

    this.getCountryName = () => info.country.value;
}
