import seasons from '../../api/seasons'
import PageMeta from '../../dto/page-meta'
import router from "../../routes";

const state = () => ({
	seasons: null,
	seasonsMeta: null,
	season: null
});

const getters = {
	seasons: state => state.seasons,
	seasonsMeta: state => state.seasonsMeta,
	season: state => state.season
};

const actions = {
	getSeasons: async ({commit}, data = {}) => {
		const response = await seasons.get(data);

		commit('UPDATE_SEASONS', response.data.data);
		commit('UPDATE_SEASONS_META', response.data);
	},
	getSeason: async ({commit}, id) => {
		const response = await seasons.getSeason(id);

		commit('UPDATE_SEASON', response.data.data);
	},
	updateSeason: async ({commit}, {id, data}) => {
		const response = await seasons.update(id, data);

		if (response.status === 201 || response.status === 200) {
			router.push('/seasons');
		}
	},
	addSeason: async ({commit}, data) => {
		const response = await seasons.add(data);

		if (response.status === 201 || response.status === 200) {
			router.push('/seasons');
		}
	},
	deleteSeason: async ({commit}, id) => {
		await seasons.deleteSeason(id);

		const response = await seasons.get();

		commit('UPDATE_SEASONS', response.data.data);
		commit('UPDATE_SEASONS_META', response.data);
	}
};

const mutations = {
	UPDATE_SEASON: (state, data) => {
		state.season = data;
	},
	UPDATE_SEASONS: (state, data) => {
		state.seasons = data;
	},
	UPDATE_SEASONS_META: (state, data) => {
		state.seasonsMeta = new PageMeta(data.meta);
	},
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
