<template>
    <div class="modal">
        <div class="modal-wrapper">
            <div class="modal-overlay"></div>
            <div class="modal-container" :class="{'final': match.is_final}">
                <div class="modal-header">
                    <div class="modal-header__title">
                        <span>Select winner {{ selectWinnersLength }}/{{ places.length }}</span>
                    </div>
                    <div class="modal-header__close" @click="$emit('close')">
                        <svg-icon
                            width="16"
                            height="16"
                            :iconType="'slim'"
                        />
                    </div>
                </div>
                <div class="choose-winner">
                    <div class="winners-wrapper" :class="{'final': match.is_final}">
                        <div class="winners-wrapper__item" v-for="place in places">
                            <label for="">{{ place }}st place</label>
                            <FormMultiSelect
                                v-model="winners[place]"
                                :placeholder="$t('Choose a winner')"
                                :clear="false"
                                :options="participants"
                                :searchable="false"
                                :selectLabel="''"
                                :deselectLabel="'Click for delete'"
                                :label="place + 'st place'">
                                <template slot="singleLabel" slot-scope="props">
                                    <div class="option__participant">
                                        <div class="option__participant-avatar">
                                            <img :src="getAvatar(props.option.participant) ? getAvatar(props.option.participant) : '/images/logo.jpg'" alt="">
                                        </div>
                                        <div class="option__participant-info">
                                            <strong>{{ getNickName(props.option.participant) }}</strong>
                                            <span v-if="props.option.participant.type === 1">Game profile id: {{ props.option.participant.profile.info.id ? props.option.participant.profile.info.id : props.option.participant.profile.info.nickname }}</span>

                                        </div>
                                    </div>
                                </template>
                                <template slot="option" slot-scope="props">

                                    <div class="option__participant">
                                        <div class="option__participant-avatar">
                                            <img :src="getAvatar(props.option.participant) ? getAvatar(props.option.participant) : '/images/logo.jpg'" alt="">
                                        </div>
                                        <div class="option__participant-info">
                                            <strong>{{ getNickName(props.option.participant) }}</strong>
                                            <span v-if="props.option.participant.type === 1">Game profile id: {{ props.option.participant.profile.info.id ? props.option.participant.profile.info.id : props.option.participant.profile.info.nickname }}</span>

                                        </div>
                                    </div>
                                </template>
                            </FormMultiSelect>
                        </div>
                    </div>
                    <div class="modal-header__title title-images">
                        Upload images
                    </div>
                    <div class="results-wrapper">
                        <div class="results-item" v-for="(participant,i) in match.participants" :key="i">
                            <div class="user">{{ getNameByParticipant(participant.participant) }}</div>
                            <div class="image">
                                <div class="empty" v-if="!getResultByParticipant(participant.participant)">
                                    No image
                                </div>
                                <div v-else class="screenshot" @click="screenshotMatch = getResultByParticipant(participant.participant)">
                                    <div class="screenshot-image">
                                        <img :src="getResultByParticipant(participant.participant).image" alt="">
                                    </div>
                                    <div class="screenshot-name">
                                        Click to open
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="choose-winner__buttons">
                        <button
                            @click.prevent="chooseWinner"
                            class="button button-primary"
                            :class="{ disabled: !isComplete }"

                        >
                            {{$t('Approve winner')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <TftScreenshot v-if="screenshotMatch" :participant="screenshotMatch" @close="screenshotMatch = null"></TftScreenshot>

    </div>
</template>
<script>
import FormMultiSelect from 'vue-multiselect'
import SvgIcon from '../../components/SvgIcon'
import tft from '../../api/tft'
import TftScreenshot from "./TftScreenshot";
export default {
    data () {
      return {
          places: [],
          winners: {},
          screenshotMatch: null
      }
    },
    props: {
        tournamentId: {
          type: Number,
          required: true
        },
        match: {
            type: Object,
            required: true
        }
    },
    methods: {
      getResultByParticipant (participant) {
        if (participant.type === 1) {
            return this.match.results.find(item => item.reporter_id === participant.profile.user.id)
        }
        // TODO
        return null
      },
      getNameByParticipant (participant) {
        if (participant.type === 1) {
            if (participant.profile.user.nickname) {
                return participant.profile.user.nickname
            }
            if (participant.profile.user.name) {
                return participant.profile.user.name
            }
            return participant.profile.user.email
        }
        return participant.team.name
      },
      chooseWinner () {
        const winners = {}

        for (const item in this.places) {
            winners[this.places[item]] = this.winners[this.places[item]].participant.id
        }
        tft.finishMatch(this.tournamentId, this.match.id, {
            winners
        }).then(response => {
            if (response.data && response.data.status === 200) {
                this.$emit('update')
                this.$emit('close')
            }
        })
      },
      getAvatar (participant) {
          return participant.type === 2 ? participant.team.logo : participant.profile.user.avatar;
      },
      getNickName(participant) {
          if (participant.type === 2) {
              return participant.team.name
          } else if (participant.type === 1) {
              if (participant.profile.user.nickname) return participant.profile.user.nickname;
              if (participant.profile.user.name) return participant.profile.user.name
              if (participant.profile.user.email) return participant.profile.user.email
          }
          return 'Unknown'
      }
    },
    mounted () {
      if (this.match.is_final) {
          this.places = [1,2,3,4,5,6,7,8]
          this.winners = {
              1: null,
              2: null,
              3: null,
              4: null,
              5: null,
              6: null,
              7: null,
              8: null
          }
      } else {
          this.places = [1,2,3,4]
          this.winners = {
              1: null,
              2: null,
              3: null,
              4: null
          }
      }
    },
    computed: {
      selectWinnersLength () {
        return Object.values(this.winners).filter(item => Boolean(item)).length
      },
      isComplete () {
          for (const item in this.places) {
              if (!this.winners[this.places[item]]) {
                  return false
              }
          }
          return true
      },
      participants () {
          return this.match.participants.filter(item => {
              const places = this.places;
              for (const i in places) {
                  if (this.winners[places[i]] && this.winners[places[i]].participant.id === item.participant.id) {
                      return false
                  }
              }
              return true
          })
      }
    },
    components: {
        TftScreenshot,
        SvgIcon,
        FormMultiSelect
    }
}
</script>
<style scoped lang="scss">
.winners-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
}
::v-deep {
    .multiselect__option--highlight {
        background: rgba(0, 231, 217, 0.5) !important;
        &::after {
            background: rgba(0, 231, 217, 0.5) !important;
        }
    }
    .multiselect__tags {
        padding: 0;
        min-height: 50px;
    }
    .multiselect__placeholder {
        height: 50px;
        display: flex;
        align-items: center;
        margin: 0;
        padding-left: 15px;
    }
    .multiselect__select {
        height: 50px;
    }
    .multiselect__single {
        display: flex;
        align-items: center;
        padding-left: 15px;
        margin-bottom: 0;
        height: 50px;
        .option__participant {
            padding: 0;
        }
    }
}
.option__participant {
    display: flex;
    padding: 5px 15px;
    align-items: center;
    .option__participant-avatar {
        margin-right: 12px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        overflow: hidden;
        border: 1px solid #06C2BC;
        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
        }
    }
    .option__participant-info {
        strong {
            width: 100%;
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-transform: capitalize;
            margin-bottom: 3px;
            color: #1C1C1C;
        }
        span {
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            display: block;
            width: 100%;
            color: #878787;
        }
    }
}
.modal {
    .modal-wrapper {

        .modal-container {
            max-height: 99vh;
            overflow-y: auto;
            &::-webkit-scrollbar {
                background-color: #fff;
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-track {
                background-color: #fff;
            }

            &::-webkit-scrollbar-track:hover {
                background-color: #f4f4f4;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #babac0;
            }
            width: 727px;
            min-width: 727px;
            padding: 25px;
            .modal-header {
                margin-bottom: 25px;
                .modal-header__title  span {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 23px;
                    text-transform: uppercase;

                    color: #06C2BC;
                }
            }
            .choose-winner {
                .choose-winner__buttons {
                    width: 100%;
                    button {
                        width: 100%;
                    }
                }
                label {
                    font-size: 14px;
                    line-height: 16px;
                    color: #06C2BC;
                    margin-bottom: 5px;
                    display: flex;
                }
                .multiselect {
                    cursor: pointer;
                }
                .title-images {
                    margin: 22px 0;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 23px;
                    text-transform: uppercase;
                    color: #06C2BC;
                }
                .results-wrapper {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 25px;
                    .results-item {
                        .user {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 16px;
                            text-transform: capitalize;
                            color: #000000;
                            margin-bottom: 6px;
                        }
                        .image {
                            height: 50px;
                            padding: 6px;
                            border: 1px solid #878787;
                            border-radius: 4px;
                            .empty {
                                height: 100%;
                                padding-left: 9px;
                                display: flex;
                                align-items: center;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 14px;
                                color: #C4C4C4;
                            }
                            .screenshot {
                                background: #ECECEC;
                                cursor: pointer;
                                align-items: center;
                                border-radius: 4px;
                                height: 100%;
                                padding: 2px;
                                display: flex;
                                width: 270px;
                                .screenshot-image {
                                    margin-right: 8px;
                                    width: 67px;
                                    height: 100%;
                                    border-radius: 5px;
                                    overflow: hidden;
                                    img {
                                        max-width: 100%;
                                    }
                                }
                                .screenshot-name {
                                    font-weight: 400;

                                    font-size: 12px;
                                    line-height: 14px;
                                    color: #303030;
                                }
                            }
                        }

                    }
                }
            }

        }
    }
}
</style>
