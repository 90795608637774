<template>
    <div>
        <div class="page__content-header">
            <div>
                <page-links v-bind:links="[
					{
						url: '/teams',
						text: $t('Teams')
					}
				]"/>
            </div>
            <div>
                <div class="input__search">
                    <input
                        v-model="search"
                        type="text"
                        name="search"
                        class="form-input"
                        :placeholder="$t('Search')"
                        required
                    >
                    <svg-icon
                        class="search-icon"
                        width="16"
                        height="16"
                        :iconType="'search'"
                    />
                </div>
            </div>
        </div>
        <div class="table-wrapper">
            <table>
                <thead class="text-left">
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name of the team</th>
                    <th scope="col">Max Players</th>
                    <th scope="col">Team Mates count</th>
                    <th scope="col">Game id</th>
                    <th scope="col">Is locked</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr @click="$router.push({ name: 'team', params: { id: team.id } })" style="cursor: pointer;" v-for="team in teams" :key="team.id">
                    <td>{{ team.id }}</td>
                    <td style="display: flex; align-items: center;">
                        <img style="width: 35px; border-radius: 100%;margin-right: 15px;" :src="team.logo ? team.logo : '/images/logo.jpg'" alt="">
                        <span>{{ team.name }}</span>
                    </td>
                    <td>{{ team.max_players }}</td>
                    <td>{{ team.team_mates_count }}</td>
                    <td>{{ team.game_id }}</td>
                    <td>{{ team.is_locked ? 'Locked' : 'No' }}</td>
                    <td>
                        <button @click.prevent.stop="deleteId = team.id" class="button button-transparent">
                            <SvgIcon
                                width="16"
                                height="16"
                                :iconType="'delete'"
                            />
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <PagePagination
            :current="current_page"
            :total="last_page"
            @change="getTeams"
        />
        <ConfirmDialog v-if="deleteId" :text="'Are you sure you want to delete the team?'" @close="deleteId = null" :callback="deleteTeam"></ConfirmDialog>
    </div>
</template>
<script>
import PagePagination from '../../components/Page/Pagination';
    import PageLinks from '../../components/Page/Links'
    import TeamsService from '../../api/teams'
    import SvgIcon from '../../components/SvgIcon'
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
    export default {
        data () {
            return {
                search: '',
                last_page: 1,
                current_page: 1,
                teams: [],
                deleteId: null
            }
        },
        components: {
            PageLinks,
            PagePagination,
            ConfirmDialog,
            SvgIcon
        },
        watch: {
            search () {
                this.getTeams()
            }
        },
        methods: {
            deleteTeam () {
              TeamsService.deleteTeam(this.deleteId).then(() => {
                  const index = this.teams.findIndex(item => item.id === this.deleteId)
                  if (index > -1) {
                      this.teams.splice(index, 1)
                  }
              }).finally(() => {
                  this.deleteId = null
              })
            },
            getTeams (page = 1) {
                TeamsService.getTeams(`search=${this.search}&page=${page}&limit=30&all=true`).then(response => {
                    this.teams = response.data.data
                    this.last_page = response.data.meta.last_page
                    this.current_page = response.data.meta.current_page
                })
            }
        },
        mounted () {
            this.getTeams()
        }
    }
</script>
<style scoped lang="scss">
    .input__search {
        width: 377px;
        position: relative;
        display: flex;
        align-items: center;
        input {
            width: 100%;
            max-width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            border: 1px solid #878787;
            border-radius: 4px;
            padding: 9px 15px;
        }
        .search-icon {
            display: flex;
            align-items: center;
            right: 15px;
            position: absolute;
        }
    }

    @media screen and (max-width: 575px) {
        .page__content-header {
            div {
                width: 100%;
            }
        }
        table {
            tr {
                td:nth-child(2) {
                    min-width: 200px;
                }
                th:nth-child(2) {
                    min-width: 200px;
                }
            }
        }
        .input__search {
            width: 100%;
        }
    }
</style>
