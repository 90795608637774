import {
	DELETE_PARTICIPANT_DIALOG,
	NOTIFICATION_SENT_DIALOG,
	REVERT_MATCH_DIALOG
} from '../../constants/dialogs'

const state = () => ({
	showParticipantDeleteDialog: false,
	deletedParticipant: {},
	showNotificationSentDialog: false,
	showRevertMatchDialog: false,
	revertMatchDialogId: null,
    revertMatchDialogType: null
});

const getters = {
	showParticipantDeleteDialog: state => state.showParticipantDeleteDialog,
	showNotificationSentDialog: state => state.showNotificationSentDialog,
	showRevertMatchDialog: state => state.showRevertMatchDialog,
	revertMatchDialogId: state => state.revertMatchDialogId,
    revertMatchDialogType: state => state.revertMatchDialogType,
	deletedParticipant: state => state.deletedParticipant
};

const actions = {
	showDialog: ({commit}, data) => {
		commit('UPDATE_DIALOG_STATE', {
			state: true,
			...data
		})
	},
	closeDialog: ({commit}, data) => {
		commit('UPDATE_DIALOG_STATE', {
			state: false,
			...data
		})
	},
};

const mutations = {
	UPDATE_DIALOG_STATE: (state, data) => {
		switch (data.type) {
			case DELETE_PARTICIPANT_DIALOG:
				state.showParticipantDeleteDialog = data.state;
				state.deletedParticipant = data.participant;
				break;
			case NOTIFICATION_SENT_DIALOG:
				state.showNotificationSentDialog = data.state;
				break;
			case REVERT_MATCH_DIALOG:
				state.showRevertMatchDialog = data.state;
                state.revertMatchDialogId = data.match;
                state.revertMatchDialogType = data.tournamentType ? data.tournamentType : null;
				break;
		}
	},
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
