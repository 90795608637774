<template>
	<div class="brackets__schedule-button" v-if="show()">
		<button :disabled="!hasPermission('Tournament edit', tournament.getGameId())" @click="openScheduleDialog" class="button button-primary">{{$t('Update schedule')}}</button>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'

	export default {
		name: "BracketsRoundsScheduleButton",
		computed: {
			...mapGetters({
				tournament: 'tournament',
			})
		},
		methods: {
			...mapActions({
				showScheduleDialog: 'showBracketsScheduleDialog'
			}),
			openScheduleDialog() {
				this.showScheduleDialog(this.tournament.getId());
			},
			show() {
				return 'REGISTRATION_CLOSED' === this.tournament.getStatus()
						|| 'STARTED' === this.tournament.getStatus()
						|| 'IN_PROGRESS' === this.tournament.getStatus();
			}
		}
	};
</script>
