<template>
	<div>
		<div class="page__content-header">
			<div>
				<PageLinks v-bind:links="[
					{
						url: '/advertisements',
						text: $t('All Advertisement')
					}
				]"/>
			</div>
			<div v-if="!isTournamentManager">
				<RouterLink to="/advertisements/add" :class="'button button-primary'">{{$t('Create new ad')}}</RouterLink>
			</div>
		</div>
		<div>
			<div class="table-wrapper">
				<table v-if="advertisements.length">
					<thead class="text-left">
					<tr>
						<th scope="col">{{$t('ID')}}</th>
						<th scope="col">{{$t('Status')}}</th>
						<th scope="col">{{$t('Name')}}</th>
						<th scope="col">{{$t('Games')}}</th>
						<th scope="col">{{$t('Goal')}}</th>
						<th scope="col">{{$t('Clicks')}}</th>
						<th scope="col">{{$t('Impressions')}}</th>
						<th scope="col">{{$t('Performed')}}</th>
                        <th scope="col">{{ $t('Publish Date') }}</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="advertisement in advertisements">
						<td scope="row">
							{{advertisement.id}}
						</td>
						<td scope="row row-40">
							<div class="status-label__wrapper">
								<span class="status-label-active" v-if="advertisement.meta && advertisement.meta.is_active">{{ $t('Active') }}</span>
								<span class="status-label-inactive" v-else>{{ $t('Inactive') }}</span>
								<label class="switch">
									<input
										type="checkbox"
										hidden
										:value="advertisement.meta.is_active"
										id="advertisement.id"
										v-model="advertisement.meta.is_active"
										@change="check(advertisement.id, advertisement)"
									>
									<span class="slider round"></span>
								</label>
							</div>
						</td>
						<td>
							{{advertisement.title}}
						</td>
						<td>
							{{getGamesNames(advertisement.games)}}
						</td>
						<td>
							{{advertisement.goal.total}}
						</td>
						<td>
							{{advertisement.goal.clicked}}
						</td>
						<td>
							<span v-if="advertisement.goal.viewed > 0">
								{{ advertisement.goal.viewed }}
							</span>
							<span v-else>-</span>
						</td>
						<td>
							<span v-if="advertisement.goal.clicked && advertisement.goal.clicked > 0">
								{{ Math.floor((advertisement.goal.clicked/advertisement.goal.total)*100) }}%
							</span>
							<span v-else>-</span>
						</td>
                        <td>
                            {{ getPublishDate(advertisement.created_at) }}
                        </td>
						<td v-if="!isTournamentManager">
							<dropdown
								:data-position="'left'"
								:data-horizontal-aligntment="'left'"
								:data-vertical-aligntment="'top'"
								:toggle-class="'dots-toggle'"
							>
								<SvgIcon
									slot="dropdown-toggle"
									width="23"
									height="5"
									view-box="0 0 23 5"
									:iconType="'dots'"
								/>
								<div slot="dropdown-content">
									<RouterLink
										:class="'dropdown__link'"
										:to="{path: '/advertisements/edit/' +  advertisement.id}"
									>
										<SvgIcon
											width="16"
											height="16"
											:iconType="'edit'"
										/>
										<span>{{$t('Edit')}}</span>
									</RouterLink>
									<a
										href="#"
										class="dropdown__link"
										@click.prevent="deleteAdvertisementItem(advertisement.id)"
									>
										<SvgIcon
											width="16"
											height="16"
											:iconType="'delete'"
										/>
										<span>{{$t('Delete')}}</span>
									</a>
								</div>
							</dropdown>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<PagePagination
				v-if="advertisementsMeta"
				:current="advertisementsMeta.currentPage()"
				:total="advertisementsMeta.lastPage()"
				@change="changePage"
			/>
		</div>
	</div>
</template>

<script>
	import {mapGetters, mapActions, mapMutations} from 'vuex'

	import SvgIcon from '../../components/SvgIcon'
	import Dropdown from '../../components/Dropdown'
	import PageLinks from '../../components/Page/Links'
	import PagePagination from '../../components/Page/Pagination'
	import router from '../../routes'
    import moment from "moment";

	export default {
		components: {
			SvgIcon,
			Dropdown,
			PageLinks,
			PagePagination
		},
		computed: {
			...mapGetters([
				'advertisements',
				'games',
				'advertisementsMeta',
				'isTournamentManager',
				'isSuperAdmin',
				'getGame'
			])
		},
		created() {
			this.init();
		},
		methods: {
			...mapActions({
				getAdvertisements: 'getAdvertisements',
				deleteAdvertisement: 'deleteAdvertisement',
				updateAdvertisementStatus: 'updateAdvertisementStatus',
				getAdvertisement: 'deleteRegion',
				getGames: 'getGames',
			}),
			...mapMutations({
				hideDropdown: 'HIDE_DROPDOWN',
			}),
            getPublishDate (time) {
                const timeZone = new Date().getTimezoneOffset();

                return moment(String(time)).utc().add((timeZone / 60) * -1, 'hours').format('YYYY.MM.D HH:mm');
            },
			async init() {
				await this.getGames()
				await this.getAdvertisements();
			},
			async deleteAdvertisementItem(id) {
				this.hideDropdown();

				await this.deleteAdvertisement(id);
				await this.getAdvertisements({
					page: this.advertisementsMeta.currentPage(),
				});
			},
			changePage(page) {
				this.getAdvertisements({
					page: page,
				});
			},
			goToPage(url) {
				router.push(url)
			},
			getGamesNames(games) {
				if (!games) return '-';
				const gamesNames = [];

				games.forEach(game => {
					const current = this.getGame(game)[0]
                    if (current) {
                        gamesNames.push(current.name)
                    }
				})

				return gamesNames.join(',')
			},
			check(id, data) {
				this.updateAdvertisementStatus({
					id: id,
					data: {
						is_active: data.meta.is_active
					}
				})
			}
		},
	}
</script>
