<template>
    <div class="modal confirm-dialog">
        <div class="modal-wrapper">
            <div class="modal-overlay"></div>
            <div class="modal-container">
                <div class="modal-header">
                    <div class="modal-header__close" @click="$emit('close')">
                        <SvgIcon
                            width="16"
                            height="16"
                            :iconType="'slim'"
                        />
                    </div>
                </div>
                <div class="modal-body">
                    <h4>{{ text }}</h4>
                    <div class="button-group">
                        <button
                            class="button button-primary"
                            @click.prevent="callback(), $emit('close')"
                        >
                            {{$t("Yes, I`m sure")}}
                        </button>
                        <button
                            class="button button-primary"
                            @click.prevent="$emit('close')"
                        >
                            {{$t('Cancel')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SvgIcon from "../SvgIcon";
export default {
    props: {
        text: {
            type: String,
            default: 'You sure ?'
        },
        callback: {
            type: Function,
            required: true
        }
    },
    components: {
        SvgIcon
    },
    name: "ConfirmDialog"
}
</script>
<style scoped lang="scss">
.modal-body h4 {
    font-size: 20px;
}
</style>
