import http from '../utils/http'
import joinUrls from '../utils/join-urls'

export default {
    get(data = {}) {
        return http.get('/terms', data)
    },
    getTerms(id) {
        if (!id) {
            return Promise.reject(new Error('Rule id is required to get rule data'))
        }

        return http.get(joinUrls('/terms', id))
    },
    addTerms(data) {
        return http.postMultipart('/terms', data)
    },
    deleteTerms(id) {
        if (!id) {
            return Promise.reject(new Error('Rule id is required to delete rule'))
        }

        return http.delete(joinUrls('/terms', id))
    },
    updateTerms(id, data) {
        if (!id) {
            return Promise.reject(new Error('Rule id is required to update rule data'))
        }

        return http.putMultipart(joinUrls('/terms', id), data)
    }
}
