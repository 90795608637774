const validationHelpers = {
    validateUserId: (data) => {
        return 2 === data.type ? data.team.creator_id : data.profile.user.id;
    }
};
export function prepare (matches) {
    matches.forEach((round, roundIndex) => {
        if (round.matches) {
            round.matches.forEach((game, gameIndex) => {
                const player1HasParticipant = null !== game.first_participant && null !== game.first_participant.participant;
                const player1ParticipantId = player1HasParticipant ? game.first_participant.participant.id : null;
                const player1UserId = player1HasParticipant ? validationHelpers.validateUserId(game.first_participant.participant) : null;
                const player2HasParticipant = null !== game.second_participant;
                const player2ParticipantId = player2HasParticipant ? game.second_participant.participant.id : null;
                const player2UserId = player2HasParticipant ? validationHelpers.validateUserId(game.second_participant.participant) : null;
                const winnerId = game.winner_id;
                const matchResults = game.results.filter(item => item.match_id === game.id && item.type === 'INT');
                let firstResult = null;
                let secondResult = null;

                if (winnerId) {
                    firstResult = Number(player1ParticipantId === winnerId);
                    secondResult = Number(player2ParticipantId === winnerId);
                }

                matchResults.forEach(result => {
                    switch (result.reporter_id) {
                        case player1UserId :
                            firstResult = result.value_state;
                            break;
                        case player2UserId :
                            secondResult = result.value_state;
                            break;
                    }
                });

                if (player1HasParticipant) {
                    matches[roundIndex].matches[gameIndex].first_participant.result = firstResult;
                }

                if (player2HasParticipant) {
                    matches[roundIndex].matches[gameIndex].second_participant.result = secondResult;
                }
            })
        }
        if (round.groups) {
            round.groups.forEach((game1, gameIndex1) => {
                game1.matches.forEach((game, gameIndex) => {
                    const player1HasParticipant = null !== game.first_participant && null !== game.first_participant.participant;
                    const player1ParticipantId = player1HasParticipant ? game.first_participant.participant.id : null;
                    const player1UserId = player1HasParticipant ? validationHelpers.validateUserId(game.first_participant.participant) : null;
                    const player2HasParticipant = null !== game.second_participant;
                    const player2ParticipantId = player2HasParticipant ? game.second_participant.participant.id : null;
                    const player2UserId = player2HasParticipant ? validationHelpers.validateUserId(game.second_participant.participant) : null;
                    const winnerId = game.winner_id;
                    const matchResults = game.results.filter(item => item.match_id === game.id && item.type === 'INT');
                    let firstResult = null;
                    let secondResult = null;

                    if (winnerId) {
                        firstResult = Number(player1ParticipantId === winnerId);
                        secondResult = Number(player2ParticipantId === winnerId);
                    }

                    matchResults.forEach(result => {
                        switch (result.reporter_id) {
                            case player1UserId :
                                firstResult = result.value_state;
                                break;
                            case player2UserId :
                                secondResult = result.value_state;
                                break;
                        }
                    });

                    if (player1HasParticipant) {
                        matches[roundIndex].groups[gameIndex1].matches[gameIndex].first_participant.result = firstResult;
                    }

                    if (player2HasParticipant) {
                        matches[roundIndex].groups[gameIndex1].matches[gameIndex].second_participant.result = secondResult;
                    }
                })
            })
        }
    });

    return matches
}
