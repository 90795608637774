<template>
  <div v-if="tournament">
    <div class="page__content-header">
      <div>
        <PageLinks :links="[
          {
            url: '/tournament/' + tournament.getId(),
            text: tournament.getTitle()
          },
          {
            url: '/tournaments/' + tournament.getId() + '/brackets',
            text: 2 === tournament.getType() || 5 === tournament.getType() ? $t('Leaderboard') : $t('Brackets')
          },
          {
            url: '/tournaments/' + tournament.getId() + '/participants',
            text: $t('Participants')
          },
          {
            url: '/tournaments/' + tournament.getId() + '/participants/logs',
            text: $t('Logs')
          }
        ]" />
      </div>

    </div>
    <div class="filters__wrapper">
      <div>
        <div class="search__wrapper">
          <label for="">Search:</label>
          <div class="inline_search__wrapper">
            <FormMultiSelect :placeholder="$t('Search on field')" :options="fields" v-model="filters.field"
              :multiple="false" :selectLabel="''"></FormMultiSelect>
            <input type="search" :disabled="!filters.field" placeholder="Search..." v-model="filters.search">

            <FormMultiSelect :placeholder="$t('Role')" :options="roles" v-model="filters.role" :multiple="false"
              :selectLabel="''"></FormMultiSelect>
            <FormMultiSelect :placeholder="$t('Status')" :options="statuses" v-model="filters.status" :multiple="false"
              :selectLabel="''"></FormMultiSelect>
          </div>
          <button class="button button-primary" @click="fetchLogs(1)">Search</button>
          <button class="button button-secondary" type="reset" @click="resetFilters">Reset</button>
        </div>

      </div>
    </div>



    <div v-if="logs" class="table__wrapper">
      <table class="participant-logs">
        <thead class="text-left">
          <tr>
            <th scope="col">{{ $t('Date') }}</th>
            <th scope="col">{{ $t('Player') }}</th>
            <th scope="col">{{ $t('Profile ID') }}</th>
            <th scope="col">{{ $t('User ID') }}</th>
            <th scope="col">{{ $t('Actor ID') }}</th>
            <th scope="col" v-if="logs[0] && logs[0].type == 2">{{ $t('Team') }}</th>
            <th scope="col" v-if="logs[0] && logs[0].type == 2">{{ $t('Role') }}</th>
            <th scope="col">{{ $t('Status') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(log, index) in logs" :key="`log-${index}`">

            <td class="row">{{ log.created_at }}</td>
            <td class="row clickable-span">
              <span @dblclick="filters.field = 'player'; filters.search = log.gamer_unique_id">{{ log.gamer_unique_id
              }}</span>
            </td>
            <td class="row clickable-span">
              <Tooltip>
                <span class="underlined" @dblclick="filters.field = 'profile_id'; filters.search = log.profile_id">{{
                  log.profile_id }}</span>
                <template v-slot:tooltip-content>
                  <pre class="pre-data">
                    {{ log.profile }}
                  </pre>
                </template>
              </Tooltip>
            </td>
            <td class="row clickable-span">
              <Tooltip>
                <span class="underlined" @dblclick="filters.field = 'user_id'; filters.search = log.user_id">{{
                  log.user_id }}</span>
                <template v-slot:tooltip-content>
                  <pre class="pre-data">
                    {{ log.user }}
                  </pre>
                </template>
              </Tooltip>
            </td>
            <td class="row clickable-span">
              <Tooltip>
                <span class="underlined" @dblclick="filters.field = 'actor_id'; filters.search = log.actor_id">{{
                  log.actor_id }}</span>
                <template v-slot:tooltip-content>
                  <pre class="pre-data">
                    {{ log.actor }}
                  </pre>
                </template>
              </Tooltip>
            </td>
            <td class="row clickable-span" v-if="log.type == 2">
              <span @dblclick="filters.field = 'team_name'; filters.search = log.team_name">{{ log.team_name }}</span>
              <br>
              <Tooltip>
                <span class="underlined" @dblclick="filters.field = 'team_id'; filters.search = log.team_id">{{
                  log.team_id }}</span>
                <template v-slot:tooltip-content>
                  <pre class="pre-data">
                    {{ log.team }}
                  </pre>
                </template>
              </Tooltip>
            </td>
            <td class="row clickable-span" v-if="log.type == 2">
              <span @dblclick="filters.role = log.role">{{ log.role }}</span>
            </td>
            <td class="row clickable-span">
              <span @dblclick="filters.status = log.status">{{ log.status }}</span>

            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button class="button" @click="fetchLogs(page - 1)" :disabled="page === 1">Previous</button>
        <label>{{ page }} / {{ totalPages }}</label>
        <button  class="button"  @click="fetchLogs(page + 1)" :disabled="page === totalPages">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import Observer from 'vue-intersection-observer'
import FormMultiSelect from 'vue-multiselect'
import { mapGetters, mapActions } from 'vuex'
import http from '../../utils/http'
import PageLinks from '../../components/Page/Links'
import SvgIcon from '../../components/SvgIcon'
import Dropdown from '../../components/Dropdown'
import Tooltip from '../../components/Tooltip'

import _ from 'lodash'
export default {
  props: ["id"],
  components: {
    SvgIcon,
    Dropdown,
    Observer,
    PageLinks,
    Tooltip,
    FormMultiSelect
  },
  data() {
    return {
      logs: [],
      currentPage: 1,
      blockLoad: false,
      stopObserver: false,
      limit: 10,
      page: 1,
      perPage: 10, // Numero di elementi per pagina
      totalPages: 1,
      statuses: ['registered', 'checkin', 'deleted'],
      roles: ['captain', 'co-captain', 'player', 'substitute'],
      fields: ['profile_id', 'user_id', 'player', 'team_name', 'team_id', 'actor_id'],
      filters: {
        search: '',
        status: null,
        role: null,
        field: null,
        // sort: {
        //   orderBy: 'status',
        //   dir: 'DESC',
        //   name: 'Relevant first (status)'
        // }
      },

    }
  },
  computed: {
    ...mapGetters([
      'tournament',
      'tournamentStatus',
      'participants',
      'getUser'
    ])
  },
  created() {
    this.getTournament(this.id);
    this.fetchLogs(1);
  },
  methods: {
    ...mapActions({
      showDialog: 'showDialog',
      getTournament: 'GET_TOURNAMENT',
      centrifugeConnect: 'centrifugeConnect',
    }),
    resetFilters() {
      this.filters = {
        search: '',
        status: null,
        role: null,
        field: null
      }
    },
    async fetchLogs(page) {
      // Esegui una chiamata API Axios per ottenere i dati paginati
      try {
        const _filters = _.pickBy(this.filters, _.identity);
        const { data } = await http.get(`/tournaments/${this.id}/participants/logs`, { page, filters: _filters });
        this.logs = data.data.map(e => {
          delete e?.user?.assignGames
          delete e?.user?.permissions
          delete e?.user?.timezone
          delete e?.user?.wallets
          delete e?.user?.avatar
          delete e?.user?.id
          delete e?.profile?.info?.puuid
          delete e?.profile?.id
          delete e?.team?.id
          delete e?.team?.description
          delete e?.team?.slot
          delete e?.team?.role
          delete e?.team?.is_locked
          delete e?.team?.logo
          return e
        });
        this.totalPages = data.meta.last_page;
        this.page = page;
      } catch (error) {
        console.error(error);
      }
    },

  }


}
</script>
<style scoped lang="scss">
table .row {
  word-break: break-all;
}

.send_message {
  margin: 0 15px;
}

.search__wrapper {
  width: 100%;
  margin: 15px 0;

  label {
    display: block;
    width: 100%;
    color: #00E7DF;
    margin-bottom: 5px;
    font-size: 14px;
  }

  input {
    width: 100%;
    padding: 10px 15px;
    outline: none;
    border: 1px solid rgba(173, 173, 173, 0.4);
    border-radius: 5px;
    transition: 0.1s;

    &:focus {
      border: 1px solid #00E7DF;
    }

    &:focus-within {
      border: 1px solid #00E7DF;
    }
  }
}

@media screen and (max-width: 992px) {
  .search__wrapper {
    width: 100%;

    input {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .table__wrapper {
    max-width: 100%;
    overflow-x: auto;
  }

  table {
    .row {
      min-width: 100px;

      &.row_email {
        min-width: 200px;
      }

      &.row_status {
        min-width: 100px;
      }
    }

    .row_id {
      min-width: 60px;
    }
  }


}

table.participant-logs {
  width: 100%;
  border-spacing: 0 2px;
  max-width: none;
  font-size: 12px;

  tbody {
    font-size: 13px;

    tr {
      box-shadow: none;
      border-radius: 0px;
      background-color: #fff;
      overflow: hidden;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      td {
        max-width: 100px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-bottom: 1px solid #e0e0e0 !important;
        border-radius: 0;
        text-align: center;
      }
    }

  }

  tr th {
    text-align: center;
  }

  tr td:first-child,
  tr th:first-child {
    min-width: 50px;
    text-align: left;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.clickable-span {
  position: relative;

  span {
    position: relative;
    cursor: pointer;
    border-radius: 3px;
    display: inline-block;
    padding: 2px 5px;

    &:hover {
      background-color: #ccf4f2;
    }

    &.underlined {
      text-decoration: underline;
    }
  }
}

.inline_search__wrapper {
  display: flex;
  margin-bottom: 15px;

  >* {
    margin-right: 10px;
  }

}

.pre-data {
  font-size: 10px;
  text-align: left;
}
.pagination {
  display: flex;
    justify-content: center;
    align-items: center;
    label {
      display: inline-block;
      padding: 0 10px;
      text-align: center;
    }
}
</style>
