
import templates from '../../api/room-templates'
import PageMeta from "../../dto/page-meta";
import Template from "../../dto/room-template";

import router from '../../routes'

const state = () => ({
    roomTemplates: null,
    roomTemplatesMeta: null
});

const getters = {
    roomTemplates: state => state.roomTemplates,
    roomTemplatesMeta: state => state.notificationsMeta
};

const actions = {
    getRoomTemplates: async ({commit}, data = {}) => {
        const response = await templates.get(data);

        commit('UPDATE_ROOM_TEMPLATES', response.data.data);
        commit('UPDATE_ROOM_TEMPLATES_META', response.data);
    },
    getRoomTemplate: async ({commit}, id) => {
        const response = await templates.getItem(id);
        return response.data;
    },
    addRoomTemplate: async ({commit}, data) => {
        const response = await templates.add(data);

        if (response.status === 201) {
            router.push('/room-templates');
        }
    },
    updateRoomTemplate: async ({commit}, data) => {
        const response = await templates.update(data.id, data.data);

        if (response.status === 200) {
            router.push('/room-templates');
        }
    },
    deleteRoomTemplate: async ({commit, dispatch}, id) => {
        await templates.delete(id);

        dispatch('getTemplates');
    },
};

const mutations = {
    UPDATE_ROOM_TEMPLATES: (state, data) => {
        const templates = [];

        data.forEach(item => {
            templates.push(new Template(item));
        });

        state.roomTemplates = templates;
    },
    UPDATE_ROOM_TEMPLATES_META: (state, data) => {
        state.roomTemplatesMeta = new PageMeta(data);
    }
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
