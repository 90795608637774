export default function (url, data) {
	if (!data) {
		return url;
	}

	let params = '';

	if (typeof data === 'object') {
		Object.keys(data).forEach((key, index) => {
			if (index > 0) {params += '&'}
			params += `${key}=${data[key]}`;
		});
	}

	if (typeof data === 'string') {
		params = data;
	}

	return url + '?' + params;
}