<template>
    <div class="withdraw__page" v-if="form && form.user">
        <div class="user__section">
            <div class="user__section-user">
                <div class="user__section-user_avatar">
                    <img :src="form.user.avatar" alt="">
                </div>
                <div class="user__section-user_name">
                    {{ form.user.name ? form.user.name : form.user.nickname }}
                </div>
            </div>
            <div class="user__section-conversation" ref="messages">
                <div class="start__message">
                    <em>Withdraw request</em>
                    <div class="amount">
                        <span>{{ parseFloat(form.amount).toFixed(2) }}</span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#018786"/>
                            <path d="M8.00422 14.0706C11.3547 14.0706 14.0709 11.3545 14.0709 8.00394C14.0709 4.65342 11.3547 1.93729 8.00422 1.93729C4.65369 1.93729 1.93756 4.65342 1.93756 8.00394C1.93756 11.3545 4.65369 14.0706 8.00422 14.0706Z" fill="#00E7DF"/>
                            <path d="M11.3015 10.0131C11.1905 9.97596 10.4503 9.71612 10.4503 9.71612C8.89579 9.0294 8.34062 8.58396 7.6189 7.87869C7.67442 8.99228 8.41464 9.45628 8.41464 9.45628H7.23028C5.6573 9.45628 5.67581 7.87869 6.17546 7.11773C6.91568 6.05982 8.06303 6.26397 8.08154 6.26397C7.41534 6.26397 6.89718 6.57949 6.54557 6.96925C6.54557 6.96925 6.78614 6.83933 7.35982 6.80221C7.78545 6.76509 8.80326 6.78365 9.19188 6.82077C9.33992 6.83933 9.72854 6.91357 9.98762 7.09917C11.0424 7.84157 10.8574 9.04796 10.8574 9.04796C10.8204 8.08285 10.3577 7.69309 10.3392 7.69309C10.5798 8.76956 9.9321 9.14076 9.9321 9.14076C9.9321 9.14076 10.9684 9.69756 11.4311 9.4006C12.6709 8.50972 11.9492 6.43101 11.9307 6.37533C12.0047 6.76509 11.9122 7.26621 11.8937 7.26621C11.3015 5.24318 9.71003 5.1875 9.71003 5.1875C11.3015 5.96702 11.0424 7.35901 11.0424 7.37757C10.9314 5.39166 9.04383 5.26174 9.04383 5.26174C9.33992 5.41022 9.43245 5.4659 9.43245 5.4659C5.04662 4.77918 5.13914 7.28477 5.15765 7.48893C4.9911 6.44957 5.69431 5.79998 5.69431 5.78142C3.76973 6.59805 4.30639 8.67676 4.30639 8.67676C4.02881 8.45404 3.86226 8.21277 3.84375 8.21277C4.6765 10.1059 6.21247 10.0131 6.19396 10.0131C5.25018 10.1244 4.62099 9.56764 4.62099 9.54908C5.50926 10.904 7.41534 10.8112 7.41534 10.8112H12.1158C12.1158 10.8112 11.7457 10.31 11.6531 10.1801C11.5606 10.0502 11.4126 10.0316 11.3015 10.0131ZM9.48797 9.75324L10.3947 10.0502C10.3947 10.0502 9.72854 10.2172 9.48797 9.75324ZM11.0424 10.1801C11.357 10.1987 11.5236 10.2358 11.5236 10.2358L11.6161 10.3657C11.6161 10.3657 11.246 10.2358 11.0424 10.1801Z" fill="#121212"/>
                        </svg>
                        <span>&nbsp;({{ form.wallet.currency }})</span>
                    </div>
                    <em style="margin-top: 10px;">Message</em>
                    <div class="amount">
                        <span>{{ form.message }}</span>
                    </div>
                </div>
                <div class="message" :class="{'right': (message.user.roles && message.user.roles.length > 0) || message.user.is_admin}" v-for="message in messages.filter(item => item.message_type !==  2)" :key="message.id">
                    <div class="message__avatar">
                        <img :src="message.user.avatar ? message.user.avatar : '/images/logo.jpg'" alt="">
                    </div>
                    <div class="message__info">
                        <div class="message__info-author">
                            <template v-if="!message.user.roles || !message.user.roles.length && !message.user.is_admin">
                                {{ message.user.name ? message.user.name : message.user.nickname }}
                            </template>
                            <template v-else>
                                Admin
                            </template>
                        </div>
                        <div class="message__info-text">
                            <template v-if="message.message_type === 0">
                                {{ message.message }}
                            </template>
                            <template v-else>
                                <img :src="message.message" alt="">
                            </template>
                        </div>
                        <div class="message__info-time">
                            {{ getTime(message.created_at) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="user__section-area">
                <div class="user__section-area_input">
                    <input type="text" @keyup.enter="send" v-model="message" placeholder="Message">
                </div>
                <button :disabled="!message" @click="send" class="user__section-area_button">
                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.1046 9.19948L1.51647 0.161148C0.666238 -0.231165 -0.208564 0.655619 0.195282 1.50043L3.42912 8.26535C3.58577 8.59305 3.90778 8.81004 4.27032 8.8322L14.6928 9.4693C14.9751 9.48655 15.1951 9.72046 15.1951 10.0032C15.1951 10.2833 14.979 10.516 14.6997 10.5367L4.28249 11.3075C3.91355 11.3348 3.58983 11.5635 3.4409 11.9022L0.110974 19.4738C-0.265443 20.3297 0.634025 21.188 1.47136 20.7719L21.1306 11.003C21.8826 10.6293 21.867 9.55129 21.1046 9.19948Z" fill="#00E7DF"/>
                    </svg>
                </button>
            </div>
        </div>
        <div class="info__section">
            <div class="user__block-form">
                <div class="user__block-form_group">
                    <label for="role">Status</label>
                    <multiselect :disabled="form.status === 'PAID' || form.status === 'CANCELED'" :value="form.status" placeholder="Select status" @input="update" :allowEmpty="false" :clear="false" :searchable="false" id="role" :options="[
                        'REQUESTED',
                        'PENDING',
                        'PAID',
                        'CANCELED'
                    ]" :multiple="false">
                        <template slot="singleLabel" slot-scope="{ option }">
                            <div class="custom__option" :class="[`status-${option}`]">{{ option }}</div>
                        </template>
                    </multiselect>
                </div>
                <div class="user__block-form_group">
                    <label for="name">Currency</label>
                    <div class="input" v-if="form.currency">
                        <input v-model="form.currency.name" ref="name" disabled type="text" placeholder="Id">
                    </div>
                </div>
                <div class="user__block-form_group">
                    <label for="name">#ID</label>
                    <div class="input">
                        <input v-model="form.id" ref="name" disabled type="text" id="name" placeholder="Id">
                    </div>
                </div>
                <div class="user__block-form_group">
                    <label for="name">Name</label>
                    <div class="input">
                        <input v-model="form.user.name" ref="name" disabled type="text" id="name" placeholder="Name">
                    </div>
                </div>
                <div class="user__block-form_group">
                    <label for="nickname">Nickname</label>
                    <div class="input">
                        <input placeholder="Nickname" value="Vitaliy" v-model="form.user.nickname" ref="nickname" disabled type="text" id="nickname">
                    </div>
                </div>
                <div class="user__block-form_group">
                    <label for="nickname">Email</label>
                    <div class="input">
                        <input v-model="form.user.email" ref="email" disabled type="email" id="email">
                    </div>
                </div>
                <div class="user__block-form_group">
                    <label for="nickname">Current balance</label>
                    <div class="input">
                        <input value="" ref="birthday" :value="parseFloat(form.wallet.value).toFixed(2)" disabled type="text" id="birthday">
                    </div>
                </div>
                <div class="user__block-form_group">
                    <label for="withr">Withdraw request</label>
                    <div class="input">
                        <input value="" ref="withr" v-model="form.amount" disabled type="text" id="withr">
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import {mapActions, mapGetters} from 'vuex'
import WithdrawService from '../../api/wallets'
import moment from "moment";
export default {
    props: {
        id: {
            required: true
        }
    },
    data () {
        return {
            message: null,
            status: null,
            messages: [],
            form: null
        }
    },
    computed: {
        ...mapGetters({
            centrifugeInstance: 'centrifugeInstance',
        })
    },
    methods: {
        ...mapActions({
            centrifugeConnect: 'centrifugeConnect',
        }),
        update (event) {
            WithdrawService.update(Number(this.id), {
                status: event
            }).then((r) => {
                if (r.response && r.response.data && r.response.data.status !== 200) {
                    this.$notify({
                        type: 'error',
                        title: 'Update error',
                        group: 'notify',
                        text: r.response.data.message ? r.response.data.message : 'Unknown error'
                    })
                } else {
                    this.form.wallet.value = parseFloat(this.form.wallet.value - this.form.amount).toFixed(2);
                    this.form.status = event;
                }
            })
        },
        toBottom () {
            const id = `messages`;
            const target = this.$refs[id];

            if (this.isElementScrolledBottom(target)) this.scrollChatToEnd();
        },
        scrollChatToEnd() {
            setTimeout(() => {
                const id = `messages`;
                const target = this.$refs[id];

                if (target) target.scrollTop = target.scrollHeight;
            }, 0);
        },
        isElementScrolledBottom(element) {
            if (!element || !element.scrollHeight)  return;

            return element.scrollHeight <= element.scrollTop + element.offsetHeight;
        },
        getTime (time) {
            const timeZone = new Date().getTimezoneOffset();

            return moment(String(time)).utc().add((timeZone / 60) * -1, 'hours').format('DD.MM.YYYY HH:mm');
        },
        getMessages () {
            WithdrawService.getMessages(this.form.room_id).then(response => {
                this.messages = response.data.data.reverse()
                this.$nextTick(() => {
                    this.scrollChatToEnd()
                })
            })
        },
        subscribeChannel () {
            const channel = this.form.room_id
            if (this.centrifugeInstance) {
                this.centrifugeInstance.subscribe(channel, (data) => {
                    if (!Array.isArray(this.messages)) {
                        this.messages = []
                    }
                    this.messages.push(data.data)
                    this.scrollChatToEnd()
                })
            }
        },
        send () {
            if (!this.message) return
          WithdrawService.sendMessage(this.form.room_id, {
              message: this.message
          }).then(() => {
              this.message = null
          }).catch(err => {
              this.$notify({
                  type: 'error',
                  title: 'Error sent',
                  group: 'notify',
                  text: err.response.data.message ? err.response.data.message : 'Unknown error'
              })
          })
        },
        getWithdraw () {
            WithdrawService.getEntity(Number(this.id)).then(response => {
                this.form = response.data
                this.subscribeChannel()
                this.getMessages();
            })
        }
    },
    async mounted () {
        if (this.id) {
            await this.getWithdraw();
            await this.centrifugeConnect()
        }
    },
    components: {
        Multiselect
    }
}
</script>
<style scoped lang="scss">
::v-deep {
    .multiselect__tags {
        padding: 6px !important
    }
    .multiselect__single {
        padding-left: 0;
        margin-bottom: 0;
    }

    .custom__option {
        text-transform: capitalize;
        padding: 6px 10px;
        background: #06C2BC;
        border-radius: 3px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
        width: fit-content;
        &.status-REQUESTED {
            background: #00E7DF;
        }
        &.status-PENDING {
            background: #06C2BC;
            color: #fff;
        }
        &.status-PAID {
            background: #C4C4C4;
        }
        &.status-CANCELED {
            background: red;
        }
    }
}
.withdraw__page {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    .user__section {
        height: 80vh;
        width: 377px;
        min-width: 377px;
        background: #FAFAFA;
        box-shadow: 0px 0px 4px rgba(0, 80, 77, 0.1), 0px 2px 10px rgba(0, 80, 77, 0.25);
        border-radius: 2px;
        margin-right: 25px;
        display: flex;
        flex-direction: column;
        .user__section-user {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid #ECECEC;
            .user__section-user_avatar {
                width: 40px;
                height: 40px;
                border-radius: 100%;
                background: #2F2F2F;
                border: 1px solid #505050;
                border-radius: 29px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 100%;
                }
            }
            .user__section-user_name {
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                text-transform: uppercase;
                color: #303030;
            }
        }
        .user__section-conversation {
            flex-grow: 1;
            padding: 0 20px;
            padding-top: 20px;
            overflow: hidden;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            font-family: Roboto;
            &::-webkit-scrollbar {
                width: 4px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #06C2BC;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
            .start__message {
                margin-bottom: 15px;
                background: #ECECEC;
                border: 1px dashed #505050;
                padding: 10px;
                margin-top: auto;
                border-radius: 5px;
                em {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    color: #06C2BC;
                    display: block;
                    width: 100%;
                    margin-bottom: 9px;
                }
                .amount {
                    display: flex;
                    align-items: center;
                    span {
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 16px;
                        color: #303030;
                        margin-right: 4px;
                    }
                }
            }
            .message {
                width: 90%;
                display: flex;
                align-items: flex-start;

                .message__avatar {
                    margin-top: 10px;
                    background: #2F2F2F;
                    border: 1px solid #505050;
                    border-radius: 100%;
                    overflow: hidden;
                    margin-right: 7px;
                    width: 32px;
                    height: 32px;
                    min-width: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        max-width: 100%;
                    }
                }
                .message__info {
                    flex-grow: 1;
                    &::after {
                        content: "";
                        display: block;
                        width: 7px;
                        height: 7px;
                        background: #ECECEC;
                        border: 1px solid #505050;
                        position: absolute;
                        left: -5px;
                        top: 22px;
                        transform: rotate(
                                45deg
                        );
                        border-right: none;
                        border-top: none;
                    }
                    position: relative;
                    background: #ECECEC;
                    border: 1px solid #505050;
                    padding: 0 12px;
                    padding-top: 4px;
                    padding-bottom: 10px;
                    border-radius: 5px;
                    .message__info-author {
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 16px;
                        white-space: nowrap;
                        margin-bottom: 4px;
                        width: 100%;
                        display: block;
                        letter-spacing: 0.4px;
                        color: #878787;
                    }
                    .message__info-text {
                        font-size: 12px;
                        font-family: Roboto;
                        padding-right: 25px;
                        line-height: 14px;
                        color: #303030;
                        img {
                            max-width: 90%;
                            margin-bottom: 15px;
                            display: block;
                            max-height: 500px;
                        }
                    }
                    .message__info-time {
                        font-size: 8px;
                        line-height: 16px;
                        letter-spacing: 0.4px;
                        color: #878787;
                        position: absolute;
                        right: 3px;
                        bottom: 0;
                    }
                }

                &.right {
                    flex-direction: row-reverse;
                    margin-left: auto;
                    .message__avatar {
                        margin-left: 7px;
                        margin-right: 0;
                    }
                    .message__info {
                        &::after {
                            right: -5px;
                            left: unset;
                            transform: rotate(
                                    45deg
                            );
                            border-right: 1px solid #505050;
                            border-top: 1px solid #505050;
                            border-bottom: none;
                            border-left: none;
                        }
                    }
                }
            }
            .message + .message {
                margin-top: 15px;
            }
        }
        .user__section-area {
            padding: 15px 20px;
            display: flex;
            .user__section-area_input {
                margin-right: 15px;
                flex-grow: 1;
                input {
                    background: #FAFAFA;
                    border: 1px solid #878787;
                    border-radius: 4px;
                    padding: 14px 13px;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    color: #00E7DF;
                    &::placeholder {
                        color: #8F8F8F;
                    }
                    outline: none;
                    overflow: hidden;
                    width: 100%;
                }
            }
            .user__section-area_button {
                width: 48px;
                min-width: 48px;
                height: 48px;
                background: #FAFAFA;
                border: 1px solid #878787;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                outline: none;
                border-radius: 4px;
                &[disabled] {
                    opacity: 0.8;
                    cursor: default;
                }
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
    .info__section {
        width: 377px;
        .user__block-form {
            width: 100%;
            .user__block-form_group {
                width: 100%;

                label {
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 6px;
                    display: block;
                    font-family: Roboto;
                    color: #06C2BC;
                }
                .input {
                    align-items: center;
                    position: relative;
                    width: 100%;
                    input {
                        background: #ECECEC;
                        border-radius: 4px;
                        border: none;
                        outline: none;
                        width: 100%;
                        padding: 11px 15px;
                        color: #303030;
                        font-weight: bold;
                        font-family: Roboto;
                        &:focus {
                            border: 1px solid #00E7DF
                        }
                    }
                    .input__edit {
                        position: absolute;
                        right: 13px;
                        top: calc(50% - 10px);
                        cursor: pointer;
                    }
                }
                &.balances {
                    padding: 15px;
                    background: gray;
                    border: 6px solid #06c2af;
                    background: #f3f3f3;
                    input {
                        font-size: 16px;
                    }
                    .input + .input {
                        margin-top: 15px;

                    }
                    label {
                        font-weight: bold;
                        font-size: 16px;
                        text-transform: uppercase;
                        margin-bottom: 15px;
                    }
                }
            }
            .user__block-form_group + .user__block-form_group {
                margin-top: 15px;
            }

        }
    }

}
</style>
