var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page__content-header"},[_c('div',[_c('page-links',{attrs:{"links":[
					{
						url: '/teams',
						text: _vm.$t('Teams')
					}
				]}})],1),_c('div',[_c('div',{staticClass:"input__search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-input",attrs:{"type":"text","name":"search","placeholder":_vm.$t('Search'),"required":""},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_c('svg-icon',{staticClass:"search-icon",attrs:{"width":"16","height":"16","iconType":'search'}})],1)])]),_c('div',{staticClass:"table-wrapper"},[_c('table',[_vm._m(0),_c('tbody',_vm._l((_vm.teams),function(team){return _c('tr',{key:team.id,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'team', params: { id: team.id } })}}},[_c('td',[_vm._v(_vm._s(team.id))]),_c('td',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"35px","border-radius":"100%","margin-right":"15px"},attrs:{"src":team.logo ? team.logo : '/images/logo.jpg',"alt":""}}),_c('span',[_vm._v(_vm._s(team.name))])]),_c('td',[_vm._v(_vm._s(team.max_players))]),_c('td',[_vm._v(_vm._s(team.team_mates_count))]),_c('td',[_vm._v(_vm._s(team.game_id))]),_c('td',[_vm._v(_vm._s(team.is_locked ? 'Locked' : 'No'))]),_c('td',[_c('button',{staticClass:"button button-transparent",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.deleteId = team.id}}},[_c('SvgIcon',{attrs:{"width":"16","height":"16","iconType":'delete'}})],1)])])}),0)])]),_c('PagePagination',{attrs:{"current":_vm.current_page,"total":_vm.last_page},on:{"change":_vm.getTeams}}),(_vm.deleteId)?_c('ConfirmDialog',{attrs:{"text":'Are you sure you want to delete the team?',"callback":_vm.deleteTeam},on:{"close":function($event){_vm.deleteId = null}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"text-left"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("ID")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Name of the team")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Max Players")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Team Mates count")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Game id")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Is locked")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Actions")])])])
}]

export { render, staticRenderFns }