import notifications from '../../api/notifications'
import users from '../../api/users'
import PageMeta from "../../dto/page-meta";
import Notification from "../../dto/notifications";
import http from '@/utils/http';

const state = () => ({
	notifications: null,
	notificationUsers: [],
	notificationsMeta: null
});

const getters = {
	notifications: state => state.notifications,
	notificationUsers: state => state.notificationUsers,
	notificationsMeta: state => state.notificationsMeta
};

const actions = {
	getNotificationUsers: async ({commit}, data = {}) => {
		const response = await users.get(data);

		if (response.status === 200) {
			commit('UPDATE_NOTIFICATION_USERS', response.data.data);
		}
	},
	GET_NOTIFICATIONS: ({commit}, data = {}) => {
		return new Promise((resolve, reject) => {
			notifications.get(data)
				.then((resp) => {
					commit('UPDATE_NOTIFICATIONS', resp.data.data);
					commit('UPDATE_NOTIFICATIONS_META', resp.data);
					resolve(resp);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	ADD_NOTIFICATION: ({commit}, data) => {
		return new Promise((resolve, reject) => {
			if (data.notification_type === 'INBOX_MESSAGE') {
				notifications.sendMessage(data)
					.then((resp) => {
						resolve(resp);
					})
					.catch(err => {
						reject(err)
					});
			} else {
				notifications.addNotification(data)
					.then((resp) => {
						resolve(resp);
					})
					.catch(err => {
						reject(err)
					});
			}
		})
	},
	SEND_NOTIFICATIONS:  ({commit}, data) => {
		return new Promise((resolve, reject) => {
			http.post('v2/notifications', data).then((resp) => {
				resolve(resp);
			})
			.catch(err => {
				reject(err)
			});
		})
	}

};

const mutations = {
	UPDATE_NOTIFICATIONS: (state, data) => {
		let items = [];

		data.forEach(item => {
			items.push(new Notification(item));
		});

		state.notifications = items;
	},
	UPDATE_NOTIFICATION_USERS: (state, data) => {
		state.notificationUsers = data;
	},
	UPDATE_NOTIFICATIONS_META: (state, data) => {
		state.notificationsMeta = new PageMeta(data);
	}
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}