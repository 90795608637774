<template>
	<div>
		<PageLinks :links="[
			{
				url: id ? '/advertisements/edit/' +  id : '/advertisements/add',
				text: id ? $t('Edit Advertisement') : $t('Add Advertisement'),
				classes: 'active'
			}
		]"/>
		<form class="form">
			<div class="form-group text-center">
				<ImagePicker
					:image="form.image"
					:label="$t('Add banner')"
					:formErrors="errors.image"
					:onChangeImage="onChangeImage"
					:onRemoveImage="onRemoveImage"
				/>
			</div>
			<div class="form-group">
				<FormInput
					:name="'title'"
					:label="$t('Add name')"
					:placeholder="$t('Name')"
					:value="form.title"
					:formErrors="errors.title"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group">
				<FormInput
					:name="'link'"
					:label="$t('Add link')"
					:placeholder="$t('Link')"
					:value="form.link"
					:formErrors="errors.link"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group form-group-2-cols">
				<div class="form-group__item">
					<FormSelect
						:name="'goal_type'"
						:label="$t('Metrics')"
						:value="form.goal_type"
						:options="goalTypes"
						:formErrors="errors.goal_type"
						:onChange="formFieldChanged"
					/>
				</div>
				<div class="form-group__item">
					<FormNumber
						:name="'goal'"
						:label="$t('Goal')"
						:value="form.goal"
						:formErrors="errors.goal"
						:onChange="formFieldChanged"
						:min="0"
					/>
				</div>
			</div>
			<div class="form-group">
				<FormSelect
					:name="'locationType'"
					:label="$t('Location type')"
					:value="locationType"
					:options="locationTypeOptions"
					:onChange="locationChanged"
				/>
			</div>
			<div class="form-group" v-if="locationType === 'region'">
				<FormSelect
					:name="'region_id'"
					:label="$t('Region')"
					:value="form.region_id"
					:options="regionsOptions"
					:formErrors="errors.region_id"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group" v-if="locationType === 'country' && form.countries">
				<span class="form-label">{{$t('Choose countries')}}</span>
				<FormMultiSelect
					v-model="form.countries"
					:placeholder="$t('Countries')"
					:options="countries"
					:loading="multiSelectOptions.loading"
					:label="multiSelectOptions.label"
					:track-by="multiSelectOptions.trackBy"
					:multiple="multiSelectOptions.multiple"
					:taggable="multiSelectOptions.taggable"
					@search-change="onSearchChange"
				/>
				<FormNotification :errors="errors.countries"/>
			</div>
			<div class="form-group" v-if="form.platforms">
				<span class="form-label">{{$t('Platform')}}</span>
				<div class="form-platform-select">
					<label class="form-platform-select-item">
						<input
							type="checkbox"
							hidden
							:value="form.platforms[0].value"
							v-model="form.platforms[0].value"
						>
						<span class="icon">
							<SvgIcon
								width="32"
								height="26"
								:viewBox="'0 0 32 26'"
								:iconType="'ps'"
							/>
						</span>
					</label>
					<label class="form-platform-select-item">
						<input
							type="checkbox"
							hidden
							:value="form.platforms[1].value"
							v-model="form.platforms[1].value"
						>
						<span class="icon">
							<SvgIcon
								width="26"
								height="26"
								:viewBox="'0 0 26 26'"
								:iconType="'xbox'"
							/>
						</span>
					</label>
					<label class="form-platform-select-item">
						<input
							type="checkbox"
							hidden
							:value="form.platforms[2].value"
							v-model="form.platforms[2].value"
						>
						<span class="icon">
							<SvgIcon
								width="27"
								height="26"
								:viewBox="'0 0 27 26'"
								:iconType="'pc'"
							/>
						</span>
					</label>
					<label class="form-platform-select-item">
						<input
							type="checkbox"
							hidden
							:value="form.platforms[3].value"
							v-model="form.platforms[3].value"
						>
						<span class="icon">
							<SvgIcon
								width="24"
								height="24"
								:viewBox="'0 0 24 24'"
								:iconType="'mobile'"
							/>
						</span>
					</label>
				</div>
				<FormNotification :errors="errors.platforms"/>
			</div>
			<div class="form-group" v-if="games">
				<span class="form-label">{{$t('Games')}}</span>
				<FormMultiSelect
					v-model="form.games"
					:placeholder="$t('Games')"
					:options="games"
					:loading="multiSelectGamesOptions.loading"
					:label="multiSelectGamesOptions.label"
					:track-by="multiSelectGamesOptions.trackBy"
					:multiple="multiSelectGamesOptions.multiple"
					:taggable="multiSelectGamesOptions.taggable"
				/>
				<FormNotification :errors="errors.games"/>
			</div>
			<FormNotification :errors="errors.message"/>
			<button
				class="button button-primary form-button-submit"
				@click.prevent="save()"
			>
				{{$t('Save')}}
			</button>
		</form>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

	import SvgIcon from '../../components/SvgIcon'
	import PageLinks from '../../components/Page/Links'
	import FormNotification from '../../components/Form/Notification'
	import ImagePicker from '../../components/Form/ImagePicker'
	import FormInput from '../../components/Form/Input'
	import FormSelect from '../../components/Form/Select'
	import FormNumber from '../../components/Form/Number'
	import _ from 'lodash'

	import FormMultiSelect from 'vue-multiselect'

	import {
		LOCATION_TYPES
	} from '../../constants/form'

	export default {
		props: ["id"],
		components: {
			SvgIcon,
			PageLinks,
			FormNotification,
			FormInput,
			FormMultiSelect,
			ImagePicker,
			FormSelect,
			FormNumber,
		},
		computed: {
			...mapGetters([
				'advertisement',
				'countries',
				'regions',
				'games'
			])
		},
		data () {
			return {
				imageChanged: false,
				regionsOptions: [],
				locationType: 'country',
				locationTypeOptions: LOCATION_TYPES,
				form: {
					title: '',
					image: '',
					goal: 0,
					goal_type: 1,
					games: [],
					countries: [],
					platforms: [
						{ id: 1, value: false },
						{ id: 2, value: false },
						{ id: 3, value: false },
						{ id: 4, value: false }
					],
				},
				goalTypes: [
					{ value: 1, name: 'View' },
					{ value: 2, name: 'Click' }
				],
				errors: {},
				defaultError: {
					message: [
						this.$t('Something went wrong. Please try again.')
					]
				},
				multiSelectOptions: {
					loading: false,
					label: 'value',
					trackBy: 'value',
					multiple: true,
					taggable: true,
				},
				multiSelectGamesOptions: {
					loading: false,
					label: 'name',
					trackBy: 'id',
					multiple: true,
					taggable: true,
				},
			};
		},
		created () {
			this.getFormData();
			this.onSearchChange = _.throttle(this.onSearchChange, 500, { 'leading': false })
		},
		watch: {
			$route () {
				this.getFormData();
			},
		},
		methods: {
			...mapActions({
				getAdvertisement: 'getAdvertisement',
				addAdvertisement: 'addAdvertisement',
				updateAdvertisement: 'updateAdvertisement',
				getCountries: 'getCountries',
				getGames: 'getGames',
				getRegions: 'getRegions'
			}),
			formFieldChanged (field, value) {
				this.form[field] = value;
			},
			async getFormData () {
				this.imageChanged = false;
				await this.getGames();
				await this.getRegions();
				await this.initRegionsOptions();

				if (this.id) {
					await this.getAdvertisement(this.id);

					this.form.platforms.map(item => item.value = this.advertisement.platforms.includes(item.id))
					const filteredGames = this.advertisement.games ? this.games.filter(item => this.advertisement.games.includes(item.id)) : []
					await this.initCountriesOptions();

					this.form = {
						title: this.advertisement.title,
						image: this.advertisement.image,
						link: this.advertisement.link,
						goal_type: this.advertisement.goal.type,
						goal: this.advertisement.goal.total,
						countries: this.countries,
						region_id: this.advertisement.meta.region_id,
						games: filteredGames,
						platforms: this.form.platforms
					};
				}
			},
			async initCountriesOptions() {
				if (this.advertisement.meta.countries.length > 0) {
					await this.getCountries({
						identities: this.advertisement.meta.countries.join(',')
					});
				}

				if (this.advertisement.meta.region_id) {
					this.locationType = 'region';
				} else  {
					this.locationType = 'country';
				}
			},
			async initRegionsOptions() {
				this.regionsOptions = this.regions.map(item => {
					return {value: item.id, name: item.title}
				});
			},
			async save () {
				const data = Object.assign({}, this.form);
				data.countries = data.countries.map(item => item.id.toString());
				data.games = data.games.map(item => item.id.toString());
				data.platforms = data.platforms.filter(item => item.value).map(item => item.id.toString());

				if (this.locationType === 'country') {
					delete data.region_id;
				} else {
					delete data.countries;
				}

				if (this.id) {
					if (!this.imageChanged) {
						delete data.image;
					}

					const updateData = await this.updateAdvertisement({
						id: this.id,
						data: data
					});

					if (updateData && updateData.response.status !== 200) {
						this.errorsHandler(updateData.response.data.errors);
					}
				} else {
					data.is_active = 0;

					const addData = await this.addAdvertisement({
						data: data
					});

					if (addData && addData.response.status !== 200) {
						this.errorsHandler(addData.response.data.errors);
					}
				}
			},
			errorsHandler (error) {
				this.errors = error ? error : this.defaultError;
			},
			async onSearchChange (value) {
				this.multiSelectOptions.loading = true;
				await this.getCountries({
					search: value
				});
				this.multiSelectOptions.loading = false;
			},
			onChangeImage (image) {
				this.form.image = image;
				this.imageChanged = true;
			},
			onRemoveImage () {
				this.form.image = null;
				this.imageChanged = true;
			},
			locationChanged(name, value) {
				this.locationType = value;
			}
		}
	}
</script>
