<template>
	<div class="form-notification" :class="type" v-if="errors">
		<span v-for="error in errors">{{error}}</span>
	</div>
</template>

<script>
	export default {
		name: "FormNotification",
		props: {
			type: {
				type: String,
				default: 'error'
			},
			errors: {
				type: Array
			},
		}
	};
</script>