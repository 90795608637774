import http from '../utils/http'
import joinUrls from "../utils/join-urls";
export default {
    get(tournament) {
        return http.get(joinUrls('/tournaments', tournament, 'multi-arena'));
    },
    getMeta (tournament) {
        return http.get(joinUrls('/tournaments', tournament, 'multi-arena', 'meta'));
    },
    getMatch (tournament, id) {
        return http.get(joinUrls('/tournaments', tournament, 'multi-arena', id));
    },
    generateLobbies (tournament) {
        return http.post(joinUrls('/tournaments', tournament, 'multi-arena', 'lobbies'));
    },
    editLobby (tournament, lobby, data) {
        return http.put(joinUrls('/tournaments', tournament, 'multi-arena', 'lobbies', lobby), data);
    },
    startNextRound (tournament) {
        return http.post(joinUrls('/tournaments', tournament, 'multi-arena', 'next-round'));
    }
}
