<template>
	<div class="brackets" :key="key">
        <template v-if="!brackets.length || loader">
            <div class="match__loader">
                <img src="https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif" alt="">
            </div>
        </template>
		<template v-else>
            <BracketsShuffleButton  v-if="!isPublic && type !== 'lower'"/>
            <BracketsRoundsList @hideRound="hideRound" @showRound="showRound" :hiddenRounds="hiddenRounds" :isMobile="isMobileBracket" :isPublic="isPublic" v-if="type !== 'lower'"/>

            <button class="button button-primary" @click="fillEmptySpots(tournament.getId())" style="margin-top: 30px;" v-if="showFillButton">Fill empty spots</button>


            <div class="brackets__wrapper">

                <BracketsStatusesMap/>
                <BracketNode :type="type" v-if="!isMobileBracket()" :data="bracketsData"/>

                <BracketMobileNode v-if="isMobileBracket()" :data="bracketsData"/>
            </div>
            <BracketsWinnerDialog v-if="!isPublic"/>
            <RevertMatchDialog v-if="!isPublic"/>
        </template>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'
    import BracketItem from './BracketItem'
	import BracketNode from './BracketNode'
	import BracketMobileNode from './BracketMobileNode'
	import BracketsRoundsList from './BracketsRoundsList'
	import BracketsWinnerDialog from './BracketsWinnerDialog'
	import BracketsShuffleButton from './BracketsShuffleButton'
	import BracketsStatusesMap from './BracketsStatusesMap'
	import RevertMatchDialog from '../Dialogs/RevertMatch'
	import helpers from '../../helpers'

	export default {
        data () {
            return {
                key: 0,
                loader: false,
                hidden: localStorage.getItem(`hiddenRounds-${this.$route.params.id}`) ? Number(localStorage.getItem(`hiddenRounds-${this.$route.params.id}`)) : 0,
                hiddenRounds: localStorage.getItem(`hiddenRounds-${this.$route.params.id}`) ? Number(localStorage.getItem(`hiddenRounds-${this.$route.params.id}`)) : 0,
            }
        },
		components: {
            BracketItem,
			BracketNode,
			BracketMobileNode,
			BracketsWinnerDialog,
			BracketsRoundsList,
			BracketsShuffleButton,
			BracketsStatusesMap,
			RevertMatchDialog
		},
		props: {
            type: {
                required: false,
                type: String,
            },
			id: String || Number,
			isPublic: {
				type: Boolean,
				default: false,
			},
		},
        watch: {
            async hidden () {
                this.loader = true
                this.$nextTick(() => {
                    this.hiddenRounds = this.hidden
                    localStorage.setItem(`hiddenRounds-${this.$route.params.id}`, this.hiddenRounds)
                    setTimeout(() => {
                        this.loader = false
                        this.$emit('update')
                    }, 0)
                })
            }
        },
        updated () {

        },
		mounted() {

			if (this.isPublic) {
				this.getPublicBrackets(this.id);
			} else {
				this.getBrackets(this.id);
			}

            if (this.tournament && this.tournament.getCustomSeeding()) {
                this.getParticipants({ id: this.tournament.getId(), params: {
                    status: 'active'
                    } })
            }

            setTimeout(() => {
                this.initWorker()
            }, 2000)
		},
		computed: {
            filteredParticipants () {
                if (!this.participants) return []
                return this.participants.filter(item => {
                    return this.excludeParticipants.indexOf(item.getId()) < 0
                })
            },
            showFillButton () {
              if (this.isPublic) return false
              if (!this.tournament.getCustomSeeding()) return false
              if (!['REGISTRATION_CLOSED'].includes(this.tournament.getStatus())) return false
              if (this.filteredParticipants.length === 0) return false
              if (this.type === 'lower') return false
              return true
            },
			...mapGetters({
                excludeParticipants: 'excludeParticipants',
				tournament: 'tournament',
				brackets: 'brackets',
                participants: 'participants',
                rounds: 'rounds',
				selectedRound: 'selectedRound',
				isMobile: 'isMobile',
                centrifugeInstance: 'centrifugeInstance'
			}),
			bracketsData() {
                const handler = (brackets, de) => {
                    if (this.isMobileBracket()) {
                        return helpers.generateMobileBracket(brackets, this.selectedRound);
                    }
                    return helpers.generateRecursiveBracket(brackets, this.hiddenRounds);
                }
                if (this.type === 'lower') {
                    return handler(helpers.bracketsLower(this.brackets), true)
                }
                if (this.type === 'upper') {
                    return handler(helpers.bracketsUpper(this.brackets), true)
                }

                return handler(this.brackets)
			}
		},
		methods: {
			...mapActions({
                fillEmptySpots: 'fillEmptySpots',
                getParticipants: 'GET_PARTICIPANTS',
                getTournament: 'GET_TOURNAMENT',
				getBrackets: 'fetchBrackets',
				getPublicBrackets: 'fetchPublicBrackets',
			}),
            showRound () {
              if (this.hidden > 0) {
                  this.hidden = this.hidden - 1;
              }
            },
            hideRound () {
              if ((this.rounds.length - this.hidden) < 2) return;

              this.hidden++;
            },
            initWorker () {

            },
			isMobileBracket() {
				return this.isMobile && !this.isPublic;
			}
		}
	};
</script>
<style scoped lang="scss">
.match__loader {
    width: 100%;
    display: flex;
    padding: 30px 0;
    justify-content: center;
    img {
        width: 40px;
}
    .brackets__wrapper {
        padding-bottom: 60px;
    }
}
.brackets__wrapper {
    display: flex;

    padding-bottom: 60px;
}
</style>
