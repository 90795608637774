import regions from '../../api/regions'
import PageMeta from '../../dto/page-meta'
import router from "../../routes";

const state = () => ({
	region: null,
	regions: [],
	regionsMeta: null,
});

const getters = {
	region: state => state.region,
	regions: state => state.regions,
	regionsMeta: state => state.regionsMeta
};

const actions = {
	getRegions: async ({commit}, data = {}) => {
		const response = await regions.get(data);

		commit('UPDATE_REGIONS', response.data.data);
		commit('UPDATE_REGIONS_META', response.data);
	},
	getRegion: async ({commit}, id) => {
		const response = await regions.getRegion(id);

		commit('UPDATE_REGION', response.data.data);
	},
	addRegion: async ({commit}, {data}) => {
		const response = await regions.add(data);

		if (response.status === 201 || response.status === 200) {
			router.push('/regions');
		}
	},
	updateRegion: async ({commit}, {id, data}) => {
		const response = await regions.update(id, data);

		if (response.status === 201 || response.status === 200) {
			router.push('/regions');
		}
	},
	deleteRegion: async ({commit}, id) => {
		await regions.deleteRegion(id);
		const response = await regions.get();

		commit('UPDATE_REGIONS', response.data.data);
		commit('UPDATE_REGIONS_META', response.data);
	},
};

const mutations = {
	UPDATE_REGION: (state, data) => {
		state.region = data;
	},
	UPDATE_REGIONS: (state, data) => {
		state.regions = data;
	},
	UPDATE_REGIONS_META: (state, data) => {
		state.regionsMeta = new PageMeta(data.meta);
	},
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
