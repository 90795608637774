<template>
    <div class="multilobby__lobby-participants">
        <div v-if="load" class="match__loader">
            <img src="https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif" alt="">
        </div>
        <template v-else>
            <div class="multilobby__lobby-participant-item" >
                <div class="participant" v-for="(participant, k) in match.participants" :key="k">
                    <div class="participant-user">
                        <img :src='getAvatar(participant)' alt="">
                        <strong>{{ getNickName(participant) }}</strong>
                    </div>
                    <div class="participant-input">
                        <input type="number" :disabled="isFinished || tournament.getStatus() === 'REGISTRATION_CLOSED' || match.status == 'FINISHED'" step="1.0" min="0" :value="participant.points" @input="setScore($event, participant)">
                    </div>
                    <div class="participant-checkbox">
                        <input type="checkbox" :disabled="tournament.getStatus() === 'REGISTRATION_CLOSED' || match.status == 'FINISHED'" v-model="participant.passed">
                    </div>
                </div>

            </div>
            <button class="button button-primary" @click="updateResult" v-if="tournament.getStatus() !== 'REGISTRATION_CLOSED'">UPDATE RESULT</button>
            <p class="form-field-description" style="text-align: center; width: 100%; display: block; margin-top: 20px;" v-else>You will be able to put points to players after the start of the tournament</p>
        </template>
    </div>
</template>
<script>
import multiarena from "../../api/multiarena";
import leaderboard from "../../api/leaderboard";
import {mapGetters} from "vuex";
export default {
    props: {
        tournamentId: {
            type: Number,
            required: true
        },
        matchId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            load: true,
            match: {}
        }
    },
    computed: {
        ...mapGetters({
            centrifugeInstance: 'centrifugeInstance',
            tournament: 'tournament'
        }),
        isFinished() {
            return 'FINISHED' === this.tournament.getStatus();
        },
    },
    methods: {
        setScore ($event, participant) {
            participant.points = Math.abs($event.target.value)
        },
        getAvatar (participant) {
            if (participant.type === 2) {
                return participant.team ? participant.team.logo : '/images/logo.jpg';
            }
            return participant.profile.user.avatar ?? '/images/logo.jpg';
        },
        getNickName(participant) {
            if (participant.type === 2) {
                return participant.team ? participant.team.name : 'Team deleted';
            }

            return participant.profile.user.name ?? participant.profile.info.nickname;
        },
        async getMatch () {
            await multiarena.getMatch(this.tournamentId, this.matchId).then(response => {
                this.match = response.data.data
            }).finally(() => this.load = false)
        },
        async updateResult() {
            const newParticipants = [];
            this.match.participants.forEach(item => {
                // if we are dealing with a team, and not a solo player, save the team with all the team mates
                if (item['team']) {
                    newParticipants.push({
                            user_ids: item.team.team_mates.map(teamMate => {
                                if (teamMate.profile && teamMate.profile.user) {
                                    return teamMate.profile.user.id;
                                }
                                else{
                                    return null;
                                }
                            }),
                            id: item.id,
                            points: item.points,
                            passed: item.passed
                    });
                }
                else {
                    newParticipants.push({
                        user_id: item.profile.user.id,
                        id: item.id,
                        points: item.points,
                        passed: item.passed
                    });
                }
            });
            await leaderboard.update(this.tournamentId, this.matchId, {
                participants: newParticipants,
            });
        },
    },
    mounted () {
        this.getMatch();
        this.centrifugeInstance.subscribe(`tournament.${this.tournamentId}.arena.${this.matchId}`, (data) => {
        })
    }
}
</script>
<style scoped lang="scss">
.match__loader {
    width: 100%;
    display: flex;
    padding: 30px 0;
    justify-content: center;
    img {
        width: 20px;
    }
}
.multilobby__lobby-participants {
    margin-top: 25px;
    .multilobby__lobby-participant-item {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        border-bottom: 1px solid #E5E5E5;
        grid-column-gap: 20px;
        .participant {
            display: flex;
            border: 1px solid #E5E5E5;
            border-bottom: none;
            .participant-user {
                padding: 6px 10px;
                border-right: 1px solid #E5E5E5;
                display: flex;
                flex-grow: 1;
                overflow: hidden;
                align-items: center;
                img {
                    width: 28px;
                    border-radius: 100%;
                    height: 28px;
                    margin-right: 10px;
                }
                strong {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    text-transform: capitalize;
                    color: #303030;
                }
            }
            .participant-input {
                padding: 3px;
                input {
                    background: #ECECEC;
                    border-radius: 4px;
                    height: 100%;
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 14px;
                    text-transform: capitalize;
                    outline: none;
                    border: none;
                    color: #303030;
                }
                width: 54px;
                min-width: 54px;
            }
            .participant-checkbox {
                padding: 3px;
                display: flex;
                align-items: center;
                input {
                    background: #ECECEC;
                    border-radius: 4px;
                    height: 20px;
                    width: 20px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 14px;
                    text-transform: capitalize;
                    outline: none;
                    border: none;
                    color: #303030;
                }
            }
        }
    }
    .multilobby__lobby-participant-item + .multilobby__lobby-participant-item {
        margin-left: 20px;
    }
}

button {
    margin: 20px 0;
    width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
</style>
