import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

import PublicBrackets from './views/Public/Brackets'
import Seasons from './views/Seasons/Seasons'
import SeasonSolo from './views/Seasons/SeasonSolo'
import SeasonTeam from './views/Seasons/SeasonTeam'
import SeasonClan from './views/Seasons/SeasonClan'
import SeasonTeamMates from './views/Seasons/SeasonTeamMates'
import EditSeason from './views/Seasons/EditSeason'
import Rules from './views/Rules/Rules'
import Games from './views/Games/Games'
import GameDetails from './views/Games/GameDetails'
import Rule from './views/Rules/Rule'
import DeepLinks from './views/DeepLinks/DeepLinks'
import AddDeepLink from './views/DeepLinks/AddDeepLink'
import Notifications from './views/Notifications/Notifications'
import EditNotification from './views/Notifications/EditNotification'
import EditNotificationOld from './views/Notifications/EditNotificationOld'
import EditRule from './views/Rules/EditRule'
import Tournaments from './views/Tournaments/Tournaments'
import RoundRobinOverview from './views/Tournaments/RoundRobin/RoundRobinOverview'
import Tournament from './views/Tournaments/Tournament'
import TournamentsAdvanced from './views/Tournaments/TournamentsAdvanced'
import Teams from './views/Teams/Teams'
import TeamDetail from './views/Teams/TeamDetail'
import Brackets from './views/Tournaments/Brackets'
import Participants from './views/Tournaments/Participants'
import ParticipantsLogs from './views/Tournaments/ParticipantsLogs'
import EditTournaments from './views/Tournaments/EditTournament'
import Events from './views/Events'
import BracketTemplates from './views/BracketTemplates/Templates'
import EditBracketTemplates from './views/BracketTemplates/Edit'
import RoomTemplates from './views/RoomTemplates/RoomTemplates'
import EditRoomTemplate from './views/RoomTemplates/EditRoomTemplate'
import News from './views/News/News'
import EditNews from './views/News/Edit'
import PageNotFound from './views/PageNotFound'
import Login from './views/Login'
import Test from './views/Test/Test'
import Exchanges from './views/Settings/Exchanges'

import Countries from './views/Countries/List'
import CountriesEdit from './views/Countries/Edit'

import Regions from './views/Regions/List'
import RegionsEdit from './views/Regions/Edit'
import RoundRobinGame from './views/Tournaments/RoundRobin/RoundRobinGame'
import RoundRobinParticipants from './views/Tournaments/RoundRobin/RoundRobinParticipants'

import Transactions from './views/Transactions/Transactions'

import Withdraws from './views/Withdraws/Withdraws'
import Withdraw from './views/Withdraws/Withdraw'

import Advertisements from './views/Advertisements/List'
import AdvertisementsEdit from './views/Advertisements/Edit'

import Terms from './views/Terms/List'
import AddTerms from './views/Terms/AddTerms'

import Users from './views/Users/UsersList'
import User from './views/Users/User'


import ChatsList from './views/Chats/ChatsList'

import {TOURNAMENT_ROUTES} from './constants/common'

import { SUPER_ADMIN, TOURNAMENT_ADMIN } from "./constants/roles";
import RoundRobin from "./views/Tournaments/RoundRobin";

Vue.use(VueRouter);

let routes = [
    {
      name: 'users',
      path: '/users',
      component: Users,
      meta: {
          accessRoles: [
              SUPER_ADMIN,
              TOURNAMENT_ADMIN
          ]
      }
    },
    {
        name: 'user',
        path: '/user/:id',
        component: User,
        props: true,
        meta: {
            accessRoles: [
                SUPER_ADMIN,
                TOURNAMENT_ADMIN
            ]
        }
    },
	{
		name: 'login',
		path: '/login',
		component: Login,
	},
    {
        name: "withdraws",
        path: "/withdraws",
        component: Withdraws
    },
    {
        name: "withdraw",
        path: "/withdraw/:id",
        props: true,
        meta: {requiresAuth: true},
        component: Withdraw
    },
    {
        name: 'transactions',
        path: '/transactions',
        component: Transactions,
    },
	{
		name: 'authenticator',
		path: '/authenticator'
	},
	{
		name: 'logout',
		path: '/logout',
		meta: {requiresAuth: true}
	},
	{
		name: 'test',
		path: '/test',
		component: Test,
		meta: {requiresAuth: true}
	},
	{
		name: 'countries',
		path: '/countries',
		component: Countries,
		meta: {requiresAuth: true}
	},
	{
		name: 'edit-country',
		path: '/countries/edit/:id',
		component: CountriesEdit,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'regions',
		path: '/regions',
		component: Regions,
		meta: {requiresAuth: true}
	},
	{
		name: 'region-add',
		path: '/regions/add',
		component: RegionsEdit,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'region-edit',
		path: '/regions/edit/:id',
		component: RegionsEdit,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'advertisements',
		path: '/advertisements',
		component: Advertisements,
		meta: {requiresAuth: true}
	},
	{
		name: 'advertisements-add',
		path: '/advertisements/add',
		component: AdvertisementsEdit,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'advertisements-edit',
		path: '/advertisements/edit/:id',
		component: AdvertisementsEdit,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'seasons',
		path: '/seasons',
		component: Seasons,
		meta: {requiresAuth: true}
	},
	{
		name: 'seasons-edit',
		path: '/seasons/edit/:id',
		component: EditSeason,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'season-solo',
		path: '/season/:id/solo',
		component: SeasonSolo,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'season-team',
		path: '/season/:id/team',
		component: SeasonTeam,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'season-clan',
		path: '/season/:id/clan',
		component: SeasonClan,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'season-team-mates',
		path: '/season/:id/team/:team',
		component: SeasonTeamMates,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'season-clan-mates',
		path: '/season/:id/clan/:team',
		component: SeasonTeamMates,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'seasons-add',
		path: '/seasons/add',
		component: EditSeason,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'rules',
		path: '/rules',
		component: Rules,
		meta: {requiresAuth: true}
	},
    {
        name: 'games',
        path: '/games',
        component: Games,
        meta: {requiresAuth: true}
    },
    {
        name: 'settings.exchange',
        path: '/settings/exchange',
        component: Exchanges,
        meta: {requiresAuth: true}
    },
    {
        name: 'game.add',
        path: '/games/add',
        component: GameDetails,
        meta: {requiresAuth: true}
    },
    {
        name: 'game',
        path: '/games/:id',
        component: GameDetails,
        meta: {requiresAuth: true}
    },
	{
		name: 'rule-edit',
		path: '/rule/edit/:id',
		component: EditRule,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'rule-add',
		path: '/rule/add',
		component: EditRule,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'rule',
		path: '/rule/:id',
		component: Rule,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'tournaments',
		path: '/',
		component: Tournaments,
		meta: {requiresAuth: true}
	},
	{
		name: 'tournament-edit',
		path: '/tournament/edit/:id',
		component: EditTournaments,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'tournament-add',
		path: '/tournament/add',
		component: EditTournaments,
		props: true,
		meta: {requiresAuth: true}
	},
    {
        name: 'teams',
        path: '/teams',
        component: Teams,
        meta: {requiresAuth: true}
    },
    {
        name: 'team',
        path: '/team/:id',
        component: TeamDetail,
        meta: {requiresAuth: true}
    },
    {
        name: 'round-robin',
        path: '/tournament/round-robin/:id',
        props: true,
        component: RoundRobin,
        redirect: { name: 'round-robin.overview' },
        children: [
            {
                path: 'overview',
                name: 'round-robin.overview',
                component: RoundRobinOverview
            },
            {
                path: 'game',
                name: 'round-robin.game',
                component: RoundRobinGame
            },
            {
                path: 'participants',
                name: 'round-robin.participants',
                component: RoundRobinParticipants
            }
        ],
        meta: {requiresAuth: true}
    },
	{
		name: 'tournament',
		path: '/tournament/:id',
		component: Tournament,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'participants',
		path: '/tournaments/:id/participants',
		component: Participants,
		props: true,
		meta: {requiresAuth: true}
	},
    {
		name: 'participants-logs',
		path: '/tournaments/:id/participants/logs',
		component: ParticipantsLogs,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'tournament-brackets',
		path: '/tournaments/:id/brackets',
		component: Brackets,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'tournament-public-brackets',
		path: '/tournaments/:id/public-brackets',
		component: PublicBrackets,
		props: true,
	},
	{
		name: 'tournaments-advanced',
		path: '/tournaments/advanced',
		component: TournamentsAdvanced,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'deep-links',
		path: '/deep-links',
		component: DeepLinks,
		meta: {requiresAuth: true}
	},
	{
		name: 'add-deep-links',
		path: '/deep-links/add',
		component: AddDeepLink,
		meta: {requiresAuth: true}
	},
	{
		name: 'notifications',
		path: '/notifications',
		component: Notifications,
		meta: {requiresAuth: true}
	},
	{
		name: 'add-notification',
		path: '/notifications/add',
		component: EditNotificationOld,
		meta: {requiresAuth: true}
	},
	{
		name: 'add-notification-new',
		path: '/notifications/add-new',
		component: EditNotification,
		meta: {requiresAuth: true}
	},
	{
		name: 'events',
		path: '/events',
		component: Events,
		meta: {requiresAuth: true}
	},
	{
		name: 'bracket-templates',
		path: '/bracket-templates',
		component: BracketTemplates,
		meta: {requiresAuth: true}
	},
    {
        name: 'room-templates',
        path: '/room-templates',
        component: RoomTemplates,
        meta: {requiresAuth: true}
    },
    {
        name: 'room-templates-add',
        path: '/room-templates/add',
        component: EditRoomTemplate,
        meta: {requiresAuth: true}
    },
    {
        name: 'room-templates-edit',
        path: '/room-templates/edit/:id',
        component: EditRoomTemplate,
        props: true,
        meta: {requiresAuth: true}
    },
	{
		name: 'add-bracket-template',
		path: '/bracket-templates/add',
		component: EditBracketTemplates,
		meta: {requiresAuth: true}
	},
	{
		name: 'edit-bracket-template',
		path: '/bracket-templates/edit/:id',
		component: EditBracketTemplates,
		props: true,
		meta: {requiresAuth: true}
	},
	{
		name: 'news',
		path: '/news',
		component: News,
		meta: {requiresAuth: true}
	},
	{
		name: 'post-news',
		path: '/news/post',
		component: EditNews,
		meta: {requiresAuth: true}
	},
	{
		name: 'edit-news',
		path: '/news/edit/:id',
		component: EditNews,
		props: true,
		meta: {requiresAuth: true}
	},
    {
        name: 'terms',
        path: '/terms',
        component: Terms,
        meta: {requiresAuth: true}
    },
    {
        name: 'add-terms',
        path: '/terms/add',
        component: AddTerms,
        meta: {requiresAuth: true}
    },
    {
        name: 'chats',
        path: '/chats',
        component: ChatsList,
        meta: {requiresAuth: true}
    },
	{
		name: '404',
		path: '/page-not-found',
		component: PageNotFound
	},
	{path: '*', redirect: '/page-not-found'}
];

const router = new VueRouter({
	mode: 'history',
	routes
});

router.beforeEach((to, from, next) => {
	const name = to.name;
	const token = to.query.token;

    if (to.meta.accessRoles) {
        let user = localStorage.getItem('userMeta')
        user = user ? JSON.parse(user) : null
        if (user) {
            let check = false
            for (const item in to.meta.accessRoles) {
                if (user.roles.includes(to.meta.accessRoles[item])) {
                    check = true
                }
            }

            if (!check) {
                next('/')
            }
        }
    }

	if (!TOURNAMENT_ROUTES.includes(name)) {
		store.dispatch('centrifugeDisconnect');
	}

	if (name === 'authenticator') {
		if (!token) next({path: '/'});

		if (token) {
			store.dispatch('TOKEN_LOGIN', token)
				.then(() => {
					next({path: '/'})
				});
		}
	}

	if (to.name === 'logout') {
		store.dispatch('LOGOUT');
		next({
			path: '/login'
		});
	}

	if (to.name === 'login') {
		if (store.getters.isLoggedIn) {
			next({
				path: '/'
			});
			next({
				path: '/'
			});
		}
	}

	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!store.getters.isLoggedIn) {
			next({
				path: '/login',
				query: {redirect: to.fullPath}
			})
		} else {
			next();
		}
	} else {
		next();
	}
});

router.addRoute({ 
	name: 'webview', 
	path: '/webview/tournament/:id',
	beforeEnter(to) {
		window.location.replace(`${process.env.VUE_APP_WEBVIEW_URL}${to.fullPath}`)
	}
})

router.addRoute({ 
	name: 'generic-redirect', 
	path: '/get-redirect',
	beforeEnter(to) {
		window.location.replace(`${process.env.VUE_APP_API_URL}${to.fullPath}`)
	}
})

router.addRoute({ 
	name: 'social-login', 
	path: '/social-login/:provider',
	beforeEnter(to) {
		window.location.replace(`${process.env.VUE_APP_API_URL}${to.fullPath}`)
	}
})

router.addRoute({ 
	name: 'social-login-mobile-callback', 
	path: '/social-login/:provider/callback',
	beforeEnter(to) {
		window.location.replace(`${process.env.VUE_APP_API_URL}${to.fullPath}`)
	}
})

router.addRoute({ 
	name: 'social-login-backoffice-callback', 
	path: '/social-login-web/:provider/callback',
	beforeEnter(to) {
		window.location.replace(`${process.env.VUE_APP_API_URL}${to.fullPath}`)
	}
})

router.addRoute({ 
	name: 'social-login-webapp-callback', 
	path: '/social-login-client/:provider/callback',
	beforeEnter(to) {
		window.location.replace(`${process.env.VUE_APP_API_URL}${to.fullPath}`)
	}
})

export default router;