import Slim from "./slim";
import Profile from "./profile";
import ArrowDown from "./arrow-down";
import ArrowUp from "./arrow-up";
import ArrowLeft from "./arrow-left";
import ArrowRight from "./arrow-right";
import Advanced from "./advanced";
import Calendar from "./calendar";
import Clock from "./clock";
import Copy from "./copy";
import Cup from "./cup";
import Delete from "./delete";
import Download from "./download";
import Edit from "./edit";
import Email from "./email";
import Filter from "./filter";
import Hand from "./hand";
import HandHover from "./hand-hover";
import Plus from "./plus";
import Search from "./search";
import Shuffle from "./shuffle";
import ZoomIn from "./zoom-in";
import ZoomOut from "./zoom-out";
import Twitch from "./twitch";
import Discord from "./discord";
import Dots from "./dots";
import Rules from "./rules";
import Chat from "./chat";
import Collapse from "./collapse";
import Telegram from "./telegram";
import Notification from "./notification";
import DeepLink from "./deep-link";
import Message from "./message";
import Winner from "./winner";
import Joystick from "./joystick";
import Confirm from "./confirm";
import PeopleFilled from "./people-filled";
import CupFilled from "./cup-filled";
import Brackets from "./brackets";
import Feed from "./feed";
import Megaphone from "./megaphone";
import Pc from "./pc";
import Xbox from "./xbox";
import Ps from "./ps";
import Mobile from "./mobile";
import SmallClose from "./small-close";
import HardDownload from "./hard-download";
import Recreate from "./recreate";
import Bypass from "./bypass";

export default {
	'slim': Slim,
	'profile': Profile,
    'message': Message,
	'arrow-down': ArrowDown,
	'arrow-up': ArrowUp,
	'arrow-left': ArrowLeft,
	'arrow-right': ArrowRight,
	'advanced': Advanced,
	'calendar': Calendar,
	'clock': Clock,
	'copy': Copy,
	'cup': Cup,
	'delete': Delete,
	'download': Download,
	'edit': Edit,
	'email': Email,
	'filter-option': Filter,
	'hand': Hand,
	'hand-hover': HandHover,
	'plus': Plus,
	'search': Search,
	'shuffle': Shuffle,
	'zoom-in': ZoomIn,
	'zoom-out': ZoomOut,
	'twitch': Twitch,
	'discord': Discord,
	'dots': Dots,
	'rules': Rules,
	'chat': Chat,
	'collapse': Collapse,
	'telegram': Telegram,
	'notification': Notification,
	'deep-link': DeepLink,
	'winner': Winner,
	'joystick': Joystick,
	'confirm': Confirm,
	'people-filled': PeopleFilled,
	'cup-filled': CupFilled,
	'brackets': Brackets,
	'feed': Feed,
	'megaphone': Megaphone,
	'pc': Pc,
	'xbox': Xbox,
	'ps': Ps,
	'mobile': Mobile,
	'small-close': SmallClose,
	'hard-download': HardDownload,
	'recreate': Recreate,
	'bypass': Bypass,
};
