<template>
    <div class="ui-brackets-item">
        <div class="ui-brackets-item__players">
            <div class="ui-brackets-item__players__header">
                <span>{{ data.status ? data.status : data.status }}</span>
                <span>{{ convertDate(data.started_at) }}</span>
            </div>
            <div class="ui-brackets-item__players__content">
                <BracketItemPlayer
                    style="border-bottom: 1px solid #232323"
                    v-for="participant in 8"
                    :key="participant"
                    :winner="data.participants[participant - 1] && data.participants[participant - 1].status === 'winner'"
                    :data="data.participants[participant - 1]"
                    :player="'participant'"
                    :result="getResult('participant')"
                />
            </div>
            <div class="ui-brackets-item__players__content__final" :class="{'lower': data.is_looser_match}" v-if="data.isFinal">
                <template v-if="data.is_looser_match">
                    Lower final
                </template>
                <template v-else>
                    grand Final
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import BracketItemPlayer from './TftUiBracketItemPlayer'
import Moment from 'moment'
import { mapGetters } from 'vuex'
export default {
    name: 'UiBracketItem',
    components: {
        BracketItemPlayer
    },
    props: {
        data: Object
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament'
        })
    },
    methods: {
        getPlayerClass (playerNode) {
            const node = this.data
            const classes = []
            const hasWinner = node.winnerId && playerNode.id !== null
            const isWinner = node.winnerId === playerNode.id

            if (!hasWinner) return

            if (isWinner) {
                classes.push('winner')
            } else {
                classes.push('defeated')
            }

            return classes.join(' ')
        },
        convertDate (value) {
            const date = new Moment(value).format('MMMM D')
            const time = new Moment(value).format('h:mm a')
            return `${date} \u00B7 ${time}`
        },
        getResult (player) {
            if (this.data.result) {
                switch (player) {
                    case 'firstParticipant':
                        return this.data.result.split(':')[0]
                    case 'secondParticipant':
                        return this.data.result.split(':')[1]
                }
            }

            return this.data[player] && this.data.winnerId === this.data[player].id ? '1' : '0'
        }
    }
}
</script>

<style lang="scss" scoped>

.ui-brackets-item {
    margin: 10px 0;
    display: flex;
    flex-direction: row-reverse;

    &__players {
        position: relative;

        &__header {
            position: absolute;
            left: 0;
            bottom: 100%;
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: -2px;

            span {
                font-size: 10px;
                line-height: 14px;
                letter-spacing: 0.25px;
                text-transform: uppercase;

                color: #8F8F8F;
                padding: 3px 7px 1px 7px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                background: #2F2F2F;

                + span {
                    margin-left: 6px;
                }
            }
        }

        &__content {
            position: relative;
            border-radius: 5px;
            background: #2F2F2F;
            box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
            overflow: hidden;
            z-index: 9999;
            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 50%;
                width: 100%;
                height: 1px;
                background: #505050;
                z-index: 1;
            }

            &__final {
                bottom: -28px;
                height: 33px;
                position: absolute;
                font-weight: bold;
                font-size: 10px;
                width: 100%;
                line-height: 14px;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #00E7DF;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #018786;
                border-radius: 10px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                &.third {
                    background: #004042;
                }
                &.lower {
                    background: #004042;
                }
            }
        }
    }
}
</style>
