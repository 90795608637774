<template>
	<div>
		<PageLinks :links="[
			{
				url: id ? '/regions/edit/' +  id : '/regions/add',
				text: id ? $t('Edit Region') : $t('Add Region'),
				classes: 'active'
			}
		]"/>
		<form class="form form--xl">
			<div class="form-group">
				<FormInput
					:name="'title'"
					:label="$t('Title')"
					:placeholder="$t('Title')"
					:value="form.title"
					:formErrors="errors.title"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group" v-if="form.countries">
				<span class="form-label">{{$t('Choose countries')}}</span>
				<FormMultiSelect
					v-model="form.countries"
					:placeholder="$t('Countries')"
					:options="countries"
					:loading="multiSelectOptions.loading"
					:label="multiSelectOptions.label"
					:track-by="multiSelectOptions.trackBy"
					:multiple="multiSelectOptions.multiple"
					:taggable="multiSelectOptions.taggable"
					@search-change="onSearchChange"
				/>
				<FormNotification :errors="errors.countries"/>
			</div>
			<FormNotification :errors="errors.message"/>
			<button
				class="button button-primary form-button-submit"
				@click.prevent="save()"
			>
				{{$t('Save')}}
			</button>
		</form>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

	import SvgIcon from '../../components/SvgIcon'
	import PageLinks from '../../components/Page/Links'
	import FormNotification from '../../components/Form/Notification'
	import FormInput from '../../components/Form/Input'
	import _ from 'lodash'

	import FormMultiSelect from 'vue-multiselect'

	export default {
		props: ["id"],
		components: {
			SvgIcon,
			PageLinks,
			FormNotification,
			FormInput,
			FormMultiSelect
		},
		computed: {
			...mapGetters([
				'region',
				'countries'
			])
		},
		data () {
			return {
				form: {
					title: '',
					countries: [],
				},
				errors: {},
				defaultError: {
					message: [
						this.$t('Something went wrong. Please try again.')
					]
				},
				multiSelectOptions: {
					loading: false,
					label: 'value',
					trackBy: 'value',
					multiple: true,
					taggable: true,
				},
			};
		},
		created () {
			this.getFormData();
			this.onSearchChange = _.throttle(this.onSearchChange, 500)
		},
		watch: {
			$route () {
				this.getFormData();
			},
		},
		methods: {
			...mapActions({
				getCountries: 'getCountries',
				getRegion: 'getRegion',
				addRegion: 'addRegion',
				updateRegion: 'updateRegion',
			}),
			formFieldChanged (field, value) {
				this.form[field] = value;
			},
			async getFormData () {
				if (this.id) {
					await this.getRegion(this.id);

					this.form = {
						title: this.region.title,
						countries: this.region.countries
					};
				}
			},
			async save () {
				const data = Object.assign({}, this.form);
				data.countries = data.countries.map(item => item.id.toString());

				if (this.id) {
					const updateData = await this.updateRegion({
						id: this.id,
						data: data
					});

					if (updateData && updateData.response.status !== 200) {
						this.errorsHandler(updateData.response.data.errors);
					}
				} else {
					const addData = await this.addRegion({
						data: data
					});

					if (addData && addData.response.status !== 200) {
						this.errorsHandler(addData.response.data.errors);
					}
				}
			},
			errorsHandler (error) {
				this.errors = error ? error : this.defaultError;
			},
			async onSearchChange(value) {
				this.multiSelectOptions.loading = true;
				await this.getCountries({
					search: value
				});
				this.multiSelectOptions.loading = false;
			},
		}
	}
</script>
