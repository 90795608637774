<template>
    <RoundRobinContainer :id="id"></RoundRobinContainer>
</template>
<script>
import RoundRobinContainer from "../../../components/RoundRobin/RoundRobinContainer";
export default {
    props: {
        id: {
            required: true,
            type: Number
        }
    },
    components: {
        RoundRobinContainer
    }
}
</script>
