<template>
	<div class="form-select-field">
		<label>
			<span class="form-label" v-if="label">{{label}}</span>
			<select
				class="form-select"
				:name="name"
				v-model="selected"
				@change="valueChanged"
			>
				<option
					v-for="option in newOptions"
					:value="option.value"
				>
					{{ option.name }}
				</option>
			</select>
			<span class="form-select__arrow">
					<SvgIcon
						width="15"
						height="15"
						:iconType="'arrow-down'"
						iconColor="#878787"
					/>
				</span>
		</label>
		<FormNotification :errors="errors"/>
	</div>
</template>

<script>
	import FormNotification from '../../components/Form/Notification'
	import SvgIcon from '../../components/SvgIcon'

	export default {
		name: "FormSelect",
		components: {
			FormNotification,
			SvgIcon
		},
		data() {
			return {
				selected: this.value,
				newOptions: this.options,
				errors: this.formErrors,
			}
		},
		props: {
			name: {
				type: String,
			},
			value: {
				type: [String, Number, Object],
				default: null
			},
			options: {
				type: Array,
			},
			label: {
				type: String,
			},
			formErrors: {
				type: [Array, Object],
			},
			onChange: {type: Function},
		},
		watch: {
			value: {
				handler(val) {
					this.selected = val;
				}
			},
			options: {
				handler(val) {
					this.newOptions = val;
				}
			},
			formErrors: {
				handler(errors) {
					this.errors = errors;
				},
				deep: true
			}
		},
		methods: {
			valueChanged(event) {
				this.onChange(this.name, event.target.value);
				this.errors = [];
			}
		}
	};
</script>