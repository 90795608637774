export default (rounds, hidden = 0) => {
    if (!rounds) {
        return null
    }
    const allRounds = () => {
        let matches = []
        rounds.forEach(item => {
            matches.push(...item.games)
        })
        matches = matches.map(item => {
            return {
                ...item,
                games: []
            }
        })
        return matches
    }

    const findRecursiveMatches = (id) => {
        const result = []
        const find = allRounds().filter(item => item.next_match_id === id)
        for (const item in find) {
            if (find[item].round > hidden) {
                find[item].games = findRecursiveMatches(find[item].id)
                result.push(find[item])
            }
        }
        return result
    }

    const parentMatch = rounds[rounds.length - 1].games[0]

    parentMatch.games = findRecursiveMatches(parentMatch.id)
    return parentMatch ?? null
}
