<template>
	<div
		class="page"
		id="page"
		:class="[currentPageClass()]"
        v-if="isLoadedData"
	>
		<PageHeader v-if="showPanel && !isLoginPage() && width < 992"/>
		<PageNavigation v-if="!isMobile && showPanel && !isLoginPage()"/>
		<PageMobileNavigation v-if="isMobile && showPanel && !isLoginPage()"/>
		<PageMain/>
        <notifications group="notify" position="right bottom" />
        <ToastNotifier></ToastNotifier>
	</div>
</template>

<script>
	import SvgIcon from '../components/SvgIcon'
	import Dropdown from '../components/Dropdown'
	import ToastNotifier from '../components/ToastNotifierComponent.vue'
	import PageNavigation from '../components/Page/Navigation'
	import PageMobileNavigation from '../components/Page/MobileNavigation'
	import PageHeader from '../components/Page/Header'
	import PageMain from '../components/Page/Main'

    import {mapActions, mapGetters} from 'vuex'

	export default {
        data () {
            return {
                isLoadedData: false
            }
        },
		components: {
			SvgIcon,
			Dropdown,
			PageNavigation,
			PageMobileNavigation,
			PageHeader,
			PageMain,
            ToastNotifier
		},
		computed: {
			...mapGetters([
				'showPanel',
				'isMobile'
            ]),
            width () {
                return window.innerWidth
            }
		},
		methods: {
            ...mapActions({
                getUser: 'GET_USER',
                getConfig: 'getConfig'
            }),
			currentPageClass() {
				let classes = '';

				if (this.showPanel && this.isLoginPage()) {
					return 'page-login';
				}

				if (this.isMobile && this.$route.name !== 'tournament-public-brackets') {
					classes += ' mobile'
				}

				return 'page-' + this.$route.name + classes;
			},
			isLoginPage() {
				return 'login' === this.$route.name
			},
            async loadCriticalData ()
            {
                await this.getUser().catch(() => {})
                await this.getConfig().catch(() => {})

                this.$nextTick(() => {
                    this.isLoadedData = true
                })
            }
		},
        created () {
            this.loadCriticalData()
        }
	}
</script>
