<template>
	<div
		class="brackets__recreate"
		v-if="showControls"
	>
		<button
			class="brackets__recreate__button"
			@click.prevent="recreate()"
		>
			<SvgIcon
				width="18"
				height="11"
				:viewBox="'0 0 18 11'"
				:iconType="'recreate'"
			/>
			<span>{{$t('Recreate')}}</span>
		</button>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'
	import SvgIcon from '../SvgIcon'

	export default {
		name: "BracketItemRecreate",
		components: {
			SvgIcon
		},
		props: {
			data: Object
		},
		computed: {
			currentMatch: function () {
				return this.data;
			},
			showControls: function () {
				return ['CONFLICT'].includes(this.currentMatch.match_status) && this.currentMatch.is_autohandled;
			},
			...mapGetters({
				tournament: 'tournament',
				status: 'tournamentStatus'
			}),
		},
		methods: {
			...mapActions({
				recreateMatch: 'recreateMatch'
			}),
			recreate() {
				const data = {
					matchId: this.currentMatch.id,
					tournamentId: this.tournament.getId()
				};

				this.recreateMatch(data);
			}
		}
	};
</script>
