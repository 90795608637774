<template>
	<div v-if="rule">
		<page-links v-bind:links="[
			{
				url:  '/rule/' +  rule.getId(),
				text: rule.getTitle()
			}
		]"/>
		<div class="post">
			<ul class="post__list">
				<li>
					<span class="post__title">{{$t('ID')}}</span>
					<span class="post__description">{{ rule.getId() }}</span>
				</li>
				<li>
					<span class="post__title">{{$t('Title')}}</span>
					<span class="post__description">{{ rule.getTitle() }}</span>
				</li>

                <li v-if="rule.getGame()">
                    <span class="post__title">{{$t('Game')}}</span>
                    <span class="post__description">{{ rule.getGame().name }}</span>
                </li>

				<li>
					<span class="post__title">{{$t('Content')}}</span>
					<span class="post__description ql-editor" v-html="removeInjectedStyles(rule.getContent())"></span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
    import FormMultiSelect from 'vue-multiselect'
    import FormNotification from '../../components/Form/Notification'
    import SvgIcon from '../../components/SvgIcon'
	import PageLinks from '../../components/Page/Links'
	import helpers from "../../helpers";
    import { mapActions, mapGetters } from 'vuex';
	export default {
		props: ["id"],
		components: {
			SvgIcon,
			PageLinks,
            FormNotification,
            FormMultiSelect
		},
		computed: {
			...mapGetters({
				rule: 'rule',
                games: 'games'
            })
		},
        methods: {
          ...mapActions({
              getGames: 'getGames',
          })
        },
		data() {
			return {
				removeInjectedStyles: helpers.removeRulesStyles
			}
		},
		created() {
			this.$store.dispatch('GET_RULE', this.id);
		}
	}
</script>
