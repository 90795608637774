export default (brackets) => {
	let matches = [];
	brackets.forEach((round, roundIndex) => {
		round.games.forEach((match) => {
			let newMatch = {
				match_id: null,
				participants: [],
			};

			if (match.first_participant) {
				newMatch.participants.push(match.first_participant.id);
			}

			if (match.second_participant) {
				newMatch.participants.push(match.second_participant.id);
			}

			if (match.id) {
				newMatch.match_id = match.id;
			}

			matches.push(newMatch);
		});
	});

	return matches;
}
