<template>
	<div>
		<PageLinks v-if="country" :links="[
			{
				url: '/countries/edit/' +  country.id,
				text: country.value,
				classes: 'active'
			}
		]"/>
		<form class="form form--xl">
			<div class="form-group">
				<FormInput
					:name="'value'"
					:label="$t('Name')"
					:placeholder="$t('Name')"
					:value="form.value"
					:formErrors="errors.value"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group">
				<FormInput
					:name="'supported_locales'"
					:label="$t('Supported locales')"
					:placeholder="$t('Locales')"
					:value="form.supported_locales"
					:formErrors="errors.supported_locales"
					:onChange="formFieldChanged"
				/>
			</div>
            <div class="form-group">
                <label class="form-label" for="">Supported wallets</label>
                <multiselect placeholder="Select support wallets" :searchable="false" id="wallets" v-model="form.available_wallets" :options="[
                    'EUR',
                    'COINS'
                ]" :multiple="true"></multiselect>
                <p class="form-field-description" style="color: red;">Note: This affects the winnings of tournaments, if the user belongs to this country, he will be awarded a prize depending on whether the currency is maintained in the country</p>
            </div>
			<FormNotification :errors="errors.message"/>
			<button
				class="button button-primary form-button-submit"
				@click.prevent="save()"
			>
				{{$t('Save')}}
			</button>
		</form>
	</div>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'

	import SvgIcon from '../../components/SvgIcon'
	import PageLinks from '../../components/Page/Links'
	import FormNotification from '../../components/Form/Notification'
	import FormInput from '../../components/Form/Input'
    import Multiselect from "vue-multiselect";

	export default {
		props: ["id"],
		components: {
			SvgIcon,
			PageLinks,
			FormNotification,
			FormInput,
            Multiselect
		},
		computed: {
			...mapGetters([
				'country'
			])
		},
		data() {
			return {
				form: {
					value: '',
					supported_locales: '',
                    available_wallets: []
				},
				errors: {},
				defaultError: {
					message: [
						this.$t('Something went wrong. Please try again.')
					]
				}
			};
		},
		created() {
			this.getFormData();
		},
		watch: {
			$route() {
				this.getFormData();
			},
		},
		methods: {
			...mapActions({
				getCountry: 'getCountry',
				updateCountry: 'updateCountry',
			}),
			formFieldChanged(field, value) {
				this.form[field] = value;
			},
			async getFormData() {
				if (this.id) {
					await this.getCountry(this.id);

					this.form = {
						value: this.country.value,
						supported_locales: this.country.supported_locales.join(','),
                        available_wallets: this.country.available_wallets
					};
				}
			},
			save() {
				if (this.id) {
					const data = Object.assign({}, this.form);

					data.supported_locales = data.supported_locales.split(',');

					this.updateCountry({
						id: this.id,
						data: data
					})
						.catch(err => {
							this.errorsHandler(err.response.data.errors)
						});
				}
			},
			errorsHandler(error) {
				this.errors = error ? error : this.defaultError;
			},
		}
	}
</script>
