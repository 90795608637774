import moment from 'moment'

export default (day) => {
	const date = moment(day);
	date.set({
		hour: 0,
		minute: 0,
		second: 0,
	});
	const timeZone = new Date().getTimezoneOffset();
	const dateWithTimeZone = moment(String(date)).add((timeZone / 60), 'hours').format('YYYY-MM-DD HH:mm:ss');

	return new Date(dateWithTimeZone);
}

