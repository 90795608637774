<template>
    <div class="radial__checkbox">
        <input :value="inputValue" v-model="model" :id="id" type="checkbox">
        <label :for="id">
            <span class="radial"></span>
        </label>
    </div>
</template>
<script>
export default {
    data () {
        return {
            id: Math.random().toString(36).slice(2, 7)
        }
    },
    props: {
        value: Boolean || Number,
        inputValue: Boolean,
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
                this.$emit("change", value);
            },
        },
    },

}
</script>
<style scoped lang="scss">
    .radial__checkbox {
        cursor: pointer;
        input {
            display: none;
        }
        label {
            cursor: pointer;
            background: #C4C4C4;
            border-radius: 121px;
            width: 36px;
            height: 19px;
            position: relative;
            display: flex;
            align-items: center;
            transition: 0.2s;
            padding: 2px;
            .radial {
                transition: 0.2s;
                height: 100%;
                width: 16px;
                border-radius: 100%;
                background: #878787;
            }
        }
        input:checked + label {
            background: #00E7DF;
            .radial {
                transform: translateX(15px);
                background: #06C2BC;
            }
        }
    }
</style>
