<template>
    <div class="exchanges">
        <div class="exchanges-body">
            <div class="exchanges-title">
                Turqs
            </div>
            <div class="settings__items">
                <div class="setting__item" :class="{'active': isTurqEnabled}">
                    <div class="setting__item-label">TurQ</div>
                    <RadialCheckbox v-model="isTurqEnabled"></RadialCheckbox>
                </div>
            </div>

            <div v-for="(wallet, i) in wallets" :key="i" class="setting__wallets">
                <div class="setting__wallet">
                    <div class="setting__wallet-input">
                        <div class="setting__wallet-input-label">
                            Name
                        </div>
                        <div class="setting__wallet-input-value">
                            <input type="text" v-model="wallet.name" placeholder="Name">
                        </div>
                    </div>
                    <div class="setting__wallet-input">
                        <div class="setting__wallet-input-label">
                            Code
                        </div>
                        <div class="setting__wallet-input-value">
                            <input type="text" v-model="wallet.code" placeholder="">
                        </div>
                    </div>
                    <div class="setting__wallet-input">
                        <div class="setting__wallet-input-label">
                            Symbol
                        </div>
                        <div class="setting__wallet-input-value">
                            <input type="text" v-model="wallet.symbol" placeholder="">
                        </div>
                    </div>
                    <div class="setting__wallet-input">
                        <div class="setting__wallet-input-label">
                            Rate
                        </div>
                        <div class="setting__wallet-input-value">
                            <input type="number" v-model="wallet.rate" placeholder="">
                        </div>
                    </div>
                    <div class="setting__wallet-remove" @click="removeWallet(i)">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.26383 5.98298L11.6681 2.57872C12.1106 2.17021 12.1106 1.48936 11.6681 1.08085L10.9191 0.331915C10.5106 -0.110638 9.82979 -0.110638 9.42128 0.331915L6.01702 3.73617L2.57872 0.331915C2.17021 -0.110638 1.48936 -0.110638 1.08085 0.331915L0.331915 1.08085C-0.110638 1.48936 -0.110638 2.17021 0.331915 2.57872L3.73617 5.98298L0.331915 9.42128C-0.110638 9.82979 -0.110638 10.5106 0.331915 10.9191L1.08085 11.6681C1.48936 12.1106 2.17021 12.1106 2.57872 11.6681L6.01702 8.26383L9.42128 11.6681C9.82979 12.1106 10.5106 12.1106 10.9191 11.6681L11.6681 10.9191C12.1106 10.5106 12.1106 9.82979 11.6681 9.42128L8.26383 5.98298Z" fill="#878787"/>
                        </svg>
                    </div>
                </div>
                <div class="setting__minimum">
                    <label for="">Minimum turq to withdraw</label>
                    <input type="number" v-model="wallet.minimal_withdraw" placeholder="Minimum">
                </div>
            </div>

            <button :disabled="isDisabledAdd" class="add__more" @click="addWallet">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.375 5.4375H7.4375V1.5C7.4375 1.03516 7.02734 0.625 6.5625 0.625H5.6875C5.19531 0.625 4.8125 1.03516 4.8125 1.5V5.4375H0.875C0.382812 5.4375 0 5.84766 0 6.3125V7.1875C0 7.67969 0.382812 8.0625 0.875 8.0625H4.8125V12C4.8125 12.4922 5.19531 12.875 5.6875 12.875H6.5625C7.02734 12.875 7.4375 12.4922 7.4375 12V8.0625H11.375C11.8398 8.0625 12.25 7.67969 12.25 7.1875V6.3125C12.25 5.84766 11.8398 5.4375 11.375 5.4375Z" fill="black"/>
                </svg>
                Add more currency
            </button>
            <button @click="save" class="save">
                Save
            </button>
        </div>
    </div>
</template>
<script>
import SettingsService from '../../api/settings'
import RadialCheckbox from '../../components/UI/RadialCheckbox'
import {updateConfig} from "../../api/config";
import { mapGetters } from 'vuex'
export default {
    data () {
        return {
            isTurqEnabled: false,
            wallets: []
        }
    },
    computed: {
        ...mapGetters({
            config: 'config'
        }),
        isDisabledAdd () {
            return this.wallets.find((item) => {
                return (!item.name || !item.code || !item.symbol || !item.rate || !item.minimal_withdraw)
            })
        }
    },
    watch: {
        isTurqEnabled () {
            updateConfig('turq_enabled', this.isTurqEnabled);
        }
    },
    methods: {
        removeWallet (index) {
            this.wallets.splice(index, 1)
        },
        addWallet () {
            this.wallets.push({
               name: undefined,
               code: undefined,
               symbol: undefined,
               rate: undefined,
               minimal_withdraw: undefined
            });
        },
        save () {
            const rates = this.wallets.filter(item => item.name && item.code && item.symbol && item.rate && item.minimal_withdraw)
            SettingsService.saveExchangeRates({ rates: rates }).then(response => {
                this.$notify({
                    type: 'success',
                    title: 'Success update',
                    group: 'notify',
                    text: 'Success update exchange rates'
                })
            }).catch(err => {
                this.$notify({
                    type: 'error',
                    title: 'Error update',
                    group: 'notify',
                    text: JSON.stringify(err)
                })
            })
        },
        init () {
            SettingsService.getExchangeRates().then(response => {
                this.wallets = response.data.data
            })


        },
        setConfig () {
            const findConfig = this.config.find(item => item.config_name === 'turq_enabled')
            if (findConfig) {
                this.isTurqEnabled = parseInt(findConfig.config_value)
            }
        }
    },
    mounted () {
      this.init()
      this.setConfig()
    },
    components: {
        RadialCheckbox
    }
}
</script>
<style scoped lang="scss">
.exchanges {
    .exchanges-body {
        width: 377px;
        .exchanges-title {
            background: #ECECEC;
            border-radius: 4px;
            padding: 14px 15px;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #303030;
            margin-bottom: 20px;
        }
        .settings__items {
            .setting__item {
                border: 1px solid #878787;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px;
                transition: 0.2s;

                .setting__item-label {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    transition: 0.2s;
                    color: gray
                }
                &.active {
                    border: 1px solid #06C2BC;
                    box-shadow: 0px 0px 4px rgba(0, 80, 77, 0.1), 0px 2px 10px rgba(0, 80, 77, 0.25);
                    .setting__item-label {
                        color: #06C2BC;
                    }
                }
            }
        }
        .setting__wallets {
            margin-top: 15px;
            .setting__wallet {
                display: flex;
                align-items: flex-end;
                .setting__wallet-input {
                    .setting__wallet-input-label {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: #06C2BC;
                        margin-bottom: 6px;
                        display: block;
                    }
                    .setting__wallet-input-value {
                        input {
                            width: 55px;
                            height: 50px;
                            text-align: center;
                            border: 1px solid #878787;
                            border-radius: 4px;
                            outline: none;
                            background: transparent;
                        }
                    }
                }
                .setting__wallet-input:first-child {
                    .setting__wallet-input-value {
                        input {
                            width: 99px;
                            min-width: 99px;
                        }
                    }
                }
                .setting__wallet-input + .setting__wallet-input {
                    margin-left: 15px;
                }

                .setting__wallet-remove {
                    width: 50px;
                    min-width: 50px;
                    height: 50px;
                    background: #E0E0E0;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: auto;
                    cursor: pointer;
                    transition: 0.2s;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
            .setting__minimum {
                margin-top: 15px;
                label {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #06C2BC;
                    margin-bottom: 6px;
                    display: block;
                }
                input {
                    outline: none;
                    padding: 17px 15px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    width: 100%;
                    color: #2F2F2F;
                    border: 1px solid #878787;
                    border-radius: 4px
                }
            }
        }
        .add__more {
            width: 100%;
            background: #E0E0E0;
            border-radius: 4px;
            padding: 17px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            margin-top: 15px;
            border: none;
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #303030;
            cursor: pointer;
            transition: 0.2s;
            &:hover {
                opacity: 0.7;
            }
            svg {
                margin-right: 10px;
            }
            &[disabled] {
                opacity: 0.7;
                cursor: default;
            }
        }
        .save {
            width: 100%;
            color: #303030;
            cursor: pointer;
            font-size: 14px;
            margin-top: 15px;
            background: #00E7DF;
            border-radius: 4px;
            outline: none;
            transition: 0.2s;
            border: none;
            font-weight: bold;
            &:hover {
                opacity: 0.7;
            }
            padding: 17px 0;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
