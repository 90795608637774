<template>
	<div>
		<div class="page__content-header">
			<div>
				<PageLinks v-bind:links="[
					{
						url: '/bracket-templates',
						text: $t('Bracket templates')
					}
				]"/>
			</div>
			<div>
				<RouterLink to="/bracket-templates/add" :class="'button button-primary'">
					{{$t('Create template')}}
				</RouterLink>
			</div>
		</div>
		<div>
			<FiltersBar :options="filters" :onChange="filtersChanged" />
			<div class="table-wrapper">
				<table v-if="templates">
					<thead class="text-left">
					<tr>
						<th scope="col">{{$t('ID')}}</th>
						<th scope="col">{{$t('Name')}}</th>
						<th scope="col">{{$t('Game')}}</th>
						<th scope="col">{{$t('Rounds')}}</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="template in templates">
						<td scope="row">{{ template.getId() }}</td>
						<td>{{ template.getName() }}</td>
						<td>{{ template.getGame() }}</td>
						<td scope="row">{{ template.getRounds().length }}</td>
						<td scope="row">
							<Dropdown
								:data-position="'right'"
								:data-horizontal-aligntment="'right'"
								:data-vertical-aligntment="'top'"
								:toggle-class="'dots-toggle'"
							>
								<SvgIcon
									slot="dropdown-toggle"
									width="23"
									height="5"
									view-box="0 0 23 5"
									:iconType="'dots'"
								/>
								<div slot="dropdown-content">
									<RouterLink
										:class="'dropdown__link'"
										:to="{path: '/bracket-templates/edit/' +  template.getId()}"
									>
										<SvgIcon
											width="16"
											height="16"
											:iconType="'edit'"
										/>
										<span>{{$t('Edit')}}</span>
									</RouterLink>
									<a
										href="#"
										class="dropdown__link"
										@click.prevent="deleteTemplateById(template.getId())"
									>
										<SvgIcon
											width="16"
											height="16"
											:iconType="'delete'"
										/>
										<span>{{$t('Delete')}}</span>
									</a>
								</div>
							</Dropdown>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<PagePagination
				v-if="meta"
				:current="meta.currentPage()"
				:total="meta.lastPage()"
				@change="changePage"
			/>
		</div>
	</div>
</template>

<script>
    import {mapGetters, mapActions, mapMutations} from 'vuex'

	import SvgIcon from '../../components/SvgIcon';
	import Dropdown from '../../components/Dropdown';
	import FiltersBar from '../../components/FiltersBar';
	import PagePagination from '../../components/Page/Pagination';
	import PageLinks from '../../components/Page/Links';

	export default {
		components: {
			SvgIcon,
			Dropdown,
			PageLinks,
			FiltersBar,
			PagePagination
		},
		computed: {
			...mapGetters({
				templates: 'bracketTemplates',
				meta: 'bracketTemplatesMeta',
				games: 'games',
                activeFilters: 'activeFilters'
            })
		},
		data() {
			return {
				filters: [],
				filtersUtil: this.$options.filters
			}
		},
		created() {
			this.init();
		},
		methods: {
			...mapActions([
				'getTemplates',
				'deleteTemplate',
				'getGames'
			]),
            ...mapMutations({
                setFilters: 'SET_FILTERS',
            }),
            async init() {
                await this.getTemplates(this.activeFilters('templates'));
                await this.prepareFiltersFields();
            },
			changePage(page) {
                const activeFilters = this.activeFilters('templates');

                this.getTemplates({
					page: page,
                    ...activeFilters
				});
			},
            async deleteTemplateById(id) {
                const activeFilters = this.activeFilters('templates');

                await this.deleteTemplate(id);
                await this.getTemplates(activeFilters);
            },
            async prepareFiltersFields() {
                const activeFilters = this.activeFilters('templates');

                await this.getGames();
                this.filters.push({
                    type: 'select',
                    name: 'game_id',
                    value: activeFilters.hasOwnProperty('game_id') ? activeFilters.game_id : null,
                    options: this.filtersUtil.gamesOptions(this.games)
                });
            },
			filtersChanged(field, value) {
                this.setFilters({
                    type: 'templates',
                    key: field,
                    value: value ? value : null
                });

                this.getTemplates(this.activeFilters('templates'));
			}
		},
	}
</script>
