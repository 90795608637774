import http from '../utils/http'
import joinUrls from '../utils/join-urls'

export default {
	get(data = {}) {
		return http.get('/regions', data)
	},
	getRegion(id) {
		return http.get(joinUrls('/regions', id))
	},
	add(data) {
		return http.post(joinUrls('/regions'), data)
	},
	update(id, data) {
		if (!id) {
			return Promise.reject(new Error('Region id is required to update regions data'))
		}

		return http.put(joinUrls('/regions', id), data)
	},
	deleteRegion(id) {
		if (!id) {
			return Promise.reject(new Error('Region id is required to delete region'))
		}

		return http.delete(joinUrls('/regions', id))
	},
}