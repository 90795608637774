var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('page-links',{attrs:{"links":[
		{
			url: '/deep-links/add',
			text: _vm.$t('Add Deep Links'),
			classes: 'active'
		}
	]}}),_c('form',{staticClass:"form form--xl"},[_c('div',{staticClass:"form-group"},[_c('FormInput',{attrs:{"name":'source',"label":_vm.$t('Source'),"placeholder":_vm.$t('Source'),"value":_vm.form.source,"formErrors":_vm.errors.source,"onChange":_vm.formFieldChanged}})],1),_c('div',{staticClass:"form-group"},[_c('FormSelect',{attrs:{"name":'game_id',"label":_vm.$t('Game'),"value":_vm.form.game_id,"options":_vm.gamesOptions,"formErrors":_vm.errors.game_id,"onChange":_vm.formFieldChanged}})],1),_c('div',{staticClass:"form-group"},[_c('FormSelect',{attrs:{"name":'platform_id',"label":_vm.$t('Platform'),"value":_vm.form.platform_id,"options":_vm.platformsOptions,"formErrors":_vm.errors.platform_id,"onChange":_vm.formFieldChanged}})],1),_c('FormNotification',{attrs:{"errors":_vm.errors.message}}),_c('button',{staticClass:"button button-primary form-button-submit",on:{"click":function($event){$event.preventDefault();return _vm.addLink()}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }