<template>
    <div>
        <div class="page__content-header">
            <div>
                <PageLinks v-bind:links="[
					{
						url: '/terms',
						text: $t('All Terms')
					}
				]"/>
            </div>
            <div>
                <RouterLink to="/terms/add" :class="'button button-primary'">{{$t('Create New Terms')}}</RouterLink>
            </div>
        </div>
        <div class="table-wrapper">
            <table v-if="terms">
                <thead class="text-left">
                <tr>
                    <th scope="col">{{$t('ID')}}</th>
                    <th scope="col">{{$t('Terms title')}}</th>
                    <th scope="col">{{$t('Terms version')}}</th>
                    <th scope="col">{{$t('Terms country')}}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="term in terms">
                    <td scope="row" class="cursor-pointer">{{ term.getId() }}</td>
                    <td scope="row" class="cursor-pointer">{{ term.getTitle() }}</td>
                    <td scope="row" class="cursor-pointer">{{ term.getVersion() }}</td>
                    <td scope="row" class="cursor-pointer">{{ term.getCountryName() }}</td>
                    <td>
                        <dropdown
                            :data-position="'left'"
                            :data-horizontal-aligntment="'left'"
                            :data-vertical-aligntment="'top'"
                            :toggle-class="'dots-toggle'"
                        >
                            <svg-icon
                                slot="dropdown-toggle"
                                width="23"
                                height="5"
                                view-box="0 0 23 5"
                                :iconType="'dots'"
                            />
                            <div slot="dropdown-content">
                                <a
                                    href="#"
                                    class="dropdown__link"
                                    @click.prevent="deleteById(term.getId())"
                                >
                                    <svg-icon
                                        width="16"
                                        height="16"
                                        :iconType="'delete'"
                                    />
                                    <span>{{$t('Delete')}}</span>
                                </a>
                            </div>
                        </dropdown>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <PagePagination
            v-if="termsMeta"
            :current="termsMeta.currentPage()"
            :total="termsMeta.lastPage()"
            @change="changePage"
        />
    </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'

import router from '../../routes';
import SvgIcon from '../../components/SvgIcon';
import Dropdown from '../../components/Dropdown';
import PagePagination from '../../components/Page/Pagination';
import PageLinks from '../../components/Page/Links';

export default {
    components: {
        SvgIcon,
        Dropdown,
        PageLinks,
        PagePagination,
    },
    computed: {
        ...mapGetters([
            'terms',
            'termsMeta',
        ])
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations({
            hideDropdown: 'HIDE_DROPDOWN',
        }),
        ...mapActions({
            getTerms: 'GET_TERMS',
            deleteTerms: 'DELETE_TERM',
        }),
        async init() {
            await this.getTerms();
        },
        async deleteById(id) {
            this.hideDropdown();
            await this.deleteTerms(id);
        },
        goToPage(url) {
            router.push(url)
        },
        changePage(page) {
            this.getTerms({
                page: page,
            });
        },
    },
}
</script>
