import clans from '../../api/clans'

const state = () => ({
	clan: null,
});

const getters = {
	clan: state => state.clan
};

const actions = {
	getClan: async ({commit}, id) => {
		const response = await clans.getClan(id);

		commit('UPDATE_CLAN', response.data.data);
	}
};

const mutations = {
	UPDATE_CLAN: (state, data) => {
		state.clan = data;
	}
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
