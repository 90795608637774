import http from '../utils/http'
import joinParams from "../utils/join-params"

export default {
    get (params) {
        return http.get(joinParams('/permissions', params))
    },
    add (params) {
        return http.post(joinParams('/permissions', params))
    },
    delete (params) {
        return http.delete(joinParams('/permissions', params))
    }
}
