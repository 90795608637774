<template>
	<div>
		<div class="page__content-header">
			<PageLinks :links="[
					{
						url: '/tournament/' +  tournament.getId(),
						text: tournament.getTitle()
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/brackets',
						text: $t('Brackets')
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/participants',
						text: $t('Participants')
					}
				]"/>
			<div class="page-content__actions">
				<BracketsRoundsScheduleButton/>
                <BracketsStartTournamentButton/>
                <BracketsRestoreTournamentButton/>
			</div>
		</div>
		<div class="page__content-body" :key="key">
			<BracketsEmpty/>
            <template v-if="tournament.getType() === 3">
<!--                <BracketsDoubleEllimination v-if="showBrackets()" :id="id" type="upper"></BracketsDoubleEllimination>-->
                <div class="bracket__type" v-if="showBrackets()">
                    Upper bracket
                    <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.85739 9.7448C8.2079 10.0851 8.7921 10.0851 9.14261 9.7448L16.7371 2.41021C17.0876 2.03214 17.0876 1.46503 16.7371 1.12476L15.8414 0.255199C15.4908 -0.0850665 14.9066 -0.0850665 14.5172 0.255199L8.48053 6.0775L2.48282 0.255199C2.09336 -0.0850659 1.50916 -0.0850659 1.15865 0.255199L0.262886 1.12476C-0.0876292 1.46503 -0.0876292 2.03214 0.262886 2.41021L7.85739 9.7448Z" fill="#06C2BC"/>
                    </svg>
                </div>
                <Brackets @update="key++, $forceUpdate" type="upper" :id="id" v-if="showBrackets()"/>
                <div class="bracket__type" v-if="showBrackets()">
                    lower bracket
                    <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.85739 9.7448C8.2079 10.0851 8.7921 10.0851 9.14261 9.7448L16.7371 2.41021C17.0876 2.03214 17.0876 1.46503 16.7371 1.12476L15.8414 0.255199C15.4908 -0.0850665 14.9066 -0.0850665 14.5172 0.255199L8.48053 6.0775L2.48282 0.255199C2.09336 -0.0850659 1.50916 -0.0850659 1.15865 0.255199L0.262886 1.12476C-0.0876292 1.46503 -0.0876292 2.03214 0.262886 2.41021L7.85739 9.7448Z" fill="#06C2BC"/>
                    </svg>
                </div>
                <div style="padding-left: 300px;">
                    <Brackets @update="key++, $forceUpdate" type="lower" :id="id" v-if="showBrackets()"/>
                </div>

            </template>
            <template v-else>
                <Brackets @update="key++, $forceUpdate" :id="id" v-if="showBrackets()"/>
            </template>
			<ChatsListContainer :id="id" v-if="showBrackets()"/>
			<BracketsRoundsScheduleDialog v-if="showBrackets()"/>
			<BracketsGeneratingLoader v-if="isPendingStatus()"/>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'
    import BracketsDoubleEllimination from '../../components/Brackets/double/BracketsDoubleEllimination'
	import PageLinks from '../../components/Page/Links'
	import Brackets from '../../components/Brackets/Brackets'
	import BracketsEmpty from '../../components/Brackets/BracketsEmpty'
	import BracketsRoundsScheduleDialog from '../../components/Brackets/BracketsRoundsScheduleDialog'
	import BracketsRoundsScheduleButton from '../../components/Brackets/BracketsRoundsScheduleButton'
	import BracketsStartTournamentButton from '../../components/Brackets/BracketsStartTournamentButton'
	import BracketsGeneratingLoader from '../../components/Brackets/BracketsGeneratingLoader'
	import ChatsListContainer from '../../components/Chat/ChatsListContainer'
    import BracketsRestoreTournamentButton from '../../components/Brackets/BracketsRestoreTournamentButton'
	export default {
        data () {
            return {
                key: 0
            }
        },
	  name: "BracketsContainer",
	  props: {
			id: String || Number
		},
		components: {
			PageLinks,
			Brackets,
            BracketsRestoreTournamentButton,
			ChatsListContainer,
			BracketsEmpty,
			BracketsRoundsScheduleButton,
			BracketsRoundsScheduleDialog,
            BracketsDoubleEllimination,
			BracketsStartTournamentButton,
			BracketsGeneratingLoader
		},
		computed: {
			...mapGetters({
				tournament: 'tournament',
				tournamentStatus: 'tournamentStatus'
			}),
		},
		mounted() {
			this.init();
		},
		methods: {
			...mapActions({
				getTournament: 'GET_TOURNAMENT',
				centrifugeConnect: 'centrifugeConnect'
			}),
			async init() {
				await this.getTournament(this.id);
				await this.centrifugeConnect();
			},
			isPendingStatus() {
				return 'PENDING' === this.tournamentStatus;
			},
			showBrackets() {
				const status = this.tournamentStatus;
				return ['REGISTRATION_CLOSED', 'STARTED', 'IN_PROGRESS', 'FINISHED', 'CLOSED'].includes(status);
			}
		}
	}
</script>
<style scoped lang="scss">
.bracket__type {
    margin-top: 15px;
    background: #ECECEC;
    border-radius: 4px;
    padding: 14px 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
    color: #303030;
}
</style>
