import events from '../../api/events'
import tournaments from "../../api/tournaments";
import moment from 'moment'

const state = () => ({
	events: [],
	dayEvents: [],
	selectedDay: null,
	selectedMonth: new Date(),
	exportedEvents: []
});

const getters = {
	events: state => state.events,
	dayEvents: state => state.dayEvents,
	selectedDay: state => state.selectedDay,
	selectedMonth: state => state.selectedMonth,
	getEventsForExport: state => state.exportedEvents
};

const actions = {
	getEvents: async ({commit}, data) => {
		const response = await events.get(data);

		commit('UPDATE_EVENTS', response.data);
	},
	setSelectedDay: async ({commit}, data) => {
		const response  = await tournaments.get(data);

		commit('UPDATE_DAY_EVENTS', response.data.data);
		commit('UPDATE_SELECTED_DAY', data.day);
	},
	setSelectedMonth: ({commit}, month) => {
		commit('UPDATE_SELECTED_MONTH', month);
	},
	getEventsForExport: async ({commit}, data) => {
		const response  = await tournaments.get(data);

		commit('UPDATE_EXPORTED_EVENTS', response.data.data);
	}
};

const mutations = {
	UPDATE_EVENTS: (state, data) => {
		state.events = data;
	},
	UPDATE_EXPORTED_EVENTS: (state, data) => {
		let events = [];

		data.forEach(item => {
			events.push({
				name: item.title,
				platform: item.platform.name,
				game: item.game.name,
				date: new moment(item.started_at).format('DD.MM.YYYY HH:mm'),
				checked: item.participant_count,
				registered: item.registered_participant_count ? item.registered_participant_count : '',
                unique_checkin: item.unique_checkin,
                unique_registered: item.unique_registered
			});
		});

		state.exportedEvents = events;
	},
	UPDATE_DAY_EVENTS: (state, data) => {
		state.dayEvents = data;
	},
	UPDATE_SELECTED_DAY: (state, day) => {
		state.selectedDay = day;
	},
	UPDATE_SELECTED_MONTH: (state, month) => {
		state.selectedMonth = month;
	},
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
