import http from '../utils/http'

export default {
    get(data = {}) {
        return http.get('/admin/wallet/withdraws', data)
    },

    getEntity (id) {
        return http.get('/admin/wallet/withdraws/' + id, {})
    },

    getMessages (roomId) {
        return http.get(`/wallet/withdraw/messages/${roomId}`)
    },

    sendMessage (roomId, data) {
        return http.post(`/wallet/withdraw/messages/${roomId}`, data)
    },

    update (id, data) {
        return http.put(`/admin/wallet/withdraws/${id}`, data)
    }

}
