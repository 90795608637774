<template>
	<div>
		<PageLinks :links="[
			{
				url: id ? '/seasons/edit/' +  id : '/seasons/add',
				text: id ? $t('Edit Season') : $t('Add Season'),
				classes: 'active'
			}
		]"/>
		<form class="form form-add-seasons">
			<div class="form-group text-center">
				<ImagePicker
					:image="form.image"
					:label="$t('Image')"
					:formErrors="errors.image"
					:onChangeImage="onChangeImage"
					:onRemoveImage="onRemoveImage"
				/>
			</div>
			<div class="form-group" v-if="!isDefault">
				<FormSelect
					:name="'game_id'"
					:label="$t('Game')"
					:value="form.game_id"
					:options="gamesOptions"
					:formErrors="errors.game_id"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group form-group-2-cols" v-if="!isDefault">
				<div class="form-group__item">
					<label class="form-label">
						{{$t('Start season')}}
					</label>
					<Datetime
						type="date"
						v-model="form.started_at"
					/>
					<FormNotification :errors="errors.started_at"/>
				</div>
				<div class="form-group__item">
					<label class="form-label">
						{{$t('End season')}}
					</label>
					<Datetime
						type="date"
						v-model="form.finished_at"
					/>
					<FormNotification :errors="errors.finished_at"/>
				</div>
			</div>
			<FormNotification :errors="errors.message"/>
			<div class="form-repeater">
				<div class="form-repeater__title">
					{{$t('Language localization')}}
				</div>
				<div class="form-repeater__item" v-for="(item, index) in form.localizations" :key="index">
					<div class="form-group">
						<FormSelect
							:name="'locale-' + index"
							:label="$t('Choose language')"
							:value="form.localizations[index].locale"
							:options="localesOptions"
							:onChange="formFieldChanged"
						/>
					</div>
					<div class="form-group">
						<FormInput
							:name="'title-' + index"
							:label="$t('Season title')"
							:placeholder="$t('Title')"
							:value="form.localizations[index].title"
							:onChange="formFieldChanged"
						/>
					</div>
					<div class="form-group">
						<FormTextarea
							:name="'description-' + index"
							:label="$t('Season description')"
							:placeholder="$t('Description')"
							:value="form.localizations[index].description"
							:onChange="formFieldChanged"
						/>
					</div>
					<span
						class="form-delete-item"
						v-if="1 < form.localizations.length"
						@click="removeLocalization(index)"
					>
					<SvgIcon
						width="24"
						height="24"
						:iconType="'delete'"
						iconColor="#878787"
					/>
				</span>
				</div>
				<button
					class="button form-repeater__add-button"
					@click.prevent="addLocalization"
				>
					<SvgIcon
						width="16"
						height="16"
						:iconType="'plus'"
					/>
					<span>{{$t('Add localization')}}</span>
				</button>
			</div>
			<button
				class="button button-primary form-button-submit"
				@click.prevent="save()"
			>
				{{$t('Save')}}
			</button>
		</form>
	</div>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'

	import helpers from '../../helpers'
	import SvgIcon from '../../components/SvgIcon'
	import PageLinks from '../../components/Page/Links'
	import FormNotification from '../../components/Form/Notification'
	import FormInput from '../../components/Form/Input'
	import FormSelect from '../../components/Form/Select'
	import ImagePicker from '../../components/Form/ImagePicker'
	import FormTextarea from '../../components/Form/Textarea'
	import {Datetime} from 'vue-datetime'
	import 'vue-datetime/dist/vue-datetime.css'
  import moment from 'moment'

	import {LOCALES} from '../../constants/locales'

	export default {
		props: ["id"],
		components: {
			SvgIcon,
			PageLinks,
			FormNotification,
			FormInput,
			FormSelect,
			ImagePicker,
			FormTextarea,
			Datetime,
		},
		computed: {
			...mapGetters([
				'season',
				'games',
			]),
			isEdit: function () {
				return this.id;
			},
			isDefault: function () {
				return this.id && this.id === 'default';
			}
		},
		data() {
			return {
				form: {
					started_at: moment().utc().format(),
					finished_at: moment().utc().format(),
					localizations: [{
						locale: 'en',
						title: '',
						description: '',
					}],
					game_id: 12,
					image: '',
				},
				imageChanged: false,
				gamesOptions: [],
				localesOptions: LOCALES,
				errors: {},
			};
		},
		created() {
			this.getFormData();
		},
		watch: {
			$route() {
				this.getFormData();
			},
		},
		methods: {
			...mapActions({
				getSeason: 'getSeason',
				addSeason: 'addSeason',
				updateSeason: 'updateSeason',
				getGames: 'getGames',
			}),
			formFieldChanged(field, value) {
				if (field.includes('locale') || field.includes('title') || field.includes('description')) {
					const fields = field.split('-');
					this.form.localizations[fields[1]][fields[0]] = value ? value : null;
				} else {
					this.form[field] = value;
				}
			},
			async getFormData() {
				await this.getGames();

				if (this.isEdit) {
					await this.getSeason(this.id);
				}

				this.prepareFieldsAfterLoad();
			},
			save() {
				!this.id ? this.newSeason() : this.updateExistSeason();
			},
			async newSeason() {
				const data = helpers.copyObject(this.form);
				data.started_at = helpers.prepareDateToSave(data.started_at);
				data.finished_at = helpers.prepareDateToSave(data.finished_at);

				await this.addSeason(data);
			},
			updateExistSeason() {
				let data = helpers.copyObject(this.form);
				data.started_at = helpers.prepareDateToSave(data.started_at);
				data.finished_at = helpers.prepareDateToSave(data.finished_at);

				const defaultSeasonData = {
					image: data.image,
					localizations: data.localizations,
				};

				this.updateSeason({
					id: this.season.id,
					data: this.isDefault ? defaultSeasonData : data
				});
			},
			errorsHandler(error) {
				this.errors = error ? error : this.defaultError;
			},
			onChangeImage(image) {
				this.form.image = image;
				this.imageChanged = true;
			},
			onRemoveImage() {
				this.form.image = null;
				this.imageChanged = true;
			},
			prepareFieldsAfterLoad() {
				this.initGamesOptions();

				if (this.isEdit) {
					this.form = {
						image: this.season.image,
						game_id: this.season.game_id,
						localizations: this.season.localizations,
						started_at: this.season.started_at,
						finished_at: this.season.finished_at,
					};
				}
			},
			initGamesOptions() {
				const isEmpty = this.form.game_id === '';
				this.gamesOptions = this.games.map(item => {
					return {value: item.id, name: item.name}
				});

				this.form.game_id = isEmpty ? this.gamesOptions[0].value.toString() || '' : this.form.game_id.toString();
			},
			removeLocalization(index) {
				this.form.localizations.splice(index, 1);
			},
			addLocalization() {
				this.form.localizations.push({
					locale: 'en',
					title: '',
					description: '',
				})
			},
		}
	}
</script>
