<template>
    <div class="form-input-field">
        <label>
            <span class="form-label">{{ label }}</span>
            <input
                class="form-input"
                :type="type"
                :name="name"
                :maxlength="maxlength"
                :placeholder="placeholder"
                v-model="newValue"
                :disabled="disabled"
                @input="valueInput"
                @change="valueChanged"
            >
        </label>
        <FormNotification :errors="errors"/>
    </div>
</template>

<script>
import FormNotification from '../../components/Form/Notification'

export default {
    name: "FormInput",
    components: {
        FormNotification
    },
    data() {
        return {
            newValue: '',
            errors: this.formErrors,
        }
    },
    props: {
        type: {
            type: String,
            default: "text"
        },
        name: {
            type: String,
        },
        value: {
            type: String,
        },
        disabled: {
            default: false,
            type: Boolean
        },
        placeholder: {
            type: String,
        },
        label: {
            type: String,
        },
        formErrors: {
            type: [Array, Object],
        },
        maxlength: {
            type: [Number, String]
        },
        onChange: {type: Function},
    },
    watch: {
        value: {
            handler(val) {
                this.newValue = val;
            }
        },
        formErrors: {
            handler(errors) {
                this.errors = errors;
            },
            deep: true
        }
    },
    mounted() {
        this.newValue = this.value;
    },
    methods: {
        valueInput() {
          this.$emit('onInput', event.target.value);
        },
        valueChanged(event) {
            if(!this.onChange) {
                return false;
            }
            this.onChange(this.name, event.target.value);
            this.errors = [];
        }
    }
};
</script>
<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
