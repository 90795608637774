import rulesApi from '../../api/rules'
import PageMeta from '../../dto/page-meta'
import Rule from "../../dto/rules";

const state = () => ({
	rules: null,
	rulesMeta: null,
	rule: null
});

const getters = {
	rules: state => state.rules,
	rulesMeta: state => state.rulesMeta,
	rule: state => state.rule
};

const actions = {
	GET_RULES: ({commit}, data = {}) => {
		return new Promise((resolve, reject) => {
			rulesApi.get(data)
				.then((resp) => {
					commit('UPDATE_RULES', resp.data.data);
					resolve(resp);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	GET_RULE: ({commit}, id) => {
		return new Promise((resolve, reject) => {
			rulesApi.getRule(id)
				.then((resp) => {
					commit('UPDATE_RULE', resp.data);
					resolve(resp);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	UPDATE_RULE: ({commit}, {id, data}) => {
		return new Promise((resolve, reject) => {
			rulesApi.updateRule(id, data)
				.then((resp) => {
					if (resp.status === 200) {
						commit('UPDATE_RULE', resp.data);
						resolve(resp);
					}

					reject(resp);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	ADD_RULE: ({commit}, data) => {
		return new Promise((resolve, reject) => {
			rulesApi.addRule(data)
				.then((resp) => {
					if (resp.status === 201) {
						commit('UPDATE_RULE', resp.data);
						resolve(resp);
					}

					reject(resp);
				})
				.catch(err => {
					reject(err)
				});
		})
	},
	DELETE_RULE: async ({commit}, id) => {
		await rulesApi.deleteRule(id)
	}
};

const mutations = {
	UPDATE_RULES: (state, data) => {
		let items = [];

		data.forEach(item => {
			items.push(new Rule(item));
		});

		state.rules = items;
	},
	UPDATE_RULE: (state, resp) => {
		state.rule = new Rule(resp.data);
	},
	UPDATE_RULES_META: (state, data) => {
		state.rulesMeta = new PageMeta(data);
	}
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
