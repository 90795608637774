<template>
	<div
		class="brackets__shuffle-button"
		v-if="show()"
	>
		<button
			class="button"
			@click.prevent="shuffle"
		>
			<SvgIcon
				width="24"
				height="24"
				:iconType="'shuffle'"
			/>
		</button>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'

	import SvgIcon from '../../components/SvgIcon'

	export default {
		name: "BracketsShuffleButton",
		components: {
			SvgIcon
		},
		computed: {
			...mapGetters({
				tournament: 'tournament'
			})
		},
		methods: {
			...mapActions({
		  shuffleBrackets: 'shuffleBrackets'
			}),
			shuffle() {
				this.shuffleBrackets(this.tournament.getId())
			},
			show() {
				return 'REGISTRATION_CLOSED' === this.tournament.getStatus();
			}
		}
	};
</script>
