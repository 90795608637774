import helpers from "./index";

export default (data) => {
	let rounds = [];

	data.forEach((item, index) => {
		rounds.push({
			round: index + 1,
			started_at: helpers.dateToFormat(item.games[0].started_at),
			status: item.games[0].status
		});
	});

	return rounds;
}
