import termsApi from '../../api/terms'
import PageMeta from '../../dto/page-meta'
import Terms from "../../dto/terms";

const state = () => ({
    terms: null,
    termsMeta: null,
    term: null
});

const getters = {
    terms: state => state.terms,
    termsMeta: state => state.termsMeta,
    term: state => state.term
};

const actions = {
    GET_TERMS: ({commit}, data = {}) => {
        return new Promise((resolve, reject) => {
            termsApi.get(data)
                .then((resp) => {
                    commit('UPDATE_TERMS', resp.data.data);
                    commit('UPDATE_TERMS_META', resp.data.meta);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    GET_TERM: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            termsApi.getTerm(id)
                .then((resp) => {
                    commit('UPDATE_TERM', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    UPDATE_TERM: ({commit}, {id, data}) => {
        return new Promise((resolve, reject) => {
            termsApi.updateTerm(id, data)
                .then((resp) => {
                    if (resp.status === 200) {
                        commit('UPDATE_TERM', resp.data);
                        resolve(resp);
                    }

                    reject(resp);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    ADD_TERM: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            termsApi.addTerms(data)
                .then((resp) => {
                    if (resp.status === 201) {
                        commit('UPDATE_TERM', resp.data);
                        resolve(resp);
                    }

                    reject(resp);
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    DELETE_TERM: async ({commit}, id) => {
        await termsApi.deleteTerms(id).then(resp => {
            if(resp.status === 200) {
                commit('DELETE_TERM', id);
            }
        })
    }
};

const mutations = {
    UPDATE_TERMS: (state, data) => {
        let items = [];

        data.forEach(item => {
            items.push(new Terms(item));
        });

        state.terms = items;
    },
    UPDATE_TERM: (state, resp) => {
        state.term = new Terms(resp.data);
    },
    UPDATE_TERMS_META: (state, data) => {
        state.termsMeta = new PageMeta(data);
    },
    DELETE_TERM: (state, id) => {
        let index = state.terms.findIndex(item => item.getId() === id);
        if(index > -1) {
            state.terms.splice(index, 1);
        }
    }
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
