<template>
    <div class="modal">
        <div class="modal-wrapper">
            <div class="modal-overlay"></div>
            <div class="modal-container">
                <div class="close" @click="$emit('close')">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.64113 6.98014L13.6128 3.00851C14.1291 2.53191 14.1291 1.73759 13.6128 1.26099L12.739 0.387234C12.2624 -0.129078 11.4681 -0.129078 10.9915 0.387234L7.01986 4.35887L3.00851 0.387234C2.53191 -0.129078 1.73759 -0.129078 1.26099 0.387234L0.387234 1.26099C-0.129078 1.73759 -0.129078 2.53191 0.387234 3.00851L4.35887 6.98014L0.387234 10.9915C-0.129078 11.4681 -0.129078 12.2624 0.387234 12.739L1.26099 13.6128C1.73759 14.1291 2.53191 14.1291 3.00851 13.6128L7.01986 9.64113L10.9915 13.6128C11.4681 14.1291 12.2624 14.1291 12.739 13.6128L13.6128 12.739C14.1291 12.2624 14.1291 11.4681 13.6128 10.9915L9.64113 6.98014Z" fill="#1C1C1C"/>
                    </svg>
                </div>
                <h1>set winner</h1>

                <div class="winners">
                    <div class="winner" v-for="place in places">
                        <div class="place">{{ place.place }}<template v-if="place.place === 1">st</template><template v-else>nd</template>
                        </div>
                        <div class="participant">
                            <ParticipantSelect :isDisabled="!place.isDraft" :value="place.participant" @close="addParticipantToPlace(place, $event)" :participants="participantsFree"></ParticipantSelect>
                        </div>
                    </div>
                </div>

                <button v-if="places.length < participants.length" class="button secondary add__new__winner" @click="addNewPlace">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.28571 3.92857H6.07143V0.714286C6.07143 0.334821 5.73661 0 5.35714 0H4.64286C4.24107 0 3.92857 0.334821 3.92857 0.714286V3.92857H0.714286C0.3125 3.92857 0 4.26339 0 4.64286V5.35714C0 5.75893 0.3125 6.07143 0.714286 6.07143H3.92857V9.28571C3.92857 9.6875 4.24107 10 4.64286 10H5.35714C5.73661 10 6.07143 9.6875 6.07143 9.28571V6.07143H9.28571C9.66518 6.07143 10 5.75893 10 5.35714V4.64286C10 4.26339 9.66518 3.92857 9.28571 3.92857Z" fill="#121212"/>
                    </svg>

                    add new winner
                </button>
                <button class="button button-primary submit" @click="saveWinners">choose winners</button>
                <transition name="fade">
                    <template v-if="isLoading">
                        <div class="loader">
                            <img src="https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif" alt="">
                        </div>
                    </template>
                </transition>
            </div>


        </div>

    </div>
</template>
<script>
import ParticipantSelect from "../RoundRobin/RoundRobinStage/Components/ParticipantSelect";
import SeedPlayer from '../Brackets/Seeding/SeedPlayer'
import {mapActions, mapGetters} from 'vuex'
import TournamentService from '../../api/tournament'
export default {
    data () {
        return {
            isLoading: true,
            places: [
                {
                    place: 1,
                    isDraft: true,
                    participant: undefined
                }
            ],
            excludeParticipants: []
        }
    },
    components: {
        ParticipantSelect,
        SeedPlayer
    },
    methods: {
      ...mapActions({
          getParticipants: 'GET_PARTICIPANTS'
      }),
        async saveWinners () {
          if (this.isLoading || !this.places.length) return
            this.isLoading = true
            await TournamentService.saveChallengeWinners(this.tournament.getId(), {
                places: this.places.filter(item => item.participant).map(item => {
                    return {
                        place: item.place,
                        participant_id: item.participant.getId()
                    }
                })
            }).then(() => {
                this.$root.$emit('enabledClose')
                this.init()
            }).catch(() => {
                this.$notify({
                    type: 'error',
                    text: "Error save"
                })
            })
            this.isLoading = false
        },
        addParticipantToPlace (place, $event) {
            const participant = $event.participant
            if (!participant) return
            place.participant = $event.participant
            this.excludeParticipants.push(place.participant.getId())
        },
        addNewPlace () {
          if (this.places.length >= this.participants.length) return
          this.places.push({
              isDraft: true,
              place: this.places[this.places.length - 1].place + 1,
              participant: undefined
          })
        },
        async init () {
            await this.getParticipants({
                id: this.tournament.getId(),
                params: {
                    status: 'active'
                }
            })

            await this.$nextTick(() => {
                const findParticipants = this.participants.filter(item => item.getTournamentPosition())
                if (findParticipants.length > 0) {
                    const places = []

                    findParticipants.sort((a, b) => {
                        return a.tournament_position > b.tournament_position
                    }).forEach(item => {
                        this.excludeParticipants.push(item.getId())
                        places.push({
                            participant: item,
                            place: item.getTournamentPosition(),
                            isDraft: false
                        })
                    })

                    this.places = places
                }

            })
        }
    },
    computed: {
        participantsFree () {
          return this.participants.filter(item => {
              return !this.excludeParticipants.includes(item.getId())
          })
        },
        ...mapGetters({
            tournament: 'tournament',
            participants: 'participants'
        })
    },
    async mounted () {
        this.isLoading = true
        await this.init()
        this.isLoading = false
    }
}
</script>
<style scoped lang="scss">
::v-deep {
    .option__participant .option__participant-info strong {
        margin-bottom: 0 !important;
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 50px;
    }
    backdrop-filter: blur(3px);
}

.modal-container {
    width: 376px;
    max-width: 376px;
    max-width: 376px;
    max-height: 90vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #06C2BC;
    }
    .close {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
}
.modal-container {
    .add__new__winner {
        width: 100%;
        margin-top: 15px;
        transition: 0.2s;
        &:hover {
            opacity: 0.7;
        }
    }
    .winners {
        margin-top: 25px;
        .winner {
            margin-bottom: 15px;
            display: flex;
            .place {
                width: 50px;
                background: #ECECEC;
                border-radius: 4px;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #2F2F2F;
                padding: 12px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 50px;
            }
            .participant {
                flex-grow: 1;
            }
        }
    }
    h1 {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        text-transform: uppercase;

        color: #06C2BC;
    }
    .submit {
        width: 100%;
        margin-top: 20px;
    }
}
</style>
