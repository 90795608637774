<template>
    <div class="modal create__room-modal">
        <div class="modal-wrapper">
            <div class="modal-overlay"></div>
            <div class="modal-container">
                <div class="modal-header">
                    <h2>Create rooms</h2>
                    <div class="modal-header__close" @click="$emit('close')">
                        <SvgIcon
                            width="16"
                            height="16"
                            :iconType="'slim'"
                        />
                    </div>
                </div>
                <form class="form" @submit.prevent.stop="create">



                    <div class="input__block">
                        <label for="asd">Number of rooms</label>
                        <input id="asd" type="number" v-model="room.length" min="1" placeholder="0" required>
                    </div>
                    <div class="input__block">
                        <label for="asd2">Room size</label>
                        <input id="asd2" type="number" v-model="room.size" min="1" placeholder="0" required>
                    </div>

                    <div class="input__block">
                        <label for="asd4">Start room date:</label>

                        <Datetime
                            :format="`DD HH:mm`"
                            type="datetime"
                            v-model="room.started_at"
                        />
                    </div>

                    <div class="input__block">
                        <FormMultiSelect
                            v-model="room.template_id"
                            :placeholder="$t('Room template')"
                            :options="templatesOptions"
                            :loading="multiSelectRegionsOptions.loading"
                            :label="multiSelectRegionsOptions.label"
                            :track-by="multiSelectRegionsOptions.trackBy"
                            :allowEmpty="true"
                            :multiple="multiSelectRegionsOptions.multiple"
                            :taggable="multiSelectRegionsOptions.taggable"
                        />
                    </div>

                    <div class="or">OR</div>

                    <template v-if="!room.template_id || room.template_id.id !== undefined">


                        <div class="input__block">
                            <label for="asd5">Round duration (minutes)</label>
                            <input id="asd5" type="number" v-model="room.duration" min="1" placeholder="0" required>
                        </div>

                        <div class="input__block">
                            <label for="asd3">Games in round</label>
                            <input id="asd3" type="number" v-model="room.games_in_round" min="1" placeholder="0" required>
                        </div>


                    </template>

                    <div class="error" v-if="error">{{ error }}</div>

                    <button class="create__room" :class="{'disabled': load || isDisabledButton}" type="submit">Create rooms</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import RoundRobinService from '../../../../api/round-robin'
import { mapGetters, mapActions } from 'vuex'
import SvgIcon from '../../../SvgIcon'
import {Datetime} from 'vue-datetime'
import moment from "moment";

import FormMultiSelect from 'vue-multiselect'

export default {
    data () {
        return {
            templatesOptions: [],
            error: undefined,
            multiSelectRegionsOptions: {
                loading: false,
                label: 'name',
                trackBy: 'value',
                multiple: false,
                taggable: true,
            },
            room: {
              games_in_round: 1,
              duration: 60,
              template_id: undefined,
              size: 0,
              started_at: moment().add(30, 'minutes').utc().format(),
              length: 0
            },
            load: false
        }
    },
    methods: {
      ...mapActions({
          getTournament: 'GET_TOURNAMENT',
          getTemplates: 'getRoomTemplates'
      }),
      isInt (n) {
          return Number(n) === n && n % 1 === 0;
      },
      create () {

          if (this.tournament.getType() === 7) {
              if (this.room.size < 16) {
                  this.error = 'The number of participants in the team must be at least 16'
                  return;
              }
              if (this.room.size > 24) {
                  this.error = 'The number of participants must not exceed 24 participants'
                  return;
              }
          }

              if (this.room.size > 16) {
                  this.error = 'In this type of tournament, a maximum of 16 participants are allowed in one room.'
                  return;
              }

          this.load = true

          if (this.room.template_id) {
              this.room.template_id = this.room.template_id.value
          }

          RoundRobinService.createRooms(this.tournament.getId(), this.room).then(response => {
              if (response.data.status === 200) {
                  this.getTournament(this.tournament.getId())
                  this.$emit('close')
              } else {
                  this.$notify({
                      type: 'error',
                      title: 'Unknown error',
                      group: 'notify',
                      text: response.data.error ? response.data.error : 'Unknown error'
                  })
              }
          }).catch(() => {
              this.$notify({
                  type: 'error',
                  title: 'Unknown error',
                  group: 'notify',
                  text: 'Unknown error'
              })
          })
      },
        initTemplates () {
            this.templates.map(item => {
                this.templatesOptions.push({value: item.getId(), name: item.getName()});
            });
        }
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament',
            templates: 'roomTemplates',
        }),
        isDisabledButton () {
            if (this.tournament.getType() === 7) {
                if (this.room.size < 16) {
                    return true;
                }
                if (this.room.size > 24) {
                    return true;
                }
            }
            if (Number(this.room.length) < 1) return true;
            if (Number(this.room.duration) < 1) return true;
            if (Number(this.room.games_in_round) < 1) return true;
            if (Number(this.room.size) < 1) return true;
        }
    },
    async mounted () {
        await this.getTemplates(this.tournament.getGameId())
        this.initTemplates()
    },
    components: {
        SvgIcon,
        FormMultiSelect,
        Datetime
    }
}
</script>
<style scoped lang="scss">
.create__room-modal {
    .modal-wrapper {
        .modal-container {
            width: 376px;
            padding: 25px;
            .modal-header {
                display: flex;
                justify-content: space-between;
                h2 {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 23px;
                    text-transform: uppercase;
                    color: #303030;
                }
            }
            .form {
                .error {
                    margin: 15px 0;
                    font-size: 14px;
                    color: red;
                }
                margin-top: 25px;
                padding: 0;
                .or {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    font-size: 14px;
                    margin-bottom: 10px;
                    position: relative;
                    &::after {
                        content: "";
                        display: block;
                        height: 1px;
                        position: absolute;
                        background: gray;
                        top: calc(50% - 1px);
                        opacity: 0.5;
                        left: calc(50% + 20px);
                        width: 100%;
                    }
                    &::before {
                        content: "";
                        display: block;
                        height: 1px;
                        position: absolute;
                        background: gray;
                        top: calc(50% - 1px);
                        opacity: 0.5;
                        right: calc(50% + 20px);
                        width: 100%;
                    }
                    color: #000;
                }
                .input__block {
                    margin-bottom: 15px;
                    label {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #06C2BC;
                        margin-bottom: 6px;
                        display: block;
                    }
                    input {
                        border: 1px solid #878787;
                        background: transparent;
                        outline: none;
                        height: 50px;
                        width: 100%;
                        padding: 0px 15px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #878787;
                        border-radius: 4px;
                    }
                }
                .create__room {
                    margin-top: 15px;
                    background: #00CBCB;
                    border-radius: 2px;
                    padding: 13px 0;
                    letter-spacing: 0.16px;
                    text-transform: uppercase;
                    color: #303030;
                    outline: none;
                    border: none;
                    width: 100%;
                    cursor: pointer;
                    transition: 0.2s;
                    &:hover {
                        opacity: 0.7;
                    }
                    font-weight: bold;
                    font-family: "Roboto";
                    &.disabled {
                        background: #C4C4C4;
                        color: #E5E5E5;
                        cursor: default;
                    }
                }
            }
        }
    }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
