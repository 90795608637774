<template>
    <div class="conversation" v-if="conversation">
        <div class="topBar">
            <div class="leftSide">
                <a :href="`/user/${conversation.user.id}`" target="_blank" class="chatName">{{ name }} <span>{{ conversation.user.email }}</span></a>
            </div>
            <div class="rightSide">
                <button @click="closeChat" class="button button-danger" v-if="conversation.status === 'ACTIVE'">Move to closed</button>
                <button @click="openChat" class="button button-primary" v-if="conversation.status === 'CLOSED'">Open again</button>
            </div>
        </div>
        <div class="convHistory userBg" ref="messages">
            <QlashLoader v-if="load"></QlashLoader>
            <template v-if="!load">
                <div class="msg messageReceived" :class="[(message.user_id === $currentUser.id ? 'messageSent' : 'messageReceived')]" v-for="(message,i) in messages" :key="i">
                    <div class="date" v-if="messages[i - 1] && checkDiffDay(messages[i - 1].created_at, message.created_at)">
                        Yesterday
                    </div>
                    <em v-if="message.message_type === 0">{{ message.message }}</em>
                    <img v-if="message.message_type === 1" :src="message.message" alt="">
                    <span class="timestamp">{{ toTime(message.created_at) }}</span>
                </div>
            </template>
        </div>
        <div class="replyBar">
            <label :for="`image-${conversation.id}`" class="attach">
                <attach-icon></attach-icon>
            </label>

            <input @input="uploadImage" :id="`image-${conversation.id}`" :disabled="conversation.status === 'CLOSED'" style="display: none;" type="file" accept="image/*">

            <input ref="input" :disabled="conversation.status === 'CLOSED'" type="text" @keyup.enter="send" v-model="message" class="replyMessage"
                   :placeholder="conversation.status === 'ACTIVE' ? 'Message...' : 'You will be able to write a message only if you make the chat active again by clicking on the Open Again button'"/>
            <div class="otherTools">
                <button :disabled="conversation.status === 'CLOSED'" @click="send" class="toolButtons">
                    <send-icon></send-icon>
                </button>
            </div>
        </div>
        <transition name="fade">
            <div class="conversation__confirm-image" v-if="image">
                <div class="image">
                    <div class="image__picture">
                        <img :src="previewImage" alt="">
                    </div>
                    <div class="image__actions">
                        <button :disabled="loadPicture" @click="cancel">Cancel</button>
                        <button :disabled="loadPicture" @click="sendPicture" class="accent">
                            <img v-show="loadPicture" src="https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif" alt="">
                            <template v-if="!loadPicture">Send</template>
                        </button>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>
<script>
import AttachIcon from 'vue-material-design-icons/Attachment.vue';
import SendIcon from 'vue-material-design-icons/Send.vue';
import ConversationService from '../../api/conversations'
import QlashLoader from '../../components/QlashLoader';
import moment from 'moment'
import {mapGetters} from "vuex";
export default {
    props: {
        conversation: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            subscribe: null,
            image: null,
            loadPicture: false,
            previewImage: null,
            message: '',
            load: true,
            messages: []
        }
    },
    watch: {
      'messages.length': function () {
          setTimeout(() => {
              this.toBottom()
          }, 200)
      },
      'conversation.id': function () {
          this.subscribe.unsubscribe()
          this.subscribe.removeAllListeners()
          this.load = true
          this.init();
      }
    },
    computed: {
        ...mapGetters({
            centrifugeInstance: 'centrifugeInstance'
        }),
        name () {
            const user = this.conversation.user;

            if (user.name) {
                return user.name
            }

            if (user.nickname) {
                return user.nickname
            }

            return user.email
        }
    },
    beforeDestroy() {
        this.subscribe.unsubscribe()
        this.subscribe.removeAllListeners()
    },
    methods: {
        checkDiffDay (prev, current) {
            prev = moment(prev)
            current = moment(current)
            return prev.diff(current, 'days') > 0
        },
        cancel () {
            this.image = null;
            this.previewImage = null;
        },
        uploadImage ($event) {
            this.image = $event.target.files[0]
            this.previewImage = URL.createObjectURL(this.image)
        },
        openChat () {
            ConversationService.startConversation(this.conversation.user.id, {reset: true}).then((response) => {
                this.$emit('changeTab', this.conversation.user.id)
                this.conversation = response.data
            });
        },
        closeChat () {
            ConversationService.closeConversation(this.conversation.id).then(() => {
                this.$emit('close')
            });
        },
        sendPicture () {
            if (!this.image || this.loadPicture) return;
            const data = new FormData();
            data.append('image', this.image)
            this.loadPicture = true
            ConversationService.sendMessage(this.conversation.id, data).then(response => {
                setTimeout(() => {
                    this.toBottom();
                }, 400)
            }).finally(() => {
                this.cancel()
                this.loadPicture = false
            })
        },
        send () {
          if (!this.message) {
              return;
          }

          ConversationService.sendMessage(this.conversation.id, {
              message: this.message
          }).then(response => {
              // this.messages.push(response.data.data)
              // this.conversation.last_message = response.data.data
              this.$nextTick(() => {
                  this.toBottom();
              })
          }).finally(() => {
              this.message = ''
          })
        },
        toBottom () {
            const el = this.$refs.messages;
            el.scrollTop = el.scrollHeight
        },
        toTime(date) {
            date = moment(date);

            return date.format('HH:mm')
        },
        async getMessages () {
            await ConversationService.getMessages(this.conversation.id).then(response => {
                this.messages = response.data.messages
            })
            this.conversation.unread_messages = 0
            this.load = false
            await this.$nextTick();
            this.toBottom()
        },
        goSubscribe () {
          this.subscribe = this.centrifugeInstance.subscribe(this.conversation.chat_room, async (data) => {
              this.messages.push(data.data)
              this.conversation.last_message = data.data
              await this.$nextTick();
              this.toBottom()
          })
        },
        init () {
            this.$refs.input.focus()
            this.getMessages()
            this.goSubscribe()
        }
    },
    mounted () {
        this.init()
    },
    components: {
        SendIcon,
        QlashLoader,
        AttachIcon
    }
}
</script>
<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
    transition: opacity .1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.conversation {
    .conversation__confirm-image {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        background: rgba(0,0,0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        .image {
            padding: 20px;
            background: #fff;
            width: 400px;
            .image__picture {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-height: 400px;
                img {
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            .image__actions {
                margin-top: 20px;
                display: flex;
                width: 100%;
                button {
                    font-weight: bold;
                    border-radius: 10px;
                    flex: 1;
                    padding: 15px 0;
                    &.accent {
                        background: #00c3bc;
                        color: #fff;
                        img {
                            width: 15px;
                        }
                    }
                    transition: 0.2s;
                    &:hover {
                        opacity: 0.7;
                    }
                }
                button + button {
                    margin-left: 15px;
                }
            }

        }
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}


.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.rightPanel {
    float: left;
    width: calc(100% - 321px);
    height: 100vh;
    background: #999;
}
button {
    outline: none;
    cursor: pointer;
    border: none;
}
input {
    outline: none;
    border: none;
}
.rightPanel .topBar {
    position: relative;
    width: 100%;
    height: 70px;
    background: #FFF;
    display: flex;
    align-items: center;
}

.leftSide {
    display: inline-block;
    clear: none;
    float: left;
    a {
        cursor: pointer;
        &:hover {
            cursor: pointer !important;
            color: #00c3bc
        }
    }
}
.rightSide {
    margin-left: auto;
}

.leftSide .chatName {
    float: left;
    margin-left: 30px;
    color: #444;
    font-weight: 600;
    cursor: default;
}

.chatName > span {
    font-size: 12px;
    font-weight: 500;
    color: #BBB;
    margin-left: 10px;
}

.leftSide .chatStatus {
    float: left;
    clear: left;
    margin-left: 30px;
    margin-top: 5px;
    color: #419fd9;
    font-weight: 300;
    cursor: default;
}

.rightSide {
    display: inline-block;
    clear: none;
    float: right;
    margin-right: 20px;
}

.tbButton, .otherTools .toolButtons {
    width: 50px;
    height: 50px;
    background: #FFF;
    color: #888;
    border-radius: 100%;
}

.tbButton:hover, .otherTools .toolButtons:hover {
    color: #555;
    background: #DDD;
}


/* THE CONVERSATION HISTORY CSS */

.convHistory {
    float: left;
    position: relative;
    width: 100%;
    height: calc(100vh - 140px);
    background: #333;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: gray;
    }
    .qlash-loader {
        position: absolute;
        bottom: 20px;
        margin: 0 auto;
        left: calc(50% - 40px)
    }
    & > div {
        padding-bottom: 20px;
    }
}

.userBg {
    background: #dfdfdf;
}
.msg {
    position: relative;
    width: auto;
    min-width: 200px;
    max-width: 45%;
    height: auto;
    padding: 10px 15px;
    padding-bottom: 35px;
    margin: 20px 15px;
    margin-bottom: 0px;
    background: #FFF;
    border-radius: 7px;
    clear: both;
    em {
        font-style: normal;
        display: block;
        margin-bottom: 5px;

    }
    img {
        max-width: 400px;
        margin-bottom: 15px;
        display: block;
        border-radius: 5px;
    }
}

.msg:nth-last-child(1) {
    margin-bottom: 20px;
}

.msg .timestamp {
    display: block;
    position: absolute;
    right: 10px;
    font-size: 14px;
    bottom: 6px;
    color: gray;
    user-select: none;
}

.messageReceived {
    float: left;
    background: #FFF;
}

.messageSent {
    float: right;
    background: #00c3bc;
    * {
        color: #fff !important;
    }
    .timestamp {
        color: white !important;
    }
}

.messageSent > .timestamp, .messageSent > .readStatus {
    bottom: 10px;
    right: 10px;
    color: darkgreen;
    user-select: none;
}

.messageSent > .readStatus {
    position: absolute;
    bottom: 10px;
    right: 12px;
}


/* THE REPLY BAR CSS */

.replyBar {
    width: 100%;
    height: 70px;
    float: left;
    background: #fff;
}

.replyBar .attach {
    width: 70px;
    height: 70px;
    color: #777;
    background: #FFF;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.replyBar .attach:hover {
    color: #555;
    background: #DDD;
}

.replyBar .d45 {
    transform: rotate(45deg);
    font-size: 32px;
}

.replyBar .replyMessage {
    width: calc(100% - 220px);
    float: left;
    height: 70px;
    padding: 0px 8px;
    font-size: 16px;
}

.replyBar .otherTools {
    float: right;
    width: 120px;
    padding-right: 20px;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.emojiBar {
    display: none;
    position: absolute;
    width: 325px;
    height: 200px;
    padding: 10px;
    right: 30px;
    bottom: 80px;
    border: 2px solid #DDD;
    border-radius: 3px;
    background: #FFF;
}

.emoticonType {
    width: 100%;
    height: 50px;
}

.emoticonType button {
    width: 105px;
    height: 36px;
    font-weight: 600;
    color: #555;
    background: none;
}

.emoticonType button:hover {
    color: #FFF;
    background: #419fd9;
}

.emojiList, .stickerList {
    width: 100%;
    height: 150px;
    overflow-x: hidden;
    overflow-y: auto;
}

.emojiList .pick {
    width: 50px;
    height: 50px;
    background: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
    transition: all 0.15s ease-in-out;
}

.emojiList .pick:hover {
    background: #DDD;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
}

.stickerList .pick {
    width: 80px;
    height: 80px;
    background: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65%;
    transition: all 0.15s ease-in-out;
}

.stickerList .pick:hover {
    background: #DDD;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65%;
}


/* hidden, while not complete */
.stickerList {
    display: none;
}

/* CSS FOR THE EMOJI IMAGES, JUST SOME */

#smileface {
    background-image: url(https://emojipedia-us.s3.amazonaws.com/thumbs/120/apple/96/grinning-face_1f600.png);
}

#grinningface {
    background-image: url(https://emojipedia-us.s3.amazonaws.com/thumbs/120/apple/96/grinning-face-with-smiling-eyes_1f601.png);
}

#tearjoyface {
    background-image: url(https://emojipedia-us.s3.amazonaws.com/thumbs/120/apple/96/face-with-tears-of-joy_1f602.png);
}

#rofl {
    background-image: url(https://emojipedia-us.s3.amazonaws.com/thumbs/120/apple/96/rolling-on-the-floor-laughing_1f923.png);
}

#somface {
    background-image: url(https://emojipedia-us.s3.amazonaws.com/thumbs/120/apple/96/smiling-face-with-open-mouth_1f603.png);
}

#swfface {
    background-image: url(https://emojipedia-us.s3.amazonaws.com/thumbs/120/apple/96/smiling-face-with-open-mouth-and-smiling-eyes_1f604.png);
}

/* NIGHT MODE THEMING */
.DarkTheme,
.DarkTheme .mainApp {
    background: #222;
}

/* SOLVING RESPONSIVE DESIGN ISSUES */
@media screen and (max-width: 1180px) {
    .config {
        width: 60%;
    }
}

@media screen and (max-width: 980px) {
    .config {
        width: 90%;
    }
}

@media screen and (max-width: 940px) {
    body {
        font-size: 14px;
    }

    .msg {
        width: 90%;
    }
}

@media screen and (max-width: 720px) {
    .leftPanel {
        width: 80px;
    }

    .rightPanel {
        width: calc(100% - 81px);
    }

    .msg {
        width: auto;
        max-width: 60%;
    }

    .profile .changePic, .profile .edit {
        font-size: 14px;
        font-weight: 500;
    }

    .profile .edit {
        margin-left: 5px;
    }

    .searchChats,
    .chatButton > .name,
    .chatButton > .message,
    .chatButton > .status {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .switchMobile {
        display: block;
    }

    .leftPanel, .rightPanel, .menuWrap, .config, .overlay {
        display: none;
    }
}

@media screen and (max-width: 300px) {
    .switchMobile {
        width: calc(220px - 20px);
    }
}
</style>
