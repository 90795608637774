export default function PageMeta(data= null) {
	const info = data;

	this.currentPage = () => info.current_page;

	this.prevPage = () => info.current_page - 1;

	this.nextPage = () => info.current_page + 1;

	this.lastPage = () => info.last_page;

	this.path = () => info.path;

	this.perPage = () => info.per_page;

	this.total = () => info.total;

}