export const LOCATIONS = [
	{name :"Global", value: null},
	{name :"English", value: "en"},
	{name :"Afghanistan",value :"af"},
	{name :"Albania",value :"al"},
	{name :"Algeria",value :"dz"},
	{name :"American Samoa",value :"as"},
	{name :"Andorra",value :"ad"},
	{name :"Angola",value :"ao"},
	{name :"Anguilla",value :"ai"},
	{name :"Antarctica",value :"aq"},
	{name :"Antigua and Barbuda",value :"ag"},
	{name :"Argentina",value :"ar"},
	{name :"Armenia",value :"am"},
	{name :"Aruba",value :"aw"},
	{name :"Australia",value :"au"},
	{name :"Austria",value :"at"},
	{name :"Azerbaijan",value :"az"},
	{name :"Bahamas (the)",value :"bs"},
	{name :"Bahrain",value :"bh"},
	{name :"Bangladesh",value :"bd"},
	{name :"Barbados",value :"bb"},
	{name :"Belarus",value :"by"},
	{name :"Belgium",value :"be"},
	{name :"Belize",value :"bz"},
	{name :"Benin",value :"bj"},
	{name :"Bermuda",value :"bm"},
	{name :"Bhutan",value :"bt"},
	{name :"Bolivia (Plurinational State of)",value :"bo"},
	{name :"Bonaire, Sint Eustatius and Saba",value :"bq"},
	{name :"Bosnia and Herzegovina",value :"ba"},
	{name :"Botswana",value :"bw"},
	{name :"Bouvet Island",value :"bv"},
	{name :"Brazil",value :"br"},
	{name :"British Indian Ocean Territory (the)",value :"io"},
	{name :"Brunei Darussalam",value :"bn"},
	{name :"Bulgaria",value :"bg"},
	{name :"Burkina Faso",value :"bf"},
	{name :"Burundi",value :"bi"},
	{name :"Cabo Verde",value :"cv"},
	{name :"Cambodia",value :"kh"},
	{name :"Cameroon",value :"cm"},
	{name :"Canada",value :"ca"},
	{name :"Cayman Islands (the)",value :"ky"},
	{name :"Central African Republic (the)",value :"cf"},
	{name :"Chad",value :"td"},
	{name :"Chile",value :"cl"},
	{name :"China",value :"cn"},
	{name :"Christmas Island",value :"cx"},
	{name :"Cocos (Keeling) Islands (the)",value :"cc"},
	{name :"Colombia",value :"co"},
	{name :"Comoros (the)",value :"km"},
	{name :"Congo (the Democratic Republic of the)",value :"cd"},
	{name :"Congo (the)",value :"cg"},
	{name :"Cook Islands (the)",value :"ck"},
	{name :"Costa Rica",value :"cr"},
	{name :"Croatia",value :"hr"},
	{name :"Cuba",value :"cu"},
	{name :"Curaçao",value :"cw"},
	{name :"Cyprus",value :"cy"},
	{name :"Czechia",value :"cz"},
	{name :"Côte d'Ivoire",value :"ci"},
	{name :"Denmark",value :"dk"},
	{name :"Djibouti",value :"dj"},
	{name :"Dominica",value :"dm"},
	{name :"Dominican Republic (the)",value :"do"},
	{name :"Ecuador",value :"ec"},
	{name :"Egypt",value :"eg"},
	{name :"El Salvador",value :"sv"},
	{name :"Equatorial Guinea",value :"gq"},
	{name :"Eritrea",value :"er"},
	{name :"Estonia",value :"ee"},
	{name :"Eswatini",value :"sz"},
	{name :"Ethiopia",value :"et"},
	{name :"Falkland Islands (the) [Malvinas]",value :"fk"},
	{name :"Faroe Islands (the)",value :"fo"},
	{name :"Fiji",value :"fj"},
	{name :"Finland",value :"fi"},
	{name :"France",value :"fr"},
	{name :"French Guiana",value :"gf"},
	{name :"French Polynesia",value :"pf"},
	{name :"French Southern Territories (the)",value :"tf"},
	{name :"Gabon",value :"ga"},
	{name :"Gambia (the)",value :"gm"},
	{name :"Georgia",value :"ge"},
	{name :"Germany",value :"de"},
	{name :"Ghana",value :"gh"},
	{name :"Gibraltar",value :"gi"},
	{name :"Greece",value :"gr"},
	{name :"Greenland",value :"gl"},
	{name :"Grenada",value :"gd"},
	{name :"Guadeloupe",value :"gp"},
	{name :"Guam",value :"gu"},
	{name :"Guatemala",value :"gt"},
	{name :"Guernsey",value :"gg"},
	{name :"Guinea",value :"gn"},
	{name :"Guinea-Bissau",value :"gw"},
	{name :"Guyana",value :"gy"},
	{name :"Haiti",value :"ht"},
	{name :"Heard Island and McDonald Islands",value :"hm"},
	{name :"Holy See (the)",value :"va"},
	{name :"Honduras",value :"hn"},
	{name :"Hong Kong",value :"hk"},
	{name :"Hungary",value :"hu"},
	{name :"Iceland",value :"is"},
	{name :"India",value :"in"},
	{name :"Indonesia",value :"id"},
	{name :"Iran (Islamic Republic of)",value :"ir"},
	{name :"Iraq",value :"iq"},
	{name :"Ireland",value :"ie"},
	{name :"Isle of Man",value :"im"},
	{name :"Israel",value :"il"},
	{name :"Italy",value :"it"},
	{name :"Jamaica",value :"jm"},
	{name :"Japan",value :"jp"},
	{name :"Jersey",value :"je"},
	{name :"Jordan",value :"jo"},
	{name :"Kazakhstan",value :"kz"},
	{name :"Kenya",value :"ke"},
	{name :"Kiribati",value :"ki"},
	{name :"Korea (the Democratic People's Republic of)",value :"kp"},
	{name :"Korea (the Republic of)",value :"kr"},
	{name :"Kuwait",value :"kw"},
	{name :"Kyrgyzstan",value :"kg"},
	{name :"Lao People's Democratic Republic (the)",value :"la"},
	{name :"Latvia",value :"lv"},
	{name :"Lebanon",value :"lb"},
	{name :"Lesotho",value :"ls"},
	{name :"Liberia",value :"lr"},
	{name :"Libya",value :"ly"},
	{name :"Liechtenstein",value :"li"},
	{name :"Lithuania",value :"lt"},
	{name :"Luxembourg",value :"lu"},
	{name :"Macao",value :"mo"},
	{name :"Madagascar",value :"mg"},
	{name :"Malawi",value :"mw"},
	{name :"Malaysia",value :"my"},
	{name :"Maldives",value :"mv"},
	{name :"Mali",value :"ml"},
	{name :"Malta",value :"mt"},
	{name :"Marshall Islands (the)",value :"mh"},
	{name :"Martinique",value :"mq"},
	{name :"Mauritania",value :"mr"},
	{name :"Mauritius",value :"mu"},
	{name :"Mayotte",value :"yt"},
	{name :"Mexico",value :"mx"},
	{name :"Micronesia (Federated States of)",value :"fm"},
	{name :"Moldova (the Republic of)",value :"md"},
	{name :"Monaco",value :"mc"},
	{name :"Mongolia",value :"mn"},
	{name :"Montenegro",value :"me"},
	{name :"Montserrat",value :"ms"},
	{name :"Morocco",value :"ma"},
	{name :"Mozambique",value :"mz"},
	{name :"Myanmar",value :"mm"},
	{name :"Namibia",value :"na"},
	{name :"Nauru",value :"nr"},
	{name :"Nepal",value :"np"},
	{name :"Netherlands (the)",value :"nl"},
	{name :"New Caledonia",value :"nc"},
	{name :"New Zealand",value :"nz"},
	{name :"Nicaragua",value :"ni"},
	{name :"Niger (the)",value :"ne"},
	{name :"Nigeria",value :"ng"},
	{name :"Niue",value :"nu"},
	{name :"Norfolk Island",value :"nf"},
	{name :"Northern Mariana Islands (the)",value :"mp"},
	{name :"Norway",value :"no"},
	{name :"Oman",value :"om"},
	{name :"Pakistan",value :"pk"},
	{name :"Palau",value :"pw"},
	{name :"Palestine, State of",value :"ps"},
	{name :"Panama",value :"pa"},
	{name :"Papua New Guinea",value :"pg"},
	{name :"Paraguay",value :"py"},
	{name :"Peru",value :"pe"},
	{name :"Philippines (the)",value :"ph"},
	{name :"Pitcairn",value :"pn"},
	{name :"Poland",value :"pl"},
	{name :"Portugal",value :"pt"},
	{name :"Puerto Rico",value :"pr"},
	{name :"Qatar",value :"qa"},
	{name :"Republic of North Macedonia",value :"mk"},
	{name :"Romania",value :"ro"},
	{name :"Russian Federation (the)",value :"ru"},
	{name :"Rwanda",value :"rw"},
	{name :"Réunion",value :"re"},
	{name :"Saint Barthélemy",value :"bl"},
	{name :"Saint Helena, Ascension and Tristan da Cunha",value :"sh"},
	{name :"Saint Kitts and Nevis",value :"kn"},
	{name :"Saint Lucia",value :"lc"},
	{name :"Saint Martin (French part)",value :"mf"},
	{name :"Saint Pierre and Miquelon",value :"pm"},
	{name :"Saint Vincent and the Grenadines",value :"vc"},
	{name :"Samoa",value :"ws"},
	{name :"San Marino",value :"sm"},
	{name :"Sao Tome and Principe",value :"st"},
	{name :"Saudi Arabia",value :"sa"},
	{name :"Senegal",value :"sn"},
	{name :"Serbia",value :"rs"},
	{name :"Seychelles",value :"sc"},
	{name :"Sierra Leone",value :"sl"},
	{name :"Singapore",value :"sg"},
	{name :"Sint Maarten (Dutch part)",value :"sx"},
	{name :"Slovakia",value :"sk"},
	{name :"Slovenia",value :"si"},
	{name :"Solomon Islands",value :"sb"},
	{name :"Somalia",value :"so"},
	{name :"South Africa",value :"za"},
	{name :"South Georgia and the South Sandwich Islands",value :"gs"},
	{name :"South Sudan",value :"ss"},
	{name :"Spain",value :"es"},
	{name :"Sri Lanka",value :"lk"},
	{name :"Sudan (the)",value :"sd"},
	{name :"Suriname",value :"sr"},
	{name :"Svalbard and Jan Mayen",value :"sj"},
	{name :"Sweden",value :"se"},
	{name :"Switzerland",value :"ch"},
	{name :"Syrian Arab Republic",value :"sy"},
	{name :"Taiwan (Province of China)",value :"tw"},
	{name :"Tajikistan",value :"tj"},
	{name :"Tanzania, United Republic of",value :"tz"},
	{name :"Thailand",value :"th"},
	{name :"Timor-Leste",value :"tl"},
	{name :"Togo",value :"tg"},
	{name :"Tokelau",value :"tk"},
	{name :"Tonga",value :"to"},
	{name :"Trinidad and Tobago",value :"tt"},
	{name :"Tunisia",value :"tn"},
	{name :"Turkey",value :"tr"},
	{name :"Turkmenistan",value :"tm"},
	{name :"Turks and Caicos Islands (the)",value :"tc"},
	{name :"Tuvalu",value :"tv"},
	{name :"Uganda",value :"ug"},
	{name :"Ukraine",value :"ua"},
	{name :"United Arab Emirates (the)",value :"ae"},
	{name :"United Kingdom of Great Britain and Northern Ireland (the)",value :"gb"},
	{name :"United States Minor Outlying Islands (the)",value :"um"},
	{name :"United States of America (the)",value :"us"},
	{name :"Uruguay",value :"uy"},
	{name :"Uzbekistan",value :"uz"},
	{name :"Vanuatu",value :"vu"},
	{name :"Venezuela (Bolivarian Republic of)",value :"ve"},
	{name :"Viet Nam",value :"vn"},
	{name :"Virgin Islands (British)",value :"vg"},
	{name :"Virgin Islands (U.S.)",value :"vi"},
	{name :"Wallis and Futuna",value :"wf"},
	{name :"Western Sahara",value :"eh"},
	{name :"Yemen",value :"ye"},
	{name :"Zambia",value :"zm"},
	{name :"Zimbabwe",value :"zw"},
	{name :"Åland Islands",value :"ax"}
];