<template>
	<div class="modal" v-show="showDialog">
		<div class="modal-wrapper">
			<div class="modal-overlay"></div>
			<div class="modal-container">
				<div class="modal-header">
					<div class="modal-header__title">
						<span>{{$t('Select Winner')}}</span>
					</div>
					<div class="modal-header__close" @click="hideWinnerDialog">
						<svg-icon
							width="16"
							height="16"
							:iconType="'slim'"
						/>
					</div>
				</div>
				<div class="choose-winner">

<!--					<div class="choose-winner__content">-->
<!--						<div class="choose-winner__participant" v-if="dialogData.first">-->
<!--							<input-->
<!--								class="choose-winner__radio"-->
<!--								type="radio"-->
<!--								:id="`participant-${rand}-1`"-->
<!--								:value="dialogData.first.id"-->
<!--								v-model="winner"-->
<!--							/>-->
<!--							<label-->
<!--								class="choose-winner__label"-->
<!--                                :for="`participant-${rand}-1`"-->
<!--							>-->
<!--							<span class="choose-winner__info">-->
<!--								<span class="avatar">-->
<!--									<img :src="dialogData.first.avatar" alt="First participant image">-->
<!--								</span>-->
<!--								<span class="name">{{dialogData.first.name}}</span>-->
<!--								<span class="nickname" v-if="dialogData.first.type === 'single'">{{dialogData.first.platformName}}</span>-->
<!--								<span class="team-mates" v-if="dialogData.first.type === 'team'">-->
<!--									<span v-for="teamMate in dialogData.first.team_mates" v-if="teamMate.status === 1">-->
<!--										{{teamMate.profile.user.nickname}}: {{teamMate.profile.info.id}}-->
<!--									</span>-->
<!--								</span>-->
<!--							</span>-->
<!--								<span class="choose-winner__images" v-if="dialogData.first.images.length">-->
<!--								<span-->
<!--									class="choose-winner__images-item"-->
<!--									v-if="imageUrl"-->
<!--									v-for="imageUrl in dialogData.first.images"-->
<!--								>-->
<!--									<expandable-image :src="imageUrl"/>-->
<!--								</span>-->
<!--							</span>-->
<!--							</label>-->
<!--						</div>-->
<!--						<div class="choose-winner__participant" v-if="dialogData.second">-->
<!--							<input-->
<!--								class="choose-winner__radio"-->
<!--								type="radio"-->
<!--                                :id="`participant-${rand}-2`"-->
<!--								:value="dialogData.second.id"-->
<!--								v-model="winner"-->
<!--							/>-->
<!--							<label-->
<!--								class="choose-winner__label"-->
<!--                                :for="`participant-${rand}-2`"-->
<!--							>-->
<!--							<span class="choose-winner__info">-->
<!--								<span class="avatar">-->
<!--									<img :src="dialogData.second.avatar" alt="First participant image">-->
<!--								</span>-->
<!--								<span class="name">{{dialogData.second.name}}</span>-->
<!--								<span class="nickname" v-if="dialogData.second.type === 'single'">{{dialogData.second.platformName}}</span>-->
<!--								<span class="team-mates" v-if="dialogData.second.type === 'team'">-->
<!--									<span v-for="teamMate in dialogData.second.team_mates" v-if="teamMate.status === 1">-->
<!--										{{teamMate.profile.user.nickname}}: {{teamMate.profile.info.id}}-->
<!--									</span>-->
<!--								</span>-->
<!--							</span>-->
<!--								<span class="choose-winner__images" v-if="dialogData.second.images.length">-->
<!--								<span-->
<!--									class="choose-winner__images-item"-->
<!--									v-if="imageUrl"-->
<!--									v-for="imageUrl in dialogData.second.images"-->
<!--								>-->
<!--									<expandable-image :src="imageUrl"/>-->
<!--								</span>-->
<!--							</span>-->
<!--							</label>-->
<!--						</div>-->
<!--					</div>-->

                    <div class="choose-wrapper">
                        <div class="choose-wrapper__participant" v-if="dialogData.first" :class="{'select': winner === dialogData.first.id}">
                            <input :value="dialogData.first.id" v-model="winner" type="radio" name="winner" :id="`participant-${rand}-1`">
                            <label :for="`participant-${rand}-1`" class="choose-wrapper__participant-player">
                                <div class="choose-wrapper__participant-player_avatar">
                                    <img :src="dialogData.first.avatar" alt="">
                                </div>
                                <div class="choose-wrapper__participant-player_info">
                                    <strong>{{ dialogData.first.name }}</strong>
                                    <span v-if="dialogData.first.type === 'single'">GAME ID: {{ dialogData.first.platformName }}</span>
                                </div>
                                <div class="choose-wrapper__participant-player_checkbox"></div>
                            </label>
                            <div class="choose-wrapper__participant-teams">
                                <div v-for="teamMate in dialogData.first.team_mates" v-if="teamMate.status === 1" class="choose-wrapper__participant-player">
                                    <div class="choose-wrapper__participant-player_avatar">
                                        <img :src="teamMate.avatar" alt="">
                                    </div>
                                    <div class="choose-wrapper__participant-player_info">
                                        <strong>{{ teamMate.profile.user.nickname ? teamMate.profile.user.nickname : teamMate.profile.user.name }}</strong>
                                        <span>GAME ID: {{teamMate.profile.info.id}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="choose-wrapper__images" v-if="dialogData.first.images.length">
                                    <span
                                        class="choose-winner__images-item"
                                        v-if="imageUrl"
                                        v-for="imageUrl in dialogData.first.images"
                                    >
                                        <expandable-image :src="imageUrl"/>
                                    </span>
                            </div>
                        </div>
                        <div class="choose-wrapper__participant" v-if="dialogData.second" :class="{'select': winner === dialogData.second.id}">
                            <input :value="dialogData.second.id" v-model="winner" type="radio" name="winner" :id="`participant-${rand}-2`">
                            <label :for="`participant-${rand}-2`" class="choose-wrapper__participant-player">
                                <div class="choose-wrapper__participant-player_avatar">
                                    <img :src="dialogData.second.avatar" alt="">
                                </div>
                                <div class="choose-wrapper__participant-player_info">
                                    <strong>{{ dialogData.second.name }}</strong>
                                    <span v-if="dialogData.second.type === 'single'">GAME ID: {{ dialogData.second.platformName }}</span>
                                </div>
                                <div class="choose-wrapper__participant-player_checkbox"></div>
                            </label>
                            <div class="choose-wrapper__participant-teams" v-if="dialogData.second.type">
                                <div v-for="teamMate in dialogData.second.team_mates" v-if="teamMate.status === 1" class="choose-wrapper__participant-player">
                                    <div class="choose-wrapper__participant-player_avatar">
                                        <img :src="teamMate.avatar" alt="">
                                    </div>
                                    <div class="choose-wrapper__participant-player_info">
                                        <strong>{{ teamMate.profile.user.nickname ? teamMate.profile.user.nickname : teamMate.profile.user.name }}</strong>
                                        <span>GAME ID: {{teamMate.profile.info.id}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="choose-wrapper__images" v-if="dialogData.second.images.length">
                                    <span
                                        class="choose-winner__images-item"
                                        v-if="imageUrl"
                                        v-for="imageUrl in dialogData.second.images"
                                    >
                                        <expandable-image :src="imageUrl"/>
                                    </span>
                            </div>
                        </div>

                    </div>


					<div class="choose-winner__buttons">
						<button
                            style="width: 100%;"
							class="button button-primary"
							:class="{ disabled: null === winner }"
							@click.prevent="chooseWinner"
						>
							{{$t('Approve')}}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'

	import SvgIcon from '../../components/SvgIcon'

	export default {
		name: "BracketsWinnerDialog",
		components: {
			SvgIcon
		},
		computed: {
			...mapGetters({
				tournament: 'tournament',
				showDialog: 'winnerDialogState',
				dialogData: 'winnerDialogData',
			})
		},
		data() {
			return {
                rand: Math.floor(Math.random() * 99999),
				winner: null
			}
		},
		methods: {
			...mapActions({
				hideWinnerDialog: 'hideBracketsWinnerDialog',
				approveWinner: 'approveBracketsWinner',
			}),
			chooseWinner() {
				if(!this.winner) return;

				this.approveWinner({
					id: this.tournament.getId(),
					matchId: this.dialogData.matchId,
					body: {winner_id: this.winner}
				});
				this.winner = null;
			}
		}
	};
</script>
<style scoped lang="scss">
.modal-container {
    max-height: 95vh;
    overflow-y: auto;
}
.choose-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 25px;
    align-items: flex-start;
    grid-gap: 25px;
    .choose-wrapper__participant {
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        input {
            display: none;
        }
        & > .choose-wrapper__participant-player {
            cursor: pointer;
            background: #ECECEC !important;
        }
        &.select {
            border: 1px solid #018786;
        }

        .choose-wrapper__participant-player {
            padding: 9px;
            display: flex;
            align-items: center;
            background: #FAFAFA;
            .choose-wrapper__participant-player_avatar {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                overflow: hidden;
                margin-right: 12px;
                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                }
            }
            .choose-wrapper__participant-player_info {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                overflow: hidden;
                strong {
                    display: block;
                    width: 100%;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 13px;
                    text-transform: capitalize;
                    color: #1C1C1C;
                    margin-bottom: 3px;
                }
                span {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 13px;
                    letter-spacing: 0.4px;
                    text-transform: uppercase;
                    color: #878787;
                }
            }
            .choose-wrapper__participant-player_checkbox {
                border-radius: 100%;
                border: 1px solid #8F8F8F;
                width: 16px;
                height: 16px;
                margin-right: 5px;
                min-width: 16px;
                position: relative;
                display: flex;
                align-items: center;
                transition: 0.2s;
                justify-content: center;
                &:after {
                    position: absolute;
                    transition: 0.2s;
                    content: "";
                    display: block;
                    width: 10px;
                    border-radius: 100%;
                    height: 10px;
                    background: #018786;
                    opacity: 0;
                }
            }
        }

        input:checked + .choose-wrapper__participant-player {
            .choose-wrapper__participant-player_checkbox {
                border-color: #018786;
                &:after {
                    opacity: 1;
                }
            }
        }

        .choose-wrapper__participant-teams {
            .choose-wrapper__participant-player + .choose-wrapper__participant-player {
                border-top: 1px solid #E5E5E5;
            }
        }

        .choose-wrapper__images {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            .choose-winner__images-item {
                max-width: 100%;
                max-height: 500px;
                img {
                    max-width: 100%;
                }
            }
        }
    }
}
</style>
