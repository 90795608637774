<template>
	<div
		class="leaderboard__close-tournament-button"
		v-if="show()"
	>
		<button
			class="button button-primary"
			@click.prevent="close"
		>{{$t('Close tournament')}}
		</button>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'

	export default {
		name: "LeaderboardCloseButton",
		computed: {
			...mapGetters({
				tournament: 'tournament',
				tournamentStatus: 'tournamentStatus'
			})
		},
		methods: {
			...mapActions({
				closeTournament: 'finishLeaderBoardTournament'
			}),
			close() {
				this.closeTournament(this.tournament.getId());
			},
			show() {
				const status = this.tournamentStatus;
				return ['STARTED', 'IN_PROGRESS'].includes(status.toUpperCase());
			}
		}
	};
</script>
