<template>
	<div v-if="season">
		<div class="page__content-header with-prev-button">
			<div>
				<RouterLink
					to="/seasons"
					class="prev-button"
				>
					<span>
						<SvgIcon
							width="24"
							height="24"
							:iconType="'arrow-left'"
						/>
					</span>
					<span>{{season.title}}</span>
				</RouterLink>
			</div>
			<div style="display: flex;justify-content: flex-end">
				<FiltersBar :options="filters" :onChange="filtersChanged"/>
                <div class="filter__country-leaderboard">
                    <FormMultiSelect
                        :loading="multiSelectCountryOptions.loading"
                        :placeholder="$t('Choose country')"
                        :label="multiSelectCountryOptions.label"
                        :track-by="multiSelectCountryOptions.trackBy"
                        :multiple="multiSelectCountryOptions.multiple"
                        :taggable="multiSelectCountryOptions.taggable"
                        :options="countries"
                        @search-change="onSearchChangeCountry"
                        v-model="country_id"></FormMultiSelect>
                </div>
            </div>
		</div>
		<div>

			<PageLinks :links="[
					{
						url: '/season/' +  season.id + '/solo',
						text: $t('Solo leaderboard')
					},
					{
						url: '/season/' +  season.id + '/team',
						text: $t('Team leaderboard')
					},
					{
						url: '/season/' +  season.id + '/clan',
						text: $t('Clan leaderboard')
					}
				]"/>
		</div>
		<div class="table-wrapper">
			<table v-if="leaderboards.length">
				<thead class="text-left">
				<tr>
					<th scope="col">{{$t('Position')}}</th>
					<th scope="col">{{$t('ID')}}</th>
					<th scope="col">{{$t('User Email')}}</th>
					<th scope="col">{{$t('Name')}}</th>
					<th scope="col">{{$t('Nickname')}}</th>
					<th scope="col">{{$t('Locale')}}</th>
					<th scope="col">{{$t('Points')}}</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(leaderboard, index) in leaderboards">
					<td class="row">{{index + 1}}</td>
					<td class="row">{{ leaderboard.user.id }}</td>
					<td class="row">{{leaderboard.user.email}}</td>
					<td class="row">{{leaderboard.user.name}}</td>
					<td class="row">{{leaderboard.user.nickname}}</td>
					<td class="row">{{leaderboard.user.locale}}</td>
					<td class="row">{{leaderboard.points}}</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import FormMultiSelect from 'vue-multiselect'

	import {mapGetters, mapActions, mapMutations} from 'vuex'

	import FiltersBar from '../../components/FiltersBar'
	import PageLinks from '../../components/Page/Links'
	import SvgIcon from '../../components/SvgIcon'

	export default {
		props: ["id"],
		components: {
			SvgIcon,
			PageLinks,
            FormMultiSelect,
			FiltersBar
		},
		data() {
			return {
                multiSelectCountryOptions: {
                    loading: false,
                    label: 'value',
                    trackBy: 'value',
                    multiple: false,
                    taggable: true,
                },
                country_id: null,
				filters: [],
				filtersUtil: this.$options.filters
			}
		},
		computed: {
			...mapGetters([
				'season',
				'leaderboards',
				'platforms',
                'countries',
				'activeFilters',
			])
		},
		beforeMount() {
			this.updateLeaderBoardData([]);
		},
		mounted() {
			this.initData();
		},
        watch: {
            country_id: {
                deep: true,
                handler() {
                    const activeFilters = this.activeFilters('season');
                    this.getLeaderBoards({
                        type: 'solo',
                        data: {
                            season_id: this.id,
                            ...activeFilters,
                            country_id: this.country_id ? this.country_id.id : null
                        }
                    });
                }
            },
        },
		methods: {
            async onSearchChangeCountry (value) {
                await this.getCountries({
                    search: value
                });
            },
			...mapActions({
				getSeason: 'getSeason',
				getLeaderBoards: 'getLeaderBoards',
				getPlatforms: 'GET_PLATFORMS',
                getCountries: 'getCountries'
			}),
			...mapMutations({
				setFilters: 'SET_FILTERS',
				updateLeaderBoardData: 'UPDATE_LEADERBOARDS'
			}),
			async initData() {
				const activeFilters = this.activeFilters('season');
                await this.getCountries()
				await this.getSeason(this.id);
				await this.getLeaderBoards({
					type: 'solo',
					data: {
						season_id: this.id,
						...activeFilters
					}
				});
				this.prepareFiltersFields();
			},
			async prepareFiltersFields() {
				const activeFilters = this.activeFilters('season');
				await this.getPlatforms();

				this.filters.push({
					type: 'select',
					name: 'platform_id',
					value: activeFilters.hasOwnProperty('platform_id') ? activeFilters.platform_id : null,
					options: this.filtersUtil.platformsOptions(this.platforms)
				});
			},
			filtersChanged(field, value) {
				this.setFilters({
					type: 'season',
					key: field,
					value: value ? value : null
				});

				this.getLeaderBoards({
					type: 'solo',
					data: {
						season_id: this.id,
						...this.activeFilters('season')
					}
				});
			}
		},
	}
</script>
<style>
.filter__country-leaderboard {
     width: 400px;
    display: flex;
    margin-left: 20px;
    justify-content: flex-end;
}
</style>
