import moment from 'moment'

function prepareDateTimeToSave(value) {
	const timeZone = new Date().getTimezoneOffset();
	return new moment(value).add((timeZone / 60), 'hours').format('YYYY-MM-DD HH:mm:ss');
}

export default (brackets, tournamentStartedAt) => {
	let data = [];
	brackets.forEach((item, index) => {
		let duration = 30;
		let startedAt = moment(tournamentStartedAt).add(duration * index, 'minutes');

		if (index === 0) {
			startedAt = tournamentStartedAt;
		}

		if (index === brackets.length - 1) {
			duration = 120;
		}

		data.push({
			round: index + 1,
			duration: duration,
			games_count: 1,
			started_at: prepareDateTimeToSave(startedAt)
		})
	});
	return data;
};
