<template>
    <div style="position: relative;">
      <div @mouseover="showTooltip = true" @mouseleave="showTooltip = false"  style="position: relative;">
        <slot></slot>
      </div>
      <div v-if="showTooltip" class="tooltip-overlay">
        <div class="tooltip-content">
          <slot name="tooltip-content"></slot>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    data() {
      return {
        showTooltip: false,
      };
    },
  };
  </script>

  <style scoped lang="scss">
  .tooltip-overlay {    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    z-index: 999;
    bottom: 20px;
    min-width: 250px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
  }

  .tooltip-content {

  }
  </style>
