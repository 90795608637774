<template>
	<nav
		class="page__mobile-navigation"
		:class="navigationIsClosed ? 'closed' : ''"
		v-if="showPanel"
	>
		<button
			class="toggle-button"
			@click.prevent="toggleNavigation"
			:class="!navigationIsClosed ? 'toggle-button--active' : ''"
		></button>
		<div v-if="!navigationIsClosed" class="page__navigation-overlay" @click="toggleNavigation"></div>
		<ul class="page__navigation-list">

            <li>
                <div class="page__navigation-item_collapse" :class="{'active': activeTabs.includes(1)}" @click="activeTabs.includes(1) ? activeTabs.splice(activeTabs.indexOf(1), 1)  : activeTabs.push(1)">
                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.9466 1.83C16.9351 1.63899 16.8513 1.45959 16.7121 1.32853C16.573 1.19747 16.3891 1.12466 16.1981 1.125H13.8066V0.75C13.8066 0.551088 13.7277 0.360322 13.5874 0.21967C13.447 0.0790178 13.2566 0 13.0581 0H4.07598C3.87746 0 3.68707 0.0790178 3.5467 0.21967C3.40633 0.360322 3.32747 0.551088 3.32747 0.75V1.125H0.771305C0.583578 1.12937 0.404347 1.20428 0.269165 1.33487C0.133982 1.46545 0.0527236 1.64218 0.0415075 1.83C0.0153097 2.3475 -0.100709 4.97625 0.224892 5.8875C0.550494 6.79875 3.67178 9.585 3.70172 9.6375C4.07563 10.4389 4.64058 11.1359 5.34677 11.6669C6.05296 12.198 6.87869 12.5467 7.75116 12.6825H7.81853V16.5H5.573C5.37448 16.5 5.18409 16.579 5.04372 16.7197C4.90335 16.8603 4.82449 17.0511 4.82449 17.25C4.82449 17.4489 4.90335 17.6397 5.04372 17.7803C5.18409 17.921 5.37448 18 5.573 18H11.5611C11.7596 18 11.95 17.921 12.0904 17.7803C12.2307 17.6397 12.3096 17.4489 12.3096 17.25C12.3096 17.0511 12.2307 16.8603 12.0904 16.7197C11.95 16.579 11.7596 16.5 11.5611 16.5H9.31555V12.6825C10.1616 12.532 10.9596 12.1813 11.6431 11.6595C12.3266 11.1377 12.8759 10.4599 13.2452 9.6825C14.0836 9 16.4264 6.9225 16.8006 5.8875C17.1075 4.97625 16.9765 2.3475 16.9466 1.83ZM1.60215 5.38125C1.47865 5.04 1.45245 3.8025 1.49362 2.625H3.32747V7.22625C2.42551 6.4125 1.7032 5.67 1.59467 5.38125H1.60215ZM15.3822 5.38125C15.2812 5.66625 14.6337 6.37875 13.8141 7.15875V2.625H15.4908C15.5207 3.8025 15.4982 5.04 15.3747 5.38125H15.3822Z" fill="#00E7DF"/>
                    </svg>
                    <span>
                        Tournaments
                    </span>
                    <div class="page__navigation-item__arrow">
                        <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.03516 6.40234C4.28125 6.64844 4.69141 6.64844 4.9375 6.40234L8.65625 2.68359C8.92969 2.41016 8.92969 2 8.65625 1.75391L8.05469 1.125C7.78125 0.878906 7.37109 0.878906 7.125 1.125L4.47266 3.77734L1.84766 1.125C1.60156 0.878906 1.19141 0.878906 0.917969 1.125L0.316406 1.75391C0.0429688 2 0.0429688 2.41016 0.316406 2.68359L4.03516 6.40234Z" fill="#00E7DF"/>
                        </svg>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="activeTabs.includes(1)" class="sub">
                        <router-link to="/">
                            <span class="icon">
                                <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.4375 2.5H7.25C7.25 1.54297 6.45703 0.75 5.5 0.75C4.51562 0.75 3.75 1.54297 3.75 2.5H1.5625C0.824219 2.5 0.25 3.10156 0.25 3.8125V13.4375C0.25 14.1758 0.824219 14.75 1.5625 14.75H9.4375C10.1484 14.75 10.75 14.1758 10.75 13.4375V3.8125C10.75 3.10156 10.1484 2.5 9.4375 2.5ZM2.875 12.3438C2.49219 12.3438 2.21875 12.0703 2.21875 11.6875C2.21875 11.332 2.49219 11.0312 2.875 11.0312C3.23047 11.0312 3.53125 11.332 3.53125 11.6875C3.53125 12.0703 3.23047 12.3438 2.875 12.3438ZM2.875 9.71875C2.49219 9.71875 2.21875 9.44531 2.21875 9.0625C2.21875 8.70703 2.49219 8.40625 2.875 8.40625C3.23047 8.40625 3.53125 8.70703 3.53125 9.0625C3.53125 9.44531 3.23047 9.71875 2.875 9.71875ZM2.875 7.09375C2.49219 7.09375 2.21875 6.82031 2.21875 6.4375C2.21875 6.08203 2.49219 5.78125 2.875 5.78125C3.23047 5.78125 3.53125 6.08203 3.53125 6.4375C3.53125 6.82031 3.23047 7.09375 2.875 7.09375ZM5.5 1.84375C5.85547 1.84375 6.15625 2.14453 6.15625 2.5C6.15625 2.88281 5.85547 3.15625 5.5 3.15625C5.11719 3.15625 4.84375 2.88281 4.84375 2.5C4.84375 2.14453 5.11719 1.84375 5.5 1.84375ZM9 11.9062C9 12.043 8.89062 12.125 8.78125 12.125H4.84375C4.70703 12.125 4.625 12.043 4.625 11.9062V11.4688C4.625 11.3594 4.70703 11.25 4.84375 11.25H8.78125C8.89062 11.25 9 11.3594 9 11.4688V11.9062ZM9 9.28125C9 9.41797 8.89062 9.5 8.78125 9.5H4.84375C4.70703 9.5 4.625 9.41797 4.625 9.28125V8.84375C4.625 8.73438 4.70703 8.625 4.84375 8.625H8.78125C8.89062 8.625 9 8.73438 9 8.84375V9.28125ZM9 6.65625C9 6.79297 8.89062 6.875 8.78125 6.875H4.84375C4.70703 6.875 4.625 6.79297 4.625 6.65625V6.21875C4.625 6.10938 4.70703 6 4.84375 6H8.78125C8.89062 6 9 6.10938 9 6.21875V6.65625Z" fill="#C4C4C4"/>
                                </svg>
                            </span>
                            <span>all Tournaments</span>
                        </router-link>
                        <router-link to="/seasons">
                        <span class="icon">
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 0.96875C3.25391 0.96875 0.21875 4.00391 0.21875 7.75C0.21875 11.4961 3.25391 14.5312 7 14.5312C10.7461 14.5312 13.7812 11.4961 13.7812 7.75C13.7812 4.00391 10.7461 0.96875 7 0.96875ZM9.51562 9.52734L8.96875 10.2109C8.88672 10.3203 8.77734 10.4023 8.61328 10.4023C8.53125 10.4023 8.42188 10.3477 8.36719 10.293L6.53516 8.92578C6.26172 8.73438 6.125 8.43359 6.125 8.07812V3.8125C6.125 3.59375 6.31641 3.375 6.5625 3.375H7.4375C7.65625 3.375 7.875 3.59375 7.875 3.8125V7.75L9.46094 8.92578C9.54297 9.00781 9.625 9.11719 9.625 9.25391C9.625 9.36328 9.57031 9.47266 9.51562 9.52734Z" fill="#C4C4C4"/>
                        </svg>
                        </span>

                            <span>Seasons</span>
                        </router-link>
                        <router-link to="/bracket-templates">
                        <span class="icon">
                            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 1H3V2H6V4V5V7H3V8H6C6.55228 8 7 7.55228 7 7V5H8V4H7V2C7 1.44772 6.55228 1 6 1Z" fill="#C4C4C4"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3C8.44772 3 8 3.44772 8 4V5C8 5.55228 8.44772 6 9 6H12C12.5523 6 13 5.55228 13 5V4C13 3.44772 12.5523 3 12 3H9ZM11 4H10V5H11V4Z" fill="#C4C4C4"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1 6C0.447715 6 0 6.44772 0 7V8C0 8.55228 0.447715 9 1 9H4C4.55228 9 5 8.55228 5 8V7C5 6.44772 4.55228 6 4 6H1ZM3 7H2V8H3V7Z" fill="#C4C4C4"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1 0C0.447715 0 0 0.447715 0 1V2C0 2.55228 0.447715 3 1 3H4C4.55228 3 5 2.55228 5 2V1C5 0.447715 4.55228 0 4 0H1ZM3 1H2V2H3V1Z" fill="#C4C4C4"/>
                        </svg>
                        </span>

                            <span>Bracket templates</span>
                        </router-link>
                        <router-link to="/rules">
                        <span class="icon">
                            <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.4375 2.5H7.25C7.25 1.54297 6.45703 0.75 5.5 0.75C4.51562 0.75 3.75 1.54297 3.75 2.5H1.5625C0.824219 2.5 0.25 3.10156 0.25 3.8125V13.4375C0.25 14.1758 0.824219 14.75 1.5625 14.75H9.4375C10.1484 14.75 10.75 14.1758 10.75 13.4375V3.8125C10.75 3.10156 10.1484 2.5 9.4375 2.5ZM5.5 1.84375C5.85547 1.84375 6.15625 2.14453 6.15625 2.5C6.15625 2.88281 5.85547 3.15625 5.5 3.15625C5.11719 3.15625 4.84375 2.88281 4.84375 2.5C4.84375 2.14453 5.11719 1.84375 5.5 1.84375ZM8.80859 8.1875L4.89844 12.0703C4.76172 12.207 4.54297 12.207 4.43359 12.0703L2.16406 9.80078C2.02734 9.66406 2.05469 9.44531 2.16406 9.33594L2.95703 8.54297C3.06641 8.43359 3.28516 8.43359 3.42188 8.57031L4.67969 9.82812L7.57812 6.95703C7.6875 6.82031 7.90625 6.82031 8.04297 6.95703L8.80859 7.72266C8.94531 7.85938 8.91797 8.07812 8.80859 8.1875Z" fill="#C4C4C4"/>
                        </svg>
                        </span>
                            <span>Rules</span>
                        </router-link>
<!--                        <router-link-->
<!--                            :event="!hasPermission('Games create/edit') ? '' : 'click'"-->
<!--                            :disabled="!hasPermission('Games create/edit')"-->
<!--                            to="/games">-->
<!--                            <span class="icon">-->
<!--                                <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                    <path d="M13.375 0.375H4.625C2.19141 0.375 0.25 2.34375 0.25 4.75C0.25 7.18359 2.19141 9.125 4.625 9.125C5.82812 9.125 6.94922 8.63281 7.74219 7.8125H10.2305C11.0234 8.63281 12.1445 9.15234 13.375 9.15234C15.7812 9.15234 17.75 7.18359 17.75 4.77734C17.75 2.34375 15.7812 0.402344 13.375 0.375ZM7.03125 5.07812C7.03125 5.26953 6.86719 5.40625 6.70312 5.40625H5.28125V6.82812C5.28125 7.01953 5.11719 7.15625 4.95312 7.15625H4.29688C4.10547 7.15625 3.96875 7.01953 3.96875 6.82812V5.40625H2.54688C2.35547 5.40625 2.21875 5.26953 2.21875 5.07812V4.42188C2.21875 4.25781 2.35547 4.09375 2.54688 4.09375H3.96875V2.67188C3.96875 2.50781 4.10547 2.34375 4.29688 2.34375H4.95312C5.11719 2.34375 5.28125 2.50781 5.28125 2.67188V4.09375H6.70312C6.86719 4.09375 7.03125 4.25781 7.03125 4.42188V5.07812ZM12.9375 7.15625C12.3086 7.15625 11.8438 6.69141 11.8438 6.0625C11.8438 5.46094 12.3086 4.96875 12.9375 4.96875C13.5391 4.96875 14.0312 5.46094 14.0312 6.0625C14.0312 6.69141 13.5391 7.15625 12.9375 7.15625ZM14.6875 4.53125C14.0586 4.53125 13.5938 4.06641 13.5938 3.4375C13.5938 2.83594 14.0586 2.34375 14.6875 2.34375C15.2891 2.34375 15.7812 2.83594 15.7812 3.4375C15.7812 4.06641 15.2891 4.53125 14.6875 4.53125Z" fill="#C4C4C4"/>-->
<!--                                </svg>-->
<!--                            </span>-->
<!--                            <span>Games</span>-->
<!--                        </router-link>-->
                    </div>
                </transition>
            </li>
            <li>
                <div class="page__navigation-item_collapse" :class="{'active': activeTabs.includes(2)}" @click="activeTabs.includes(2) ? activeTabs.splice(activeTabs.indexOf(2), 1)  : activeTabs.push(2)">
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.6719 9.5H13.043C13.2539 9.5 13.5 9.28906 13.5 9.07812V4.33203C13.5 4.12109 13.2539 3.875 13.043 3.875H11.6719C11.4609 3.875 11.25 4.12109 11.25 4.33203V9.07812C11.25 9.28906 11.4609 9.5 11.6719 9.5ZM15.0469 9.5H16.418C16.6289 9.5 16.875 9.28906 16.875 9.07812V0.957031C16.875 0.746094 16.6289 0.5 16.418 0.5H15.0469C14.8359 0.5 14.625 0.746094 14.625 0.957031V9.07812C14.625 9.28906 14.8359 9.5 15.0469 9.5ZM4.92188 9.5H6.29297C6.50391 9.5 6.75 9.28906 6.75 9.07812V6.58203C6.75 6.37109 6.50391 6.125 6.29297 6.125H4.92188C4.71094 6.125 4.5 6.37109 4.5 6.58203V9.07812C4.5 9.28906 4.71094 9.5 4.92188 9.5ZM8.29688 9.5H9.66797C9.87891 9.5 10.125 9.28906 10.125 9.07812V2.08203C10.125 1.87109 9.87891 1.625 9.66797 1.625H8.29688C8.08594 1.625 7.875 1.87109 7.875 2.08203V9.07812C7.875 9.28906 8.08594 9.5 8.29688 9.5ZM17.4375 11.75H2.25V1.0625C2.25 0.78125 1.96875 0.5 1.6875 0.5H0.5625C0.246094 0.5 0 0.78125 0 1.0625V12.875C0 13.5078 0.492188 14 1.125 14H17.4375C17.7188 14 18 13.7539 18 13.4375V12.3125C18 12.0312 17.7188 11.75 17.4375 11.75Z" fill="#00E7DF"/>
                    </svg>

                    <span>
                        Monitor
                    </span>
                    <div class="page__navigation-item__arrow">
                        <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.03516 6.40234C4.28125 6.64844 4.69141 6.64844 4.9375 6.40234L8.65625 2.68359C8.92969 2.41016 8.92969 2 8.65625 1.75391L8.05469 1.125C7.78125 0.878906 7.37109 0.878906 7.125 1.125L4.47266 3.77734L1.84766 1.125C1.60156 0.878906 1.19141 0.878906 0.917969 1.125L0.316406 1.75391C0.0429688 2 0.0429688 2.41016 0.316406 2.68359L4.03516 6.40234Z" fill="#00E7DF"/>
                        </svg>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="activeTabs.includes(2)" class="sub">
                        <router-link to="/events">
                        <span class="icon">
                            <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.375 13.4375C0.375 14.1758 0.949219 14.75 1.6875 14.75H11.3125C12.0234 14.75 12.625 14.1758 12.625 13.4375V6H0.375V13.4375ZM9.125 8.07812C9.125 7.91406 9.26172 7.75 9.45312 7.75H10.5469C10.7109 7.75 10.875 7.91406 10.875 8.07812V9.17188C10.875 9.36328 10.7109 9.5 10.5469 9.5H9.45312C9.26172 9.5 9.125 9.36328 9.125 9.17188V8.07812ZM9.125 11.5781C9.125 11.4141 9.26172 11.25 9.45312 11.25H10.5469C10.7109 11.25 10.875 11.4141 10.875 11.5781V12.6719C10.875 12.8633 10.7109 13 10.5469 13H9.45312C9.26172 13 9.125 12.8633 9.125 12.6719V11.5781ZM5.625 8.07812C5.625 7.91406 5.76172 7.75 5.95312 7.75H7.04688C7.21094 7.75 7.375 7.91406 7.375 8.07812V9.17188C7.375 9.36328 7.21094 9.5 7.04688 9.5H5.95312C5.76172 9.5 5.625 9.36328 5.625 9.17188V8.07812ZM5.625 11.5781C5.625 11.4141 5.76172 11.25 5.95312 11.25H7.04688C7.21094 11.25 7.375 11.4141 7.375 11.5781V12.6719C7.375 12.8633 7.21094 13 7.04688 13H5.95312C5.76172 13 5.625 12.8633 5.625 12.6719V11.5781ZM2.125 8.07812C2.125 7.91406 2.26172 7.75 2.45312 7.75H3.54688C3.71094 7.75 3.875 7.91406 3.875 8.07812V9.17188C3.875 9.36328 3.71094 9.5 3.54688 9.5H2.45312C2.26172 9.5 2.125 9.36328 2.125 9.17188V8.07812ZM2.125 11.5781C2.125 11.4141 2.26172 11.25 2.45312 11.25H3.54688C3.71094 11.25 3.875 11.4141 3.875 11.5781V12.6719C3.875 12.8633 3.71094 13 3.54688 13H2.45312C2.26172 13 2.125 12.8633 2.125 12.6719V11.5781ZM11.3125 2.5H10V1.1875C10 0.96875 9.78125 0.75 9.5625 0.75H8.6875C8.44141 0.75 8.25 0.96875 8.25 1.1875V2.5H4.75V1.1875C4.75 0.96875 4.53125 0.75 4.3125 0.75H3.4375C3.19141 0.75 3 0.96875 3 1.1875V2.5H1.6875C0.949219 2.5 0.375 3.10156 0.375 3.8125V5.125H12.625V3.8125C12.625 3.10156 12.0234 2.5 11.3125 2.5Z" fill="#C4C4C4"/>
                            </svg>

                        </span>
                            <span>Calendar</span>
                        </router-link>
                    </div>
                </transition>
            </li>

            <li>
                <div class="page__navigation-item_collapse" :class="{'active': activeTabs.includes(3)}" @click="activeTabs.includes(3) ? activeTabs.splice(activeTabs.indexOf(3), 1)  : activeTabs.push(3)">
                    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.625 8.6875C20.625 7.87891 20.168 7.14062 19.5 6.75391V1.41016C19.5 1.09375 19.2539 0.25 18.375 0.25C18.0938 0.25 17.8477 0.355469 17.6719 0.53125L14.6484 2.92188C13.1719 4.11719 11.2734 4.75 9.375 4.75H2.625C1.35938 4.75 0.375 5.76953 0.375 7V10.375C0.375 11.6406 1.35938 12.625 2.625 12.625H3.78516C3.75 13.0117 3.71484 13.3984 3.71484 13.75C3.71484 15.1562 4.03125 16.4922 4.62891 17.6523C4.80469 18.0391 5.19141 18.25 5.61328 18.25H8.21484C9.12891 18.25 9.69141 17.2305 9.12891 16.4922C8.56641 15.7188 8.21484 14.8047 8.21484 13.75C8.21484 13.3633 8.28516 13.0117 8.35547 12.625H9.375C11.2734 12.625 13.1719 13.293 14.6484 14.4883L17.6719 16.8789C17.8477 17.0547 18.0938 17.125 18.3398 17.125C19.2188 17.125 19.4648 16.3516 19.4648 16V10.6562C20.168 10.2695 20.625 9.53125 20.625 8.6875ZM17.25 13.6797L16.0547 12.7305C14.1562 11.2188 11.8008 10.375 9.375 10.375V7C11.8008 7 14.1562 6.19141 16.0547 4.67969L17.25 3.73047V13.6797Z" fill="#00E7DF"/>
                    </svg>
                    <span>
                        Advertisement
                    </span>
                    <div class="page__navigation-item__arrow">
                        <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.03516 6.40234C4.28125 6.64844 4.69141 6.64844 4.9375 6.40234L8.65625 2.68359C8.92969 2.41016 8.92969 2 8.65625 1.75391L8.05469 1.125C7.78125 0.878906 7.37109 0.878906 7.125 1.125L4.47266 3.77734L1.84766 1.125C1.60156 0.878906 1.19141 0.878906 0.917969 1.125L0.316406 1.75391C0.0429688 2 0.0429688 2.41016 0.316406 2.68359L4.03516 6.40234Z" fill="#00E7DF"/>
                        </svg>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="activeTabs.includes(3)" class="sub">
                        <router-link to="/advertisements">
                        <span class="icon">
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.875 7.3125C15.875 6.68359 15.5195 6.10938 15 5.80859V1.65234C15 1.40625 14.8086 0.75 14.125 0.75C13.9062 0.75 13.7148 0.832031 13.5781 0.96875L11.2266 2.82812C10.0781 3.75781 8.60156 4.25 7.125 4.25H1.875C0.890625 4.25 0.125 5.04297 0.125 6V8.625C0.125 9.60938 0.890625 10.375 1.875 10.375H2.77734C2.75 10.6758 2.72266 10.9766 2.72266 11.25C2.72266 12.3438 2.96875 13.3828 3.43359 14.2852C3.57031 14.5859 3.87109 14.75 4.19922 14.75H6.22266C6.93359 14.75 7.37109 13.957 6.93359 13.3828C6.49609 12.7812 6.22266 12.0703 6.22266 11.25C6.22266 10.9492 6.27734 10.6758 6.33203 10.375H7.125C8.60156 10.375 10.0781 10.8945 11.2266 11.8242L13.5781 13.6836C13.7148 13.8203 13.9062 13.875 14.0977 13.875C14.7812 13.875 14.9727 13.2734 14.9727 13V8.84375C15.5195 8.54297 15.875 7.96875 15.875 7.3125ZM13.25 11.1953L12.3203 10.457C10.8438 9.28125 9.01172 8.625 7.125 8.625V6C9.01172 6 10.8438 5.37109 12.3203 4.19531L13.25 3.45703V11.1953Z" fill="#C4C4C4"/>
                            </svg>
                        </span>
                            <span>Banners</span>
                        </router-link>
                    </div>
                </transition>
            </li>

            <li>
                <div class="page__navigation-item_collapse" :class="{'active': activeTabs.includes(4)}" @click="activeTabs.includes(4) ? activeTabs.splice(activeTabs.indexOf(4), 1) : activeTabs.push(4)">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.625 13.875C4.625 12.6445 3.60547 11.625 2.375 11.625C1.10938 11.625 0.125 12.6445 0.125 13.875C0.125 15.1406 1.10938 16.125 2.375 16.125C3.60547 16.125 4.625 15.1406 4.625 13.875ZM10.7773 15.5625C10.4961 10.1133 6.13672 5.75391 0.6875 5.47266C0.371094 5.4375 0.125 5.71875 0.125 6.03516V7.72266C0.125 8.00391 0.335938 8.25 0.617188 8.28516C4.55469 8.53125 7.71875 11.6953 7.96484 15.6328C8 15.9141 8.24609 16.125 8.52734 16.125H10.2148C10.5312 16.125 10.8125 15.8789 10.7773 15.5625ZM15.8398 15.5625C15.5586 7.33594 8.94922 0.691406 0.6875 0.410156C0.371094 0.375 0.125 0.621094 0.125 0.972656V2.66016C0.125 2.94141 0.335938 3.1875 0.652344 3.22266C7.36719 3.46875 12.7812 8.88281 13.0273 15.5977C13.0625 15.9141 13.3086 16.1602 13.5898 16.1602H15.2773C15.6289 16.125 15.875 15.8789 15.8398 15.5625Z" fill="#00E7DF"/>
                    </svg>

                    <span>
                        Content
                    </span>
                    <div class="page__navigation-item__arrow">
                        <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.03516 6.40234C4.28125 6.64844 4.69141 6.64844 4.9375 6.40234L8.65625 2.68359C8.92969 2.41016 8.92969 2 8.65625 1.75391L8.05469 1.125C7.78125 0.878906 7.37109 0.878906 7.125 1.125L4.47266 3.77734L1.84766 1.125C1.60156 0.878906 1.19141 0.878906 0.917969 1.125L0.316406 1.75391C0.0429688 2 0.0429688 2.41016 0.316406 2.68359L4.03516 6.40234Z" fill="#00E7DF"/>
                        </svg>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="activeTabs.includes(4)" class="sub">
                        <router-link
                            to="/news">
                        <span class="icon">
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.875 11.125C3.875 10.168 3.08203 9.375 2.125 9.375C1.14062 9.375 0.375 10.168 0.375 11.125C0.375 12.1094 1.14062 12.875 2.125 12.875C3.08203 12.875 3.875 12.1094 3.875 11.125ZM8.66016 12.4375C8.44141 8.19922 5.05078 4.80859 0.8125 4.58984C0.566406 4.5625 0.375 4.78125 0.375 5.02734V6.33984C0.375 6.55859 0.539062 6.75 0.757812 6.77734C3.82031 6.96875 6.28125 9.42969 6.47266 12.4922C6.5 12.7109 6.69141 12.875 6.91016 12.875H8.22266C8.46875 12.875 8.6875 12.6836 8.66016 12.4375ZM12.5977 12.4375C12.3789 6.03906 7.23828 0.871094 0.8125 0.652344C0.566406 0.625 0.375 0.816406 0.375 1.08984V2.40234C0.375 2.62109 0.539062 2.8125 0.785156 2.83984C6.00781 3.03125 10.2188 7.24219 10.4102 12.4648C10.4375 12.7109 10.6289 12.9023 10.8477 12.9023H12.1602C12.4336 12.875 12.625 12.6836 12.5977 12.4375Z" fill="#C4C4C4"/>
                            </svg>
                        </span>
                            <span>News</span>
                        </router-link>
                    </div>
                </transition>
            </li>

            <li>
                <div class="page__navigation-item_collapse" :class="{'active': activeTabs.includes(5)}" @click="activeTabs.includes(5) ? activeTabs.splice(activeTabs.indexOf(5), 1)  : activeTabs.push(5)">
                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 18.25C9.23047 18.25 10.2148 17.2656 10.2148 16H5.75C5.75 17.2656 6.73438 18.25 8 18.25ZM15.5586 13.0117C14.8906 12.2734 13.5898 11.1836 13.5898 7.5625C13.5898 4.85547 11.6914 2.67578 9.08984 2.11328V1.375C9.08984 0.777344 8.59766 0.25 8 0.25C7.36719 0.25 6.875 0.777344 6.875 1.375V2.11328C4.27344 2.67578 2.375 4.85547 2.375 7.5625C2.375 11.1836 1.07422 12.2734 0.40625 13.0117C0.195312 13.2227 0.0898438 13.5039 0.125 13.75C0.125 14.3477 0.546875 14.875 1.25 14.875H14.7148C15.418 14.875 15.8398 14.3477 15.875 13.75C15.875 13.5039 15.7695 13.2227 15.5586 13.0117Z" fill="#00E7DF"/>
                    </svg>
                    <span>
                    Notifications
                </span>
                    <div class="page__navigation-item__arrow">
                        <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.03516 6.40234C4.28125 6.64844 4.69141 6.64844 4.9375 6.40234L8.65625 2.68359C8.92969 2.41016 8.92969 2 8.65625 1.75391L8.05469 1.125C7.78125 0.878906 7.37109 0.878906 7.125 1.125L4.47266 3.77734L1.84766 1.125C1.60156 0.878906 1.19141 0.878906 0.917969 1.125L0.316406 1.75391C0.0429688 2 0.0429688 2.41016 0.316406 2.68359L4.03516 6.40234Z" fill="#00E7DF"/>
                        </svg>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="activeTabs.includes(5)" class="sub">
                        <router-link to="/notifications">
                        <span class="icon">
                            <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.5 14.75C7.45703 14.75 8.22266 13.9844 8.22266 13H4.75C4.75 13.9844 5.51562 14.75 6.5 14.75ZM12.3789 10.6758C11.8594 10.1016 10.8477 9.25391 10.8477 6.4375C10.8477 4.33203 9.37109 2.63672 7.34766 2.19922V1.625C7.34766 1.16016 6.96484 0.75 6.5 0.75C6.00781 0.75 5.625 1.16016 5.625 1.625V2.19922C3.60156 2.63672 2.125 4.33203 2.125 6.4375C2.125 9.25391 1.11328 10.1016 0.59375 10.6758C0.429688 10.8398 0.347656 11.0586 0.375 11.25C0.375 11.7148 0.703125 12.125 1.25 12.125H11.7227C12.2695 12.125 12.5977 11.7148 12.625 11.25C12.625 11.0586 12.543 10.8398 12.3789 10.6758Z" fill="#C4C4C4"/>
                            </svg>
                        </span>
                            <span>Send notification</span>
                        </router-link>
                        <router-link to="/deep-links">
                        <span class="icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.91406 5.83594C9.75 5.67188 9.55859 5.50781 9.36719 5.37109C9.17578 5.23438 8.95703 5.26172 8.79297 5.39844L8.21875 5.97266C8 6.21875 7.89062 6.51953 7.89062 6.79297C7.89062 6.95703 7.97266 7.06641 8.08203 7.14844C8.16406 7.20312 8.27344 7.28516 8.38281 7.36719C9.14844 8.16016 9.14844 9.39062 8.38281 10.1562L6.52344 11.9883C5.75781 12.7812 4.5 12.7812 3.73438 11.9883C2.96875 11.2227 2.99609 9.96484 3.76172 9.19922L4.11719 8.84375C4.25391 8.73438 4.28125 8.54297 4.22656 8.37891C4.0625 7.91406 3.98047 7.42188 3.95312 6.95703C3.95312 6.57422 3.48828 6.38281 3.21484 6.65625L2.20312 7.66797C0.589844 9.28125 0.589844 11.9336 2.20312 13.5469C3.81641 15.1602 6.46875 15.1602 8.08203 13.5469L9.91406 11.7148C9.91406 11.7148 9.91406 11.7148 9.91406 11.6875C11.5273 10.1016 11.5547 7.47656 9.91406 5.83594ZM13.7695 1.98047C12.1562 0.367188 9.50391 0.367188 7.89062 1.98047L6.05859 3.8125C6.05859 3.8125 6.05859 3.8125 6.05859 3.83984C4.44531 5.42578 4.41797 8.05078 6.05859 9.69141C6.22266 9.85547 6.41406 10.0195 6.60547 10.1562C6.79688 10.293 7.01562 10.2656 7.17969 10.1289L7.75391 9.55469C7.97266 9.30859 8.08203 9.00781 8.08203 8.73438C8.08203 8.57031 8 8.46094 7.89062 8.37891C7.80859 8.32422 7.69922 8.24219 7.58984 8.16016C6.82422 7.36719 6.82422 6.13672 7.58984 5.37109L9.44922 3.53906C10.2148 2.74609 11.4727 2.74609 12.2383 3.53906C13.0039 4.30469 12.9766 5.5625 12.2109 6.32812L11.8555 6.68359C11.7188 6.79297 11.6914 6.98438 11.7461 7.14844C11.9102 7.61328 11.9922 8.10547 12.0195 8.57031C12.0195 8.95312 12.4844 9.14453 12.7578 8.87109L13.7695 7.85938C15.3828 6.24609 15.3828 3.59375 13.7695 1.98047Z" fill="#C4C4C4"/>
                            </svg>
                        </span>
                            <span>Deep links</span>
                        </router-link>
                    </div>
                </transition>
            </li>

            <li>
                <div class="page__navigation-item_collapse" :class="{'active': activeTabs.includes(6)}" @click="activeTabs.includes(6) ? activeTabs.splice(activeTabs.indexOf(6), 1)  : activeTabs.push(6)">
                    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.375 3.39844V15.5625C0.375 15.9844 0.761719 16.2656 1.11328 16.0898L6 13.875V0.375L1.07812 2.34375C0.65625 2.51953 0.375 2.94141 0.375 3.39844ZM7.125 13.875L13.875 16.125V2.625L7.125 0.375V13.875ZM19.8516 0.445312L15 2.625V16.125L19.8867 14.1914C20.3086 14.0156 20.625 13.5938 20.625 13.1367V0.972656C20.625 0.550781 20.2031 0.269531 19.8516 0.445312Z" fill="#00E7DF"/>
                    </svg>
                    <span>
                    Location
                </span>
                    <div class="page__navigation-item__arrow">
                        <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.03516 6.40234C4.28125 6.64844 4.69141 6.64844 4.9375 6.40234L8.65625 2.68359C8.92969 2.41016 8.92969 2 8.65625 1.75391L8.05469 1.125C7.78125 0.878906 7.37109 0.878906 7.125 1.125L4.47266 3.77734L1.84766 1.125C1.60156 0.878906 1.19141 0.878906 0.917969 1.125L0.316406 1.75391C0.0429688 2 0.0429688 2.41016 0.316406 2.68359L4.03516 6.40234Z" fill="#00E7DF"/>
                        </svg>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="activeTabs.includes(6)" class="sub">
                        <router-link to="/regions">
                        <span class="icon">
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 0.75C6.08594 0.75 4.55469 2.30859 4.55469 4.19531C4.55469 5.75391 6.79688 8.54297 7.64453 9.58203C7.83594 9.77344 8.13672 9.77344 8.32812 9.58203C9.17578 8.54297 11.4453 5.75391 11.4453 4.19531C11.4453 2.30859 9.88672 0.75 8 0.75ZM8 5.34375C7.34375 5.34375 6.85156 4.85156 6.85156 4.19531C6.85156 3.56641 7.34375 3.04688 8 3.04688C8.62891 3.04688 9.14844 3.56641 9.14844 4.19531C9.14844 4.85156 8.62891 5.34375 8 5.34375ZM0.671875 6.65625C0.34375 6.79297 0.125 7.12109 0.125 7.47656V14.3125C0.125 14.6406 0.425781 14.8594 0.699219 14.7227L4.5 13V6.62891C4.25391 6.19141 4.03516 5.78125 3.89844 5.37109L0.671875 6.65625ZM8 10.5938C7.58984 10.5938 7.23438 10.4297 6.98828 10.1289C6.44141 9.5 5.86719 8.78906 5.375 8.02344V13L10.625 14.75V8.02344C10.1055 8.78906 9.53125 9.5 8.98438 10.1289C8.73828 10.4297 8.38281 10.5938 8 10.5938ZM15.2734 5.17969L11.5 6.875V14.75L15.3008 13.2461C15.6289 13.1094 15.875 12.7812 15.875 12.4258V5.58984C15.875 5.26172 15.5469 5.04297 15.2734 5.17969Z" fill="#C4C4C4"/>
                            </svg>
                        </span>
                            <span>Regions</span>
                        </router-link>
                        <router-link to="/countries">
                        <span class="icon">
                            <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.95312 14.4766C5.19922 14.8594 5.77344 14.8594 6.01953 14.4766C10.0117 8.73438 10.75 8.13281 10.75 6C10.75 3.10156 8.39844 0.75 5.5 0.75C2.57422 0.75 0.25 3.10156 0.25 6C0.25 8.13281 0.960938 8.73438 4.95312 14.4766ZM5.5 8.1875C4.26953 8.1875 3.3125 7.23047 3.3125 6C3.3125 4.79688 4.26953 3.8125 5.5 3.8125C6.70312 3.8125 7.6875 4.79688 7.6875 6C7.6875 7.23047 6.70312 8.1875 5.5 8.1875Z" fill="#C4C4C4"/>
                            </svg>
                        </span>
                            <span>Countries</span>
                        </router-link>
                    </div>
                </transition>
            </li>

            <li>
                <div class="page__navigation-item_collapse" :class="{'active': activeTabs.includes(8)}" @click="activeTabs.includes(8) ? activeTabs.splice(activeTabs.indexOf(8), 1)  : activeTabs.push(8)">
                    <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.625 7.125C4.85547 7.125 5.875 6.14062 5.875 4.875C5.875 3.64453 4.85547 2.625 3.625 2.625C2.35938 2.625 1.375 3.64453 1.375 4.875C1.375 6.14062 2.35938 7.125 3.625 7.125ZM19.375 7.125C20.6055 7.125 21.625 6.14062 21.625 4.875C21.625 3.64453 20.6055 2.625 19.375 2.625C18.1094 2.625 17.125 3.64453 17.125 4.875C17.125 6.14062 18.1094 7.125 19.375 7.125ZM20.5 8.25H18.25C17.6172 8.25 17.0547 8.53125 16.6328 8.91797C18.0742 9.69141 19.0586 11.0977 19.3047 12.75H21.625C22.2227 12.75 22.75 12.2578 22.75 11.625V10.5C22.75 9.26953 21.7305 8.25 20.5 8.25ZM11.5 8.25C13.6445 8.25 15.4375 6.49219 15.4375 4.3125C15.4375 2.16797 13.6445 0.375 11.5 0.375C9.32031 0.375 7.5625 2.16797 7.5625 4.3125C7.5625 6.49219 9.32031 8.25 11.5 8.25ZM14.1719 9.375H13.8906C13.1523 9.72656 12.3438 9.9375 11.5 9.9375C10.6211 9.9375 9.8125 9.72656 9.07422 9.375H8.79297C6.54297 9.375 4.75 11.2031 4.75 13.4531V14.4375C4.75 15.3867 5.48828 16.125 6.4375 16.125H16.5625C17.4766 16.125 18.25 15.3867 18.25 14.4375V13.4531C18.25 11.2031 16.4219 9.375 14.1719 9.375ZM6.33203 8.91797C5.91016 8.53125 5.34766 8.25 4.75 8.25H2.5C1.23438 8.25 0.25 9.26953 0.25 10.5V11.625C0.25 12.2578 0.742188 12.75 1.375 12.75H3.66016C3.90625 11.0977 4.89062 9.69141 6.33203 8.91797Z" fill="#00E7DF"/>
                    </svg>


                    <span>
                    Users
                </span>
                    <div class="page__navigation-item__arrow">
                        <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.03516 6.40234C4.28125 6.64844 4.69141 6.64844 4.9375 6.40234L8.65625 2.68359C8.92969 2.41016 8.92969 2 8.65625 1.75391L8.05469 1.125C7.78125 0.878906 7.37109 0.878906 7.125 1.125L4.47266 3.77734L1.84766 1.125C1.60156 0.878906 1.19141 0.878906 0.917969 1.125L0.316406 1.75391C0.0429688 2 0.0429688 2.41016 0.316406 2.68359L4.03516 6.40234Z" fill="#00E7DF"/>
                        </svg>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="activeTabs.includes(8)" class="sub">
                        <router-link
                            :event="!hasRole('Super admin') && !hasRole('Tournament admin') ? '' : 'click'"
                            :disabled="!hasRole('Super admin') && !hasRole('Tournament admin')"
                            to="/users">
                        <span class="icon">
                            <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 6.75C7.16797 6.75 8.5625 5.38281 8.5625 3.6875C8.5625 2.01953 7.16797 0.625 5.5 0.625C3.80469 0.625 2.4375 2.01953 2.4375 3.6875C2.4375 5.38281 3.80469 6.75 5.5 6.75ZM7.57812 7.625H7.35938C6.78516 7.89844 6.15625 8.0625 5.5 8.0625C4.81641 8.0625 4.1875 7.89844 3.61328 7.625H3.39453C1.64453 7.625 0.25 9.04688 0.25 10.7969V11.5625C0.25 12.3008 0.824219 12.875 1.5625 12.875H9.4375C10.1484 12.875 10.75 12.3008 10.75 11.5625V10.7969C10.75 9.04688 9.32812 7.625 7.57812 7.625ZM13.375 6.75C14.8242 6.75 16 5.57422 16 4.125C16 2.67578 14.8242 1.5 13.375 1.5C11.9258 1.5 10.75 2.67578 10.75 4.125C10.75 5.57422 11.9258 6.75 13.375 6.75ZM14.6875 7.625H14.5781C14.1953 7.76172 13.7852 7.84375 13.375 7.84375C12.9375 7.84375 12.5273 7.76172 12.1445 7.625H12.0625C11.4883 7.625 10.9688 7.78906 10.5312 8.0625C11.1875 8.77344 11.625 9.73047 11.625 10.7969V11.8359C11.625 11.8906 11.5977 11.9453 11.5977 12H16.4375C17.1484 12 17.75 11.4258 17.75 10.6875C17.75 9.01953 16.3555 7.625 14.6875 7.625Z" fill="#C4C4C4"/>
                            </svg>

                        </span>
                            <span>All users</span>
                        </router-link>
                        <router-link
                            to="/teams">
                        <span class="icon">
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.7422 3.04688L7.49219 0.859375C7.32812 0.804688 7.16406 0.777344 7 0.777344C6.80859 0.777344 6.64453 0.804688 6.48047 0.859375L1.23047 3.04688C0.738281 3.26562 0.4375 3.73047 0.4375 4.25C0.4375 9.69141 3.55469 13.4375 6.48047 14.668C6.80859 14.8047 7.16406 14.8047 7.49219 14.668C9.84375 13.6836 13.5625 10.3203 13.5625 4.25C13.5625 3.73047 13.2344 3.26562 12.7422 3.04688ZM7 12.9727V2.55469L11.7852 4.55078C11.7031 8.67969 9.54297 11.6875 7 12.9727Z" fill="#C4C4C4"/>
                            </svg>

                        </span>
                            <span>Teams</span>
                        </router-link>
                    </div>
                </transition>
            </li>

            <li class="last">
                <div class="page__navigation-item_collapse" :class="{'active': activeTabs.includes(7)}" @click="activeTabs.includes(7) ? activeTabs.splice(activeTabs.indexOf(7), 1)  : activeTabs.push(7)">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 10.375C11.7773 10.375 14.0625 8.125 14.0625 5.3125C14.0625 2.53516 11.7773 0.25 9 0.25C6.1875 0.25 3.9375 2.53516 3.9375 5.3125C3.9375 8.125 6.1875 10.375 9 10.375ZM13.5 11.5H11.5312C10.7578 11.8867 9.91406 12.0625 9 12.0625C8.08594 12.0625 7.20703 11.8867 6.43359 11.5H4.5C2.00391 11.5 0 13.5391 0 16V16.5625C0 17.5117 0.738281 18.25 1.6875 18.25H16.3125C17.2266 18.25 18 17.5117 18 16.5625V16C18 13.5391 15.9609 11.5 13.5 11.5Z" fill="#00E7DF"/>
                    </svg>

                    <span>
                    {{ getUser.nickname }}
                </span>
                    <div class="page__navigation-item__arrow">
                        <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.03516 6.40234C4.28125 6.64844 4.69141 6.64844 4.9375 6.40234L8.65625 2.68359C8.92969 2.41016 8.92969 2 8.65625 1.75391L8.05469 1.125C7.78125 0.878906 7.37109 0.878906 7.125 1.125L4.47266 3.77734L1.84766 1.125C1.60156 0.878906 1.19141 0.878906 0.917969 1.125L0.316406 1.75391C0.0429688 2 0.0429688 2.41016 0.316406 2.68359L4.03516 6.40234Z" fill="#00E7DF"/>
                        </svg>
                    </div>
                </div>
                <transition>
                    <div v-if="activeTabs.includes(7) && getUser.roles.includes('Super admin')" class="sub">
                        <router-link to="/tournaments/advanced">
                            <span class="icon">
                                <svg-icon
                                    width="16"
                                    height="16"
                                    :iconType="'advanced'"
                                />
                            </span>
                            <span>Advanced</span>
                        </router-link>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="activeTabs.includes(7)" class="sub">
                        <router-link to="/logout">
                        <span class="icon">
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6484 5.89844C11.8594 5.6875 11.8594 5.33594 11.6484 5.10156L7.71094 1.16406C7.33594 0.8125 6.75 1.07031 6.75 1.5625V3.8125H3.5625C3.23438 3.8125 3 4.07031 3 4.375V6.625C3 6.95312 3.23438 7.1875 3.5625 7.1875H6.75V9.4375C6.75 9.95312 7.35938 10.1875 7.71094 9.83594L11.6484 5.89844ZM4.5 9.71875V8.78125C4.5 8.64062 4.35938 8.5 4.21875 8.5H2.25C1.82812 8.5 1.5 8.17188 1.5 7.75V3.25C1.5 2.85156 1.82812 2.5 2.25 2.5H4.21875C4.35938 2.5 4.5 2.38281 4.5 2.21875V1.28125C4.5 1.14062 4.35938 1 4.21875 1H2.25C1.00781 1 0 2.00781 0 3.25V7.75C0 8.99219 1.00781 10 2.25 10H4.21875C4.35938 10 4.5 9.88281 4.5 9.71875Z" fill="#C4C4C4"/>
                            </svg>
                        </span>
                            <span>Logout</span>
                        </router-link>
                    </div>
                </transition>
            </li>

		</ul>
	</nav>
</template>

<script>
    import SvgIcon from '../SvgIcon'

	import {mapGetters, mapMutations} from 'vuex'

	export default {
		name: "PageMobileNavigation",
		computed: {
			...mapGetters([
				'navigationIsClosed',
				'showPanel',
                'getUser',
				'isTournamentManager',
				'isSuperAdmin'
			]),
		},
		watch: {
			$route() {
				this.hideNavigation();
			}
		},
        data () {
            return {
                activeTabs: []
            }
        },
        components: {
			SvgIcon,
		},
		methods: {
			...mapMutations({
				toggleNavigation: 'TOGGLE_NAVIGATION',
				hideNavigation: 'HIDE_NAVIGATION',
			}),
			disableLinks() {
				return this.isTournamentManager;
			}
		}
	};
</script>
<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
    transition: opacity .1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.page__navigation-list {
    * {
        transition: 0.3s;
    }
    a {
        padding: 13px 35px;
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
            margin-left: 17px;
            font-weight: bold;
            font-size: 14px;
            line-height: 11px;
            display: flex;
            text-transform: uppercase;
            color: #C4C4C4;
        }
    }
    li {
        &.last {
            margin-top: auto;
        }
        display: block;
        .page__navigation-item_collapse {
            width: 100%;
            padding: 13px 32px;
            background: #212121;
            display: flex;
            cursor: pointer;
            justify-content: flex-start;
            align-items: center;
            &.active {
                .page__navigation-item__arrow {
                    transform: rotate(180deg);
                }
            }
            span {
                margin-left: 17px;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                text-transform: uppercase;
                color: #00E7DF;
            }
            .page__navigation-item__arrow {
                display: block;
                transition: 0.2s;
                margin-left: auto;
            }
        }
        .sub {
            a {
                &[disabled="disabled"] {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                display: flex;
                align-items: center;
                padding: 10px 32px;
                .icon {
                    width: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    margin-right: 14px;
                }
                span {
                    margin: 0;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 15px;
                    text-transform: uppercase;
                    color: #C4C4C4;
                }
            }
        }
    }
    &.closed  {
        width: 80px;
        a {
            span {
                display: none;
            }
        }
        li {
            .page__navigation-item_collapse {
                span {
                    display: none;
                }
                .icon {
                    margin-right: 0;
                }
                .page__navigation-item__arrow {
                    display: none;
                }
            }
            .sub {
                a {
                    padding-left: 0;
                    padding-right: 0;
                    justify-content: center;
                    .icon {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #06C2BC;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
</style>
