<template>
	<div class="modal delete-participant-dialog" v-show="showRevertMatchDialog">
		<div class="modal-wrapper">
			<div class="modal-overlay"></div>
			<div class="modal-container">
				<div class="modal-header">
					<div class="modal-header__close" @click="close">
						<SvgIcon
							width="16"
							height="16"
							:iconType="'slim'"
						/>
					</div>
				</div>
				<div class="modal-body">
					<h4>{{$t('Do you want to revert this match result?')}}</h4>
					<div class="button-group">
						<button
							class="button button-primary"
							@click.prevent="submit"
						>
							{{$t('Submit')}}
						</button>
						<button
							class="button button-primary"
							@click.prevent="close"
						>
							{{$t('Cancel')}}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'
	import {REVERT_MATCH_DIALOG} from '../../constants/dialogs'

	import SvgIcon from '../SvgIcon'

	export default {
		name: "RevertMatchDialog",
		components: {
			SvgIcon
		},
		computed: {
			...mapGetters([
				'tournament',
				'showRevertMatchDialog',
				'revertMatchDialogId',
                'revertMatchDialogType'
			])
		},
		methods: {
			...mapActions({
				revertMatch: 'revertMatch',
				closeDialog: 'closeDialog',
			}),
			async submit() {
				const data = {
					tournamentId: this.tournament.getId(),
					matchId: this.revertMatchDialogId,
                    tournamentType: this.revertMatchDialogType
				};
				await this.revertMatch(data);
                this.$emit('update')
                this.close();
            },
			close() {
				this.closeDialog({type: REVERT_MATCH_DIALOG})
			}
		}
	};
</script>
