<template>
    <div class="miltilobby">
        <div class="page__content-header">
            <PageLinks :links="[
					{
						url: '/tournament/' +  tournament.getId(),
						text: tournament.getTitle()
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/brackets',
						text: $t('Leaderboard')
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/participants',
						text: $t('Participants')
					}
				]"/>
            <div class="page-content__actions">
                <LeaderboardRoundsScheduleButton/>
                <MultiLobbyStartButton v-if="['REGISTRATION_CLOSED'].includes(tournament.getStatus())"/>
                <MultiLobbyGenerate v-if="['REGISTRATION_OPENED', 'CHECKIN'].includes(tournament.getStatus())"/>
                <LeaderboardCloseButton/>
            </div>
        </div>
        <div class="page__content-body" :class="{progress: showProgressScreen}">
                <LeaderboardGeneratingLoader v-if="showProgressScreen"/>
                <Multilobby v-if="!showProgressScreen && isStarted" :id="id"></Multilobby>
                <p class="form-field-description" v-if="!isStarted && !showProgressScreen">
                    Information will be generated after the generated of lobbies
                </p>
                <LeaderboardRoundsScheduleDialog/>
        </div>
    </div>
</template>
<script>
import PageLinks from '../../components/Page/Links'
import MultiLobbyStartButton from './MuliLobbyStartButton'
import LeaderboardStartButton from '../Leaderboard/LeaderboardStartButton'
import LeaderboardCloseButton from '../Leaderboard/LeaderboardCloseButton'
import LeaderboardGeneratingLoader from '../Leaderboard/LeaderboardGeneratingLoader'
import Multilobby from './Multilobby'
import LeaderboardRoundsScheduleButton from '../Leaderboard/LeaderboardRoundsScheduleButton'
import MultiLobbyGenerate from '../Multilobby/MultiLobbyGenerate'
import LeaderboardRoundsScheduleDialog from '../Leaderboard/LeadeboardRoundsScheduleDialog'
import {mapGetters} from "vuex";
export default {
    props: {
        id: String
    },
    components: {
        PageLinks,
        LeaderboardCloseButton,
        LeaderboardStartButton,
        LeaderboardRoundsScheduleDialog,
        MultiLobbyGenerate,
        MultiLobbyStartButton,
        LeaderboardRoundsScheduleButton,
        Multilobby,
        LeaderboardGeneratingLoader
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament',
            tournamentStatus: 'tournamentStatus'
        }),
        isStarted () {
            return ['STARTED', 'IN_PROGRESS', 'FINISHED', 'CLOSED', 'REGISTRATION_CLOSED'].includes(this.tournamentStatus)
        },
        showProgressScreen() {
            return 'PENDING' === this.tournamentStatus;
        }
    },
}
</script>
