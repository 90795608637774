<template>
    <div class="tft-public-brackets">
        <template v-if="!brackets.length">
            <div class="match__loader">
                <img src="https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif" alt="">
            </div>
        </template>
        <template v-else>
            <BracketsRoundsList :isPublic="isPublic" v-if="type !== 'lower'"/>
            <div class="brackets__wrapper">
                <BracketsStatusesMap/>
                <BracketNode :type="type" v-if="!isMobileBracket()" :data="bracketsData"/>

                <BracketMobileNode v-if="isMobileBracket()" :data="bracketsData"/>
            </div>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import BracketItem from '../../components/Brackets/BracketItem'
import BracketNode from './TftUiBracketNode'
import BracketMobileNode from '../../components/Brackets/BracketMobileNode'
import BracketsRoundsList from '../../components/Brackets/BracketsRoundsList'
import BracketsStatusesMap from '../../components/Brackets/BracketsStatusesMap'
import helpers from '../../helpers'

export default {
    components: {
        BracketItem,
        BracketNode,
        BracketMobileNode,
        BracketsRoundsList,
        BracketsStatusesMap
    },
    props: {
        type: {
            required: false,
            type: String,
        },
        id: String,
        isPublic: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.isPublic) {
            this.getTournament(this.id)
            this.getPublicBrackets(this.id);
        } else {
            this.getBrackets(this.id);
        }

        setTimeout(() => {
            this.initWorker()
        }, 2000)
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament',
            brackets: 'brackets',
            selectedRound: 'selectedRound',
            isMobile: 'isMobile',
            centrifugeInstance: 'centrifugeInstance'
        }),
        bracketsData() {
            const handler = (brackets, de) => {
                if (this.isMobileBracket()) {
                    return helpers.generateMobileBracket(brackets, this.selectedRound);
                }

                return helpers.generateRecursiveBracket(brackets);
            }
            if (this.type === 'lower') {
                return handler(helpers.bracketsLower(this.brackets), true)
            }
            if (this.type === 'upper') {
                return handler(helpers.bracketsUpper(this.brackets), true)
            }

            return handler(this.brackets)
        }
    },
    methods: {
        ...mapActions({
            getTournament: 'GET_TOURNAMENT',
            getBrackets: 'fetchBrackets',
            getPublicBrackets: 'fetchPublicBrackets',
        }),
        initWorker () {

        },
        isMobileBracket() {
            return this.isMobile && !this.isPublic;
        }
    }
};
</script>
<style scoped lang="scss">
.match__loader {
    width: 100%;
    display: flex;
    padding: 30px 0;
    justify-content: center;
    img {
        width: 40px;
    }
    .brackets__wrapper {
        padding-bottom: 60px;
    }
}
.brackets__wrapper {
    padding-bottom: 60px;
}
</style>
