<template>
	<div
		class="brackets__start-tournament-button"
		v-if="show()"
	>
		<button
			class="button button-primary"
			@click.prevent="start"
		>{{$t('Start tournament')}}
		</button>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'

	export default {
		name: "BracketsStartTournamentButton",
		computed: {
			...mapGetters({
				tournament: 'tournament',
				brackets: 'brackets',
			})
		},
		methods: {
			...mapActions({
				startTournament: 'startTournament'
			}),
			start() {
                this.startTournament({
                    id: this.tournament.getId(),
                    brackets: this.brackets
                }).catch(err => {
                    this.$notify({
                        type: 'error',
                        title: 'Permission error',
                        group: 'notify',
                        text: err.response.data.message ? err.response.data.message : 'Unknown error'
                    })
                })
			},
			show() {
				return 'REGISTRATION_CLOSED' === this.tournament.getStatus();
			}
		}
	};
</script>
