<template>
	<div>
		<div class="page__content-header">
			<div>
				<PageLinks v-bind:links="[
					{
						url: '/deep-links',
						text: $t('Deep Links')
					}
				]"/>
			</div>
			<div>
				<RouterLink to="/deep-links/add" :class="'button button-primary'">{{$t('Create New Deep Link')}}</RouterLink>
			</div>
		</div>
		<div>
			<FiltersBar :options="filters" :onChange="filtersChanged"/>
			<div class="table-wrapper">
				<table v-if="deepLinks && platforms && games">
					<thead class="text-left">
					<tr>
						<th scope="col">#</th>
						<th scope="col">ID</th>
						<th scope="col">{{$t('Link')}}</th>
						<th scope="col">{{$t('Source')}}</th>
						<th scope="col">{{$t('Game')}}</th>
						<th scope="col">{{$t('Platform')}}</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(deepLink, index) in deepLinks">
						<td scope="row">{{index + 1}}</td>
						<td>{{deepLink.getLinkId()}}</td>
						<td>
							<div class="copy-field">
								<span class="text-ellipsis">{{deepLink.getLink()}}</span>
								<a href="#" @click.prevent="copy(deepLink.getLink())">
									<SvgIcon
										width="22"
										height="22"
										:iconType="'copy'"
									/>
								</a>
							</div>
						</td>
						<td class="text-ellipsis text-ellipsis-100">{{deepLink.getSource()}}</td>
						<td>{{getGameName(deepLink.getGameId())}}</td>
						<td>{{getPlatformName(deepLink.getPlatformId())}}</td>
						<td>
							<a href="#" @click.prevent="deleteLink(deepLink.getLinkId())">
								<SvgIcon
									width="22"
									height="22"
									:iconType="'delete'"
								/>
							</a>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<PagePagination
				v-if="deepLinksMeta"
				:current="deepLinksMeta.currentPage()"
				:total="deepLinksMeta.lastPage()"
				@change="changePage"
			/>
		</div>
	</div>
</template>

<script>
	import {mapGetters, mapActions, mapMutations} from 'vuex'

	import SvgIcon from '../../components/SvgIcon'
	import PagePagination from '../../components/Page/Pagination'
	import PageLinks from '../../components/Page/Links'
	import FiltersBar from '../../components/FiltersBar';

	export default {
		components: {
			SvgIcon,
			PageLinks,
			FiltersBar,
			PagePagination
		},
		computed: {
			...mapGetters([
				'deepLinks',
				'deepLinksMeta',
				'platforms',
				'games',
				'getGame',
				'getPlatform',
				'activeFilters'
			])
		},
		data() {
			return {
				filters: [],
				filtersUtil: this.$options.filters
			}
		},
		created() {
			this.init();
		},
		methods: {
			...mapActions({
				getGames: 'getGames',
				getDeepLinks: 'GET_DEEP_LINKS',
				getPlatforms: 'GET_PLATFORMS',
				deleteDeepLinks: 'DELETE_DEEP_LINK',
			}),
			...mapMutations({
				setFilters: 'SET_FILTERS',
			}),
			async init() {
				await this.getPlatforms();
				await this.getDeepLinks(this.activeFilters('deepLinks'));

				await this.getGames();
				await this.prepareFiltersFields();
			},
			async deleteLink(id) {
				const activeFilters = this.activeFilters('deepLinks');

				await this.deleteDeepLinks(id);
				await this.getDeepLinks({
					...activeFilters
				});
			},
			getGameName(id) {
				return id && this.getGame ? this.getGame(id)[0]?.name : '';
			},
			getPlatformName(id) {
				return id && this.getPlatform ? this.getPlatform(id)[0].getName() : '';
			},
			copy(string) {
				this.$clipboard(string);
				alert("Copied to clipboard");
			},
			changePage(page) {
				const activeFilters = this.activeFilters('deepLinks');

				this.getDeepLinks({
					page: page,
					...activeFilters
				});
			},
			prepareFiltersFields() {
				const activeFilters = this.activeFilters('deepLinks');
				this.filters.push({
					type: 'select',
					name: 'games',
					value: activeFilters.hasOwnProperty('games') ? activeFilters.games : null,
					options: this.filtersUtil.gamesOptions(this.games)
				});
			},
			filtersChanged(field, value) {
				this.setFilters({
					type: 'deepLinks',
					key: field,
					value: value ? value : null
				});

				this.getDeepLinks(this.activeFilters('deepLinks'));
			}
		},
	}
</script>
