<template>
	<div class="form-textarea-field">
		<label>
			<span class="form-label">{{label}}</span>
			<textarea
				class="form-textarea"
				:name="name"
				:placeholder="placeholder"
				v-model="newValue"
				@change="valueChanged"
			></textarea>
		</label>
		<FormNotification :errors="errors"/>
	</div>
</template>

<script>
	import FormNotification from '../../components/Form/Notification'

	export default {
		name: "FormTextarea",
		components: {
			FormNotification
		},
		data() {
			return {
				newValue: '',
				errors: this.formErrors,
			}
		},
		props: {
			name: {
				type: String,
			},
			value: {
				type: String,
			},
			placeholder: {
				type: String,
			},
			label: {
				type: String,
			},
			formErrors: {
				type: [Array, Object],
			},
			maxLength: {
				type: [Number, String]
			},
			onChange: {type: Function},
		},
		watch: {
			value: {
				handler(val) {
					this.newValue = val;
				}
			},
			formErrors: {
				handler(errors) {
					this.errors = errors;
				},
				deep: true
			}
		},
		mounted() {
			this.newValue = this.value;
		},
		methods: {
			valueChanged(event) {
				this.onChange(this.name, event.target.value);
				this.errors = [];
			}
		}
	};
</script>