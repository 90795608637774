<template>
	<main
		class="page__content"
		:class="[(!showPanel || isLoginPage()) ? 'fullwidth' : '', showPanel && !navigationIsClosed ? 'menu-opened' : '', ($route.name === 'chats' ? 'p-0' : '')]"
	>
		<page-loader/>
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>

	</main>
</template>

<script>
	import PageLoader from '../Page/Loader'


	import {mapGetters} from 'vuex'

	export default {
		name: "page-main",
		components: {
			PageLoader
		},
		computed: {
			...mapGetters([
				'navigationIsClosed',
				'showPanel'
			]),
		},
		methods: {
			isLoginPage() {
				return 'login' === this.$route.name
			}
		}
	};
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}


.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
