<template>
    <div class="game__detail">
        <div class="columns" v-if="game">
            <div class="column">
                <div class="column__title">
                    Game informations
                </div>
                <form @submit.prevent="save" class="form">
                    <div class="form__input">
                        <label for="">Name</label>
                        <input v-model="game.name" required type="text" placeholder="Name">
                    </div>
                    <div class="form__input">
                        <label for="">Short name</label>
                        <input v-model="game.name_short" required type="text" placeholder="Short name">
                    </div>
                    <div class="form__input">
                        <label for="">Label</label>
                        <input v-model="game.label" required type="text" placeholder="Label">
                    </div>
                    <div class="form__input">
                        <label for="">iOS label</label>
                        <input v-model="game.ios_label" required type="text" placeholder="iOS label">
                    </div>
                    <div class="form__input">
                        <label for="">ReGex</label>
                        <input v-model="game.reg_ex" type="text" placeholder="ReGex">
                    </div>
                    <div class="form__input" v-if="platforms">
                        <label for="">Game platform</label>
                        <FormMultiSelect
                            v-model="game.platforms"
                            :placeholder="$t('Games')"
                            :searchable="false"
                            :options="platforms"
                            :loading="multiSelectGamesOptions.loading"
                            :label="multiSelectGamesOptions.label"
                            :track-by="multiSelectGamesOptions.trackBy"
                            :multiple="multiSelectGamesOptions.multiple"
                            :taggable="multiSelectGamesOptions.taggable"
                        />
                    </div>

                    <div class="form__input">
                        <label for="">Participant number (use comma as separator)*</label>
                        <input v-model="game.participant_number" required type="text" placeholder="0">
                    </div>

                    <div class="form__input">
                        <label for="">Co-Captan limit</label>
                        <input v-model="game.co_cap_limit" type="number" placeholder="0">
                    </div>
                    <div class="form__buttons">
                        <button class="button">Back</button>
                        <button class="button button-primary" type="submit">Save</button>
                    </div>
                </form>


            </div>
            <div class="column">
                <div class="column__title">
                    Images default
                </div>

                <ImageUploader :value="game.icon" @upload="game.icon = $event" label="Logo Image" sizes="80x80"></ImageUploader>
                <ImageUploader :value="game.image" @upload="game.image = $event" label="Game banner" sizes="377x800"></ImageUploader>
                <ImageUploader :value="game.top_banner" @upload="game.top_banner = $event" label="Top banner"></ImageUploader>

                <div class="column__title">
                    Web app images
                </div>
                <ImageUploader :value="game.web_image" @upload="game.web_image = $event" label="Web image in list" sizes="377x799"></ImageUploader>
                <ImageUploader :value="game.web_icon" @upload="game.web_icon = $event" label="Web icon" ></ImageUploader>
            </div>
            <div class="column">
                <div :class="{'active': game.required_template}" class="column__checkbox">
                    <span>Template required</span>
                    <RadialCheckbox :value="game.required_template" v-model="game.required_template"></RadialCheckbox>
                </div>
                <div :class="{'active': game.is_global}" class="column__checkbox">
                    <span>Is global (single profile for all platforms)</span>
                    <RadialCheckbox :value="game.is_global" v-model="game.is_global"></RadialCheckbox>
                </div>
                <div :class="{'active': game.display_in_tournaments}" class="column__checkbox">
                    <span>Display in tournaments (available)</span>
                    <RadialCheckbox :value="game.display_in_tournaments" v-model="game.display_in_tournaments"></RadialCheckbox>
                </div>

                <div :class="{'active': game.display_in_events}" class="column__checkbox">
                    <span>Display in events</span>
                    <RadialCheckbox :value="game.display_in_events" v-model="game.display_in_events"></RadialCheckbox>
                </div>
                <div :class="{'active': game.community_default}" class="column__checkbox">
                    <span>Community default</span>
                    <RadialCheckbox :value="game.community_default" v-model="game.community_default"></RadialCheckbox>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import FormMultiSelect from 'vue-multiselect'
import RadialCheckbox from '../../components/UI/RadialCheckbox'
import ImageUploader from '../../components/UI/ImageUploader'
import {mapActions, mapGetters} from "vuex";
import { getGame, update, addGame } from '../../api/games'
export default {
    data () {
        return {
            game: null,
            multiSelectGamesOptions: {
                loading: false,
                label: 'name',
                trackBy: 'id',
                multiple: true,
                taggable: true,
            },
        }
    },
    computed: {
        isEdit () {
            return this.$route.name === 'game'
        },
        ...mapGetters({
            platforms: 'nativePlatforms'
        })
    },
    methods: {
        ...mapActions({
            getPlatforms: 'GET_PLATFORMS',
        }),
        save () {
            const data = new FormData();
            data.append('name', this.game.name)
            data.append('name_short', this.game.name_short)
            data.append('label', this.game.label)
            data.append('ios_label', this.game.ios_label)
            data.append('reg_ex', this.game.reg_ex)
            data.append('platforms', this.game.platforms.map(item => item.id).join(','))
            data.append('participant_number', this.game.participant_number)
            data.append('co_cap_limit', this.game.co_cap_limit)
            data.append('required_template', this.game.required_template ? 1 : 0)
            data.append('is_global', this.game.is_global ? 1 : 0)
            data.append('display_in_tournaments', this.game.display_in_tournaments ? 1 : 0)
            data.append('display_in_events', this.game.display_in_events ? 1 : 0)
            data.append('community_default', this.game.community_default ? 1 : 0)
            if (this.game.icon instanceof File) {
                data.append('icon', this.game.icon)
            }
            if (this.game.image instanceof File) {
                data.append('image', this.game.image)
            }
            if (this.game.top_banner instanceof File) {
                data.append('top_banner', this.game.top_banner)
            }
            if (this.game.web_image instanceof File) {
                data.append('web_image', this.game.web_image)
            }
            if (this.game.web_icon instanceof File) {
                data.append('web_icon', this.game.web_icon)
            }

            if (this.isEdit) {
                data.append('_method', 'PUT')
                return update(this.game.id, data).then(response => {
                    if (response.data.data) {
                        this.game = response.data.data
                        this.$forceUpdate()
                    }
                    this.$notify({
                        type: 'success',
                        title: 'Success action',
                        group: 'notify',
                        text: 'Success update'
                    })
                }).catch(() => {
                    this.$notify({
                        type: 'error',
                        title: 'Error action',
                        group: 'notify',
                        text: 'Make sure all fields are filled'
                    })
                })
            }

            return addGame(data).then(response => {
                if (response.data.data) {
                    this.$notify({
                        type: 'success',
                        title: 'Success action',
                        group: 'notify',
                        text: 'Success add game'
                    })
                    this.game = response.data.data
                    return this.$router.push('/games/' + response.data.data.id)
                }
            }).catch(() => {
                this.$notify({
                    type: 'error',
                    title: 'Error action',
                    group: 'notify',
                    text: 'Make sure all fields are filled'
                })
            })
        },
        getGame () {
            getGame(this.$route.params.id).then(response => {
                this.game = response.data.data
            })
        }
    },
    mounted () {
        if (!this.isEdit) {
            this.game = {
                name: '',
                name_short: '',
                label: '',
                ios_label: '',
                reg_ex: '',
                platforms: [],
                participant_number: '',
                co_cap_limit: 0,
                required_template: false,
                is_global: false,
                display_in_tournaments: false,
                display_in_events: false,
                community_default: false,
                icon: null,
                image: null,
                top_banner: null,
                web_image: null,
                web_icon: null,
            }
            return;
        }
        this.getGame();
        this.getPlatforms();
    },
    components: {
        RadialCheckbox,
        ImageUploader,
        FormMultiSelect
    }
}
</script>
<style scoped lang="scss">

.game__detail {
    .columns {
        display: flex;
        .column {
            flex: 1;
            .column__title {
                background: #ECECEC;
                border-radius: 4px;
                padding: 14px 15px;
                font-weight: 700;
                font-size: 18px;
                line-height: 21px;
                color: #303030;
                margin-bottom: 15px;
            }
            .form__buttons {
                display: flex;
                margin-top: 30px;
                button {
                    flex: 1;
                }
                button + button {
                    margin-left: 20px;
                }
            }
            .form {
                width: 100%;
                max-width: 100%;
                padding: 0;
                .form__input {
                    width: 100%;
                    label {
                        display: block;
                        width: 100%;
                        margin-bottom: 6px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #06C2BC;
                    }
                    input {
                        padding: 12px 15px;
                        outline: none;
                        border: 1px solid #878787;
                        background: transparent;
                        border-radius: 4px;
                        font-size: 14px;
                        width: 100%;
                        line-height: 16px;
                        color: #303030;
                        font-weight: bold;
                        &::placeholder {
                            font-weight: normal;
                            color: #878787;
                        }
                    }
                }
                .form__input + .form__input {
                    margin-top: 15px;
                }
            }
            .column__checkbox {
                background: #FAFAFA;
                border: 1px solid #878787;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 17px 15px;
                transition: 0.2s;
                span {
                    font-weight: 700;
                    font-size: 14px;
                    transition: 0.2s;
                    line-height: 16px;
                    color: #878787;
                }
                &.active {
                    box-shadow: 0px 0px 4px rgba(0, 80, 77, 0.1), 0px 2px 10px rgba(0, 80, 77, 0.25);
                    border: 1px solid #06C2BC;
                    span {
                        color: #06C2BC;
                    }
                }
            }
            .column__checkbox + .column__checkbox {
                margin-top: 15px;
            }
        }
        .column + .column {
            margin-left: 20px;
        }
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
