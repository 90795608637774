<template>
	<div>
		<div class="page-title" v-if="isEdit">{{ $t('Edit tournament') }}</div>
		<div class="page-title" v-else>{{ $t('Add tournament') }}</div>
		<form class="form form--fluid">
			<div class="shell-fluid">
				<div class="range">
					<div class="cell-sm-8">
						<div class="form-title">{{ $t('Tournament details') }}</div>
						<div class="range">
							<div class="cell-sm-6">
								<div class="form-group" v-if="!isEdit" :class="{ disabled: isEdit }">
									<FormSelect :name="'type'" :label="$t('Tournament Type')" :value="form.type"
										:options="tournamentTypeOptions" :formErrors="errors.type" :onChange="formFieldChanged" />
								</div>

								<template v-if="!isEdit">

									<div class="form-group" v-if="Number(form.type) === 5">
										<FormNumber :name="'min_lobby_players'" :label="$t('Min players in lobby')"
											:value="form.min_lobby_players" :formErrors="errors.min_lobby_players"
											:onChange="formFieldChanged" :min="1" />
									</div>

									<div class="form-group" v-if="Number(form.type) === 5">
										<FormNumber :name="'max_lobby_players'" :label="$t('Max players in lobby')"
											:value="form.max_lobby_players" :formErrors="errors.max_lobby_players"
											:onChange="formFieldChanged" :min="1" />
									</div>
								</template>


								<div class="form-group" v-if="Number(form.type) === 2">
									<FormNumber :name="'rounds'" :label="$t('Rounds')" :value="form.rounds" :formErrors="errors.rounds"
										:onChange="formFieldChanged" :min="1" />
								</div>


								<div class="form-group text-center">
									<ImagePicker :image="form.image" :label="$t('Image')" :formErrors="errors.image"
										:onChangeImage="onChangeImage" :onRemoveImage="onRemoveImage" />
								</div>
								<div class="form-group text-center">
									<ImagePicker :image="form.icon" :label="$t('Icon (200x200 pixel)')" :formErrors="errors.icon"
										:onChangeImage="onChangeIcon" :onRemoveImage="onRemoveIcon" />
								</div>
								<div class="form-group" v-if="showPromo">
									<FormCheckbox :name="'send_promo'" :value="form.send_promo"
										:label="$t('Send promo notification to all users')" :onChange="formFieldChanged" />
								</div>
								<div class="form-group" v-if="!isEdit">
									<FormCheckbox :name="'consider_in_leaderboard'" :value="form.consider_in_leaderboard"
										:label="$t('Points')" :onChange="formFieldChanged" />
								</div>
								<div class="form-group" v-if="!isEdit && form.type !== '6'">
									<FormCheckbox :disabled="false" :name="'with_third_place_match'"
										:value.number="form.with_third_place_match" :label="$t('Match for 3rd place')"
										:onChange="formFieldChanged" />
								</div>
								<div class="form-group">
									<FormCheckbox :disabled="false" :name="'is_chat_enabled'" v-model.number="form.is_chat_enabled"
										:label="$t('With tournament chat')" :onChange="formFieldChanged" />
								</div>
								<div class="form-group" v-show="form.is_chat_enabled">
									<FormCheckbox :disabled="false" :name="'chat_image_enabled'" v-model.number="form.chat_image_enabled"
										:label="$t('Allow send images in chat')" :onChange="formFieldChanged" />
								</div>
								<div class="form-group">
									<FormCheckbox :disabled="false" :name="'require_user_profiling'"
										v-model.number="form.require_user_profiling" :label="$t('With profiling acceptance')"
										:onChange="formFieldChanged" />
								</div>

								<template v-if="">
									<div class="form-title">Mzaalo</div>
									<div class="form-group">
										<div class="custom__checkbox">
											<input v-model.number="form.notify_mzaalo" type="checkbox" id="notify_mzaalo" />
											<label for="notify_mzaalo">Notify Mzaalo</label>
										</div>
									</div>
								</template>


								<div class="form-title">Supported Platforms</div>
								<div class="form-group">
									<div class="custom__checkbox">
										<input v-model.number="form.available_web" type="checkbox" id="test1" />
										<label for="test1">Available for WEB</label>
									</div>
									<div class="custom__checkbox">
										<input v-model.number="form.available_mobile" type="checkbox" id="test2" />
										<label for="test2">Available for MOBILE</label>
									</div>
								</div>
								<template v-if="!isEdit && [1, 3].includes(parseInt(form.type))">
									<div class="form-title">Seeding</div>
									<div class="form-group">

										<div class="custom__checkbox">
											<input v-model.number="form.custom_seeding" type="checkbox" id="test3" />
											<label for="test3">Custom seeding</label>
										</div>
									</div>
								</template>


								<div class="form-group">
									<FormInput :name="'title'" :label="$t('Title')" :placeholder="$t('Name')" :value="form.title"
										:formErrors="errors.title" :onChange="formFieldChanged" />
								</div>
								<template v-if="isChallenge">
									<div class="form-title" style="margin-bottom: 15px;">Challenge description</div>

									<div class="form-group">
										<TextEditor :name="'challenge_description'" class="form-text-editor"
											:label="$t('Challenge description')" :placeholder="$t('Challenge description')"
											:value="form.challenge_description" :formErrors="errors.challenge_description"
											@change="formFieldChanged" v-model="form.challenge_description" />
									</div>
								</template>


								<div class="form-group" v-if="!isEdit">
									<FormInput :name="'secret_code'" :label="$t('Secret code')" :placeholder="$t('Code')"
										:value="form.secret_code" :formErrors="errors.secret_code" :onChange="formFieldChanged" />
								</div>
								<div class="form-group">
									<FormSelect :name="'rule_id'" :label="$t('Rule')" :value="form.rule_id" :options="rulesOptions"
										:formErrors="errors.rule_id" :onChange="formFieldChanged" />
								</div>
								<div class="form-group">
									<FormSelect :name="'locationType'" :label="$t('Location type')" :value="locationType"
										:options="locationTypeOptions" :onChange="locationChanged" />
								</div>
								<div class="form-group" v-if="locationType === 'region'">
									<FormSelect :name="'region_id'" :label="$t('Region')" :value="form.region_id"
										:options="regionsOptions" :formErrors="errors.region_id" :onChange="formFieldChanged" />
								</div>
								<div class="form-group" v-if="locationType === 'country'">
									<span class="form-label">{{ $t('Choose countries') }}</span>
									<FormMultiSelect v-model="form.supported_countries" :placeholder="$t('Countries')"
										:options="countries" :loading="multiSelectOptions.loading" :label="multiSelectOptions.label"
										:track-by="multiSelectOptions.trackBy" :multiple="multiSelectOptions.multiple"
										:taggable="multiSelectOptions.taggable" @search-change="onSearchChange" />
									<FormNotification :errors="errors.supported_countries" />
								</div>
								<div class="form-group" :class="{ disabled: isEdit }">
									<FormSelect :name="'game_id'" :label="$t('Game')" :value="form.game_id" :options="gamesOptions"
										:formErrors="errors.game_id" :onChange="formFieldChanged" />
								</div>
								<div class="form-group" v-if="!isEdit && Number(form.game_id) === 6">
									<FormCheckbox :name="'match_autohandle'" :value="form.match_autohandle"
										:label="$t('Use Clash Royale API')" :onChange="formFieldChanged" />
								</div>
								<div class="form-group" :class="{ disabled: isEdit }">
									<FormSelect :name="'template_id'" :label="$t('Bracket template')" :value="form.template_id"
										:options="templatesOptions" :formErrors="errors.template_id" :onChange="formFieldChanged" />
									<FormNotification :errors="errors.game_id" />
								</div>
								<div class="form-group" :class="{ disabled: isEdit }">
									<FormSelect :name="'match_type'" :label="$t('Tournament Match Type')" :value="form.match_type"
										:options="proxyMatchTypeOptions" :formErrors="errors.match_type" :onChange="formFieldChanged" />
								</div>
								<div class="form-group" v-if="Number(form.match_type) === 2">
									<FormNumber :name="'substitute_limit'" :label="$t('Substitutes Count')" :value="form.substitute_limit"
										:formErrors="errors.substitute_limit" :onChange="formFieldChanged" :min="0" />
								</div>
								<div class="form-group" v-if="Number(form.match_type) === 2">
									<FormNumber :name="'team_mates'" :label="$t('Number of teammates')" :value="form.team_mates"
										:formErrors="errors.team_mates" :onChange="formFieldChanged" :min="2" />
								</div>

								<div class="form-group" v-if="games" :class="{ disabled: isEdit }">
									<FormSelect :name="'platform_id'" :label="$t('Platform')" :value="form.platform_id"
										:options="platformsOptions" :formErrors="errors.platform_id" :onChange="formFieldChanged" />
								</div>
							</div>
							<div class="cell-sm-6">
								<div class="form-group">
									<FormNumber :name="'max_member'" :label="$t('Max member')" :value="form.max_member"
										:formErrors="errors.max_member" :onChange="formFieldChanged" :min="form.type === '6' ? 16 : 6" />
								</div>
								<div class="form-group" :class="{ disabled: isEdit }">
									<FormSelect :name="'start_status'" :label="$t('Start status')" :value="startStatus"
										:options="startStatusOptions" :onChange="startStatusChanged" />
								</div>
								<div class="form-group">
									<label class="form-label">
										{{ $t('Start time') }}
									</label>
									<Datetime :format="`DD HH:mm`" type="datetime" v-model="form.started_at" />
									<FormNotification :errors="errors.started_at" />
								</div>


								<div class="form-group" v-if="isChallenge">
									<label class="form-label">
										{{ $t('Challenge end date') }}
									</label>
									<Datetime :format="`DD HH:mm`" type="datetime" v-model="form.end_at" />
									<FormNotification :errors="errors.end_at" />
								</div>

								<div class="form-group" v-if="startStatus === 'opened'">
									<label class="form-label">
										{{ $t('Registration start') }}
									</label>
									<Datetime :format="`DD HH:mm`" type="datetime" v-model="form.register_timeout" />
									<FormNotification :errors="errors.register_timeout" />
								</div>
								<div class="form-group" v-if="!isEdit">
									<div class="form-group__item">
										<FormNumber :name="'fixture_participants'" :label="$t('Fixture participants')"
											:value="form.fixture_participants" :formErrors="errors.fixture_participants"
											:onChange="formFieldChanged" :min="0" />
									</div>
								</div>

								<div class="form-group">
									<div class="custom__checkbox">
										<input v-model.number="form.auto_check_in" type="checkbox" id="test12as" />
										<label for="test12as">Auto-check in</label>
									</div>
								</div>

								<div class="form-group">
									<FormNumber :name="'check_in_time'" :label="$t('Checkin time')" :value="form.check_in_time"
										:formErrors="errors.check_in_time" :onChange="formFieldChanged" :min="0" :max="1440" />
								</div>
								<div class="form-group">
									<FormInput :name="'chat_link'" :label="$t('Chat link')" :placeholder="$t('Chat link')"
										:value="form.chat_link" :formErrors="errors.chat_link" :onChange="formFieldChanged" />
								</div>
								<div class="form-group">
									<FormInput :name="'site_link'" :label="$t('Site link')" :placeholder="$t('Site link')"
										:value="form.site_link" :formErrors="errors.site_link" :onChange="formFieldChanged" />
								</div>
								<div class="form-group">
									<FormTextarea :name="'description'" :label="$t('Description')" :placeholder="$t('Description')"
										:value="form.description" :formErrors="errors.description" :onChange="formFieldChanged" />
								</div>
							</div>
						</div>
					</div>
					<div class="cell-sm-4">
						<div class="form-title">{{ $t('Sponsored') }}</div>
						<div class="range">
							<div class="cell-sm-12">
								<div class="form-group">
									<ImagePicker :image="form.sponsor_image" :label="$t('Thumbnail')" :formErrors="errors.sponsor_image"
										:onChangeImage="onChangeSponsorImage" :onRemoveImage="onRemoveSponsorImage" />
								</div>
								<div class="form-group">
									<FormInput :name="'sponsor_url'" :label="$t('URL')" :placeholder="$t('')" :value="form.sponsor_url"
										:formErrors="errors.sponsor_url" :onChange="formFieldChanged" />
								</div>
								<div class="form-group">
									<FormTextarea :name="'sponsor_text'" :label="$t('Message')" :placeholder="$t('Message')"
										:value="form.sponsor_text" :formErrors="errors.sponsor_text" :onChange="formFieldChanged" />
								</div>
							</div>
						</div>
						<div class="form-title">{{ $t('Prize pool') }}</div>

						<div class="form-group">
							<div class="range offset-top-15" v-for="(prize, k) in form.prize_pool" :key="k">
								<div class="cell-sm-12">
									<label for="">
										<span>{{ prize.place }}<template v-if="prize.place === 1">st</template><template
												v-else-if="prize.place === 2">nd</template><template
												v-else-if="prize.place === 3">rd</template><template v-else="prize.place === 3">th</template>
											place prize</span>
									</label>
								</div>
								<div class="cell-sm-6">
									<div class="prize-pull__input">
										<FormInput :name="'prize_money_' + prize.place" :placeholder="$t('Euro')" :value="prize.prizes.EUR"
											:formErrors="errors.sponsor_url" :emittedData="{ index: k, wallet: 'EUR' }" :showError="false"
											:type="'number'" @onInput="prize.prizes.EUR = Math.abs($event), $forceUpdate()" />
										<img v-if="!prize.prizes.EUR" src="/images/icons/euro.svg" alt="">
										<img v-else src="/images/icons/euro-fill.svg" alt="">
									</div>
								</div>
								<div class="cell-sm-6">
									<div class="prize-pull__input">
										<FormInput :name="'prize_coin_' + prize.place" :placeholder="$t('Coins')"
											:value="prize.prizes.COINS" :formErrors="errors.sponsor_url"
											:emittedData="{ index: k, wallet: 'COINS' }" :showError="false" :disabled="true" :type="'number'"
											@onInput="prize.prizes.COINS = Math.abs($event), $forceUpdate()" />
										<img v-if="!prize.prizes.COINS" src="/images/icons/coin.svg" alt="">
										<img v-else src="/images/icons/coin-fill.svg" alt="">
									</div>
								</div>
							</div>

							<FormNotification :errors="errors.prize_pool" />

							<div class="offset-top-20">
								<button class="button button-gray"
									:disabled="form.prize_pool && form.prize_pool.length >= form.max_member"
									@click.prevent="addNewPrize()">
									{{ $t('+ Add more') }}
								</button>
							</div>
						</div>

					</div>
				</div>
			</div>
			<FormNotification :errors="errors.message" />
			<button class="button button-primary form-button-submit" @click.prevent="save()">
				{{ $t('Save') }}
			</button>
		</form>
		<ConfirmationCreateTournamentDialog :text="confirmationText" :callback="addPost"
			@close="isShowConfirmationPopup = false" v-if="isShowConfirmationPopup"></ConfirmationCreateTournamentDialog>
	</div>
</template>

<script>
import ConfirmationCreateTournamentDialog from '../../components/Dialogs/ConfirmationCreateTournamentDialog'
import { mapGetters, mapActions, mapMutations } from 'vuex'

import moment from 'moment'
import helpers from '../../helpers'
import router from '../../routes'
import SvgIcon from '../../components/SvgIcon'
import RadialCheckbox from '../../components/UI/RadialCheckbox'
import FormNotification from '../../components/Form/Notification'
import FormCheckbox from '../../components/Form/Checkbox'
import FormInput from '../../components/Form/Input'
import FormNumber from '../../components/Form/Number'
import FormTextarea from '../../components/Form/Textarea'
import FormSelect from '../../components/Form/Select'
import ImagePicker from '../../components/Form/ImagePicker'
import PageLinks from '../../components/Page/Links'
import { Datetime } from 'vue-datetime'
// import 'vue-datetime/vue-datetime.css'

import _ from 'lodash'
import FormMultiSelect from 'vue-multiselect'

import {
	TOURNAMENT_FIELDS_VALUES,
	MATCH_TYPES_VALUES,
	TOURNAMENT_TYPES_VALUES,
	LOCATION_TYPES,
	START_STATUS
} from '../../constants/form'
import { LOCATIONS } from '../../constants/locations'
import { VueEditor } from "vue2-editor";

export default {
	props: ["id"],
	components: {
		'TextEditor': VueEditor,
		SvgIcon,
		FormNotification,
		ConfirmationCreateTournamentDialog,
		FormCheckbox,
		FormInput,
		FormSelect,
		FormNumber,
		RadialCheckbox,
		FormTextarea,
		ImagePicker,
		Datetime,
		PageLinks,
		FormMultiSelect
	},
	data() {
		return {
			isShowConfirmationPopup: false,
			form: {},
			defaultData: TOURNAMENT_FIELDS_VALUES,
			imageChanged: false,
			iconChanged: false,
			sponsorImageChanged: false,
			locationOptions: LOCATIONS,
			matchTypeOptions: MATCH_TYPES_VALUES,
			tournamentTypeOptions: TOURNAMENT_TYPES_VALUES,
			rulesOptions: [],
			platformsOptions: [],
			gamesOptions: [],
			templatesOptions: [],
			countriesOptions: [],
			regionsOptions: [],
			locationType: 'country',
			locationTypeOptions: LOCATION_TYPES,
			startStatus: 'registration',
			startStatusOptions: START_STATUS,
			errors: {},
			multiSelectOptions: {
				loading: false,
				label: 'value',
				trackBy: 'value',
				multiple: true,
				taggable: true,
			},
		};
	},
	computed: {
		...mapGetters({
			tournament: 'tournament',
			regions: 'regions',
			countries: 'countries',
			rules: 'rules',
			games: 'games',
			platforms: 'platforms',
			templates: 'bracketTemplates',
			getConfig: 'getConfig'
		}),
		isChallenge() {
			return +this.form.type === 8 || +this.form.type === 9;
		},
		isDonor() {
			return !!this.$route.query.donor_id
		},
		confirmationText() {
			const getType = () => {
				const find = this.tournamentTypeOptions.find(item => item.value == this.form.type)
				if (find) {
					return find.name
				}
				return 'Unknown'
			}
			const type = getType()

			const getGame = () => {
				const find = this.games.find(item => item.id == this.form.game_id)
				if (find) {
					return find.name
				}
				return 'Unknown'
			}
			const game = getGame()

			const getPlatform = () => {
				const find = this.platformsOptions.find(item => item.value == this.form.platform_id)
				if (find) {
					return find.name
				}
				return 'Unknown'
			}
			const platform = getPlatform()
			return `Confirm that you create ${type} tournament for ${game} (${platform})`
		},
		proxyMatchTypeOptions() {
			if (this.form.type == 6) {
				return [
					{ value: 1, name: 'Solo participant' }
				]
			}
			return this.matchTypeOptions
		},
		showPromo: function () {
			return !this.id;
		},
		isEdit: function () {
			return !!this.id;
		},
	},
	created() {
		this.onSearchChange = _.throttle(this.onSearchChange, 1000)
	},
	mounted() {
		if (this.isDonor) {
			return this.initializeDonor()
		}

		this.getFormData();
	},
	watch: {
		$route() {
			this.getFormData();
		},
		'form.type': function () {
			if (this.form.type === "6") {
				this.form.match_type = "1";
				this.form.with_third_place_match = 0;
			}
		},
		// 'form.match_type': function () {
		//     if (Number(this.form.match_type) === 2) {
		//         this.form.turq_enabled = false;
		//     }
		// }
	},
	methods: {
		...mapMutations({
			removeExpandedChat: 'REMOVE_EXPANDED_CHAT',
			removeOpenedChat: 'REMOVE_OPENED_CHAT',
			removeTournamentChat: 'REMOVE_TOURNAMENT_CHAT'
		}),
		...mapActions({
			getRegions: 'getRegions',
			getCountries: 'getCountries',
			getGames: 'getGames',
			getTemplates: 'getTemplates',
			getPlatforms: 'GET_PLATFORMS',
			getRules: 'GET_RULES',
			getTournament: 'GET_TOURNAMENT',
			addTournament: 'ADD_TOURNAMENT',
			updateTournament: 'UPDATE_TOURNAMENT',
		}),
		async initializeDonor() {
			this.form = Object.assign({}, this.defaultData);
			this.form.started_at = moment().utc().format();
			this.form.end_at = moment().utc().format();
			this.imageChanged = false;
			this.iconChanged = false;
			this.sponsorImageChanged = false;


			await this.getGames();
			await this.getPlatforms();
			await this.getRules();
			await this.getRegions();

			await this.getTournament(this.$route.query.donor_id);
			await this.getTemplates({ game_id: this.tournament.getGameId() });

			await this.prepareFieldsAfterLoad();

			this.form.started_at = moment().utc().format();
			this.form.end_at = moment().utc().format();
		},
		addNewPrize() {
			if (this.form.prize_pool && this.form.prize_pool.length >= this.form.max_member) {
				return;
			}
			const last = this.form.prize_pool[this.form.prize_pool.length - 1] ? this.form.prize_pool[this.form.prize_pool.length - 1].place + 1 : 1;
			this.form.prize_pool.push({
				place: last,
				prizes: {
					EUR: null,
					COINS: null
				}
			});
		},
		async getFormData() {
			this.form = Object.assign({}, this.defaultData);
			this.form.started_at = moment().add(30, 'minutes').utc().format();
			this.form.end_at = moment().add(24, 'hours').utc().format();
			this.imageChanged = false;
			this.iconChanged = false;
			this.sponsorImageChanged = false;

			await this.getGames();
			await this.getPlatforms();
			await this.getRules();
			await this.getRegions();

			if (this.isEdit) {
				await this.getTournament(this.id);
				await this.getTemplates({ game_id: this.tournament.getGameId() });
			} else {
				await this.getTemplates({ game_id: this.form.game_id });
			}
			await this.prepareFieldsAfterLoad();
		},
		formFieldChanged(field, value) {
			this.form[field] = value;
			if (field === 'game_id') {
				this.selectedGameChanged();
			}
		},
		async onSearchChange(value) {
			this.multiSelectOptions.loading = true;
			await this.getCountries({
				search: value
			});
			this.multiSelectOptions.loading = false;
		},
		showConfirmationPopup() {
			this.isShowConfirmationPopup = true
		},
		save() {
			let empty = undefined;
			// TODO
			// this.form.prize_pool.forEach((item, index) => {
			//
			//     if(empty !== undefined && (!item.prizes.money || !item.prizes.coins)) {
			//         this.errorsHandler({prize_pool: 'You cannot add prizes after the empty field of previous places'})
			//         throw new Error('error');
			//     }
			//
			//     if(!item.prizes.money) {
			//         empty = index;
			//     }
			// })

			if (!this.id) {
				this.showConfirmationPopup();
				return;
			}

			this.id ? this.updatePost() : this.addPost();
		},
		addPost() {
			let data = helpers.copyObject(this.form);
			data = helpers.deleteEmptyObjectFields(data);
			const asArray = Array.from(data.prize_pool);
			data.prize_pool = asArray.filter(item => item.prizes.COINS !== "" && item.prizes.EUR !== "");
			const prizePools = []
			data.prize_pool.forEach((item) => {
				const wallets = Object.keys(item.prizes);
				const prizes = []
				wallets.forEach(i => {
					if (item.prizes[i]) {
						prizes.push({
							wallet: i,
							value: item.prizes[i]
						})
					}
				})
				if (prizes.length) {
					prizePools.push({
						place: item.place,
						prizes: prizes
					})
				}
			})
			data.prize_pool = prizePools
			data.prize_pool = data.prize_pool.map(item => {
				const formatted = { ...item }
				formatted.prizes = JSON.stringify(item.prizes);
				return formatted
			})
			data.started_at = helpers.prepareDateTimeToSave(data.started_at);
			data.end_at = helpers.prepareDateTimeToSave(data.end_at);

			if (!data.image) {
				delete data.image;
			}

			if (!data.icon) {
				delete data.icon;
			}

			if (!data.sponsor_image) {
				delete data.sponsor_image;
			}

			if (!data.team_mates) {
				delete data.team_mates;
			}

			if (!data.rounds || 1 === data.type) {
				delete data.rounds;
			}

			if (!data.template_id) {
				delete data.template_id;
			}

			if (this.locationType === 'country') {
				delete data.region_id;
				data.supported_countries = data.supported_countries.map(item => item.id.toString());
			} else {
				delete data.supported_countries;
			}

			if (data.match_autohandle) {
				data.match_autohandle = 1;
			} else {
				data.match_autohandle = 0;
			}

			if (data.auto_check_in) {
				data.auto_check_in = 1;
				data.check_in_time = 0;
			} else {
				data.auto_check_in = 0;
			}

			if (data.consider_in_leaderboard) {
				data.consider_in_leaderboard = 1;
			} else {
				data.consider_in_leaderboard = 0;
			}

			if (data.is_chat_enabled) {
				data.is_chat_enabled = 1;
			} else {
				data.is_chat_enabled = 0;
			}
			if (data.chat_image_enabled) {
				data.chat_image_enabled = 1;
			} else {
				data.chat_image_enabled = 0;
			}

			if (data.available_web) {
				data.available_web = 1;
			} else {
				data.available_web = 0;
			}


			if (data.notify_mzaalo) {
				data.notify_mzaalo = 1;
			} else {
				data.notify_mzaalo = 0;
			}

			if (data.require_user_profiling) {
				data.require_user_profiling = 1;
			}
			else {
				data.require_user_profiling = 0;
			}

			if (data.available_mobile) {
				data.available_mobile = 1;
			} else {
				data.available_mobile = 0;
			}
			if (data.custom_seeding) {
				data.custom_seeding = 1;
			} else {
				data.custom_seeding = 0
			}

			// if (data.turq_enabled) {
			//     data.turq_enabled = 1;
			// } else {
			//     data.turq_enabled = 0
			// }

			if (this.startStatus === 'registration') {
				delete data.register_timeout;
			} else {
				data.register_timeout = helpers.prepareDateTimeToSave(data.register_timeout);
				data.register_timeout = helpers.getTimeDiff(data.register_timeout, data.started_at);
				data.register_timeout = data.register_timeout * -1;

				if (data.register_timeout < 0) {
					data.register_timeout = 0
				}
			}

			this.addTournament(data)
				.then(() => router.push('/tournament/' + this.tournament.getId()))
				.catch(err => {
					if (err.response.status === 403) {
						this.$notify({
							type: 'error',
							title: 'Permission error',
							group: 'notify',
							text: err.response.data.message ? err.response.data.message : 'Unknown error'
						})
					} else {
						this.errorsHandler(err.response.data.errors);
					}
				})
		},
		updatePost() {
			let data = helpers.copyObject(this.form);
			const asArray = Array.from(data.prize_pool);
			data.prize_pool = asArray.filter(item => item.prizes.COINS !== "" && item.prizes.EUR !== "");
			const prizePools = []
			data.prize_pool.forEach((item) => {
				const wallets = Object.keys(item.prizes);
				const prizes = []
				wallets.forEach(i => {
					if (item.prizes[i]) {
						prizes.push({
							wallet: i,
							value: item.prizes[i]
						})
					}
				})
				if (prizes.length) {
					prizePools.push({
						place: item.place,
						prizes: prizes
					})
				}
			})
			data.prize_pool = prizePools
			data.prize_pool = data.prize_pool.map(item => {
				const formatted = { ...item }
				formatted.prizes = JSON.stringify(item.prizes);
				return formatted
			})
			data.started_at = helpers.prepareDateTimeToSave(data.started_at);
			data.end_at = helpers.prepareDateTimeToSave(data.end_at);
			data = helpers.deleteEmptyObjectFields(data);
			if (!this.imageChanged) {
				delete data.image;
			} else if (data.image === undefined) {
				data.image = ''
			}

			if (!this.iconChanged) {
				delete data.icon;
			} else if (data.icon === undefined) {
				data.icon = ''
			}

			if (!this.sponsorImageChanged) {
				delete data.sponsor_image;
			} else if (data.sponsor_image === undefined) {
				data.sponsor_image = ''
			}

			delete data.match_autohandle;
			delete data.consider_in_leaderboard;

			if (this.locationType === 'country') {
				delete data.region_id;
				data.supported_countries = data.supported_countries.map(item => item.id.toString());
			} else {
				delete data.supported_countries;
			}

			if (data.available_web) {
				data.available_web = 1;
			} else {
				data.available_web = 0;
			}


			if (data.notify_mzaalo) {
				data.notify_mzaalo = 1;
			} else {
				data.notify_mzaalo = 0;
			}

			if (data.require_user_profiling) {
				data.require_user_profiling = 1;
			}
			else {
				data.require_user_profiling = 0;
			}

			if (data.auto_check_in) {
				data.auto_check_in = 1;
				data.check_in_time = 0;
			} else {
				data.auto_check_in = 0;
			}


			if (data.available_mobile) {
				data.available_mobile = 1;
			} else {
				data.available_mobile = 0;
			}
			if (data.is_chat_enabled) {
				data.is_chat_enabled = 1;
			} else {
				data.is_chat_enabled = 0;
			}
			if (data.chat_image_enabled) {
				data.chat_image_enabled = 1;
			} else {
				data.chat_image_enabled = 0;
			}

			// if (data.turq_enabled) {
			//     data.turq_enabled = 1;
			// } else {
			//     data.turq_enabled = 0
			// }

			if (this.startStatus === 'registration') {
				delete data.register_timeout;
			} else {
				data.register_timeout = helpers.prepareDateTimeToSave(data.register_timeout);
				data.register_timeout = helpers.getTimeDiff(data.register_timeout, data.started_at);
				data.register_timeout = data.register_timeout * -1;

				if (data.register_timeout < 0) {
					data.register_timeout = 0
				}
			}
			this.updateTournament({
				id: this.id,
				data: data
			})
				.then(async () => {
					if (!data.is_chat_enabled) {
						await localStorage.removeItem('tournamentChat');
						await this.removeExpandedChat(this.tournament.getId())
						await this.removeOpenedChat(this.tournament.getId())
						await this.removeTournamentChat(this.tournament.getId())
					}
					await router.push('/tournament/' + this.id)
				})
				.catch(err => {
					if (err.response.status === 403) {
						this.$notify({
							type: 'error',
							title: 'Permission error',
							group: 'notify',
							text: err.response.data.message ? err.response.data.message : 'Unknown error'
						})
					} else {
						this.errorsHandler(err.response.data.errors);
					}
				});
		},
		errorsHandler(error) {
			this.errors = error ? error : this.defaultError;
		},
		onChangeImage(image) {
			this.form.image = image;
			this.imageChanged = true;
		},
		onRemoveImage() {
			this.form.image = null;
			this.imageChanged = true;
		},
		onChangeIcon(icon) {
			this.form.icon = icon;
			this.iconChanged = true;
		},
		onRemoveIcon() {
			this.form.icon = null;
			this.iconChanged = true;
		},
		onChangeSponsorImage(image) {
			this.form.sponsor_image = image;
			this.sponsorImageChanged = true;
		},
		onRemoveSponsorImage() {
			this.form.sponsor_image = null;
			this.sponsorImageChanged = true;
		},
		async selectedGameChanged() {
			await this.getTemplates({
				game_id: this.form.game_id
			});
			this.initTemplatesOptions();
			this.initPlatformsOptions();
		},
		prepareFieldsAfterLoad() {
			if (this.isEdit || this.isDonor) {
				this.form = helpers.extractTournamentFields(this.tournament);
				const prizePools = []
				this.form.prize_pool.forEach((item, key) => {
					const prizes = {}
					item.prizes.forEach((i) => {
						prizes[i.wallet] = i.value
					})
					prizePools.push({
						place: item.place,
						prizes: prizes
					})
				})
				this.form.prize_pool = prizePools

				if (this.form.register_timeout || this.form.register_timeout === 0) {
					this.form.register_timeout = moment(this.form.started_at).add(this.form.register_timeout * -1, 'minutes').utc().format()
					this.startStatus = 'opened';
				}
			}

			this.initCountriesAndRegionsOptions();
			this.initPlatformsOptions();
			this.initPlatformsOptions();
			this.initRulesOptions();
			this.initGamesOptions();
			this.initTemplatesOptions();
		},
		async initCountriesAndRegionsOptions() {

			if ((this.isEdit || this.isDonor) && this.form.supported_countries.length > 0) {
				await this.getCountries({
					identities: this.form.supported_countries.join(',')
				});

				this.form.supported_countries = this.countries
			}

			if (this.form.region_id) {
				this.locationType = 'region';
			} else {
				this.locationType = 'country';
			}

			this.regionsOptions = this.regions.map(item => {
				return { value: item.id, name: item.title }
			});

			if (this.form.region_id === null) {
				this.form.region_id = this.regionsOptions[0].value.toString()
			}
		},
		initGamesOptions() {
			const isEmpty = this.form.game_id === '';
			this.gamesOptions = this.games.map(item => {
				return { value: item.id, name: item.name }
			});

			this.form.game_id = isEmpty ? this.gamesOptions[0].value.toString() || '' : this.form.game_id.toString();
		},
		initTemplatesOptions() {
			this.templatesOptions = [{
				value: '',
				name: 'No template'
			}];

			const isEmpty = this.form.template_id === '';
			const templateExist = this.templates.filter(e => e.getId() === this.form.template_id).length > 0;

			this.templates.map(item => {
				this.templatesOptions.push({ value: item.getId(), name: item.getName() });
			});

			this.form.template_id = isEmpty || !templateExist ? this.templatesOptions[0].value || '' : this.form.template_id;
		},
		initRulesOptions() {
			const isEmpty = this.form.rule_id === '';
			this.rulesOptions = this.rules.map(item => {
				return { value: item.getId(), name: item.getTitle() }
			});

			this.form.rule_id = isEmpty ? this.rulesOptions[0].value || '' : this.form.rule_id;
		},
		initPlatformsOptions() {
			const game = this.games.filter(item => item.id === Number(this.form.game_id))[0];
			const allPlatforms = this.platforms.map(item => {
				return { value: item.getId(), name: item.getName() }
			});
			const gamePlatforms = allPlatforms.filter(item => game.platforms.includes(item.value));
			const gameIsGlobal = game.is_global;

			this.platformsOptions = gameIsGlobal ? allPlatforms : gamePlatforms;
			if (!this.isEdit) {
				this.form.platform_id = this.platformsOptions.length ? gamePlatforms[0].value : '';
			}
		},
		locationChanged(name, value) {
			this.locationType = value;
		},
		startStatusChanged(name, value) {
			this.startStatus = value;
		}
	}
}
</script>

<style scoped lang="scss">
.range {
	label {
		span {
			display: block;
			margin-bottom: 5px;
			font-size: 14px;
			line-height: 15px;
			color: #06C2BC;
		}
	}
}

.prize-pull__input {
	display: flex;
	align-items: center;
	position: relative;

	::v-deep .form-input-field {
		width: 100%;

		label {
			.form-label {
				display: none;
			}

			input {
				border: 1px solid #878787;
				border-radius: 4px;
				transition: 0.2s;

				&:focus,
				&:active {
					border: 1px solid #06C2BC;
					box-shadow: 0px 0px 4px rgba(0, 80, 77, 0.1), 0px 2px 10px rgba(0, 80, 77, 0.25);
				}
			}
		}
	}

	img {
		position: absolute;
		right: 10px;
	}
}


.button-gray {
	width: 100%;
	padding: 12px 0;
}

.custom__checkbox {
	display: flex;
	margin-top: 15px;
	align-items: center;

	[type="checkbox"]:not(:checked),
	[type="checkbox"]:checked {
		position: absolute;
		left: 0;
		opacity: 0.01;
	}

	[type="checkbox"]:not(:checked)+label,
	[type="checkbox"]:checked+label {
		position: relative;
		padding-left: 2.3em;
		font-size: 1.05em;
		line-height: 1.7;
		cursor: pointer;
	}

	/* checkbox aspect */
	[type="checkbox"]:not(:checked)+label:before,
	[type="checkbox"]:checked+label:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 1.4em;
		height: 1.4em;
		border: 1px solid #aaa;
		background: #FFF;
		border-radius: .2em;
		box-shadow: inset 0 1px 3px rgb(0, 0, 0, 0.10), 0 0 0 rgb(0, 231, 223);
		-webkit-transition: all .275s;
		transition: all .275s;
	}

	/* checked mark aspect */
	[type="checkbox"]:not(:checked)+label:after,
	[type="checkbox"]:checked+label:after {
		content: '✕';
		position: absolute;
		top: .525em;
		left: .18em;
		font-size: 1.375em;
		color: #00e7df;
		line-height: 0;
		-webkit-transition: all .2s;
		transition: all .2s;
	}

	/* checked mark aspect changes */
	[type="checkbox"]:not(:checked)+label:after {
		opacity: 0;
		-webkit-transform: scale(0) rotate(45deg);
		transform: scale(0) rotate(45deg);
	}
}
</style>
