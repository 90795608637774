import match from '../../api/match'
import systemEvents from '../../api/system'
import ChatService from '../../api/chat'
const state = () => ({
	chatTournament: localStorage.getItem('chatTournament') || '',
	activeChats: JSON.parse(localStorage.getItem('activeChats')) || [],
	newMessagesCount: JSON.parse(localStorage.getItem('newMessagesCount')) || [],
	chatsList: [],
    tournamentChat: JSON.parse(localStorage.getItem('tournamentChat')) || null,
	openedChats: JSON.parse(localStorage.getItem('openedChats')) || [],
	expandedChats: JSON.parse(localStorage.getItem('expandedChats')) || [],
    adminCalls: [],
    notificationMuted: localStorage.getItem('notification_muted') ? Number(localStorage.getItem('notification_muted')) : 0
});

const getters = {
    notificationMuted: state => Number(state.notificationMuted),
    adminCalls: state => state.adminCalls,
    isMatchInAdminCalls: state => (match_id) => {
      return state.adminCalls.find(item => Number(item.match_id) === Number(match_id))
    },
	chatTournament: state => state.chatTournament,
	chatsList: state => state.chatsList,
	getActiveChats: state => state.activeChats,
	isActiveChat: (state) => (match) => {
		return state.activeChats.includes(match);
	},
	expandedChats: (state) => {
        const chat = state.expandedChats

        if (state.tournamentChat) {
            state.expandedChats.push(state.tournamentChat)
        }

        return chat
    },
	isExpandedChat: (state) => (id) => {
		return state.expandedChats.includes(id);
	},
	openedChats: state => {
        const chat = state.openedChats

        if (state.tournamentChat) {
            if (!state.openedChats.find(item => item.id === state.tournamentChat.id)) {
                state.openedChats.push(state.tournamentChat)
            }
        }

        return chat
    },
	isOpenedChat: (state) => (id) => {
		return state.openedChats.filter(item => item.id === id);
	},
	getChat: state => (id) => {
		return state.openedChats.filter(item => item.id === id)[0];
	},
	getNewMessagesCount: state => (id) => {
		return state.newMessagesCount.filter(item, index => index === id)[0];
	},
};

const actions = {
    toggleMuted ({ commit }, currentValue) {
        if (Number(currentValue) === 1) {
            commit('SET_MUTED', 0);
        } else {
            commit('SET_MUTED', 1);
        }
    },
    GET_ADMIN_CALLS ({commit, state, rootGetters}, tournamentId) {
        return ChatService.getTournamentAdminCalls(tournamentId).then(async response => {
            const activeChats = rootGetters.getActiveChats;
            const chats = response.data

            if (Number(chats.length) > Number(state.adminCalls.length) && state.adminCalls.length > 0 && !state.notificationMuted) {
                try {
                    const audio = new Audio('/sounds/call_admin.mp3');
                    audio.volume = 0.2;
                    await audio.play();
                } catch(e) {
                    console.log(e)
                }
            }

            await commit('SET_ADMIN_CALLS', chats.map((item, index) => {
                if (activeChats.includes(item.match_id)) {
                    item.is_active = true;
                }
                return item;
            }));
        })
    },
	INIT_WEB_SOCKET: ({commit}) => {
		commit('INIT_WEB_SOCKET');
	},
	CHAT_TOURNAMENT_INIT: ({commit}, id) => {
		commit('TOURNAMENT_INIT', id);
	},
	CHAT_CHECK_ON_CALL: ({commit}, data) => {
		commit('CHECK_ON_CALL', data);
	},
	GET_CHATS_LIST: ({commit}, id) => {
		return new Promise((resolve, reject) => {
			match.getAll(id)
				.then((resp) => {
					commit('UPDATE_CHATS_LIST', resp.data.data);
					commit('UPDATE_CHATS_LIST_STATES');
					resolve(resp.data.data);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	COLLAPSE_CHAT: ({commit}, id) => {
		commit('REMOVE_EXPANDED_CHAT', id);
	},
	EXPAND_CHAT: ({commit}, id) => {
		commit('ADD_EXPANDED_CHAT', id);
		commit('REMOVE_ACTIVE_CHAT', id);
		commit('REMOVE_MESSAGE_COUNT', id);
		commit('UPDATE_CHATS_LIST_STATES');
	},
    OPEN_TOURNAMENT_CHAT: async ({ commit, rootState }, data) => {
        localStorage.setItem('tournamentChat', JSON.stringify(data));
        commit('ADD_TOURNAMENT_CHAT', data)
    },
	OPEN_CHAT: async ({commit, rootState}, data) => {
		const isMobile = rootState.main.isMobile;
		systemEvents.send({
			event: "admin_entered_event",
			match_id: data.match,
			tournament_id: Number(data.tournament)
		});
        commit('ADD_OPENED_CHATS', {
            match: data.match,
            isMobile: isMobile,
            payload: data.payload ? data.payload : null
        });
        commit('REMOVE_ACTIVE_CHAT', data.match);
        commit('REMOVE_MESSAGE_COUNT', data.match);
        commit('UPDATE_CHATS_LIST_STATES');
        commit('ADD_EXPANDED_CHAT', data.match);
	},
	CLOSE_CHAT: ({commit}, data) => {
		systemEvents.send({
			event: "admin_left_event",
			match_id: data.match,
			tournament_id: Number(data.tournament)
		});

		commit('REMOVE_EXPANDED_CHAT', data.match);
		commit('REMOVE_OPENED_CHAT', data.match);
	},
	NEW_MESSAGE: ({commit}, data) => {
		commit('ADD_NEW_MESSAGE', data);
		commit('UPDATE_CHATS_LIST_STATES');
	},
	RECEIVE_MESSAGE: async ({commit, state}, data) => {
        const messages = data ?? []
        const activeChats = [...state.activeChats];
        const openedChats =[...state.openedChats];
        const newMessagesCount = [...state.newMessagesCount];
        messages.forEach(data => {
            const message = data.message;
            const created = data.created_at;
            const nickname = data.nickname;
            const role = data.role;
            const eventType = data.event_type;
            const chatID = Number(data.match_id);

            if ('system_event' === eventType) return;

            const isActiveChat = activeChats.includes(chatID);

            const isExpandedChat = state.expandedChats.includes(chatID);
            const isOpenedChat = openedChats.filter(item => item.id === chatID).length;

            if (!isExpandedChat && '<#>call_admin' !== message) {
                const count = newMessagesCount[chatID] || 0;
                newMessagesCount[chatID] = count + 1;
            }

            if ('<#>call_admin' === message) {
                if (!isExpandedChat && !isActiveChat) {
                    activeChats.push(chatID);
                }
            }
            if (isOpenedChat) {
                openedChats.forEach((item, index) => {
                    if (item.id === chatID) {
                        openedChats[index].messages.push({
                            nickname: nickname,
                            created_at: created,
                            role: role,
                            message: message
                        });
                    }
                })
            }
        });


		commit('UPDATE_MESSAGES', { activeChats, openedChats, newMessagesCount });

        let chats = state.chatsList;
        chats.forEach((item, index) => {
            const count = state.newMessagesCount.filter((message, index) => index === item.id);

            chats[index].isActive = state.activeChats.includes(item.id);
            chats[index].messagesCount = count.length ? count[0] : 0;
        });

        if (Array.isArray(chats)) {
            chats.sort((x, y) => y.isActive - x.isActive)
        }

		commit('UPDATE_CHATS_DATA', chats);
	}
};

const mutations = {
    SET_MUTED (state, value) {
        state.notificationMuted = value;
        localStorage.setItem('notification_muted', value)
    },
    SET_ADMIN_CALLS (state, data) {
        state.adminCalls = data;
    },
    ADD_TOURNAMENT_CHAT (state, data) {
      if (state.openedChats.find(item => item.id === data.id)) return
      state.openedChats.push(data)
    },
    REMOVE_TOURNAMENT_CHAT (state, id) {
        if (state.openedChats.length === 1) {
            state.openedChats = []
            return;
        }
      const index = state.openedChats.findIndex(item => item.id === id)
      if (index === 0) {
          state.openedChats = []
      } else {
          state.openedChats.splice(index, 1)
      }
    },
	UPDATE_CHATS_LIST: (state, data) => {
		let items = [];
		data.forEach((item) => {
			if (!item.first_participant || !item.second_participant || item.match_status === 'FINISHED') return;

			items.push({
				id: item.id,
				isActive: false,
				messagesCount: 0
			});
		});

		state.chatsList = items;
	},
	UPDATE_CHATS_LIST_STATES: (state, data) => {
		let chats = state.chatsList;
		chats.forEach((item, index) => {
			chats[index].isActive = state.activeChats.includes(item.id);
		});

		if (Array.isArray(chats)) {
			chats.sort((x, y) => y.isActive - x.isActive)
		}

		state.chatsList = chats;
	},
	TOURNAMENT_INIT: (state, id) => {
		if (state.chatTournament !== id) {
			state.chatTournament = id;
			state.activeChats = [];
			state.openedChats = [];
			state.expandedChats = [];
			localStorage.setItem('chatTournament', id);
			localStorage.setItem('activeChats', JSON.stringify([]));
			localStorage.setItem('openedChats', JSON.stringify([]));
		}
	},
	CHECK_ON_CALL: (state, data) => {
		const message = data.message;
		const destination = data.destination;
		const config = destination.split('/').pop().split('.');
		const tournament = Number(config[2]);
		const match = Number(config[4]);
		const isValidMatch = Number(state.chatTournament) === tournament && 0 > state.activeChats.indexOf(match);

		if ('<#>call_admin' !== message) return;

		if (isValidMatch) {
			state.activeChats.push(match);
			localStorage.setItem('activeChats', JSON.stringify(state.activeChats));
		}
	},
	REMOVE_ACTIVE_CHAT: (state, match) => {
		const index = state.activeChats.indexOf(match);
		state.activeChats.splice(index, 1);
		localStorage.setItem('activeChats', JSON.stringify(state.activeChats));
	},
	REMOVE_MESSAGE_COUNT: (state, chatID) => {
        if (state.newMessagesCount[chatID]) {
            state.newMessagesCount[chatID] = 0;
        }

		localStorage.setItem('newMessagesCount', JSON.stringify(state.newMessagesCount));
	},
	ADD_EXPANDED_CHAT: (state, id) => {
		const chatExists = -1 < state.expandedChats.indexOf(id);

		if (!chatExists) {
			state.expandedChats.push(id);
			localStorage.setItem('expandedChats', JSON.stringify(state.expandedChats));
		}
	},
	REMOVE_EXPANDED_CHAT: (state, id) => {
		const index = state.expandedChats.indexOf(id);
		state.expandedChats.splice(index, 1);
		localStorage.setItem('expandedChats', JSON.stringify(state.expandedChats));
	},
	ADD_OPENED_CHATS: (state, data) => {
		if (state.openedChats.filter(item => Number(item.id) === Number(data.match)).length) return;

		const chatsCount = data.isMobile ? 1 : 5;

		if (state.openedChats.length >= chatsCount) {
			state.openedChats.shift();
		}
		state.openedChats.push({
			id: data.match,
            payload: data.payload ? data.payload : {}
		});

		localStorage.setItem('openedChats', JSON.stringify(state.openedChats));
	},
	REMOVE_OPENED_CHAT: (state, id) => {
		state.openedChats.filter((item, index) => {
			if (item.id === id) {
				state.openedChats.splice(index, 1);
			}
		});

		localStorage.setItem('openedChats', JSON.stringify(state.openedChats));
	},
	ADD_NEW_MESSAGE: (state, data) => {
		const body = data.body;

		if ('system_event' === body.event_type) return;

		const message = body.message;
		const destination = data.destination;
		const config = destination.split('/').pop().split('.');

		const tournamentID = Number(config[2]);
		const chatID = Number(config[4]);

		const isActiveChat = state.activeChats.includes(chatID);
		const isExpandedChat = state.expandedChats.includes(chatID);
		const isOpenedChat = state.openedChats.filter(item => item.id === chatID).length;
		const isValidChat = Number(state.chatTournament) === tournamentID;

		if (!isExpandedChat && '<#>call_admin' !== message) {
			const count = state.newMessagesCount[chatID] || 0;
			state.newMessagesCount[chatID] = count + 1;
			localStorage.setItem('newMessagesCount', JSON.stringify(state.newMessagesCount));
		}

		if ('<#>call_admin' === message) {
			if (isValidChat && !isExpandedChat && !isActiveChat) {
				state.activeChats.push(chatID);
				localStorage.setItem('activeChats', JSON.stringify(state.activeChats));
			}
		}

		if (isOpenedChat) {
			state.openedChats.forEach((item, index) => {
				if (item.id === chatID) {
					state.openedChats[index].messages.push(body);
				}
			})
		}
	},
	UPDATE_MESSAGES: async (state, { openedChats, activeChats, newMessagesCount }) => {
		state.activeChats = activeChats;
		state.openedChats = openedChats;
		state.newMessagesCount = newMessagesCount;
        localStorage.setItem('activeChats', JSON.stringify(activeChats));
		localStorage.setItem('newMessagesCount', JSON.stringify(newMessagesCount));
	},
	UPDATE_CHATS_DATA: (state, chats) => {
		state.chatsList = chats;
	}
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
