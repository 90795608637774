<template>
	<div>
		<div class="page__content-header">
			<PageLinks :links="[
					{
						url: '/tournament/' +  tournament.getId(),
						text: tournament.getTitle()
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/brackets',
						text: $t('Leaderboard')
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/participants',
						text: $t('Participants')
					}
				]"/>
			<div class="page-content__actions">
				<LeaderboardRoundsScheduleButton/>
				<LeaderboardStartButton/>
				<LeaderboardCloseButton/>
			</div>
		</div>
		<div class="page__content-body" :class="{progress: showProgressScreen()}">
			<Leaderboard v-if="!showProgressScreen()" :id="id"/>
			<LeaderboardGeneratingLoader v-if="showProgressScreen()"/>
			<LeaderboardRoundsScheduleDialog/>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'

	import Leaderboard from './Leaderboard'
	import LeaderboardGeneratingLoader from './LeaderboardGeneratingLoader'
	import LeaderboardStartButton from './LeaderboardStartButton'
	import LeaderboardCloseButton from './LeaderboardCloseButton'
	import LeaderboardRoundsScheduleButton from './LeaderboardRoundsScheduleButton'
	import LeaderboardRoundsScheduleDialog from './LeadeboardRoundsScheduleDialog'
	import PageLinks from '../../components/Page/Links'

	export default {
		name: "LeaderboardContainer",
		props: {
			id: String
		},
		components: {
			PageLinks,
			Leaderboard,
			LeaderboardGeneratingLoader,
			LeaderboardStartButton,
			LeaderboardCloseButton,
			LeaderboardRoundsScheduleDialog,
			LeaderboardRoundsScheduleButton
		},
		computed: {
			...mapGetters({
				tournament: 'tournament',
				tournamentStatus: 'tournamentStatus'
			}),
		},
		mounted() {
			this.init();
		},
		methods: {
			...mapActions({
				getTournament: 'GET_TOURNAMENT',
				centrifugeConnect: 'centrifugeConnect'
			}),
			async init() {
				await this.getTournament(this.id);
				await this.centrifugeConnect();
			},
			showProgressScreen() {
				return 'PENDING' === this.tournamentStatus;
			}
		}
	}
</script>
