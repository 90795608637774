<template>
    <div class="room_matches">
        <div class="rounds__list" v-if="tournament.getType() === 7">
            <div class="round__title" v-for="(match,i) in matches" :key="i">
                ROUND {{ match.round }}
                <template>
                    - BEST OF {{ room.games_in_round }}
                </template>
                <SvgIcon
                    width="16"
                    height="16"
                    :viewBox="'0 0 60 60'"
                    :iconType="'clock'"
                />
                {{ getDate(match.groups[0].matches[0].startedAt) }}

                <button @click="editRound = { room_id: room.id, duration: match.groups[0].matches[0].duration, round: match.round, started_at: match.groups[0].matches[0].startedAt }" class="button button-primary round__edit__started">
                    <SvgIcon
                        width="16"
                        height="16"
                        :viewBox="'0 0 60 60'"
                        :iconType="'clock'"
                    />
                </button>
            </div>
            <div class="round__title">
                Winners
            </div>
        </div>
        <div class="rounds" :key="keyUpdate" :class="{'middle': tournament.getType() === 7}">
            <div class="round" v-for="(match,i) in matches" :key="i">
                <div class="round__title" v-if="tournament.getType() !== 7">
                    ROUND {{ match.round }}
                    <template>
                        - BEST OF {{ room.games_in_round }}
                    </template>
                    <SvgIcon
                        width="16"
                        height="16"
                        :viewBox="'0 0 60 60'"
                        :iconType="'clock'"
                    />
                    {{ getDate(match.matches[0].startedAt) }}

                    <button @click="editRound = { room_id: room.id, duration: match.matches[0].duration, round: match.round, started_at: match.matches[0].startedAt }" class="button button-primary round__edit__started">
                        <SvgIcon
                            width="16"
                            height="16"
                            :viewBox="'0 0 60 60'"
                            :iconType="'clock'"
                        />
                    </button>
                </div>
                <div class="round__matches">
                    <template v-if="tournament.getType() === 7">
                        <div class="swiss__item" v-for="(m, j) in match.groups" :key="j">
                            <div class="swiss__item-title">{{ m.name }}</div>
                            <RoundRobinMatch v-for="(t, k) in m.matches" :match="t" :key="k"></RoundRobinMatch>
                        </div>
                    </template>

                    <RoundRobinMatch v-else v-for="(m, j) in match.matches" :match="m" :key="j"></RoundRobinMatch>
                </div>
            </div>



            <div class="round" style="align-self: flex-start;" v-if="matches.length">
                <div class="round__title" v-if="!tournament.getType() === 7">
                    Winners
                </div>
                <div class="round__matches">
                   <div class="participants__matches">
                       <div class="participant__head">
                           <div class="participant__head__item">
                               Score
                           </div>
                           <div class="participant__head__item">
                               Wins
                           </div>
                       </div>
                       <div class="participant__winner" :class="{'winner': participant.swiss_winner, 'lose': participant && participant.participant && participant.participant.status === 'defeated'}" v-for="(participant, k) in roomParticipants" :key="k">
                           <div class="participant">
                               <div class="avatar">
                                   <img :src="getAvatar(participant.participant)" alt="">
                               </div>
                               <span>{{ getName(participant.participant) }}</span>
                           </div>
                           <div class="score">
                               {{ participant.points }}
                           </div>
                           <div class="points">
                               <div class="points__body">
                                   {{ participant.participant.earned_points }}
                               </div>
                           </div>
                           <label :for="`participant-${participant.participant.id}`" class="checkbox">
                               <input :disabled="load" :checked="room.winners.includes(participant.id)" @change.prevent="changeWinnerInRooms($event, participant.id)" :id="`participant-${participant.participant.id}`" type="checkbox">
                           </label>
                       </div>
                   </div>
                </div>
            </div>

        </div>

        <EditRound @update="$emit('updateRoom'), editRound = null" :round="editRound" v-if="editRound" @close="editRound = null"></EditRound>
    </div>
</template>
<script>
import RoundRobinService from '../../../../api/round-robin'
import RoundRobinMatch from './RoundRobinMatch'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import { prepare as prepareMatch } from '../../../../prepares/round-robin/PrepareMatch'
import EditRoomModal from "../Modals/EditRoomModal";
import SvgIcon from "../../../SvgIcon";
import EditRound from "../Modals/EditRound";
import _ from 'lodash'
import moment from "moment";
export default {
    props: {
        room: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            editRound: null,
            keyUpdate: 0,
            processUpdate: false,
            load: false,
            matches: [],
        }
    },
    computed: {
        ...mapGetters({
            showLoader: 'showLoader',
            tournament: 'tournament'
        }),
        roomParticipants () {
            if (!this.room.participants || !this.room.participants.length) {
                return []
            }
            return this.room.participants.sort((a, b) => {
                return b.participant.earned_points - a.participant.earned_points
            })
        }
    },
    watch: {
      room_id () {
          this.getMatches()
      }
    },
    methods: {
        ...mapMutations(['SHOW_LOADER', 'DISABLED_LOADER', 'ENABLED_LOADER']),
        ...mapActions({
            getTournament: 'GET_TOURNAMENT'
        }),
        getDate (d) {
            return moment(d).format('DD/MM HH:mm')
        },
        getStartedRound (date) {
            const result = moment(date)

            return result.format('DD/MM HH:mm')
        },
        update: function () {
            if (this.processUpdate) {
                return false;
            }
            this.processUpdate = true
            this.getMatches();
            this.$emit('updateRoom');
            setTimeout(() => {
                this.processUpdate = false
            }, 2000)
        },
        async changeWinnerInRooms ($event, participantId) {
            if (this.load) return;
            this.load = true
            await RoundRobinService.roomChangeWinners(this.tournament.getId(), this.room.id, {
                participantId
            }).then(data => {
                if (data.data && data.data.winners) {
                    this.room.winners = Object.values(data.data.winners)
                } else {
                    this.$notify({
                        group: 'notify',
                        type: 'error',
                        text: data.response.data.message ? data.response.data.message : 'Error server',
                        title: "Error change"
                    })
                    $event.target.checked = false
                }
            })
            this.load = false
        },
        getAvatar (participant) {
            if (participant.type === 2) {
                return participant.team.logo ? participant.team.logo : '/images/logo.jpg'
            }

            return participant.profile.user.avatar ? participant.profile.user.avatar : '/images/logo.jpg';
        },
        getName (participant) {
            if (participant.type === 2) {
                return participant.team.name;
            }

            if (participant.profile.user.nickname) {
                return participant.profile.user.nickname;
            }
            if (participant.profile.user.name) {
                return participant.profile.user.name;
            }
            if (participant.profile.user.email) {
                return participant.profile.user.email;
            }

            return 'Unknown';
        },
        async getMatches () {
            await this.SHOW_LOADER(true)
            await this.DISABLED_LOADER()
            await RoundRobinService.getRoomMatches(this.tournament.getId(), this.room.id).then(response => {
                const matches = prepareMatch(response.data)
                if (this.tournament.getType() === 7) {
                    matches.forEach((item, index) => {
                        matches[index].groups = item.groups.reverse()
                    })
                }

                this.matches = matches
            })
            await this.ENABLED_LOADER()
            this.SHOW_LOADER(false)
        }
    },
    async mounted () {
        await this.getMatches()
        this.$root.$on('updateRoom', async () => {
            this.$emit('updateRoom');
            await this.getMatches();
            this.keyUpdate++;
            this.$forceUpdate()
        })
    },
    components: {
        EditRound,
        SvgIcon,
        RoundRobinMatch
    }
}
</script>
<style scoped lang="scss">
.room_matches {
    .rounds__list {
        display: flex;
        .round__title {
            background: #ECECEC;
            border-radius: 4px;
            padding: 10.5px 15px;
            font-weight: 700;
            font-size: 16px;
            min-width: 375px;
            display: flex;
            align-items: center;
            line-height: 21px;
            text-transform: uppercase;
            width: 300px;
            color: #303030;
            .round__edit__started {
                margin-left: auto;
                display: block;
            }
            ::v-deep {
                i {
                    margin: 0 5px;
                    display: flex;
                    align-items: center;
                }
            }
            i {
                display: flex;
                align-items: center;
            }
        }
        .round__title + .round__title {
            margin-left: 21px;
        }
    }
    .rounds {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 21px;
        &.middle {
            align-items: center;
            position: relative;
            display: flex;
            .round {
                width: 375px;
                min-width: 375px;
            }
        }
        .round {
            width: 375px;
            min-width: 375px;
            .round__title {
                background: #ECECEC;
                border-radius: 4px;
                padding: 10.5px 15px;
                font-weight: 700;
                font-size: 16px;
                width: 375px;
                min-width: 375px;
                line-height: 21px;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                color: #303030;
                ::v-deep {
                    i {
                        margin: 0 5px;
                        display: flex;
                        align-items: center;
                    }
                }
                i {
                    display: flex;
                    align-items: center;
                }
                .round__edit__started {
                    margin-left: auto;
                    display: block;
                }
            }
            .round__matches {
                margin-top: 15px;
                .participants__matches {
                    margin-top: 15px;
                    border: 1px solid #E5E5E5;
                }
                .swiss__item {
                    border-radius: 5px;
                    border: 2px solid #018786;
                    padding: 30px 10px;
                    position: relative;
                    .swiss__item-title {
                        left: calc(50% - 25px);
                        position: absolute;
                        color: #fff;
                        top: -12px;
                        background: #018786;
                        border-radius: 4px;
                        padding: 3px 12px;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
                .swiss__item + .swiss__item {
                    margin-top: 35px;
                }
                .participant__head {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    border-bottom: 1px solid #E5E5E5;
                    padding: 4px 0;
                    padding-right: 28px;
                    .participant__head__item {
                        width: 55px;
                        text-align: center;
                        font-size: 14px;
                        min-width: 55px;
                    }
                }
                .participant__winner {
                    display: flex;
                    &.winner {
                        background: rgba(0, 231, 217, 0.5);

                    }
                    &.lose {
                        background: #ffdcdc;
                    }
                    .score {
                        border-left:  1px solid #E5E5E5;
                        border-right:  1px solid #E5E5E5;
                        padding: 3px 2px;
                        min-width: 55px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        text-transform: capitalize;
                        color: #000000;
                    }

                    .participant {
                        display: flex;
                        overflow: hidden;
                        flex-grow: 1;
                        padding: 6px 0;
                        padding-left: 10px;
                        align-items: center;
                        .avatar {
                            width: 28px;
                            height: 28px;
                            min-width: 28px;
                            overflow: hidden;
                            border-radius: 100%;
                            img {
                                max-width: 100%;
                                border-radius: 100%;
                            }

                            margin-right: 10px;
                        }
                        span {
                            font-weight: 500;
                            font-size: 14px;
                            max-width: 70%;
                            overflow: hidden;
                            line-height: 16px;
                            text-transform: capitalize;
                            text-overflow: ellipsis;
                            color: #000000;
                        }
                    }
                    .points {
                        border-left:  1px solid #E5E5E5;
                        border-right:  1px solid #E5E5E5;
                        padding: 3px 2px;
                        min-width: 55px;

                        .points__body {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            background: #ECECEC;
                            border-radius: 4px;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 14px;
                            text-transform: capitalize;
                            color: #303030;
                        }
                    }
                    .checkbox {
                        width: 28px;
                        min-width: 28px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                            input {
                                cursor: pointer;
                            }
                        cursor: pointer;
                    }
                }
                .participant__winner + .participant__winner {
                    border-top: 1px solid #E5E5E5;
                }
            }
        }
    }
}
</style>
