<template>
    <div class="room__instance">
        <div class="room__instance-header">
            <div class="room__instance-header_name">
                {{ room.name }}
                <template v-if="room.games_in_round > 1">
                    - BEST OF {{ room.games_in_round }}
                </template>
            </div>

            <div class="room__instance-header_indicators">
                <span>{{ room.participants.length }}/{{ room.size }}</span>

                <div class="room__instance-header_started_at" @click="editRoom = room" v-if="room.started_at">
                    <div class="date__time">
                        {{ getDate }} | {{ getTime }}
                    </div>
                </div>

                <div @click="deleteRoom" class="close">
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.26383 6.82325L11.6681 3.46438C12.1106 3.06132 12.1106 2.38955 11.6681 1.98648L10.9191 1.24753C10.5106 0.810881 9.82979 0.810881 9.42128 1.24753L6.01702 4.6064L2.57872 1.24753C2.17021 0.810881 1.48936 0.810881 1.08085 1.24753L0.331915 1.98648C-0.110638 2.38955 -0.110638 3.06132 0.331915 3.46438L3.73617 6.82325L0.331915 10.2157C-0.110638 10.6188 -0.110638 11.2905 0.331915 11.6936L1.08085 12.4326C1.48936 12.8692 2.17021 12.8692 2.57872 12.4326L6.01702 9.07369L9.42128 12.4326C9.82979 12.8692 10.5106 12.8692 10.9191 12.4326L11.6681 11.6936C12.1106 11.2905 12.1106 10.6188 11.6681 10.2157L8.26383 6.82325Z" fill="#878787"/>
                    </svg>
                </div>
            </div>

        </div>

        <div class="room__instance-participants">
            <div class="room__instance-participant" v-for="participant in room.size" :key="participant">
                <template v-if="!room.participants[participant - 1]">
                    <div class="room__instance-participant_empty" @click="addToOpenParticipants(participant)" v-if="!openParticipants.includes(participant)">
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1C8 0.447715 7.55228 0 7 0H6C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6V7C0 7.55228 0.447715 8 1 8H5V12C5 12.5523 5.44772 13 6 13H7C7.55228 13 8 12.5523 8 12V8H12C12.5523 8 13 7.55228 13 7V6C13 5.44772 12.5523 5 12 5H8V1Z" fill="#878787"/>
                        </svg>
                        <span>add participant</span>
                    </div>
                    <ParticipantSelect
                        :ref="`participant-${participant}`"
                        @close="addParticipantToRoom($event, room.id, participant)"
                        v-if="openParticipants.includes(participant)"
                        :participants="filteredParticipants"></ParticipantSelect>
                </template>
                <div class="room__instance-participant_user" v-else>
                    <div class="user__info">
                        <div class="avatar">
                            <img :src="getAvatar(room.participants[participant - 1].participant)" alt="">
                        </div>
                        <span>{{ getNickname(room.participants[participant - 1].participant) }} - #{{ room.participants[participant - 1].id }}</span>
                    </div>
                    <div class="delete" @click="removeParticipantFromRoom(room.id, room.participants[participant - 1].participant.id)">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.88653 4.98582L9.7234 2.14894C10.0922 1.80851 10.0922 1.24113 9.7234 0.900709L9.09929 0.276596C8.75887 -0.0921986 8.19149 -0.0921986 7.85106 0.276596L5.01418 3.11348L2.14894 0.276596C1.80851 -0.0921986 1.24113 -0.0921986 0.900709 0.276596L0.276596 0.900709C-0.0921986 1.24113 -0.0921986 1.80851 0.276596 2.14894L3.11348 4.98582L0.276596 7.85106C-0.0921986 8.19149 -0.0921986 8.75887 0.276596 9.09929L0.900709 9.7234C1.24113 10.0922 1.80851 10.0922 2.14894 9.7234L5.01418 6.88653L7.85106 9.7234C8.19149 10.0922 8.75887 10.0922 9.09929 9.7234L9.7234 9.09929C10.0922 8.75887 10.0922 8.19149 9.7234 7.85106L6.88653 4.98582Z" fill="#878787"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <EditRoomModal @update="updateRoom" v-if="editRoom" :editRoom="editRoom" @close="editRoom = undefined"></EditRoomModal>
    </div>
</template>
<script>
import ParticipantSelect from './ParticipantSelect'
import RoundRobinService from '../../../../api/round-robin'
import {mapActions, mapGetters, mapMutations} from "vuex";
import moment from "moment";
import EditRoomModal from "../Modals/EditRoomModal";
export default {
    data () {
      return {
          openParticipants: [],
          editRoom: undefined
      }
    },
    props: {
        excludeIds: {
            default: new Set()
        },
        room: {
            type: Object,
            required: true
        }
    },
    methods: {
        ...mapActions({
           getTournament: 'GET_TOURNAMENT',
           getParticipants: 'GET_ROUND_ROBIN_PARTICIPANTS'
        }),
        ...mapMutations({
            removeParticipant: 'REMOVE_PARTICIPANTS_FROM_STATE'
        }),
        updateRoom (room) {
            this.$set(this, 'room', room)
            this.editRoom = undefined
        },
        deleteRoom () {
            RoundRobinService.deleteRoom(this.tournament.getId(), this.room.id).then(() => {
                this.getTournament(this.tournament.getId())
            });
        },
        getAvatar (participant) {
            if (participant.type === 2) {
                return participant.team.logo ? participant.team.logo : '/images/logo.jpg'
            }

            return participant.profile.user.avatar ? participant.profile.user.avatar : '/images/logo.jpg';
        },
        getNickname (participant) {
            if (participant.type === 2) {
                return participant.team.name;
            }

            if (participant.profile.user.nickname) {
                return participant.profile.user.nickname;
            }
            if (participant.profile.user.name) {
                return participant.profile.user.name;
            }
            if (participant.profile.user.email) {
                return participant.profile.user.email;
            }

            return 'Unknown';
        },
        async addParticipantToRoom (participant, roomId, index) {
            if (!participant.participant) {
                this.addToOpenParticipants(index)
                return;
            }
            await RoundRobinService.addParticipantToRoom(this.tournament.getId(), roomId, {
                participant_id: participant.participant.getId()
            }).then(response => {
                if (response.data && response.data.data) {
                    this.room = response.data.data
                    this.$emit('addExclude', participant.participant.getId())
                } else {
                    this.$notify({
                        type: 'error',
                        title: 'Start error',
                        group: 'notify',
                        text: 'Error'
                    })
                }
            }).catch(() => {
                this.$notify({
                    type: 'error',
                    title: 'Start error',
                    group: 'notify',
                    text: 'Error'
                })
            })
            this.addToOpenParticipants(index)
        },
        addToOpenParticipants (participant) {
            if (this.openParticipants.includes(participant)) {
                this.openParticipants.splice(this.openParticipants.indexOf(participant), 1);
            } else {
                this.openParticipants.push(participant)
                this.$nextTick(() => {
                    this.$refs[`participant-${participant}`][0].$el.focus()
                })
            }
        },
        removeParticipantFromRoom (roomId, participantId) {
            RoundRobinService.removeParticipantFromRoom(this.tournament.getId(), roomId, participantId).then(response => {
                if (response.data && response.data.data) {
                    this.room = response.data.data
                    this.$emit('removeFromExclude', participantId)
                } else {
                    this.$notify({
                        type: 'error',
                        title: 'Start error',
                        group: 'notify',
                        text: 'Error'
                    })
                }
            }).catch(() => {
                this.$notify({
                    type: 'error',
                    title: 'Start error',
                    group: 'notify',
                    text: 'Error'
                })
            })
        }
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament',
            participants: 'participants'
        }),
        getDate () {
            let date = new Date(this.room.started_at)
            return moment(this.room.started_at).format('DD/MM')
        },
        getTime () {
            let date = new Date(this.room.started_at)

            return `${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`
        },
        filteredParticipants () {
            return this.participants.filter(item => {
                return !this.room.participants.find(i => i.participant.id === item.getId()) && !this.excludeIds.has(item.getId())
            });
        }
    },
    components: {
        EditRoomModal,
        ParticipantSelect
    }
}
</script>
<style scoped lang="scss">
.room__instance {
    .room__instance-header {
        background: #ECECEC;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 15px;
        .room__instance-header_name {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            text-transform: uppercase;
            color: #303030;
        }
        .room__instance-header_started_at {
            padding: 6px;
            border-left: 1px solid #D6D6D6;
            cursor: pointer;
            border-right: 1px solid #D6D6D6;
            .date__time {
                padding: 8px 11px;
                background: #D6D6D6;
                border-radius: 4px;
            }
        }
        .room__instance-header_indicators {
            display: flex;
            align-items: center;
            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;
                color: #878787;
                margin-right: 15px;
            }
            .close {
                width: 42px;
                min-width: 42px;
                border-left: 1px solid #D6D6D6;
                display: flex;
                align-items: center;
                height: 42px;
                justify-content: center;
                cursor: pointer;
                transition: .2s;
                &:hover {
                    opacity: 0.6;
                }
            }
        }

    }
    .room__instance-participants {
        margin-top: 15px;
        border: 1px solid #E5E5E5;
        border-top: none;
        border-radius: 2px;
        .room__instance-participant {
            height: 40px;
            border-top: 1px solid #E5E5E5;
            .room__instance-participant_empty {
                display: flex;
                align-items: center;
                padding: 12px 14px;
                cursor: pointer;
                &:hover {
                    background: rgba(229,229,229, 0.4);
                }
                transition: 0.2s;
                span {
                    margin-left: 15px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 15px;
                    text-transform: capitalize;
                    color: #878787;
                }
            }
            .room__instance-participant_user {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 10px;
                .user__info {
                    display: flex;
                    flex-grow: 1;
                    align-items: center;
                    .avatar {
                        width: 28px;
                        height: 28px;
                        border-radius: 100%;
                        overflow: hidden;
                        margin-right: 10px;
                    }
                    span {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 15px;
                        text-transform: capitalize;
                        color: #000000;
                    }
                }
                .delete {
                    width: 30px;
                    min-width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: 0.2s;
                    border-left: 1px solid #E5E5E5;
                    height: 40px;
                    &:hover {
                        background: rgba(229,229,229, 0.4);
                    }
                }
            }
        }

    }
}
</style>
