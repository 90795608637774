var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page__content-header"},[_c('div',[_c('page-links',{attrs:{"links":[
					{
						url: '/notifications',
						text: _vm.$t('Notifications')
					}
				]}})],1),_c('div',[_c('RouterLink',{class:'button button-primary',attrs:{"to":"/notifications/add"}},[_vm._v(_vm._s(_vm.$t('Send Notification')))])],1)]),_c('div',[_c('div',{staticClass:"filters__wrapper"},[_c('div',[_c('label',{staticClass:"filter__label",attrs:{"for":""}},[_vm._v(" Filters: ")]),_c('div',{staticClass:"tournament__filters"},[(_vm.games && _vm.games.length)?_c('FormMultiSelect',{attrs:{"placeholder":_vm.$t('Game'),"options":_vm.games,"searchable":true,"multiple":false,"label":"name","selectLabel":''},on:{"input":_vm.changeFilters},model:{value:(_vm.filters.games),callback:function ($$v) {_vm.$set(_vm.filters, "games", $$v)},expression:"filters.games"}}):_vm._e()],1)]),_c('div',[_c('label',{staticClass:"filter__label",attrs:{"for":""}},[_vm._v(" Sort by: ")]),_c('div',{staticClass:"tournament__filters"},[(_vm.games && _vm.games.length)?_c('FormMultiSelect',{attrs:{"placeholder":_vm.$t('Order by'),"options":[
                                {
                                    orderBy: 'id',
                                    dir: 'DESC',
                                    name: 'Latest first'
                                },
                                {
                                    orderBy: 'id',
                                    dir: 'ASC',
                                    name: 'Old first'
                                },
                            ],"searchable":false,"label":"name","selectLabel":''},on:{"input":_vm.changeFilters},model:{value:(_vm.filters.sort),callback:function ($$v) {_vm.$set(_vm.filters, "sort", $$v)},expression:"filters.sort"}}):_vm._e()],1)])]),(_vm.notifications)?_c('table',[_c('thead',{staticClass:"text-left"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Notification ID')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Title')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Message')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Tournament ID')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Actions')))])])]),_c('tbody',_vm._l((_vm.notifications),function(notification){return _c('tr',[_c('td',{attrs:{"scope":"row"}},[_vm._v(_vm._s(notification.getId()))]),_c('td',[_vm._v(_vm._s(notification.getTitle()))]),_c('td',[_vm._v(_vm._s(notification.getMessage()))]),_c('td',{attrs:{"scope":"row"}},[_vm._v(_vm._s(notification.getTournamentId()))]),_c('td',{attrs:{"scope":"row"}},[_c('dropdown',{attrs:{"data-position":'left',"data-horizontal-aligntment":'left',"data-vertical-aligntment":'top',"toggle-class":'dots-toggle'}},[_c('svg-icon',{attrs:{"slot":"dropdown-toggle","width":"23","height":"5","view-box":"0 0 23 5","iconType":'dots'},slot:"dropdown-toggle"}),_c('div',{attrs:{"slot":"dropdown-content"},slot:"dropdown-content"},[_c('RouterLink',{class:'dropdown__link',attrs:{"to":{ path: '/notifications/add/?donor_id=' +  notification.getId() }}},[_c('svg-icon',{attrs:{"width":"16","height":"16","iconType":'plus'}}),_c('span',[_vm._v(_vm._s(_vm.$t('Clone')))])],1)],1)],1)],1)])}),0)]):_vm._e(),(_vm.notificationsMeta)?_c('page-pagination',{attrs:{"current":_vm.notificationsMeta.currentPage(),"total":_vm.notificationsMeta.lastPage()},on:{"change":_vm.changePage}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }