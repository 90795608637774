<template>
	<div class="modal delete-participant-dialog" v-show="showParticipantDeleteDialog">
		<div class="modal-wrapper">
			<div class="modal-overlay"></div>
			<div class="modal-container">
				<div class="modal-header">
					<div class="modal-header__close" @click="close">
						<SvgIcon
							width="16"
							height="16"
							:iconType="'slim'"
						/>
					</div>
				</div>
				<div class="modal-body">
					<h4>{{$t('Do you want to delete this user from participants list?')}}</h4>
					<div class="button-group">
						<button
							class="button button-primary"
							@click.prevent="submit"
						>
							{{$t('Submit')}}
						</button>
						<button
							class="button button-primary"
							@click.prevent="close"
						>
							{{$t('Cancel')}}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'
	import {DELETE_PARTICIPANT_DIALOG} from '../../constants/dialogs'

	import SvgIcon from '../SvgIcon'

	export default {
		name: "DeleteParticipantDialog",
		components: {
			SvgIcon
		},
		computed: {
			...mapGetters([
				'tournament',
				'showParticipantDeleteDialog',
				'deletedParticipant'
			])
		},
		methods: {
			...mapActions({
				deleteParticipant: 'DELETE_PARTICIPANT',
				closeDialog: 'closeDialog',
			}),
			submit() {
				const userId = this.deletedParticipant.isType('team') ? this.deletedParticipant.getCreatorId() : this.deletedParticipant.getUserId();

				this.close();
				this.deleteParticipant({tournament: this.tournament.getId(), user: userId});
			},
			close() {
				this.closeDialog({
					type: DELETE_PARTICIPANT_DIALOG,
					participant: []
				})
			}
		}
	};
</script>
