<template>
    <div class="create__rooms">
        <strong v-if="tournament.getType() === 4">Create rooms for round robin</strong>
        <strong v-if="tournament.getType() === 7">Create rooms for Swiss-system</strong>
        <button class="create__room" @click="isShowCreateRoomsModal = true">Create number of rooms</button>

        <CreateRoomModal v-if="isShowCreateRoomsModal" @close="isShowCreateRoomsModal = false"></CreateRoomModal>
    </div>
</template>
<script>
import CreateRoomModal from './Modals/CreateRoomModal'
import { mapGetters } from 'vuex'
export default {
    data () {
        return {
            isShowCreateRoomsModal: false
        }
    },
    computed: {
      ...mapGetters({
          tournament: 'tournament'
      })
    },
    components: {
        CreateRoomModal
    }
}
</script>
<style scoped lang="scss">
    .create__rooms {
        border: 1px dashed #C4C4C4;
        padding: 50px 0;
        border-radius: 4px;
        margin: 20px 0;
        strong {
            width: 100%;
            display: block;
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
            text-transform: uppercase;
            text-align: center;
            color: #303030;
        }
        .create__room {
            margin: 0 auto;
            margin-top: 25px;
            display: block;
            background: #00E7DF;
            border-radius: 3px;
            padding: 13px 38px;
            font-weight: bold;
            letter-spacing: 0.16px;
            text-transform: uppercase;
            transition: 0.2s;
            font-family: 'Roboto';
            cursor: pointer;
            &:hover {
                opacity: 0.7;
            }
            outline: none;
            border: none;
            color: #303030;
        }
    }
</style>
