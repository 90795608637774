<template>
	<div
		class="leaderboard__start-tournament-button"
		v-if="show()"
	>
		<button
			class="button button-primary"
			@click.prevent="start"
		>{{$t('Start tournament')}}
		</button>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'

	export default {
		name: "LeaderboardStartButton",
		computed: {
			...mapGetters({
				tournament: 'tournament',
				tournamentStatus: 'tournamentStatus'
			})
		},
		methods: {
			...mapActions({
				startTournament: 'startTftTournament'
			}),
			start() {
				this.startTournament(this.tournament.getId()).catch(e => {
                    this.$notify({
                        type: 'error',
                        title: 'Start error',
                        group: 'notify',
                        text: e
                    })
                });
			},
			show() {
				const status = this.tournamentStatus;
				return ['REGISTRATION_OPENED', 'REGISTRATION_CLOSED', 'CHECKIN'].includes(status.toUpperCase());
			}
		}
	};
</script>
