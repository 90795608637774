var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tournament)?_c('div',[_c('PageLinks',{attrs:{"links":[
					{
						url: '/tournament/round-robin/' +  _vm.tournament.getId() + '/overview',
						text: _vm.tournament.getTitle()
					},
					{
						url: '/tournament/round-robin/' +  _vm.tournament.getId() + '/game',
						text: 'Game panel'
					},
					{
						url: '/tournament/round-robin/' +  _vm.tournament.getId() + '/participants',
						text: _vm.$t('Participants')
					}
				]}}),_c('router-view',{attrs:{"id":_vm.id}}),_c('ChatsListContainer',{attrs:{"id":_vm.id}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }