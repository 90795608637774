var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.country)?_c('PageLinks',{attrs:{"links":[
			{
				url: '/countries/edit/' +  _vm.country.id,
				text: _vm.country.value,
				classes: 'active'
			}
		]}}):_vm._e(),_c('form',{staticClass:"form form--xl"},[_c('div',{staticClass:"form-group"},[_c('FormInput',{attrs:{"name":'value',"label":_vm.$t('Name'),"placeholder":_vm.$t('Name'),"value":_vm.form.value,"formErrors":_vm.errors.value,"onChange":_vm.formFieldChanged}})],1),_c('div',{staticClass:"form-group"},[_c('FormInput',{attrs:{"name":'supported_locales',"label":_vm.$t('Supported locales'),"placeholder":_vm.$t('Locales'),"value":_vm.form.supported_locales,"formErrors":_vm.errors.supported_locales,"onChange":_vm.formFieldChanged}})],1),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Supported wallets")]),_c('multiselect',{attrs:{"placeholder":"Select support wallets","searchable":false,"id":"wallets","options":[
                    'EUR',
                    'COINS'
                ],"multiple":true},model:{value:(_vm.form.available_wallets),callback:function ($$v) {_vm.$set(_vm.form, "available_wallets", $$v)},expression:"form.available_wallets"}}),_c('p',{staticClass:"form-field-description",staticStyle:{"color":"red"}},[_vm._v("Note: This affects the winnings of tournaments, if the user belongs to this country, he will be awarded a prize depending on whether the currency is maintained in the country")])],1),_c('FormNotification',{attrs:{"errors":_vm.errors.message}}),_c('button',{staticClass:"button button-primary form-button-submit",on:{"click":function($event){$event.preventDefault();return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }