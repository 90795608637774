<template>
	<div
		class="page__loader"
		v-if="showLoader"
	>
        <QlashLoader></QlashLoader>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex'
    import QlashLoader from '../QlashLoader'
	export default {
		name: "page-loader",
		computed: {
			...mapGetters([
				'showLoader'
			]),
		},
        components: {
            QlashLoader
        }
	};
</script>
