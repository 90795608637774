<template>
	<div
		class="brackets__status"
		:class="status | lowercase"
	>
    <span>MID #{{id}}</span>
	</div>
</template>

<script>
  export default {
		name: "BracketItemStatus",
		props: {
			status: String,
			id: Number
		}
	};
</script>
