<template>
	<div class="brackets__item" >
		<div :class="nodeClasses">
            <template v-if="data.is_tft">
                <TftUiBracketItem :data="data"></TftUiBracketItem>
            </template>
			<template v-else>
                <BracketItem :data="data" :final="!data.next_match_id"/>
                <BracketItem class="third_match" :third="true" v-if="!data.next_match_id && thirdMatch" :data="thirdMatch"/>
            </template>
		</div>
		<div v-if="data.games[0] || data.games[1]" class="brackets__item-children">
			<div class="brackets__item-child" v-if="data.games[0]">
				<BracketNode :data="data.games[0]"/>
			</div>
			<div class="brackets__item-child" v-if="data.games[1]">
				<BracketNode :data="data.games[1]"/>
			</div>
		</div>
	</div>
</template>

<script>
	import BracketItem from './BracketItem'
    import { mapGetters } from 'vuex'
    import TftUiBracketItem from '../../views/Public/TftUiBracketItem'
	export default {
		name: "BracketNode",
		components: {
            TftUiBracketItem,
			BracketItem
		},
		props: {
            type: {
                type: String,
                required: false,
            },
			data: Object
		},
        computed: {
            ...mapGetters({
                tournament: 'tournament',
                thirdMatch: 'thirdMatch'
            }),
            nodeClasses() {
                let classes = [];
                let node = this.data;
                if (!node.next_match_id) {
                    classes.push('with-third-match')
                }
                const isParent = node.games[0] || node.games[1];
                const hasParent = node.hasParent;

                if (isParent) {
                    if (this.data.is_tft) {
                        classes.push('brackets__item-parent brackets__item-parent-tft');
                    } else {
                        classes.push('brackets__item-parent');
                    }

                    return classes.join(' ');
                }
                if (hasParent) classes.push('brackets__item-child');
                return classes.join(' ');
            }
        }
	};
</script>
<style scoped lang="scss">
.with-third-match {
    position: relative;
    .third_match {
        position: absolute;
        top: calc(50% + 100px);
        right: 0;
    }
}
</style>
