<template>
	<div>
		<PageLinks v-bind:links="[
			{
				url: '/bracket-templates/add',
				text: $t('Create template'),
				classes: 'active'
			}
		]"/>
		<form class="form form-edit-templates">
			<div class="form-group">
				<FormInput
					:name="'title'"
					:label="$t('Title')"
					:placeholder="$t('Title')"
					:value="form.title"
					:formErrors="errors.title"
					:maxlength="50"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group" v-if="gamesOptions">
				<FormSelect
					:name="'game_id'"
					:label="$t('Game')"
					:value="form.game_id"
					:options="gamesOptions"
					:formErrors="errors.game_id"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group form-group-2-cols" v-for="(round, index) in form.info">
				<span class="form-group__title">
					{{rounds.names[index]}}
				</span>
				<div class="form-group__item">
					<FormNumber
						:name="'duration-' + index"
						:label="$t('Checkin time')"
						:value="form.info[index].duration"
						:onChange="formFieldChanged"
						:min="5"
					/>
				</div>
				<div class="form-group__item">
					<FormNumber
						:name="'matches-' + index"
						:label="$t('Matches in round')"
						:value="form.info[index].matches"
						:onChange="formFieldChanged"
						:min="1"
					/>
				</div>
				<span
					class="form-delete-item"
					:class="{disabled: rounds.min >= form.info.length }"
					@click="removeRound(index)"
				>
					<SvgIcon
						width="24"
						height="24"
						:iconType="'delete'"
						iconColor="#878787"
					/>
				</span>
				<FormNotification :errors="errors.rounds"/>
			</div>
			<button
				class="button button-primary form-add-item"
				:class="{disabled: rounds.max <= form.info.length }"
				@click.prevent="addRound"
			>
				{{$t('Add round')}}
			</button>
			<p class="form-field-description">
				{{$t("Note: if your tournament will have less rounds than described in the template, the bracket will be generated with the latest rounds. If there more players than described in the template - additional rounds will be equal to the earliest round")}}
			</p>
			<button
				class="button button-primary form-button-submit"
				@click.prevent="save"
			>
				{{$t('Save')}}
			</button>
		</form>
	</div>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'
	import {ROUNDS_NAMES} from '../../constants/common'

	import SvgIcon from '../../components/SvgIcon'
	import PageLinks from '../../components/Page/Links'
	import FormInput from '../../components/Form/Input'
	import FormNumber from '../../components/Form/Number'
	import FormSelect from '../../components/Form/Select'
	import FormNotification from '../../components/Form/Notification'

	export default {
		props: ['id'],
		components: {
			SvgIcon,
			PageLinks,
			FormInput,
			FormSelect,
			FormNumber,
			FormNotification
		},
		computed: {
			...mapGetters({
				games: 'games',
			})
		},
		data() {
			return {
				form: {
					title: '',
					game_id: '',
					info: [
						{
							duration: 30,
							matches: 1
						}
					],
				},
				rounds: {
					names: ROUNDS_NAMES,
					min: 1,
					max: 8
				},
				durationMin: 30,
				gamesOptions: [],
				matchesMin: 1,
				errors: {},
			};
		},
		mounted() {
			this.init();
		},
		methods: {
			...mapActions({
				getGames: 'getGames',
				addTemplate: 'addTemplate',
				getTemplate: 'getTemplate',
				updateTemplate: 'updateTemplate',
			}),
			async init() {
				await this.getGames();

				if (this.id) {
					await this.getTemplate(this.id)
						.then((data) => {
							this.form = {
								title: data.title,
								game_id: data.game_id,
								info: data.info,
							}
						});
				}

				this.prepareFieldsAfterLoad();
			},
			formFieldChanged(field, value) {
				if (field.includes('duration') || field.includes('matches')) {
					const fields = field.split('-');
					this.form.info[fields[1]][fields[0]] = value;
				} else {
					this.form[field] = value;
				}
			},
			prepareFieldsAfterLoad() {
				this.initGamesOptions();
			},
			initGamesOptions() {
				const isEmpty = this.form.game_id === '';
				this.gamesOptions = this.games.map(item => {
					return {value: item.id, name: item.name}
				});

				this.form.game_id = isEmpty ? this.gamesOptions[0].value.toString() || '' : this.form.game_id.toString();
			},
			addRound() {
				this.form.info.push({
					duration: this.durationMin,
					matches: this.matchesMin,
				})
			},
			removeRound(index) {
				this.form.info.splice(index, 1);
			},
			save() {
				if (!this.validate()) return;

				if (this.id) {
					this.updateTemplate({
						id: this.id,
						data: this.form
					});
				} else {
					this.addTemplate(this.form);
				}
			},
			validate() {
				const form = this.form;
				const titleValid = '' !== form.title;
				const gameIdValid = '' !== form.game_id;
				const errors = {};

				if (!titleValid) {
					errors.title = [this.$t('Title field should not be empty.')]
				}

				if (!gameIdValid) {
					errors.game_id = [this.$t('Game field should not be empty.')]
				}

				if (Object.keys(errors).length) {
					this.errors = errors;
				}

				return titleValid && gameIdValid;
			},
		}
	}
</script>
