import Vue from 'vue'

import VueRouter from 'vue-router'
import http from './utils/http'
import AppMain from './views/AppMain'
import routesListener from './listeners/routes'
import Filters from './utils/filters'
import { ObserveVisibility } from 'vue-observe-visibility'
import {API_URL} from './constants/common'
import VueExpandableImage from 'vue-expandable-image'
import Clipboard from 'v-clipboard'
import i18n from './i18n'
import store from './store'
import {mapMutations} from 'vuex'
import router from './routes'
import 'hooper/dist/hooper.css';

import '/public/app.css';

import VueWorker from 'vue-worker'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Notifications from 'vue-notification'
import UserMixin from "./mixins/UserMixin";
// window.EventBus = new Vue()
window.Vue = require('vue');
Vue.directive('observe-visibility', ObserveVisibility)
Vue.mixin(UserMixin)
Vue.use(VueRouter);
Vue.use(Notifications)
Vue.use(VueExpandableImage);
Vue.use(VueWorker)
Vue.use(Clipboard);

const vueInstance = new Vue({
	store: store,
    router,
    i18n,
	beforeDestroy() {
		if (typeof window === 'undefined') return;
		window.removeEventListener('resize', this.onResize, {passive: true})
	},
	mounted() {
		this.onResize();
		window.addEventListener('resize', this.onResize, {passive: true})
	},
	methods: {
		...mapMutations({
			setIsMobile: 'SET_IS_MOBILE',
		}),
        async loaderOff () {
          await this.$store.commit('DISABLED_LOADER')
        },
        async loaderOn () {
            await this.$store.commit('ENABLED_LOADER')
        },
		onResize() {
			this.setIsMobile(window.innerWidth < 992)
		},
	},
	created() {
		http.setRequestData(API_URL, this.$store.getters.getToken);
	},
	render: h => h(AppMain)
}).$mount('#app');

export { vueInstance }

Vue.config.productionTip = false
