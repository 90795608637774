import http from '../utils/http'
import joinUrls from '../utils/join-urls'

export default {
	create(id, restore = false) {
		if (!id) {
			return Promise.reject(new Error('Tournament id is required to create brackets data'));
		}

		return http.post(joinUrls('/tournaments', id, 'brackets') + `?restore=${restore}`);
	}
}
