<template>
    <div class="modal">
        <div class="modal-wrapper">
            <div class="modal-overlay"></div>
            <div class="modal-container">
                <div class="modal-header">
                    <div class="modal-header__title">
                        <span>{{$t('Select Winner')}}</span>
                    </div>
                    <div class="modal-header__close" @click="$emit('close')">
                        <SvgIcon
                            width="16"
                            height="16"
                            :iconType="'slim'"
                        />
                    </div>
                </div>
                <div class="choose-winner" v-if="results !== null">
<!--                    <div class="choose-winner__content">-->
<!--                        <div class="choose-winner__participant" v-if="match.first_participant">-->
<!--                            <input-->
<!--                                class="choose-winner__radio"-->
<!--                                type="radio"-->
<!--                                :id="`participant-${rand}-1`"-->
<!--                                :value="match.first_participant.id"-->
<!--                                v-model="winner"-->
<!--                            />-->
<!--                            <label-->
<!--                                class="choose-winner__label"-->
<!--                                :for="`participant-${rand}-1`"-->
<!--                            >-->
<!--							<span class="choose-winner__info">-->
<!--								<span class="avatar">-->
<!--									<img :src="getAvatar(match.first_participant.participant)" alt="First participant image">-->
<!--								</span>-->
<!--								<span class="name">Name: {{ getName(match.first_participant.participant) }}</span>-->
<!--								<span class="nickname">Nickname: {{ getNickname(match.first_participant.participant) }}</span>-->
<!--								<span class="nickname" v-if="results && results.first && results.first.type === 'single'">Game id: {{ results.first.platformName }}</span>-->
<!--								<span class="team-mates" v-if="results && results.first.type === 'team'">-->
<!--									<span v-for="teamMate in results.first.team_mates" v-if="teamMate.status === 1">-->
<!--										{{ getNickname(teamMate)}}: {{teamMate.profile.info.id}}-->
<!--									</span>-->
<!--								</span>-->
<!--							</span>-->
<!--                                <span class="choose-winner__images" v-if="results && results.first.images.length">-->
<!--                                    <span-->
<!--                                        class="choose-winner__images-item"-->
<!--                                        v-if="imageUrl"-->
<!--                                        v-for="imageUrl in results.first.images"-->
<!--                                    >-->
<!--                                        <expandable-image :src="imageUrl"/>-->
<!--                                    </span>-->
<!--							    </span>-->
<!--                            </label>-->
<!--                        </div>-->
<!--                        <div class="choose-winner__participant" v-if="match.second_participant">-->
<!--                            <input-->
<!--                                class="choose-winner__radio"-->
<!--                                type="radio"-->
<!--                                :id="`participant-${rand}-2`"-->
<!--                                :value="match.second_participant.id"-->
<!--                                v-model="winner"-->
<!--                            />-->
<!--                            <label-->
<!--                                class="choose-winner__label"-->
<!--                                :for="`participant-${rand}-2`"-->
<!--                            >-->
<!--							<span class="choose-winner__info">-->
<!--								<span class="avatar">-->
<!--									<img :src="getAvatar(match.second_participant.participant)" alt="Secobd participant image">-->
<!--								</span>-->
<!--								<span class="name">Name: {{ getName(match.second_participant.participant) }}</span>-->
<!--								<span class="nickname">Nickname: {{ getNickname(match.second_participant.participant) }}</span>-->
<!--                                <span class="nickname" v-if="results && results.second && results.second.type === 'single'">Game id: {{ results.second.platformName }}</span>-->
<!--								<span class="team-mates" v-if="results && results.second.type === 'team'">-->
<!--									<span v-for="teamMate in results.second.team_mates" v-if="teamMate.status === 1">-->
<!--										{{getNickname(teamMate)}}: {{teamMate.profile.info.id}}-->
<!--									</span>-->
<!--								</span>-->
<!--							</span>-->
<!--                                <span class="choose-winner__images" v-if="results && results.second.images.length">-->
<!--                                    <span-->
<!--                                        class="choose-winner__images-item"-->
<!--                                        v-if="imageUrl"-->
<!--                                        v-for="imageUrl in results.second.images"-->
<!--                                    >-->
<!--                                        <expandable-image :src="imageUrl"/>-->
<!--                                    </span>-->
<!--							    </span>-->
<!--                            </label>-->
<!--                        </div>-->
<!--                    </div>-->

                    <div class="choose-wrapper">
                        <div class="choose-wrapper__participant" v-if="match.first_participant" :class="{'select': winner === match.first_participant.id}">
                            <input :value="match.first_participant.id" v-model="winner" type="radio" name="winner" id="p1">
                            <label for="p1" class="choose-wrapper__participant-player">
                                <div class="choose-wrapper__participant-player_avatar">
                                    <img :src="getAvatar(match.first_participant.participant)" alt="">
                                </div>
                                <div class="choose-wrapper__participant-player_info">
                                    <strong>{{ getName(match.first_participant.participant) }}</strong>
                                    <span v-if="results && results.second && results.second.type === 'single'">GAME ID: {{ results.first.platformName }}</span>
                                </div>
                                <div class="choose-wrapper__participant-player_checkbox"></div>
                            </label>
                            <div class="choose-wrapper__participant-teams">
                                <div v-for="teamMate in results.first.team_mates" v-if="teamMate.status === 1" class="choose-wrapper__participant-player">
                                    <div class="choose-wrapper__participant-player_avatar">
                                        <img :src="teamMate.avatar" alt="">
                                    </div>
                                    <div class="choose-wrapper__participant-player_info">
                                        <strong>{{ getNickname(teamMate) }}</strong>
                                        <span>GAME ID: {{teamMate.profile.info.id}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="choose-wrapper__images" v-if="results.first.images.length">
                                    <span
                                        class="choose-winner__images-item"
                                        v-if="imageUrl"
                                        v-for="imageUrl in results.first.images"
                                    >
                                        <expandable-image :src="imageUrl"/>
                                    </span>
                            </div>
                        </div>
                        <div class="choose-wrapper__participant" v-if="match.second_participant" :class="{'select': winner === match.second_participant.id}">
                            <input :value="match.second_participant.id" v-model="winner" type="radio" name="winner" id="p2">
                            <label for="p2" class="choose-wrapper__participant-player">
                                <div class="choose-wrapper__participant-player_avatar">
                                    <img :src="getAvatar(match.second_participant.participant)" alt="">
                                </div>
                                <div class="choose-wrapper__participant-player_info">
                                    <strong>{{ getName(match.second_participant.participant) }}</strong>
                                    <span v-if="results && results.second && results.second.type === 'single'">GAME ID: {{ results.second.platformName }}</span>
                                </div>
                                <div class="choose-wrapper__participant-player_checkbox"></div>
                            </label>
                            <div class="choose-wrapper__participant-teams" v-if="results.second.type">
                                <div v-for="teamMate in results.second.team_mates" v-if="teamMate.status === 1" class="choose-wrapper__participant-player">
                                    <div class="choose-wrapper__participant-player_avatar">
                                        <img :src="teamMate.avatar" alt="">
                                    </div>
                                    <div class="choose-wrapper__participant-player_info">
                                        <strong>{{ teamMate.profile.user.nickname ? teamMate.profile.user.nickname : teamMate.profile.user.name }}</strong>
                                        <span>GAME ID: {{teamMate.profile.info.id}}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="choose-wrapper__images" v-if="results.second.images.length">
                                    <span
                                        class="choose-winner__images-item"
                                        v-if="imageUrl"
                                        v-for="imageUrl in results.second.images"
                                    >
                                        <expandable-image :src="imageUrl"/>
                                    </span>
                            </div>
                        </div>

                    </div>

                    <div class="choose-winner__buttons">
                        <button
                            class="button button-primary"
                            :class="{ disabled: null === winner }"
                            @click.prevent="chooseWinner"
                        >
                            {{$t('Approve')}}
                        </button>
                    </div>
                </div>
                <div class="messages__loader" v-else>
                    <img src="https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif" class="" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SvgIcon from "../../../../SvgIcon";
import match from "../../../../../api/match";
import TeamService from '../../../../../api/team'
import team from "../../../../../api/team";
import RoundRobinService from '../../../../../api/round-robin'
import Match from "../../../../../dto/match";
import Participant from "../../../../../dto/participant";
import MatchResult from "../../../../../dto/matchResult";
export default {
    props: {
        match: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            results: null,
            load: false,
            winner: null,
            rand: Math.floor(Math.random() * 99999),
        }
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament',
        })
    },
    methods: {
        getAvatar (participant) {
            if (participant.type === 2) {
                return participant.team.logo ? participant.team.logo : '/images/logo.jpg'
            }

            return participant.profile.user.avatar ? participant.profile.user.avatar : '/images/logo.jpg';
        },
        getNickname (participant) {
            if (participant.type === 2) {
                return participant.team.name;
            }

            if (participant.profile.user.nickname) {
                return participant.profile.user.nickname;
            }
            if (participant.profile.user.name) {
                return participant.profile.user.name;
            }
            if (participant.profile.user.email) {
                return participant.profile.user.email;
            }

            return 'Unknown';
        },
        chooseWinner () {
            if (!this.winner || this.load) {
                return;
            }
            this.load = true
            RoundRobinService.rrFinishMatch(this.tournament.getId(), this.match.room_id, this.match.id, {
                winner_id: this.winner
            }).then(data => {
                if (data.response && data.response && data.response.status !== 200) {
                    this.$notify({
                        type: 'error',
                        title: 'Finish error',
                        group: 'notify',
                        text: data.response.data.message ? data.response.data.message : 'Unknown error'
                    })
                } else {
                    this.$emit('update')
                    this.$emit('close')
                }
            }).finally(() => {
                this.load = false
            });
        },
        getName (participant) {
            if (participant.type === 2) {
                return participant.team.name;
            }
            if (participant.profile.user.name) {
                return participant.profile.user.name;
            }
            if (participant.profile.user.nickname) {
                return participant.profile.user.nickname;
            }
            if (participant.profile.user.email) {
                return participant.profile.user.email;
            }

            return 'Unknown'
        },
        async init () {
            const matchResponse = await RoundRobinService.getRoomMatch(this.tournament.getId(), this.match.room_id, this.match.id);
            const resultsResponse = await RoundRobinService.getMatchResult(this.tournament.getId(), this.match.id);

            const matchData = matchResponse.data.data;
            const firstParticipantExists = !!matchResponse.data.data.first_participant.participant;
            const secondParticipantExists = !!matchResponse.data.data.second_participant.participant;

            if (firstParticipantExists) {
                const firstParticipantType = Number(matchResponse.data.data.first_participant.participant.type);
                const firstParticipant = matchResponse.data.data.first_participant.participant;

                if (firstParticipantType === 2) {
                    const firstTeamResponse = await TeamService.get(firstParticipant.team.id);
                    const firstTeam = firstTeamResponse.data.data;
                }
            }

            if (secondParticipantExists) {
                const secondParticipantType = Number(matchResponse.data.data.second_participant.participant.type);
                const secondParticipant = matchResponse.data.data.second_participant.participant;

                if (secondParticipantType === 2) {
                    const secondTeamResponse = await TeamService.get(secondParticipant.team.id);
                    const secondTeam = secondTeamResponse.data.data;
                }
            }


            const data = {
                match: matchData,
                images: resultsResponse.data.data
            }


            const match = new Match(data.match);
            const firstPlayer = new Participant(match.getFirstParticipant().participant);
            const secondPlayer = new Participant(match.getSecondParticipant().participant);
            let firstPlayerData = null;
            let secondPlayerData = null;
            const images = {
                first: [],
                second: [],
            };

            data.images.forEach((item) => {
                const result = new MatchResult(item);

                if (result.getType() === 'IMAGE') {
                    const firstUserId = firstPlayer.isType('single') ? firstPlayer.getUserId() : firstPlayer.getCreatorId();
                    const secondUserId = secondPlayer.isType('single') ? secondPlayer.getUserId() : secondPlayer.getCreatorId();
                    switch (result.getReporterID()) {
                        case firstUserId :
                            images.first.push(result.getImage());
                            break;
                        case secondUserId :
                            images.second.push(result.getImage());
                            break;
                    }
                }
            });

            if (!firstPlayer.isEmpty()) {
                firstPlayerData = {
                    id: firstPlayer.getId(),
                    avatar: firstPlayer.getUserAvatar(),
                    name: firstPlayer.getUserNickName(),
                    type: firstPlayer.isType('team') ? 'team' : 'single',
                    team_mates: firstPlayer.getTeamMates(),
                    platformName: firstPlayer.getPlatformId(),
                    images: images.first
                };
            }

            if (!secondPlayer.isEmpty()) {
                secondPlayerData = {
                    id: secondPlayer.getId(),
                    avatar: secondPlayer.getUserAvatar(),
                    name: secondPlayer.getUserNickName(),
                    type: secondPlayer.isType('team') ? 'team' : 'single',
                    team_mates: secondPlayer.getTeamMates(),
                    platformName: secondPlayer.getPlatformId(),
                    images: images.second
                };
            }

            this.results = {
                matchId: match.getMatchID(),
                first: firstPlayerData,
                second: secondPlayerData
            };
        }
    },
    mounted () {
      this.init();
    },
    components: {
        SvgIcon
    }
}
</script>
<style scoped lang="scss">
.messages__loader {
    width: 100%;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    img {
        width: 30px;
    }
}
.choose-wrapper {
    max-height: 10rem;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 25px;
    align-items: flex-start;
    grid-gap: 25px;
    .choose-wrapper__participant {
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        input {
            display: none;
        }
        & > .choose-wrapper__participant-player {
            cursor: pointer;
            background: #ECECEC !important;
        }
        &.select {
            border: 1px solid #018786;
        }

        .choose-wrapper__participant-player {
            padding: 9px;
            display: flex;
            align-items: center;
            background: #FAFAFA;
            .choose-wrapper__participant-player_avatar {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                overflow: hidden;
                margin-right: 12px;
                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                }
            }
            .choose-wrapper__participant-player_info {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                overflow: hidden;
                strong {
                    display: block;
                    width: 100%;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 13px;
                    text-transform: capitalize;
                    color: #1C1C1C;
                    margin-bottom: 3px;
                }
                span {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 13px;
                    letter-spacing: 0.4px;
                    text-transform: uppercase;
                    color: #878787;
                }
            }
            .choose-wrapper__participant-player_checkbox {
                border-radius: 100%;
                border: 1px solid #8F8F8F;
                width: 16px;
                height: 16px;
                margin-right: 5px;
                min-width: 16px;
                position: relative;
                display: flex;
                align-items: center;
                transition: 0.2s;
                justify-content: center;
                &:after {
                    position: absolute;
                    transition: 0.2s;
                    content: "";
                    display: block;
                    width: 10px;
                    border-radius: 100%;
                    height: 10px;
                    background: #018786;
                    opacity: 0;
                }
            }
        }

        input:checked + .choose-wrapper__participant-player {
            .choose-wrapper__participant-player_checkbox {
                border-color: #018786;
                &:after {
                    opacity: 1;
                }
            }
        }

        .choose-wrapper__participant-teams {
            .choose-wrapper__participant-player + .choose-wrapper__participant-player {
                border-top: 1px solid #E5E5E5;
            }
        }

        .choose-wrapper__images {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            .choose-winner__images-item {
                max-width: 100%;
                max-height: 500px;
                img {
                    max-width: 100%;
                }
            }
        }
    }
}

</style>
