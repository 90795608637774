// create a vuejs 2 component for toast notifications where intercept in vuex store che error and create a stack of Toast notifications with component Toast.vue
<template>
  <div class="toast-notifier">
    <Toast v-for="toast in toasts" :key="toast.id" v-bind="{ ...toast }" @close="closeToast(toast.id)"></Toast>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import Toast from './Toast.vue';
export default {
  name: 'ToastNotifier',
  components: {
    Toast
  },
  computed: {
    toasts() {
      return this.$store.getters['toastEvents/getEvents'];
    }
  },
  mounted() {
    // TODO remove this
    // this.$store.commit('toastEvents/ADD_EVENT', {
    //   message: 'test',
    //   type: 'info'})

  },
  methods: {
    closeToast(index) {
      this.$store.commit('toastEvents/REMOVE_EVENT', index);
    }
  }
}

</script>
<style scoped>
.toast-notifier {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px;
}
</style>
