<template>
	<div>
		<div class="page__content-header">
			<div>
				<PageLinks v-bind:links="[
					{
						url: '/seasons',
						text: $t('All Seasons')
					}
				]"/>
			</div>
			<div>
				<RouterLink to="/seasons/edit/default" :class="'button button-transparent'">{{$t('Edit default season')}}</RouterLink>
				<RouterLink to="/seasons/add" :class="'button button-primary'">{{$t('Create New Season')}}</RouterLink>
			</div>
		</div>
		<div class="table-wrapper">
			<table v-if="seasons">
				<thead class="text-left">
				<tr>
					<th scope="col">{{$t('ID')}}</th>
					<th scope="col">{{$t('Season title')}}</th>
					<th scope="col">{{$t('Season description')}}</th>
					<th scope="col">{{$t('Game')}}</th>
					<th scope="col">{{$t('Start season')}}</th>
					<th scope="col">{{$t('End season')}}</th>
					<th scope="col">{{$t('Is Active')}}</th>
					<th scope="col"></th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="season in seasons" :key="season.id">
					<td scope="row" class="cursor-pointer" @click="goToPage(`/season/${season.id}/solo`)">{{ season.id }}</td>
					<td scope="row" class="cursor-pointer" @click="goToPage(`/season/${season.id}/solo`)">{{ season.title }}</td>
					<td scope="row" class="cursor-pointer" @click="goToPage(`/season/${season.id}/solo`)">{{ season.description }}</td>
					<td scope="row" class="cursor-pointer" @click="goToPage(`/season/${season.id}/solo`)">{{ getGameName(season.game_id) }}</td>
					<td scope="row" class="cursor-pointer" @click="goToPage(`/season/${season.id}/solo`)">{{ season.started_at | readableDateFormat | getDate }}</td>
					<td scope="row" class="cursor-pointer" @click="goToPage(`/season/${season.id}/solo`)">{{ season.finished_at | readableDateFormat | getDate }}</td>
					<td scope="row" class="cursor-pointer" @click="goToPage(`/season/${season.id}/solo`)">{{ season.is_active ? $t('Yes') : $t('No') }}</td>
					<td>
						<Dropdown
							:data-position="'left'"
							:data-horizontal-aligntment="'left'"
							:data-vertical-aligntment="'top'"
							:toggle-class="'dots-toggle'"
						>
							<SvgIcon
								slot="dropdown-toggle"
								width="23"
								height="5"
								view-box="0 0 23 5"
								:iconType="'dots'"
							/>
							<div slot="dropdown-content">
								<RouterLink
									:class="'dropdown__link'"
									:to="{ path: '/seasons/edit/' +  season.id }"
								>
									<SvgIcon
										width="16"
										height="16"
										:iconType="'edit'"
									/>
									<span>{{$t('Edit')}}</span>
								</RouterLink>
								<a
									href="#"
									class="dropdown__link"
									@click.prevent="deleteById(season.id)"
								>
									<SvgIcon
										width="16"
										height="16"
										:iconType="'delete'"
									/>
									<span>{{$t('Delete')}}</span>
								</a>
								<RouterLink
									:class="'dropdown__link'"
									:to="{ path: '/season/' +  season.id + '/solo' }"
								>
									<SvgIcon
										width="16"
										height="16"
										:iconType="'copy'"
									/>
									<span>{{$t('View')}}</span>
								</RouterLink>
							</div>
						</Dropdown>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
		<PagePagination
			v-if="seasonsMeta"
			:current="seasonsMeta.currentPage()"
			:total="seasonsMeta.lastPage()"
			@change="changePage"
		/>
	</div>
</template>

<script>
	import {mapGetters, mapActions, mapMutations} from 'vuex'

	import router from '../../routes';
	import SvgIcon from '../../components/SvgIcon';
	import Dropdown from '../../components/Dropdown';
	import PagePagination from '../../components/Page/Pagination';
	import PageLinks from '../../components/Page/Links';

	export default {
		components: {
			SvgIcon,
			Dropdown,
			PageLinks,
			PagePagination,
		},
		computed: {
			...mapGetters([
				'getGame',
				'seasons',
				'seasonsMeta'
			])
		},
		mounted() {
			this.init();
		},
		methods: {
			...mapMutations({
				hideDropdown: 'HIDE_DROPDOWN',
			}),
			...mapActions({
				getGames: 'getGames',
				getSeasons: 'getSeasons',
				deleteSeason: 'deleteSeason',
			}),
			async init() {
				await this.getGames();
				await this.getSeasons();
			},
			async deleteById(id) {
				this.hideDropdown();
				await this.deleteSeason(id);
			},
			goToPage(url) {
				router.push(url)
			},
			changePage(page) {
				this.getSeasons({
					page: page,
				});
			},
			getGameName(id) {
				return id && this.getGame ? this.getGame(id)[0]?.name : '';
			},
		},
	}
</script>
