<template>
	<div class="modal notification-sent-dialog" v-show="showNotificationSentDialog">
		<div class="modal-wrapper">
			<div class="modal-overlay"></div>
			<div class="modal-container">
				<div class="modal-header">
					<div class="modal-header__title">
						<span>{{$t('Notification was sent')}}</span>
					</div>
					<div class="modal-header__close" @click="close">
						<SvgIcon
							width="16"
							height="16"
							:iconType="'slim'"
						/>
					</div>
				</div>
				<div class="modal-body">
					<div class="notification-modal">
						<h6>{{$t('Your message was sent to all users in tournaments that you selected!')}}</h6>
						<button
							class="button button-primary"
							@click.prevent="goToNotifications()"
						>
							{{$t('Show notification')}}
						</button>
						<button
							class="button button-primary"
							@click.prevent="close()"
						>
							{{$t('Send New')}}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'
	import {NOTIFICATION_SENT_DIALOG} from '../../constants/dialogs'
	import router from '../../routes'

	import SvgIcon from '../SvgIcon'

	export default {
		name: "NotificationSentDialog",
		components: {
			SvgIcon
		},
		computed: {
			...mapGetters([
				'showNotificationSentDialog'
			])
		},
		methods: {
			...mapActions({
				closeDialog: 'closeDialog',
			}),
			close() {
				this.closeDialog({type: NOTIFICATION_SENT_DIALOG})
			},
			goToNotifications() {
				this.close();
				router.push('/notifications');
			},
		}
	};
</script>
