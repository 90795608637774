import http from '../utils/http'
import joinUrls from '../utils/join-urls'

export default {
	get(team) {
		// Leave commented to allow backend to send Deleted Team placeholder
		// if (!team) {
		// 	return Promise.reject(new Error('Team id is required to get team'));
		// }

		return http.get(joinUrls('/teams', team));
	}
}