<template>
    <div class="double-ellimination" :style="{'height': 200 * findMaxCount + 'px'}" v-if="loaded">
        <div v-for="(round, i) in getRoundsWithMatches" :key="i"
             class="brackets__round"
             :style="{'justify-content': i !== 0 || getRoundsWithMatches[i + 1].length * 2 === round.length ? 'space-around': ''}">
            <BracketItem
                :style="getStyleForItem(match, i, match.next_match_id)"
                :data="match"
                :class="{'right-line': countToNextMatch(match) < 2 && i === 0, 'default-line': i !== 0 || countToNextMatch(match) > 1}"
                v-for="(match,k) in round"
                :key="k"></BracketItem>
        </div>
    </div>
</template>
<script>
import helpers from "../../../helpers";
import {mapActions, mapGetters} from "vuex";
import BracketItem from '../BracketItem'
import match from '../../../api/match'
export default {
    data () {
        return {
            cache: [],
            loaded: false,
            matches: [],
            currentRound: null
        }
    },
    props: {
        type: {
            required: false,
            type: String,
        },
        id: String,
        isPublic: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        countToNextMatch (match) {
            return this.getMatches.filter(item => item.next_match_id === match.next_match_id).length
        },
        getStyleForItem (match, i, next_match_id) {
         let findRound = this.getMatches.find(item => item.id === next_match_id)
         if (findRound) {
             findRound = findRound.round - 1
         }

          if (i === 0 && findRound && this.getRoundsWithMatches[findRound].length * 2 !== this.getRoundsWithMatches[i].length) {

              let nextMatches = this.getRoundsWithMatches[findRound - 1]
              const findIndex = nextMatches.findIndex(item => item.id === match.next_match_id)
              if (findIndex < 0) {
                  return {
                  'top': '10px'
                  }
              }

              const checkTwo = this.getMatches.filter(item => item.next_match_id === match.next_match_id)
              if (checkTwo.length > 1) {
                  if (!this.cache.includes(match.next_match_id)) {
                      this.cache.push(match.next_match_id)
                      return {
                          'position': 'absolute',
                          'top': (findIndex + 0.5) * 100.1 + 'px'
                      }
                  } else {
                      this.cache = []
                      return {
                          'position': 'absolute',
                          'top': ((findIndex) * 100.1) - 50 + 'px'
                      }
                  }

              }

              return {
                  'position': 'absolute',
                  'top': (findIndex) * 100.1 + 'px'
              }
          }

          return {
              'margin-top': '10px'
          }
        },
        sortRoundByNextMatchId (round) {
            return round.sort((a,b) => {
                return a.next_match_id < b.next_match_id ? 1 : -1
            })
        },
        findReferenceToItem (item) {
            return this.getMatches.find(i => i.next_match_id === item.id)
        },
        ...mapActions({
            getBrackets: 'fetchBrackets',
            getPublicBrackets: 'fetchPublicBrackets',
        }),
        isMobileBracket() {
            return this.isMobile && !this.isPublic;
        },
        fetchMatches () {
            match.getAll(this.id).then(response => {
                this.matches = response.data.data
            }).finally(() => {
                this.$nextTick(() => {
                    this.loaded = true
                })
            })
        }
    },
    components: {
        BracketItem
    },
    mounted() {
        this.fetchMatches()
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament',
            brackets: 'brackets',
            selectedRound: 'selectedRound',
            isMobile: 'isMobile'
        }),
        findMaxCount () {
          let max = 0
          this.getRoundsWithMatches.map(item => {
              if (item.length > max) {
                  max = item.length
              }
          })

          return max
        },
        getMatches () {
          if (this.type === 'upper') {
              return this.matches.filter(item => !item.is_looser_match)
          }
          return  this.matches.filter(item => item.is_looser_match)
        },
        rounds () {
          const rounds = []
          this.getMatches.forEach(item => {
              if (!rounds.includes(item.round)) {
                  rounds.push(item.round)
              }
          })

          return rounds
        },
        getRoundsWithMatches () {
            const matches = []
            let skip = 1
            this.rounds.forEach(item => {
                const findMatches = this.getMatches.filter(i => i.round === item)
                if (findMatches.length > 0) {
                    if (skip) {
                        matches.push(this.sortRoundByNextMatchId(findMatches))
                    } else {
                        skip = 0
                        matches.push(findMatches)
                    }
                }
            })
            return matches
        },
        bracketsData() {
            const handler = (brackets) => {
                if (this.isMobileBracket()) {
                    return helpers.generateMobileBracket(brackets, this.selectedRound);
                }

                return helpers.generateRecursiveBracket(brackets);
            }
            if (this.type === 'lower') {
                return handler(helpers.bracketsLower(this.brackets))
            }
            if (this.type === 'upper') {
                return handler(helpers.bracketsUpper(this.brackets))
            }

            return handler(this.brackets)
        }
    }
}
</script>
<style scoped lang="scss">
.double-ellimination {
    padding-bottom: 100px;
    padding-top: 100px;
    display: flex;
    .brackets__round {
        width: 300px;
        position: relative;
        display: flex;
        padding-right: 100px;
        flex-direction: column;
        .brackets__item-content {
            margin: 50px 0;
            &.right-line {
                position: relative;
                &::after {
                    position: absolute;
                    left: 100%;
                    top: calc(50% - 0.5px);
                    width: 100px;
                    height: 2px;
                    background: #E5E5E5;
                    content: "";
                    display: block;
                }
            }
            &.default-line {
                position: relative;
                &::after {
                    position: absolute;
                    left: 100%;
                    top: calc(50% - 0.3px);
                    width: 50px;
                    height: 1px;
                    background: #E5E5E5;
                    content: "";
                    display: block;
                }
            }
        }
    }
}
</style>
