<template>
	<ul class="pagination" v-if="1 < total">
		<li class="pagination-item pagination-item--prev" v-if="current > 1">
			<a href="#" @click.prevent="changePage(current - 1)">&lt; Prev</a>
		</li>
		<li v-for="(index, page) in total" :key="index" :class="{current: page === current}">
			<a href="#" @click.prevent="changePage(page)">{{page}}</a>
		</li>
		<li class="pagination-item pagination-item--next" v-if="current < total">
			<a href="#" @click.prevent="changePage(current + 1)">Next &gt;</a>
		</li>
	</ul>
</template>

<script>
	export default {
		name: "page-pagination",
		props: {
			current: {
				type: Number
			},
			total: {
				type: Number
			}
		},
		methods: {
			changePage(page) {
				this.$emit('change', page);
			}
		}
	};
</script>
