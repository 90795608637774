<template>
	<div>
		<div class="page__content-header">
			<div>
				<page-links v-bind:links="[
					{
						url: '/notifications',
						text: $t('Notifications')
					}
				]"/>
			</div>
			<div>
				<RouterLink to="/notifications/add" :class="'button button-primary'">{{$t('Send Notification')}}</RouterLink>
			</div>
		</div>
		<div>
            <div class="filters__wrapper">
                <div>
                    <label for="" class="filter__label">
                        Filters:
                    </label>
                    <div class="tournament__filters">
                        <FormMultiSelect
                            v-if="games && games.length"
                            :placeholder="$t('Game')"
                            :options="games"
                            :searchable="true"
                            v-model="filters.games"
                            @input="changeFilters"
                            :multiple="false"
                            label="name"
                            :selectLabel="''"
                        ></FormMultiSelect>
                    </div>
                </div>
                <div>
                    <label for="" class="filter__label">
                        Sort by:
                    </label>
                    <div class="tournament__filters">
                        <FormMultiSelect
                            v-if="games && games.length"
                            :placeholder="$t('Order by')"
                            @input="changeFilters"
                            :options="[
                                {
                                    orderBy: 'id',
                                    dir: 'DESC',
                                    name: 'Latest first'
                                },
                                {
                                    orderBy: 'id',
                                    dir: 'ASC',
                                    name: 'Old first'
                                },
                            ]"
                            :searchable="false"
                            v-model="filters.sort"
                            label="name"
                            :selectLabel="''"
                        ></FormMultiSelect>
                    </div>
                </div>
            </div>

            <table v-if="notifications">
				<thead class="text-left">
				<tr>
					<th scope="col">{{$t('Notification ID')}}</th>
					<th scope="col">{{$t('Title')}}</th>
					<th scope="col">{{$t('Message')}}</th>
                    <th scope="col">{{$t('Tournament ID')}}</th>
                    <th scope="col">{{$t('Actions')}}</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(notification) in notifications">
					<td scope="row">{{ notification.getId() }}</td>
					<td>{{ notification.getTitle() }}</td>
					<td>{{ notification.getMessage() }}</td>
                    <td scope="row">{{ notification.getTournamentId() }}</td>
                    <td scope="row">
                        <dropdown
                            :data-position="'left'"
                            :data-horizontal-aligntment="'left'"
                            :data-vertical-aligntment="'top'"
                            :toggle-class="'dots-toggle'"
                        >
                            <svg-icon
                                slot="dropdown-toggle"
                                width="23"
                                height="5"
                                view-box="0 0 23 5"
                                :iconType="'dots'"
                            />
                            <div slot="dropdown-content">
                                <RouterLink
                                    :class="'dropdown__link'"
                                    :to="{ path: '/notifications/add/?donor_id=' +  notification.getId() }"
                                >
                                    <svg-icon
                                        width="16"
                                        height="16"
                                        :iconType="'plus'"
                                    />
                                    <span>{{$t('Clone')}}</span>
                                </RouterLink>
                            </div>
                        </dropdown>
                    </td>
				</tr>
				</tbody>
			</table>
			<page-pagination
				v-if="notificationsMeta"
				:current="notificationsMeta.currentPage()"
				:total="notificationsMeta.lastPage()"
				@change="changePage"
			/>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

	import router from '../../routes';
	import SvgIcon from '../../components/SvgIcon';
	import Dropdown from '../../components/Dropdown';
	import PagePagination from '../../components/Page/Pagination';
	import PageLinks from '../../components/Page/Links';
    import FormMultiSelect from 'vue-multiselect'

	export default {
        data () {
            return {
                filters: {
                    games: [],
                    sort: {
                        orderBy: 'id',
                        dir: 'DESC',
                        name: 'Latest first'
                    }
                },
            }
        },
		components: {
			SvgIcon,
			Dropdown,
			PageLinks,
			PagePagination,
            FormMultiSelect
		},
		computed: {
			...mapGetters([
				'notifications',
                'games',
				'notificationsMeta'
			])
		},
		created() {
			this.$store.dispatch('GET_NOTIFICATIONS');
            this.getGames();
		},
		methods: {
            ...mapActions({
                getGames: 'getGames'
            }),
            prepareFields () {
                const data = {}
                if (this.filters.games) {
                    data.games = this.filters.games
                }

                if (this.filters.match_type) {
                    data.match_type = this.filters.match_type
                }
                if (this.filters.tournament_type) {
                    data.tournament_type = this.filters.tournament_type
                }
                if (this.filters.sort && this.filters.sort.orderBy && this.filters.sort.dir) {
                    data.sort = this.filters.sort
                }
                sessionStorage.setItem('main', JSON.stringify(data))
            },
            changeFilters () {
                this.$store.dispatch('GET_NOTIFICATIONS', {
                    page: 1,
                    games: this.filters.games ? this.filters.games.id : '',
                    order: this.filters.sort.orderBy,
                    direction: this.filters.sort.dir
                });
            },
			changePage(page) {
				this.$store.dispatch('GET_NOTIFICATIONS', {
					page: page
				});
			},
		},
	}
</script>
<style scoped lang="scss">
.filters__wrapper {
    max-width: 90%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.filter__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #06C2BC;
    display: block;
    margin-bottom: 5px;
}
.tournament__filters {
    display: flex;
    .multiselect {
        width: 250px;
    }
    .multiselect + .multiselect {
        margin-left: 15px;
    }
}
</style>
