<template>
    <div
        class="brackets__start-tournament-button"
        v-if="show()"
    >
        <button
            :disabled="!hasPermission('Tournament edit', tournament.getGameId())"
            class="button button-primary"
            @click.prevent="showRecreateBracketsDialog = true"
        >{{$t('Recreate brackets')}}
        </button>
        <RecreateBracketsDialog v-if="showRecreateBracketsDialog" @close="showRecreateBracketsDialog = false"></RecreateBracketsDialog>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import RecreateBracketsDialog from "./RecreateBracketsDialog";
export default {
    data () {
        return {
            showRecreateBracketsDialog: false
        }
    },
    name: "BracketsRestoreTournamentButton",
    computed: {
        ...mapGetters({
            tournament: 'tournament',
        })
    },
    components: {
        RecreateBracketsDialog
    },
    methods: {
        show() {
            return ['REGISTRATION_CLOSED', 'STARTED'].includes(this.tournament.getStatus());
        }
    }
};
</script>
