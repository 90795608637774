<template>
	<div class="form-number-field">
		<label>
			<span class="form-label">{{label}}</span>
			<input
				class="form-input"
				type="number"
				:name="name"
				v-model="newValue"
				:min="min"
				:max="max"
				@change="valueChanged"
			>
		</label>
		<FormNotification :errors="errors"/>
	</div>
</template>

<script>
	import FormNotification from '../../components/Form/Notification'

	export default {
		name: "FormNumber",
		components: {
			FormNotification
		},
		data() {
			return {
				newValue: this.value,
				errors: this.formErrors,
		    minValue: this.min,
		    maxValue: this.max,
			}
		},
		props: {
			name: {
				type: String,
			},
			value: {
				type: [String, Number],
			},
			min: {
				type: Number,
				default: 0
			},
			max: {
				type: Number,
				default: 99999
			},
			label: {
				type: String,
			},
			formErrors: {
				type: [Array, Object],
			},
			onChange: {type: Function},
		},
		watch: {
			value: {
				handler(val) {
					this.newValue = val;
				}
			},
			max: {
				handler(val) {
					this.maxValue = val;
				}
			},
			min: {
				handler(val) {
					this.minValue = val;
				}
			},
			formErrors: {
				handler(errors) {
					this.errors = errors;
				},
				deep: true
			}
		},
		methods: {
			valueChanged(event) {
				if (this.minValue > Number(event.target.value)) this.newValue = this.minValue;
				if (this.maxValue < Number(event.target.value)) this.newValue = this.maxValue;

				this.onChange(this.name, this.newValue);
				this.errors = [];
			}
		}
	};
</script>