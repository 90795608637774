import participantsApi from '../../api/participants'
import Participant from '../../dto/participant'
import RoundRobin from '../../api/round-robin'
const state = () => ({
	participants: [],
	participantsEmails: []
});

const getters = {
	participants: state => state.participants,
	participantsEmails: state => state.participantsEmails,
};

const actions = {
	GET_PARTICIPANTS: ({commit}, data) => {
		return new Promise((resolve, reject) => {
			participantsApi.get(data.id, data.params)
				.then((resp) => {
                    if (data.push) {
                        commit('ADD_PARTICIPANTS', resp.data.data);
                    } else {
                        commit('UPDATE_PARTICIPANTS', resp.data.data);
                    }
					resolve(resp);
				})
				.catch(err => {
					reject(err)
				});
		});
	},
    GET_ROUND_ROBIN_PARTICIPANTS: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            RoundRobin.getParticipants(data.id, data.params)
                .then((resp) => {
                    commit('UPDATE_PARTICIPANTS', resp.data.data);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err)
                });
        });
    },
	GET_PARTICIPANTS_EMAILS: ({commit}, id) => {
		return new Promise((resolve, reject) => {
			participantsApi.getEmails(id)
				.then((resp) => {
					commit('UPDATE_PARTICIPANTS_EMAILS', resp.data.users);
					resolve(resp);
				})
				.catch(err => {ql-editor
					reject(err)
				});
		});
	},
	DELETE_PARTICIPANT: async ({commit}, data) => {
		await participantsApi.delete(data.tournament, data.user);

		const participantsResponse = await participantsApi.get(data.tournament);

		commit('UPDATE_PARTICIPANTS', participantsResponse.data.data);
	},
	BYPASS_PARTICIPANT_CHECK: async ({commit}, data) => {
		await participantsApi.bypassChechinValidator(data.tournament, data.participant_id);

		const participantsResponse = await participantsApi.get(data.tournament);

		commit('UPDATE_PARTICIPANTS', participantsResponse.data.data);
	}
};

const mutations = {
    ADD_PARTICIPANTS: (state, data) => {
        let items = state.participants;

        data.forEach(item => {
            items.push(new Participant(item));
        });

        state.participants = items;
    },
	UPDATE_PARTICIPANTS: (state, data) => {
		let items = [];

		data.forEach(item => {
			items.push(new Participant(item));
		});
		state.participants = items;
	},
	UPDATE_PARTICIPANTS_EMAILS: (state, data) => {
		state.participantsEmails = data;
	},
    REMOVE_PARTICIPANTS_FROM_STATE: (state, id) => {
        const index = state.participants.findIndex(item => item.getId() === id)
        if (index > 0) {
            state.participants.splice(index, 1)
        }
    }
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
