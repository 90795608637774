<template>
	<div class="leaderboard__schedule-button" v-if="show()">
		<button @click="openScheduleDialog" class="button button-primary">{{$t('Update schedule')}}</button>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'

	export default {
		name: "LeaderboardRoundsScheduleButton",
		computed: {
			...mapGetters({
				tournament: 'tournament',
				tournamentStatus: 'tournamentStatus'
			})
		},
		methods: {
			...mapActions({
				showScheduleDialog: 'showLeaderBoardScheduleDialog'
			}),
			openScheduleDialog() {
				this.showScheduleDialog(this.tournament.getId());
			},
			show() {
				const status = this.tournamentStatus;
				return ['REGISTRATION_CLOSED', 'REGISTRATION_OPENED', 'STARTED', 'IN_PROGRESS'].includes(status.toUpperCase());
			}
		}
	};
</script>
