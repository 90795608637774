<template>
	<div class="chat" v-if="id && (payload && payload.isTournamentChat ? tournament.getId() === id : true)">
		<div
			class="chat__header"
			:class="{active : isActiveChat(id) && !isExpandedChat(id)}"
			@click.prevent="expandChat"
		>
			<div class="chat__header-title">
                <template v-if="payload && payload.isTournamentChat">{{ $t('Tournament id') }}</template>
                <template v-else>{{$t('Match ID')}}</template>
                 #{{id}}
                </div>
			<div class="chat__header-buttons" v-if="!collapsed">
				<span @click="collapseChat">
					<svg-icon
						width="16"
						height="16"
						:iconType="'collapse'"
					/>
				</span>
				<span @click.prevent="closeChat(id)" style="display: block;">
					<svg-icon
						width="16"
						height="16"
						:iconType="'slim'"
					/>
				</span>
			</div>
		</div>
		<div class="chat__content" v-if="!collapsed">
			<ul class="chat__messages" :ref="'messages-' + id">
                <template v-if="loader">
                    <img src="https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif" class="messages__loader" alt="">
                </template>
				<li
					v-for="item in messages"
                    v-if="item.event_type !== 'system_event'"
				>
					<div
						class="chat__message"
						:class="item.role === 'admin' ? 'chat__message--right' : 'chat__message--left'"
						v-if="!isCallAdmin(item.message)"
					>
						<span class="chat__message-author">{{item.nickname}}</span>
                        <div class="chat__message-content" v-if="item.message_type === 0" v-html="item.message" v-linkified/>
                        <div class="chat__message-content" v-if="item.message_type === 1">
                            <img :src="item.message" alt="">
                        </div>
						<span class="chat__message-time">{{getMessageTime(item.created_at)}}</span>
					</div>
                    <div class="chat__message-call-admin" v-else>{{$t('Call admin')}}</div>
				</li>
			</ul>
		</div>
		<form class="chat__footer" v-if="!collapsed">
			<textarea
				rows="1"
				class="chat__input form-input"
				:placeholder="$t('Type a message...')"
				@keydown.enter.exact.prevent
				@keyup.enter.exact="sendMessage"
				@keydown.enter.ctrl.exact="addNewLineToText"
				@input="autoResizeArea"
				v-model="message"
			></textarea>
			<button @click.prevent="sendMessage" class="button chat__button">
				<svg-icon
					width="24"
					height="24"
					:iconType="'telegram'"
				/>
			</button>
		</form>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex'

	import moment from 'moment'
	import matchApi from '../../api/match'
	import SvgIcon from '../SvgIcon'
    import TournamentService from '../../api/tournament'
	import linkify from 'vue-linkify'
    import MatchService from '../../api/match'

	export default {
		name: 'chat',
		components: {
			SvgIcon
		},
		props: ['id', 'payload'],
		directives: {
			linkified: linkify
		},
		data() {
			return {
                subscribe: null,
                messages: null,
				message: null,
				collapsed: true,
				areaMaxHeight: 300,
                loader: false,
                channelQueue: []
			}
		},
		computed: {
			...mapGetters({
				chatTournament: 'chatTournament',
				getChat: 'getChat',
                tournament: 'tournament',
                notificationMuted: 'notificationMuted',
				isActiveChat: 'isActiveChat',
				isExpandedChat: 'isExpandedChat',
                centrifugeInstance: 'centrifugeInstance'
			})
		},
		watch: {
			id: function (id) {
				this.collapsed = !this.isExpandedChat(this.id);
			},
            centrifugeInstance () {
                this.subscribeMessages()
            }
		},
        beforeDestroy() {
            this.subscribe.unsubscribe()
            this.subscribe.removeAllListeners()
        },
        async created() {
			this.collapsed = !this.isExpandedChat(this.id);
			this.scrollChatToEnd();
        },
        async mounted () {
            await this.getMessages()
        },
		methods: {
            toBottom () {
                const id = `messages-${this.id}`;
                const target = this.$refs[id];

                if (this.isElementScrolledBottom(target)) this.scrollChatToEnd();
            },
            async getMessages () {
                this.loader = true
                if (this.payload && this.payload.isTournamentChat) {
                    await TournamentService.getChatMessages(this.payload.channel).then(response => {
                        this.messages = response.data.data.reverse()
                        if (this.centrifugeInstance) {
                            this.subscribeMessages()
                        }
                        this.toBottom()
                    }).finally(() => {
                        this.loader = false
                    })
                } else {
                    await MatchService.getMessages(this.tournament.getId(), this.id).then(response => {
                        this.messages = response.data.data.reverse()
                        if (this.centrifugeInstance) {
                            this.subscribeMessages()
                        }
                        this.toBottom()
                    }).finally(() => {
                        this.loader = false
                    })
                }
            },
            subscribeMessages () {
                const channel = this.payload && this.payload.channel ? this.payload.channel : `chat.tournament.${this.tournament.getId()}.match.${this.id}`
                if (this.channelQueue.includes(channel)) return
                this.channelQueue.push(channel)
                this.subscribe = this.centrifugeInstance.subscribe(channel, (message) => {
                    if (!this.notificationMuted && message.data.user_id !== this.$currentUser.id && message.data.message !== '<#>call_admin') {
                        const audio = new Audio('/sounds/message_received.mp3');
                        audio.volume = 0.2;
                        audio.play();
                    }
                    this.messages.push(message.data)
                  this.toBottom()
              })
            },
			closeChat(matchID) {
                if (this.payload && this.payload.isTournamentChat) {
                    localStorage.removeItem('tournamentChat');
                    this.$store.commit('REMOVE_TOURNAMENT_CHAT', this.id)
                } else {
                    this.$store.dispatch('CLOSE_CHAT', {
                        tournament: this.tournament.getId(),
                        match: matchID
                    });
                }
			},
			expandChat() {
				if (this.collapsed) {
					this.collapsed = false;
					this.scrollChatToEnd();
					this.$store.dispatch('EXPAND_CHAT', this.id);
				}
			},
			collapseChat(e) {
				e.stopPropagation();
				this.collapsed = true;
				this.$store.dispatch('COLLAPSE_CHAT', this.id);
			},
			autoResizeArea(event) {
				if (event.target.scrollHeight > this.areaMaxHeight) {
					event.target.style.overflow = "auto";
				} else {
					event.target.style.overflow = "hidden";
				}

				event.target.style.height = "auto";
				event.target.style.height = `${event.target.scrollHeight}px`;
			},
			sendMessage() {
				if (this.message === '') return;
                const msg = this.message
                this.message = '';
                if (this.payload && this.payload.isTournamentChat) {
                    TournamentService.sendChatMessages(this.payload.channel, {
                        message: msg
                    })
                        .then((response) => {
                            if (201 !== response.status) {
                                this.$notify({
                                    type: 'error',
                                    title: 'Permission error',
                                    group: 'notify',
                                    text: response.response.data.message ? response.response.data.message : 'Unknown error'
                                })
                            }
                        })
                } else {
                    matchApi.sendMessage(this.tournament.getId(), this.id, msg)
                        .then((response) => {
                            if (201 !== response.status) {
                                this.$notify({
                                    type: 'error',
                                    title: 'Permission error',
                                    group: 'notify',
                                    text: response.response.data.message ? response.response.data.message : 'Unknown error'
                                })
                            }
                        })
                }

			},
			getMessageTime(time) {
				const timeZone = new Date().getTimezoneOffset();

				return moment(String(time)).utc().add((timeZone / 60) * -1, 'hours').format('HH:mm');
			},
			scrollChatToEnd() {
				setTimeout(() => {
					const id = `messages-${this.id}`;
					const target = this.$refs[id];

					if (target) target.scrollTop = target.scrollHeight;
				}, 0);
			},
			isCallAdmin(message) {
				return '<#>call_admin' === message;
			},
			addNewLineToText(e) {
				const value = this.message ? this.message : '';
				this.message = `${value}\n`;
				this.autoResizeArea(e);
			},
			isElementScrolledBottom(element) {
				if (!element || !element.scrollHeight)  return;

				return element.scrollHeight <= element.scrollTop + element.offsetHeight;
			}
		}
	};
</script>
<style scoped lang="scss">
.messages__loader {
    width: 20px;
    margin: 10px auto;
    display: block;
}
</style>
