<template>
    <div class="chats__page">
        <p style="padding: 30px;">Will be available soon</p>
        <section class="mainApp" v-if="centrifugeInstance && isAvailable">
            <div class="leftPanel">
                <header>
                    <input v-model="search" class="searchChats" type="search" placeholder="Search..."/>
                    <div class="chats__sides">
                        <button :class="{'active': activeTab === 'ACTIVE'}" @click="activeTab = 'ACTIVE'">Active</button>
                        <button :class="{'active': activeTab === 'CLOSED'}" @click="activeTab = 'CLOSED'">Closed</button>
                    </div>
                </header>
                <div class="chats">
                    <ConversationItem
                        :selectId="select ? select.id : null"
                        :class="{'active': select && select.id === conversation.id}"
                        @selectConversation="select = $event"
                        v-for="(conversation, i) in conversations"
                        :conversation="conversation" :key="i"></ConversationItem>
                </div>
            </div>

            <div class="rightPanel">
                <div class="select-conversation" v-if="!select">Please select a dialogue</div>
                <Conversation @changeTab="changeTab" @close="hiddenSelect" v-if="select" :conversation="select"></Conversation>
            </div>
        </section>
    </div>
</template>
<script>
import CachedIcon from 'vue-material-design-icons/Cached.vue';
import MessageIcon from 'vue-material-design-icons/Message.vue';
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import SendIcon from 'vue-material-design-icons/Send.vue';
import Conversation from './Conversation'
import AttachIcon from 'vue-material-design-icons/Attachment.vue';
import ConversationItem from './ConversationItem'
import ConversationsService from '../../api/conversations'
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
export default {
    data () {
        return {
            activeTab: 'ACTIVE',
            search: '',
            select: null,
            conversations: [],
            isAvailable: false,
        }
    },
    watch: {
      search () {
          this.getConversations()
      },
      activeTab() {
        this.select = null
        this.conversations =  []
        this.getConversations()
      }
    },
    computed: {
        ...mapGetters({
            centrifugeInstance: 'centrifugeInstance'
        }),
    },
    methods: {
        ...mapActions({
            connectCentrifuge: 'centrifugeConnect'
        }),
        hiddenSelect () {
          const index = this.conversations.findIndex(item => item.id === this.select.id)
          if (index > -1) {
              this.conversations.splice(index, 1)
          }
          this.select = null
        },
        changeTab (userId) {
            this.activeTab = 'ACTIVE'
            this.$router.push(`/chats?start=${userId}`).catch(() => {})
            this.init()
        },
        changeTabAndSelect (data) {
            this.activeTab = data.tab;
            this.$nextTick(() => {
                this.select = this.conversations.find(item => Number(item.user.id) === Number(data.conversation.user.id))
            })
        },
        async getConversations () {
            await ConversationsService.getConversations({
                search: this.search,
                withId: this.$route.query.start,
                status: this.activeTab
            }).then(response => {
                this.conversations = response.data
            })
        },
        async startConversation () {
            await ConversationsService.startConversation(this.$route.query.start).then(response => {
                const item = response.data
                if (response.status === 200) {
                    this.conversations.unshift(item);
                }
            })
        },
        async init () {
            if (this.$route.query.start) {
                await this.startConversation();
            }

            await this.getConversations()

            if (this.$route.query.start) {
                this.select = this.conversations.find(item => Number(item.user.id) === Number(this.$route.query.start))
            }

            this.connectCentrifuge()
        }
    },
    async mounted () {
        await this.init()
    },
    components: {
        CachedIcon,
        AttachIcon,
        Conversation,
        ConversationItem,
        SendIcon,
        MenuIcon,
        MessageIcon
    }
}
</script>
<style scoped lang="scss">
.mainApp {
    height: 100vh;
    overflow: hidden;
}
.select-conversation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    color: #000;
    font-weight: bold;
    bottom: 0;

}
.rightPanel {
    position: relative;
    background: #fff;
}
.chats__page {
    * {
        font-family: Arial, sans-serif !important;
    }
}



button {
    cursor: pointer;
}

button, input[type="search"], input[type="text"] {
    border: none;
    outline: none;
}

input[type="checkbox"] {
    margin: 7px;
    margin-right: 15px;
}

/* FIRST, THE OVERLAY ELEMENTS */

.alerts {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 9999;
    padding: 10px;
    color: #666;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.4);
    display: none;
}

/* small conversation menu */
.moreMenu {
    position: absolute;
    top: 70px;
    right: 0px;
    z-index: 10;
    padding: 10px;
    color: #666;
    border-radius: 0 0 0 4px;
    background: #FFF;
    display: none;
    border-top: 1px solid #DDD;
}

.moreMenu .option {
    width: 150px;
    height: 50px;
    display: block;
    background: #FFF;
    font-size: 14px;
    text-align: left;
    border-radius: 4px;
    padding-left: 10px;
}

.moreMenu .option:hover {
    background: #DDD;
}

.moreMenu .option:nth-last-child(1) {
    margin-top: 3px;
}

/* switch to mobile version screen */
.switchMobile {
    position: absolute;
    width: 65%;
    height: auto;
    padding: 10px;
    background: #FFF;
    top: 75px;
    left: 0px;
    right: 0px;
    margin: auto;
    border-radius: 4px;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.1);
    display: none;
}

.switchMobile .title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.switchMobile .desc {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 25px;
}

.switchMobile .okay {
    float: right;
    width: 80px;
    font-size: 16px;
    font-weight: 600;
    background: #419fd9;
    color: #fff;
    border-radius: 4px;
    padding: 10px;
}


.menu {
    position: relative;
    left: -320px;
    width: 100%;
    height: 100vh;
    float: left;
    background: #FFF;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.4);
    opacity: 0;
}

.me {
    position: relative;
    width: calc(100% - 50px);
    height: 140px;
    background: #419fd9;
    padding: 15px 25px;
    margin-bottom: 15px;
}

.me .image {
    width: 70px;
    height: 70px;
    background: #FFF url(https://thispersondoesnotexist.com/image) no-repeat center;
    background-size: cover;
    border-radius: 100%;
    cursor: pointer;
}

.me .settings {
    position: absolute;
    right: 20px;
    bottom: 65px;
    width: 40px;
    height: 40px;
    padding-top: 2px;
    color: #FFF;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.15);
}

.me .settings:hover {
    background: rgba(0, 0, 0, 0.35);
}

.me .cloud {
    position: absolute;
    right: 20px;
    bottom: 15px;
    width: 40px;
    height: 40px;
    color: #FFF;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.09);
}

.me .cloud:hover {
    background: rgba(0, 0, 0, 0.35);
}

.me .myinfo {
    position: absolute;
    bottom: 15px;
    font-size: 14px;
    color: #FFF;
}

.myinfo .name {
    font-weight: 600;
    margin-bottom: 5px;
}

.myinfo .phone {
    font-weight: 300;
}

nav button {
    width: 100%;
    height: 45px;
    background: #FFF;
    text-align: left;
    padding-left: 20px;
    color: #666;
}

nav {
    float: left;
    width: 100%;
    height: auto;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
}

nav button:hover {
    background: #EEE;
}

nav button > i {
    color: #999;
    float: left;
}

nav button > span {
    display: inline-block;
    margin-top: 5px;
    margin-left: 20px;
    font-weight: 600;
    font-size: 14px;
}

.info {
    position: absolute;
    left: 20px;
    bottom: 15px;
    font-size: 12px;
    color: #666;
}

/* configuration screen */
.config {
    position: absolute;
    width: 50%;
    height: 100vh;
    left: 0px;
    right: -200vw;
    top: 0px;
    margin: auto;
    background: #DDD;
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    z-index: 520;
    opacity: 0;
}

.confTitle {
    font-size: 24px;
    font-weight: 600;
    color: #444;
    margin: 10px 0px 15px 0px;
}

.configSect {
    float: left;
    width: calc(100% - 60px);
    padding: 15px 30px;
    margin-bottom: 10px;
    background: #FFF;
}

.profile .image {
    width: 140px;
    height: 140px;
    background: #FFF url(https://thispersondoesnotexist.com/image) no-repeat center;
    background-size: cover;
    border-radius: 100%;
    float: left;
    margin-right: 30px;
}

.side {
    width: auto;
    height: 110px;
    float: none;
    position: relative;
}

.side .name {
    font-size: 18px;
    font-weight: 600;
}

.side .pStatus {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 300;
}

.profile .changePic, .profile .edit {
    width: auto;
    font-size: 16px;
    font-weight: 600;
    background: #419fd9;
    color: #fff;
    border-radius: 4px;
    padding: 10px;
}

.profile .edit {
    margin-left: 10px;
    background: #FFF;
    color: #999;
}

.profile .edit:hover {
    color: #419fd9;
}

.second ul {
    float: none;
    margin-left: -7px;
    list-style-type: none;
}

.second ul li {
    margin: 7px;
}

.second .blue {
    color: #419fd9
}

.second label {
    display: block;
    clear: both;
}

.second .information {
    margin-bottom: 30px;
}

.check {
    position: relative;
    float: left;
    display: block;
    width: 38px;
    height: 14px;
    background: #BBB;
    cursor: pointer;
    border-radius: 15px;
    transition: all 0.2s ease-in-out;
}

.check > .tracer {
    width: 16px;
    height: 16px;
    background: #FFF;
    border: 2px solid #BBB;
    border-radius: 100%;
    float: left;
    margin-top: -3px;
    transition: all 0.2s ease-in-out;
}

#checkNight, #deskNotif, #showSName, #showPreview, #playSounds {
    display: none;
}

.toggleTracer:checked ~ .check {
    background: #419fd9;
}

.toggleTracer:checked ~ .check > .tracer {
    border-color: #419fd9;
    margin-left: 18px;
}

.optionWrapper {
    display: block;
    width: 100%;
    height: 32px;
}

.optionWrapper p {
    float: left;
    margin-top: 3px;
    margin-left: 15px;
    font-size: 14px;
    color: #444;
}

/* dark overlay element */
.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2;
    display: none;
}

/* ----------------- */
/* MAIN APP ELEMENTS */
/* ----------------- */

.leftPanel {
    position: relative;
    display: inline-block;
    left: 0px;
    width: 320px;
    height: 100vh;
    float: left;
    background: #FFF;
    border-right: 1px solid #DDD;
}

header {
    width: 100%;
    background: #FFF;
    padding-left: 15px;
    padding-right: 15px;
    input {
        width: 100% !important;
        outline: none !important;
        border: none !important;
    }
    .chats__sides {
        display: flex;
        width: 100%;
        padding: 10px 0;
        button {
            flex: 1;
            padding: 10px 0;
            outline: none;
            transition: 0.2s;
            border: 1px solid rgba(0,0,0, 0.1);
            background: transparent;
            &.active {
                background: #00c3bc !important;
                color: #fff !important;
            }
            &:hover {
                opacity: 0.7;
            }
        }
        button + button {
            margin-left: 10px;
        }
    }
}

.searchChats {
    width: calc(100% - 85px);
    height: 40px;
    background: #EEE;
    float: left;
    padding: 8px;
    margin-top: 14px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
}

.searchChats:focus {
    color: #444;
    border: 2px solid #419fd9;
    background: rgb(243, 243, 243);
}

.chats {
    width: 100%;
    height: calc(100vh - 70px);
    float: left;
    overflow-x: hidden;
    overflow-y: auto;
}

.chatButton {
    float: left;
    position: relative;
    width: 100%;
    height: 80px;
    background: #FFF;
    color: #555;
    cursor: pointer;
    overflow: hidden;
}

.chatButton:hover {
    color: #555;
    background: #EEE;
}

.active, .active:hover {
    color: #FFF;
    background: #00c3bc;
}

.chatInfo {
    float: left;
}

.chatInfo p {
    float: left;
}

.chatInfo .image {
    position: absolute;
    width: 55px;
    height: 55px;
    background: #DDD url(https://pbs.twimg.com/profile_images/378800000822867536/3f5a00acf72df93528b6bb7cd0a4fd0c.jpeg) no-repeat center;
    background-size: cover;
    border-radius: 100%;
    margin: 13px 13px;
}

.chatInfo .my-image {
    background-image: url(https://avatars1.githubusercontent.com/u/21313332?s=460&v=4);
}

.chatInfo .name {
    float: left;
    font-weight: 600;
    margin-left: 80px;
    margin-top: 18px;
}

.chatInfo .message {
    float: left;
    clear: left;
    margin-left: 80px;
    margin-top: 7px;
    max-width: 58%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.status {
    width: 60px;
    height: 100%;
    position: relative;
    float: right;
}

.status .fixed {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: none;
}

div[class~="normal"] > .fixed {
    display: none;
}

div[class~="normal"] > .count {
    right: 10px;
    bottom: 7px;
}

div[class~="onTop"] > .fixed {
    display: block;
    width: 24px;
    height: 24px;
    fill: #FFF;
}

div[class~="onTop"] > .count {
    right: 49px;
    bottom: 7px;
    background: #FFF;
    color: #419fd9;
}

.status .count {
    position: absolute;
    right: 49px;
    bottom: 7px;
    width: auto;
    height: auto;
    padding: 7px;
    border-radius: 20px;
    color: #222;
    background: rgba(0, 0, 0, 0.12);
}

.status .date {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 12px;
}

.status .read {
    position: absolute;
    right: 53px;
    top: 6px;
}

.rightPanel {
    float: left;
    width: calc(100% - 321px);
    height: 100vh;
    background: #fff;
}

.rightPanel .topBar {
    position: relative;
    width: 100%;
    height: 70px;
    background: #FFF;
}

.leftSide {
    display: inline-block;
    clear: none;
    float: left;
}

.leftSide .chatName {
    float: left;
    margin-left: 30px;
    margin-top: 13px;
    color: #444;
    font-weight: 600;
    cursor: default;
}

.chatName > span {
    font-size: 12px;
    font-weight: 500;
    color: #BBB;
    margin-left: 10px;
}

.leftSide .chatStatus {
    float: left;
    clear: left;
    margin-left: 30px;
    margin-top: 5px;
    color: #419fd9;
    font-weight: 300;
    cursor: default;
}

.rightSide {
    display: inline-block;
    clear: none;
    float: right;
    margin-right: 20px;
}

.tbButton, .otherTools .toolButtons {
    width: 50px;
    height: 50px;
    background: #FFF;
    color: #888;
    border-radius: 100%;
}

.tbButton:hover, .otherTools .toolButtons:hover {
    color: #555;
    background: #DDD;
}


/* THE CONVERSATION HISTORY CSS */

.convHistory {
    float: left;
    position: relative;
    width: 100%;
    height: calc(100vh - 140px);
    background: #333;
    overflow-x: hidden;
    overflow-y: auto;
}

.userBg {
    background: #dfdfdf;
}
.msg {
    position: relative;
    width: auto;
    min-width: 100px;
    max-width: 45%;
    height: auto;
    padding: 25px;
    padding-bottom: 35px;
    margin: 20px 15px;
    margin-bottom: 0px;
    background: #FFF;
    border-radius: 7px;
    clear: both;
}

.msg:nth-last-child(1) {
    margin-bottom: 20px;
}

.msg .timestamp {
    display: block;
    position: absolute;
    right: 10px;
    bottom: 6px;
    color: #AAA;
    user-select: none;
}

.messageReceived {
    float: left;
    background: #FFF;
}

.messageSent {
    float: right;
    background: #effdde;
}

.messageSent > .timestamp, .messageSent > .readStatus {
    bottom: 10px;
    right: 10px;
    color: darkgreen;
    user-select: none;
}

.messageSent > .readStatus {
    position: absolute;
    bottom: 10px;
    right: 12px;
}


/* THE REPLY BAR CSS */

.replyBar {
    width: 100%;
    height: 70px;
    float: left;
    background: #fff;
}

.replyBar .attach {
    width: 70px;
    height: 70px;
    color: #777;
    background: #FFF;
    float: left;
}

.replyBar .attach:hover {
    color: #555;
    background: #DDD;
}

.replyBar .d45 {
    transform: rotate(45deg);
    font-size: 32px;
}

.replyBar .replyMessage {
    width: calc(100% - 220px);
    float: left;
    height: 70px;
    padding: 0px 8px;
    font-size: 16px;
}

.replyBar .otherTools {
    float: right;
    width: 120px;
    padding-right: 20px;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.emojiBar {
    display: none;
    position: absolute;
    width: 325px;
    height: 200px;
    padding: 10px;
    right: 30px;
    bottom: 80px;
    border: 2px solid #DDD;
    border-radius: 3px;
    background: #FFF;
}

.emoticonType {
    width: 100%;
    height: 50px;
}

.emoticonType button {
    width: 105px;
    height: 36px;
    font-weight: 600;
    color: #555;
    background: none;
}

.emoticonType button:hover {
    color: #FFF;
    background: #419fd9;
}

.emojiList, .stickerList {
    width: 100%;
    height: 150px;
    overflow-x: hidden;
    overflow-y: auto;
}

.emojiList .pick {
    width: 50px;
    height: 50px;
    background: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
    transition: all 0.15s ease-in-out;
}

.emojiList .pick:hover {
    background: #DDD;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
}

.stickerList .pick {
    width: 80px;
    height: 80px;
    background: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65%;
    transition: all 0.15s ease-in-out;
}

.stickerList .pick:hover {
    background: #DDD;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65%;
}


/* hidden, while not complete */
.stickerList {
    display: none;
}

/* CSS FOR THE EMOJI IMAGES, JUST SOME */

#smileface {
    background-image: url(https://emojipedia-us.s3.amazonaws.com/thumbs/120/apple/96/grinning-face_1f600.png);
}

#grinningface {
    background-image: url(https://emojipedia-us.s3.amazonaws.com/thumbs/120/apple/96/grinning-face-with-smiling-eyes_1f601.png);
}

#tearjoyface {
    background-image: url(https://emojipedia-us.s3.amazonaws.com/thumbs/120/apple/96/face-with-tears-of-joy_1f602.png);
}

#rofl {
    background-image: url(https://emojipedia-us.s3.amazonaws.com/thumbs/120/apple/96/rolling-on-the-floor-laughing_1f923.png);
}

#somface {
    background-image: url(https://emojipedia-us.s3.amazonaws.com/thumbs/120/apple/96/smiling-face-with-open-mouth_1f603.png);
}

#swfface {
    background-image: url(https://emojipedia-us.s3.amazonaws.com/thumbs/120/apple/96/smiling-face-with-open-mouth-and-smiling-eyes_1f604.png);
}

/* NIGHT MODE THEMING */
.DarkTheme,
.DarkTheme .mainApp {
    background: #222;
}

/* SOLVING RESPONSIVE DESIGN ISSUES */
@media screen and (max-width: 1180px) {
    .config {
        width: 60%;
    }
}

@media screen and (max-width: 980px) {
    .config {
        width: 90%;
    }
}

@media screen and (max-width: 940px) {
    body {
        font-size: 14px;
    }

    .msg {
        width: 90%;
    }
}

@media screen and (max-width: 720px) {
    .leftPanel {
        width: 80px;
    }

    .rightPanel {
        width: calc(100% - 81px);
    }

    .msg {
        width: auto;
        max-width: 60%;
    }

    .profile .changePic, .profile .edit {
        font-size: 14px;
        font-weight: 500;
    }

    .profile .edit {
        margin-left: 5px;
    }

    .searchChats,
    .chatButton > .name,
    .chatButton > .message,
    .chatButton > .status {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .switchMobile {
        display: block;
    }

    .leftPanel, .rightPanel, .menuWrap, .config, .overlay {
        display: none;
    }
}

@media screen and (max-width: 300px) {
    .switchMobile {
        width: calc(220px - 20px);
    }
}
</style>
