<template>
	<div v-if="season && (clanData || teamData)">
		<div class="page__content-header with-prev-button">
			<div>
				<RouterLink
					:to="getPrevLink()"
					class="prev-button"
				>
					<span>
						<SvgIcon
							width="24"
							height="24"
							:iconType="'arrow-left'"
						/>
					</span>
					<span>{{getPrevLinkText()}}</span>
				</RouterLink>
			</div>
		</div>
		<div class="table-wrapper">
			<table v-if="getFilteredMates.length">
				<thead class="text-left">
				<tr>
					<th scope="col">{{$t('Position')}}</th>
					<th scope="col">{{$t('ID')}}</th>
					<th scope="col">{{$t('User Email')}}</th>
					<th scope="col">{{$t('Name')}}</th>
					<th scope="col">{{$t('Nickname')}}</th>
					<th scope="col">{{$t('Locale')}}</th>
                    <th scope="col">{{$t('Role')}}</th>
                    <th scope="col">{{$t('Points')}}</th>
                    <th scope="col">{{$t('Actions')}}</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(mate, index) in getFilteredMates">
					<td scope="row">{{index + 1}}</td>
					<td scope="row">{{ mate.user_id }}</td>
					<td class="row">{{mate.profile.user.email}}</td>
					<td class="row">{{mate.profile.user.name}}</td>
					<td class="row">
						<span>{{mate.profile.user.nickname}}</span>
						<span v-if="isOwner(mate.user_id)">({{$t('Owner')}})</span>
					</td>
                    <td class="row">{{mate.profile.user.locale}}</td>
                    <td class="row">{{mate.role}}</td>
					<td class="row" v-if="points[mate.user_id]">{{ points[mate.user_id] }}</td>
					<td class="row" v-else>0</td>
                    <td>
                        <a target="_blank" :href="`/chats?start=${mate.user_id}`" class="send_message">
                            <svg width="18" height="14" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7266 3.30729C13.0977 3.77604 12.3047 4.34896 9.51562 6.27604C8.96875 6.66667 7.95703 7.52604 7 7.52604C6.01562 7.52604 5.03125 6.66667 4.45703 6.27604C1.66797 4.34896 0.875 3.77604 0.246094 3.30729C0.136719 3.22917 0 3.30729 0 3.4375V8.75C0 9.45313 0.574219 10 1.3125 10H12.6875C13.3984 10 14 9.45313 14 8.75V3.4375C14 3.30729 13.8359 3.22917 13.7266 3.30729ZM7 6.66667C7.62891 6.69271 8.53125 5.91146 8.99609 5.59896C12.6328 3.09896 12.9062 2.86458 13.7266 2.23958C13.8906 2.13542 14 1.95313 14 1.74479V1.25C14 0.572917 13.3984 0 12.6875 0H1.3125C0.574219 0 0 0.572917 0 1.25V1.74479C0 1.95313 0.0820312 2.13542 0.246094 2.23958C1.06641 2.86458 1.33984 3.09896 4.97656 5.59896C5.44141 5.91146 6.34375 6.69271 7 6.66667Z" fill="#8F8F8F"/>
                            </svg>
                        </a>
                    </td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
	import {mapGetters, mapActions, mapMutations} from 'vuex'

	import PageLinks from '../../components/Page/Links'
	import SvgIcon from '../../components/SvgIcon'

	export default {
		props: ["id","team"],
		components: {
			SvgIcon,
			PageLinks
		},
		data() {
			return {
				current: {},
				points: {},
				mates: []
			}
		},
		computed: {
            getFilteredMates () {
              return this.mates.filter(item => item.status && item.role)
            },
			...mapGetters({
				season: 'season',
				teamData: 'team',
				clanData: 'clan',
				leaderboards: 'leaderboards',
				platforms: 'platforms'
			}),
			getRouteName() {
				return this.$route.name;
			}
		},
		beforeMount() {
			this.updateLeaderBoardData([]);
		},
		mounted() {
			this.initData();
		},
		methods: {
			...mapActions({
				getSeason: 'getSeason',
				getTeam: 'getTeam',
				getClan: 'getClan',
				getLeaderBoards: 'getLeaderBoards',
				getPlatforms: 'GET_PLATFORMS',
			}),
			...mapMutations({
				updateLeaderBoardData: 'UPDATE_LEADERBOARDS'
			}),
			async initData() {
				const points = {};
				let users = [];
				await this.getSeason(this.id);

				if (this.isClanRoute()) {
					await this.getClan(this.team);
					this.current = this.clanData;
					this.mates = this.current.clan_mates;
				} else {
					await this.getTeam(this.team);
					this.current = this.teamData;
					this.mates = this.current.team_mates;
				}

				this.mates.forEach(item => users.push(item.user_id));

				await this.getLeaderBoards({
					type: 'solo',
					data: {
						users: users.join(','),
						season_id: this.id,
					}
				});

				this.leaderboards.forEach(item => {
					points[item.user.id] = item.points ? item.points : 0;
				});

				this.points = points;
			},
			getPrevLink() {
				if (this.getRouteName === 'season-team-mates') {
					return `/season/${this.id}/team`;
				}

				if (this.getRouteName === 'season-clan-mates') {
					return `/season/${this.id}/clan`;
				}

				return '/seasons'
			},
			getPrevLinkText() {
				return this.current.name ? this.current.name : '';
			},
			isClanRoute() {
				return this.getRouteName === 'season-clan-mates';
			},
			isOwner(id) {
				return this.current.creator_id === id
			},
			getPoints() {

			}
		},
	}
</script>
