<template>
	<div>
		<div class="page__content-header">
			<div>
				<PageLinks v-bind:links="[
					{
						url: '/countries',
						text: $t('All Countries')
					}
				]"/>
			</div>
		</div>
		<div>
			<div class="table-wrapper">
				<table v-if="countries">
					<thead class="text-left">
					<tr>
						<th scope="col">{{$t('Code')}}</th>
						<th scope="col">{{$t('Name')}}</th>
						<th scope="col">{{$t('Supported Locales')}}</th>
						<th scope="col"></th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="country in countries">
						<td scope="row">{{ country.code }}</td>
						<td>{{ country.value }}</td>
						<td>{{ country.supported_locales.join(',') }}</td>
						<td v-if="!isTournamentManager">
							<Dropdown
								:data-position="'left'"
								:data-horizontal-aligntment="'left'"
								:data-vertical-aligntment="'top'"
								:toggle-class="'dots-toggle'"
							>
								<SvgIcon
									slot="dropdown-toggle"
									width="23"
									height="5"
									view-box="0 0 23 5"
									:iconType="'dots'"
								/>
								<div slot="dropdown-content">
									<RouterLink
										:class="'dropdown__link'"
										:to="{ path: '/countries/edit/' +  country.id }"
									>
										<SvgIcon
											width="16"
											height="16"
											:iconType="'edit'"
										/>
										<span>{{$t('Edit')}}</span>
									</RouterLink>
								</div>
							</Dropdown>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<PagePagination
				v-if="countriesMeta"
				:current="countriesMeta.currentPage()"
				:total="countriesMeta.lastPage()"
				@change="changePage"
			/>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

	import SvgIcon from '../../components/SvgIcon';
	import Dropdown from '../../components/Dropdown';
	import PagePagination from '../../components/Page/Pagination';
	import PageLinks from '../../components/Page/Links';

	export default {
		components: {
			SvgIcon,
			Dropdown,
			PageLinks,
			PagePagination,
		},
		computed: {
			...mapGetters([
				'countries',
				'countriesMeta',
				'isTournamentManager',
			])
		},
		created () {
			this.init();
		},
		methods: {
			...mapActions({
				getCountries: 'getCountries',
			}),
			init () {
				this.getCountries();
			},
			changePage(page) {
				this.getCountries({
					page: page
				});
			},
		},
	}
</script>
