<template>
	<div class="chats-list-container">
		<ChatsList v-if="!hideAdminCalls"/>
		<ChatsContainer/>
	</div>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'

	import ChatsList from '../../components/Chat/ChatsList'
	import ChatsContainer from '../../components/Chat/ChatsContainer'

	export default {
		name: 'ChatsListContainer',
		props: {
            id: {},
            hideAdminCalls: {
                type: Boolean,
                default: false
            }
        },
		components: {
			ChatsContainer,
			ChatsList
		},
		computed: {
			...mapGetters({
				chatTournament: 'chatTournament'
			})
		},

		methods: {
			...mapActions({
				chatsInit: 'CHAT_TOURNAMENT_INIT',
				getChatsLists: 'GET_CHATS_LIST'
			})
		}
	};
</script>
