export default function Participant(data = null) {
	const info = data;

	this.isType = (type) => {
		if (type === 'team') {
			return info.type === 2
		}

		if (type === 'single') {
			return info.type === 1
		}

		return false;
	};

    this.getProfile = function () {
        return info.profile;
    };

	this.getType = function () {
		return info.type;
	};

	this.getTeamMatesCount = function () {
		return info.type === 2 && null !== info.team ? info.team.team_mates_count : 0;
	};

	this.getMaxPlayers = function () {
		return info.type === 2 && null !== info.team ? info.team.max_players : 0;
	};

	this.getId = function () {
		return info.id;
	};

	this.getUserId = function () {

		if (info.type === 2) {
			return null !== info.team ? info.team.id : null;
		}

		return info.profile.user.id;
	};

	this.getCreatorId = function () {
		return info.type === 2 && null !== info.team ? info.team.creator_id : null;
	};

	this.getStatus = function () {
		return info.status;
	};

	this.getName = function () {
		if (info.type === 2) {
			return null !== info.team && null !== info.team.name ? info.team.name : 'TEAM DELETED'
		}

		return info.profile.user.name;
	};

    this.getTournamentPosition = function () {
        return info.tournament_position
    };

	this.getNickName = function () {
		return info.nickname;
	};

	this.getUserEmail = function () {
		if (info.type === 2) {
			return 'No';
		}

		return info.profile.user.email;
	};

	this.getUserAvatar = function () {

		if (info.type === 2) {
			return null !== info.team && null !== info.team.logo ? info.team.logo : '/images/logo.jpg'
		}

		return info.profile.user.avatar ? info.profile.user.avatar : '/images/logo.jpg';
	};

	this.getUserName = function () {
		if (info.type === 2) {
			return null !== info.team && null !== info.team.name ? info.team.name : 'TEAM DELETED'
		}

		return info.profile.user.name;
	};

	this.getUserNickName = function () {
		return info.type === 2 && null !== info.team ? info.team.name : info.profile.user.nickname;
	};

	this.getPlatformNickname = function () {
		return info.type !== 2 ? info.profile.info.nickname : null;
	};

	this.getPlatformId = function () {
		return info.type !== 2 ? info.profile.info.id : null;
	};

	this.getTeamMates = function () {
		return info.type === 2 && null !== info.team ? info.team.team_mates : [];
	};

    this.getTeam = function () {
        return info.team
    }

    this.getTeamId = function () {
        return info.type === 2 && null !== info.team ? info.team.id : null;
    }

	this.exists = function () {
		return info.id !== null;
	};

	this.isEmpty = function () {
		return info === null;
	};
}
