import { getConfig } from '../../api/config'
const state = () => ({
	showLoader: false,
	showPanel: true,
	navigationIsClosed: true,
	showDropdown: false,
	isMobile: false,
    loaderOff: false,
    config: null
});

const getters = {
    config: state => state.config,
    getConfig: state => (config_name) => {
      const find = state.config.find(item => item.config_name === config_name)
      if (find) {
          return find.config_value
      }

      return false
    },
	showLoader: state => state.showLoader,
	showPanel: (state, getters, rootState) => {
		return getters.isLoggedIn && !rootState.brackets.isPublicBrackets;
	},
	navigationIsClosed: state => state.navigationIsClosed,
	isMobile: state => state.isMobile,
};

const actions = {
    async getConfig ({ commit }) {
        return await getConfig().then(async response => {
            await commit('SET_CONFIG', response.data.data)
        })
    }
};

const mutations = {
	SHOW_LOADER: (state, value) => {
        if (!state.loaderOff) {
            state.showLoader = value;
        }
	},
    DISABLED_LOADER (state) {
        state.loaderOff = true
    },
    ENABLED_LOADER (state) {
        state.loaderOff = false
    },
	TOGGLE_NAVIGATION: (state, value) => {
		state.navigationIsClosed = !state.navigationIsClosed
	},
	HIDE_NAVIGATION: (state) => {
		state.navigationIsClosed = true;
	},
	HIDE_DROPDOWN: (state) => {
		state.showDropdown = false;
	},
	SET_IS_MOBILE: (state, value) => {
		state.isMobile = value;
	},
    SET_CONFIG (state, config) {
        state.config = config;
    }
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
