<template>
    <div>
        <template v-if="!type || type !== 6">
            <template v-if="brackets.length && brackets[0] && brackets[0].games[0].tournament.type === 3">
                <div className="bracket__type">
                    upper bracket
                    <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.85739 9.7448C8.2079 10.0851 8.7921 10.0851 9.14261 9.7448L16.7371 2.41021C17.0876 2.03214 17.0876 1.46503 16.7371 1.12476L15.8414 0.255199C15.4908 -0.0850665 14.9066 -0.0850665 14.5172 0.255199L8.48053 6.0775L2.48282 0.255199C2.09336 -0.0850659 1.50916 -0.0850659 1.15865 0.255199L0.262886 1.12476C-0.0876292 1.46503 -0.0876292 2.03214 0.262886 2.41021L7.85739 9.7448Z"
                            fill="#06C2BC"/>
                    </svg>
                </div>
                <Brackets :id="id" type="upper" :is-public="true"/>
                <div className="bracket__type">
                    lower bracket
                    <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.85739 9.7448C8.2079 10.0851 8.7921 10.0851 9.14261 9.7448L16.7371 2.41021C17.0876 2.03214 17.0876 1.46503 16.7371 1.12476L15.8414 0.255199C15.4908 -0.0850665 14.9066 -0.0850665 14.5172 0.255199L8.48053 6.0775L2.48282 0.255199C2.09336 -0.0850659 1.50916 -0.0850659 1.15865 0.255199L0.262886 1.12476C-0.0876292 1.46503 -0.0876292 2.03214 0.262886 2.41021L7.85739 9.7448Z"
                            fill="#06C2BC"/>
                    </svg>
                </div>
                <Brackets :id="id" type="lower" :is-public="true"/>
            </template>
            <template v-else>
                <Brackets :id="id" :is-public="true"/>
            </template>
        </template>
        <template v-else>
            <Brackets :tft="true" :id="id" :is-public="true"/>
        </template>
    </div>
</template>

<script>
import Brackets from '../../components/Brackets/Brackets'
import TournamentService from '../../api/tournament'
import TftPublicBrackets from './TftPublicBrackets'
import {mapActions, mapGetters} from 'vuex';

export default {
    data() {
        return {
            type: null,
        }
    },
    computed: {
        ...mapGetters({
            brackets: 'brackets',
        })
    },
    methods: {
        ...mapActions({
            centrifugeConnect: 'centrifugeConnect',
            getTftPublicBrackets: 'fetchTftPublicBrackets',
            getPublicBrackets: 'fetchPublicBrackets',
        }),
    },
    async mounted() {
        window.location.href = `/webview/tournament/${this.id}`;

        await TournamentService.getTournamentType(this.id).then(response => {
            this.type = response.data.type
            this.$root.type = this.type
        });
        if (!this.type) {
            await this.getPublicBrackets(this.id)
        } else {
            await this.getTftPublicBrackets(this.id)
        }
    },
    props: {
        id: String
    },
    components: {
        TftPublicBrackets,
        Brackets
    }
}
</script>
<style scoped lang="scss">
.bracket__type {
    background: #ECECEC;
    border-radius: 4px;
    margin-top: 30px;
    padding: 14px 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
    color: #303030;
}
</style>
