import http from '../utils/http'

export default {
	get(data) {
		return http.get('/propaganda', data)
	},
	addNotification(data) {
		return http.post('/propaganda', data)
	},
	sendMessage(data) {
		return http.post('/notification-requests', data)
	},
}