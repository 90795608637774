var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.season)?_c('div',[_c('div',{staticClass:"page__content-header with-prev-button"},[_c('div',[_c('RouterLink',{staticClass:"prev-button",attrs:{"to":"/seasons"}},[_c('span',[_c('SvgIcon',{attrs:{"width":"24","height":"24","iconType":'arrow-left'}})],1),_c('span',[_vm._v(_vm._s(_vm.season.title))])])],1),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('FiltersBar',{attrs:{"options":_vm.filters,"onChange":_vm.filtersChanged}}),_c('div',{staticClass:"filter__country-leaderboard"},[_c('FormMultiSelect',{attrs:{"loading":_vm.multiSelectCountryOptions.loading,"placeholder":_vm.$t('Choose country'),"label":_vm.multiSelectCountryOptions.label,"track-by":_vm.multiSelectCountryOptions.trackBy,"multiple":_vm.multiSelectCountryOptions.multiple,"taggable":_vm.multiSelectCountryOptions.taggable,"options":_vm.countries},on:{"search-change":_vm.onSearchChangeCountry},model:{value:(_vm.country_id),callback:function ($$v) {_vm.country_id=$$v},expression:"country_id"}})],1)],1)]),_c('div',[_c('PageLinks',{attrs:{"links":[
					{
						url: '/season/' +  _vm.season.id + '/solo',
						text: _vm.$t('Solo leaderboard')
					},
					{
						url: '/season/' +  _vm.season.id + '/team',
						text: _vm.$t('Team leaderboard')
					},
					{
						url: '/season/' +  _vm.season.id + '/clan',
						text: _vm.$t('Clan leaderboard')
					}
				]}})],1),_c('div',{staticClass:"table-wrapper"},[(_vm.leaderboards.length)?_c('table',[_c('thead',{staticClass:"text-left"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Position')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('ID')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Clan name')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Clan size')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Points')))])])]),_c('tbody',_vm._l((_vm.leaderboards),function(leaderboard,index){return _c('tr',[_c('td',{staticClass:"row cursor-pointer",on:{"click":function($event){return _vm.goToPage(`/season/${_vm.season.id}/clan/${leaderboard.team.id}`)}}},[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"row cursor-pointer",on:{"click":function($event){return _vm.goToPage(`/season/${_vm.season.id}/clan/${leaderboard.team.id}`)}}},[_vm._v(_vm._s(leaderboard.team.id))]),_c('td',{staticClass:"row cursor-pointer",on:{"click":function($event){return _vm.goToPage(`/season/${_vm.season.id}/clan/${leaderboard.team.id}`)}}},[_vm._v(_vm._s(leaderboard.team.name))]),_c('td',{staticClass:"row cursor-pointer",on:{"click":function($event){return _vm.goToPage(`/season/${_vm.season.id}/clan/${leaderboard.team.id}`)}}},[_vm._v(_vm._s(leaderboard.team.clan_mates_count)+"/"+_vm._s(leaderboard.team.max_players))]),_c('td',{staticClass:"row cursor-pointer",on:{"click":function($event){return _vm.goToPage(`/season/${_vm.season.id}/clan/${leaderboard.team.id}`)}}},[_vm._v(_vm._s(leaderboard.points))])])}),0)]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }