import http from "../utils/http";

export default {
    login(data) {
        return http.post('/login', data)
    },
    user() {
        return http.get('/users/me');
    },
    getWsToken(id) {
        return http.get(`/users/${id}/ws-token`);
    }
}
