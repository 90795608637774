import teams from '../../api/teams'

const state = () => ({
	team: null,
});

const getters = {
	team: state => state.team
};

const actions = {
	getTeam: async ({commit}, id) => {
		const response = await teams.getTeam(id);

		commit('UPDATE_TEAM', response.data.data);
	}
};

const mutations = {
	UPDATE_TEAM: (state, data) => {
		state.team = data;
	}
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
