<template>
	<div>
		<PageLinks :links="[
			{
				url: id ? '/rule/edit/' +  id : '/rule/add',
				text: id ? $t('Edit Rule') : $t('Add Rule'),
				classes: 'active'
			}
		]"/>
		<form class="form form--xl">
			<div class="form-group">
				<FormInput
					:name="'title'"
					:label="$t('Title')"
					:placeholder="$t('Name')"
					:value="form.title"
					:formErrors="errors.title"
					:onChange="formFieldChanged"
				/>
			</div>


            <div class="form-group" v-if="games">
                <span class="form-label">{{$t('Games')}}</span>
                <FormMultiSelect
                    v-model="form.game_id"
                    :placeholder="$t('Games')"
                    :options="games"
                    :loading="multiSelectOptions.loading"
                    :label="multiSelectOptions.label"
                    :track-by="multiSelectOptions.trackBy"
                    :multiple="multiSelectOptions.multiple"
                    :taggable="multiSelectOptions.taggable"
                />
                <FormNotification :errors="errors.games"/>
            </div>

			<div class="form-group">
				<div class="form-input-field">
					<label>
						<span class="form-label">{{$t('Content')}}</span>

					</label>
                    <vue-editor
                        class="form-text-editor"
                        :placeholder="$t('Content')"
                        v-model="form.content"
                    />
					<FormNotification :errors="errors.title"/>
				</div>
			</div>
			<FormNotification :errors="errors.message"/>
			<button
				class="button button-primary form-button-submit"
				@click.prevent="saveRule()"
			>
				{{$t('Save')}}
			</button>
		</form>
	</div>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'
    import FormMultiSelect from 'vue-multiselect'
	import router from '../../routes'
	import SvgIcon from '../../components/SvgIcon'
	import PageLinks from '../../components/Page/Links'
	import FormNotification from '../../components/Form/Notification'
	import FormInput from '../../components/Form/Input'
	import {VueEditor} from 'vue2-editor'

	import helpers from '../../helpers'

	export default {
		props: ["id"],
		components: {
			SvgIcon,
			PageLinks,
            FormMultiSelect,
			FormNotification,
			FormInput,
			VueEditor
		},
		computed: {
			...mapGetters([
				'rule',
                'games'
			])
		},
		data() {
			return {
                multiSelectOptions: {
                    loading: false,
                    label: 'name',
                    trackBy: 'id',
                    multiple: false,
                    taggable: true,
                },
				form: {
                    game_id: null,
					title: '',
					content: '',
				},
				errors: {},
				defaultError: {
					message: [
						this.$t('Something went wrong. Please try again.')
					]
				}
			};
		},
		created() {
            this.getGames();
			this.getFormData();
		},
		watch: {
			$route() {
				this.getFormData();
			},
		},
		methods: {
			...mapActions({
                getGames: 'getGames',
				getRule: 'GET_RULE',
				addRule: 'ADD_RULE',
				updateRule: 'UPDATE_RULE',
			}),
			formFieldChanged(field, value) {
				this.form[field] = value;
			},
			async getFormData() {
				if (this.id) {
					await this.getRule(this.id);
					let content = helpers.removeRulesStyles(this.rule.getContent());

					this.form = {
						title: this.rule.getTitle(),
						content: content,
                        game_id: this.rule.getGame()
					};
				}
			},
			saveRule() {
				!this.id ? this.newRule() : this.updateExistRule();
			},
			async newRule() {
				const data = this.form;
                data.game_id = data.game_id ? data.game_id.id : 0;
				data.content = helpers.injectRulesStyles(data.content);

				await this.addRule(data)
					.then(resp => router.push('/rule/' + resp.data.data.id))
					.catch(err => this.errorsHandler(err.response.data.errors));
			},
			updateExistRule() {
				const data = this.form;
                data.game_id = data.game_id ? data.game_id.id : 0;
                data.content = helpers.injectRulesStyles(data.content);

				this.updateRule({
					id: this.id,
					data: data
				})
					.then(() => router.push('/rule/' + this.id))
					.catch(err => this.errorsHandler(err.response.data.errors));
			},
			errorsHandler(error) {
				this.errors = error ? error : this.defaultError;
			},
		}
	}
</script>
