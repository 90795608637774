import http from '../utils/http'
import joinUrls from '../utils/join-urls'

export default {
	getAll(id, withResults = false) {
		if (!id) {
			return Promise.reject(new Error('Tournament id is required to get match'));
		}

		return http.get(joinUrls('/tournaments', id, 'matches?with_tournament=true' + (withResults ? '&withResults=true' : '')));
	},
	get(id, matchId, withResults = false) {
		if (!id && !matchId) {
			return Promise.reject(new Error('Tournament id and Match id are required to get match'));
		}

		return http.get(joinUrls('/tournaments', id, 'matches', matchId) + (withResults ? '?withResults=true' : ''));
	},
	finish(id, matchId, data) {
		if (!id && !matchId) {
			return Promise.reject(new Error('Tournament id and Match id are required to finish match'));
		}

		return http.post('/tournaments/' + id + '/matches/' + matchId + '/finish', data);
	},
    setParticipant (id, matchId, data) {
        if (!id && !matchId) {
            return Promise.reject(new Error('Tournament id and Match id are required to finish match'));
        }

        return http.put('/tournaments/' + id + '/matches/' + matchId + '/set-participant', data);
    },
	update(id, matchId, data) {
		if (!id && !matchId) {
			return Promise.reject(new Error('Tournament id and Match id are required to update match'));
		}

		return http.put(joinUrls('/tournaments', id, 'matches', matchId), data);
	},
	advancedUpdate(id, matchId, data) {
		if (!id && !matchId) {
			return Promise.reject(new Error('Tournament id and Match id are required to update match'));
		}

		return http.put(joinUrls('/tournaments', id, 'matches', matchId, 'advanced'), data);
	},
	getResult(id, matchId) {
		if (!id && !matchId) {
			return Promise.reject(new Error('Tournament id and Match id are required to get match result'));
		}

		return http.get(joinUrls('/tournaments', id, 'matches', matchId, 'result'));
	},
	getMessages(id, matchId) {
		if (!id && !matchId) {
			return Promise.reject(new Error('Tournament id and Match id is required to get messages'))
		}

		return http.get(joinUrls('/tournaments', id, 'matches', matchId, 'messages',))
	},
	revert(id, matchId) {
		if (!id && !matchId) {
			return Promise.reject(new Error('Tournament id and Match id is required to revert match'))
		}

		return http.post(joinUrls('/tournaments', id, 'matches', matchId, 'revert',))
	},
	recreate(id, matchId) {
		if (!id && !matchId) {
			return Promise.reject(new Error('Tournament id and Match id is required to revert match'))
		}

		return http.post(joinUrls('/tournaments', id, 'matches', matchId, 'game-recreate',))
	},
	sendMessage(id, matchId, message) {
		if (!id && !matchId) {
			return Promise.reject(new Error('Tournament id and Match id is required to send message'))
		}

		const data = {
			"message": message
		};

		return http.post(joinUrls('/tournaments', id, 'matches', matchId, 'messages',), data)
	},
	getTimings(id) {
		if (!id) {
			return Promise.reject(new Error('Tournament id is required to get timings data'))
		}

		return http.get(joinUrls('/tournaments', id, 'matches', 'timings'));
	},
	updateTimings(id, data) {
		if (!id) {
			return Promise.reject(new Error('Tournament id is required to update timings data'))
		}

		return http.put(joinUrls('/tournaments', id, 'matches', 'timings'), {rounds: data})
	}
}
