export default function News( data = null) {
	const info = data;

	this.getId = () => info.id;

	this.getTitle = () => info.title;

	this.getDescription = () => info.description;

    this.getContent = () => info.content;

	this.getDeeplink = () => info.deeplink;

	this.getImage = () => info.image;

	this.getIconImage = () => info.icon_image;

	this.getGame = () => info.game_id;

	this.getDate = () => info.created_at;
}
