export default function Notification( data= null) {
	const info = data;

	this.getId = () => info.id;

	this.getTournamentId = () => info.tournament_id;

	this.getTitle = () => info.title;

	this.getMessage = () => info.message;
}