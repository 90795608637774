<template>
	<div>
		<div class="chats-list" :class="{opened: isOpened}">
			<div class="chats-list__header" @click.default="isOpened = !isOpened">
				<span class="chats-list__header-title">{{$t('Admin Calls')}}</span>
				<span class="chats-list__header-icon" v-if="!isMobile">
				<SvgIcon
					width="14"
					height="14"
					:iconType="'arrow-up'"
					v-if="!isOpened"
				/>
				<SvgIcon
					width="14"
					height="14"
					:iconType="'arrow-down'"
					v-if="isOpened"
				/>
			</span>
			</div>
			<ul class="chats-list__content" v-if="showChatsListContent()">
				<li
					class="chats-list__item"
					:class="{active: chat.is_active}"
					v-for="chat in adminCalls"
					@click="openChat(chat)"
				>
				<span>
					<span class="chats-list__item-name">#{{chat.match_id}}</span>
					<span class="chats-list__item-count" v-if="chat.count > 0">{{chat.count}}</span>
				</span>
				</li>
			</ul>
		</div>
		<div class="chats-list__button" v-if="isMobile" @click.default="toggleState()">
			<SvgIcon
				width="18"
				height="18"
				:viewBox="'0 0 24 24'"
				:iconType="'chat'"
				v-if="!isOpened"
			/>
			<SvgIcon
				width="18"
				height="18"
				:iconType="'slim'"
				v-if="isOpened"
			/>
		</div>
		<div v-if="isMobile && isOpened" class="chats-list__mobile-overlay"></div>
	</div>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'
	import SvgIcon from '../SvgIcon'

	export default {
		name: 'ChatsList',
		components: {
			SvgIcon
		},
		props: [],
		computed: {
			...mapGetters({
				tournament: 'tournament',
				chatTournament: 'chatTournament',
				isMobile: 'isMobile',
                adminCalls: 'adminCalls',
			})
		},
		data() {
			return {
                interval: null,
				isOpened: false
			}
		},
		methods: {
			...mapActions({
                getAdminCalls: 'GET_ADMIN_CALLS',
				showChat: 'OPEN_CHAT'
			}),
			openChat(match) {
				const data = {
					match: match.match_id,
					tournament: this.tournament.getId(),
                    payload: match.payload
				};

				if (this.isMobile) {
					this.toggleState();
				}
				this.showChat(data);
			},
			showChatsListContent() {
				if (this.isMobile) return true;

				return this.isOpened;
			},
			toggleState() {
				this.isOpened = !this.isOpened;
			},
		},
        mounted () {
            this.getAdminCalls(this.tournament.getId());

            this.interval = setInterval(() => {
                this.getAdminCalls(this.tournament.getId())
            }, 4000)
        },
        beforeDestroy() {
            clearInterval(this.interval)
        }
    };
</script>
