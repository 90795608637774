<template>
	<div class="chats-container">
		<div v-for="chat in openedChats" :key="chat.id">
			<Chat :id="chat.id" :payload="chat.payload"/>
		</div>
        <div class="mute-notifications" @click="toggleMuted(notificationMuted)">
            <img src="/images/muted.png" v-if="!notificationMuted" alt="">
            <img src="/images/mute.png" v-else alt="">
        </div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

	import Chat from './Chat'

	export default {
		name: 'ChatsContainer',
		components: {
			Chat
		},
		props: [],
        methods: {
            ...mapActions({
                toggleMuted: 'toggleMuted'
            })
        },
		computed: {
			...mapGetters({
                notificationMuted: 'notificationMuted',
				openedChats: 'openedChats',
			})
		},
	};
</script>
<style scoped lang="scss">
.mute-notifications {
    position: fixed;
    top: 25px;
    cursor: pointer;
    right: 20px;
    background: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.2s;
    border: 2px dashed gray;
    img {
        max-width: 20px;
    }
    &:hover {
        opacity: 0.5;
    }
}
</style>
