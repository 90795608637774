export const API_URL = process.env.VUE_APP_API_URL;
export const CENTRIFUGE_HOST = process.env.VUE_APP_CENTRIFUGE_HOST;
export const DISCORD_URL = process.env.VUE_APP_DISCORD_URL;
export const TWITCH_URL = process.env.VUE_APP_TWITCH_URL;
export const AVATAR = '/images/logo.jpg';
export const TOURNAMENT_ROUTES = [
	'tournament-brackets',
	'tournament',
	'participants'
];
export const STATUSES_BEFORE_START = [
	'OPENED',
	'REGISTRATION_OPENED',
	'CHECKIN',
	'REGISTRATION_CLOSED',
	'PENDING'
];
export const STATUSES = [
	'OPENED',
	'REGISTRATION_OPENED',
	'CHECKIN',
	'REGISTRATION_CLOSED',
	'PENDING',
	'STARTED',
	'IN_PROGRESS',
	'FINISHED',
	'CLOSED'
];

export const ROUNDS_NAMES = [
	'Final',
	'Semifinals',
	'Quarterfinals',
	'Eighth-finals',
	'16th-finals',
	'32nd-finals',
	'64th-finals',
    '256th-finals',
    '512th-finals',
    '1024th-finals',
    '2048th-finals',
    '4096th-finals',
    '8192th-finals',
    '16384th-finals',
    '32768th-finals',
    '65536th-finals',
    '131072th-finals'
];
