<template>
	<div class="filters-bar" >
		<div class="filters-bar__item" v-for="option in options">
			<FormSelect
				v-if="option.type === 'select'"
				:name="option.name"
				:label="option.label ? option.label : ''"
				:value="option.value"
				:options="option.options"
				:onChange="onChange"
			/>
		</div>
	</div>
</template>

<script>
	import FormSelect from './Form/Select'

	export default {
		name: "FiltersBar",
		components: {
			FormSelect
		},
		props: {
			options: {
				type: Array,
				default: []
			},
			onChange: {type: Function},
		}
	};
</script>
