export default {
    computed: {
        $currentUser () {
            return this.$store.getters['getUser']
        }
    },
    methods: {
        hasGame (game_id) {
          if (!this.$currentUser) return
          return this.$currentUser.assignGames.includes(game_id)
        },
        hasRole (role) {
            if (!this.$currentUser) return
            return this.$currentUser.roles.includes(role)
        },
        hasPermission (permission, gameId = null) {
            if (!this.$currentUser) return
            if (gameId) {
                if (this.hasGame(gameId)) {
                    return this.$currentUser.permissions.includes(permission)
                }
                return true
            }
            return this.$currentUser.permissions.includes(permission)
        }
    },
}
