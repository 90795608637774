import http from '../utils/http'
import joinUrls from '../utils/join-urls'
import joinParams from '../utils/join-params';

export default {
	get(data = {}) {
		return http.get('/bracket-templates', data)
	},
	getItem(id) {
		return http.get(joinUrls('/bracket-templates', id))
	},
	add(data) {
		return http.post('/bracket-templates', data)
	},
	update(id, data) {
		return http.put(joinUrls('/bracket-templates', id), data)
	},
	delete(id) {
		return http.delete(joinUrls('/bracket-templates', id))
	}
}
