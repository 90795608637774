import tournaments from "../../api/tournaments";
import Tournament from '../../dto/tournament'
import PageMeta from '../../dto/page-meta'

const state = () => ({
	tournaments: [],
	activeTournaments: [],
	tournamentsMeta: null,
});

const getters = {
	tournaments: state => state.tournaments,
	activeTournaments: state => {
		return state.tournaments.filter(item => item.status !== 'FINISHED');
	},
	tournamentsMeta: state => state.tournamentsMeta
};

const actions = {
	GET_TOURNAMENTS: ({commit}, data = {}) => {
		return new Promise((resolve, reject) => {
			tournaments.get(data)
				.then((response) => {
					commit('UPDATE_TOURNAMENTS', response.data.data);
					commit('UPDATE_TOURNAMENTS_META', response.data.meta);
					resolve(response);
				})
				.catch(err => {
					reject(err)
				});
		});
	}
};

const mutations = {
	UPDATE_TOURNAMENTS: (state, data) => {
		let items = [];

		data.forEach(item => {
			items.push(new Tournament(item));
		});

		state.tournaments = items;
	},
	UPDATE_TOURNAMENTS_META: (state, data) => {
		state.tournamentsMeta = new PageMeta(data);
	}
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}