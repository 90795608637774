<template>
    <FormMultiSelect
        :placeholder="$t('Search participant')"
        :clear="false"
        :options="participants"
        :searchable="true"
        v-model="participant"
        label="room_id"
        :disabled="isDisabled"
        :customLabel="getLabel"
        :selectLabel="''"
        @close="$emit('close', $data)"
        :deselectLabel="'Click for delete'">
        <template slot="selection" slot-scope="props">
            <div class="option__participant" v-if="!participant">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.8086 12.8633L11.0742 10.1289C10.9375 10.0195 10.7734 9.9375 10.6094 9.9375H10.1719C10.9102 8.98047 11.375 7.77734 11.375 6.4375C11.375 3.32031 8.80469 0.75 5.6875 0.75C2.54297 0.75 0 3.32031 0 6.4375C0 9.58203 2.54297 12.125 5.6875 12.125C7 12.125 8.20312 11.6875 9.1875 10.9219V11.3867C9.1875 11.5508 9.24219 11.7148 9.37891 11.8516L12.0859 14.5586C12.3594 14.832 12.7695 14.832 13.0156 14.5586L13.7812 13.793C14.0547 13.5469 14.0547 13.1367 13.8086 12.8633ZM5.6875 9.9375C3.74609 9.9375 2.1875 8.37891 2.1875 6.4375C2.1875 4.52344 3.74609 2.9375 5.6875 2.9375C7.60156 2.9375 9.1875 4.52344 9.1875 6.4375C9.1875 8.37891 7.60156 9.9375 5.6875 9.9375Z" fill="#878787"/>
                </svg>
            </div>
        </template>
        <template slot="singleLabel" slot-scope="props">
            <div class="option__participant">
                <div class="option__participant-avatar">
                    <img :src="getAvatar(props.option) ? getAvatar(props.option) : '/images/logo.jpg'" alt="">
                </div>
                <div class="option__participant-info">
                    <strong>{{ getNickName(props.option) }}</strong>
                    <span v-if="props.option.type === 1">Game profile id: {{ props.option.profile.info.id ? props.option.profile.info.id : props.option.profile.info.nickname }}</span>
                </div>
            </div>
        </template>
        <template slot="option" slot-scope="props">
            <div class="option__participant">
                <div class="option__participant-avatar">
                    <img :src="getAvatar(props.option) ? getAvatar(props.option) : '/images/logo.jpg'" alt="">
                </div>
                <div class="option__participant-info">
                    <strong>{{ getNickName(props.option) }}</strong>
                    <span v-if="props.option.type === 1">Game profile id: {{ props.option.profile.info.id ? props.option.profile.info.id : props.option.profile.info.nickname }}</span>
                </div>
            </div>
        </template>
    </FormMultiSelect>
</template>
<script>
import FormMultiSelect from 'vue-multiselect'
export default {
    data () {
      return {
          participant: null
      }
    },
    props: {
        isDisabled: {
            type: Boolean,
            default: false
        },
        value: {
            required: false,
            type: Object
        },
        participants: {
              type: Array,
              required: true
        }
    },
    methods: {
        getLabel (instance) {
            if (instance.isType('team')) {
                return instance.getTeam().name
            }
            return instance.getProfile().user.nickname
        },
        getAvatar (participant) {
            return participant.getType() === 2 ? participant.getTeam().logo : participant.getProfile().user.avatar;
        },
        getNickName(participant) {
            if (participant.getType() === 2) {
                return participant.getTeam().name
            } else if (participant.getType() === 1) {
                if (participant.getProfile().user.nickname) return participant.getProfile().user.nickname;
                if (participant.getProfile().user.name) return participant.getProfile().user.name
                if (participant.getProfile().user.email) return participant.getProfile().user.email
            }
            return 'Unknown'
        }

    },
    watch: {
      value () {
          if (this.value) {
              this.participant = this.value
          }
      }
    },
    mounted () {
        if (this.value) {
            this.participant = this.value
        }
    },
    components: {
        FormMultiSelect
    }
}
</script>
<style scoped lang="scss">
::v-deep {
    .multiselect__option--highlight {
        background: rgba(0, 231, 217, 0.5) !important;
        &::after {
            background: rgba(0, 231, 217, 0.5) !important;
        }
    }
    .multiselect__tags {
        padding: 0;
        min-height: 40px;
        display: flex;
        border-radius: 0;
        border: none;
        input {
            position: static !important;
            padding-left: 0 !important;
            padding-bottom: 0 !important;
            margin-bottom: 0 !important;
            font-size: 14px !important;
        }
    }
    .multiselect__placeholder {
        height: 40px;
        display: flex;
        align-items: center;
        margin: 0;
        padding-left: 0;
    }
    .multiselect__select {
        height: 50px;
        display: none;
    }
    .multiselect__content-wrapper {

        &::-webkit-scrollbar {
            background-color: #fff;
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #06C2BC;
            border-radius: 16px;
        }
    }
    .multiselect__single {
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-bottom: 0;
        height: 40px;
        .option__participant {
            padding: 0;
        }
    }
}
.option__participant {
    display: flex;
    padding: 0 15px;
    align-items: center;
    .option__participant-avatar {
        margin-right: 12px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        overflow: hidden;
        border: 1px solid #06C2BC;
        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
        }
    }
    .option__participant-info {
        strong {
            width: 100%;
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-transform: capitalize;
            margin-bottom: 3px;
            color: #1C1C1C;
        }
        span {
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            display: block;
            width: 100%;
            color: #878787;
        }
    }
}

</style>
