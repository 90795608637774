<template>
	<div class="leaderboard" v-if="rounds.length">
		<div class="leaderboard__tabs">
			<div
				v-for="(round, index) in rounds"
				class="leaderboard__tab"
				:class="{active: activeTab === index}"
				@click="changeTab(index)"
			>
				<span class="title">{{$t('Round')}} {{round.round}}</span>
				<span class="date">{{round.started_at | readableDateFormat}}</span>
			</div>
			<div
				class="leaderboard__tab"
				@click="changeTab(999)"
				:class="{active: activeTab === 999}"
			>
				<span class="title">{{$t('Total')}}</span>
			</div>
		</div>
		<div
			v-for="(round, i) in rounds"
			class="leaderboard__item"
			v-if="activeTab === i"
		>
			<div class="leaderboard__item-header" v-if="!isFinished()">
				<label>
					<span>{{$t('Room ID')}}</span>
					<input
						type="text"
						name="room-id"
						class="form-input"
						:placeholder="$t('Enter room ID')"
						v-model="rounds[i].room_id"
						required
					>
				</label>
				<label>
					<span>{{$t('Password')}}</span>
					<input
						type="text"
						name="code"
						class="form-input"
						:placeholder="$t('Enter password')"
						v-model="rounds[i].password"
						required
					>
				</label>
				<button
					class="button button-primary"
					@click.prevent="updateRoom(i)"
				>
					{{$t('Apply')}}
				</button>
			</div>
			<div v-if="showPlayers()" class="leaderboard__item-content">
				<div class="head">
					<span>{{$t('Position')}}</span>
					<span>{{$t('Player')}}</span>
					<span>{{$t('Score')}}</span>
				</div>
				<div class="row" v-if="participant.info" v-for="(participant, j) in round.participants">
					<span class="row__index">{{j + 1}}</span>
					<span class="row__name"><span>{{getNickName(participant)}}</span> <span v-if="!isTeam(participant)">(ID: {{getPlatformId(participant)}})</span></span>
					<label class="row__input" :class="{disabled: isFinished()}">
						<input
							type="number"
							name="score"
							class="form-input"
							:min="0"
							required
							v-model="rounds[i].participants[j].points"
						>
					</label>
				</div>
			</div>
			<div v-if="showPlayers() && !isFinished()" class="leaderboard__item-footer">
				<button class="button button-primary" @click.prevent="updateResult(i)">{{$t('Update result')}}</button>
			</div>
		</div>
		<div class="leaderboard__item total" v-if="showPlayers() && activeTab === 999">
			<div class="leaderboard__item-content">
				<div class="head">
					<span>{{$t('Position')}}</span>
					<span>{{$t('Player')}}</span>
					<span>{{$t('Score')}}</span>
				</div>
				<div class="row" v-if="participant.info" v-for="(participant, index) in getTotal()">
					<span>{{index + 1}}</span>
					<span>{{getNickName(participant)}} <span v-if="!isTeam(participant)">(ID: {{getPlatformId(participant)}})</span></span>
					<span>{{participant.points}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'

	import SvgIcon from '../../components/SvgIcon'

	export default {
		props: ["id"],
		name: "Leaderboard",
		components: {
			SvgIcon
		},
		data() {
			return {
				activeTab: 0
			}
		},
		computed: {
			...mapGetters({
				tournament: 'tournament',
				rounds: 'leaderboardItems'
			}),
		},
		created() {
			this.init();
		},
		methods: {
			...mapActions([
				'getLeaderBoard',
				'updateLeaderBoard'
			]),
			init() {
				this.getLeaderBoard({ id: this.id });
			},
			updateRoom(index) {
				let currentRound = this.rounds[index];
				this.updateLeaderBoard({
					tournament: this.id,
					match: currentRound.id,
					body: {
						room_id: currentRound.room_id,
						password: currentRound.password,
					}
				})
			},
			updateResult(index) {
				let currentRound = this.rounds[index];
				const newParticipants = [];

				currentRound.participants.forEach(item => {
					newParticipants.push({
						id: item.id,
						points: item.points
					});
				});

				this.updateLeaderBoard({
					tournament: this.id,
					match: currentRound.id,
					body: {
						participants: newParticipants,
					}
				})
			},
			changeTab(index) {
				this.activeTab = index;
			},
			getTotal() {
				const copied = JSON.parse(JSON.stringify(this.rounds));
				const firstRound = copied.shift();
				const total = firstRound.participants;

				copied.forEach((round) => {
					total.forEach((item, j) => {
						const nextParticipantPoints = round.participants.filter(next => item.id === next.id)[0].points;
						total[j].points = Number(item.points) + (Number(nextParticipantPoints));
					});
				});

				return total.sort((a, b) => b.points - a.points);
			},
			showPlayers() {
				return 'REGISTRATION_OPENED' !== this.tournament.getStatus() && 'CHECKIN' !== this.tournament.getStatus();
			},
			isFinished() {
				return 'FINISHED' === this.tournament.getStatus();
			},
			getNickName(participant) {

				if (participant.info.type === 2) {
					return participant.info.team ? participant.info.team.name : 'Team deleted';
				}

				return participant.info.profile.user.nickname;
			},
			getPlatformId(participant) {
				return participant.info.profile.info.nickname;
			},
			isTeam(participant) {
				return participant.info.type === 2;
			}
		}
	};
</script>
