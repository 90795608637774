<template>
    <div class="image__uploader">
        <div class="top__information">
            <label for="" v-if="label">{{ label }}</label>
            <div class="sizes" v-if="sizes">
                Recommended size {{ sizes }}px
            </div>
        </div>
        <input @input="upload" accept="image/*" :id="'i_' + id" type="file">
        <div class="area" >
            <span class="image">
                <img :src="!preview ? value : preview" alt="">
                <span class="info" v-if="file">
                    <span class="name">{{ file.name }}</span>
                    <button @click="clear">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.88653 4.98582L9.7234 2.14894C10.0922 1.80851 10.0922 1.24113 9.7234 0.900709L9.09929 0.276596C8.75887 -0.0921986 8.19149 -0.0921986 7.85106 0.276596L5.01418 3.11348L2.14894 0.276596C1.80851 -0.0921986 1.24113 -0.0921986 0.900709 0.276596L0.276596 0.900709C-0.0921986 1.24113 -0.0921986 1.80851 0.276596 2.14894L3.11348 4.98582L0.276596 7.85106C-0.0921986 8.19149 -0.0921986 8.75887 0.276596 9.09929L0.900709 9.7234C1.24113 10.0922 1.80851 10.0922 2.14894 9.7234L5.01418 6.88653L7.85106 9.7234C8.19149 10.0922 8.75887 10.0922 9.09929 9.7234L9.7234 9.09929C10.0922 8.75887 10.0922 8.19149 9.7234 7.85106L6.88653 4.98582Z" fill="#303030"/>
                        </svg>
                    </button>
                </span>
            </span>
            <label :for="'i_' + id" class="uploader">
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.09375 11.25C8.44922 11.25 8.75 10.9766 8.75 10.5938V6H11.1289C11.6211 6 11.8672 5.42578 11.5117 5.07031L7.35547 0.914062C7.16406 0.722656 6.80859 0.722656 6.61719 0.914062L2.46094 5.07031C2.10547 5.42578 2.35156 6 2.84375 6H5.25V10.5938C5.25 10.9766 5.52344 11.25 5.90625 11.25H8.09375ZM14 11.0312C14 10.6758 13.6992 10.375 13.3438 10.375H9.625V10.5938C9.625 11.4414 8.91406 12.125 8.09375 12.125H5.90625C5.05859 12.125 4.375 11.4414 4.375 10.5938V10.375H0.65625C0.273438 10.375 0 10.6758 0 11.0312V14.0938C0 14.4766 0.273438 14.75 0.65625 14.75H13.3438C13.6992 14.75 14 14.4766 14 14.0938V11.0312ZM10.6094 13.4375C10.6094 13.7383 10.3633 13.9844 10.0625 13.9844C9.76172 13.9844 9.51562 13.7383 9.51562 13.4375C9.51562 13.1367 9.76172 12.8906 10.0625 12.8906C10.3633 12.8906 10.6094 13.1367 10.6094 13.4375ZM12.3594 13.4375C12.3594 13.7383 12.1133 13.9844 11.8125 13.9844C11.5117 13.9844 11.2656 13.7383 11.2656 13.4375C11.2656 13.1367 11.5117 12.8906 11.8125 12.8906C12.1133 12.8906 12.3594 13.1367 12.3594 13.4375Z" fill="#303030"/>
                </svg>
            </label>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            id: Math.random().toString(36).slice(2, 7),
            preview: null,
            oldValue: null,
            file: null
        }
    },
    props: {
        value: {
            type: String || File,
        },
        label: {
            type: String,
        },
        sizes: {
            type: String
        }
    },
    mounted () {
        this.oldValue = this.value
    },
    methods: {
        clear () {
            this.file = null
            this.preview = null
            this.$emit('upload', this.oldValue)
        },
        upload ($event) {
            const file = $event.target.files[0]
            this.file = file
            this.preview = URL.createObjectURL(file)
            this.$emit('upload', file)
        }
    }
}
</script>
<style scoped lang="scss">
.image__uploader {
    margin-bottom: 15px;
    .top__information {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        label {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #06C2BC;
        }
        .sizes {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #878787;
        }
    }
    input {
        display: none;
    }
    .area {
        margin-top: 6px;
        border: 1px solid #878787;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        .image {
            margin-top: 4px;
            margin-bottom: 4px;
            margin-left: 4px;
            max-width: 90%;
            border-radius: 4px;
            height: 45px;
            overflow: hidden;
            background: #ECECEC;
            display: flex;
            .info {
                padding: 0 8px;
                display: flex;
                align-items: center;
                .name {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #303030;
                }
                button {
                    margin-left: 10px;
                    cursor: pointer;
                    outline: none;
                    border: none;
                    background: transparent;
                }
            }
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .uploader {
            padding: 0 15px;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: 0.2s;
            &:hover {
                opacity: 0.6;
            }
            border-left: 1px solid #878787;
        }
    }
}
</style>
