import http from '../utils/http'
import joinUrls from '../utils/join-urls'

export default {
	get(data = {}) {
		return http.get('/countries', data)
	},
	getCountry(id) {
		return http.get(joinUrls('/countries', id))
	},
	update(id, data) {
		if (!id) {
			return Promise.reject(new Error('Country id is required to update countries data'))
		}

		return http.put(joinUrls('/countries', id), data)
	},
}