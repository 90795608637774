<template>
    <div class="modal create__room-modal">
        <div class="modal-wrapper">
            <div class="modal-overlay"></div>
            <div class="modal-container" v-if="proxyLobby">
                <div class="modal-header">
                    <h2>Edit lobby</h2>
                    <div class="modal-header__close" @click="$emit('close')">
                        <SvgIcon
                            width="16"
                            height="16"
                            :iconType="'slim'"
                        />
                    </div>
                </div>
                <form class="form" @submit.prevent.stop="create">

                    <div class="input__block">
                        <label for="asd4">Start lobby date:</label>

                        <Datetime
                            :format="`DD HH:mm`"
                            type="datetime"
                            v-model="getTime"
                        />
                    </div>

                    <div class="error" v-if="error">{{ error }}</div>

                    <button class="create__room" :class="{'disabled': load}" type="submit">Save date</button>
                </form>
            </div>
        </div>

    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import SvgIcon from '../../SvgIcon'
import {Datetime} from 'vue-datetime'
import MultilobbyService from '../../../api/multiarena'
import moment from "moment";
export default {
    props: {
        round: {
            type: Number,
            required: true,
        },
        lobby: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            proxyLobby: undefined,
            error: undefined,
            load: false,
        }
    },
    methods: {
        ...mapActions({
            getTournament: 'GET_TOURNAMENT',
        }),
        create () {
            this.load = true
            MultilobbyService.editLobby(this.tournament.getId(), this.lobby.lobby, {
                lobby_started: this.proxyLobby.lobby_started,
                round: this.round,
            }).then(response => {
                if (response.status === 200) {
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        group: 'notify',
                        text: 'Success lobby update'
                    })
                    this.lobby = this.proxyLobby;
                    this.$emit('update', this.proxyLobby.lobby_started)
                } else {
                    this.$notify({
                        type: 'error',
                        title: 'Unknown error',
                        group: 'notify',
                        text: response.data.error ? response.data.error : 'Unknown error'
                    })
                }
            }).catch(() => {
                this.$notify({
                    type: 'error',
                    title: 'Unknown error',
                    group: 'notify',
                    text: 'Unknown error'
                })
            })
        },
    },
    computed: {
        getTime: {
            get () {
                return this.proxyLobby.lobby_started
            },
            set (value) {
                this.proxyLobby.lobby_started = value
            }
        },
        ...mapGetters({
            tournament: 'tournament',
        }),
    },
    async mounted () {
        this.proxyLobby = {...this.lobby}
    },
    components: {
        SvgIcon,
        Datetime
    }
}
</script>
<style scoped lang="scss">
.create__room-modal {
    .modal-wrapper {
        .modal-container {
            width: 376px;
            padding: 25px;
            .modal-header {
                display: flex;
                justify-content: space-between;
                h2 {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 23px;
                    text-transform: uppercase;
                    color: #303030;
                }
            }
            .form {
                .error {
                    margin: 15px 0;
                    font-size: 14px;
                    color: red;
                }
                margin-top: 25px;
                padding: 0;
                .or {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    font-size: 14px;
                    margin-bottom: 10px;
                    position: relative;
                    &::after {
                        content: "";
                        display: block;
                        height: 1px;
                        position: absolute;
                        background: gray;
                        top: calc(50% - 1px);
                        opacity: 0.5;
                        left: calc(50% + 20px);
                        width: 100%;
                    }
                    &::before {
                        content: "";
                        display: block;
                        height: 1px;
                        position: absolute;
                        background: gray;
                        top: calc(50% - 1px);
                        opacity: 0.5;
                        right: calc(50% + 20px);
                        width: 100%;
                    }
                    color: #000;
                }

                .input__block {
                    margin-bottom: 15px;
                    label {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #06C2BC;
                        margin-bottom: 6px;
                        display: block;
                    }
                    input {
                        border: 1px solid #878787;
                        background: transparent;
                        outline: none;
                        height: 50px;
                        width: 100%;
                        padding: 0px 15px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #878787;
                        border-radius: 4px;
                    }
                }
                .create__room {
                    margin-top: 15px;
                    background: #00CBCB;
                    border-radius: 2px;
                    padding: 13px 0;
                    letter-spacing: 0.16px;
                    text-transform: uppercase;
                    color: #303030;
                    outline: none;
                    border: none;
                    width: 100%;
                    cursor: pointer;
                    transition: 0.2s;
                    &:hover {
                        opacity: 0.7;
                    }
                    font-weight: bold;
                    font-family: "Roboto";
                    &.disabled {
                        background: #C4C4C4;
                        color: #E5E5E5;
                        cursor: default;
                    }
                }
            }
        }
    }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
