<template>
    <div class="tft__player" :class="{
        'winner': player && player.status === 'winner',
        'defeated': player && player.status === 'defeated'
    }">
        <template v-if="player && player.participant">
            <div class="tft__player-avatar">
                <img :src="getAvatar() ? getAvatar() : '/images/logo.jpg'">
            </div>
            <div class="tft__player-nickname">
                {{ getNickName() }}
                <template v-if="player.place">
                    - #{{ player.place }}
                </template>
            </div>
            <div :class="{'active': isResult}" class="tft__image">
                <template v-if="isResult">
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5 12C15.3125 12 16 11.3438 16 10.5V1.5C16 0.6875 15.3125 0 14.5 0H1.5C0.65625 0 0 0.6875 0 1.5V10.5C0 11.3438 0.65625 12 1.5 12H14.5ZM3.5 1.75C4.4375 1.75 5.25 2.5625 5.25 3.5C5.25 4.46875 4.4375 5.25 3.5 5.25C2.53125 5.25 1.75 4.46875 1.75 3.5C1.75 2.5625 2.53125 1.75 3.5 1.75ZM2 10V8.5L4.21875 6.28125C4.375 6.125 4.59375 6.125 4.75 6.28125L6.5 8L10.7188 3.78125C10.875 3.625 11.0938 3.625 11.25 3.78125L14 6.5V10H2Z" fill="#06C2BC"/>
                    </svg>
                </template>
                <template v-else>

                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5 12C15.3125 12 16 11.3438 16 10.5V1.5C16 0.6875 15.3125 0 14.5 0H1.5C0.65625 0 0 0.6875 0 1.5V10.5C0 11.3438 0.65625 12 1.5 12H14.5ZM3.5 1.75C4.4375 1.75 5.25 2.5625 5.25 3.5C5.25 4.46875 4.4375 5.25 3.5 5.25C2.53125 5.25 1.75 4.46875 1.75 3.5C1.75 2.5625 2.53125 1.75 3.5 1.75ZM2 10V8.5L4.21875 6.28125C4.375 6.125 4.59375 6.125 4.75 6.28125L6.5 8L10.7188 3.78125C10.875 3.625 11.0938 3.625 11.25 3.78125L14 6.5V10H2Z" fill="#E5E5E5"/>
                    </svg>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="tft__player-avatar"></div>
            <div class="tft__player-nickname"></div>
        </template>

    </div>
</template>
<script>
export default {
    props: {
      results: {
          type: Array,
          required: true
      },
      player: {
          required: true
      }
    },
    computed: {
      isResult () {
          if (this.player.participant.type === 1) {
              return !!this.results.find(item => item.reporter_id === this.player.participant.profile.user.id)
          }
          return this.results.find(item => item.reporter_id === this.player.participant.team.creator_id)
      }
    },
    methods: {
        getAvatar() {
            return this.player.participant.type === 2 ? this.player.participant.team.logo : this.player.participant.profile.user.avatar;
        },
        getNickName() {
            if (this.player.participant.type === 2) {
                return this.player.participant.team.name
            } else if (this.player.participant.type === 1) {
                if (this.player.participant.profile.user.nickname) return this.player.participant.profile.user.nickname;
                if (this.player.participant.profile.user.name) return this.player.participant.profile.user.name
                if (this.player.participant.profile.user.email) return this.player.participant.profile.user.email
            }
            return 'Unknown'
        }
    }
}
</script>
<style scoped lang="scss">
.tft__player {
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    padding: 6px 10px;
    align-items: center;
    position: relative;
    &.winner {
        background: rgb(188 255 251 / 50%)
    }
    &.defeated {
        background: #fff0f0;
    }
    .tft__player-avatar {
        margin-right: 10px;
        width: 28px;
        height: 28px;
        border-radius: 100%;
        background: #ECECEC;
        overflow: hidden;
        img {
            border-radius: 100%;
            width: 100%;
            height: 100%;
        }
    }
    .tft__player-nickname {
        font-weight: 500;
        max-width: calc(100% - 70px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 16px;
        text-transform: capitalize;
        color: #000000;
    }
    .tft__image {
        &.active {
            cursor: pointer;
        }
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 40px;
        min-width: 40px;
        border-left: 1px solid #E5E5E5;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}
</style>
