import advertisements from '../../api/advertisements'
import PageMeta from '../../dto/page-meta'
import router from "../../routes";
import tournament from "../../api/tournament";

const state = () => ({
	advertisement: null,
	advertisements: [],
	advertisementsMeta: null,
});

const getters = {
	advertisement: state => state.advertisement,
	advertisements: state => state.advertisements,
	advertisementsMeta: state => state.advertisementsMeta
};

const actions = {
	getAdvertisements: async ({commit}, data = {}) => {
		const response = await advertisements.get(data);

		commit('UPDATE_ADVERTISEMENTS', response.data.data);
		commit('UPDATE_ADVERTISEMENTS_META', response.data);
	},
	getAdvertisement: async ({commit}, id) => {
		const response = await advertisements.getAdvertisement(id);

		commit('UPDATE_ADVERTISEMENT', response.data);
	},
	addAdvertisement: async ({commit}, {data}) => {
		const response = await advertisements.add(data);

		if (response.status === 201 || response.status === 200) {
			router.push('/advertisements');
		} else {
			return response;
		}
	},
	updateAdvertisement: async ({commit}, {id, data}) => {
		const response = await advertisements.update(id, data);

		if (response.status === 201 || response.status === 200) {
			router.push('/advertisements');
		} else {
			return response;
		}
	},
	updateAdvertisementStatus: async ({commit}, {id, data}) => {
		await advertisements.activate(id, data);
	},
	deleteAdvertisement: async ({commit}, id) => {
		await advertisements.deleteAdvertisement(id);
		const response = await advertisements.get();

		commit('UPDATE_ADVERTISEMENTS', response.data.data);
		commit('UPDATE_ADVERTISEMENTS_META', response.data);
	},
};

const mutations = {
	UPDATE_ADVERTISEMENT: (state, data) => {
		state.advertisement = data;
	},
	UPDATE_ADVERTISEMENTS: (state, data) => {
		state.advertisements = data;
	},
	UPDATE_ADVERTISEMENTS_META: (state, data) => {
		state.advertisementsMeta = new PageMeta(data.meta);
	},
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
