import http from '../utils/http'

export function getList() {
    return http.get('/games');
}
export function updateAvailable (id, data) {
    return http.put(`/games/${id}/update-available`, data)
}
export function update (id, data)
{
    return http.post(`/games/${id}`, data)
}

export function addGame (data) {
    return http.post(`/games`, data)
}

export function getGame (id) {
    return http.get(`/games/${id}`)
}
