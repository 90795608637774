var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageLinks',{attrs:{"links":[
			{
				url: '/term/sadd',
				text: _vm.$t('Add Terms'),
				classes: 'active'
			}
		]}}),_c('form',{staticClass:"form form--xl"},[_c('div',{staticClass:"form-group"},[_c('FormInput',{attrs:{"name":'title',"label":_vm.$t('Title'),"placeholder":_vm.$t('Name'),"value":_vm.form.title,"formErrors":_vm.errors.title,"onChange":_vm.formFieldChanged}})],1),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-input-field"},[_c('label',[_c('span',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.$t('Content')))])]),_c('TextEditor',{staticClass:"form-text-editor",attrs:{"placeholder":_vm.$t('Content')},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}}),_c('FormNotification',{attrs:{"errors":_vm.errors.content}})],1)]),_c('div',{staticClass:"form-group"},[_c('span',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.$t('Choose country')))]),_c('FormMultiSelect',{attrs:{"placeholder":_vm.$t('Country'),"options":_vm.getCountriesWithLocales,"loading":_vm.multiSelectOptions.loading,"label":_vm.multiSelectOptions.label,"track-by":_vm.multiSelectOptions.trackBy,"multiple":_vm.multiSelectOptions.multiple,"taggable":_vm.multiSelectOptions.taggable},on:{"search-change":_vm.onSearchChange},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('FormNotification',{attrs:{"errors":_vm.errors.country}})],1),_c('button',{staticClass:"button button-primary form-button-submit",on:{"click":function($event){$event.preventDefault();return _vm.saveTerm()}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }