<template>
	<div>
		<div class="page__content-header">
			<div>
				<PageLinks v-bind:links="[
					{
						url: '/news',
						text: $t('News')
					}
				]"/>
			</div>
			<div>
				<RouterLink
					to="/news/post"
					:class="'button button-primary'"
				>
					{{$t('Post news')}}
				</RouterLink>
			</div>
		</div>
		<div>
			<FiltersBar :options="filters" :onChange="filtersChanged"/>
			<div class="table-wrapper">
				<table v-if="news">
					<thead class="text-left">
					<tr>
						<th scope="col">{{$t('ID')}}</th>
						<th scope="col">{{$t('Title')}}</th>
						<th scope="col">{{$t('Description')}}</th>
						<th scope="col">{{$t('Deeplink')}}</th>
						<th scope="col">{{$t('Date')}}</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="item in news">
						<td scope="row">{{ item.getId() }}</td>
						<td class="text-ellipsis text-ellipsis-100">
							{{ item.getTitle() }}
						</td>
						<td class="text-ellipsis text-ellipsis-100">
							{{ item.getDescription() }}
						</td>
						<td>
							<div class="copy-field">
								<span class="text-ellipsis">{{item.getDeeplink()}}</span>
								<a href="#" @click.prevent="copy(item.getDeeplink())">
									<SvgIcon
										width="22"
										height="22"
										:iconType="'copy'"
									/>
								</a>
							</div>
						</td>
						<td>{{item.getDate() | readableDateFormat | getDate}}</td>
						<td scope="row">
							<Dropdown
								:data-position="'left'"
								:data-horizontal-aligntment="'left'"
								:data-vertical-aligntment="'top'"
								:toggle-class="'dots-toggle'"
							>
								<SvgIcon
									slot="dropdown-toggle"
									width="23"
									height="5"
									view-box="0 0 23 5"
									:iconType="'dots'"
								/>
								<div slot="dropdown-content">
									<RouterLink
										:class="'dropdown__link'"
										:to="{path: '/news/edit/' +  item.getId()}"
									>
										<SvgIcon
											width="16"
											height="16"
											:iconType="'edit'"
										/>
										<span>{{$t('Edit')}}</span>
									</RouterLink>
									<a
										href="#"
										class="dropdown__link"
										@click.prevent="deleteNewsById(item.getId())"
									>
										<SvgIcon
											width="16"
											height="16"
											:iconType="'delete'"
										/>
										<span>{{$t('Delete')}}</span>
									</a>
								</div>
							</Dropdown>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<PagePagination
				v-if="meta"
				:current="meta.currentPage()"
				:total="meta.lastPage()"
				@change="changePage"
			/>
		</div>
	</div>
</template>

<script>
	import {mapGetters, mapActions, mapMutations} from 'vuex'

	import SvgIcon from '../../components/SvgIcon';
	import Dropdown from '../../components/Dropdown';
	import FiltersBar from '../../components/FiltersBar';
	import PagePagination from '../../components/Page/Pagination';
	import PageLinks from '../../components/Page/Links';

	export default {
		components: {
			SvgIcon,
			Dropdown,
			FiltersBar,
			PageLinks,
			PagePagination
		},
		computed: {
			...mapGetters({
				news: 'news',
				meta: 'newsMeta',
				games: 'games',
				activeFilters: 'activeFilters'
			})
		},
		data() {
			return {
				filters: [],
				filtersUtil: this.$options.filters
			}
		},
		created() {
			this.init();
		},
		methods: {
			...mapActions([
				'getAllNews',
				'deleteNews',
				'getGames'
			]),
			...mapMutations({
				setFilters: 'SET_FILTERS',
			}),
			async init() {
				await this.getAllNews(this.activeFilters('news'));
				await this.prepareFiltersFields();
			},
			changePage(page) {
				const activeFilters = this.activeFilters('news');

				this.getAllNews({
					page: page,
					...activeFilters
				});
			},
			copy(string) {
				this.$clipboard(string);
				alert("Copied to clipboard");
			},
			async deleteNewsById(id) {
				const activeFilters = this.activeFilters('news');

				await this.deleteNews(id).catch(err => {
                    this.$notify({
                        type: 'error',
                        title: 'Permission error',
                        group: 'notify',
                        text: err.response.data.message ? err.response.data.message : 'Unknown error'
                    })
                });
				await this.getAllNews({
					...activeFilters
				});
			},
			async prepareFiltersFields() {
				await this.getGames();

				const activeFilters = this.activeFilters('news');
				this.filters.push({
					type: 'select',
					name: 'games',
					value: activeFilters.hasOwnProperty('games') ? activeFilters.games : null,
					options: this.filtersUtil.gamesOptions(this.games)
				});
			},
			filtersChanged(field, value) {
				this.setFilters({
					type: 'news',
					key: field,
					value: value ? value : null
				});

				this.getAllNews(this.activeFilters('news'));
			}
		},
	}
</script>
