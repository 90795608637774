import brackets from '../../api/brackets'
import tournament from '../../api/tournament'
import match from '../../api/match'
import participants from '../../api/participants'
import tft from '../../api/tft'
import Participant from '../../dto/participant'
import Match from '../../dto/match'
import MatchResult from '../../dto/matchResult'

import helpers from '../../helpers'
import team from "../../api/team";


// TODO refactor
const validationHelpers = {
	validateUserId: (data) => {
		return 2 === data.type ? data.team.creator_id : data.profile.user.id;
	}
};

const state = () => ({
	brackets: [],
    thirdMatch: null,
	isPublicBrackets: false,
	rounds: [],
	winnerDialogState: false,
	winnerDialogData: [],
	scheduleDialogState: false,
	scheduleDialogData: [],
	highlightedPlayerId: null,
	selectedRound: 1,
    matches: [],
    excludeParticipants: []
});

const getters = {
	brackets: state => state.brackets,
    thirdMatch: state => state.thirdMatch,
    double_brackets: (state) => {
        let data = state.brackets
        data = data.map(item => {
            const games = item.games.filter(i => !i.is_looser_match)
            return {
                games
            }
        })
        data = data.filter(item => item.games.length > 0)
        return data
    },
	getNextMatch: state => (round, id) => {
        const allRounds = () => {
            let matches = []
            let rounds = JSON.parse(JSON.stringify(state.brackets))
            rounds.forEach(item => {
                matches.push(...item.games)
            })
            matches = matches.map(item => {
                return {
                    ...item,
                    games: []
                }
            })
            return matches
        }
		return allRounds().find(item => item.id === id)
	},
	rounds: state => state.rounds,
	isPublicBrackets: state => state.isPublicBrackets,
	winnerDialogState: state => state.winnerDialogState,
	winnerDialogData: state => state.winnerDialogData,
	scheduleDialogState: state => state.scheduleDialogState,
	scheduleDialogData: state => state.scheduleDialogData,
	highlightedPlayerId: state => state.highlightedPlayerId,
	selectedRound: state => state.selectedRound,
    matches: state => state.matches,
    excludeParticipants: state => {
        const exclude = []
        state.matches.forEach(item => {
            if (item.first_participant) {
                exclude.push(item.first_participant.id)
            }
            if (item.second_participant) {
                exclude.push(item.second_participant.id)
            }
        })
        return [...exclude, ...state.excludeParticipants]
    }
};

const actions = {
    async startChallenge ({ commit, dispatch }, id) {
        try {
            await tournament.start(id).then((data) => {
                if (data.response && data.response.status !== 200) {
                    throw data
                }
            });
        } catch(e) {
            throw e
        }

        const tournamentResponse = await tournament.get(id);

        if (200 === tournamentResponse.status) {
            commit('UPDATE_TOURNAMENT', tournamentResponse.data);
            commit('UPDATE_TOURNAMENT_STATUS', tournamentResponse.data.status);
        }
    },
	async startTournament({commit, dispatch}, data) {
		const matches = helpers.prepareMatchesForStart(data.brackets);
        try {
            await tournament.start(data.id, matches).then((data) => {
                if (data.response && data.response.status !== 200) {
                    throw data
                }
            });
        } catch(e) {
            throw e
        }
		const tournamentResponse = await tournament.get(data.id);

		if (200 === tournamentResponse.status) {
			commit('UPDATE_TOURNAMENT', tournamentResponse.data);
			commit('UPDATE_TOURNAMENT_STATUS', tournamentResponse.data.status);
		}

		if (200 === tournamentResponse.status && 'STARTED' === tournamentResponse.data.status) {

			commit('TOURNAMENT_INIT', tournamentResponse.data.id);

			dispatch('fetchBrackets', data.id);

			await match.getAll(data.id)
				.then((resp) => {
					commit('UPDATE_CHATS_LIST', resp.data.data);
					commit('UPDATE_CHATS_LIST_STATES');
				});
		}
	},
    async restoreBrackets ({commit, dispatch}, id) {
        await brackets.create(id, true);
        dispatch('GET_TOURNAMENT', id)
        dispatch('fetchBrackets', id)
    },
	async generateBrackets({commit, dispatch, getters}, id) {
		const bracketsResponse = await brackets.create(id);
		if (200 === bracketsResponse.status) {
			const tournamentResponse = await tournament.get(id);
			const matchesResponse = await match.getAll(id);

			if (matchesResponse.data.data.length) {
				const brackets = helpers.convertMatchesToBrackets(matchesResponse.data.data);
				const timings = helpers.generateInitialTimings(brackets, tournamentResponse.data.started_at);

				if (null === tournamentResponse.data.template_id) {
					await match.updateTimings(id, timings);
				}

				await commit('UPDATE_TOURNAMENT', tournamentResponse.data);
				await commit('UPDATE_TOURNAMENT_STATUS', tournamentResponse.data.status);
			}
		}
	},
	async shuffleBrackets({commit, dispatch, getters}, id) {
		const participantsResponse = await participants.get(id, 'status=active');
		commit('SHUFFLE_BRACKETS', {brackets: getters.brackets, participants: participantsResponse.data.data});
	},
    async fetchTftPublicBrackets({commit, getters}, id) {
        commit('SET_IS_PUBLIC_BRACKETS', true);

        const matchesResponse = await tft.getMatches(id);
        if (matchesResponse.data.data.length) {
            const resultsResponse = await tournament.getResults(id);
            const brackets = helpers.convertMatchesToBrackets(matchesResponse.data.data);
            commit('UPDATE_BRACKETS', brackets);
        }
    },
	async fetchPublicBrackets({commit, getters}, id) {
		commit('SET_IS_PUBLIC_BRACKETS', true);

		const matchesResponse = await match.getAll(id);
		if (matchesResponse.data.data.length) {
			const resultsResponse = await tournament.getResults(id);
			const brackets = helpers.convertMatchesToBrackets(matchesResponse.data.data);
			commit('UPDATE_BRACKETS', brackets);
			commit('ADD_RESULTS_TO_BRACKETS', resultsResponse.data.data);
		}
	},
    async fillEmptySpots ({ commit, dispatch }, id) {
      await tournament.fillEmptySpots(id).then(() => {
          dispatch('fetchBrackets', id)
      })
    },
    async fetchMatches ({commit, getters, rootGetters}, id) {
        const matchesResponse = await match.getAll(id, true);
        if (matchesResponse.data.data.length) {
            commit('SET_MATCHES', matchesResponse.data.data)
        }
    },
	async fetchBrackets({commit, getters, rootGetters}, id) {
		const isEmptyBrackets = 'REGISTRATION_CLOSED' === getters.tournament.getStatus();
		let participantsResponse = [];
		let resultsResponse = [];
		const matchesResponse = await match.getAll(id);

		if (isEmptyBrackets) {
			participantsResponse = await participants.get(id, 'status=active');
		} else {
			resultsResponse = await tournament.getResults(id)
		}

		if (matchesResponse.data.data.length) {
            commit('SET_MATCHES', matchesResponse.data.data)
            let matches = matchesResponse.data.data.map(item => {
                let nextMatch = matchesResponse.data.data.find(i => Number(i.id) === Number(item.next_match_id))
                return {
                    ...item,
                    next_match_finished: nextMatch && nextMatch.match_status === 'FINISHED'
                }
            }).filter(item => !item.is_third_match)
            const thirdMatch = matchesResponse.data.data.find(item => item.is_third_match)
            commit('SET_THIRD_MATCH', thirdMatch ?? null)

			const brackets = helpers.convertMatchesToBrackets(matches);

			if (isEmptyBrackets) {
                if (rootGetters.tournament && rootGetters.tournament.getCustomSeeding()) {
                    commit('CUSTOM_SHUFFLE', {brackets: brackets, participants: participantsResponse.data.data})
                } else {
                    commit('SHUFFLE_BRACKETS', {brackets: brackets, participants: participantsResponse.data.data});
                }
			} else {
				commit('UPDATE_BRACKETS', brackets);
				commit('ADD_RESULTS_TO_BRACKETS', resultsResponse.data.data);
			}
		}
	},
	async showBracketsWinnerDialog({commit}, data) {
		const matchResponse = await match.get(data.tournamentId, data.matchId);
		const resultsResponse = await match.getResult(data.tournamentId, data.matchId);
		const matchData = matchResponse.data;
		const firstParticipantExists = !!matchResponse.data.first_participant;
		const secondParticipantExists = !!matchResponse.data.second_participant;

		if (firstParticipantExists) {
			const firstParticipantType = Number(matchResponse.data.first_participant.type);
			const firstParticipant = matchResponse.data.first_participant;

			if (firstParticipantType === 2) {
				const firstTeamResponse = await team.get(firstParticipant.team.id);
				if (firstTeamResponse.data.data) {
					const firstTeam = firstTeamResponse.data.data;
					matchData.first_participant.team.team_mates = firstTeam.team_mates;
				} else {
					const firstTeam = firstTeamResponse.data;
					matchData.first_participant.team.team_mates = firstTeam.team_mates;
				}
			}
		}

		if (secondParticipantExists) {
			const secondParticipantType = Number(matchResponse.data.second_participant.type);
			const secondParticipant = matchResponse.data.second_participant;

			if (secondParticipantType === 2) {
				const secondTeamResponse = await team.get(secondParticipant.team.id);
				if (secondTeamResponse.data.data) {
					const secondTeam = secondTeamResponse.data.data;
					matchData.second_participant.team.team_mates = secondTeam.team_mates;
				} else {
					const secondTeam = secondTeamResponse.data;
					matchData.second_participant.team.team_mates = secondTeam.team_mates;
				}
			}
		}

		commit(
			'UPDATE_BRACKETS_WINNER_DIALOG_DATA',
			{
				match: matchData,
				images: resultsResponse.data.data
			}
		);

		commit('UPDATE_BRACKETS_WINNER_DIALOG_STATE', true);
	},
	hideBracketsWinnerDialog({commit}) {
		commit('UPDATE_BRACKETS_WINNER_DIALOG_STATE', false);
	},
	async showBracketsScheduleDialog({commit}, id) {
		const response = await match.getTimings(id);
		let timings = helpers.calculateTimingsForCurrentTimeZone(response.data.data);

		commit('UPDATE_BRACKETS_SCHEDULE_DIALOG_DATA', timings);
		commit('UPDATE_BRACKETS_SCHEDULE_DIALOG_STATE', true);
	},
	hideBracketsScheduleDialog({commit}) {
		commit('UPDATE_BRACKETS_SCHEDULE_DIALOG_STATE', false);
	},
	async saveBracketsSchedule({commit, dispatch}, data) {
		commit('UPDATE_BRACKETS_SCHEDULE_DIALOG_STATE', false);

		const response = await match.updateTimings(data.id, data.body);

		commit('UPDATE_BRACKETS_SCHEDULE_DIALOG_DATA', response.data.data);

		dispatch('fetchBrackets', data.id)
	},
	async approveBracketsWinner({commit}, data) {
		commit('UPDATE_BRACKETS_WINNER_DIALOG_STATE', false);

		await match.finish(data.id, data.matchId, data.body);

		const resultsResponse = await tournament.getResults(data.id);
		const matchesResponse = await match.getAll(data.id);

		if (matchesResponse.data.data.length) {
            let matches = matchesResponse.data.data.map(item => {
                const nextMatch = matchesResponse.data.data.indexOf(i => i.id === item.next_match_id)
                return {
                    ...item,
                    next_match_finished: nextMatch && nextMatch.match_status === 'FINISHED'
                }
            }).filter(item => !item.is_third_match)
            const thirdMatch = matchesResponse.data.data.find(item => item.is_third_match)
            commit('SET_THIRD_MATCH', thirdMatch ?? null)

			const brackets = helpers.convertMatchesToBrackets(matches);
			commit('UPDATE_BRACKETS', brackets);
			commit('ADD_RESULTS_TO_BRACKETS', resultsResponse.data.data);
		}
	},
	highlightBracketsPlayer({commit}, id) {
		commit('SET_BRACKETS_HIGHLIGHTED_PLAYER', id);
	},
	unHighlightBracketsPlayer({commit}) {
		commit('SET_BRACKETS_HIGHLIGHTED_PLAYER', null);
	},

    async setParticipantToMatch ({ commit }, { matchId, tournamentId, data }) {
        return await match.setParticipant(tournamentId, matchId, data)
    }
};

const mutations = {
    replaceMatch (state, { id, match }) {
        const find = state.matches.findIndex(item => item.id === id)
        if (find > -1) {
            state.matches.splice(find, 1, match)
        }
    },
    ADD_EXCLUDE_PARTICIPANT (state, id) {
      state.excludeParticipants.push(parseInt(id))
    },
    SET_DEFAULT_MATCH (state, match) {
      const index = state.matches.findIndex(item => item.id === match.id)
      if (index > -1) {
          state.matches.splice(index, 1, match)
      }
    },
    REMOVE_EXCLUDE_PARTICIPANT (state, id) {
      const index = state.excludeParticipants.findIndex(item => parseInt(item) === parseInt(id))
      if (index > -1) {
          state.excludeParticipants.splice(index, 1)
      }
    },
    SET_MATCHES (state, matches) {
        state.matches = matches
    },
	UPDATE_BRACKETS(state, data) {
		state.rounds = helpers.getRoundsFromBrackets(data);
		state.brackets = data;
	},
	SET_IS_PUBLIC_BRACKETS(state, value) {
		state.isPublicBrackets = value;
	},
    SET_THIRD_MATCH (state, match) {
        state.thirdMatch = match
    },
    CUSTOM_SHUFFLE (state, data) {
        state.rounds = helpers.getRoundsFromBrackets(data.brackets);
        state.brackets = data.brackets;
    },
	SHUFFLE_BRACKETS(state, data) {
		state.rounds = helpers.getRoundsFromBrackets(data.brackets);

        state.brackets = helpers.shuffleBrackets(data);
	},
	UPDATE_BRACKETS_WINNER_DIALOG_DATA(state, data) {
		const match = new Match(data.match);
		const firstPlayer = new Participant(match.getFirstParticipant());
		const secondPlayer = new Participant(match.getSecondParticipant());
		let firstPlayerData = null;
		let secondPlayerData = null;
		const images = {
			first: [],
			second: [],
		};

		data.images.forEach((item) => {
			const result = new MatchResult(item);

			if (result.getType() === 'IMAGE') {
				const firstUserId = firstPlayer.isType('single') ? firstPlayer.getUserId() : firstPlayer.getCreatorId();
				const secondUserId = secondPlayer.isType('single') ? secondPlayer.getUserId() : secondPlayer.getCreatorId();
				switch (result.getReporterID()) {
					case firstUserId :
						images.first.push(result.getImage());
						break;
					case secondUserId :
						images.second.push(result.getImage());
						break;
				}
			}
		});

		if (!firstPlayer.isEmpty()) {
			firstPlayerData = {
				id: firstPlayer.getId(),
				avatar: firstPlayer.getUserAvatar(),
				name: firstPlayer.getUserNickName(),
				type: firstPlayer.isType('team') ? 'team' : 'single',
				team_mates: firstPlayer.getTeamMates(),
				platformName: firstPlayer.getPlatformId(),
				images: images.first
			};
		}

		if (!secondPlayer.isEmpty()) {
			secondPlayerData = {
				id: secondPlayer.getId(),
				avatar: secondPlayer.getUserAvatar(),
				name: secondPlayer.getUserNickName(),
				type: secondPlayer.isType('team') ? 'team' : 'single',
				team_mates: secondPlayer.getTeamMates(),
				platformName: secondPlayer.getPlatformId(),
				images: images.second
			};
		}

		state.winnerDialogData = {
			matchId: match.getMatchID(),
			first: firstPlayerData,
			second: secondPlayerData
		};
	},
	UPDATE_BRACKETS_WINNER_DIALOG_STATE(state, value) {
		state.winnerDialogState = value;
	},
	UPDATE_BRACKETS_SCHEDULE_DIALOG_STATE(state, value) {
		state.scheduleDialogState = value;
	},
	UPDATE_BRACKETS_SCHEDULE_DIALOG_DATA(state, value) {
		state.scheduleDialogData = value;
	},
	SET_BRACKETS_HIGHLIGHTED_PLAYER(state, id) {
		state.highlightedPlayerId = id;
	},
	ADD_RESULTS_TO_BRACKETS(state, results) {
		let brackets = state.brackets;
		brackets.forEach((round, roundIndex) => {
			round.games.forEach((game, gameIndex) => {
                if (!game) return;
				const player1HasParticipant = null !== game.first_participant;
				const player1ParticipantId = player1HasParticipant ? game.first_participant.id : null;
				const player1UserId = player1HasParticipant ? validationHelpers.validateUserId(game.first_participant) : null;
				const player2HasParticipant = null !== game.second_participant;
				const player2ParticipantId = player2HasParticipant ? game.second_participant.id : null;
				const player2UserId = player2HasParticipant ? validationHelpers.validateUserId(game.second_participant) : null;
				const winnerId = game.winner_id;
				const matchResults = results.filter(item => item.match_id === game.id && item.type === 'INT');
				let firstResult = null;
				let secondResult = null;

				if (winnerId) {
					firstResult = Number(player1ParticipantId === winnerId);
					secondResult = Number(player2ParticipantId === winnerId);
				}

				matchResults.forEach(result => {
					switch (result.reporter_id) {
						case player1UserId :
							firstResult = result.value_state;
							break;
						case player2UserId :
							secondResult = result.value_state;
							break;
					}
				});

				if (player1HasParticipant) {
					brackets[roundIndex].games[gameIndex].first_participant.result = firstResult;
				}

				if (player2HasParticipant) {
					brackets[roundIndex].games[gameIndex].second_participant.result = secondResult;
				}
			})
		});

		state.brackets = brackets;
	},
	UPDATE_RESULT_IN_BRACKETS(state, result) {
		let brackets = state.brackets;
		brackets.forEach((round, roundIndex) => {
			round.games.forEach((game, gameIndex) => {
				if (game.id === result.match_id) {
					const player1UserId = null !== game.first_participant ? validationHelpers.validateUserId(game.first_participant) : null;
					const player2UserId = null !== game.second_participant ? validationHelpers.validateUserId(game.second_participant) : null;
					const matchResult = result.value_state;

					switch (result.reporter_id) {
						case player1UserId :
							brackets[roundIndex].games[gameIndex].first_participant.result = matchResult;
							break;
						case player2UserId :
							brackets[roundIndex].games[gameIndex].second_participant.result = matchResult;
							break;
					}
				}
			})
		});

		state.brackets = brackets;
	},
	UPDATE_MATCH_STATUS_IN_BRACKETS(state, match) {
		let brackets = state.brackets;
		const matchId = match.match_id;
		const status = match.status;
		brackets.forEach((round, roundIndex) => {
			round.games.forEach((game, gameIndex) => {
				if (game.id === matchId) {
					brackets[roundIndex].games[gameIndex].match_status = status;
				}
			})
		});

		state.brackets = brackets;
	},
	UPDATE_SELECTED_ROUND(state, round) {
		state.selectedRound = round;
	}
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
