<template>
	<form class="login-form">
		<div class="login-form__header">
			<div class="login-form__logo">
				<img
					:src="'/images/logo-light.jpg'"
					alt="logo"
				>
			</div>
			<h1 class="login-form__title">{{$t('Connect with us.')}}<br>{{$t('Become a part of QLASH.')}}</h1>
			<p class="login-form__subtitle">{{$t('How would you like to log in?')}}</p>
		</div>
		<div class="login-form__content">
			<a :href="discordUrl" class="login-form__discord-button">
				<svg-icon
					width="32"
					height="32"
					:iconType="'discord'"
				/>
				<span>{{$t('Log in with Discord')}}</span>
			</a>
			<a :href="twitchUrl" class="login-form__twitch-button">
				<svg-icon
					width="32"
					height="32"
					:iconType="'twitch'"
				/>
				<span>{{$t('Log in with Twitch')}}</span>
			</a>
			<div class="form-group">
				<label class="form-label" for="email">
					{{$t('Email address')}}
				</label>
				<input
					class="form-input"
					id="email"
					type="text"
					name="email"
					:placeholder="$t('Enter email')"
					v-model="email"
					@focus="clearError('email')"
				>
				<FormNotification :errors="errors.email"/>
			</div>
			<div class="form-group">
				<label class="form-label" for="password">
					{{$t('Password')}}
				</label>
				<input
					class="form-input"
					id="password"
					name="password"
					:placeholder="$t('Enter password')"
					:type="showPassword ? 'text' : 'password'"
					v-model="password"
					@focus="clearError('password')"
				>
				<FormNotification :errors="errors.password"/>
				<a
					href="#"
					class="toggle-password-button"
					@click.prevent="showPassword = !showPassword"
				>
					<span v-if="showPassword">{{$t('Hide')}}</span>
					<span v-else>{{$t('Show')}}</span>
				</a>
			</div>
			<button
				class="button button-primary"
				@click.prevent="login"
			>
				{{$t('Log in')}}
			</button>
			<FormNotification :errors="errors.message"/>
		</div>
		<div class="login-form__footer">
			<p>
				{{$t('By clicking Join Now, you state you also agree with our')}}
				<a href="https://qlash.gg/en/page/privacy-policy">{{$t('Privacy Policy')}}</a>
				{{$t('and')}}
				<a href="https://qlash.gg/en/page/terms">{{$t('Terms')}}</a>.</p>
		</div>
	</form>
</template>

<script>
	import router from '../routes'
	import SvgIcon from '../components/SvgIcon'
	import FormNotification from '../components/Form/Notification'
	import {mapGetters} from 'vuex'

	export default {
		components: {
			SvgIcon,
			FormNotification
		},
		data() {
			return {
				discordUrl: process.env.VUE_APP_DISCORD_URL,
				twitchUrl: process.env.VUE_APP_TWITCH_URL,
				email: '',
				password: '',
				showPassword: false,
				errors: {},
				defaultError: {
					message: [
						this.$t('Something went wrong. Please try again.')
					]
				}
			};
		},
		computed: {
			...mapGetters([
				'isLoggedIn',
			]),
		},
		mounted() {
			if (this.isLoggedIn) router.push('/');
		},
		methods: {
			async login() {
				this.showPassword = false;
				const user = {
					email: this.email,
					password: this.password
				};

				await this.$store.dispatch('LOGIN', user)
					.catch(error => this.errorsHandler(error));

				await this.$store.dispatch('GET_USER');

				this.$store.dispatch('GET_WS_TOKEN')
					.then(() => this.successHandler());
			},
			successHandler() {
				router.push('/');
			},
			errorsHandler(error) {
				if (undefined !== error.response.data.errors) {
					this.errors = error.response.data.errors;
				} else {
					this.errors = this.defaultError;
				}
			},
			clearError(key) {
				this.errors[key] = null;
			}
		}
	}
</script>