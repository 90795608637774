<template>
    <div class="modal">
        <div class="modal-wrapper">
            <div class="modal-overlay"></div>
            <div class="modal-container">
                <div class="modal-header">
                    <div class="modal-header__title">
                        <span>{{$t('Are you sure you want to re-create brackets?')}}</span>
                    </div>
                    <div class="modal-header__close" @click="$emit('close')">
                        <svg-icon
                            width="16"
                            height="16"
                            :iconType="'slim'"
                        />
                    </div>
                </div>
                <div class="choose-winner">
                    <div class="choose-winner__buttons">
                        <button
                            class="button button-primary"
                            @click.prevent="restore"
                        >
                            {{$t('Yes')}}
                        </button>
                        <button
                            class="button button-warning"
                            @click.prevent="$emit('close')"
                        >
                            {{$t('Cancel')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import SvgIcon from '../../components/SvgIcon'

export default {
    name: "BracketsWinnerDialog",
    components: {
        SvgIcon
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament',
        })
    },
    data() {
        return {
            showRecreateDialog: false,
        }
    },
    methods: {
        async restore() {
            this.$emit('close')
            await this.restoreBrackets(this.tournament.getId());
        },
        ...mapActions({
            restoreBrackets: 'restoreBrackets'
        }),
    }
};
</script>
