export const LOCALES = [
	{name :"Afar",value :"aa"},
	{name :"Abkhazian",value :"ab"},
	{name :"Afrikaans",value :"af"},
	{name :"Akan",value :"ak"},
	{name :"Albanian",value :"sq"},
	{name :"Amharic",value :"am"},
	{name :"Arabic",value :"ar"},
	{name :"Aragonese",value :"an"},
	{name :"Armenian",value :"hy"},
	{name :"Assamese",value :"as"},
	{name :"Avaric",value :"av"},
	{name :"Avestan",value :"ae"},
	{name :"Aymara",value :"ay"},
	{name :"Azerbaijani",value :"az"},
	{name :"Bashkir",value :"ba"},
	{name :"Bambara",value :"bm"},
	{name :"Basque",value :"eu"},
	{name :"Belarusian",value :"be"},
	{name :"Bengali",value :"bn"},
	{name :"Bihari languages",value :"bh"},
	{name :"Bislama",value :"bi"},
	{name :"Tibetan",value :"bo"},
	{name :"Bosnian",value :"bs"},
	{name :"Breton",value :"br"},
	{name :"Bulgarian",value :"bg"},
	{name :"Burmese",value :"my"},
	{name :"Catalan; Valencian",value :"ca"},
	{name :"Czech",value :"cs"},
	{name :"Chamorro",value :"ch"},
	{name :"Chechen",value :"ce"},
	{name :"Chinese",value :"zh"},
	{name :"Church Slavic",value :"cu"},
	{name :"Chuvash",value :"cv"},
	{name :"Cornish",value :"kw"},
	{name :"Corsican",value :"co"},
	{name :"Cree",value :"cr"},
	{name :"Welsh",value :"cy"},
	{name :"Czech",value :"cs"},
	{name :"Danish",value :"da"},
	{name :"German",value :"de"},
	{name :"Divehi; Dhivehi; Maldivian",value :"dv"},
	{name :"Dutch; Flemish",value :"nl"},
	{name :"Dzongkha",value :"dz"},
	{name :"Greek, Modern (1453-)",value :"el"},
	{name :"English",value :"en"},
	{name :"Esperanto",value :"eo"},
	{name :"Estonian",value :"et"},
	{name :"Basque",value :"eu"},
	{name :"Ewe",value :"ee"},
	{name :"Faroese",value :"fo"},
	{name :"Persian",value :"fa"},
	{name :"Fijian",value :"fj"},
	{name :"Finnish",value :"fi"},
	{name :"French",value :"fr"},
	{name :"French",value :"fr"},
	{name :"Western Frisian",value :"fy"},
	{name :"Fulah",value :"ff"},
	{name :"Georgian",value :"ka"},
	{name :"German",value :"de"},
	{name :"Gaelic; Scottish Gaelic",value :"gd"},
	{name :"Irish",value :"ga"},
	{name :"Galician",value :"gl"},
	{name :"Manx",value :"gv"},
	{name :"Greek, Modern (1453-)",value :"el"},
	{name :"Guarani",value :"gn"},
	{name :"Gujarati",value :"gu"},
	{name :"Haitian; Haitian Creole",value :"ht"},
	{name :"Hausa",value :"ha"},
	{name :"Hebrew",value :"he"},
	{name :"Herero",value :"hz"},
	{name :"Hindi",value :"hi"},
	{name :"Hiri Motu",value :"ho"},
	{name :"Croatian",value :"hr"},
	{name :"Hungarian",value :"hu"},
	{name :"Armenian",value :"hy"},
	{name :"Igbo",value :"ig"},
	{name :"Icelandic",value :"is"},
	{name :"Ido",value :"io"},
	{name :"Sichuan Yi; Nuosu",value :"ii"},
	{name :"Inuktitut",value :"iu"},
	{name :"Interlingue; Occidental",value :"ie"},
	{name :"Interlingua (International Auxiliary Language Association)",value :"ia"},
	{name :"Indonesian",value :"id"},
	{name :"Inupiaq",value :"ik"},
	{name :"Icelandic",value :"is"},
	{name :"Italian",value :"it"},
	{name :"Javanese",value :"jv"},
	{name :"Japanese",value :"ja"},
	{name :"Kalaallisut; Greenlandic",value :"kl"},
	{name :"Kannada",value :"kn"},
	{name :"Kashmiri",value :"ks"},
	{name :"Georgian",value :"ka"},
	{name :"Kanuri",value :"kr"},
	{name :"Kazakh",value :"kk"},
	{name :"Central Khmer",value :"km"},
	{name :"Kikuyu; Gikuyu",value :"ki"},
	{name :"Kinyarwanda",value :"rw"},
	{name :"Kirghiz; Kyrgyz",value :"ky"},
	{name :"Komi",value :"kv"},
	{name :"Kongo",value :"kg"},
	{name :"Korean",value :"ko"},
	{name :"Kuanyama; Kwanyama",value :"kj"},
	{name :"Kurdish",value :"ku"},
	{name :"Lao",value :"lo"},
	{name :"Latin",value :"la"},
	{name :"Latvian",value :"lv"},
	{name :"Limburgan; Limburger; Limburgish",value :"li"},
	{name :"Lingala",value :"ln"},
	{name :"Lithuanian",value :"lt"},
	{name :"Luxembourgish; Letzeburgesch",value :"lb"},
	{name :"Luba-Katanga",value :"lu"},
	{name :"Ganda",value :"lg"},
	{name :"Macedonian",value :"mk"},
	{name :"Marshallese",value :"mh"},
	{name :"Malayalam",value :"ml"},
	{name :"Maori",value :"mi"},
	{name :"Marathi",value :"mr"},
	{name :"Malay",value :"ms"},
	{name :"Macedonian",value :"mk"},
	{name :"Malagasy",value :"mg"},
	{name :"Maltese",value :"mt"},
	{name :"Mongolian",value :"mn"},
	{name :"Maori",value :"mi"},
	{name :"Malay",value :"ms"},
	{name :"Burmese",value :"my"},
	{name :"Nauru",value :"na"},
	{name :"Navajo; Navaho",value :"nv"},
	{name :"Ndebele, South; South Ndebele",value :"nr"},
	{name :"Ndebele, North; North Ndebele",value :"nd"},
	{name :"Ndonga",value :"ng"},
	{name :"Nepali",value :"ne"},
	{name :"Dutch; Flemish",value :"nl"},
	{name :"Norwegian Nynorsk; Nynorsk, Norwegian",value :"nn"},
	{name :"Bokmål, Norwegian; Norwegian Bokmål",value :"nb"},
	{name :"Norwegian",value :"no"},
	{name :"Chichewa; Chewa; Nyanja",value :"ny"},
	{name :"Occitan (post 1500)",value :"oc"},
	{name :"Ojibwa",value :"oj"},
	{name :"Oriya",value :"or"},
	{name :"Oromo",value :"om"},
	{name :"Ossetian; Ossetic",value :"os"},
	{name :"Panjabi; Punjabi",value :"pa"},
	{name :"Persian",value :"fa"},
	{name :"Pali",value :"pi"},
	{name :"Polish",value :"pl"},
	{name :"Portuguese",value :"pt"},
	{name :"Pushto; Pashto",value :"ps"},
	{name :"Quechua",value :"qu"},
	{name :"Romansh",value :"rm"},
	{name :"Romanian; Moldavian; Moldovan",value :"ro"},
	{name :"Romanian; Moldavian; Moldovan",value :"ro"},
	{name :"Rundi",value :"rn"},
	{name :"Russian",value :"ru"},
	{name :"Sango",value :"sg"},
	{name :"Sanskrit",value :"sa"},
	{name :"Sinhala; Sinhalese",value :"si"},
	{name :"Slovak",value :"sk"},
	{name :"Slovak",value :"sk"},
	{name :"Slovenian",value :"sl"},
	{name :"Northern Sami",value :"se"},
	{name :"Samoan",value :"sm"},
	{name :"Shona",value :"sn"},
	{name :"Sindhi",value :"sd"},
	{name :"Somali",value :"so"},
	{name :"Sotho, Southern",value :"st"},
	{name :"Spanish; Castilian",value :"es"},
	{name :"Albanian",value :"sq"},
	{name :"Sardinian",value :"sc"},
	{name :"Serbian",value :"sr"},
	{name :"Swati",value :"ss"},
	{name :"Sundanese",value :"su"},
	{name :"Swahili",value :"sw"},
	{name :"Swedish",value :"sv"},
	{name :"Tahitian",value :"ty"},
	{name :"Tamil",value :"ta"},
	{name :"Tatar",value :"tt"},
	{name :"Telugu",value :"te"},
	{name :"Tajik",value :"tg"},
	{name :"Tagalog",value :"tl"},
	{name :"Thai",value :"th"},
	{name :"Tibetan",value :"bo"},
	{name :"Tigrinya",value :"ti"},
	{name :"Tonga (Tonga Islands)",value :"to"},
	{name :"Tswana",value :"tn"},
	{name :"Tsonga",value :"ts"},
	{name :"Turkmen",value :"tk"},
	{name :"Turkish",value :"tr"},
	{name :"Twi",value :"tw"},
	{name :"Uighur; Uyghur",value :"ug"},
	{name :"Ukrainian",value :"uk"},
	{name :"Urdu",value :"ur"},
	{name :"Uzbek",value :"uz"},
	{name :"Venda",value :"ve"},
	{name :"Vietnamese",value :"vi"},
	{name :"Volapük",value :"vo"},
	{name :"Welsh",value :"cy"},
	{name :"Walloon",value :"wa"},
	{name :"Wolof",value :"wo"},
	{name :"Xhosa",value :"xh"},
	{name :"Yiddish",value :"yi"},
	{name :"Yoruba",value :"yo"},
	{name :"Zhuang; Chuang",value :"za"},
	{name :"Chinese",value :"zh"},
	{name :"Zulu",value :"zu"}];