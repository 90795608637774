import http from '../utils/http'
import joinUrls from '../utils/join-urls'

export default {
	getTeam(id) {
		return http.get(joinUrls('/teams', id));
	},
    getTeams (params) {
        return http.get(`/teams?${params}`);
    },
    deleteTeam (id) {
        return http.delete(joinUrls('/teams', id));
    },
    declineInvite (teamId, mateId) {
        return http.delete(`teams/${teamId}/team-mates/${mateId}`);
    }
}
