<template>
	<div class="form-image-picker">
		<span class="form-label text-left">
			{{label}}
		</span>
		<label class="form-image-picker__label">
			<input
				class="form-image-picker__input"
				type="file"
				name="image"
				accept="image/*"
				@change="onChangeFileUpload"
			>
			<span class="form-image-picker__content">
				<span
					class="form-image-picker__item"
					v-if="hasImage()"
				>
					<img
						class="form-image-picker__image"
						:src="imagePreview"
						alt=""
					/>
					<span class="form-image-picker__filename">{{ fileName }}</span>
					<span
						class="form-image-picker__remove"
						@click.prevent="removeImage"
					>
						<SvgIcon
							width="10"
							height="10"
							viewBox="0 0 10 10"
							:iconType="'small-close'"
						/>
					</span>
				</span>
				<span v-else>{{$t('Choose image')}}</span>
				<SvgIcon
					width="14"
					height="15"
					viewBox="0 0 14 15"
					:iconType="'hard-download'"
				/>
			</span>
		</label>
		<FormNotification :errors="errors"/>
	</div>
</template>

<script>
	import SvgIcon from '../SvgIcon'
	import FormNotification from '../../components/Form/Notification'
	import { MAX_FILE_SIZE } from '../../constants/form'

	export default {
		name: "ImagePicker",
		components: {
			SvgIcon,
			FormNotification
		},
		data () {
			return {
				maxImageSize: MAX_FILE_SIZE,
				imagePreview: '',
				fileName: '',
				errors: this.formErrors,
				imageSrc: ''
			}
		},
		props: {
			image: {
				type: [String, Object, File]
			},
			label: {
				type: String
			},
			formErrors: {
				type: [Array, Object],
			},
			onChangeImage: { type: Function },
			onRemoveImage: { type: Function },
		},
		watch: {
			image: {
				handler (src) {
					this.imageSrc = src;
					this.imagePreview = src;
				},
				deep: true
			},
			formErrors: {
				handler (errors) {
					this.errors = errors;
				},
				deep: true
			}
		},
		methods: {
			onChangeFileUpload (event) {
				const fileSize = Math.round((event.target.files[0].size / 1024));

				if (this.maxImageSize < fileSize) {
					this.errors = [this.$t('File size too large. Maximum is ') + this.maxImageSize];
					return;
				} else {
					this.errors = [];
				}

				this.imageSrc = event.target.files[0];
				this.onChangePreview(this.imageSrc);
				this.onChangeImage(this.imageSrc);
			},
			onChangePreview (file) {
				const reader = new FileReader();

				if (file) {
					this.fileName = file.name;

					reader.onload = (event) => {
						this.imagePreview = event.target.result;
					};

					reader.readAsDataURL(file);
				}
			},
			removeImage () {
				this.imagePreview = '';
				this.imageSrc = null;
				this.onRemoveImage();
			},
			hasImage () {
				return this.image instanceof File || (typeof this.image === 'string' && this.image !== '');
			}
		}
	};
</script>