<template>
	<div class="brackets__mobile-item">
        <template v-if="data.is_tft">
            <TftUiBracketItem :data="data"></TftUiBracketItem>
        </template>
		<template v-else>
            <div v-for="item in data">
                <BracketItem  :data="item"/>
            </div>
        </template>
	</div>
</template>

<script>
import TftUiBracketItem from '../../views/Public/TftUiBracketItem'

	import BracketItem from './BracketItem'

	export default {
		name: "BracketMobileNode",
		components: {
			BracketItem,
            TftUiBracketItem
		},
		props: {
			data: Array
		}
	};
</script>
