import http from '../utils/http'
import joinParams from "../utils/join-params";

export default {
	get(data) {
		const url = joinParams('/users', data);
		return http.get(url);
	},
    getUser(id) {
        return http.get(`/users/${id}`);
    },
    getProfiles (id) {
        return http.get(`/users/${id}/profiles`);
    },
    getAssignGames (user_id) {
        return http.get(`/assign-games/${user_id}`);
    },
    addAssignGames (data) {
        return http.post(joinParams('/assign-games', data));
    },
    removeAssignGames (data) {
        return http.delete(joinParams('/assign-games', data));
    },
    update (id, params) {
        return http.put(`/admin/users/${id}`, params)
    },
    block (email) {
        return http.post('/admin/users/block', {email: email})
    }
}
