export default function Platform( data = null) {
	const info = data;

	this.getId = function () {
		return info.id;
	};

	this.getName = function () {
		return info.name;
	};
}