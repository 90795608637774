<template>
    <div class="chatButton" @click="$emit('selectConversation', conversation)">
        <div class="chatInfo">
            <div class="image my-image" :style="{'background': `#DDD url(${avatar}) no-repeat center`}">

            </div>
            <p class="name">
                {{ name }}
            </p>
            <p class="message" v-if="conversation.last_message">
                <span v-if="conversation.last_message.message_type === 0">
                    {{ conversation.last_message.message }}
                </span>
                <span class="picture" v-if="conversation.last_message.message_type === 1">
                    Image
                </span>
            </p>
        </div>

        <div class="status onTop">
            <p class="date" v-if="conversation.last_message">{{ toTime(conversation.last_message.created_at) }}</p>
            <p class="count" v-if="conversation.unread_messages > 0">{{ conversation.unread_messages }}</p>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
    props: {
        selectId: {
        },
        conversation: {
            type: Object,
            requred: true
        }
    },
    methods: {
        toTime(date) {
            date = moment(date);

            return date.format('HH:mm')
        }
    },
    computed: {
        ...mapGetters({
            centrifugeInstance: 'centrifugeInstance'
        }),
        avatar () {
            return this.conversation.user.avatar ?? '/images/logo.svg'
        },
        name () {
            const user = this.conversation.user;

            if (user.name) {
                return user.name
            }

            if (user.nickname) {
                return user.nickname
            }

            return user.email
        }
    },
    mounted () {
        if (this.conversation.chat_room) {
            this.centrifugeInstance.subscribe(this.conversation.chat_room, (data) => {
                this.conversation.last_message = data.data;
                if (this.conversation.id !== this.selectId) {
                    if (this.conversation.unread_messages) {
                        this.conversation.unread_messages = 0;
                    }
                    this.conversation.unread_messages++;
                }
            })
        }
    }
}
</script>
<style scoped lang="scss">
.chatButton {
    float: left;
    width: 100%;
    height: 80px;
    background: #FFF;
    color: #555;
    cursor: pointer;
    overflow: hidden;
}

.chatButton:hover {
    color: #555;
    background: #EEE;
}

.chatInfo {
    float: left;
}

.chatInfo p {
    float: left;
}

.chatInfo .image {
    position: absolute;
    width: 55px;
    height: 55px;
    background: #DDD url(https://pbs.twimg.com/profile_images/378800000822867536/3f5a00acf72df93528b6bb7cd0a4fd0c.jpeg) no-repeat center;
    background-size: cover;
    border-radius: 100%;
    margin: 13px 13px;
}

.chatInfo .my-image {
    background-image: url(https://avatars1.githubusercontent.com/u/21313332?s=460&v=4);
}

.chatInfo {
    max-width: 100%;
}

.chatInfo .name {
    float: left;
    font-weight: 600;
    margin-left: 80px;
    margin-top: 18px;
}

.chatInfo .message {
    float: left;
    clear: left;
    margin-left: 80px;
    margin-top: 7px;
    max-width: 58%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
    }
    .picture {
        opacity: 0.7;
    }
}

.status {
    width: 60px;
    height: 100%;
    position: relative;
    float: right;
}

.status .fixed {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: none;
}

div[class~="onTop"] > .fixed {
    display: block;
    width: 24px;
    height: 24px;
    fill: #FFF;
}

div[class~="onTop"] > .count {
    right: 49px;
    bottom: 7px;
    background: #585858;
    color: #fff;
}

.status .count {
    position: absolute;
    right: 49px;
    bottom: 7px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 3px;
    border-radius: 100%;
    color: #222;
    background: rgba(0, 0, 0, 0.12);
}


.status .date {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 12px;
}

.status .read {
    position: absolute;
    right: 53px;
    top: 6px;
}

</style>
