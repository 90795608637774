<template>
    <div class="multilobby">
        <div class="multilobby__rounds">
            <div class="multilobby__rounds-round"
                 @click="selectRound = round.round.round"
                 :class="{'active': selectRound === round.round.round}"
                 v-for="(round, i) in meta"
                 :key="i">
                <div class="round">
                    Round {{ round.round.round }}
                </div>
            </div>
            <button class="next__round button button-primary" @click="startNextRound" v-if="['STARTED', 'IN_PROGRESS'].includes(tournament.getStatus())">
                Start next round
            </button>
        </div>
        <div class="multilobby__lobbies">
            <div class="multilobby__lobby" v-for="(lobby,i) in lobbies" :key="i">
                <div class="multilobby__lobby-head">
                    <div class="multilobby__lobby-head__info">
                        <span>Lobby {{ lobby.lobby }}</span>
                    </div>
                    <div class="multilobby__lobby-head__credentials">
                        <div class="multilobby__lobby-head__credentials-item">
                            <div class="room__instance-header_started_at" v-if="['REGISTRATION_CLOSED', 'STARTED', 'IN_PROGRESS'].includes(tournament.getStatus())">
                                Lobby started:
                                <div class="date__time" @click="editLobby = lobby">
                                    {{ getStartedLobby(lobby.lobby_started) }}
                                </div>
                            </div>
                        </div>
                        <template v-if="tournament.getStatus() !== 'REGISTRATION_CLOSED'">
                            <div class="multilobby__lobby-head__credentials-item">
                                <label for="">Room ID</label>
                                <input type="text" v-model="lobby.room_id" placeholder="Enter room id">
                            </div>
                            <div class="multilobby__lobby-head__credentials-item">
                                <label for="">Password</label>
                                <input type="text" v-model="lobby.password" placeholder="Enter password">
                            </div>
                        </template>

                        <div class="multilobby__lobby-head__credentials-item" v-if="tournament.getStatus() !== 'REGISTRATION_CLOSED'"  style="align-items: flex-end;display: flex;">

                            <button
                                @click.prevent="updateRoom(lobby)"
                                class="button button-primary"
                            >
                                {{$t('Apply')}}
                            </button>
                        </div>
                    </div>
                    <div :class="{'transform': lobbyOpenedList.includes(lobby.lobby)}" @click="toggleOpenLobby(lobby.lobby)" class="multilobby__lobby-head__arrow">
                        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.60481 0.255198C8.27491 -0.0850665 7.72509 -0.0850666 7.39519 0.255198L0.247422 7.58979C-0.0824736 7.96786 -0.0824736 8.53497 0.247422 8.87524L1.09049 9.7448C1.42039 10.0851 1.97022 10.0851 2.33677 9.7448L7.98167 3.92249L13.6632 9.7448C14.0298 10.0851 14.5796 10.0851 14.9095 9.7448L15.7526 8.87524C16.0825 8.53497 16.0825 7.96786 15.7526 7.58979L8.60481 0.255198Z" fill="#878787"/>
                        </svg>
                    </div>
                </div>
                <div class="multilobby__lobby-participants" v-if="lobbyOpenedList.includes(lobby.lobby)">
                    <MultilobbyMatch :tournamentId="id" :matchId="lobby.id"></MultilobbyMatch>
                </div>
            </div>
        </div>
        <EditLobby @update="editLobby.lobby_started = $event, editLobby = undefined" v-if="editLobby && ['REGISTRATION_CLOSED', 'STARTED', 'IN_PROGRESS'].includes(tournament.getStatus())" :round="selectRound" :lobby="editLobby" @close="editLobby = undefined"></EditLobby>
    </div>
</template>
<script>
import multiarena from "../../api/multiarena";
import leaderboard from "../../api/leaderboard";
import EditLobby from './Modals/EditLobby'
import {mapActions, mapGetters} from 'vuex'
import MultilobbyMatch from './MultilobbyMatch'
import moment from "moment";
export default {
    props: {
        id: {
            required: true
        }
    },
    data () {
        return {
            editLobby: undefined,
            selectRound: 1,
            lobbyOpenedList: [],
            meta: {}
        }
    },
    watch: {
        selectRound () {
            this.lobbyOpenedList = []
        },
        tournament: {
            deep: true,
            handler () {
                this.init();
            }
        }
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament'
        }),
        lobbies () {
            return this.meta[this.selectRound - 1] ? this.meta[this.selectRound - 1].lobbies: []
        },
        isFinished() {
            return 'FINISHED' === this.tournament.getStatus();
        },
    },
    methods: {
        ...mapActions({
            getTournament: 'GET_TOURNAMENT',
            centrifugeConnect: 'centrifugeConnect'
        }),
        startNextRound () {
            multiarena.startNextRound(this.tournament.getId()).then(response => {
                if (response.status === 200) {
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        group: 'notify',
                        text: 'Success start next round'
                    })
                    this.getTournament(this.tournament.getId())
                } else {
                    this.$notify({
                        type: 'error',
                        title: 'Unknown error',
                        group: 'notify',
                        text: response.response.data.error ? response.response.data.error : 'Unknown error'
                    })
                }
            }).catch((err) => {
                alert('catch')
                console.log(err)
                this.$notify({
                    type: 'error',
                    title: 'Unknown error',
                    group: 'notify',
                    text: 'Unknown error'
                })
            })
        },
        getStartedLobby (date) {
            date = moment(date)
            return date.format('DD.MM HH:mm')
        },
        init () {
            this.centrifugeConnect()
            this.getMeta();
        },
        toggleOpenLobby (lobby) {
            if (this.lobbyOpenedList.includes(lobby)) {
                this.lobbyOpenedList.splice(this.lobbyOpenedList.indexOf(lobby), 1)
                return
            }

            this.lobbyOpenedList.push(lobby)
        },
        getMeta () {
            multiarena.getMeta(this.tournament.getId()).then(response => {
                this.meta = response.data.data
                this.selectRound = this.meta.length
            })
        },
        updateRoom(lobby) {
            leaderboard.update(this.id, lobby.id, {
                room_id: lobby.room_id,
                password: lobby.password,
            }).then(() => {
                this.getTournament(this.tournament.getId())
            })
        },
    },
    mounted () {
        this.init()
    },
    components: {
        MultilobbyMatch,
        EditLobby
    }
}
</script>
<style scoped lang="scss">
.multilobby {
    .multilobby__rounds {
        display: flex;

        .multilobby__rounds-round {
            background: #ECECEC;
            border-radius: 4px;
            min-width: 178px;
            padding: 10px 15px;
            border: 2px solid transparent;
            transition: 0.1s;
            cursor: pointer;

            .round {
                font-weight: bold;
                font-size: 18px;
                line-height: 21px;
                text-transform: uppercase;
                color: #878787;
            }

            .date {
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;
                color: #878787;
            }

            &.active {
                border: 2px solid #06C2BC;

                .round {
                    color: #303030;
                }
            }

        }
        .next__round {
            margin-left: 20px;
        }

        .multilobby__rounds-round + .multilobby__rounds-round {
            margin-left: 20px;
        }
    }
    .multilobby__lobbies {
        margin-top: 25px;
        .multilobby__lobby {
            .multilobby__lobby-head {
                background: #ECECEC;
                border-radius: 4px;
                padding: 10px 15px;
                display: flex;
                justify-content: space-between;
                position: relative;
                align-items: center;
                .multilobby__lobby-head__info {
                    span {
                        display: block;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 18px;
                        text-transform: uppercase;
                        color: #303030;
                    }
                    em {
                        font-weight: normal;
                        font-style: normal;

                    }
                }
                .multilobby__lobby-head__credentials {
                    padding-right: 80px;
                    display: flex;
                    overflow: hidden;
                    .room__instance-header_started_at {
                        padding: 0px 6px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        .date__time {
                            padding: 10px 11px;
                            background: #D6D6D6;
                            margin-left: 10px;
                            border-radius: 4px;
                        }
                    }

                    .multilobby__lobby-head__credentials-item {
                        label {
                            color: #00E7DF;
                            display: block;
                            margin-bottom: 2px;
                        }
                        input {
                            width: 100%;
                            background: #fff;
                            padding: 0 15px;
                            font-size: 16px;
                            line-height: 40px;
                            border: 1px solid #878787;
                            border-radius: 5px;
                        }

                    }
                    .multilobby__lobby-head__credentials-item + .multilobby__lobby-head__credentials-item {
                        margin-left: 15px;
                    }
                }
                .multilobby__lobby-head__arrow {
                    position: absolute;
                    transform: rotate(180deg);
                    cursor: pointer;
                    padding: 20px;
                    right: 0;
                    transition: 0.2s;
                    &.transform {
                        transform: rotate(0deg);
                    }
                }
            }
        }
        .multilobby__lobby + .multilobby__lobby {
            margin-top: 30px;
        }
    }

}
</style>
