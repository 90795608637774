<template>
	<div>
		<div class="page__content-header">
			<div>
				<page-links v-bind:links="[
					{
						url: '/',
						text: $t('All Tournaments')
					}
				]"/>
			</div>
			<div >
				<RouterLink to="/tournament/add" :class="'button button-primary'">{{$t('Create New Tournament')}}</RouterLink>
			</div>
		</div>
		<div>
            <div class="filters__wrapper">
                <div>
                    <label for="" class="filter__label">
                        Filters:
                    </label>
                    <div class="tournament__filters">
                        <FormMultiSelect
                            v-if="games && games.length"
                            :placeholder="$t('Game')"
                            :options="games"
                            :searchable="true"
                            v-model="filters.games"
                            @input="changeFilters"
                            :multiple="false"
                            label="name"
                            :selectLabel="''"
                        ></FormMultiSelect>
                        <FormMultiSelect
                            :placeholder="$t('Tournament type')"
                            :clear="false"
                            @input="changeFilters"
                            :options="TOURNAMENT_TYPES_VALUES"
                            :searchable="false"
                            v-model="filters.tournament_type"
                            label="name"
                            :selectLabel="''"
                        >
                            <template slot="option" slot-scope="props">
                                <div class="option__participant">
                                    <div class="option__participant-avatar">
                                        <img :src="'/images/logo.svg'" alt="">
                                    </div>
                                    <div class="option__participant-info">
                                        <strong>{{ props.option.name }}</strong>
                                    </div>
                                </div>
                            </template>
                        </FormMultiSelect>
                        <FormMultiSelect
                            :placeholder="$t('Match type')"
                            :clear="false"
                            :options="MATCH_TYPES_VALUES"
                            :searchable="false"
                            @input="changeFilters"
                            v-model="filters.match_type"
                            label="name"
                            :selectLabel="''"
                        ></FormMultiSelect>
                    </div>
                </div>
                <div>
                    <label for="" class="filter__label">
                        Sort by:
                    </label>
                    <div class="tournament__filters">
                        <FormMultiSelect
                            v-if="games && games.length"
                            :placeholder="$t('Order by')"
                            @input="changeFilters"
                            :options="[
                                {
                                    orderBy: 'id',
                                    dir: 'DESC',
                                    name: 'Latest first'
                                },
                                {
                                    orderBy: 'status',
                                    dir: 'ASC',
                                    name: 'Relevant first (status)'
                                },
                                {
                                    orderBy: 'id',
                                    dir: 'ASC',
                                    name: 'Old first'
                                },
                                {
                                    orderBy: 'started_at',
                                    dir: 'DESC',
                                    name: 'Start time'
                                }
                            ]"
                            :searchable="false"
                            v-model="filters.sort"
                            label="name"
                            :selectLabel="''"
                        ></FormMultiSelect>
                    </div>
                </div>
            </div>

            <transition name="slide">
                <div class="table-wrapper" v-if="!loading">
                    <table v-if="tournaments.length">
                        <thead class="text-left">
                        <tr>
                            <th scope="col">{{$t('ID')}}</th>
                            <th scope="col">{{$t('Title')}}</th>
                            <th scope="col">{{$t('Game')}}</th>
                            <th scope="col">{{$t('Max Players')}}</th>
                            <th scope="col">{{$t('Start Date')}}</th>
                            <th scope="col">{{$t('Start Time')}}</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="tournament in tournaments">
                            <td @click="goToPage('/tournament/' + tournament.getId())" scope="row">
                                {{tournament.getId()}}
                            </td>
                            <td @click="goToPage('/tournament/' + tournament.getId())">
                                {{tournament.getTitle()}}
                            </td>
                            <td @click="goToPage('/tournament/' + tournament.getId())">
                                {{tournament.getGameName()}}
                            </td>
                            <td @click="goToPage('/tournament/' + tournament.getId())">
                                {{tournament.getMaxMembers()}}
                            </td>
                            <td @click="goToPage('/tournament/' + tournament.getId())">
                                {{tournament.getStartedAt() | readableDateFormat | getDate}}
                            </td>
                            <td @click="goToPage('/tournament/' + tournament.getId())">
                                {{tournament.getStartedAt() | readableDateFormat | getTime}}
                            </td>
                            <td v-if="!isTournamentManager">
                                <dropdown
                                    :data-position="'left'"
                                    :data-horizontal-aligntment="'left'"
                                    :data-vertical-aligntment="'top'"
                                    :toggle-class="'dots-toggle'"
                                >
                                    <svg-icon
                                        slot="dropdown-toggle"
                                        width="23"
                                        height="5"
                                        view-box="0 0 23 5"
                                        :iconType="'dots'"
                                    />
                                    <div slot="dropdown-content">
                                        <RouterLink
                                            :class="'dropdown__link'"
                                            :to="{path: '/tournament/edit/' +  tournament.getId()}"
                                        >
                                            <svg-icon
                                                width="16"
                                                height="16"
                                                :iconType="'edit'"
                                            />
                                            <span>{{$t('Edit')}}</span>
                                        </RouterLink>
                                        <RouterLink
                                            :class="'dropdown__link'"
                                            :to="{ path: '/tournament/' +  tournament.getId() }"
                                        >
                                            <svg-icon
                                                width="16"
                                                height="16"
                                                :iconType="'copy'"
                                            />
                                            <span>{{$t('View')}}</span>
                                        </RouterLink>
                                        <RouterLink
                                            :class="'dropdown__link'"
                                            :to="{ path: '/tournament/add/?donor_id=' +  tournament.getId() }"
                                        >
                                            <svg-icon
                                                width="16"
                                                height="16"
                                                :iconType="'plus'"
                                            />
                                            <span>{{$t('Clone')}}</span>
                                        </RouterLink>
                                        <a
                                            href="#"
                                            class="dropdown__link"
                                            @click.prevent="deleteId = tournament.getId()"
                                        >
                                            <svg-icon
                                                width="16"
                                                height="16"
                                                :iconType="'delete'"
                                            />
                                            <span>{{$t('Delete')}}</span>
                                        </a>
                                    </div>
                                </dropdown>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </transition>
			<page-pagination
				v-if="tournamentsMeta && !loading"
				:current="tournamentsMeta.currentPage()"
				:total="tournamentsMeta.lastPage()"
				@change="changePage"
			/>
		</div>
        <ConfirmDialog v-if="deleteId" @close="deleteId = null" :callback="deleteTournamentItem" text="Are you sure you want to delete the tournament?"></ConfirmDialog>
	</div>
</template>

<script>
import { TOURNAMENT_TYPES_VALUES, MATCH_TYPES_VALUES } from '../../constants/form'
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
	import {mapGetters, mapActions, mapMutations} from 'vuex'
    import QlashLoader from '../../components/QlashLoader'
	import SvgIcon from '../../components/SvgIcon'
	import Dropdown from '../../components/Dropdown'
	import PageLinks from '../../components/Page/Links'
	import FiltersBar from '../../components/FiltersBar'
	import PagePagination from '../../components/Page/Pagination'
	import router from '../../routes'
    import FormMultiSelect from 'vue-multiselect'
	export default {
		components: {
            QlashLoader,
            FormMultiSelect,
			SvgIcon,
			Dropdown,
            ConfirmDialog,
			PageLinks,
			FiltersBar,
			PagePagination
		},
		computed: {
			...mapGetters([
				'tournaments',
				'games',
				'tournamentsMeta',
				'isTournamentManager',
				'isSuperAdmin',
				'activeFilters'
			])
		},
		data() {
			return {
                TOURNAMENT_TYPES_VALUES: TOURNAMENT_TYPES_VALUES,
                MATCH_TYPES_VALUES: MATCH_TYPES_VALUES,
                deleteId: null,
				filters: {
                    games: null,
                    tournament_type: null,
                    match_type: null,
                    sort: {
                        orderBy: 'status',
                        dir: 'DESC',
                        name: 'Relevant first (status)'
                    }
                },
				filtersUtil: this.$options.filters,
                loading: false,
			}
		},
		created() {
			this.init();
		},
		methods: {
			...mapActions({
				getTournaments: 'GET_TOURNAMENTS',
				deleteTournament: 'DELETE_TOURNAMENT',
				getGames: 'getGames'
			}),
			...mapMutations({
				setFilters: 'SET_FILTERS',
				hideDropdown: 'HIDE_DROPDOWN',
			}),
            getFilterFields () {
                const data = {};
                const filters = sessionStorage.getItem('main') ? JSON.parse(sessionStorage.getItem('main')) : {}
                if (!filters) return [];

                if (filters.games) {
                    data.games = filters.games.id
                }
                if (filters.match_type) {
                    data.match_type = filters.match_type.value
                }
                if (filters.tournament_type) {
                    data.tournament_type = filters.tournament_type.value
                }
                if (filters.sort) {
                    data.order = filters.sort.orderBy
                    data.direction = filters.sort.dir
                }

                return data;
            },
            prepareFields () {
                const data = {}
                if (this.filters.games) {
                    data.games = this.filters.games
                }

                if (this.filters.match_type) {
                    data.match_type = this.filters.match_type
                }
                if (this.filters.tournament_type) {
                    data.tournament_type = this.filters.tournament_type
                }
                if (this.filters.sort && this.filters.sort.orderBy && this.filters.sort.dir) {
                    data.sort = this.filters.sort
                }
                sessionStorage.setItem('main', JSON.stringify(data))
            },
            async changeFilters () {
                await this.prepareFields()
                await this.getTournamentsMethod()
            },
            async getTournamentsMethod (page = 1) {
                this.loading = true

                await this.getTournaments({
                    page: page,
                    ...this.getFilterFields()
                });

                this.loading = false
            },
			init() {
                this.getTournamentsMethod();
				this.prepareFiltersFields();
			},
			async prepareFiltersFields() {
				await this.getGames();

                const filters = sessionStorage.getItem('main') ? JSON.parse(sessionStorage.getItem('main')) : {}
                if (filters) {
                    if (filters.games) {
                        this.filters.games = filters.games
                    }
                    if (filters.match_type) {
                        this.filters.match_type = filters.match_type
                    }
                    if (filters.tournament_type) {
                        this.filters.tournament_type = filters.tournament_type
                    }
                    if (filters.sort) {
                        this.filters.sort = filters.sort
                    }
                }
			},
			async deleteTournamentItem() {
                const id = this.deleteId

				this.hideDropdown();

				await this.deleteTournament(id).then(err => {
                    if (err.response && err.response.status !== 200) {
                        this.$notify({
                            type: 'error',
                            title: 'Permission error',
                            group: 'notify',
                            text: err.response.data.message ? err.response.data.message : 'Unknown error'
                        })
                    }
                }).finally(() => {
                    this.deleteId = null
                })
				await this.getTournamentsMethod()
			},
			changePage(page) {
				this.getTournamentsMethod(page)
			},
			goToPage(url) {
				router.push(url)
			},
		},
	}
</script>
<style scoped lang="scss">

.page__content {
    overflow-y: hidden !important;
}
.slide-enter-active, .slide-leave-active {
    transition: all .15s ease-in;
    top: -10px;
}
.table-wrapper {
    min-height: 200px;
}

.slide-enter, .slide-leave-to {
    opacity: 0;
}

.slide-enter-to, .slide-leave {
    opacity: 1;
    top: 0;
}

.loading {
    width: 90%;
    margin: 30px;
    display: flex;
    justify-content: center;
}
.filters__wrapper {
    max-width: 90%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.filter__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #06C2BC;
    display: block;
    margin-bottom: 5px;
}
.tournament__filters {
    display: flex;
    .multiselect {
        width: 250px;
    }
    .multiselect + .multiselect {
        margin-left: 15px;
    }
}
@media screen and (max-width: 1300px) and (min-width: 1205px) {
    table {
        max-width: 100%;
    }
}
@media screen and (max-width: 1300px) {
    .filters__wrapper {
        max-width: 100%;
    }
}
@media screen and (max-width: 1205px) {
    .filters__wrapper {
        max-width: 100%;
        flex-wrap: wrap;
        & > div {
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .tournament__filters {
        flex-wrap: wrap;
        .multiselect {
            margin-left: 0 !important;
            margin-bottom: 10px;
            width: 100%;
        }
    }
}
.option__participant {
    display: flex;
    padding: 0 5px;
    align-items: center;
    .option__participant-avatar {
        margin-right: 12px;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
        }
    }
    .option__participant-info {
        strong {
            width: 100%;
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-transform: capitalize;
            margin-bottom: 3px;
            color: #1C1C1C;
        }
        span {
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            display: block;
            width: 100%;
            color: #878787;
        }
    }
    &:hover {
        .option__participant-info {

        }
    }
}
::v-deep {
    .multiselect__content-wrapper {

        &::-webkit-scrollbar {
            background-color: #fff;
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #06C2BC;
            border-radius: 16px;
        }
    }
    .multiselect__option--highlight {
        .option__participant {
            strong {
                color: #fff;
            }
        }
    }
    .multiselect__element:hover {
        transition: 0.1s;
        color: #fff;
        opacity: 0.9;
        background: #c8fbf9;
        .option__participant {
            strong {
                color: #fff;
            }
        }
    }
}
</style>
