export default function Match( data = null) {
	const info = data;

	this.getReporterID = function () {
		return info.reporter_id;
	};

	this.getType = function () {
		return info.type;
	};

	this.getImage = function () {
	    if (info.value_state !='0' && info.value_state !='1'){
            return info.value_state
        }

        return null;
	};
}
