<template>
    <div>
        <div class="page__content-header">
            <div>
                <page-links v-bind:links="[
					{
						url: '/rules',
						text: $t('All Games')
					}
				]"/>
            </div>
            <div>
                <RouterLink to="/games/add" :class="'button button-primary'">{{$t('Create new game')}}</RouterLink>
            </div>
        </div>
        <div>

            <table v-if="games">
                <thead class="text-left">
                <tr>
                    <th scope="col">{{$t('#')}}</th>
                    <th scope="col">{{$t('Name')}}</th>
                    <th scope="col">{{$t('Display in tournaments')}}</th>
                    <th scope="col" style="text-align: right; padding-right: 30px;">{{$t('Actions')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr class="tr" v-for="game in games">
                    <td>
                        {{ game.id }}
                    </td>
                    <td>
                        <div class="game__title">
                            <img :src="game.icon" alt="">
                            <span>{{ game.name }}</span>
                        </div>
                    </td>
                    <td>
                        <div class="game__available">
                            <RadialCheckbox :input-value="game.display_in_tournaments" @change="updateGameStatus($event, game.id)" v-model="game.display_in_tournaments"></RadialCheckbox>
                            <div :class="{'accent': game.display_in_tournaments}" class="game__available-status">
                                <template v-if="game.display_in_tournaments">
                                    Display
                                </template>
                                <template v-else>
                                    Hide
                                </template>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="game__actions">
                            <router-link :to="`/games/${game.id}`" class="game__action">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 0.00012207C3.58065 0.00012207 0 3.61303 0 8.00012C0 12.4195 3.58065 16.0001 8 16.0001C12.3871 16.0001 16 12.4195 16 8.00012C16 3.61303 12.3871 0.00012207 8 0.00012207ZM8 3.54851C8.74194 3.54851 9.35484 4.16141 9.35484 4.90335C9.35484 5.67754 8.74194 6.25819 8 6.25819C7.22581 6.25819 6.64516 5.67754 6.64516 4.90335C6.64516 4.16141 7.22581 3.54851 8 3.54851ZM9.80645 11.7421C9.80645 11.9679 9.6129 12.1292 9.41935 12.1292H6.58065C6.35484 12.1292 6.19355 11.9679 6.19355 11.7421V10.9679C6.19355 10.7743 6.35484 10.5808 6.58065 10.5808H6.96774V8.51625H6.58065C6.35484 8.51625 6.19355 8.35496 6.19355 8.12915V7.35496C6.19355 7.16141 6.35484 6.96786 6.58065 6.96786H8.64516C8.83871 6.96786 9.03226 7.16141 9.03226 7.35496V10.5808H9.41935C9.6129 10.5808 9.80645 10.7743 9.80645 10.9679V11.7421Z" fill="#878787"/>
                                </svg>
                            </router-link>
                            <router-link :to="`/games/${game.id}`" class="game__action">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6101 3.93368C14.13 3.42073 14.13 2.58382 13.6101 2.09787L11.9684 0.478033C11.4758 -0.0349142 10.6276 -0.0349142 10.1077 0.478033L8.84909 1.71991C8.73964 1.8279 8.73964 2.04387 8.84909 2.17886L11.8863 5.17555C12.0231 5.28354 12.242 5.28354 12.3514 5.17555L13.6101 3.93368ZM7.78197 2.7728L0.585746 9.87307L0.011143 13.1397C-0.0709432 13.5987 0.312126 13.9767 0.777281 13.8957L4.08809 13.3287L11.2843 6.22845C11.4211 6.12046 11.4211 5.90448 11.2843 5.76949L8.24713 2.7728C8.11032 2.66481 7.89142 2.66481 7.78197 2.7728ZM3.40404 9.25214C3.23987 9.11715 3.23987 8.87418 3.40404 8.73919L7.6178 4.58161C7.75461 4.41963 8.00087 4.41963 8.13768 4.58161C8.30185 4.7166 8.30185 4.95958 8.13768 5.09456L3.92392 9.25214C3.78711 9.41412 3.54085 9.41412 3.40404 9.25214ZM2.41901 11.5199H3.73238V12.5188L1.95385 12.8158L1.10563 11.9789L1.40661 10.224H2.41901V11.5199Z" fill="#878787"/>
                                </svg>
                            </router-link>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import RadialCheckbox from '../../components/UI/RadialCheckbox'
import SvgIcon from '../../components/SvgIcon';
import PageLinks from '../../components/Page/Links';
import {getList, updateAvailable} from "../../api/games";

export default {
    data () {
        return {
            games: []
        }
    },
    methods: {
        updateGameStatus ($value, id) {
            updateAvailable(id, {
                display_in_tournaments: $value ? 1 : 0
            }).then(response => {
                console.log(response)
            })
        },
        getGames () {
            getList().then(response => {
                this.games = response.data
            })
        }
    },
    mounted() {
        this.getGames()
    },
    components: {
        RadialCheckbox,
        SvgIcon,
        PageLinks,
    }
}
</script>
<style scoped lang="scss">
table {
    .tr {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        box-shadow: 0px 0px 4px rgba(0, 80, 77, 0.1), 0px 2px 10px rgba(0, 80, 77, 0.25);
    }
    td {
        padding-top: 0;
        padding-bottom: 0;
        .game__title {
            display: flex;
            align-items: center;
            img {
                margin-right: 20px;
                width: 30px;
                height: 30px;
                border-radius: 5px;
            }
            span {
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: #303030;
            }
        }
        .game__available {
            display: flex;
            align-items: center;
            .game__available-status {
                margin-left: 10px;
                background: #C4C4C4;
                border-radius: 4px;
                padding: 6px 10px;
                &.accent {
                    background: #00E7DF;
                }
            }
        }
        .game__actions {
            justify-content: flex-end;
            display: flex;
            height: 100%;
            .game__action {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 16px;
                border-left: 1px solid #D6D6D6;
                svg {
                    path {
                        transition: 0.2s;
                    }
                }
                &:hover {
                    svg {
                        path {
                            fill: #00E7DF
                        }
                    }
                }
            }
        }
    }
}
</style>
