export default (brackets) => {
    brackets = brackets.map(item => {
        const games = item.games.filter(i => i.is_looser_match)
        return {
            games
        }
    })
    brackets = brackets.filter(item => item.games.length > 0)
    return brackets
};
