import http from '../utils/http'

export function getConfig () {
    return http.get('config');
}

export function updateConfig (config_name, value) {
    return http.put(`config/${config_name}`, {
        value
    });
}
