<template>
    <div class="controls__rooms">
        <div class="rooms_list">
            <RoomInstance v-for="(room, i) in tournament.getRR().rooms"
                          @addExclude="addToExclude"
                          @removeFromExclude="removeFromExcludeMethod"
                          :exclude-ids="excludeIds"
                          :key="i"
                          :room="room"></RoomInstance>
        </div>
        <button class="add-new-room" @click="isShowAddNewRooms = true">
            Add new room
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1C8 0.447715 7.55228 0 7 0H6C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6V7C0 7.55228 0.447715 8 1 8H5V12C5 12.5523 5.44772 13 6 13H7C7.55228 13 8 12.5523 8 12V8H12C12.5523 8 13 7.55228 13 7V6C13 5.44772 12.5523 5 12 5H8V1Z" fill="#00CBCB"/>
            </svg>
        </button>
        <CreateRoomModal @close="isShowAddNewRooms = false" v-if="isShowAddNewRooms"></CreateRoomModal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import RoomInstance from './Components/RoomInstance'
import CreateRoomModal from './Modals/CreateRoomModal'
export default {
    data () {
        return {
            isShowAddNewRooms: false,
            excludeIds: new Set()
        }
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament',
            participants: 'participants'
        })
    },

    methods: {
      ...mapActions({
          getParticipants: 'GET_ROUND_ROBIN_PARTICIPANTS'
      }),
        removeFromExcludeMethod (id) {
          this.excludeIds.delete(id)
        },
        addToExclude (id) {
          this.excludeIds.add(id)
        },
        setExcludeParticipants () {
            this.tournament.getRR().rooms.forEach(item => {
                item.participants.forEach(i => {
                    this.excludeIds.add(i.participant.id)
                })
            })
        }
    },
    async mounted () {
      await this.getParticipants({ id: this.tournament.getId() });
      this.setExcludeParticipants();
    },
    components: {
        CreateRoomModal,
        RoomInstance
    }
}
</script>

<style scoped lang="scss">
.controls__rooms {
    margin-top: 20px;
    .rooms_list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
    }
    .add-new-room {
        background: #ECECEC;
        border-radius: 4px;
        margin-top: 25px;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
        transition: 0.2s;
        padding: 10px 15px;
        text-align: left;
        font-weight: 700;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-right: 127px;
        line-height: 21px;
        text-transform: uppercase;
        color: #303030;
        outline: none;
        border: none;
        position: relative;
        svg {
            position: absolute;
            right: 15px;
        }
    }
}
</style>
