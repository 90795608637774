<template>
	<div>
		<form class="form form--xl">
			<div class="form-group">
				<FormInput
					:name="'tournament'"
					:label="$t('Tournament')"
					:placeholder="$t('Tournament')"
					:value="form.tournament"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group">
				<FormInput
					:name="'match'"
					:label="$t('Match')"
					:placeholder="$t('match')"
					:value="form.match"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group">
				<FormInput
					:name="'message'"
					:label="$t('Message')"
					:placeholder="$t('message')"
					:value="form.message"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group">
				<FormInput
					:name="'count'"
					:label="$t('Count')"
					:placeholder="$t('count')"
					:value="form.count"
					:onChange="formFieldChanged"
				/>
			</div>
			<button
				class="button button-primary form-button-submit"
				@click.prevent="send()"
			>
				{{$t('Send')}}
			</button>
		</form>

	</div>
</template>

<script>
	import matchApi from '../../api/match'
  import FormInput from '../../components/Form/Input'

	export default {
	  components: {
		  FormInput
	  },
		data() {
			return {
				form: {
					tournament: '',
					match: '',
					message: '',
					count: '',
				},
				errors: {},
			};
		},
		methods: {
			formFieldChanged(field, value) {
				this.form[field] = value;
			},
			send() {
				const {tournament, match, message, count} = this.form;
				if (tournament && match && message && count > 0) {
					for (let i = 0; i < Number(count); i++) {
						matchApi.sendMessage(tournament, match, message);
					}
				}
			}
		}
	}
</script>