<template>
    <div
        class="leaderboard__start-tournament-button"
    >
        <button
            class="button button-primary"
            @click.prevent="start"
        >{{$t('Generate lobbies')}}
        </button>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import MultilobbyService from '../../api/multiarena'
export default {
    name: "LeaderboardStartButton",
    computed: {
        ...mapGetters({
            tournament: 'tournament',
            tournamentStatus: 'tournamentStatus'
        })
    },
    methods: {
        ...mapActions({
            getTournament: 'GET_TOURNAMENT'
        }),
        async start() {
            await MultilobbyService.generateLobbies(this.tournament.getId()).catch(e => {
                this.$notify({
                    type: 'error',
                    title: 'Start error',
                    group: 'notify',
                    text: e
                })
            });

            await this.getTournament(this.tournament.getId())
        },
    }
};
</script>
