<template>
    <div class="multilobby">
        <div class="multilobby__rounds">
            <div class="multilobby__rounds-wrapper"
                 v-for="(round, i) in matches"
                 :key="i">
                <div class="multilobby__rounds-round">
                    <div class="round">
                        Round {{ round.round }}
                    </div>
                    <div class="date">
                        {{ getReadableDate(round.started_at) }} | {{getReadableTime(round.started_at)}}
                    </div>
                </div>
                <div class="multilobby__rounds-matches">
                    <TftMatch @openWinnersDialog="selectWinnersMatch = $event" v-for="match in round.matches" :match="match" :key="match.id"></TftMatch>
                </div>
            </div>

        </div>
        <SelectWinnersDialog @update="getMatches" :tournamentId="tournament.getId()" @close="selectWinnersMatch = null" v-if="selectWinnersMatch" :match="selectWinnersMatch"></SelectWinnersDialog>
        <RevertMatchDialog @update="getMatches" />
        <ChatsListContainer :id="id"/>
    </div>
</template>
<script>
import ChatsListContainer from '../Chat/ChatsListContainer'
import TftMatch from './TftMatch'
import SelectWinnersDialog from './SelectWinnersDialog'
import tft from "../../api/tft";
import {mapGetters, mapMutations, mapActions} from 'vuex'
import RevertMatchDialog from "../Dialogs/RevertMatch";
import moment from "moment";
export default {
    props: {
        id: {
            required: true
        }
    },
    data () {
        return {
            selectWinnersMatch: null,
            matches: []
        }
    },
    components: {
        ChatsListContainer,
        RevertMatchDialog,
        TftMatch,
        SelectWinnersDialog
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament'
        }),
        isFinished() {
            return 'FINISHED' === this.tournament.getStatus();
        },
    },
    methods: {
        ...mapMutations({
           initTournament: 'TOURNAMENT_INIT'
        }),
        ...mapActions({
            centrifugeConnect: 'centrifugeConnect'
        }),
        getReadableDate(date) {
            return moment(date).format('YYYY/MM/DD HH:mm:ss').split(' ')[0]
        },
        getReadableTime(date) {
            return moment(date).format('YYYY-MM-DD HH:mm').split(' ')[1]
        },
        init () {
            this.initTournament(this.tournament.getId());
            this.centrifugeConnect();
            this.getMatches();
        },
        getMatches () {
            tft.getMeta(this.tournament.getId()).then(response => {
                this.matches = response.data.data
            })
        }
    },
    mounted () {
        this.init()
    }
}
</script>
<style scoped lang="scss">
.multilobby {
    .multilobby__rounds {
        .multilobby__rounds-round {
            background: #ECECEC;
            border-radius: 4px;
            margin-bottom: 15px;
            width: 100%;
            min-width: 178px;
            padding: 10px 15px;
            border: 2px solid transparent;
            transition: 0.1s;
            cursor: pointer;

            .round {
                font-weight: bold;
                font-size: 18px;
                line-height: 21px;
                text-transform: uppercase;
                color: #303030;
                margin-bottom: 7px;
            }

            .date {
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;
                color: #878787;
            }

            &.active {
                border: 2px solid #06C2BC;

                .round {
                    color: #303030;
                }
            }

        }
    }
    .multilobby__rounds-wrapper {
        margin-bottom: 15px;
        .multilobby__rounds-matches {
            max-width: 100%;
            overflow-x: auto;
            padding-right: 25px;
            display: flex;
            &::-webkit-scrollbar {
                background-color: #fff;
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-track {
                background-color: #f4f4f4;
            }


            &::-webkit-scrollbar-thumb {
                background-color: #06C2BC;
                border-radius: 16px;
            }
            .brackets__item-content + .brackets__item-content {
                margin-left: 20px;
            }
        }
    }

}
</style>
