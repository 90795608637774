import http from '../utils/http'
import joinUrls from '../utils/join-urls'

export default {
	get(data = {}) {
		return http.get('/seasons', data)
	},
	getSeason(id) {
		return http.get(joinUrls('/seasons', id))
	},
	add(data) {
		return http.postMultipart('/seasons', data)
	},
	update(id, data) {
		if (!id) {
			return Promise.reject(new Error('Seasons id is required to update season data'))
		}

		return http.putMultipart(joinUrls('/seasons', id), data)
	},
	deleteSeason(id) {
		if (!id) {
			return Promise.reject(new Error('Seasons id is required to delete season data'))
		}

		return http.delete(joinUrls('/seasons', id))
	},
}