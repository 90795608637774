<template>

  <transition name="fade" mode="out-in">
    <div v-if="show" :class="classes">
        <p>{{ message }}</p>
        <a href="#" @click="closeToast">&times;</a>
    </div>
  </transition>
</template>
<script>
import { TOAST_MESSAGE_TYPE_INFO } from '../store/modules/toast-events'

export default {
  name: 'Toast',
  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: TOAST_MESSAGE_TYPE_INFO
    },
    duration: {
      type: Number,
      default: 5000
    }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    classes() {
      return [
        'toast',
        `toast--${this.type}`
      ]
    }
  },
  mounted() {
    this.showToast()
  },
  methods: {
    showToast() {
      this.show = true
      setTimeout(() => {
        this.closeToast()
      }, this.duration)
    },
    closeToast() {
      this.show = false;
      this.$emit('close');
    }
  },
}

</script>

<style lang="scss" scoped>
.toast {
  margin-bottom: 4px;
  background: #333;
  color: #fff;
  padding: 8px 12px;
  border-radius: 3px;
  width: 280px;
  font-size: 12px;
  display: flex;
  p {
    padding-right: 8px;
  }
  a {
    margin-left: auto;
    background: white;
    line-height: 21px;
    display: block;
    border-radius: 50%;
    height: 21px;
    text-align: center;
    padding: 0 7px;
  }
}
.toast--success {
  background: #00E7DF;
  color: #333;
}
.toast--error {
  background: #FF0000;
  color: #fff;
}
.toast--warning {
  background: #FFA500;
  color: #fff;
}
.toast--info {
  background: #00BFFF;
  color: #fff;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
</style>
