import { getConfig } from '../../api/config'

export const TOAST_MESSAGE_TYPE_ERROR = 'error';
export const TOAST_MESSAGE_TYPE_WARNING = 'warning';
export const TOAST_MESSAGE_TYPE_INFO = 'info';
export const TOAST_MESSAGE_TYPE_SUCCESS = 'success';

const state = () => ({
	events: [],
});

const getters = {
    getEvents: state => state.events,
};

const actions = {

};

const mutations = {
	ADD_EVENT: (state, {message, type}) => {

        state.events.push({message, type, id: new Date().getTime()})
	},
    REMOVE_EVENT: (state,  eventId ) => {
        state.events.splice(state.events.findIndex(event => event.id === eventId), 1)
    },
    CLEAR_EVENTS:  (state) => {
        state.events = []
    },
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
