import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import brackets from './modules/brackets'
import tournaments from './modules/tournaments'
import tournament from './modules/tournament'
import participants from './modules/participants'
import rules from './modules/rules'
import deepLinks from './modules/deep-links'
import notifications from './modules/notifications'
import matches from './modules/matches'
import centrifuge from './modules/centrifuge'
import chat from './modules/chat'
import dialogs from './modules/dialogs'
import events from './modules/events'
import bracketTemplates from './modules/bracket-templates'
import roomTemplates from './modules/room-templates'
import news from './modules/news'
import leaderboard from './modules/leaderboard'
import filters from './modules/filters'
import seasons from './modules/seasons'
import teams from './modules/teams'
import clans from './modules/clans'
import countries from './modules/countries'
import regions from './modules/regions'
import advertisements from './modules/advertisements'
import terms from './modules/terms'
import main from './modules/main'
import toastEvents from './modules/toast-events'

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		main,
		auth,
		tournaments,
		tournament,
		participants,
		rules,
        roomTemplates,
		deepLinks,
		chat,
		matches,
		notifications,
		brackets,
		centrifuge,
		dialogs,
		events,
		bracketTemplates,
		news,
		leaderboard,
		filters,
		seasons,
		countries,
		regions,
		advertisements,
		teams,
		clans,
        terms,
        toastEvents
	},
})
