<template>
	<div class="leaderboard__generating-loader">
		<h5>{{$t('Starting tournament. Hold on for a while.')}}</h5>
		<span class="loader"></span>
	</div>
</template>

<script>
	export default {
		name: "LeaderboardGeneratingLoader",
	};
</script>