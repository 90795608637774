<template>
    <div class="post">
        <div class="post__left">
            <span class="post__title">{{$t('Thumbnail')}}</span>
            <img class="post__image" :src="tournament.getImage() ? tournament.getImage() : '/images/placeholder.png'" alt="">
            <span v-if="!isMobile" class="post__title">{{$t('Status')}}</span>
            <StatusProgress v-if="!isMobile" :current-status="tournament.getStatus()"/>
        </div>
        <div class="post__right">
            <ul class="post__list">
                <li v-if="isMobile">
                    <span class="post__title">{{$t('Status')}}</span>
                    <span class="post__description">{{ tournament.getStatus() }}</span>
                </li>
                <li>
                    <span class="post__title">{{$t('Title')}}</span>
                    <span class="post__description">{{ tournament.getTitle() }}</span>
                </li>
                <li>
                    <span class="post__title">{{$t('Secret code')}}</span>
                    <span class="post__description">{{ tournament.getSecretCode() }}</span>
                </li>
                <li>
                    <span class="post__title">{{$t('Is Private?')}}</span>
                    <span class="post__description">{{ tournament.getIsPrivate() ? $t('Yes') : $t('No') }}</span>
                </li>
                <li v-if="game">
                    <span class="post__title">{{$t('Game')}}</span>
                    <span class="post__description">{{game.name || ''}}</span>
                </li>

                <li v-if="tournament.region">
                    <span class="post__title">{{$t('Region')}}</span>
                    <span class="post__description">{{tournament.region}}</span>
                </li>

                <li v-if="tournament.getCountry() && tournament.getCountry().length && filteredCountries">
                    <span class="post__title">{{$t('Countries')}}</span>
                    <span class="post__description">
                            <template v-for="(country, i) in filteredCountries" v-if="country.info">{{ country.info.value }}
                            <template v-if="filteredCountries[i+1]">, </template>
                            </template>
                        </span>
                </li>

                <li v-if="tournament.getType()">
                    <span class="post__title">{{$t('Type')}}</span>
                    <span class="post__description">
                            <template v-if="tournament.getType() === 1">
                                Single Elimination
                            </template>
                            <template v-if="tournament.getType() === 2">
                                Arena
                            </template>
                            <template v-if="tournament.getType() === 3">
                                Double Elimination
                            </template>
                            <template v-if="tournament.getType() === 4 || tournament.getType() === 7">
                                Round Robin
                            </template>
                            <template v-if="tournament.getType() === 5">
                                Multi lobby arena
                            </template>

                        </span>
                </li>

                <li v-if="rule">
                    <span class="post__title">{{$t('Rule')}}</span>
                    <span class="post__description">{{rule.getTitle()}}</span>
                </li>
                <li v-if="platform">
                    <span class="post__title">{{$t('Platform')}}</span>
                    <span class="post__description">{{platform.getName()}}</span>
                </li>
                <li>
                    <span class="post__title">{{$t('Max members')}}</span>
                    <span class="post__description">{{ tournament.getMaxMembers() }}</span>
                </li>
                <li>
                    <span class="post__title">{{$t('Deeplink')}}</span>
                    <span class="post__description">{{ tournament.getDeeplink() }}</span>
                </li>
                <li>
                    <span class="post__title">{{$t('Start time')}}</span>
                    <span class="post__description">{{ tournament.getStartedAt() | readableDateFormat }}</span>
                </li>
                <li>
                    <span class="post__title">{{$t('Checkin time')}}</span>
                    <span class="post__description">{{ tournament.getCheckInTime() }}</span>
                </li>
                <li>
                    <span class="post__title">{{$t('Discord channel')}}</span>
                    <span class="post__description">{{ tournament.getChatLink() }}</span>
                </li>
                <li>
                    <span class="post__title">{{$t('Description')}}</span>
                    <span class="post__description">{{ tournament.getDescription() }}</span>
                </li>
                <li>
                    <span class="post__title">{{$t('Created on')}}</span>
                    <span class="post__description">{{ tournament.getCreatedAt() | readableDateFormat }}</span>
                </li>
                <li>
                    <span class="post__title">{{$t('Created by')}}</span>
                    <span class="post__description">{{ tournament.getCreatorName() }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

import PageLinks from '../../../components/Page/Links'
import SvgIcon from '../../../components/SvgIcon'
import StatusProgress from '../../../components/StatusProgress'
import ChatsListContainer from '../../../components/Chat/ChatsListContainer'

export default {
    props: ["id"],
    components: {
        SvgIcon,
        PageLinks,
        StatusProgress,
        ChatsListContainer
    },
    data() {
        return {
            game: null,
            platform: null,
        }
    },
    computed: {
        ...mapGetters([
            'tournament',
            'rule',
            'getGame',
            'getPlatform',
            'isMobile',
            'countries'
        ]),
        filteredCountries () {
            return this.tournament.getCountry().map(item => {
                return {
                    info: this.countries.find(i => i.id === item),
                    id: item
                }
            })
        }
    },
    created() {
        this.init();
    },
    methods: {
        ...mapActions({
            getTournament: 'GET_ROUND_ROBIN_TOURNAMENT',
            getGames: 'getGames',
            getPlatforms: 'GET_PLATFORMS',
            getCountries: 'getCountries',
            getRule: 'GET_RULE',
            centrifugeConnect: 'centrifugeConnect'
        }),
        async init() {
            await this.getGames();
            if (this.tournament.getRuleId()) {
                await this.getRule(this.tournament.getRuleId());
            }
            this.getCountries({ identities: this.tournament.getCountry().join(',') } );

            this.game = this.getGame(this.tournament.getGameId())[0];
            this.platform = this.getPlatform(this.tournament.getPlatformId())[0];
        }
    }
}
</script>
