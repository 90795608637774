import http from '../utils/http'

export default {
    getExchangeRates(data = {}) {
        return http.get('/admin/exchange-rates', data)
    },
    saveExchangeRates(data) {
        return http.post('/admin/exchange-rates', data)
    },

    changeTurqEnabled (status) {
        return http.put('/admin/config/turq-switch', {
            status
        })
    }
}
