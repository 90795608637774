import leaderboard from '../../api/leaderboard'
import multiarena from '../../api/multiarena'
import helpers from '../../helpers'
import tournament from "../../api/tournament";
import participantsApi from '../../api/participants'
import tft from '../../api/tft'
const state = () => ({
	leaderboards: [],
	leaderboardItems: [],
	scheduleLeaderBoardDialogState: false,
	scheduleLeaderBoardDialogData: [],
});

const getters = {
	leaderboards: state => state.leaderboards,
	leaderboardItems: state => state.leaderboardItems,
	scheduleLeaderBoardDialogState: state => state.scheduleLeaderBoardDialogState,
	scheduleLeaderBoardDialogData: state => state.scheduleLeaderBoardDialogData,
};

const actions = {
	getLeaderBoard: async ({commit}, { id, service = null }) => {
        if (!service) {
            service = leaderboard
        }
		const response = await service.get(id);
		const participantsResponse = await participantsApi.get(id);
		const participants = participantsResponse.data.data;
		commit('UPDATE_LEADERBOARD_ITEMS', {data: response.data.data, participants: participants})
	},
	getLeaderBoards: async ({commit}, data) => {
		let response;

		switch (data.type) {
			case 'team':
				response = await leaderboard.team(data.data);
				break;
			case 'clan':
				response = await leaderboard.clan(data.data);
				break;
			default:
				response = await leaderboard.solo(data.data);
				break;
		}

		if (response.status === 200) {
			commit('UPDATE_LEADERBOARDS', response.data)
		}
	},
	updateLeaderBoard: async ({commit, dispatch}, data = {}) => {
		await leaderboard.update(data.tournament, data.match, data.body);

		dispatch('getLeaderBoard', { id: data.tournament })
	},
    startTftTournament: async ({commit, dispatch}, id) => {
        try {
            await tft.start(id).then((resp) => {
                if (resp.response && resp.response.status === 500) {
                    throw new Error(resp.response.data.message ?? 'Error');
                }
            })
        } catch(e) {
            throw e;
        }
    },
    startMultiArenaTournament: async ({commit, dispatch}, id) => {
        try {
            await leaderboard.start(id).then((resp) => {
                if (resp.response && resp.response.status === 500) {
                    throw new Error(resp.response.data.message ?? 'Error');
                }
            })
        } catch(e) {
            throw e;
        }
        const tournamentResponse = await tournament.get(id);

        if (200 === tournamentResponse.status) {
            commit('UPDATE_TOURNAMENT', tournamentResponse.data);
            commit('UPDATE_TOURNAMENT_STATUS', tournamentResponse.data.status);
        }
        dispatch('getLeaderBoard', { id, service: multiarena })
    },
	startLeaderBoardTournament: async ({commit, dispatch}, id) => {
		try {
            await leaderboard.start(id).then((resp) => {
                if (resp.response && resp.response.status === 500) {
                    throw new Error(resp.response.data.message ?? 'Error');
                }
            })
        } catch(e) {
            throw e;
        }

		const tournamentResponse = await tournament.get(id);

		if (200 === tournamentResponse.status) {
			commit('UPDATE_TOURNAMENT', tournamentResponse.data);
			commit('UPDATE_TOURNAMENT_STATUS', tournamentResponse.data.status);
		}

		dispatch('getLeaderBoard', { id, service: leaderboard })
	},
	finishLeaderBoardTournament: async ({commit, dispatch}, id) => {
		await leaderboard.finish(id);

		const tournamentResponse = await tournament.get(id);

		if (200 === tournamentResponse.status) {
			commit('UPDATE_TOURNAMENT', tournamentResponse.data);
			commit('UPDATE_TOURNAMENT_STATUS', tournamentResponse.data.status);
		}
	},
	async showLeaderBoardScheduleDialog({commit}, id) {
		const response = await leaderboard.getTimings(id);
		let timings = helpers.calculateTimingsForCurrentTimeZone(response.data.data);

		commit('UPDATE_LEADERBOARD_SCHEDULE_DIALOG_DATA', timings);
		commit('UPDATE_LEADERBOARD_SCHEDULE_DIALOG_STATE', true);
	},
	hideLeaderBoardScheduleDialog({commit}) {
		commit('UPDATE_LEADERBOARD_SCHEDULE_DIALOG_STATE', false);
	},
	async saveLeaderBoardSchedule({commit, dispatch}, data) {
		commit('UPDATE_LEADERBOARD_SCHEDULE_DIALOG_STATE', false);

		const response = await leaderboard.updateTimings(data.id, data.body);

		commit('UPDATE_LEADERBOARD_SCHEDULE_DIALOG_DATA', response.data.data);

		dispatch('getLeaderBoard', { id: data.id })
	},
};

const mutations = {
	UPDATE_LEADERBOARDS: (state, data) => {
		state.leaderboards = data;
	},
	UPDATE_LEADERBOARD_ITEMS: (state, {data, participants}) => {
        data.forEach((round, ii) => {
            if (round.lobbies) {
                round.lobbies.forEach((lobby, i) => {
                    lobby.participants.forEach((participant, j) => {
                        data[ii].lobbies[i].participants[j].info = participants.filter(item => participant.id === item.id)[0]
                    });
                });
            }
            else {
                data.forEach((round, ii) => {
                    if (round.participants) {
                        round.participants.forEach((participant, j) => {
                            data[ii].participants[j].info = participants.filter(item => participant.id === item.id)[0]
                        });
                    }
                });
            }
        })

		state.leaderboardItems = data;
	},
	UPDATE_LEADERBOARD_SCHEDULE_DIALOG_STATE(state, value) {
		state.scheduleLeaderBoardDialogState = value;
	},
	UPDATE_LEADERBOARD_SCHEDULE_DIALOG_DATA(state, value) {
		state.scheduleLeaderBoardDialogData = value;
	},
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
