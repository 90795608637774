<template>
	<div class="brackets__item-content" v-observe-visibility="{callback: visibilityChanged, throttle: 500, throttleOptions: {
    leading: 'visible',
  }}" >
		<BracketItemControls :data="data" v-if="!isPublicBrackets && isVisible"/>
		<BracketItemStatus :status="newStatus ? newStatus : data.match_status" :id="data.id"/>
		<div class="brackets__players">
			<div v-if="availableSetParticipants && !isPublicBrackets">
                <SeedPlayer v-if="!data.first_participant" @setData="data = $event" :participants="filteredParticipants" :data="data" :player="'first_participant'"></SeedPlayer>
                <BracketItemPlayer v-else :data="data" :player="'first_participant'"/>

            </div>
            <div v-if="availableSetParticipants && !isPublicBrackets">
                <SeedPlayer v-if="!data.second_participant" @setData="data = $event" :participants="filteredParticipants" :data="data" :player="'second_participant'"></SeedPlayer>
                <BracketItemPlayer v-else :data="data" :player="'second_participant'"/>

            </div>
            <div v-else>
                <BracketItemPlayer :data="data" :player="'first_participant'"/>
                <BracketItemPlayer :data="data" :player="'second_participant'"/>
            </div>
		</div>
		<BracketItemRecreate :data="data" v-if="!isPublicBrackets && isVisible"/>
        <div class="brackets__item-label" v-if="third">
            3rd place
        </div>
        <div class="brackets__item-label" v-if="final">
            Final
        </div>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex'
    import SeedPlayer from './Seeding/SeedPlayer'
	import BracketItemPlayer from './BracketItemPlayer'
	import BracketItemStatus from './BracketItemStatus'
	import BracketItemControls from './BracketItemControls'
	import BracketItemRecreate from './BracketItemRecreate'


	export default {
		name: "BracketItem",
		components: {
			BracketItemPlayer,
			BracketItemStatus,
            SeedPlayer,
			BracketItemControls,
			BracketItemRecreate
		},
        data () {
            return {
                subscribe: false,
                newStatus: null,
                isVisible: false
            }
        },
		props: {
            final: {
                type: Boolean,
                default: false
            },
            third: {
                type: Boolean,
                default: false
            },
			data: Object
		},
		computed: {
            availableSetParticipants () {
                if (this.data.is_looser_match) return false
                if (!this.tournament) return false
                if (!this.tournament.getCustomSeeding()) return false
                if (![1,3].includes(this.tournament.getType())) return false
                if (![1,2].includes(this.data.round)) return false
                if (!this.filteredParticipants.length) return false
                if (!['REGISTRATION_CLOSED'].includes(this.tournament.getStatus())) return false
                return true
            },
            filteredParticipants () {
                if (!this.participants || !this.tournament) return []
                return this.participants.filter(item => {
                    return this.excludeParticipants.indexOf(item.getId()) < 0
                })
            },
			...mapGetters({
                excludeParticipants: 'excludeParticipants',
                participants: 'participants',
                tournament: 'tournament',
				isPublicBrackets: 'isPublicBrackets',
                centrifugeInstance: 'centrifugeInstance'
			}),
		},
        watch: {
            centrifugeInstance () {
                if (!this.subscribe) {
                    this.subscribeChannel()
                }
            }
        },
        mounted () {
            if (this.tournament) {
                const channel = `tournament.${this.tournament.getId()}.match.${this.data.id}`
                try {
                    this.centrifugeInstance.subscribe(channel, (data) => {
                        this.newStatus = data.data.status
                    })
                    this.subscribe = true
                } catch(e) {}
            }
        },
        beforeDestroy () {
            if (this.centrifugeInstance && this.centrifugeInstance._status === 'connected') {
                const channel = `tournament.${this.tournament.getId()}.match.${this.data.id}`
                try {
                    this.centrifugeInstance.unsubscribe(channel)
                } catch(e) {}
            }
        },
		methods: {
            subscribeChannel () {
                const channel = `tournament.${this.tournament.getId()}.match.${this.data.id}`
                if (this.centrifugeInstance) {
                    this.centrifugeInstance.subscribe(channel, (data) => {
                        this.newStatus = data.data.status
                    })
                    this.subscribe = true
                }
            },
            visibilityChanged (isVisible, entry) {
                this.isVisible = isVisible
            },
			getPlayerClass(playerNode) {
				const node = this.data;
				let classes = [];
				const hasWinner = node.winner_id && playerNode.id !== null;
				const isWinner = node.winner_id === playerNode.id;

				if (!hasWinner) return;

				if (isWinner) {
					classes.push('winner');
				} else {
					classes.push('defeated');
				}

				return classes.join(' ');
			},
		}
	};
</script>
<style scoped lang="scss">
.brackets__item-content {
    position: relative;
    .brackets__item-label {
        position: absolute;
        top: 100%;
        padding-top: 4px;
        padding-bottom: 2px;
        background: #004042;
        border-radius: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        width: 100%;
        letter-spacing: 0.16px;
        text-transform: uppercase;
        color: #00E7DF;
    }
}
</style>
