
import http from '../utils/http'
import joinUrls from '../utils/join-urls'
import joinParams from '../utils/join-params';

export default {
    get(data = {}) {
        return http.get('/room-templates', data)
    },
    getItem(id) {
        return http.get(joinUrls('/room-templates', id))
    },
    add(data) {
        return http.post('/room-templates', data)
    },
    update(id, data) {
        return http.put(joinUrls('/room-templates', id), data)
    },
    delete(id) {
        return http.delete(joinUrls('/room-templates', id))
    }
}
