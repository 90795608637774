import Centrifuge from 'centrifuge'
import router from '../../routes'

import {CENTRIFUGE_HOST} from '../../constants/common'

const state = () => ({
	centrifugeInstance: null,
});

const getters = {
	centrifugeInstance: state => state.centrifugeInstance
};

const actions = {
	centrifugeConnect: ({commit, dispatch, getters}) => {
		const wsToken = getters.getWsToken;
		const tournament = getters.tournament ? getters.tournament.getId() : null;
		let centrifugeInstance = getters.centrifugeInstance;

		if (!wsToken) {
			dispatch('LOGOUT');
			router.push('/login').catch(() => {});
		}

		if (null === centrifugeInstance) {
			centrifugeInstance = new Centrifuge(CENTRIFUGE_HOST);
			centrifugeInstance.setToken(wsToken);
			centrifugeInstance.connect();

            if (tournament) {
                centrifugeInstance.on('connect', function (ctx) {

                    centrifugeInstance.subscribe(
                        `result.tournament.${tournament}.match.*`,
                        function (ctx) {
                            const type = ctx.data.type;

                            if (type === 'INT') {
                                commit('UPDATE_RESULT_IN_BRACKETS', ctx.data);
                            }
                        });

                    centrifugeInstance.subscribe(
                        `tournament.${tournament}`,
                        function (ctx) {
                            const status = ctx.data.status;
                            if (
                                'STARTED' === status
                                || 'CHECKIN' === status
                            ) {
                                commit('UPDATE_TOURNAMENT_STATUS', status);
                            }
                        });
                });
                centrifugeInstance.on('disconnect', function (ctx) {
                    console.log("disconnected", ctx);
                });
            }

			commit('CONNECT_CENTRIFUGE', centrifugeInstance);
		}
	},
	centrifugeDisconnect: ({commit, dispatch, getters}) => {
		const centrifugeInstance = getters.centrifugeInstance;
		if (null !== centrifugeInstance) commit('DISCONNECT_CENTRIFUGE');
	}
};

const mutations = {
	CONNECT_CENTRIFUGE: (state, instance) => {
		state.centrifugeInstance = instance;
	},
	DISCONNECT_CENTRIFUGE: (state) => {
		state.centrifugeInstance.disconnect();
		state.centrifugeInstance = null;
	},
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
