export default function DeepLink( data= null) {
	const info = data;

	this.getLinkId = () => info.id;

	this.getLink = () => info.deeplink;

	this.getGameId = () => info.data.initial_game;

	this.getPlatformId = () => info.data.initial_platform;

	this.getSource = () => info.data.initial_source;

}