<template>
    <div class="team-detail">
        <PageLinks v-if="team" :links="[
            {
				url: `/`,
				text: 'Main',
			},
			{
				url: `/team/${team.id}`,
				text: team.name ? team.name : 'Team',
				classes: 'active'
			}
		]"/>
        <div class="page__content-header">
            <h1>Team page</h1>
            <h2>{{ team.name }}</h2>
        </div>
        <div class="team__description" v-if="team.description">
            <h6>Team description</h6>
            <p v-html="team.description">
                {{ team.description }}
            </p>
        </div>
        <div class="table-wrapper">
            <table>
                <thead class="text-left">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">User id</th>
                    <th scope="col">Avatar</th>
                    <th scope="col">Name</th>
                    <th scope="col">Nickname</th>
                    <th class="email" scope="col">Email</th>
                    <th scope="col">Locale</th>
                    <th scope="col">Role</th>
                    <th scoped="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr :class="{'warning': !player.role && !player.status}" v-for="player in team.team_mates" :key="player.id">
                    <td scope="col">{{ player.id }}</td>
                    <td scope="col">{{ player.user_id }}</td>
                    <td scope="col">
                        <img style="width: 35px; height: 35px; border-radius: 100%;" :src="player.profile.user.avatar" alt="">
                    </td>
                    <td scope="col">
                        <strong>{{ player.profile.user.name ? player.profile.user.name : 'Unknown' }}</strong>
                    </td>
                    <td scope="col">
                        <strong>{{ player.profile.info.nickname }}</strong>
                    </td>
                    <td class="email" scope="col">{{ player.profile.user.email }}</td>
                    <td scope="col">{{ player.profile.user.locale }}</td>
                    <td scope="col" style="min-width: 150px">
                        <template v-if="player.role">{{ player.role }}</template>
                        <template v-else>
                        <button :disabled="load" @click="deleteRequest(player.id)" class="button button-danger delete__request">Delete request</button>
                        </template>
                    </td>
                    <td>
                        <a target="_blank" :href="`/chats?start=${player.user_id}`" class="send_message">
                            <svg width="18" height="14" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7266 3.30729C13.0977 3.77604 12.3047 4.34896 9.51562 6.27604C8.96875 6.66667 7.95703 7.52604 7 7.52604C6.01562 7.52604 5.03125 6.66667 4.45703 6.27604C1.66797 4.34896 0.875 3.77604 0.246094 3.30729C0.136719 3.22917 0 3.30729 0 3.4375V8.75C0 9.45313 0.574219 10 1.3125 10H12.6875C13.3984 10 14 9.45313 14 8.75V3.4375C14 3.30729 13.8359 3.22917 13.7266 3.30729ZM7 6.66667C7.62891 6.69271 8.53125 5.91146 8.99609 5.59896C12.6328 3.09896 12.9062 2.86458 13.7266 2.23958C13.8906 2.13542 14 1.95313 14 1.74479V1.25C14 0.572917 13.3984 0 12.6875 0H1.3125C0.574219 0 0 0.572917 0 1.25V1.74479C0 1.95313 0.0820312 2.13542 0.246094 2.23958C1.06641 2.86458 1.33984 3.09896 4.97656 5.59896C5.44141 5.91146 6.34375 6.69271 7 6.66667Z" fill="#8F8F8F"/>
                            </svg>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import TeamsService from '../../api/teams'
import PageLinks from '../../components/Page/Links'
export default {
    data () {
        return {
            load: false,
            team: null
        }
    },
    components: {
        PageLinks
    },
    methods: {
        getTeam () {
            TeamsService.getTeam(this.$route.params.id).then(response => {
                this.team = response.data.data
            })
        },
        deleteRequest (teamMateId) {
            this.load = true
            TeamsService.declineInvite(this.team.id, teamMateId).then((data) => {
                if (data && data.response && data.response.status && data.response.status === 403) {
                    this.$notify({
                        type: 'error',
                        title: 'Delete error',
                        group: 'notify',
                        text: 'Team has participation'
                    })
                } else {
                    this.getTeam()
                }
            }).finally(() => {
                this.load = false
            })
        }
    },
    mounted () {
        this.getTeam()
    }
}
</script>
<style scoped lang="scss">
.delete__request {
    background: rgba(255,255,255, 0.3);
    border: 2px solid #000;
    &:hover {
        opacity: 0.7;
    }
    transition: 0.2s;
    border-radius: 5px;
}
.team__description {
    padding: 15px 10px;
    background: #dbdbdb;
    max-width: 90%;
    margin-bottom: 20px;
    h6 {
        margin-bottom: 10px;
        display: block;
    }

    p {
        font-size: 14px;
    }
}
.page__content-header {
    display: flex;
    margin-top: 37px;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 37px;
    h1 {
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;
        color: #303030;
        margin-right: 10px;
    }
    h2 {
        font-size: 18px;
        line-height: 21px;
        color: #878787;
    }
}
.warning {
    background: orange;
}
@media screen and (max-width: 992px) {
    .page__content-header {
        justify-content: flex-start !important;
        align-items: flex-start;
    }
}
table {
    .email {
        min-width: 250px;
    }
    tr {

        td:first-child {
            min-width: 120px;
        }
        td:nth-child(2) {
            min-width: 100px;
        }

        td:nth-child(3) {
            min-width: 100px;
        }
        td:nth-child(4) {
            min-width: 200px;
        }
        td:nth-child(5) {
            min-width: 200px;
        }

        th:first-child {
            min-width: 120px;
        }
        th:nth-child(2) {
            min-width: 100px;
        }

        th:nth-child(3) {
            min-width: 100px;
        }
        th:nth-child(4) {
            min-width: 200px;
        }
        th:nth-child(5) {
            min-width: 200px;
        }
    }
}
</style>
