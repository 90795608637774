<template>
    <div>
        <div class="page__content-header">
            <div>
                <h3 class="page__content-header_title">Transactions</h3>
            </div>
            <div class="page__content-header_right">
                <div class="roles_filter">
                    Filter by type:
                    <multiselect track-by="name" label="name" placeholder="Select type" v-model="type" :options="[
                        {
                            type: 'ACCRUAL_TOURNAMENT_PRIZE',
                            name: 'Tournament prizes'
                        },
                        {
                            type: 'ACCRUAL_WITHDRAW_REQUEST',
                            name: 'Withdraw request'
                        }
                    ]"></multiselect>
                </div>

                <div class="input__search">
                    <input
                        v-model="search"
                        type="text"
                        @keydown.enter="getTransactions"
                        name="search"
                        class="form-input"
                        :placeholder="$t('Search by user id')"
                        required
                    >
                    <svg-icon
                        class="search-icon"
                        width="16"
                        height="16"
                        :iconType="'search'"
                    />
                </div>
            </div>
        </div>
        <div class="page__content-body">
            <div class="table-wrapper">
                <table>
                    <thead class="text-left">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Amount</th>
                        <th scope="col">User</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Type</th>
                        <th scope="col">Payment referer</th>
                        <th scope="col">Date</th>
                        <th scope="col">Additional informations</th>
                        <th scope="col">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr @click="$router.push({ name: 'user', params: { id: user.id } })" v-for="transaction in transactions" :key="transaction.id">
                        <td>
                            {{ transaction.id }}
                        </td>
                        <td>
                            {{ parseFloat(transaction.amount).toFixed(2) }}
                        </td>
                        <td>
                            <router-link style="color: #06C2BC;" :to="{ name: 'user', params: { id: transaction.user_id } }">{{ transaction.user_id }}</router-link>
                        </td>
                        <td>{{ transaction.currency }}</td>
                        <td>
                            <template v-if="transaction.type === 'ACCRUAL_TOURNAMENT_PRIZE'">
                                Tournament prizes
                            </template>
                            <template v-if="transaction.type === 'ACCRUAL_WITHDRAW_REQUEST'">
                                Withdraw request
                            </template>
                        </td>
                        <td>
                            {{ transaction.payment_system }}
                        </td>
                        <td>
                            {{ getPublishDate(transaction.created_at) }}
                        </td>
                        <td>
                            {{ transaction.additionalDetails }}
                        </td>
                        <td>
                            <span class="red" v-if="transaction.status === 'CANCELED'">
                                {{ transaction.status }}
                            </span>
                            <span class="green" v-if="transaction.status === 'SUCCESS'">
                                {{ transaction.status }}
                            </span>
                            <span class="orange" v-if="transaction.status === 'PENDING'">
                                {{ transaction.status }}
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <PagePagination
                :current="current_page"
                :total="last_page"
                @change="getTransactions"
            />
        </div>

    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'

import TransactionsService from '../../api/transactions'
import moment from "moment";
import PagePagination from "../../components/Page/Pagination";
export default {
    data () {
        return {
            search: "",
            last_page: 1,
            current_page: 1,
            type: null,
            transactions: []
        }
    },
    components: {
        PagePagination,
        Multiselect,
    },
    watch: {
      type () {
          this.getTransactions()
        },
      search () {
          this.getTransactions()
      }
    },
    methods: {
        getPublishDate (time) {
            const timeZone = new Date().getTimezoneOffset();

            return moment(String(time)).utc().add((timeZone / 60) * -1, 'hours').format('YYYY.MM.D HH:mm');
        },
        getTransactions () {
           TransactionsService.get({
               search: this.search,
               type: this.type ? this.type.type : ''
           }).then(response => {
               this.transactions = response.data.data
               this.last_page = response.data.meta.last_page
               this.current_page = response.data.meta.current_page
           })
        }
    },
    mounted () {
        this.getTransactions();
    }
}
</script>
<style scoped lang="scss">
span.orange {
    color: orange;
    font-weight: bold;

}
span.red {
    font-weight: bold;

    color: red;
}
span.green {
    font-weight: bold;
    color: green !important;
}
.page__content-header {
    align-items: center;
    margin-top: 22px;
}
.page__content-header_right {
    display: flex;
    .roles_filter {
        align-items: center;
        display: flex;
        .multiselect {
            margin-left: 10px;
            width: 400px;
        }
        margin-right: 20px;
    }
    .input__search {
        margin-right: 20px;
        width: 277px;
        position: relative;
        display: flex;
        align-items: center;
        input {
            width: 100%;
            max-width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            border: 1px solid #878787;
            border-radius: 4px;
            padding: 9px 15px;
        }
        .search-icon {
            display: flex;
            align-items: center;
            right: 15px;
            position: absolute;
        }
    }
    .export__button {
        padding-left: 39px;
        padding-right: 39px;
    }
}

@media screen and (max-width: 1000px) {
    .table-wrapper {
        padding: 0 5px;
        table {
            min-width: 1000px
        }
    }
}

@media screen and (max-width: 1400px) {
    .page__content-header {
        flex-wrap: wrap;
        .page__content-header_right {
            width: 100%;
        }
    }
}
@media screen and (max-width: 1300px) {
    .page__content-header {
        flex-wrap: wrap;
        .page__content-header_right {
            width: 100%;
            flex-wrap: wrap;
            div {
                width: 100%;
                margin-right: 0 !important;
            }
            .roles_filter {
                order: 1;
                margin-top: 15px;
            }
            .export__button {
                margin-left: auto;
                margin-top: 20px;
                order: 2;
            }
            .input__search {
                margin-top: 20px;
                order: 0;
            }
        }
    }
    .page__content-header_right .roles_filter .multiselect {
        width: 250px;
        margin-left: auto;
    }
}
</style>
