import axios from 'axios';
import {errorResponse, successResponse} from './process-response'

function setRequestData(url, authToken) {
	axios.defaults.headers.common = {
		"Accept": "application/json",
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Credentials": "true",
		"X-WEB": "true"
	};

	axios.defaults.baseURL = url;

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
    axios.defaults.headers.common['X-ADMIN'] = true;
}

function get(url, params = {}) {
	return axios
		.get(url, { params: params })
		.then(successResponse)
		.catch(errorResponse)
}

function post(url, data = {}) {
	return axios
		.post(url, data)
		.then(successResponse)
		.catch(errorResponse)
}

function getFormData(formData, data, previousKey = '') {
	if (data instanceof Object) {
		Object.keys(data).forEach((key) => {
			if (Array.isArray(data[key])) {
				data[key].forEach((item, index) => {
					if (item instanceof Object) {
						getFormData(formData, item, key + '['+ index +']')
					} else {
						formData.append(key + '['+ index +']', item);
					}
				})
			} else {
				const newKey = previousKey ? previousKey + '['+ key +']' : key;
				formData.append(newKey, data[key]);
			}
		});
	}
}

function postMultipart(url, data = {}, config = {}) {
	const formData = new FormData();

	getFormData(formData, data);

	return axios
		.post(url, formData, {
			...config,
			headers: {  ...config.headers, 'Content-Type': 'multipart/form-data' }
		})
		.then(successResponse)
		.catch(errorResponse)
}

function putMultipart(url, data = {}, config = {}) {
	const formData = new FormData();

	getFormData(formData, data);
	formData.append('_method', 'PUT');

	return axios
		.post(url, formData, {
			...config,
			headers: {  ...config.headers, 'Content-Type': 'multipart/form-data' }
		})
		.then(successResponse)
		.catch(errorResponse)
}

function put(url, data = {}) {
	return axios
		.put(url, data)
		.then(successResponse)
		.catch(errorResponse)
}

function httpDelete(url) {
	return axios
		.delete(url)
		.then(successResponse)
		.catch(errorResponse)
}

export default {
	delete: httpDelete,
	get,
	post,
	postMultipart,
	putMultipart,
	put,
	setRequestData
}
