import http from '../utils/http'
import joinUrls from "../utils/join-urls";

export default {
    getMatches (tournament) {
        return http.get(joinUrls('/tournaments', tournament, 'tft', 'matches'));
    },
    start (id) {
        return http.put(joinUrls('/tournaments', id, 'start'));
    },
    getMeta (tournament) {
        return http.get(joinUrls('/tournaments', tournament, 'tft', 'meta'));
    },
    finishMatch(id, matchId, data) {
        if (!id && !matchId) {
            return Promise.reject(new Error('Tournament id and Match id are required to finish match'));
        }

        return http.post('/tournaments/' + id + '/tft/matches/' + matchId + '/finish', data);
    },
    matchRevert(id, matchId) {
        if (!id && !matchId) {
            return Promise.reject(new Error('Tournament id and Match id is required to revert match'))
        }

        return http.post(joinUrls('/tournaments', id, 'tft/matches', matchId, 'revert'))
    },
}
