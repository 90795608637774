
export default function RoomTemplate( data = null) {
    const info = data;

    this.getId = () => info.id;

    this.getRounds = () => info.info;

    this.getName = () => info.title;

    this.getGame = () => info.game_id;
}
