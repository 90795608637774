import http from '../utils/http'
import joinUrls from '../utils/join-urls'
import joinParams from '../utils/join-params';

export default {
	get(id, data) {
		if (!id) {
			return Promise.reject(new Error('Tournament id is required to get participants'));
		}

		const url = joinUrls('/tournaments', id, 'participants');

		return http.get(joinParams(url, data));
	},
	delete(tournament, user) {
		if (!tournament && !user) {
			return Promise.reject(new Error('Participant id and tournament id are required to delete participant'));
		}

		const url = joinUrls('/tournaments', tournament, 'participants', user);

		return http.delete(url);
	},
	bypassChechinValidator(tournament, participant_id) {

		if (!tournament && !participant_id) {
			return Promise.reject(new Error('Participant id and tournament id are required to update participant'));
		}

		const url = joinUrls('/tournaments', tournament, 'participants');

		return http.put(url, { skip_validations: true, participant_id: participant_id });
	},
	getEmails(id) {
		if (!id) {
			return Promise.reject(new Error('Tournament id is required to export emails'));
		}
		const url = joinUrls('/tournaments', id, 'participants/export');

		return http.get(url);
	}
}
