<template>
	<div class="brackets__rounds">
		<ul class="brackets__rounds-list">
			<li
				v-for="round in rounds"
                v-if="round.round > hiddenRounds"
				class="brackets__rounds-list-item"
				:class="getRoundClass(round.round)"
				@click="setCurrentRound(round.round)"
			>
				<span class="title">{{$t('Round')}} {{round.round}}</span>
				<span class="date">{{getReadableDate(round.started_at)}}</span>
				<span class="time">{{getReadableTime(round.started_at)}}</span>
			</li>
		</ul>
        <div class="brackets__hide" v-if="!isMobile && !isPublic" >
            <div class="brackets__hide__item" @click="$emit('showRound')">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_598_1359)">
                        <path d="M23.0938 12.1875C23.0938 12.4361 22.995 12.6746 22.8192 12.8504C22.6433 13.0262 22.4049 13.125 22.1562 13.125H13.25V22.125C13.25 22.3736 13.1512 22.6121 12.9754 22.7879C12.7996 22.9637 12.5611 23.0625 12.3125 23.0625C12.0639 23.0625 11.8254 22.9637 11.6496 22.7879C11.4738 22.6121 11.375 22.3736 11.375 22.125V13.125H2.46875C2.22011 13.125 1.98165 13.0262 1.80584 12.8504C1.63002 12.6746 1.53125 12.4361 1.53125 12.1875C1.53125 11.9389 1.63002 11.7004 1.80584 11.5246C1.98165 11.3488 2.22011 11.25 2.46875 11.25H11.375V2.06625C11.375 1.81761 11.4738 1.57916 11.6496 1.40334C11.8254 1.22753 12.0639 1.12875 12.3125 1.12875C12.5611 1.12875 12.7996 1.22753 12.9754 1.40334C13.1512 1.57916 13.25 1.81761 13.25 2.06625V11.25H22.1562C22.4049 11.25 22.6433 11.3488 22.8192 11.5246C22.995 11.7004 23.0938 11.9389 23.0938 12.1875Z" fill="#1C1C1C"/>
                        <path d="M90.875 48.7497C90.875 49.7443 90.4799 50.6981 89.7767 51.4014C89.0734 52.1047 88.1196 52.4997 87.125 52.4997H51.5V88.4997C51.5 89.4943 51.1049 90.4481 50.4017 91.1514C49.6984 91.8547 48.7446 92.2497 47.75 92.2497C46.7554 92.2497 45.8016 91.8547 45.0984 91.1514C44.3951 90.4481 44 89.4943 44 88.4997V52.4997H8.375C7.38044 52.4997 6.42661 52.1047 5.72335 51.4014C5.02009 50.6981 4.625 49.7443 4.625 48.7497C4.625 47.7552 5.02009 46.8014 5.72335 46.0981C6.42661 45.3948 7.38044 44.9997 8.375 44.9997H44V8.26474C44 7.27018 44.3951 6.31635 45.0984 5.61309C45.8016 4.90983 46.7554 4.51474 47.75 4.51474C48.7446 4.51474 49.6984 4.90983 50.4017 5.61309C51.1049 6.31635 51.5 7.27018 51.5 8.26474V44.9997H87.125C88.1196 44.9997 89.0734 45.3948 89.7767 46.0981C90.4799 46.8014 90.875 47.7552 90.875 48.7497Z" fill="#1C1C1C"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_598_1359">
                            <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div class="brackets__hide__item" @click="$emit('hideRound')">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.0938 12.1876C23.0938 12.4362 22.995 12.6747 22.8192 12.8505C22.6433 13.0263 22.4049 13.1251 22.1562 13.1251L2.46875 13.1251C2.22011 13.1251 1.98165 13.0263 1.80584 12.8505C1.63002 12.6747 1.53125 12.4362 1.53125 12.1876C1.53125 11.9389 1.63002 11.7005 1.80584 11.5246C1.98165 11.3488 2.22011 11.2501 2.46875 11.2501L22.1562 11.2501C22.4049 11.2501 22.6433 11.3488 22.8192 11.5246C22.995 11.7005 23.0938 11.9389 23.0938 12.1876Z" fill="#1C1C1C"/>
                </svg>
            </div>
        </div>

    </div>
</template>

<script>
	import {mapGetters, mapMutations} from 'vuex'

	import moment from 'moment'

	export default {
		name: "BracketsRoundsList",
	  props: {
          isMobile: {
              type: Boolean,
              default: false,
          },
          hiddenRounds: {
              type: Number,
              default: 0
          },
		  id: String,
		  isPublic: {
			  type: Boolean,
			  default: false,
		  },
	  },
		computed: {
			...mapGetters({
				rounds: 'rounds',
				isMobile: 'isMobile',
				selectedRound: 'selectedRound',
			})
		},
		methods: {
			...mapMutations({
				selectRound: 'UPDATE_SELECTED_ROUND'
			}),
			getReadableDate(date) {
				return moment(date).format('YYYY/MM/DD hh:mm:ss').split(' ')[0]
			},
			getReadableTime(date) {
				return moment(date).format('YYYY-MM-DD hh:mm').split(' ')[1]
			},
			setCurrentRound(round) {
				this.selectRound(round)
			},
			getRoundClass(round) {
				if (this.isPublic || !this.isMobile) return;

				return Number(this.selectedRound) === Number(round) ? 'selected' : '';
			}
		}
	};
</script>
<style scoped lang="scss">
.brackets__rounds {
    align-items: center;
}
.brackets__hide {
    right: 0;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    display: flex;
    border-radius: 4px;
    padding: 6px;
    align-items: center;
    margin-right: 20px;
    .brackets__hide__item {
        padding: 1px 14px;
        padding-left: 11px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
            opacity: 0.5;
        }
    }
    .brackets__hide__item + .brackets__hide__item {
        border-left: 1px solid #E5E5E5
    }
}
</style>
