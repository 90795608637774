export default (content) => {
	const regex = /(?:^<span[^>]*>)|(?:<\/span>$)/g;

	if (!content) return content;

	const bodyContent = document.createRange().createContextualFragment(content);
	const stylesNode = bodyContent.getElementById('injected-styles');

	if (stylesNode) stylesNode.remove();

	const tmp = document.createElement("div");
	tmp.appendChild(bodyContent);

	let html = tmp.innerHTML;

	html = html.replace(regex, "");

	return html;
}
