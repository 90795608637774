
<template>
    <div
        class="leaderboard__close-tournament-button"
        style="margin-left: 0 !important;"
        v-show="show() && (isHaveWinner || enabledClose)"
    >
        <button
            class="button button-primary"
            @click.prevent="close"
        >{{$t('Close Challenge')}}
        </button>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    data () {
        return {
            enabledClose: false
        }
    },
    name: "LeaderboardCloseButton",
    computed: {
        ...mapGetters({
            matches: 'matches',
            tournament: 'tournament',
            tournamentStatus: 'tournamentStatus'
        }),
        isHaveWinner () {
            return this.matches.find(item => item.winner_id)
        }
    },
    mounted () {
      this.$root.$on('enabledClose', () => {
          this.enabledClose = true
      })
    },
    methods: {
        ...mapActions({
            closeTournament: 'FINISH_TOURNAMENT'
        }),
        close() {
            this.closeTournament(this.tournament.getId());
        },
        show() {
            const status = this.tournamentStatus;
            return ['STARTED', 'IN_PROGRESS'].includes(status.toUpperCase());
        }
    }
};
</script>
