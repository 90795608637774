<template>
	<div class="events-calendar__export-button">
		<button
			class="button button-primary"
			@click.prevent="exportEvents"
		>{{$t('Export events')}}
		</button>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'
	import {ExportToCsv} from 'export-to-csv'

	import moment from 'moment'

	export default {
        props: {
            gamesSelect: {
                default: [],
                type: Array
            }
        },
		name: "EventsExportButton",
		computed: {
			...mapGetters({
				events: 'getEventsForExport',
				currentMonth: 'selectedMonth'
			})
		},
		data() {
			return {
				csvOptions: {
					filename: 'events',
					decimalSeparator: '.',
					fieldSeparator: ',',
					quoteStrings: '"',
					showLabels: true,
					showTitle: true,
					title: 'Events',
					useTextFile: false,
					useBom: true,
					useKeysAsHeaders: true,
				}
			}
		},
		methods: {
			...mapActions({
				getEventsForExport: 'getEventsForExport'
			}),
			async exportEvents() {
				await this.getEventsForExport({
                    games: this.gamesSelect.join(','),
					from: new moment(this.getFirstDay()).format('YYYY-MM-DD'),
					to:  new moment(this.getLastDay()).format('YYYY-MM-DD'),
                    limit: 999
				});

				if (Object.keys(this.events).length === 0) return;

				const csvExporter = new ExportToCsv(this.csvOptions);

				csvExporter.generateCsv(this.events);
			},
			getFirstDay() {
				return new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth(), 1);
			},
			getLastDay() {
				return new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() + 1, 0);
			},
		}
	};
</script>
