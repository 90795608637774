<template>
	<div>
		<div class="page__content-header">
			<div>
				<PageLinks :links="[
					{
						url: '/events/',
						text: $t('Calendar of Events')
					}
				]"/>
			</div>
			<div class="top__filters">
                <div class="roles_filter" v-if="games.length">
                    Filter by games:
                    <multiselect track-by="name" label="name" placeholder="Select games" v-model="gamesSelect" multiple="true" :options="games"></multiselect>
                </div>
				<EventsExportButton :gamesSelect="gamesFiltered"/>
			</div>
		</div>
		<div class="page__content-body">
			<EventsCalendar :gamesSelect="gamesFiltered"/>
			<EventsList :gamesSelect="gamesFiltered"/>
		</div>
	</div>
</template>

<script>
	import EventsCalendar from '../components/Events/EventsCalendar'
	import EventsList from '../components/Events/EventsList'
	import EventsExportButton from '../components/Events/EventsExportButton'
	import PageLinks from '../components/Page/Links'
    import Multiselect from "vue-multiselect";
    import {mapActions, mapGetters} from 'vuex'
	export default {
        data () {
          return {
              gamesSelect: []
          }
        },
        methods: {
            ...mapActions({
                getGames: 'getGames',
            })
        },
        computed: {
            gamesFiltered() {
              return this.gamesSelect.map(item => item.id)
            },
            ...mapGetters({
                games: 'games'
            })
        },
        mounted () {
            this.getGames()
        },
		components: {
			PageLinks,
			EventsCalendar,
            Multiselect,
			EventsList,
			EventsExportButton
		}
	}
</script>
<style scoped lang="scss">
.top__filters {
    display: flex;
    align-items: center;
    .roles_filter {
        margin-right: 20px;
        display: flex;
        align-items: center;
        .multiselect {
            width: 300px;
            margin-left: 20px;
            ::v-deep {
                .multiselect__tags-wrap {
                    display: flex;
                    flex-wrap: wrap;
                    .multiselect__tag {
                        display: block;
                    }
                }
            }
        }
        white-space: nowrap;
    }
}
</style>
