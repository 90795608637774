<template>
	<div>
		<div class="page__content-header">
			<div>
				<PageLinks v-bind:links="[
					{
						url: '/regions',
						text: $t('All Regions')
					}
				]"/>
			</div>
			<div>
				<RouterLink to="/regions/add" :class="'button button-primary'">{{$t('Create New Region')}}</RouterLink>
			</div>
		</div>
		<div>
			<div class="table-wrapper">
				<table v-if="regions">
					<thead class="text-left">
					<tr>
						<th scope="col">{{$t('ID')}}</th>
						<th scope="col">{{$t('Title')}}</th>
						<th scope="col">{{$t('Supported Locales')}}</th>
						<th scope="col">{{$t('Countries')}}</th>
						<th scope="col"></th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="region in regions">
						<td scope="row">{{ region.id }}</td>
						<td>{{ region.title }}</td>
						<td>{{ region.supported_locales.join(',') }}</td>
						<td>{{ getCountriesNames(region.countries) }}</td>
						<td v-if="!isTournamentManager">
							<Dropdown
								:data-position="'left'"
								:data-horizontal-aligntment="'left'"
								:data-vertical-aligntment="'top'"
								:toggle-class="'dots-toggle'"
							>
								<SvgIcon
									slot="dropdown-toggle"
									width="23"
									height="5"
									view-box="0 0 23 5"
									:iconType="'dots'"
								/>
								<div slot="dropdown-content">
									<RouterLink
										:class="'dropdown__link'"
										:to="{ path: '/regions/edit/' +  region.id }"
									>
										<SvgIcon
											width="16"
											height="16"
											:iconType="'edit'"
										/>
										<span>{{$t('Edit')}}</span>
									</RouterLink>
									<a
										href="#"
										class="dropdown__link"
										@click.prevent="deleteById(region.id)"
									>
										<svg-icon
											width="16"
											height="16"
											:iconType="'delete'"
										/>
										<span>{{$t('Delete')}}</span>
									</a>
								</div>
							</Dropdown>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<PagePagination
				v-if="regionsMeta"
				:current="regionsMeta.currentPage()"
				:total="regionsMeta.lastPage()"
				@change="changePage"
			/>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions, mapMutations } from 'vuex'

	import SvgIcon from '../../components/SvgIcon';
	import Dropdown from '../../components/Dropdown';
	import PagePagination from '../../components/Page/Pagination';
	import PageLinks from '../../components/Page/Links';

	export default {
		components: {
			SvgIcon,
			Dropdown,
			PageLinks,
			PagePagination
		},
		computed: {
			...mapGetters([
				'regions',
				'regionsMeta',
				'isTournamentManager',
			])
		},
		created () {
			this.init();
		},
		methods: {
			...mapMutations({
				hideDropdown: 'HIDE_DROPDOWN'
			}),
			...mapActions({
				getRegions: 'getRegions',
				deleteRegion: 'deleteRegion',
			}),
			init () {
				this.getRegions();
			},
			changePage(page) {
				this.getRegions({
					page: page
				});
			},
			getCountriesNames(countries) {
				const names = [];

				countries.map(country => {
					names.push(country.value)
				});

				return names.join(',');
			},
			async deleteById (id) {
				this.hideDropdown();

				await this.deleteRegion(id);
			},
		},
	}
</script>
