<template>
	<div class="events-calendar" v-if="events">
		<div class="events-calendar__navigation">
			<div>
				<a href="#" class="previous-button" @click.prevent="previousMonth">
					<SvgIcon
						width="24"
						height="24"
						:iconType="'arrow-left'"
					/>
				</a>
			</div>
			<div class="caption" v-if="selectedMonth">
				{{getCaption()}}
			</div>
			<div>
				<a href="#" class="next-button" @click.prevent="nextMonth">
					<SvgIcon
						width="24"
						height="24"
						:iconType="'arrow-right'"
					/>
				</a>
			</div>
		</div>
		<div class="events-calendar__header">
			<div>{{$t('Monday')}}</div>
			<div>{{$t('Tuesday')}}</div>
			<div>{{$t('Wednesday')}}</div>
			<div>{{$t('Thursday')}}</div>
			<div>{{$t('Friday')}}</div>
			<div>{{$t('Saturday')}}</div>
			<div>{{$t('Sunday')}}</div>
		</div>
		<div class="events-calendar__body">
			<div class="row" v-for="week in calendarData">
				<div
					class="cell"
					v-for="day in week"
					:class="{disabled: day.disabled, 'has-events': day.hasEvents, active: selectedDay === day.fullDate}"
					@click="selectDay(day.fullDate)"
				>
					<span class="cell__date">
						{{day.date.getDate()}}
					</span>
					<span class="cell__info" v-if="day.hasEvents">
						<span class="cell__info-tournament">
							<SvgIcon
								width="16"
								height="16"
								viewBox="0 0 24 24"
								:iconType="'cup-filled'"
							/>
							<span>
								{{day.info.tournamentsCount}}
							</span>
						</span>
						<span class="cell__info-games">
							<SvgIcon
								width="16"
								height="16"
								viewBox="0 0 16 16"
								:iconType="'joystick'"
							/>
							<span>
								{{day.info.gamesCount}}
							</span>
						</span>
						<span class="cell__info-members">
							<SvgIcon
								width="16"
								height="16"
								viewBox="0 0 24 24"
								:iconType="'people-filled'"
							/>
							<span>
								{{day.info.membersCount}}
							</span>
						</span>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'

	import moment from 'moment'
	import helpers from '../../helpers'
	import SvgIcon from '../SvgIcon'

	export default {
		name: "EventsCalendar",
        props: {
            gamesSelect: {
                default: [],
                type: Array
            }
        },
		components: {
			SvgIcon
		},
		data() {
			return {
				calendarData: []
			};
		},
		created() {
			this.updateCalendarData();
			this.selectDay(new moment().format('YYYY-MM-DD'));
		},
		computed: {
			...mapGetters({
				events: 'events',
				selectedDay: 'selectedDay',
				selectedMonth: 'selectedMonth'
			}),
		},
		watch: {
			'selectedMonth': {
				handler() {
					this.updateCalendarData();
				}
			},
            async gamesSelect() {
                await this.updateCalendarData();
                this.selectDay(new moment().format('YYYY-MM-DD'));
            }
		},
		methods: {
			...mapActions({
				getEvents: 'getEvents',
				setSelectedDay: 'setSelectedDay',
				setSelectedMonth: 'setSelectedMonth'
			}),
			async updateCalendarData() {
				await this.getEvents({
                    gamesSelect: this.gamesSelect.join(','),
					from: new moment(this.getFirstDay()).format('YYYY-MM-DD'),
					to: new moment(this.getLastDay()).format('YYYY-MM-DD')
				});

				this.prepareCalendarData();
			},
			previousMonth: function () {
				const previousMonth = this.selectedMonth.getMonth() - 1;
				this.setSelectedMonth(new Date(this.selectedMonth.setMonth(previousMonth)));
				this.updateCalendarData();
			},
			nextMonth: function () {
				const nextMonth = this.selectedMonth.getMonth() + 1;
				this.setSelectedMonth(new Date(this.selectedMonth.setMonth(nextMonth)));
				this.updateCalendarData();
			},
			prepareCalendarData: function () {
				const {monthStart, monthEnd} = this.getMonthInterval();
				const calendarData = [];

				let week = [];
				let currentDay = monthStart;

				while (currentDay <= monthEnd) {
					const currentDate = moment(currentDay).format('YYYY-MM-DD');
					const events = this.getCurrentDayEvents(currentDay);
					let day = {
						date: new Date(currentDay),
						fullDate: currentDate,
						disabled: this.isOutOfMonthDay(currentDate,),
						info: {},
						hasEvents: !!events
					};

					if (events) {
						day.info = {
							tournamentsCount: events.tournaments_count,
							membersCount: events.max_members,
							gamesCount: Object.keys(events.game_ids).length,
						};
					}

					week.push(day);

					if (week.length === 7) {
						calendarData.push(week);
						week = []
					}

					currentDay.setDate(currentDay.getDate() + 1);
				}

				this.calendarData = calendarData;
			},
			selectDay(day) {
				const date = helpers.prepareTimeToGetDayEvents(day);
				const currentDay = moment(date).format('YYYY-MM-DD HH:mm:ss');
				const nextDay = moment(date.setDate(date.getDate() + 1)).format('YYYY-MM-DD HH:mm:ss');

				this.setSelectedDay({
                    games: this.gamesSelect.join(','),
					day: day,
					from: currentDay,
					to: nextDay
				});
			},
			getFirstDay() {
				return new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), 1);
			},
			getLastDay() {
				return new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth() + 1, 0);
			},
			getMonthInterval() {
				let first = new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), 1);
				let last = new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth() + 1, 0);

				const startDow = (first.getDay() + 6) % 7;
				const endDow = (last.getDay() + 6) % 7;

				first.setDate(first.getDate() - startDow);

				last.setDate(last.getDate() + (6 - endDow));

				return {
					monthStart: first,
					monthEnd: last
				}
			},
			getCurrentDayEvents(day) {
				const currentDate = moment(day).format('YYYY-MM-DD');
				return this.events[currentDate];
			},
			isOutOfMonthDay(day) {
				const firstDay = this.getFirstDay();
				const lastDay = this.getLastDay();
				return moment(day).month() > moment(firstDay).month() || moment(day).month() < moment(lastDay).month()
			},
			getCaption() {
				return moment(this.selectedMonth).format('MMMM YYYY');
			}
		}
	}
</script>
