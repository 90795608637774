import { render, staticRenderFns } from "./BracketItemPlayer.vue?vue&type=template&id=3436e474&scoped=true"
import script from "./BracketItemPlayer.vue?vue&type=script&lang=js"
export * from "./BracketItemPlayer.vue?vue&type=script&lang=js"
import style0 from "./BracketItemPlayer.vue?vue&type=style&index=0&id=3436e474&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3436e474",
  null
  
)

export default component.exports