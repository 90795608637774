import shuffleArray from './shuffle-array'
import Participant from '../dto/participant'

export default (data) => {
	const brackets = data.brackets;
    let participants = [...shuffleArray(data.participants)]
    const allRounds = () => {
        let matches = []
        let rounds = JSON.parse(JSON.stringify(brackets))
        rounds.forEach(item => {
            matches.push(...item.games)
        })
        matches = matches.map(item => {
            return {
                ...item,
                games: []
            }
        })
        return matches
    }
    const findReferencesMatch = (id) => {
        return allRounds().filter(item => item.next_match_id === id)
    }
	for (let i = 0; i < brackets.length; i++) {
		let round = brackets[i];
		for (let j = round.games.length - 1; j >= 0; j--) {
            if (round.games[j].is_looser_match) continue
			let gameFull = false;
			let newParticipant = null;

                const references = findReferencesMatch(round.games[j].id)
                const fillFull = () => {
                    newParticipant = participants.shift();

                    brackets[i].games[j].first_participant = newParticipant;

                    newParticipant = participants.shift();
                    brackets[i].games[j].second_participant = newParticipant;

                    gameFull = true;
                }
                if (!references) {
                    fillFull()
                }
                else {
                    if (references.length === 0) {
                        fillFull()
                    }
                    else if (references.length === 1) {
                        newParticipant = participants.shift();
                        brackets[i].games[j].first_participant = newParticipant;
                    }
                }
		}
	}

	return brackets;
};
