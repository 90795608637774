<template>
    <div v-if="tournament">
        <PageLinks :links="[
					{
						url: '/tournament/round-robin/' +  tournament.getId() + '/overview',
						text: tournament.getTitle()
					},
					{
						url: '/tournament/round-robin/' +  tournament.getId() + '/game',
						text: 'Game panel'
					},
					{
						url: '/tournament/round-robin/' +  tournament.getId() + '/participants',
						text: $t('Participants')
					}
				]"/>
        <router-view :id="id"></router-view>
        <ChatsListContainer :id="id"/>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import PageLinks from '../../components/Page/Links'
import SvgIcon from '../../components/SvgIcon'
import StatusProgress from '../../components/StatusProgress'
import ChatsListContainer from '../../components/Chat/ChatsListContainer'

export default {
    props: ["id"],
    components: {
        SvgIcon,
        PageLinks,
        StatusProgress,
        ChatsListContainer
    },
    data() {
        return {
            game: null,
            platform: null,
        }
    },
    computed: {
        ...mapGetters([
            'tournament',
            'rule',
            'getGame',
            'getPlatform',
            'isMobile',
            'countries'
        ]),
        filteredCountries () {
            return this.tournament.getCountry().map(item => {
                return {
                    info: this.countries.find(i => i.id === item),
                    id: item
                }
            })
        }
    },
    created() {
        this.init();
    },
    methods: {
        ...mapActions({
            getTournament: 'GET_ROUND_ROBIN_TOURNAMENT',
            getGames: 'getGames',
            getPlatforms: 'GET_PLATFORMS',
            getCountries: 'getCountries',
            getRule: 'GET_RULE',
            centrifugeConnect: 'centrifugeConnect'
        }),
        async init() {
            await this.getTournament(this.id)

            await this.getGames();
            await this.getPlatforms();
            if (this.tournament.getRuleId()) {
                await this.getRule(this.tournament.getRuleId());
            }
            this.getCountries({ identities: this.tournament.getCountry().join(',') } );
            await this.centrifugeConnect();

            this.game = this.getGame(this.tournament.getGameId())[0];
            this.platform = this.getPlatform(this.tournament.getPlatformId())[0];
        }
    }
}
</script>
