<template>
    <div>
        <div class="page__content-header">
            <div>
                <h3 class="page__content-header_title">Users</h3>
            </div>
            <div class="page__content-header_right">
                <div class="roles_filter">
                    Filter by role:
                    <multiselect placeholder="Select role" v-model="role" :options="roles"></multiselect>
                </div>

                <div class="input__search">
                    <input
                        v-model="search"
                        type="text"
                        @keydown.enter="getUsers"
                        name="search"
                        class="form-input"
                        :placeholder="$t('Search')"
                        required
                    >
                    <svg-icon
                        class="search-icon"
                        width="16"
                        height="16"
                        :iconType="'search'"
                    />
                </div>
                <button type="button" class="button export__button button-primary">
                    Export users
                </button>
            </div>
        </div>
        <div class="page__content-body">
            <div class="table-wrapper">
                <table>
                    <thead class="text-left">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Roles</th>
                        <th scope="col">Name</th>
                        <th scope="col">Nickname</th>
                        <th scope="col">Email</th>
                        <th scope="col">Timezone</th>
                        <th class="action_col" scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr @click="$router.push({ name: 'user', params: { id: user.id } })" v-for="user in users" :key="user.id">
                        <td>
                            {{ user.id }}
                        </td>
                        <td>
                            <div v-for="role in user.roles">
                                {{ role }}
                            </div>
                            <div v-if="!user.roles.length">Player</div>
                        </td>
                        <td>{{ user.name }}</td>
                        <td>{{ user.nickname }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.timezone }}</td>
                        <td class="action_col">
                            <button class="edit_button">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6101 3.89225C14.13 3.37238 14.13 2.52415 13.6101 2.03163L11.9684 0.389909C11.4758 -0.12997 10.6276 -0.12997 10.1077 0.389909L8.84909 1.64856C8.73964 1.75801 8.73964 1.97691 8.84909 2.11372L11.8863 5.15091C12.0231 5.26036 12.242 5.26036 12.3514 5.15091L13.6101 3.89225ZM7.78197 2.71569L0.585746 9.91191L0.011143 13.2227C-0.0709432 13.6879 0.312126 14.0709 0.777281 13.9889L4.08809 13.4143L11.2843 6.21803C11.4211 6.10858 11.4211 5.88969 11.2843 5.75287L8.24713 2.71569C8.11032 2.60624 7.89142 2.60624 7.78197 2.71569ZM3.40404 9.28258C3.23987 9.14577 3.23987 8.89951 3.40404 8.7627L7.6178 4.54894C7.75461 4.38477 8.00087 4.38477 8.13768 4.54894C8.30185 4.68575 8.30185 4.93201 8.13768 5.06882L3.92392 9.28258C3.78711 9.44675 3.54085 9.44675 3.40404 9.28258ZM2.41901 11.581H3.73238V12.5934L1.95385 12.8944L1.10563 12.0462L1.40661 10.2676H2.41901V11.581Z" fill="#878787"/>
                                </svg>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <PagePagination
                :current="current_page"
                :total="last_page"
                @change="getUsers"
            />
        </div>
    </div>
</template>
<script>
import PageLinks from '../../components/Page/Links'
import SvgIcon from '../../components/SvgIcon'
import PagePagination from '../../components/Page/Pagination';
import UsersService from '../../api/users'
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'
export default {
    data () {
        return {
            role: null,
            search: '',
            last_page: 1,
            current_page: 1,
            users: []
        }
    },
    computed: {
        ...mapGetters({
            user: 'getUser'
        }),
        roles () {
            const roles = []
            if (this.user.roles.includes('Super admin')) {
                roles.push('Super admin')
                roles.push('Tournament admin')
            }
            roles.push('Tournament manager')

            return roles;
        },
    },
    watch: {
        role () {
            this.getUsers()
        },
        search () {
            this.getUsers()
        }
    },
    methods: {
        getUsers (page = 1) {
            UsersService.get({
                search: this.search,
                role: this.role || '',
                page: page,
                limit: 30,
                orderBy: 'DESC'
            }).then(response => {
                this.users = response.data.data
                this.last_page = response.data.meta.last_page
                this.current_page = response.data.meta.current_page
            })
        }
    },
    created () {
      this.getUsers()
    },
    components: {
        PageLinks,
        PagePagination,
        Multiselect,
        SvgIcon
    }
}
</script>
<style scoped lang="scss">
.page__content-header {
    align-items: center;
    margin-top: 22px;
}
.action_col {
    width: 50px;
}
table {
    margin-bottom: 30px;
    tbody tr {
        cursor: pointer;
        td {
            padding-top: 0;
            padding-bottom: 0;
        }
        background: #FAFAFA;
        box-shadow: 0px 0px 4px rgba(0, 80, 77, 0.1), 0px 2px 10px rgba(0, 80, 77, 0.25);
        border-radius: 2px;
    }
    border-spacing: 0 15px;
    .edit_button {
        padding-top: 15px;
        padding-bottom: 15px;
        width: 55px;
        border: none;
        background: transparent;
        cursor: pointer;
        border-left: 1px solid #D6D6D6;
        outline: none;

    }
}
.page__content-header_right {
    display: flex;
    .roles_filter {
        align-items: center;
        display: flex;
        .multiselect {
            margin-left: 10px;
            width: 300px;
        }
        margin-right: 20px;
    }
    .input__search {
        margin-right: 20px;
        width: 377px;
        position: relative;
        display: flex;
        align-items: center;
        input {
            width: 100%;
            max-width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            border: 1px solid #878787;
            border-radius: 4px;
            padding: 9px 15px;
        }
        .search-icon {
            display: flex;
            align-items: center;
            right: 15px;
            position: absolute;
        }
    }
    .export__button {
        padding-left: 39px;
        padding-right: 39px;
    }
}

@media screen and (max-width: 1000px) {
    .table-wrapper {
        padding: 0 5px;
        table {
            min-width: 1000px
        }
    }
}

@media screen and (max-width: 1400px) {
    .page__content-header {
        flex-wrap: wrap;
        .page__content-header_right {
            width: 100%;
        }
    }
}
@media screen and (max-width: 1300px) {
    .page__content-header {
        flex-wrap: wrap;
        .page__content-header_right {
            width: 100%;
            flex-wrap: wrap;
            div {
                width: 100%;
                margin-right: 0 !important;
            }
            .roles_filter {
                order: 1;
                margin-top: 15px;
            }
            .export__button {
                margin-left: auto;
                margin-top: 20px;
                order: 2;
            }
            .input__search {
                margin-top: 20px;
                order: 0;
            }
        }
    }
    .page__content-header_right .roles_filter .multiselect {
        width: 250px;
        margin-left: auto;
    }
}
</style>
