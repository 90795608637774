<template>
    <div class="round__robin">
        <template v-if="tournament.getRR().stage === STAGE_ROUND_ROBIN">
            <div class="page__content-header">
                <PageLinks :links="[
					{
						url: '/tournament/' +  tournament.getId(),
						text: tournament.getTitle()
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/brackets',
						text: $t('Brackets')
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/participants',
						text: $t('Participants')
					}
				]"/>
                <div class="page-content__actions">
                    <template v-if="tournament.getRR().stage === STAGE_ROUND_ROBIN && !tournament.getRR().is_generated_rooms">
                        <div class="participants__indicator">
                            <span>all participant</span>
                            <span>{{ tournament.getParticipantsCount() }}/{{ tournament.getMaxMembers() }}</span>
                        </div>
                        <button
                            v-if="tournament.getRR().rooms.length > 0 && !tournament.getRR().is_generated_rooms"
                            class="fill_empty_spots"
                            :disabled="!tournament.getRR().rooms.length"
                            @click="fillEmptySpots"
                        >
                            Fill empty spots
                        </button>
                        <button class="create__rooms"
                                @click="generateMatches"
                                title="There are empty rooms"
                                :disabled="!tournament.getRR().rooms.length || issetEmptyRooms">
                            Start tournament
                        </button>
                    </template>
                </div>
            </div>
            <div class="page__content-body">
                <RoundRobinStage :key="key"></RoundRobinStage>
            </div>
        </template>
        <template v-else>
            <BracketsContainer :id="tournament.getId()" :key="key" v-if="tournament.getRR().stage === STAGE_BRACKETS"></BracketsContainer>
        </template>

        <ChatsListContainer :id="tournament.getId()"></ChatsListContainer>
    </div>
</template>
<script>
import PageLinks from '../../components/Page/Links'
import RoundRobinStage from './RoundRobinStage/RoundRobinStage'
import RoundRobinService from '../../api/round-robin'
import ChatsListContainer from '../../components/Chat/ChatsListContainer'
import { mapGetters, mapActions } from 'vuex'
import Brackets from "../../components/Brackets/Brackets";
import BracketsContainer from "../Brackets/BracketsContainer";
export default {
    data () {
      return {
          issetEmptyRooms: false,
          key: 0,
          STAGE_ROUND_ROBIN: 1,
          STAGE_BRACKETS: 2
      }
    },
    components: {
        BracketsContainer,
        Brackets,
        PageLinks,
        ChatsListContainer,
        RoundRobinStage
    },
    watch: {
        async $route () {
            await this.getTournament({ id: this.tournament.getId() });
            await this.subscribeRR();
        }
    },
    methods: {
        ...mapActions({
            getTournament: 'GET_TOURNAMENT',
            getParticipants: 'GET_ROUND_ROBIN_PARTICIPANTS',
            centrifugeConnect: 'centrifugeConnect'
        }),
        fillEmptySpots () {
            RoundRobinService.fillParticipants(this.tournament.getId()).then(response => {
                if (response && response.data && response.data.status && response.data.status === 200) {
                    this.getTournament(this.tournament.getId());
                    this.key++
                } else {
                    this.$notify({
                        type: 'error',
                        title: 'Start error',
                        group: 'notify',
                        text: 'Unknown error'
                    })
                }
            }).catch((e) => {
                this.$notify({
                    type: 'error',
                    title: 'Start error',
                    group: 'notify',
                    text: e
                })
            })
        },
        subscribeRR () {
            if (this.tournament.getId()) {
                this.centrifugeInstance.subscribe(`rr.${this.tournament.getId()}`, (rr) => {
                    setTimeout(() => {
                        this.getTournament(this.tournament.getId())
                    }, 800)
                })

                this.centrifugeInstance.subscribe(`rr.empty-rooms.${this.tournament.getId()}`, (data) => {
                    this.issetEmptyRooms = data.data.isset
                })
            }
        },
        generateMatches () {
            RoundRobinService.generateMatches(this.tournament.getId()).then((data) => {
                if (data.response && data.response && data.response.status !== 200) {
                    this.$notify({
                        type: 'error',
                        title: 'Generated error',
                        group: 'notify',
                        text: data.response.data.message ? data.response.data.message : 'Unknown error'
                    })
                } else {
                    this.getTournament(this.tournament.getId())
                }
            }).catch((err) => {
                this.$notify({
                    type: 'error',
                    title: 'Generated error',
                    group: 'notify',
                    text: err.response.data.message ? err.response.data.message : 'Unknown error'
                })
            })
        },
        async init () {
            await this.centrifugeConnect();
            this.subscribeRR()
        }
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament',
            centrifugeInstance: 'centrifugeInstance'
        })
    },
    mounted() {
        this.init();
        this.issetEmptyRooms = this.tournament.getRR().rooms.find(item => item.participants.length === 0)
    }
}
</script>
<style scoped lang="scss">
    .round__robin {
        .page-content__actions {
            margin-left: auto;
            .participants__indicator {
                display: flex;
                align-items: center;
                background: #ECECEC;
                border-radius: 4px;
                padding: 13px 15px;
                span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    text-transform: capitalize;
                    color: #303030;
                }
                span + span {
                    margin-left: 25px;
                }
            }
            .create__rooms {
                background: #00E7DF;
                border-radius: 4px;
                padding: 9px 36px;
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                cursor: pointer;
                border: none;
                align-items: center;
                text-align: center;
                letter-spacing: 0.16px;
                text-transform: uppercase;
                font-weight: bold;
                font-family: "Roboto";
                color: #303030;
                outline: none;
                transition: 0.2s;
                &:hover {
                    opacity: 0.7;
                }
                &[disabled] {
                    cursor: not-allowed;
                    color: #C4C4C4;
                    background: #ECECEC;
                }
            }
            .fill_empty_spots {
                padding: 9px 25px;
                border: 2px solid #00E7DF;
                border-radius: 4px;
                font-weight: 700;
                font-size: 14px;
                cursor: pointer;
                line-height: 24px;
                align-items: center;
                text-align: center;
                letter-spacing: 0.16px;
                text-transform: uppercase;
                background: transparent;
                outline: none;
                color: #303030;
                transition: 0.2s;
                &:hover {
                    background: #00E7DF;
                }
                &[disabled] {
                    cursor: not-allowed;
                    color: #C4C4C4;
                    background: #ECECEC;
                }
            }
        }
    }
</style>
