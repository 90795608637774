<template>
	<i>
		<svg
			:width="width"
			:height="height"
			:fill="iconColor"
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="none"
			:viewBox="viewBox"
		>
			<g>
				<component :is="iconType"></component>
			</g>
		</svg>
	</i>
</template>

<script>
	import icons from "./icon";

	export default {
		name: "SvgIcon",
		components: {
			...icons
		},
		props: {
			iconType: {
				type: String
			},
			iconColor: {
				type: String,
				default: "currentColor"
			},
			width: {
				type: [Number, String],
				default: 64
			},
			height: {
				type: [Number, String],
				default: 64
			},
			viewBox: {
				type: [String],
				default: '0 0 64 64'
			},
		}
	};
</script>