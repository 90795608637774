<template>
	<div>
		<div class="page__content-header">
			<div>
				<page-links v-bind:links="[
					{
						url: '/rules',
						text: $t('All Rules')
					}
				]"/>
			</div>
			<div>
				<RouterLink to="/rule/add" :class="'button button-primary'">{{$t('Create New Rule')}}</RouterLink>
			</div>
		</div>
		<div>
			<FiltersBar :options="filters" :onChange="filtersChanged"/>
			<table v-if="rules">
				<thead class="text-left">
				<tr>
					<th scope="col">{{$t('ID')}}</th>
					<th scope="col">{{$t('Title')}}</th>
					<th scope="col"></th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="rule in rules">
					<td @click="goToPage('/rule/' + rule.getId())" scope="row">{{ rule.getId() }}</td>
					<td @click="goToPage('/rule/' + rule.getId())">{{ rule.getTitle() }}</td>
					<td>
						<dropdown
							:data-position="'left'"
							:data-horizontal-aligntment="'left'"
							:data-vertical-aligntment="'top'"
							:toggle-class="'dots-toggle'"
						>
							<svg-icon
								slot="dropdown-toggle"
								width="23"
								height="5"
								view-box="0 0 23 5"
								:iconType="'dots'"
							/>
							<div slot="dropdown-content">
								<RouterLink
									:class="'dropdown__link'"
									:to="{ path: '/rule/' +  rule.getId() }"
								>
									<svg-icon
										width="16"
										height="16"
										:iconType="'hand'"
									/>
									<span>{{$t('View')}}</span>
								</RouterLink>
								<RouterLink
									:class="'dropdown__link'"
									:to="{ path: '/rule/edit/' +  rule.getId() }"
								>
									<svg-icon
										width="16"
										height="16"
										:iconType="'edit'"
									/>
									<span>{{$t('Edit')}}</span>
								</RouterLink>
								<a
									href="#"
									class="dropdown__link"
									@click.prevent="deleteById(rule.getId())"
								>
									<svg-icon
										width="16"
										height="16"
										:iconType="'delete'"
									/>
									<span>{{$t('Delete')}}</span>
								</a>
							</div>
						</dropdown>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions, mapMutations } from 'vuex'

	import router from '../../routes';
	import SvgIcon from '../../components/SvgIcon';
	import Dropdown from '../../components/Dropdown';
	import PagePagination from '../../components/Page/Pagination';
	import PageLinks from '../../components/Page/Links';
	import FiltersBar from "../../components/FiltersBar";

	export default {
		components: {
			SvgIcon,
			Dropdown,
			PageLinks,
			PagePagination,
			FiltersBar,
		},
		computed: {
			...mapGetters([
				'games',
				'rules',
				'activeFilters'
			])
		},
		data () {
			return {
				filters: [],
				filtersUtil: this.$options.filters
			}
		},
		created () {
			this.init();
		},
		methods: {
			...mapMutations({
				setFilters: 'SET_FILTERS',
				hideDropdown: 'HIDE_DROPDOWN',
			}),
			...mapActions({
				getRules: 'GET_RULES',
				deleteRule: 'DELETE_RULE',
				getGames: 'getGames'
			}),
			init () {
				this.getRules(this.activeFilters('rules'));
				this.prepareFiltersFields();
			},
			async deleteById (id) {
				const activeFilters = this.activeFilters('rules')

				this.hideDropdown();

				await this.deleteRule(id);
				await this.getRules(activeFilters);
			},
			goToPage (url) {
				router.push(url)
			},
			async prepareFiltersFields () {
				const activeFilters = this.activeFilters('rules');
				await this.getGames();
				this.filters.push({
					type: 'select',
					name: 'game_id',
					value: activeFilters.hasOwnProperty('game_id') ? activeFilters.game_id : null,
					options: this.filtersUtil.gamesOptions(this.games)
				});
			},
			filtersChanged (field, value) {
				this.setFilters({
					type: 'rules',
					key: field,
					value: value ? value : null
				});

				this.getRules(this.activeFilters('rules'));
			}
		},
	}
</script>
