import http from '../utils/http'
import joinUrls from '../utils/join-urls'
import joinParams from "../utils/join-params";

export default {
    get(id) {
        if (!id) {
            return Promise.reject(new Error('Tournament id is required to get tournament data'))
        }

        return http.get(joinUrls('/admin', 'tournaments', 'round-robin', id))
    },
    getParticipants(id, data) {
        if (!id) {
            return Promise.reject(new Error('Tournament id is required to get participants'));
        }

        const url = joinUrls('/admin', 'tournaments', 'round-robin', id, 'participants');

        return http.get(joinParams(url, data));
    },

    editRound (tournamentId, roomId, round, data) {
        return http.put(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms', roomId, 'round', round), data)
    },

    editRoom (tournamentId, roomId, data) {
        return http.put(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms', roomId), data)
    },

    createRooms (tournamentId, data) {
        return http.post(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms'), data)
    },

    startBracketsStage (tournamentId) {
        return http.post(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'brackets'))
    },

    generateMatches (tournamentId) {
        return http.post(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms', 'matches'))
    },

    fillParticipants (tournamentId) {
        return http.put(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms', 'fill'))
    },

    addParticipantToRoom (tournamentId, roomId, data) {
        return http.post(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms', roomId), data)
    },

    roomChangeWinners (tournamentId, roomId, data) {
        return http.put(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms', roomId, 'winner'), data)
    },

    removeParticipantFromRoom (tournamentId, roomId, participantId) {
        return http.delete(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms', roomId + '?participant_id=' + participantId))
    },

    deleteRoom (tournamentId, roomId) {
        return http.delete(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms', roomId, 'delete'))
    },

    getRoomMatches (tournamentId, roomId) {
        return http.get(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms', roomId, 'matches'))
    },

    getRoomMatch (tournamentId, roomId, matchId) {
        return http.get(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms', roomId, 'matches', matchId))
    },

    getMatchResult (tournamentId, matchId) {
        return http.get(joinUrls('tournaments', 'round-robin', tournamentId, 'matches', matchId, 'result'))
    },

    getRoom (tournamentId, roomId) {
        return http.get(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms', roomId))
    },

    rrFinishMatch (tournamentId, roomId, matchId, data) {
        return http.post(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms', roomId, 'matches', matchId, 'finish'), data)
    },

    rrRevertMatch (tournamentId, roomId, matchId) {
        return http.post(joinUrls('/admin', 'tournaments', 'round-robin', tournamentId, 'rooms', roomId, 'matches', matchId, 'revert'))
    }
}
