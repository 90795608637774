import http from '../utils/http'
import joinUrls from '../utils/join-urls'

export default {
	get(tournament) {
		return http.get(joinUrls('/tournaments', tournament, 'arena'));
	},
	update(tournament, match, body) {
		return http.put(joinUrls('/tournaments', tournament, 'arena', match), body);
	},
	start(id) {
		return http.put(joinUrls('/tournaments', id, 'start'));
	},
	finish(id) {
		return http.post(joinUrls('/tournaments', id, 'arena', 'finish'));
	},
	getTimings(id) {
		if (!id) {
			return Promise.reject(new Error('Tournament id is required to get timings data'))
		}

		return http.get(joinUrls('/tournaments', id, 'arena', 'timings'));
	},
	updateTimings(id, data) {
		if (!id) {
			return Promise.reject(new Error('Tournament id is required to update timings data'))
		}

		return http.put(joinUrls('/tournaments', id, 'arena', 'timings'), {rounds: data})
	},
	solo(data) {
		return http.get('/leaderboard', data);
	},
	team(data) {
		return http.get('/team-leaderboard', data);
	},
	clan(data) {
		return http.get('/clan-leaderboard', data);
	}
}