<template>
    <div>
        <PageLinks v-bind:links="[
			{
				url: '/news/post',
				text: $t('Post news'),
				classes: 'active'
			}
		]"/>
        <form class="form form-edit-templates">
            <div class="form-group text-center">
                <ImagePicker
                    :image="form.image"
                    :label="$t('Image')"
                    :formErrors="errors.image"
                    :onChangeImage="onChangeImage"
                    :onRemoveImage="onRemoveImage"
                />
            </div>
            <div class="form-group text-center">
                <ImagePicker
                    :image="form.icon_image"
                    :label="$t('Icon (300x300)')"
                    :formErrors="errors.icon_image"
                    :onChangeImage="onChangeIconImage"
                    :onRemoveImage="onRemoveIconImage"
                />
            </div>
            <div class="form-group">
                <FormInput
                    :name="'title'"
                    :label="$t('Title')"
                    :placeholder="$t('Title')"
                    :value="form.title"
                    :formErrors="errors.title"
                    :onChange="formFieldChanged"
                />
            </div>
            <div class="form-group" v-if="gamesOptions">
                <FormSelect
                    :name="'game_id'"
                    :label="$t('Game')"
                    :value="form.game_id"
                    :options="gamesOptions"
                    :formErrors="errors.game_id"
                    :onChange="formFieldChanged"
                />
            </div>
            <div class="form-group">
                <FormTextarea
                    :name="'description'"
                    :label="$t('Short description')"
                    :placeholder="$t('Short description')"
                    :value="form.description"
                    :formErrors="errors.description"
                    :onChange="formFieldChanged"
                />
            </div>
            <div class="form-group">
                <!--				<FormTextarea-->
                <!--					:name="'description'"-->
                <!--					:label="$t('Description')"-->
                <!--					:placeholder="$t('Content')"-->
                <!--					:value="form.description"-->
                <!--					:formErrors="errors.description"-->
                <!--					:onChange="formFieldChanged"-->
                <!--				/>-->
                <label for="">
                    <span class="form-label">Content</span>
                </label>
                <TextEditor
                    :name="'content'"
                    class="form-text-editor"
                    :label="$t('Content')"
                    :placeholder="$t('Content')"
                    :value="form.content"
                    :formErrors="errors.content"
                    @change="formFieldChanged"
                    v-model="form.content"
                />
                <div class="form-notification error" v-if="errors.content"><span>{{ errors.content }}</span></div>
            </div>
            <button
                class="button button-primary form-button-submit"
                @click.prevent="save"
            >
                {{$t('Post')}}
            </button>
        </form>
    </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'

import SvgIcon from '../../components/SvgIcon'
import PageLinks from '../../components/Page/Links'
import ImagePicker from '../../components/Form/ImagePicker'
import FormInput from '../../components/Form/Input'
import FormTextarea from '../../components/Form/Textarea'
import FormSelect from '../../components/Form/Select'
import News from "../../dto/news";

import helpers from '../../helpers'
import {VueEditor} from "vue2-editor";

export default {
    props: ['id'],
    components: {
        SvgIcon,
        PageLinks,
        ImagePicker,
        FormInput,
        FormSelect,
        FormTextarea,
        'TextEditor': VueEditor
    },
    computed: {
        ...mapGetters([
            'games'
        ])
    },
    data() {
        return {
            form: {},
            defaultData: {
                title: '',
                game_id: '',
                content: '',
                image: '',
                icon_image: '',
                description: '',
            },
            imageChanged: false,
            iconImageChanged: false,
            gamesOptions: [],
            errors: {},
        };
    },
    watch: {
        $route() {
            this.init();
        },
    },
    created() {
        this.init();
    },
    methods: {
        ...mapActions([
            'getGames',
            'getNews',
            'addNews',
            'updateNews'
        ]),
        ...mapMutations({
            updateGames: 'UPDATE_GAMES'
        }),
        async init() {
            this.form = this.defaultData;
            this.imageChanged = false;
            this.iconImageChanged = false;
            await this.getGames().then(() => {
                const games = [{
                    id: null,
                    name: 'General'
                },
                    ...this.games
                ];
                this.updateGames(games);
            });

            if (this.id) {
                await this.getNews(this.id).then(data => {
                    const currentNews = new News(data);
                    this.form = {
                        title: currentNews.getTitle(),
                        game_id: currentNews.getGame(),
                        image: currentNews.getImage(),
                        icon_image: currentNews.getIconImage(),
                        description: currentNews.getDescription(),
                        content: currentNews.getContent()
                    };
                });
            }

            this.prepareFieldsAfterLoad();
        },
        formFieldChanged(field, value) {
            this.form[field] = value;
        },
        prepareFieldsAfterLoad() {
            this.initGamesOptions();
        },
        initGamesOptions() {
            const isEmpty = this.form.game_id === '';
            this.gamesOptions = this.games.map(item => {
                return {value: item.id, name: item.name}
            });
            this.form.game_id = isEmpty ? null : this.form.game_id;
        },
        save() {
            if (!this.validate()) return;

            this.id ? this.updatePost() : this.addPost();
        },
        addPost() {
            const data = helpers.copyObject(this.form);

            if (!data.image) {
                delete data.image;
            }

            if (null === data.game_id) {
                delete data.game_id;
            }

            this.addNews(data).catch(err => {
                this.$notify({
                    type: 'error',
                    title: 'Permission error',
                    group: 'notify',
                    text: err.response.data.message ? err.response.data.message : 'Unknown error'
                })
            });
        },
        updatePost() {
            let data = helpers.copyObject(this.form);
            data = helpers.deleteEmptyObjectFields(data);

            if (!this.imageChanged) {
                delete data.image;
            }

            if (!this.iconImageChanged) {
                delete data.icon_image;
            }

            if (null === data.game_id) {
                delete data.game_id;
            }

            this.updateNews({
                id: this.id,
                data: data
            }).catch(err => {
                this.$notify({
                    type: 'error',
                    title: 'Permission error',
                    group: 'notify',
                    text: err.response.data.message ? err.response.data.message : 'Unknown error'
                })
            })
        },
        validate() {
            const form = this.form;
            const titleValid = '' !== form.title;
            const descriptionValid = '' !== form.description;
            const contentValid = '' !== form.content
            const errors = {};

            if (!titleValid) {
                errors.title = [this.$t('Title field should not be empty.')]
            }

            if (!descriptionValid) {
                errors.description = [this.$t('Brief description or content must be required.')]
            }
            if (!contentValid) {
                errors.content = [this.$t('Brief description or content must be required.')]
            }

            if (Object.keys(errors).length) {
                this.errors = errors;
            }

            return titleValid && descriptionValid && contentValid;
        },
        onChangeImage(image) {
            this.form.image = image;
            this.imageChanged = true;
        },
        onRemoveImage() {
            this.form.image = null;
            this.imageChanged = true;
        },
        onChangeIconImage(image) {
            this.form.icon_image = image;
            this.iconImageChanged = true;
        },
        onRemoveIconImage() {
            this.form.icon_image = null;
            this.iconImageChanged = true;
        },
    }
}
</script>

<style scoped lang="scss">
.form {
    max-width: 100%;
}
</style>
