import axios from 'axios'
import router from '../routes'
import store from '../store'

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    switch (error.response.status) {
        case 401 :
            store.commit('SHOW_LOADER', false);
            if (this.$route.name !== 'tournament-public-brackets') {
                store.dispatch('LOGOUT');

                router.push('/login').catch(() => {});
            }

            return error;
        case 400 :
        case 402 :
        case 403 :
        case 500 :
            store.commit('toastEvents/ADD_EVENT', {message: error?.response?.data?.message ?? error.message, type: 'error'}, {root: true});
            return error;
        default:
            return error;
    }
});

axios.interceptors.request.use(config => {
    console.log(config)
    let endPoint = config.url.split('/').pop().split('?').shift();
    let excludeEndPoints = ['messages', 'sys-events', 'results', 'login', 'users', 'admin-calls'];

    if (!excludeEndPoints.includes(endPoint)) store.commit('SHOW_LOADER', true);

    window.onbeforeunload = function () {
        if (store.getters.showLoader) {
            return "Do you really want to leave our brilliant application?";
        }
    };

    return config;
});


axios.interceptors.response.use(response => {
    store.commit('SHOW_LOADER', false);
    return response
});
