export default function Rules( data= null) {
	const info = data;

	this.getId = () => info.id;

	this.getTitle = () => info.title;

	this.getContent = () => info.content;

    this.getGame = () => info.game;
}
