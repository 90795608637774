import auth from '../../api/auth'
import axios from 'axios'

const state = () => ({
	token: localStorage.getItem('token') || '',
	wsToken: localStorage.getItem('wsToken') || '',
	user: localStorage.getItem('userMeta') ? localStorage.getItem('userMeta') : {}
});

const getters = {
	getToken: state => state.token,
	getWsToken: state => state.wsToken,
	isLoggedIn: state => !!state.token && !!state.user,
	getUser: state => {
		return JSON.parse(state.user);
	},
	isSuperAdmin: state => {
		const user = JSON.parse(state.user);
		return user.hasOwnProperty('roles') ? user.roles.includes('SuperAdmin') : false
	},
	isTournamentManager: state => {
		const user = JSON.parse(state.user);
		return user.hasOwnProperty('roles') ? user.roles.includes('TournamentManager') : false
	},
};

const actions = {
	LOGIN: ({ commit }, user) => {
		return new Promise((resolve, reject) => {
			auth.login(user)
				.then((resp) => {
					if (resp.status && resp.status === 200) {
						commit('SET_TOKEN', resp.data.token);
						resolve(resp)
					} else {
						reject(resp)
					}
				})
				.catch(err => {
					reject(err);
					commit('AUTH_ERROR');
				})
		});
	},
	GET_USER: ({dispatch, commit}) => {
		return new Promise((resolve, reject) => {
			auth.user()
				.then((response) => {
                    if (response.status && response.status === 200) {
						commit('SET_USER', response.data);
						resolve(response);
					}
                    else {
                        reject('err');
                        // throw new Error('Failed load user')
                    }
				})
				.catch(err => {
					reject(err);
				});
		});
	},
	GET_WS_TOKEN: ({dispatch, getters, commit}) => {
		const user = getters.getUser;
		return new Promise((resolve, reject) => {
			auth.getWsToken(user.id)
				.then((response) => {
					if (response.status && response.status === 200) {
						commit('SET_WS_TOKEN', response.data);
						resolve(response);
					}
				})
				.catch(err => {
					reject(err);
				});
		});
	},
	TOKEN_LOGIN: ({dispatch, commit}, token) => {
		return new Promise((resolve, reject) => {
			commit('SET_TOKEN', token);
			setTimeout(() => {
				auth.user()
					.then((response) => {
						if (response.status && response.status === 200) {

							auth.getWsToken(response.data.id)
								.then((response) => {
									if (response.status && response.status === 200) {
										commit('SET_WS_TOKEN', response.data);
										resolve(response);
									}
								})
								.catch(err => {
									reject(err);
								});

							commit('SET_USER', response.data);
							resolve(response);
						} else {
							reject(response)
						}
					})
					.catch(err => {
						reject(err)
					})
			}, 0)
		});
	},
	LOGOUT: ({commit}) => {
		return new Promise((resolve, reject) => {
			commit('LOGOUT');
			resolve()
		})
	}
};

const mutations = {
	AUTH_ERROR: (state) => {
		localStorage.removeItem('token');
		localStorage.removeItem('userMeta');
	},
	LOGOUT: (state) => {
		state.token = '';
		state.wsToken = '';
		state.user = '';

		localStorage.removeItem('token');
		localStorage.removeItem('wsToken');
		localStorage.removeItem('userMeta');

		axios.defaults.headers.common['Authorization'] = '';
	},
	SET_TOKEN: (state, token) => {
		state.token = token;
		localStorage.setItem('token', token);
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
	},
	SET_WS_TOKEN: (state, data) => {
		state.wsToken = data.token;
		localStorage.setItem('wsToken', data.token);
	},
	SET_USER: (state, user) => {
		state.user = JSON.stringify(user);
		localStorage.setItem('userMeta', state.user);
	}
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
