const state = () => ({
	filters: sessionStorage.getItem('filters') || ''
});

const getters = {
	activeFilters: state => (type) => {
		const filters = state.filters ? JSON.parse(state.filters) : {};

		return filters.hasOwnProperty(type) ? filters[type] : {};
	}
};

const actions = {};

const mutations = {
	SET_FILTERS: (state, data) => {
		const filters = state.filters ? JSON.parse(state.filters) : {};
		filters[data.type] = {[data.key]: data.value};
		state.filters = JSON.stringify(filters);
		sessionStorage.setItem('filters', state.filters);
	},
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
