<template>
    <div class="user__page">
        <div class="user__blocks">
            <form @submit.prevent="saveProfileData" class="user__block">
                <div class="user__block-head">Settings</div>
                <div class="user__block-form">
                    <div class="user__block-form_group">
                        <label for="role">Role</label>
                        <multiselect placeholder="Select roles" :searchable="false" id="role" v-model="form.roles" :options="rolesList" :multiple="true"></multiselect>
                    </div>
                    <div class="user__block-form_group">
                        <label for="name">Name</label>
                        <div class="input">
                            <input v-model="form.name" ref="name" disabled type="text" id="name" placeholder="Name">
                            <div @click="$refs.name.removeAttribute('disabled'), $refs.name.focus(), $event.target.style.display = 'none'" class="input__edit">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6101 3.89225C14.13 3.37238 14.13 2.52415 13.6101 2.03163L11.9684 0.389909C11.4758 -0.12997 10.6276 -0.12997 10.1077 0.389909L8.84909 1.64856C8.73964 1.75801 8.73964 1.97691 8.84909 2.11372L11.8863 5.15091C12.0231 5.26036 12.242 5.26036 12.3514 5.15091L13.6101 3.89225ZM7.78197 2.71569L0.585746 9.91191L0.011143 13.2227C-0.0709432 13.6879 0.312126 14.0709 0.777281 13.9889L4.08809 13.4143L11.2843 6.21803C11.4211 6.10858 11.4211 5.88969 11.2843 5.75287L8.24713 2.71569C8.11032 2.60624 7.89142 2.60624 7.78197 2.71569ZM3.40404 9.28258C3.23987 9.14577 3.23987 8.89951 3.40404 8.7627L7.6178 4.54894C7.75461 4.38477 8.00087 4.38477 8.13768 4.54894C8.30185 4.68575 8.30185 4.93201 8.13768 5.06882L3.92392 9.28258C3.78711 9.44675 3.54085 9.44675 3.40404 9.28258ZM2.41901 11.581H3.73238V12.5934L1.95385 12.8944L1.10563 12.0462L1.40661 10.2676H2.41901V11.581Z" fill="#878787"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="user__block-form_group balances">
                        <label for="nickname">Balances</label>
                        <div class="input" v-for="balance in wallets" :key="balance._id">
                            <label for="">{{ balance.currency }}</label>
                            <input disabled type="text" :id="`balance_${balance._id}`" :value="parseFloat(balance.value).toFixed(2)" placeholder="">
                        </div>
                    </div>
                    <div class="user__block-form_group">
                        <label for="nickname">Nickname</label>
                        <div class="input">
                            <input placeholder="Nickname" value="Vitaliy" v-model="form.nickname" ref="nickname" disabled type="text" id="nickname">
                            <div @click="$refs.nickname.removeAttribute('disabled'), $refs.nickname.focus(), $event.target.style.display = 'none'" class="input__edit">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6101 3.89225C14.13 3.37238 14.13 2.52415 13.6101 2.03163L11.9684 0.389909C11.4758 -0.12997 10.6276 -0.12997 10.1077 0.389909L8.84909 1.64856C8.73964 1.75801 8.73964 1.97691 8.84909 2.11372L11.8863 5.15091C12.0231 5.26036 12.242 5.26036 12.3514 5.15091L13.6101 3.89225ZM7.78197 2.71569L0.585746 9.91191L0.011143 13.2227C-0.0709432 13.6879 0.312126 14.0709 0.777281 13.9889L4.08809 13.4143L11.2843 6.21803C11.4211 6.10858 11.4211 5.88969 11.2843 5.75287L8.24713 2.71569C8.11032 2.60624 7.89142 2.60624 7.78197 2.71569ZM3.40404 9.28258C3.23987 9.14577 3.23987 8.89951 3.40404 8.7627L7.6178 4.54894C7.75461 4.38477 8.00087 4.38477 8.13768 4.54894C8.30185 4.68575 8.30185 4.93201 8.13768 5.06882L3.92392 9.28258C3.78711 9.44675 3.54085 9.44675 3.40404 9.28258ZM2.41901 11.581H3.73238V12.5934L1.95385 12.8944L1.10563 12.0462L1.40661 10.2676H2.41901V11.581Z" fill="#878787"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="user__block-form_group">
                        <label for="nickname">Email</label>
                        <div class="input">
                            <input v-model="form.email" ref="email" disabled type="email" id="email">
                            <div @click="$refs.email.removeAttribute('disabled'), $refs.email.focus(), $event.target.style.display = 'none'" class="input__edit">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6101 3.89225C14.13 3.37238 14.13 2.52415 13.6101 2.03163L11.9684 0.389909C11.4758 -0.12997 10.6276 -0.12997 10.1077 0.389909L8.84909 1.64856C8.73964 1.75801 8.73964 1.97691 8.84909 2.11372L11.8863 5.15091C12.0231 5.26036 12.242 5.26036 12.3514 5.15091L13.6101 3.89225ZM7.78197 2.71569L0.585746 9.91191L0.011143 13.2227C-0.0709432 13.6879 0.312126 14.0709 0.777281 13.9889L4.08809 13.4143L11.2843 6.21803C11.4211 6.10858 11.4211 5.88969 11.2843 5.75287L8.24713 2.71569C8.11032 2.60624 7.89142 2.60624 7.78197 2.71569ZM3.40404 9.28258C3.23987 9.14577 3.23987 8.89951 3.40404 8.7627L7.6178 4.54894C7.75461 4.38477 8.00087 4.38477 8.13768 4.54894C8.30185 4.68575 8.30185 4.93201 8.13768 5.06882L3.92392 9.28258C3.78711 9.44675 3.54085 9.44675 3.40404 9.28258ZM2.41901 11.581H3.73238V12.5934L1.95385 12.8944L1.10563 12.0462L1.40661 10.2676H2.41901V11.581Z" fill="#878787"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="user__block-form_group">
                        <label for="nickname">Birthday</label>
                        <div class="input">
                            <input value="" ref="birthday" v-model="form.birthday" disabled type="date" id="birthday">
                            <div @click="$refs.birthday.removeAttribute('disabled'), $refs.birthday.focus(), $event.target.style.display = 'none'" class="input__edit">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6101 3.89225C14.13 3.37238 14.13 2.52415 13.6101 2.03163L11.9684 0.389909C11.4758 -0.12997 10.6276 -0.12997 10.1077 0.389909L8.84909 1.64856C8.73964 1.75801 8.73964 1.97691 8.84909 2.11372L11.8863 5.15091C12.0231 5.26036 12.242 5.26036 12.3514 5.15091L13.6101 3.89225ZM7.78197 2.71569L0.585746 9.91191L0.011143 13.2227C-0.0709432 13.6879 0.312126 14.0709 0.777281 13.9889L4.08809 13.4143L11.2843 6.21803C11.4211 6.10858 11.4211 5.88969 11.2843 5.75287L8.24713 2.71569C8.11032 2.60624 7.89142 2.60624 7.78197 2.71569ZM3.40404 9.28258C3.23987 9.14577 3.23987 8.89951 3.40404 8.7627L7.6178 4.54894C7.75461 4.38477 8.00087 4.38477 8.13768 4.54894C8.30185 4.68575 8.30185 4.93201 8.13768 5.06882L3.92392 9.28258C3.78711 9.44675 3.54085 9.44675 3.40404 9.28258ZM2.41901 11.581H3.73238V12.5934L1.95385 12.8944L1.10563 12.0462L1.40661 10.2676H2.41901V11.581Z" fill="#878787"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="user__block-form_group">
                        <label for="nickname">Country</label>
                        <multiselect placeholder="Select country" track-by="id" :required="true" :label="'value'" :searchable="true" id="role" @search-change="getCountries" v-model="form.country" :options="countries" :multiple="false"></multiselect>

                    </div>
                    <div class="user__block-form_group">
                        <label for="">Game profile</label>
                    </div>

                </div>
                <div class="user__block-profiles">
                    <div v-for="profile in profiles">
                        <img :src="profile.game.icon" alt="">
                    </div>
                </div>
                <div class="buttons">
                    <button class="button save__button button-secondary" type="button" @click="getProfile">back</button>
                    <button class="button save__button button-primary" type="submit">Save</button>
                </div>
            </form>
            <div  v-if="form.roles.length > 0" class="user__block">
                <div class="user__block-head">Manager permissions</div>
                <div class="user__block-games">
                    <div class="user__block-game" v-for="game in games" :key="game.id">
                        <input :checked="assignGames.includes(game.id)" @change="updateAssignGames($event, game.id)" type="checkbox" :id="`game_${game.id}`">
                        <label :for="`game_${game.id}`">
                            <img :src="game.icon" alt="">
                            <span class="checked__block">
                                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.73047 10.7812C6.00391 11.0547 6.46875 11.0547 6.74219 10.7812L14.7812 2.74219C15.0547 2.46875 15.0547 2.00391 14.7812 1.73047L13.7969 0.746094C13.5234 0.472656 13.0859 0.472656 12.8125 0.746094L6.25 7.30859L3.16016 4.24609C2.88672 3.97266 2.44922 3.97266 2.17578 4.24609L1.19141 5.23047C0.917969 5.50391 0.917969 5.96875 1.19141 6.24219L5.73047 10.7812Z" fill="black"/>
                                </svg>
                            </span>
                        </label>
                    </div>
                </div>
                <div class="user__block-permissions">
                    <template v-for="permission in permissions.manager">
                        <input :checked="permissionsAssign.includes(permission.name)" @change="updatePermissionUser($event, permission.id)" type="checkbox" :id="`permission_${permission.id}`">
                        <label :for="`permission_${permission.id}`" class="user__block-permission">
                        <span class="user__block-permission_name">
                            {{ permission.name }}
                        </span>
                            <span class="user__block-permission_indicator"></span>
                        </label>
                    </template>

                </div>
            </div>
            <div class="user__block" v-if="form.roles.includes(SUPER_ADMIN) || form.roles.includes(TOURNAMENT_ADMIN)">
                <div class="user__block-head">Admin permissions</div>
                <div class="user__block-permissions">
                    <template v-for="permission in permissions.admin">
                        <input :checked="permissionsAssign.includes(permission.name)" @change="updatePermissionUser($event, permission.id)" type="checkbox" :id="`permission_${permission.id}`">
                        <label :for="`permission_${permission.id}`" class="user__block-permission">
                        <span class="user__block-permission_name">
                            {{ permission.name }}
                        </span>
                            <span class="user__block-permission_indicator"></span>
                        </label>
                    </template>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import CountriesService from '../../api/countries'
import UsersService from '../../api/users'
import TournamentService from '../../api/tournament'
import PermissionsService from '../../api/permissions'
import { mapGetters } from 'vuex'
import { SUPER_ADMIN, TOURNAMENT_ADMIN, TOURNAMENT_MANAGER } from "../../constants/roles";
export default {
    data () {
        return {
            SUPER_ADMIN,
            TOURNAMENT_ADMIN,
            TOURNAMENT_MANAGER,
            countries: [],
            form: {
                country: null,
                roles: [],
                name: '',
                nickname: '',
                email: null,
                birthday: new Date(),
            },
            wallets: [],
            profiles: [],
            games: [],
            permissions: {
                'manager': [],
                'admin': []
            },
            assignGames: [],
            permissionsAssign: []
        }
    },
    computed: {
      ...mapGetters({
          user: 'getUser'
      }),
      rolesList () {
          const list = []
          if (this.hasRole(SUPER_ADMIN)) {
              list.push(SUPER_ADMIN);
              list.push(TOURNAMENT_ADMIN);
          }
          list.push(TOURNAMENT_MANAGER)
          return list;
      }
    },
    props: {
        id: {
            required: true,
            type: Number
        }
    },
    methods: {
        saveProfileData () {
            const data = {...this.form}
            data.country = data.country.id
            UsersService.update(this.id, data).then(response => {
                if (response.status !== 200) {
                    this.$notify({
                        group: 'notify',
                        type: 'error',
                        text: response.response.data.message ? response.response.data.message : 'Error server',
                        title: "Error edit data"
                    })
                } else {
                    this.$notify({
                        group: 'notify',
                        type: 'success',
                        text: 'Status',
                        title: "Success update data"
                    })
                }
            })
        },
        updatePermissionUser ($event, permission_id) {
            const isActive = $event.target.checked
            let handler
            if (isActive) {
                handler = PermissionsService.add({
                    user_id: Number(this.id),
                    permission_id
                })
            } else {
                handler = PermissionsService.delete({
                    user_id: Number(this.id),
                    permission_id
                })
            }
            handler.then(data => {
                if (data.response.status !== 200) {
                    this.$notify({
                        type: 'error',
                        group: 'notify',
                        title: 'Error',
                        text: data.response.data.message ? data.response.data.message : 'Unknown error'
                    })
                    $event.target.checked = false
                }
            })
        },
        updateAssignGames ($event, game_id) {
            const isActive = $event.target.checked
            let handler
            if (isActive) {
                handler = UsersService.addAssignGames({
                    game_id,
                    user_id: this.id
                })
            } else {
                handler = UsersService.removeAssignGames({
                    game_id,
                    user_id: this.id
                })
            }
            handler.then(data => {
                if (data.response.status !== 200) {
                    this.$notify({
                        type: 'error',
                        group: 'notify',
                        title: 'Error',
                        text: data.response.data.message ? data.response.data.message : 'Unknown error'
                    })
                    $event.target.checked = false
                }
            })
        },
        getProfile () {
            UsersService.getUser(this.id).then(response => {
                this.initialStartUserData(response.data)
            })
        },
        async initialStartUserData (user) {
          await CountriesService.getCountry(user.country_id).then(response => {
              this.form.country = response.data.data
          })
          this.form.name = user.name
          this.form.nickname = user.nickname
          this.form.email = user.email
          this.form.birthday = user.birthday
          this.form.roles = user.roles
          this.wallets = user.wallets
          this.permissionsAssign = user.permissions
        },
        getCountries (search = '') {
            CountriesService.get({
                limit: 20,
                search: search
            }).then(response => {
                this.countries = response.data.data;
            })
        },
        getProfiles () {
            UsersService.getProfiles(this.id).then(response => {
                this.profiles = response.data.data
            })
        },
        getGames () {
            TournamentService.getGames().then(response => {
                this.games = response.data
            })
        },
        getPermissions (group) {
            PermissionsService.get({
                group: group
            }).then(response => {
                this.permissions[group] = response.data
            })
        },
        getAssignGames () {
            UsersService.getAssignGames(this.id).then(response => {
                const assignGames = response.data
                this.assignGames = assignGames.map(item => item.game_id)
            })
        }
    },
    mounted () {
      this.getProfile()
      this.getProfiles()
      this.getCountries()
      this.getGames()
      this.getAssignGames()
      this.getPermissions('manager')
      this.getPermissions('admin')
    },
    components: {
        Multiselect
    }
}
</script>
<style scoped lang="scss">
    .user__page {
        min-height: 100vh;
        margin-top: 15px;
        .user__blocks {
            margin-top: 25px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
            .user__block {
                .buttons {
                    display: grid;
                    grid-gap: 20px;
                    grid-template-columns: repeat(2, 1fr);
                }
                .save__button {
                    width: 100%;
                    margin-top: 10px;
                }
                .user__block-head {
                    margin-bottom: 15px;
                    background: #ECECEC;
                    border-radius: 4px;
                    padding: 14px 15px;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 21px;
                    color: #303030;
                }
                .user__block-form {
                    .user__block-form_group {
                        label {
                            font-size: 14px;
                            line-height: 16px;
                            margin-bottom: 6px;
                            display: block;
                            color: #06C2BC;
                        }
                        .input {
                            align-items: center;
                            position: relative;
                            width: 100%;
                            input {
                                background: transparent;
                                border: 1px solid #D6D6D6;
                                outline: none;
                                border-radius: 4px;
                                width: 100%;
                                padding: 12px 15px;
                                &:focus {
                                    border: 1px solid #00E7DF
                                }
                            }
                            .input__edit {
                                position: absolute;
                                right: 13px;
                                top: calc(50% - 10px);
                                cursor: pointer;
                            }
                        }
                        &.balances {
                            padding: 15px;
                            background: gray;
                            border: 6px solid #06c2af;
                            background: #f3f3f3;
                            input {
                                font-size: 16px;
                            }
                            .input + .input {
                                margin-top: 15px;

                            }
                            label {
                                font-weight: bold;
                                font-size: 16px;
                                text-transform: uppercase;
                                margin-bottom: 15px;
                            }
                        }
                    }
                    .user__block-form_group + .user__block-form_group {
                        margin-top: 15px;
                    }

                }
                .user__block-profiles {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-gap: 10px;
                }
                .user__block-games {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-gap: 10px;
                    .user__block-game {
                        input {
                            display: none;

                        }
                        label {
                            cursor: pointer;
                            display: flex;
                            border: 2px solid transparent;
                            border-radius: 2px;
                            overflow: hidden;
                            position: relative;
                            img {
                                border-radius: 2px;
                                width: 100%;
                            }
                            .checked__block {
                                opacity: 0;
                                width: 24px;
                                height: 24px;
                                background: #06C2BC;
                                border-radius: 2px;
                                position: absolute;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                top: 0;
                                right: 0;
                            }
                        }
                        input:checked + label {
                            border: 2px solid #06C2BC;
                            .checked__block {
                                opacity: 1;
                            }
                        }
                    }
                }
                .add_new_game {
                    width: 100%;
                    margin-top: 15px;
                }
                .user__block-permissions {
                    margin-top: 15px;
                    input {
                        display: none;
                    }
                    .user__block-permission {
                        background: #FAFAFA;
                        cursor: pointer;
                        border: 1px solid #878787;
                        transition: 0.2s;
                        display: flex;
                        align-items: center;
                        border-radius: 4px;
                        justify-content: space-between;
                        padding: 15px;
                        margin-bottom: 15px;
                        .user__block-permission_name {
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 16px;
                            color: #878787;
                        }
                        .user__block-permission_indicator {
                            width: 36px;
                            height: 20px;
                            background: #C4C4C4;
                            border-radius: 20px;
                            display: flex;
                            position: relative;
                            align-items: center;
                            &::after {
                                content: "";
                                display: block;
                                width: 16px;
                                border-radius: 100%;
                                height: 16px;
                                transition: 0.2s;
                                background: #878787;
                                position: absolute;
                                left: 2px;
                            }
                        }
                    }
                    input:checked {
                        & + .user__block-permission {
                            box-shadow: 0px 0px 4px rgba(0, 80, 77, 0.1), 0px 2px 10px rgba(0, 80, 77, 0.25);
                            border: 1px solid #06C2BC;
                            .user__block-permission_name {
                                color: #06C2BC;
                            }
                            .user__block-permission_indicator {
                                background: #00E7DF;
                                &::after {
                                    background: #06C2BC;
                                    transform: translateX(15px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1100px) {
        .user__page .user__blocks {
            grid-template-columns: repeat(2, 1fr);
            .user__block:last-child {
                grid-column: 3 / 1;
            }
        }
    }
    @media screen and (max-width: 575px) {
        .user__page .user__blocks {
            grid-template-columns: repeat(1, 1fr);
            .user__block:last-child {
                grid-column: unset;
            }
        }
    }
</style>
