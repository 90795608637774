import { render, staticRenderFns } from "./SeedPlayer.vue?vue&type=template&id=ed2444b6&scoped=true"
import script from "./SeedPlayer.vue?vue&type=script&lang=js"
export * from "./SeedPlayer.vue?vue&type=script&lang=js"
import style0 from "./SeedPlayer.vue?vue&type=style&index=0&id=ed2444b6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed2444b6",
  null
  
)

export default component.exports