export default function Match( data = null) {
	const info = data;

	this.getMatchID = function () {
		return info.id;
	};

	this.getMatchStatus = function () {
		return info.match_status ? info.match_status : info.status;
	};

	this.getPlayerIndex = function () {
		return info.player;
	};

	this.getRound = function () {
		return info.round;
	};

	this.getFirstParticipant = function () {
		return info.first_participant;
	};

	this.getSecondParticipant = function () {
		return info.second_participant;
	};

	this.exists = function () {
		return info !== null;
	};
}
