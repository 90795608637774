import http from '../utils/http'
import joinUrls from '../utils/join-urls'

export default {
	get(data = {}) {
		return http.get('/news', data)
	},
	getItem(id) {
		return http.get(joinUrls('/news', id))
	},
	add(data) {
		return http.postMultipart('/news', data)
	},
	update(id, data) {
		return http.putMultipart(joinUrls('/news', id), data)
	},
	delete(id) {
		return http.delete(joinUrls('/news', id))
	}
}
