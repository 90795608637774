<template>
    <div class="rooms-matches-area">
        <div class="page__content-top">

            <div class="page-content__actions">
                <div class="room__instance-header_started_at" v-if="selectRoom && selectRoom.started_at && tournament.getStatus() === 'REGISTRATION_CLOSED'">
                    <div class="date__time">
                        Started at: {{ getDate(selectRoom.started_at) }}
                    </div>
                </div>
                <FormMultiSelect
                    :placeholder="$t('Select room')"
                    :clear="false"
                    :options="tournament.getRR().rooms"
                    :searchable="false"
                    v-model="selectRoom"
                    @input="key++, updateRoom()"
                    label="name"
                    :selectLabel="'Select room'">
                </FormMultiSelect>
                <button :disabled="!tournament.getRR().complete" @click="nextStage" class="start__tournament">
                    Start bracket
                </button>
            </div>

        </div>
        <div class="page__content-body">
            <div class="please_select_room" v-if="!selectRoom">
                Please, select room
            </div>

            <RoomMatches :key="key" @updateRoom="updateRoom" :room="selectRoom" v-if="selectRoom"></RoomMatches>
        </div>

    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import FormMultiSelect from 'vue-multiselect'
import RoomMatches from './Room/RoomMatches'
import RoundRobinService from '../../../api/round-robin'
import moment from "moment";

export default {
    data () {
        return {
            key: 0,
            selectRoom: null
        }
    },
    computed: {
        ...mapGetters({
            tournament: 'tournament'
        })
    },
    methods: {
        ...mapActions({
            getTournament: 'GET_TOURNAMENT'
        }),
        getDate (d) {
            return moment(d).format('DD/MM HH:mm')
        },
        setDefaultRoom () {
            if (this.tournament.getRR().rooms.length > 0) {
                this.selectRoom = this.tournament.getRR().rooms[0];
            }
        },
        nextStage () {
            RoundRobinService.startBracketsStage(this.tournament.getId()).then(data => {
                if (data.response && data.response && data.response.status !== 200) {
                    this.$notify({
                        type: 'error',
                        title: 'Generated error',
                        group: 'notify',
                        text: data.response.data.message ? data.response.data.message : 'Unknown error'
                    })
                } else {
                    this.getTournament(this.tournament.getId())
                }
            })
        },
        updateRoom () {
            RoundRobinService.getRoom(this.tournament.getId(), this.selectRoom.id).then(data => {
                this.$set(this, 'selectRoom', data.data.data);
            })
        }
    },
    mounted () {
      this.setDefaultRoom()
    },
    components: {
        RoomMatches,
        FormMultiSelect
    }
}
</script>
<style scoped lang="scss">
    .rooms-matches-area {
        min-height: 100vh;
        .page__content-top {
            display: flex;
            justify-content: flex-end;
            .room__instance-header_started_at {
                margin-left: auto;
                .date__time {
                    padding: 10px 11px;
                    background: #D6D6D6;
                    border-radius: 4px;
                }
            }
            .page-content__actions {
                margin-left: auto;
                &::v-deep {
                    .multiselect {
                        cursor: pointer;
                        .multiselect__single {
                            margin-bottom: 0;
                            padding-left: 0;
                        }
                        width: 250px;
                        .multiselect__tags {

                            input {
                                padding-left: 0 !important;
                                padding-bottom: 0 !important;
                                margin-bottom: 0 !important;
                                font-size: 14px !important;
                            }
                            background: #ECECEC;
                            * {
                                background: #ECECEC;
                            }
                        }

                        .multiselect__placeholder {
                            margin: 0;
                            padding-left: 0;
                            color: #000;
                            font-weight: bold;
                        }
                        .multiselect__content-wrapper {

                            &::-webkit-scrollbar {
                                background-color: #fff;
                                width: 4px;
                            }

                            &::-webkit-scrollbar-track {
                                background-color: #fff;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: #06C2BC;
                                border-radius: 16px;
                            }
                        }
                    }
                }
                .start__tournament {
                    margin-left: 15px;
                    background: #00E7DF;
                    border-radius: 5px;
                    cursor: pointer;
                    &[disabled] {
                        opacity: 0.7;
                        cursor: not-allowed;
                        background: #ECECEC;
                    }
                    padding: 9px 13px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: 0.16px;
                    text-transform: uppercase;
                    color: #303030;
                    outline: none;
                    border: none;
                    font-family: "Roboto";
                    font-weight: bold;
                    transition: 0.2s;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
        .page__content-body {
            padding: 20px 0;
            .please_select_room {
                padding: 50px 0;
                display: flex;
                justify-content: center;
                font-size: 14px;
                align-items: center;
                border: 1px dashed gray;
                font-style: italic;
            }
        }
    }
</style>
