<template>
	<div v-if="tournament">
		<div class="page__content-header">
			<div>
				<PageLinks :links="[
					{
						url: '/tournament/' +  tournament.getId(),
						text: tournament.getTitle()
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/brackets',
						text: 2 === tournament.getType() || 5 === tournament.getType() ? $t('Leaderboard') : $t('Brackets')
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/participants',
						text: $t('Participants')
					},
					{
						url: '/tournaments/' +  tournament.getId() + '/participants/logs',
						text: $t('Logs')
					}
				]"/>
			</div>
			<div>
				<div class="participants-count">
					{{$t('Total:')}} {{tournament.getCheckinParticipantsCount()}}/{{ tournament.getRegisteredParticipantsCount() }}/{{tournament.getMaxMembers()}}
				</div>
				<button
					class="button button-primary"
					:class="{disabled : participants && !participants.length}"
					@click.prevent="exportCsv"
				>{{$t('Players’ contacts')}}
				</button>
			</div>
		</div>
        <div class="search__wrapper">
            <label for="">Search:</label>
            <input type="search" placeholder="Search by nickname, name, email" @input="goSearch" v-model="search">
        </div>
		<div class="table__wrapper">
			    <table v-if="participants.length">
				<thead class="text-left">
				<tr>
					<th scope="col">#</th>
					<th scope="col">{{$t('ID')}}</th>
					<th scope="col">{{$t('Name')}}</th>
					<th scope="col" v-if="participants[0].isType('single')">{{$t('Nick name')}}</th>
					<th scope="col" v-if="participants[0].isType('single')">{{$t('Email')}}</th>
					<th scope="col" v-if="participants[0].isType('single')">{{$t('Game ID')}}</th>
					<th scope="col" v-if="participants[0].isType('team')">{{$t('Max Players')}}</th>
					<th scope="col" v-if="participants[0].isType('team')">{{$t('Team Mates Count')}}</th>
					<th scope="col">{{$t('Status')}}</th>
				</tr>
				</thead>
				<tbody>
				<tr :style="{'cursor': (participant.isType('team') ? 'pointer': 'default')}" @click="participant.isType('team') && participant.getTeamId() ? $router.push({ name: 'team', params: { id: participant.getTeamId() } }) : ''" v-for="(participant, index) in participants">
                    <td scope="row">{{index + 1}}</td>
					<td scope="row" class="row_id">{{participant.getId()}}</td>
					<td class="row row--with-avatar">
						<img :src="participant.getUserAvatar()" alt="">
						<span>{{participant.getName()}}</span>
					</td>
					<td class="row" v-if="participant.isType('single')">{{participant.getUserNickName()}}</td>
					<td class="row row_email" v-if="participant.isType('single')">{{participant.getUserEmail()}}</td>
					<td class="row" v-if="participant.isType('single')">{{participant.getPlatformId()}}</td>
					<td class="row" v-if="participant.isType('team')">{{participant.getTeamMatesCount()}}</td>
					<td class="row" v-if="participant.isType('team')">{{participant.getMaxPlayers()}}</td>
					<td class="row row_status">{{participant.getStatus()}}</td>
					<td class="row">
						<div class="icon-tools">
                        <a target="_blank" v-if="participant.isType('single')" :href="`/chats?start=${participant.getUserId()}`" class="send_message">
                            <svg width="18" height="14" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7266 3.30729C13.0977 3.77604 12.3047 4.34896 9.51562 6.27604C8.96875 6.66667 7.95703 7.52604 7 7.52604C6.01562 7.52604 5.03125 6.66667 4.45703 6.27604C1.66797 4.34896 0.875 3.77604 0.246094 3.30729C0.136719 3.22917 0 3.30729 0 3.4375V8.75C0 9.45313 0.574219 10 1.3125 10H12.6875C13.3984 10 14 9.45313 14 8.75V3.4375C14 3.30729 13.8359 3.22917 13.7266 3.30729ZM7 6.66667C7.62891 6.69271 8.53125 5.91146 8.99609 5.59896C12.6328 3.09896 12.9062 2.86458 13.7266 2.23958C13.8906 2.13542 14 1.95313 14 1.74479V1.25C14 0.572917 13.3984 0 12.6875 0H1.3125C0.574219 0 0 0.572917 0 1.25V1.74479C0 1.95313 0.0820312 2.13542 0.246094 2.23958C1.06641 2.86458 1.33984 3.09896 4.97656 5.59896C5.44141 5.91146 6.34375 6.69271 7 6.66667Z" fill="#8F8F8F"/>
                            </svg>
                        </a>
						<!-- <a href="#" v-if="participant.isType('single')" :href="`/chats?start=${participant.getUserId()}`" class="send_message">
                            <svg width="18" height="14" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7266 3.30729C13.0977 3.77604 12.3047 4.34896 9.51562 6.27604C8.96875 6.66667 7.95703 7.52604 7 7.52604C6.01562 7.52604 5.03125 6.66667 4.45703 6.27604C1.66797 4.34896 0.875 3.77604 0.246094 3.30729C0.136719 3.22917 0 3.30729 0 3.4375V8.75C0 9.45313 0.574219 10 1.3125 10H12.6875C13.3984 10 14 9.45313 14 8.75V3.4375C14 3.30729 13.8359 3.22917 13.7266 3.30729ZM7 6.66667C7.62891 6.69271 8.53125 5.91146 8.99609 5.59896C12.6328 3.09896 12.9062 2.86458 13.7266 2.23958C13.8906 2.13542 14 1.95313 14 1.74479V1.25C14 0.572917 13.3984 0 12.6875 0H1.3125C0.574219 0 0 0.572917 0 1.25V1.74479C0 1.95313 0.0820312 2.13542 0.246094 2.23958C1.06641 2.86458 1.33984 3.09896 4.97656 5.59896C5.44141 5.91146 6.34375 6.69271 7 6.66667Z" fill="#8F8F8F"/>
                            </svg>
                        </a> -->
						<a href="#" v-if="userCanDeleteParticipants(participant)" @click.prevent.stop="deleteParticipant(participant)">
							<SvgIcon
								width="16"
								height="16"
								:iconType="'delete'"
							/>
						</a>
						<a href="#" v-if="participant.getStatus() === 'registered'" @click.prevent.stop="bypassCheck(participant)" title="Bypass Checkin">
							<SvgIcon
								width="24"
								height="24"
								:iconType="'bypass'"
							/>
						</a>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
            <observer v-if="!blockLoad" @on-change="loadMore" class="test-lazy"></observer>
		</div>
		<ChatsListContainer :id="id"/>
		<DeleteParticipantDialog/>
	</div>
</template>

<script>
    import Observer from 'vue-intersection-observer'
	import {mapGetters, mapActions} from 'vuex'
	import {DELETE_PARTICIPANT_DIALOG} from '../../constants/dialogs'

	import {ExportToCsv} from 'export-to-csv'
	import PageLinks from '../../components/Page/Links'
	import SvgIcon from '../../components/SvgIcon'
	import Dropdown from '../../components/Dropdown'
	import ChatsListContainer from '../../components/Chat/ChatsListContainer'
	import DeleteParticipantDialog from '../../components/Dialogs/DeleteParticipant'
    import { SUPER_ADMIN, TOURNAMENT_ADMIN, TOURNAMENT_MANAGER } from "../../constants/roles";
    import _ from 'lodash'
	export default {
		props: ["id"],
		components: {
			SvgIcon,
			Dropdown,
            Observer,
			PageLinks,
			ChatsListContainer,
			DeleteParticipantDialog
		},
		data() {
			return {
                currentPage: 1,
                blockLoad: false,
                stopObserver: false,
                limit: 10,
                search: '',
				csvOptions: {
					filename: 'participants',
					decimalSeparator: '.',
					fieldSeparator: ',',
					quoteStrings: '"',
					showLabels: true,
					showTitle: true,
					title: 'Participants',
					useTextFile: false,
					useBom: true,
					useKeysAsHeaders: true,
				}
			}
		},
		computed: {
			...mapGetters([
				'tournament',
				'tournamentStatus',
				'participants',
				'participantsEmails',
				'getUser'
			])
		},
		created() {
			this.initData();
		},
		methods: {
			...mapActions({
				showDialog: 'showDialog',
				getTournament: 'GET_TOURNAMENT',
				getParticipants: 'GET_PARTICIPANTS',
				getParticipantsEmails: 'GET_PARTICIPANTS_EMAILS',
				centrifugeConnect: 'centrifugeConnect',
			}),
            async loadMore () {
                this.currentPage++
                await this.fetchParticipants(true)

                if ((this.limit * this.currentPage) > this.participants.length) {
                    this.$nextTick(() => {
                         this.blockLoad = true
                    })
                }
            },
            goSearch: _.debounce(function () {
                this.currentPage = 1
                this.fetchParticipants()
            }, 500),
            async fetchParticipants (push = false) {
                await this.getParticipants({ id: this.id, params: {search: this.search, limit: this.limit, page: this.currentPage }, push })
                this.blockLoad = false
            },
			async initData() {
				await this.fetchParticipants();
				await this.getTournament(this.id);
				await this.centrifugeConnect();
			},
			async exportCsv() {
				if (Object.keys(this.participants).length === 0) return;

				await this.getParticipantsEmails(this.id);

				const csvExporter = new ExportToCsv(this.csvOptions);

				csvExporter.generateCsv(this.participantsEmails);
			},
			getActiveParticipants() {
				const active = this.participants.filter(item => item.getStatus() === 'active' || item.getStatus() !== 'registered');
				return active.length;
			},
			deleteParticipant(participant) {
				this.showDialog({
					type: DELETE_PARTICIPANT_DIALOG,
					participant: participant
				});
			},
			bypassCheck(participant) {

				confirm('Are you sure you want to bypass check-in validation?') && this.$store.dispatch('BYPASS_PARTICIPANT_CHECK', {tournament: this.tournament.getId(), participant_id: participant.getId()})
			},
			userCanDeleteParticipants(participant) {
				const user = this.getUser;
				const status = this.tournamentStatus;
				const type = this.tournament.getType();
				const userRegistered = 'registered' === participant.getStatus();


                const allowedStatuses = [
                    'OPENED',
                    'REGISTRATION_OPENED',
                    'CHECKIN',
                    'REGISTRATION_CLOSED',
                ];

                if (userRegistered && !allowedStatuses.includes(status)) {
                    return true;
                }


				if (type === 2 && status !== 'FINISHED') return this.hasPermission("Tournament edit", this.id)
				if (type === 1 && !allowedStatuses.includes(status)) return false;

				if (type === 2 && status === 'FINISHED') return false;
				return this.hasPermission("Tournament edit", this.id)
			}
		},
	}
</script>
<style scoped lang="scss">
table .row {
    word-break: break-all;
}
.send_message {
    margin: 0 15px;
}
.search__wrapper {
    width: 90%;
    margin: 15px 0;
    label {
        display: block;
        width: 100%;
        color: #00E7DF;
        margin-bottom: 5px;
        font-size: 14px;
    }
    input {
        width: 100%;
        padding: 10px 15px;
        outline: none;
        border: 1px solid rgba(173,173,173, 0.4);
        border-radius: 5px;
        transition: 0.1s;
        &:focus {
            border: 1px solid #00E7DF;
        }
        &:focus-within {
            border: 1px solid #00E7DF;
        }
    }
}

@media screen and (max-width: 992px) {
    .search__wrapper {
        width: 100%;
        input {
            width: 100%;
        }
    }
}
@media screen and (max-width: 600px) {
    .table__wrapper {
        max-width: 100%;
        overflow-x: auto;
    }
    table {
        .row {
            min-width: 100px;
            &.row_email {
                min-width: 200px;
            }
            &.row_status {
                min-width: 100px;
            }
        }
        .row_id {
            min-width: 60px;
        }
    }
    table {

    }
}
.icon-tools {
	display: flex;
	align-items: center;
	a {
		margin: 0 5px;
	}
}
</style>
