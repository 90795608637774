<template>
    <div>
        <PageLinks :links="[
			{
				url: '/term/sadd',
				text: $t('Add Terms'),
				classes: 'active'
			}
		]"/>
        <form class="form form--xl">
            <div class="form-group">
                <FormInput
                    :name="'title'"
                    :label="$t('Title')"
                    :placeholder="$t('Name')"
                    :value="form.title"
                    :formErrors="errors.title"
                    :onChange="formFieldChanged"
                />
            </div>
            <div class="form-group">
                <div class="form-input-field">
                    <label>
                        <span class="form-label">{{$t('Content')}}</span>
                    </label>
                    <TextEditor
                        class="form-text-editor"
                        :placeholder="$t('Content')"
                        v-model="form.content"
                    />
                    <FormNotification :errors="errors.content"/>
                </div>
            </div>

            <div class="form-group">
                <span class="form-label">{{$t('Choose country')}}</span>
                <FormMultiSelect
                    v-model="form.country"
                    :placeholder="$t('Country')"
                    :options="getCountriesWithLocales"
                    :loading="multiSelectOptions.loading"
                    :label="multiSelectOptions.label"
                    :track-by="multiSelectOptions.trackBy"
                    :multiple="multiSelectOptions.multiple"
                    :taggable="multiSelectOptions.taggable"
                    @search-change="onSearchChange"
                />
                <FormNotification :errors="errors.country"/>
            </div>
            <button
                class="button button-primary form-button-submit"
                @click.prevent="saveTerm()"
            >
                {{$t('Save')}}
            </button>
        </form>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

import router from '../../routes'
import SvgIcon from '../../components/SvgIcon'
import PageLinks from '../../components/Page/Links'
import FormNotification from '../../components/Form/Notification'
import FormInput from '../../components/Form/Input'
import {VueEditor} from 'vue2-editor'
import FormMultiSelect from 'vue-multiselect'


export default {
    components: {
        SvgIcon,
        PageLinks,
        FormMultiSelect,
        FormNotification,
        FormInput,
        'TextEditor': VueEditor
    },
    computed: {
        ...mapGetters([
            'term',
            'countries'
        ]),
        getCountriesWithLocales() {
            return this.countries.filter(item => {
                return item.supported_locales.length > 0;
            })
        },
    },
    data() {
        return {
            multiSelectOptions: {
                loading: false,
                label: 'value',
                trackBy: 'value',
                multiple: false,
                taggable: true,
            },
            form: {
                title: '',
                content: '',
                country: null,
            },
            errors: {},
            defaultError: {
                message: [
                    this.$t('Something went wrong. Please try again.')
                ]
            }
        };
    },
    methods: {
        ...mapActions({
            getTerm: 'GET_TERM',
            addTerms: 'ADD_TERM',
            updateTerm: 'UPDATE_TERM',
            getCountries: 'getCountries',
        }),
        formFieldChanged(field, value) {
            this.form[field] = value;
        },
        async onSearchChange (value) {
            this.multiSelectOptions.loading = true;
            await this.getCountries({
                search: value
            });
            this.multiSelectOptions.loading = false;
        },
        validateTerm() {
            if(!this.form.title) {
                this.errorsHandler({title: 'Select title'});
                return false;
            }
            if(!this.form.content) {
                this.errorsHandler({title: 'Select content'});
                return false;
            }
            if(!this.form.country) {
                this.errorsHandler({country: 'Select country'});
                return false;
            }
            if(!this.form.country.id) {
                this.errorsHandler({country: 'Select country'});
                return false;
            }

            return true;
        },
        saveTerm() {
            if(this.validateTerm()) {
                this.newTerm();
            }
        },
        async newTerm() {
            const data = this.form;
            data.country_id = data.country.id;
            delete data['country'];
            await this.addTerms(data)
                .then(() => router.push('/terms'))
                .catch(err => this.errorsHandler(err.response.data.errors));
        },
        errorsHandler(error) {
            this.errors = error ? error : this.defaultError;
        },
    }
}
</script>
