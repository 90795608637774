<template>
	<div>
		<PageLinks :links="[
			{
				url: '/notifications/add',
				text: $t('Send Notification'),
				classes: 'active'
			}
		]"/>
		<form class="form form--xl">
			<div class="form-group" v-if="form.notification_type !== 'INBOX_MESSAGE'">

                    <FormSelect
                        :name="'location_type'"
                        :label="$t('Location type')"
                        :value="form.location_type"
                        :options="locationTypes"
                        :formErrors="errors.location_type"
                        :onChange="formFieldChanged"
                    />
			</div>

            <div class="form-group" v-if="form.location_type === 'country' && form.notification_type !== 'INBOX_MESSAGE'">

                <FormMultiSelect
                    v-model="form.countries"
                    :placeholder="$t('Choose country')"
                    :options="countries"
                    :loading="multiSelectCountryOptions.loading"
                    :label="multiSelectCountryOptions.label"
                    :track-by="multiSelectCountryOptions.trackBy"
                    :multiple="multiSelectCountryOptions.multiple"
                    :taggable="multiSelectCountryOptions.taggable"
                    @search-change="onSearchChangeCountry"
                />
            </div>
            <div class="form-group" v-if="form.location_type === 'region' && form.notification_type !== 'INBOX_MESSAGE'">

                <FormMultiSelect
                    v-model="form.regions"
                    :placeholder="$t('Choose regions')"
                    :options="regions"
                    :loading="multiSelectRegionsOptions.loading"
                    :label="multiSelectRegionsOptions.label"
                    :track-by="multiSelectRegionsOptions.trackBy"
                    :multiple="multiSelectRegionsOptions.multiple"
                    :taggable="multiSelectRegionsOptions.taggable"
                    @search-change="onSearchChangeRegions"
                />
            </div>

            <div class="form-group">
                <FormInput
                    :name="'title'"
                    :label="$t('Title')"
                    :placeholder="$t('Name')"
                    :value="form.title"
                    :formErrors="errors.title"
                    :onChange="formFieldChanged"
                />
            </div>
			<div class="form-group">
				<FormTextarea
					:name="'message'"
					:label="$t('Message')"
					:placeholder="$t('Message')"
					:maxLength="250"
					:value="form.message"
					:formErrors="errors.message"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group">
				<FormSelect
					:name="'notification_type'"
					:label="$t('Notification type')"
					:value="form.notification_type"
					:options="typesOptions"
					:formErrors="errors.notification_type"
					:onChange="formFieldChanged"
				/>
			</div>
            <div class="form-group" v-if="!form.notification_type">
                <FormSelect
                    :name="'notification_mode'"
                    :label="$t('Notification mode')"
                    :value="form.notification_mode"
                    :options="modesOptions"
                    :formErrors="errors.notification_mode"
                    :onChange="formFieldChanged"
                />
            </div>
			<div class="form-group" v-if="!form.notification_type && form.notification_mode !== '2'">
<!--				<FormSelect-->
<!--					:name="'tournament_id'"-->
<!--					:label="$t('Tournament')"-->
<!--					:value="form.tournament_id"-->
<!--					:options="tournamentsOptions"-->
<!--					:formErrors="errors.tournament_id"-->
<!--					:onChange="formFieldChanged"-->
<!--				/>-->
                <label for="" class="form-label">Tournament</label>
                <FormMultiSelect
                    v-model="form.tournament_id"
                    :placeholder="$t('Tournament')"
                    :options="tournamentsOptions"
                    :loading="false"
                    :label="'name'"
                    :track-by="'value'"
                    :multiple="false"
                    :taggable="false"
                    @search-change="formFieldChanged"
                />


			</div>
            <div class="form-group" v-else-if="!form.notification_type && form.notification_mode === '1'">

                <FormMultiSelect
                    v-model="form.game_id"
                    required
                    :placeholder="$t('Choose game')"
                    :options="games"
                    :loading="gamesOptions.loading"
                    :label="gamesOptions.label"
                    :track-by="gamesOptions.trackBy"
                    :multiple="gamesOptions.multiple"
                    :taggable="gamesOptions.taggable"
                />
            </div>
			<div class="form-group" v-if="form.notification_type">
				<FormSelect
					:name="'request_type'"
					:label="$t('Request type')"
					:value="form.request_type"
					:options="requestTypeOptions"
					:formErrors="errors.request_type"
					:onChange="formFieldChanged"
				/>
			</div>
			<div class="form-group" v-if="form.notification_type">
				<span class="form-label">{{$t('Choose users')}}</span>
				<FormMultiSelect
					v-model="form.notifiable_ids"
					:placeholder="$t('Choose users')"
					:options="notificationUsers"
					:loading="multiSelectOptions.loading"
					:label="multiSelectOptions.label"
					:track-by="multiSelectOptions.trackBy"
					:multiple="multiSelectOptions.multiple"
					:taggable="multiSelectOptions.taggable"
					@search-change="onSearchChange"
				/>
				<FormNotification :errors="errors.notifiable_ids"/>
			</div>
			<button
				class="button button-primary form-button-submit"
				@click.prevent="sendNotification()"
			>
				{{$t('Send')}}
			</button>
		</form>
		<NotificationSentDialog/>
	</div>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'
	import {NOTIFICATION_SENT_DIALOG} from '../../constants/dialogs'
    import NotificationsService from '../../api/notifications'
	import _ from 'lodash'

	import SvgIcon from '../../components/SvgIcon'
	import PageLinks from '../../components/Page/Links'
	import FormInput from '../../components/Form/Input'
	import FormTextarea from '../../components/Form/Textarea'
	import FormSelect from '../../components/Form/Select'
	import FormNotification from '../../components/Form/Notification'
	import NotificationSentDialog from '../../components/Dialogs/NotificationSent'
	import FormMultiSelect from 'vue-multiselect'

	export default {
		components: {
			SvgIcon,
			PageLinks,
			FormInput,
			FormTextarea,
			FormSelect,
			FormNotification,
			NotificationSentDialog,
			FormMultiSelect
		},
		computed: {
			...mapGetters({
                regions: 'regions',
				tournaments: 'activeTournaments',
				notificationUsers: 'notificationUsers',
                countries: 'countries',
                games: 'games'
			})
		},
		data() {
			return {
                locationTypes: [
                    {
                      value: null,
                      name: 'None'
                    },
                    {
                        value: 'country',
                        name: 'Country'
                    },
                    {
                        value: 'region',
                        name: 'Region'
                    }
                ],
				form: {
                    countries: [],
                    game_id: null,
                    regions: [],
                    location_type: null,
					title: '',
					message: '',
					tournament_id: '',
					notification_mode: null,
					notification_type: '',
					request_type: "1",
					notifiable_ids: [],
				},
				notifiableIds: [],
                multiSelectCountryOptions: {
                    loading: false,
                    label: 'value',
                    trackBy: 'id',
                    multiple: true,
                    taggable: true,
                },
                multiSelectRegionsOptions: {
                    loading: false,
                    label: 'title',
                    trackBy: 'id',
                    multiple: true,
                    taggable: true,
                },
				multiSelectOptions: {
					loading: false,
					label: 'email',
					trackBy: 'email',
					multiple: true,
					taggable: true,
				},
                gamesOptions: {
                    loading: false,
                    label: 'name',
                    trackBy: 'id',
                    multiple: false,
                    taggable: true,
                },
				modesOptions: [
					{
						value: 0,
						name: 'Participants Mode'
					},
					{
						value: 1,
						name: 'Subscribers'
					},
					{
						value: 2,
						name: 'All'
					},
				],
				requestTypeOptions: [
					{
						value: 1,
						name: 'Personal'
					},
				],
				typesOptions: [
					{
						value: '',
						name: 'Default'
					},
					{
						value: 'INBOX_MESSAGE',
						name: 'Inbox'
					},
				],
				tournamentsOptions: [],
				errors: {},
				titleSize: {
					min: 8,
					max: 50
				},
				messageSize: {
					min: 8,
					max: 250
				}
			};
		},
		async created() {
			await this.init();
            await this.getGames();
			this.onSearchChange = _.throttle(this.onSearchChange, 500)

            if (this.$route.query.donor_id) {
                await this.getDonor()
            }
		},
		watch: {
			$route() {
				this.init();
			},
            'form.notification_mode': function (newValue) {
              if (Number(newValue) === 2) {
                  this.form.tournament_id = ''
              }
            },
            'form.location_type': function () {
                this.form.regions = []
                this.form.countries = []
            },
		},
		methods: {
			...mapActions({
				getNotificationUsers: 'getNotificationUsers',
                getRegions: 'getRegions',
				getTournaments: 'GET_TOURNAMENTS',
				addNotification: 'ADD_NOTIFICATION',
                getGames: 'getGames',
				showDialog: 'showDialog',
                getCountries: 'getCountries'
			}),
			async init() {
				await this.getTournaments({limit: 200, order: 'started_at', 'direction': 'DESC'});
				await this.prepareFieldsAfterLoad();
			},
            async getDonor () {
                await NotificationsService.get({
                    id: this.$route.query.donor_id
                }).then(response => {
                    if (response.data && response.data.data && response.data.data[0]) {
                        const donor = response.data.data[0]
                        this.form.location_type = donor.payload.location_type
                        this.form = {...donor.payload}

                        setTimeout(() => {
                            this.form.regions = donor.payload.regions;
                            this.form.countries = donor.payload.countries;
                        }, 0)
                    }
                })
            },
			formFieldChanged(field, value) {
				this.form[field] = value;
			},
			prepareFieldsAfterLoad() {
				this.initTournamentsOptions();
			},
			initTournamentsOptions() {
				const isEmpty = this.form.tournament_id === '';
				this.tournamentsOptions = this.tournaments.map(item => {
					return {value: item.getId(), name: item.getTitle()}
				});

				this.form.tournament_id = isEmpty ? this.tournamentsOptions[0] || '' : this.form.tournament_id;
			},
			prepareFields(data) {
				let newData = {};
				if (data.notification_type === 'INBOX_MESSAGE') {
					newData = {
						title: data.title,
						message: data.message,
						request_type: data.request_type,
						notification_type: data.notification_type,
						notifiable_ids: data.notifiable_ids.map(item => item.id.toString()),
					}
				} else {
					newData = {
                        location_type: data.location_type,
                        countries: data.countries.map(item => item.id),
                        regions: data.regions.map(item => item.id),
                        game_id: data.game_id,
						title: data.title,
						message: data.message,
						tournament_id: data.tournament_id,
						notification_mode: data.notification_mode,
					}
				}

				return newData;
			},
			sendNotification() {
                if (this.form.location_type) {
                    if (!this.form.countries.length && !this.form.regions.length) {
                        this.$notify({
                            group: 'notify',
                            type: 'error',
                            title: 'Error',
                            text: 'Select locations'
                        })
                        return;
                    }
                }
                if (this.form.notification_type !== 'INBOX_MESSAGE') {
                    if (!this.form.notification_mode) {
                        this.$notify({
                            group: 'notify',
                            type: 'error',
                            title: 'Error',
                            text: 'Select notification mode'
                        })
                        return;
                    }
                }
                if (this.form.notification_mode !== '2' && this.form.notification_type !== 'INBOX_MESSAGE') {
                    if (!this.form.tournament_id) {
                        this.$notify({
                            group: 'notify',
                            type: 'error',
                            title: 'Error',
                            text: 'Select tournament id'
                        })
                        return;

                    }
                    const tournament = this.tournaments.find(item => Number(item.getId()) === Number(this.form.tournament_id.value))
                    if (tournament) {
                        if (!this.hasPermission("Send notification", tournament.game_id)) {
                            this.$notify({
                                group: 'notify',
                                type: 'error',
                                title: 'Error',
                                text: 'You dont have right permissions'
                            })
                            return;
                        }
                    }
                }
				const data = this.prepareFields(Object.assign({}, this.form));

                if (data.tournament_id) {
                    data.tournament_id = data.tournament_id.value
                }

                data.payload = this.form;

				if (this.isValid()) {
					this.addNotification(data)
						.then((data) => {
                            if (data.response && data.response.status !== 201) {
                                    this.$notify({
                                        group: 'notify',
                                        type: 'error',
                                        title: 'Error',
                                        text: data.response.data.message ?? 'Error sent, try again'
                                    })
                                return;
                            } else {
                                this.showDialog({type: NOTIFICATION_SENT_DIALOG});
                                this.form = {
                                    countries: [],
                                    regions: [],
                                    game_id: null,
                                    location_type: null,
                                    title: '',
                                    message: '',
                                    tournament_id: '',
                                    notification_mode: null,
                                    notification_type: '',
                                    request_type: 1,
                                    notifiable_ids: [],
                                };
                            }
						});
				}
			},
			isValid() {
				const form = this.form;
				const titleValid = this.titleSize.min <= form.title.length && this.titleSize.max >= form.title.length;
				const messageValid = this.messageSize.min <= form.message.length && this.messageSize.max >= form.message.length;
				const notifiableIdsValid = form.notifiable_ids.map(item => item.id).length > 0 && form.notification_type === 'INBOX_MESSAGE';
				const errors = {};

				if (!titleValid) {
					errors.title = [this.$t('Title should contain 8-50 characters.')]
				}

				if (!messageValid) {
					errors.message = [this.$t('Message should contain 8-250 characters.')]
				}

				if (!notifiableIdsValid) {
					errors.notifiable_ids = [this.$t('Minimum one user should be selected.')]
				}

				if (!form.tournament_id) {
					errors.tournament_id = [this.$t('Tournament is required field.')]
				}

				if ('' === form.notification_mode) {
					errors.notification_mode = [this.$t('Notification mode is required field.')]
				}

				if (Object.keys(errors).length) {
					this.errors = errors;
				}

				return titleValid && messageValid;
			},
            async onSearchChangeRegions (value) {
                await this.getRegions({
                    search: value
                });
            },
            async onSearchChangeCountry (value) {
              await this.getCountries({
                  search: value
              });
            },
            async onSearchChangeGames () {
                await this.getGames();
            },
			async onSearchChange(value) {
				this.multiSelectOptions.loading = true;
				await this.getNotificationUsers({
					search: value
				});
				this.multiSelectOptions.loading = false;
			},
		}
	}
</script>
