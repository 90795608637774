import http from '../utils/http'

export default {
    getConversations (data) {
        return http.get('/conversations', data);
    },
    getMessages (conversationId) {
        return http.get(`/conversations/${conversationId}/messages`);
    },
    sendMessage (conversationId, data) {
        return http.post(`/conversations/${conversationId}/messages`, data);
    },
    startConversation (userId, data) {
        return http.post(`/conversations/${userId}`, data);
    },
    closeConversation (conversationId) {
        return http.delete(`/conversations/${conversationId}`)
    }
}
