export default function Tournament(data = null) {
  const info = data;

  this.getId = () => info.id;

  this.getTitle = () => info.title;

  this.getPrizePool = () => info.prize_pool;

  this.getDescription = () => info.description;

  this.getImage = () => (info.image ? info.image : "");

  this.getIcon = () => (info.icon ? info.icon : "");

  this.getStatus = () => info.status;

  this.getGameId = () => info.game_id;
  this.getGameNewId = () => (info.game ? info.game.id : null);

  this.getRR = () => info.rr;

  this.getAvailableMobile = () => info.available_mobile;

  this.getAvailableWeb = () => info.available_web;

  this.getIsChatEnabled = () => info.is_chat_enabled;

  this.getChatImageEnabled = () => info.chat_image_enabled;

  this.getCustomSeeding = () => info.custom_seeding;

  this.getWithThirdPlaceMatch = () => info.with_third_place_match;

  this.getPlatformId = () => info.platform_id;

  this.getRuleId = () => info.rule_id;

  this.getGameName = () => (info.game.name ? info.game.name : null);

  this.getMaxMembers = () => info.max_member;

  this.getDeeplink = () => info.deeplink;

  this.getStartedAt = () => info.started_at;

  this.getEndAt = () => info.end_at;

  this.getCreatedAt = () => info.created_at;

  this.getCheckInTime = () => info.check_in_time;

  this.getAutoCheckIn = () => info.auto_check_in;

  this.getChatLink = () => info.chat_link;

  this.getNotifyMzaalo = () => info.notify_mzaalo;

  this.getRequireUserProfiling = () => info.require_user_profiling;

  this.getSiteLink = () => info.site_link;

  this.getMatchType = () => info.match_type;

  this.getType = () => info.type;

  this.getTeamMatesCount = () => info.team_mates;

  this.getChallengeDescription = () => info.challenge_description;

  this.getCreatorName = () => info.creator.name;

  this.getChatRoom = () => info.chat_room;

  this.getIsPrivate = () => info.is_private;

  this.getSecretCode = () => info.secret_code;

  this.getTemplateId = () => info.template_id;

  this.getSubstitutes = () =>
    info.substitute_limit ? info.substitute_limit : 0;

  this.getRegionId = () => {
    if (info.region_id) return info.region_id;

    return info.region ? info.region.id : null;
  };

  this.getRegion = () => {
    if (info.region) {
      return info.region.title;
    }

    return null;
  };

  this.getCountry = () =>
    info.supported_countries ? info.supported_countries : [];

  this.getRegisterTimeout = () => info.register_timeout;

  this.getCheckinParticipantsCount = () => info.checkin_participant_count;
  this.getRegisteredParticipantsCount = () => info.registered_participant_count;

  this.getParticipantsCount = () => info.participant_count;

  this.getIsGeneratedRooms = () => info.is_generated_rooms;

  this.getRooms = () => info.rooms;

  this.getStage = () => info.stage;

  this.getSponsorImage = () => (info.sponsor_image ? info.sponsor_image : "");

  this.getSponsorText = () => info.sponsor_text;

  // this.getTurqEnabled = () => info.turq_enabled;

  this.getSponsorUrl = () => info.sponsor_url;

  this.getMatchAutoHandle = () => info.match_autohandle;

  this.getConsiderInLeaderboard = () => info.consider_in_leaderboard;

  this.exists = () => info !== null;
}
