<template>
	<ul class="page-content__links">
		<li v-for="link in links" :key="link.url">
			<RouterLink
				:to="link.url ? link.url : '/'"
				:class="link.classes ? link.classes : ''"
			>
				<span>{{link.text}}</span>
			</RouterLink>
		</li>
	</ul>
</template>

<script>
	export default {
		name: "page-links",
	  props: {
		links: {
			  type: Array
		  }
	  }
	};
</script>