<template>
    <div class="match" >
        <div class="match__user">
            <img :src="getParticipantAvatar(match.first_participant)" alt="" class="avatar">
            <span>{{ getParticipantName(match.first_participant) }}
            <template v-if="match.first_participant.tournament_position">
                #{{ match.first_participant.tournament_position }}
            </template>
            </span>
        </div>
        <div class="match__info">
            <div class="match__report" v-if="match.results && match.results.length > 0">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 0C2.2379 0 0 2.2379 0 5C0 7.7621 2.2379 10 5 10C7.7621 10 10 7.7621 10 5C10 2.2379 7.7621 0 5 0ZM6.85484 6.31048L6.45161 6.81452C6.39113 6.89516 6.31048 6.95565 6.18952 6.95565C6.12903 6.95565 6.04839 6.91532 6.00806 6.875L4.65726 5.86694C4.45565 5.72581 4.35484 5.50403 4.35484 5.24194V2.09677C4.35484 1.93548 4.49597 1.77419 4.67742 1.77419H5.32258C5.48387 1.77419 5.64516 1.93548 5.64516 2.09677V5L6.81452 5.86694C6.875 5.92742 6.93548 6.00806 6.93548 6.10887C6.93548 6.18952 6.89516 6.27016 6.85484 6.31048Z" fill="#505050"/>
                </svg>
                <span>Report Time: {{ match.results[0].updated_at | readableShortDateFormat }}</span>
            </div>
            <div class="match__report" v-else>

                <span>Wait for results from user</span>
            </div>
            <div class="match__controls" v-if="tournament.getStatus() !== 'CLOSED'">
                <button @click="selectResults = match.results" v-if="match.results && match.results.length > 0">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.3333 14.2857H14V14.5536C14 14.6607 13.9167 14.7143 13.8333 14.7143H3.5C3.38889 14.7143 3.33333 14.6607 3.33333 14.5536V8.01786C3.33333 7.9375 3.38889 7.85714 3.5 7.85714H3.77778V6.57143H3.33333C2.58333 6.57143 2 7.16071 2 7.85714V14.7143C2 15.4375 2.58333 16 3.33333 16H14C14.7222 16 15.3333 15.4375 15.3333 14.7143V14.2857ZM16.5 5.28571C16.5833 5.28571 16.6667 5.36607 16.6667 5.44643V11.9821C16.6667 12.0893 16.5833 12.1429 16.5 12.1429H6.16667C6.05556 12.1429 6 12.0893 6 11.9821V5.44643C6 5.36607 6.05556 5.28571 6.16667 5.28571H16.5ZM16.6667 4H6C5.25 4 4.66667 4.58929 4.66667 5.28571V12.1429C4.66667 12.8661 5.25 13.4286 6 13.4286H16.6667C17.3889 13.4286 18 12.8661 18 12.1429V5.28571C18 4.58929 17.3889 4 16.6667 4ZM9.33333 7C9.33333 6.41071 8.83333 5.92857 8.22222 5.92857C7.58333 5.92857 7.11111 6.41071 7.11111 7C7.11111 7.61607 7.58333 8.07143 8.22222 8.07143C8.83333 8.07143 9.33333 7.61607 9.33333 7ZM7.33333 9.57143V10.8571H15.3333V8.71429L13.3333 6.8125C13.1944 6.67857 13 6.67857 12.8611 6.8125L10 9.57143L8.88889 8.52679C8.75 8.39286 8.55556 8.39286 8.41667 8.52679L7.33333 9.57143Z" fill="#06C2BC"/>
                    </svg>
                </button>

            </div>
        </div>

        <transition name="fade">
            <ResultsModal @close="selectResults = []" :results="selectResults" v-if="selectResults.length"></ResultsModal>
        </transition>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ResultsModal from "./ResultsModal";
export default {
    data () {
        return {
            selectResults: []
        }
    },
    props: {
        match: {
            type: Object,
            required: true
        }
    },
    components: {
        ResultsModal
    },
    computed: {
        ...mapGetters({
            centrifugeInstance: 'centrifugeInstance',
            tournament: 'tournament'
        })
    },
    methods: {
        getParticipantAvatar (participant) {
            if (participant.type === 2) {
                return participant.team.logo ?? '/images/logo.svg'
            }

            return participant.profile.user.avatar ?? '/images/logo.svg'
        },
        getParticipantName (participant) {
            if (participant.type === 2) {
                return participant.team.name ?? 'Unknown team';
            }

            const user = participant.profile.user;

            if (!user) {
                return 'Unknown'
            }

            if (user.nickname) return user.nickname
            if (user.name) return user.name
            if (user.email) return user.email

            return 'Unknown user'
        }
    },
}
</script>
<style scoped lang="scss">
.match {
    display: flex;
    justify-content: space-between;
    background: #FAFAFA;
    box-shadow: 0px 0px 4px rgba(0, 80, 77, 0.1), 0px 2px 10px rgba(0, 80, 77, 0.25);
    border-radius: 2px;
    margin-bottom: 10px;
    .match__user {
        padding: 7px 0;
        padding-left: 10px;
        align-items: center;
        display: flex;
        .avatar {
            width: 28px;
            height: 28px;
            border-radius: 100%;
            margin-right: 10px;
        }
        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-transform: capitalize;
            color: #121212;
        }
    }
    .match__info {
        display: flex;
        .match__report {
            margin-right: 14px;
            display: flex;
            align-items: center;
            span {
                margin-left: 5px;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                color: #505050;
            }
        }
        .match__controls {
            display: flex;
            button {
                padding: 0 15px;
                background: transparent;
                outline: none;
                border: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                transition: 0.2s;
                border-left: 1px solid #E5E5E5;
                &:hover {
                    opacity: 0.6;
                }
            }

        }
    }
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
