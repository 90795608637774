import templates from '../../api/bracket-templates'
import PageMeta from "../../dto/page-meta";
import Template from "../../dto/bracket-templates";

import router from '../../routes'

const state = () => ({
	bracketTemplates: null,
	bracketTemplatesMeta: null
});

const getters = {
	bracketTemplates: state => state.bracketTemplates,
	bracketTemplatesMeta: state => state.notificationsMeta
};

const actions = {
	getTemplates: async ({commit}, data = {}) => {
		const response = await templates.get(data);

		commit('UPDATE_BRACKET_TEMPLATES', response.data.data);
		commit('UPDATE_BRACKET_TEMPLATES_META', response.data);
	},
	getTemplate: async ({commit}, id) => {
		const response = await templates.getItem(id);
		return response.data;
	},
	addTemplate: async ({commit}, data) => {
		const response = await templates.add(data);

		if (response.status === 201) {
			router.push('/bracket-templates');
		}
	},
	updateTemplate: async ({commit}, data) => {
		const response = await templates.update(data.id, data.data);

		if (response.status === 200) {
			router.push('/bracket-templates');
		}
	},
	deleteTemplate: async ({commit, dispatch}, id) => {
		await templates.delete(id);

		dispatch('getTemplates');
	},
};

const mutations = {
	UPDATE_BRACKET_TEMPLATES: (state, data) => {
		const templates = [];

		data.forEach(item => {
			templates.push(new Template(item));
		});

		state.bracketTemplates = templates;
	},
	UPDATE_BRACKET_TEMPLATES_META: (state, data) => {
		state.bracketTemplatesMeta = new PageMeta(data);
	}
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}