import { render, staticRenderFns } from "./BracketsContainer.vue?vue&type=template&id=7c45c087&scoped=true"
import script from "./BracketsContainer.vue?vue&type=script&lang=js"
export * from "./BracketsContainer.vue?vue&type=script&lang=js"
import style0 from "./BracketsContainer.vue?vue&type=style&index=0&id=7c45c087&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c45c087",
  null
  
)

export default component.exports