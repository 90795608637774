import deepLinks from '../../api/deep-links'
import DeepLink from "../../dto/deepLinks";
import PageMeta from "../../dto/page-meta";

const state = () => ({
	deepLinks: null,
	deepLinksMeta: null,
	deepLink: null
});

const getters = {
	deepLinks: state => state.deepLinks,
	deepLinksMeta: state => state.deepLinksMeta,
	deepLink: state => state.deepLink
};

const actions = {
	GET_DEEP_LINKS: ({commit}, data = {}) => {
		return new Promise((resolve, reject) => {
			deepLinks.get(data)
				.then((resp) => {
					commit('UPDATE_DEEP_LINKS', resp.data.data);
					commit('UPDATE_DEEP_LINKS_META', resp.data);
					resolve(resp);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	GET_DEEP_LINK: ({commit}, id) => {
		return new Promise((resolve, reject) => {
			deepLinks.getLink(id)
				.then((resp) => {
					commit('UPDATE_DEEP_LINK', resp.data);
					resolve(resp);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	ADD_DEEP_LINK: ({commit}, data) => {
		return new Promise((resolve, reject) => {
			deepLinks.addLink(data)
				.then((resp) => {
					if (resp.status === 201) {
						commit('UPDATE_DEEP_LINK', resp.data);
						resolve(resp);
					}

					reject(resp);
				})
				.catch(err => {
					reject(err)
				});
		})
	},
	DELETE_DEEP_LINK: async ({commit}, id) => {
		await deepLinks.deleteLink(id)
	}
};

const mutations = {
	UPDATE_DEEP_LINKS: (state, data) => {
		let items = [];

		data.forEach(item => {
			items.push(new DeepLink(item));
		});

		state.deepLinks = items;
	},
	UPDATE_DEEP_LINK: (state, resp) => {
		state.deepLink = new DeepLink(resp.data);
	},
	UPDATE_DEEP_LINKS_META: (state, data) => {
		state.deepLinksMeta = new PageMeta(data);
	}
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}