var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageLinks',{attrs:{"links":[
		{
			url: _vm.id ? '/regions/edit/' +  _vm.id : '/regions/add',
			text: _vm.id ? _vm.$t('Edit Region') : _vm.$t('Add Region'),
			classes: 'active'
		}
	]}}),_c('form',{staticClass:"form form--xl"},[_c('div',{staticClass:"form-group"},[_c('FormInput',{attrs:{"name":'title',"label":_vm.$t('Title'),"placeholder":_vm.$t('Title'),"value":_vm.form.title,"formErrors":_vm.errors.title,"onChange":_vm.formFieldChanged}})],1),(_vm.form.countries)?_c('div',{staticClass:"form-group"},[_c('span',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.$t('Choose countries')))]),_c('FormMultiSelect',{attrs:{"placeholder":_vm.$t('Countries'),"options":_vm.countries,"loading":_vm.multiSelectOptions.loading,"label":_vm.multiSelectOptions.label,"track-by":_vm.multiSelectOptions.trackBy,"multiple":_vm.multiSelectOptions.multiple,"taggable":_vm.multiSelectOptions.taggable},on:{"search-change":_vm.onSearchChange},model:{value:(_vm.form.countries),callback:function ($$v) {_vm.$set(_vm.form, "countries", $$v)},expression:"form.countries"}}),_c('FormNotification',{attrs:{"errors":_vm.errors.countries}})],1):_vm._e(),_c('FormNotification',{attrs:{"errors":_vm.errors.message}}),_c('button',{staticClass:"button button-primary form-button-submit",on:{"click":function($event){$event.preventDefault();return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }