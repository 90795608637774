<template>
    <div class="stage round-robin__stage">
        <CreateRooms v-if="!tournament.getRR().rooms.length"></CreateRooms>
        <ControlsRooms v-if="tournament.getRR().rooms.length > 0 && !tournament.getRR().is_generated_rooms"></ControlsRooms>
        <RoomsMatchesArea v-if="tournament.getRR().is_generated_rooms"></RoomsMatchesArea>
    </div>
</template>
<script>
import CreateRooms from './CreateRooms'
import RoomsMatchesArea from './RoomsMatchesArea'
import ControlsRooms from './ControlsRooms'
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            tournament: 'tournament'
        })
    },
    components: {
        ControlsRooms,
        RoomsMatchesArea,
        CreateRooms
    }
}
</script>
