<template>
    <div class="modal">
        <div class="modal-wrapper">
            <div class="modal-overlay"></div>
            <div class="modal-container">
                <div class="modal-header">
                    <div class="modal-header__title">
                        <span v-if="participant">{{ participant.user.nickname ? participant.user.nickname : participant.user.name }}</span>
                        <span v-else>Not found screenshot for this participant</span>
                    </div>
                    <div class="modal-header__close" @click="$emit('close')">
                        <svg-icon
                            width="16"
                            height="16"
                            :iconType="'slim'"
                        />
                    </div>
                </div>
                <div class="choose-winner">
                    <img :src="participant.image" class="result-image" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SvgIcon from '../../components/SvgIcon'

export default {
    props: {
        participant: {
            type: Object,
            required: true
        }
    },
    components: {
        SvgIcon
    },
}
</script>
<style scoped lang="scss">
.result-image {
    max-width: 100%;
    margin-top: 30px;
    max-height: 80vh;
}
</style>
