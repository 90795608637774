var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page__content-header"},[_c('PageLinks',{attrs:{"links":[
				{
					url: '/tournament/' +  _vm.tournament.getId(),
					text: _vm.tournament.getTitle()
				},
				{
					url: '/tournaments/' +  _vm.tournament.getId() + '/brackets',
					text: _vm.$t('Leaderboard')
				},
				{
					url: '/tournaments/' +  _vm.tournament.getId() + '/participants',
					text: _vm.$t('Participants')
				}
			]}}),_c('div',{staticClass:"page-content__actions"},[_c('LeaderboardRoundsScheduleButton'),_c('LeaderboardStartButton'),_c('LeaderboardCloseButton')],1)],1),_c('div',{staticClass:"page__content-body",class:{progress: _vm.showProgressScreen()}},[(!_vm.showProgressScreen())?_c('Leaderboard',{attrs:{"id":_vm.id}}):_vm._e(),(_vm.showProgressScreen())?_c('LeaderboardGeneratingLoader'):_vm._e(),_c('LeaderboardRoundsScheduleDialog')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }