<template>
	<div class="events-list" v-if="dayEvents">
		<div class="events-list__navigation" v-if="selectedDay">
			<div>
				<a href="#" class="previous-button" @click.prevent="previousDay">
					<SvgIcon
						width="15"
						height="15"
						:iconType="'arrow-left'"
					/>
				</a>
			</div>
			<div class="caption">
				{{getCaption()}}
			</div>
			<div>
				<a href="#" class="next-button" @click.prevent="nextDay">
					<SvgIcon
						width="15"
						height="15"
						:iconType="'arrow-right'"
					/>
				</a>
			</div>
		</div>
		<div class="events-list__body">
			<div class="event" v-for="event in dayEvents">
				<span class="event__time">{{getEventTime(event.started_at)}}</span>
				<div class="event__info">
					<div class="event__info-row">
						<span class="event__info-icon">
							<SvgIcon
								width="24"
								height="24"
								viewBox="0 0 24 24"
								:iconType="'cup-filled'"
							/>
						</span>
						<span class="event__info-text">
							<span class="event__info-label">{{$t('Tournament name')}}</span>
							<span class="event__info-value">
								<a href="#" @click.prevent="goToTournament(event.id)">
									{{event.title}}
								</a>
							</span>
						</span>
					</div>
					<div class="event__info-row">
						<span class="event__info-icon">
							<SvgIcon
								width="24"
								height="24"
								viewBox="0 0 16 16"
								:iconType="'joystick'"
							/>
						</span>
						<span class="event__info-text">
							<span class="event__info-label">{{$t('Game')}}</span>
							<span class="event__info-value">
								{{event.game.name}}
							</span>
						</span>
					</div>
					<div class="event__info-row">
						<span class="event__info-icon">
							<SvgIcon
								width="24"
								height="24"
								viewBox="0 0 24 24"
								:iconType="'people-filled'"
							/>
						</span>
						<span class="event__info-text">
							<span class="event__info-label">{{$t('Max participants')}}</span>
							<span class="event__info-value">{{event.max_member}}</span>
						</span>
					</div>
                    <div class="event__info-row">
                        <span class="event__info-icon">
                            <svg width="24" height="24" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path d="M11.8125 2.5H9C9 1.26953 7.98047 0.25 6.75 0.25C5.48438 0.25 4.5 1.26953 4.5 2.5H1.6875C0.738281 2.5 0 3.27344 0 4.1875V16.5625C0 17.5117 0.738281 18.25 1.6875 18.25H11.8125C12.7266 18.25 13.5 17.5117 13.5 16.5625V4.1875C13.5 3.27344 12.7266 2.5 11.8125 2.5ZM6.75 1.65625C7.20703 1.65625 7.59375 2.04297 7.59375 2.5C7.59375 2.99219 7.20703 3.34375 6.75 3.34375C6.25781 3.34375 5.90625 2.99219 5.90625 2.5C5.90625 2.04297 6.25781 1.65625 6.75 1.65625ZM11.0039 9.8125L5.97656 14.8047C5.80078 14.9805 5.51953 14.9805 5.37891 14.8047L2.46094 11.8867C2.28516 11.7109 2.32031 11.4297 2.46094 11.2891L3.48047 10.2695C3.62109 10.1289 3.90234 10.1289 4.07812 10.3047L5.69531 11.9219L9.42188 8.23047C9.5625 8.05469 9.84375 8.05469 10.0195 8.23047L11.0039 9.21484C11.1797 9.39062 11.1445 9.67188 11.0039 9.8125Z" fill="#018786"/>
                            </svg>
                        </span>
                        <span class="event__info-text">
                        <span class="event__info-label">{{$t('Registered')}}</span>
                        <span class="event__info-value">{{event.registered_participant_count}}</span>
					</span>
                    </div>
					<div class="event__info-row">
					<span class="event__info-icon">
					<SvgIcon
						width="24"
						height="24"
						viewBox="0 0 24 24"
						:iconType="'confirm'"
					/>
					</span>
                    <span class="event__info-text">
                        <span class="event__info-label">{{$t('Check in')}}</span>
                        <span class="event__info-value">{{event.checkin_participant_count}}</span>
					</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'

	import moment from 'moment'
	import helpers from '../../helpers'
	import SvgIcon from '../SvgIcon'

	export default {
        props: {
            gamesSelect: {
                default: [],
                type: Array
            }
        },
		name: "EventsList",
		components: {
			SvgIcon
		},
		computed: {
			...mapGetters({
				dayEvents: 'dayEvents',
				selectedDay: 'selectedDay',
				selectedMonth: 'selectedMonth',
			}),
		},
		methods: {
			...mapActions({
				setSelectedDay: 'setSelectedDay',
				setSelectedMonth: 'setSelectedMonth'
			}),
			getCaption() {
				return moment(this.selectedDay).format("D MMM, dddd");
			},
			previousDay() {
				const selectedDay = new Date(this.selectedDay);
				const day = moment(selectedDay.setDate(selectedDay.getDate() - 1)).format('YYYY-MM-DD');
				const date = helpers.prepareTimeToGetDayEvents(this.selectedDay);
				const toDay = moment(date).format('YYYY-MM-DD');
				const fromDay = moment(date.setDate(date.getDate() - 1)).format('YYYY-MM-DD');

				if (selectedDay.getMonth() < this.selectedMonth.getMonth()) {
					this.setSelectedMonth(selectedDay);
				}

				this.setSelectedDay({
					day: day,
					from: fromDay,
					to: toDay
				});
			},
			nextDay() {
				const selectedDay = new Date(this.selectedDay);
				const day = moment(selectedDay.setDate(selectedDay.getDate() + 1)).format('YYYY-MM-DD');
				const date = helpers.prepareTimeToGetDayEvents(this.selectedDay);
				const fromDay = moment(date.setDate(date.getDate() + 1)).format('YYYY-MM-DD');
				const toDay = moment(date.setDate(date.getDate() + 2)).format('YYYY-MM-DD');


				if (selectedDay.getMonth() > this.selectedMonth.getMonth()) {
					this.setSelectedMonth(selectedDay);
				}

				this.setSelectedDay({
					day: day,
					from: fromDay,
					to: toDay
				});
			},
			getEventTime(date) {
				const dateInTimeZone = helpers.addTimeZoneDiff(date);
				return moment(dateInTimeZone).utc().format('HH:mm')
			},
			goToTournament(id) {
				this.$router.push({name: 'tournament', params: {id: id}})
			}
		}
	}
</script>
