<template>
	<div class="modal" v-show="showDialog">
		<div class="modal-wrapper">
			<div class="modal-overlay"></div>
			<div class="modal-container">
				<div class="modal-header">
					<div class="modal-header__title">
						<span>{{$t('Update schedule')}}</span>
					</div>
					<div class="modal-header__close" @click="hideScheduleDialog">
						<SvgIcon
							width="16"
							height="16"
							:iconType="'slim'"
						/>
					</div>
				</div>
				<div class="modal-body">
					<div class="brackets__schedule">
						<form class="brackets__schedule-form">
							<div
								class="brackets__schedule-form-item"
								v-for="(round, index) in timings"
								:class="{disabled: 'OPENED' !== round.status.toUpperCase()}"
							>
								<div class="form-group__item">
									<div class="brackets__schedule-form-label">{{$t('Title')}}</div>
									<div class="brackets__schedule-form-round">{{$t('Round')}} {{round.round}}</div>
								</div>
								<div class="form-group__item">
									<div class="brackets__schedule-form-label">{{$t('Start time')}}</div>
									<div class="brackets__schedule-form-input">
										<Datetime
											type="datetime"
											v-model="timings[index].started_at"
										/>
									</div>
								</div>
								<div class="form-group__item" v-if="timings[index].games_count">
									<label
										:for="'brackets-schedule-form-match-count-' + index"
										class="brackets__schedule-form-label"
									>{{$t('Num.matches')}}</label>
									<input
										:id="'brackets-schedule-form-match-count-' + index"
										class="brackets__schedule-form-match-count"
										type="number"
										v-model="timings[index].games_count" min="1"
									>
								</div>
							</div>
							<div class="brackets__schedule-form-footer">
								<button
									class="button button-primary"
									@click.prevent="save()"
								>{{$t('Save')}}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from 'vuex'
	import moment from 'moment'
	import {Datetime} from 'vue-datetime'

	import SvgIcon from '../../components/SvgIcon'
	import helpers from '../../helpers'

	export default {
		name: "BracketsRoundsScheduleDialog",
		components: {
			SvgIcon,
			Datetime
		},
		computed: {
			...mapGetters({
				tournament: 'tournament',
				showDialog: 'scheduleDialogState',
				timings: 'scheduleDialogData',
			})
		},
		data() {
			return {
				minTimeDiff: 5,
				maxTimeDiff: 120,
			};
		},
		watch: {
			timings: {
				handler: function () {
					this.updateTimings();
				},
				deep: true
			},
		},
		methods: {
			...mapActions({
				hideScheduleDialog: 'hideBracketsScheduleDialog',
				saveSchedule: 'saveBracketsSchedule',
			}),
			async save() {
				let data = [];
				const lastRoundIndex = this.timings.length - 1;

				this.timings.forEach((item, index) => {
					const roundIsOpened = 'OPENED' === item.status.toUpperCase();
					const roundStartTime = helpers.prepareDateTimeToSave(item.started_at);
					const currentRoundStartTime = item.started_at;

					let duration = null;

					if (!roundIsOpened) return;

					if (index === lastRoundIndex) {
						duration = this.maxTimeDiff;
					} else {
						const nextRoundStartTime = this.timings[index + 1].started_at;
						duration = helpers.getTimeDiff(nextRoundStartTime, currentRoundStartTime);
					}

					// if (this.maxTimeDiff < duration) duration = this.maxTimeDiff;

					data.push({
						round: item.round,
						duration: duration,
						games_count: item.games_count ? item.games_count : null,
						started_at: roundStartTime
					})
				});

				await this.saveSchedule({
					id: this.tournament.getId(),
					body: data
				});
                this.$emit('update')
			},
			updateTimings() {
				for (let i = 1; i < this.timings.length; i++) {
					const roundStatus = this.timings[i].status;
					const currentRoundStartTime = this.timings[i].started_at;
					const previousRoundStartTime = this.timings[i - 1].started_at;
					const currentAndPreviousTimeDiff = helpers.getTimeDiff(currentRoundStartTime, previousRoundStartTime);

					if ('OPENED' === roundStatus.toUpperCase() && this.minTimeDiff > currentAndPreviousTimeDiff) {
						this.timings[i].started_at = moment(previousRoundStartTime).utc().add(this.minTimeDiff, 'minutes').format();
					}
				}
			}
		}
	};
</script>
