<template>
	<div
		class="dropdown"
		:data-position="position"
		:data-horizontal-aligntment="horizontalAlign"
		:data-vertical-aligntment="verticalAlign"
		:class="(show) ?  'show' : ''"
		v-click-outside="hideDropdown"
	>
		<div class="dropdown__toggle" :class="toggleClass" @click="toggleDropdown">
			<slot name="dropdown-toggle"></slot>
		</div>
		<div class="dropdown__content" :class="contentClass">
			<slot name="dropdown-content"></slot>
		</div>
	</div>
</template>

<script>
	import ClickOutside from 'vue-click-outside'
	import {mapGetters} from 'vuex'

	export default {
		name: 'dropdown',
		data() {
			return {
				show: false
			}
		},
		computed: {
			...mapGetters([
				'showDropdown'
			])
		},
		props: {
			position: {
				type: String,
				default: 'bottom'
			},
			horizontalAlign: {
				type: String,
				default: "right"
			},
			verticalAlign: {
				type: String,
				default: "top"
			},
			toggleClass: {
				type: String,
				default: ""
			},
			contentClass: {
				type: String,
				default: ""
			},
		},
		created() {
			this.unsubscribe = this.$store.subscribe((mutation) => {
				if (mutation.type === 'HIDE_DROPDOWN') this.show = false;
			});
		},
		beforeDestroy() {
			this.unsubscribe()
		},
		methods: {
			toggleDropdown() {
				this.show = !this.show;
			},
			hideDropdown() {
				this.show = false;
			}
		},
		directives: {
			ClickOutside
		}
	};
</script>