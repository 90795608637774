import http from '../utils/http'
import joinUrls from "../utils/join-urls";

export default {
	get(data) {
		return http.get('/advertisements', data)
	},
	add(data) {
		return http.postMultipart('/advertisements', data)
	},
	update(id, data) {
		if (!id) {
			return Promise.reject(new Error('Advertisement id is required to update advertisements data'))
		}

		return http.putMultipart(joinUrls('/advertisements', id), data)
	},
	activate(id, data) {
		if (!id) {
			return Promise.reject(new Error('Advertisement id is required to update advertisements data'))
		}

		return http.post(joinUrls('/advertisements', id, 'action', 'activate'), data)
	},
	getAdvertisement(id) {
		if (!id) {
			return Promise.reject(new Error('Advertisement link id is required to get advertisement data'))
		}

		return http.get(joinUrls('/advertisements', id))
	},
	deleteAdvertisement(id) {
		if (!id) {
			return Promise.reject(new Error('Advertisement link id is required to delete advertisement'))
		}

		return http.delete(joinUrls('/advertisements', id))
	}
}