import http from '../utils/http'
import joinUrls from '../utils/join-urls'

export default {
	get(data = {}) {
		return http.get('/rules', data)
	},
	getRule(id) {
		if (!id) {
			return Promise.reject(new Error('Rule id is required to get rule data'))
		}

		return http.get(joinUrls('/rules', id))
	},
	addRule(data) {
		return http.postMultipart('/rules', data)
	},
	deleteRule(id) {
		if (!id) {
			return Promise.reject(new Error('Rule id is required to delete rule'))
		}

		return http.delete(joinUrls('/rules', id))
	},
	updateRule(id, data) {
		if (!id) {
			return Promise.reject(new Error('Rule id is required to update rule data'))
		}

		return http.putMultipart(joinUrls('/rules', id), data)
	}
}
