import tournament from '../../api/tournament'
import roundRobin from '../../api/round-robin'
import Tournament from '../../dto/tournament'
import Platform from '../../dto/platform'
import tft from '../../api/tft'
const state = () => ({
	tournament: null,
	tournamentResults: null,
	tournamentStatus: '',
	games: null,
    nativePlatforms: null,
	platforms: null
});

const getters = {
	games: state => state.games,
    nativePlatforms: state => state.nativePlatforms,
	platforms: state => state.platforms,
	tournament: state => state.tournament,
	tournamentStatus: state => state.tournamentStatus,
	tournamentResults: state => state.tournamentResults,
	matchResult: state => (match, reporter) => {
		return state.tournamentResults.filter(item => item.match_id === match && item.reporter_id === reporter);
	},
	getGame: state => (id) => {
		return state.games.filter(item => item.id === id);
	},
	getPlatform: state => (id) => {
		return state.platforms.filter(item => item.getId() === Number(id));
	},
};

const actions = {
	getGames: ({commit}) => {
		return new Promise((resolve, reject) => {
			tournament.getGames()
				.then((resp) => {
					commit('UPDATE_GAMES', resp.data);
					resolve(resp);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
    async FINISH_TOURNAMENT ({ commit }, id) {
        await tournament.finishChallenge(id)

        const tournamentResponse = await tournament.get(id);

        if (200 === tournamentResponse.status) {
            commit('UPDATE_TOURNAMENT', tournamentResponse.data);
            commit('UPDATE_TOURNAMENT_STATUS', tournamentResponse.data.status);
        }
    },
	GET_PLATFORMS: ({commit}) => {
		return new Promise((resolve, reject) => {
			tournament.getPlatforms()
				.then((resp) => {
					commit('UPDATE_PLATFORMS', resp.data);
					resolve(resp);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	GET_TOURNAMENT: ({commit}, id) => {
		return new Promise((resolve, reject) => {
			tournament.get(id)
				.then((resp) => {
					commit('UPDATE_TOURNAMENT', resp.data);
					commit('UPDATE_TOURNAMENT_STATUS', resp.data.status);
					resolve(resp);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	GET_TOURNAMENT_WITHOUT_STATUS: ({commit}, id) => {
		return new Promise((resolve, reject) => {
			tournament.get(id)
				.then((resp) => {
					commit('UPDATE_TOURNAMENT', resp.data);
					resolve(resp);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
    GET_ROUND_ROBIN_TOURNAMENT: ({commit}, id) => {
        return new Promise((resolve, reject) => {
            roundRobin.get(id)
                .then((resp) => {
                    commit('UPDATE_TOURNAMENT', resp.data);
                    commit('UPDATE_TOURNAMENT_STATUS', resp.data.status);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
	UPDATE_TOURNAMENT: ({commit}, {id, data}) => {
		return new Promise((resolve, reject) => {
			tournament.update(id, data)
				.then((resp) => {
					if (resp.status === 200) {
						commit('UPDATE_TOURNAMENT', resp.data);
						commit('UPDATE_TOURNAMENT_STATUS', resp.data.status);
						resolve(resp);
					}

					reject(resp);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	ADD_TOURNAMENT: ({commit}, data) => {
		return new Promise((resolve, reject) => {
			tournament.add(data)
				.then((resp) => {
					if (resp.status === 201) {
						commit('UPDATE_TOURNAMENT', resp.data);
						resolve(resp);
					}

					reject(resp);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	GET_TOURNAMENT_RESULTS: ({commit}, id) => {
		return new Promise((resolve, reject) => {
			tournament.getResults(id)
				.then((resp) => {
					commit('UPDATE_TOURNAMENT_RESULTS', resp.data.data);
					resolve(resp);
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	UPDATE_TOURNAMENT_RESULT: ({commit}, data) => {
		commit('UPDATE_TOURNAMENT_RESULT', data);
	},
	DELETE_TOURNAMENT: async ({commit}, id) => {
		return await tournament.deleteTournament(id)
	}
};

const mutations = {
	UPDATE_GAMES: (state, data) => {
		state.games = data;
	},
	UPDATE_PLATFORMS: (state, data) => {
		let platforms = [];

		data.forEach((item) => {
			platforms.push(new Platform(item));
		});
        state.nativePlatforms = data
		state.platforms = platforms;
	},
	UPDATE_TOURNAMENT: (state, data) => {
		state.tournament = new Tournament(data);
	},
	UPDATE_TOURNAMENT_RESULTS: (state, data) => {
		state.tournamentResults = data;
	},
	UPDATE_TOURNAMENT_STATUS: (state, status) => {
		state.tournamentStatus = status;
	},
	UPDATE_TOURNAMENT_RESULT: (state, data) => {
		const result = data.result;

		state.tournamentResults.forEach((item, index) => {
			if (result.type === 'INT') {
				if (item.match_id === result.match_id && item.reporter_id === result.reporter_id) {
					state.tournamentResults.splice(index, 1);
				}
			}
		});
		state.tournamentResults.push(result);
	}
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
