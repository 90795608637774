import match from '../../api/match'
import team from '../../api/team'
import Match from '../../dto/match'
import MatchResult from '../../dto/matchResult'
import tft from "../../api/tft";

const state = () => ({
	match: false,
	matchScreenshots: [],
	timings: [],
});

const getters = {
	timings: state => state.timings,
	match: state => state.match,
	matchScreenshots: state => state.matchScreenshots
};

const actions = {
	GET_MATCH: async ({commit}, data) => {
		const matchResponse = await match.get(data.id, data.matchId);
		const matchData = matchResponse.data;
		const firstParticipantType = Number(matchResponse.data.first_participant.type);
		const secondParticipantType = Number(matchResponse.data.second_participant.type);
		const firstParticipant = matchResponse.data.first_participant;
		const secondParticipant = matchResponse.data.second_participant;

		if (firstParticipantType === 2) {
			const firstTeamResponse = await team.get(firstParticipant.team.id);
			if (firstTeamResponse.data.data) {
				const firstTeam = firstTeamResponse.data.data;
				matchData.first_participant.team.team_mates = firstTeam.team_mates;
			} else {
				const firstTeam = firstTeamResponse.data;
				matchData.first_participant.team.team_mates = firstTeam.team_mates;
			}
		}

		if (secondParticipantType === 2) {
			const secondTeamResponse = await team.get(secondParticipant.team.id);
			if (secondTeamResponse.data.data) {
				const secondTeam = secondTeamResponse.data.data;
				matchData.second_participant.team.team_mates = secondTeam.team_mates;
			} else {
				const secondTeam = secondTeamResponse.data;
				matchData.second_participant.team.team_mates = secondTeam.team_mates;
			}
		}

		commit('UPDATE_MATCH', matchData);

	},
	GET_MATCH_SCREENSHOTS: ({commit}, data) => {
		return new Promise((resolve, reject) => {
			match.getResult(data.id, data.matchId)
				.then((response) => {
					commit('UPDATE_MATCH_SCREENSHOTS', response.data.data);
					resolve(response);
				})
				.catch(err => {
					reject(err)
				});
		});
	},
	FINISH_MATCH: ({commit}, data) => {
		return new Promise((resolve, reject) => {
			match.finish(data.id, data.matchId, data.body)
				.then((response) => {
					resolve(response);
				})
				.catch(err => {
					reject(err)
				});
		});
	},
	GET_TIMINGS: ({commit}, id) => {
		return new Promise((resolve, reject) => {
			match.getTimings(id)
				.then((response) => {
					commit('UPDATE_TIMINGS', response.data.data);
					resolve(response);
				})
				.catch(err => {
					reject(err)
				});
		});
	},
	UPDATE_TIMINGS: ({commit}, data) => {
		return new Promise((resolve, reject) => {
			match.updateTimings(data.id, data.body)
				.then((response) => {
					commit('UPDATE_TIMINGS', response.data.data);
					resolve(response);
				})
				.catch(err => {
					reject(err)
				});

		});

	},
	revertMatch: async ({commit, dispatch}, data) => {
        if (data.tournamentType === 6) {
            return await tft.matchRevert(data.tournamentId, data.matchId);
        } else {
            const response = await match.revert(data.tournamentId, data.matchId);
            if (response.status === 200) {
                dispatch('fetchBrackets', data.tournamentId);
            }
        }
	},
	recreateMatch: async ({commit, dispatch}, data) => {
		const response = await match.recreate(data.tournamentId, data.matchId);

		if (response.status === 200) {
			dispatch('fetchBrackets', data.tournamentId);
		}
	},
};

const mutations = {
	UPDATE_MATCH: (state, value) => {
		state.match = new Match(value);
	},
	UPDATE_MATCH_SCREENSHOTS: (state, data) => {
		let results = [];

		data.forEach((item) => {
			results.push(new MatchResult(item));
		});

		state.matchScreenshots = results;
	},
	UPDATE_TIMINGS: (state, value) => {
		state.timings = value;
	},
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
