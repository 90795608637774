var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageLinks',{attrs:{"links":[
			{
				url: _vm.id ? '/rule/edit/' +  _vm.id : '/rule/add',
				text: _vm.id ? _vm.$t('Edit Rule') : _vm.$t('Add Rule'),
				classes: 'active'
			}
		]}}),_c('form',{staticClass:"form form--xl"},[_c('div',{staticClass:"form-group"},[_c('FormInput',{attrs:{"name":'title',"label":_vm.$t('Title'),"placeholder":_vm.$t('Name'),"value":_vm.form.title,"formErrors":_vm.errors.title,"onChange":_vm.formFieldChanged}})],1),(_vm.games)?_c('div',{staticClass:"form-group"},[_c('span',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.$t('Games')))]),_c('FormMultiSelect',{attrs:{"placeholder":_vm.$t('Games'),"options":_vm.games,"loading":_vm.multiSelectOptions.loading,"label":_vm.multiSelectOptions.label,"track-by":_vm.multiSelectOptions.trackBy,"multiple":_vm.multiSelectOptions.multiple,"taggable":_vm.multiSelectOptions.taggable},model:{value:(_vm.form.game_id),callback:function ($$v) {_vm.$set(_vm.form, "game_id", $$v)},expression:"form.game_id"}}),_c('FormNotification',{attrs:{"errors":_vm.errors.games}})],1):_vm._e(),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-input-field"},[_c('label',[_c('span',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.$t('Content')))])]),_c('vue-editor',{staticClass:"form-text-editor",attrs:{"placeholder":_vm.$t('Content')},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}}),_c('FormNotification',{attrs:{"errors":_vm.errors.title}})],1)]),_c('FormNotification',{attrs:{"errors":_vm.errors.message}}),_c('button',{staticClass:"button button-primary form-button-submit",on:{"click":function($event){$event.preventDefault();return _vm.saveRule()}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }