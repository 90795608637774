var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"round__robin"},[(_vm.tournament.getRR().stage === _vm.STAGE_ROUND_ROBIN)?[_c('div',{staticClass:"page__content-header"},[_c('PageLinks',{attrs:{"links":[
					{
						url: '/tournament/' +  _vm.tournament.getId(),
						text: _vm.tournament.getTitle()
					},
					{
						url: '/tournaments/' +  _vm.tournament.getId() + '/brackets',
						text: _vm.$t('Brackets')
					},
					{
						url: '/tournaments/' +  _vm.tournament.getId() + '/participants',
						text: _vm.$t('Participants')
					}
				]}}),_c('div',{staticClass:"page-content__actions"},[(_vm.tournament.getRR().stage === _vm.STAGE_ROUND_ROBIN && !_vm.tournament.getRR().is_generated_rooms)?[_c('div',{staticClass:"participants__indicator"},[_c('span',[_vm._v("all participant")]),_c('span',[_vm._v(_vm._s(_vm.tournament.getParticipantsCount())+"/"+_vm._s(_vm.tournament.getMaxMembers()))])]),(_vm.tournament.getRR().rooms.length > 0 && !_vm.tournament.getRR().is_generated_rooms)?_c('button',{staticClass:"fill_empty_spots",attrs:{"disabled":!_vm.tournament.getRR().rooms.length},on:{"click":_vm.fillEmptySpots}},[_vm._v(" Fill empty spots ")]):_vm._e(),_c('button',{staticClass:"create__rooms",attrs:{"title":"There are empty rooms","disabled":!_vm.tournament.getRR().rooms.length || _vm.issetEmptyRooms},on:{"click":_vm.generateMatches}},[_vm._v(" Start tournament ")])]:_vm._e()],2)],1),_c('div',{staticClass:"page__content-body"},[_c('RoundRobinStage',{key:_vm.key})],1)]:[(_vm.tournament.getRR().stage === _vm.STAGE_BRACKETS)?_c('BracketsContainer',{key:_vm.key,attrs:{"id":_vm.tournament.getId()}}):_vm._e()],_c('ChatsListContainer',{attrs:{"id":_vm.tournament.getId()}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }